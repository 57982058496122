// 查询基础信息
// 授课老师
export const TEACHER_LIST_REQUEST = 'TEACHER_LIST_REQUEST';
// 学段列表
export const STUDY_LIST_REQUEST = 'STUDY_LIST_REQUEST';
// 乜有"全部"的学段列表
export const STUDY_LIST_REQUEST_NOT_ALL = 'STUDY_LIST_REQUEST_NOT_ALL';
// 学科列表
export const SUBJECTS_LIST_REQUEST = 'SUBJECTS_LIST_REQUEST';
// 年级列表
export const GRADES_LIST_REQUEST = 'GRADES_LIST_REQUEST';
// 学年列表
export const GET_SCHOOL_YEARS = 'GET_SCHOOL_YEARS';
// 学届列表
export const GET_SCHOOL_PERIODS = 'GET_SCHOOL_PERIODS';
// 教材列表
export const GET_TEXT_BOOKS = 'GET_TEXT_BOOKS';
// 章节树
export const GET_CHAPTERS_TREE_TREE = 'GET_CHAPTERS_TREE_TREE';
// 老师分页总数
export const TEACHER_LIST_TOTALPAGES = 'TEACHER_LIST_TOTALPAGES';
// 重载老师列表
export const TEACHER_LIST_REQUEST_RELOAD = 'TEACHER_LIST_REQUEST_RELOAD';
// 有权限授课老师
export const FILTERED_TEACHER_LIST_REQUEST = 'FILTERED_TEACHER_LIST_REQUEST';
// 将有权限授课老师unshift到老师列表
export const UNSHIFT_TEACHER_LIST_REQUEST = 'UNSHIFT_TEACHER_LIST_REQUEST';
