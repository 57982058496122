/*
 * @Author: gouyang
 * @Description: 没有状态的请求
 * @Date: 2019-07-12 16:59:27
 */
import { message } from 'antd';
import axios, { AxiosRequestConfig, AxiosPromise, AxiosInstance } from 'axios';
import apiConfig from '../config/api';

message.config({
  maxCount: 1,
});
interface wrapConfig extends AxiosRequestConfig {
  isCustom?: boolean;
  onlyLogin?: boolean;
  ignoreLoading?: boolean;
  noLeagueId?: boolean;
}
interface wrapAxios extends AxiosInstance {
  (config: wrapConfig): AxiosPromise;
}
let wrapConfig: wrapConfig;
// let isCustom: boolean;
let hasNotStatusRequest: wrapAxios = axios.create({
  baseURL: apiConfig.api,
});

/* 过滤响应 */
hasNotStatusRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {},
);

export default hasNotStatusRequest;
