/*
 * @Author: gouyang
 * @Description: 通过所选栏目跳转到对应页面
 * @Date: 2019-10-15 13:32:04
 */
import { stringifySearchParams, getUserId, linkColumnUrl } from './index';

function getMainUserInfo() {
  let userInfo = window.localStorage.getItem('mainUserInfo');
  userInfo = userInfo ? JSON.parse(userInfo) : { access_token: '', userId: '' };
  const { access_token, userId } = userInfo as any;
  return { access_token, userId };
}

function fromColumnToPage(menus: any, parentId: string) {
  const { item, key } = menus;
  const data = JSON.parse(item.props.className);
  if (data.type === 2) {
    const { access_token, userId } = getMainUserInfo();
    const config = JSON.parse(data.config);
    if (access_token) {
      const url = config.url.split('?');
      const mergeParams = url.slice(1).join('?');
      const params = mergeParams ? `&${mergeParams}` : '';
      const link = `${
        url[0]
      }?mainUserId=${userId}&currentUserId=${getUserId()}&token=${access_token}${params}`;
      window.open(linkColumnUrl(link));
    }
    return null;
  }

  const search = {
    id: key,
    name: data.name,
    type: data.type,
    parentId,
  };
  (window as any)._history.push(`/navigation${stringifySearchParams(search)}`);
}

export default fromColumnToPage;
