/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable guard-for-in */
// 点播课时
import React from 'react';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Radio, message, Button } from 'antd';
import Immutable from 'seamless-immutable';
import { getUserId } from '../../../util';

import { AJTextField } from '../../../components/AJInput';
import AddResources from '../siteResources/addResources';
import Upload from './upload';
import * as action from '../../../redux/action/vod.action';

import './style.less';
import { toLower } from 'lodash';
import Mp4OneOcrf from './Mp4OneOcrf';

interface P extends FormComponentProps {
  onDemand: any; // 数据回显
  isEdit: boolean; // 是否是编辑状态
  subjectId: []; // 学科id
  progressStatus: {
    mp4: boolean;
    ocrf: boolean;
    txt: boolean;
  };
}
interface S {
  sourceTypeCurrent: number | string; // 课时来源类型激活状态
  meansTypeCurrent: number | string; // 资料类型激活状态
  resourMeansVisible: boolean; // 站点资源弹出
  resourVideoVisible: boolean;
  filemp4: any; // 点播视频列表
  fileocrf: any; // ocrf
  filetxt: any; // txt
  markData: any; // 打点文件
  videoTips: boolean;
  subjects: []; // 学科id
  resSubjects: []; // 资源学科
  isFilter: boolean; // 是否过滤数据，当从站点资源选取过 才过滤数据
  prohibitEdit: boolean; // 禁止编辑资源
  resourceGuid: string; // 资源id
  markups: any;
  hadMarkups: boolean;
  mp4LinkOcrf?: boolean; // 视频是否关联了ocrf
}
const userId = getUserId();
class OnDemand extends React.PureComponent<P, S> {
  private clearUploadStatus: () => void;
  private uploadSucess = true;
  private whenUploadClearFileInResource = false; // 是否在资源库上传过程中清空视频文件
  private refMp4oneocrf?: any;

  constructor(props: P) {
    super(props);
    this.state = {
      sourceTypeCurrent: '',
      meansTypeCurrent: '',
      resourMeansVisible: false,
      resourVideoVisible: false,
      filemp4: [],
      fileocrf: [],
      filetxt: [],
      markData: [],
      videoTips: false,
      subjects: [],
      resSubjects: [],
      resourceGuid: '',
      isFilter: false,
      prohibitEdit: true,
      markups: [],
      hadMarkups: false,
      mp4LinkOcrf: false,
    };
    this.clearUploadStatus = () => {};
    this.refMp4oneocrf = React.createRef();
  }
  componentDidMount() {
    // 编辑的数据填充
    const { onDemand, isEdit, subjectId } = this.props;
    if (onDemand && isEdit) {
      this.formatData(onDemand);
    }
    this.setState({
      subjects: subjectId,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.subjectId !== nextProps.subjectId) {
      this.setState(
        {
          subjects: nextProps.subjectId.asMutable(),
        },
        this.clearData,
      );
    }
  }
  // 上传拿值
  uploadDataFun = async (
    data: any,
    typeName: string,
    markData: any,
    clearUploadStatus?: () => void,
  ) => {
    const { sourceTypeCurrent } = this.state;
    const _typeName = toLower(typeName);
    if (sourceTypeCurrent === 2) {
      this.whenUploadClearFileInResource = true;
    } else {
      this.whenUploadClearFileInResource = false;
    }
    if (_typeName === 'mp4') {
      this.clearUploadStatus = clearUploadStatus || (() => {});
      if ((data || []).length > 0 && data[0].md5) {
        const res = await action.getAllMarkups(data[0].md5);
        this.setState({
          filemp4: data,
          markups: res,
        });
      } else {
        this.setState({
          filemp4: data,
          markups: [],
        });
      }
    }
    if (_typeName === 'ocrf') {
      this.setState({
        fileocrf: data,
      });
    }

    // 点播文件类型txt
    if (_typeName === 'txt') {
      this.setState({
        filetxt: data,
        markData,
      });
    }
  };
  // 课时来源类型判断
  sourceType = (e: any) => {
    const { prohibitEdit, filemp4 } = this.state;
    const { form, progressStatus } = this.props;
    const { value } = e.target;

    if (filemp4.length === 0 && !progressStatus.mp4) {
      message.warning('请等待资源上传完成！');
      return;
    }
    if (!progressStatus.ocrf || !progressStatus.txt) {
      message.warning('请等待资源上传完成！');
      return;
    }

    if (value === 1 && prohibitEdit) {
      // 本地上传 并且不是自己上传的文件， 则点播数据全部进行清空
      this.setState(
        {
          filemp4: [],
          fileocrf: [],
          filetxt: [],
          markData: [],
          resSubjects: [],
          sourceTypeCurrent: value,
          prohibitEdit: false,
        },
        () => form.setFieldsValue({ name: '' }),
      );
    } else {
      this.setState({
        sourceTypeCurrent: value,
      });
    }
  };
  // 显示弹窗
  show = (name: string) => {
    const { subjects } = this.state;
    if ((name === 'resourMeansVisible' || name === 'resourVideoVisible') && subjects.length === 0) {
      message.warning('请选择学科！');
      return;
    }
    const obj: any = {};
    obj[name] = true;
    this.setState({
      ...obj,
    });
  };
  // 关闭弹窗
  cancel = (name: string) => {
    const obj: any = {};
    obj[name] = false;
    this.setState({
      ...obj,
    });
  };
  // 点播主视频拿数据 数据返回
  cbackVeido = (data: any) => {
    if (data.length > 0) {
      this.formatData(data[0]);
    }
  };
  // 父组件获取数据
  getItemsValue = () => {
    const { progressStatus, form } = this.props;
    const { filemp4, fileocrf, filetxt, resourceGuid, sourceTypeCurrent } = this.state;
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      form.validateFields((error: any, value: any) => {
        if (!error) {
          if (filemp4.length === 0 && !progressStatus.mp4) {
            message.warning('请等待资源上传完成后，再进行保存！');
            return;
          } else if (filemp4.length === 0 && progressStatus.mp4) {
            this.clearUploadStatus();
            reject('请添加点播视频！');
            this.setState({
              videoTips: true,
            });
            return;
          }
          if (!progressStatus.ocrf || !progressStatus.txt) {
            message.warning('请等待资源上传完成后，再进行保存！');
            return;
          }

          const obj = {
            files: [...filemp4, ...fileocrf, ...filetxt],
            // 新逻辑不上传该字段，免得删除或覆盖
            // markData,
            category: 1,
            name: value.name,
            resourceGuid: sourceTypeCurrent === 1 ? '' : resourceGuid,
          };
          resolve(obj);
        } else {
          reject('请添加资料课时信息！');
        }
      });
    });
  };
  // 当学科变化 清空 站点资源选择的数据
  clearData = () => {
    const { subjects, resSubjects, isFilter } = this.state;
    if (!isFilter) {
      return;
    }
    // 置空的数据
    const clearObj = {
      filemp4: [],
      fileocrf: [],
      filetxt: [],
      markData: [],
      isFilter: false,
      prohibitEdit: false,
    };
    if (subjects.length === 0) {
      this.setState({
        ...clearObj,
      });
      this.props.form.setFieldsValue({ name: '' });
    }
    if (resSubjects.length > 0 && subjects.length > 0) {
      const flg = resSubjects.some((item: any) => {
        return (subjects as any).indexOf(item.subjectId) >= 0;
      });
      if (!flg) {
        this.setState({
          ...clearObj,
        });
        this.props.form.setFieldsValue({ name: '' });
      }
    }
  };
  // 资料数据去重
  duplicate = (data: any) => {
    const obj: any = {};
    const list = [];
    for (const i in data) {
      if (data[i].data && !obj[data[i].data.fileId]) {
        list.push(data[i]);
        obj[data[i].data.fileId] = true;
      }
      if (data[i].resourceGuid && !obj[data[i].resourceGuid]) {
        list.push(data[i]);
        obj[data[i].resourceGuid] = true;
      }
    }
    return list;
  };
  // 处理数据格式
  formatData = (data: any) => {
    const { markData, files, name, subjects, createUserId, resourceGuid, markups } = data;
    const obj = {
      resourceGuid,
      resSubjects: subjects,
      markData,
      markups: markups || [],
      filetxt: files.filter(
        (item: any) =>
          item.extension.toLowerCase() === 'txt' || item.extension.toLowerCase() === 'json',
      ),
      filemp4: files.filter((item: any) => item.extension.toLowerCase() === 'mp4'),
      fileocrf: files.filter(
        (item: any) =>
          item.extension.toLowerCase() === 'ocrf' ||
          item.extension.toLowerCase() === 'crf' ||
          item.extension.toLowerCase() === 'crf2',
      ),
      isFilter: true,
    };
    this.setState({
      ...obj,
      videoTips: false,
      prohibitEdit: userId !== createUserId,
    });
    this.props.form.setFieldsValue({ name });
  };
  getShowStatus(): boolean {
    const { isEdit } = this.props;
    const { sourceTypeCurrent, filemp4 } = this.state;
    if (this.whenUploadClearFileInResource) {
      return true;
    }
    return !!(sourceTypeCurrent === 1 || isEdit || filemp4.length > 0);
  }

  onChangeMp4LinkOcrf = (value: boolean) => {
    this.setState({
      mp4LinkOcrf: value,
    });
  }

  render() {
    const {
      sourceTypeCurrent,
      filemp4,
      fileocrf,
      filetxt,
      resourVideoVisible,
      subjects,
      videoTips,
      prohibitEdit,
      markups,
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    const isShow = this.getShowStatus();

    return (
      <div className="model" id="onDemand">
        <Mp4OneOcrf ref={this.refMp4oneocrf} filemp4={filemp4} fileocrf={fileocrf} changeFileocrf={(data:any) => {
          this.setState({fileocrf: data})
        }} onChangeMp4LinkOcrf={this.onChangeMp4LinkOcrf}/>
        <h3>点播设置</h3>
        {resourVideoVisible ? (
          <AddResources
            category={[1]}
            title="选择点播"
            subjectId={subjects}
            resourcesType="video"
            cancel={() => this.cancel('resourVideoVisible')}
            cbackFun={this.cbackVeido}
            visible={resourVideoVisible}
          />
        ) : (
          ''
        )}
        <div className="onDemand">
          <Form>
            <Form.Item {...formLayout} className="requireStar first-form-item" label="添加方式">
              <Radio.Group value={sourceTypeCurrent} onChange={this.sourceType}>
                <Radio value={1}>本地导入</Radio>
                <Radio value={2}>站点资源</Radio>
              </Radio.Group>
            </Form.Item>
            {
              // 站点资源
              sourceTypeCurrent === 2 ? (
                <Form.Item {...formLayout1}>
                  <Button
                    className="default"
                    style={{ width: '110px' }}
                    onClick={() => this.show('resourVideoVisible')}
                  >
                    选择资源
                  </Button>
                  <span className="gray_font" style={{ marginLeft: '5px' }}>
                    请先选择学科
                  </span>
                </Form.Item>
              ) : (
                ''
              )
            }
            {isShow ? (
              <Form.Item {...formLayout2} label="名称">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: '请填写名称！',
                    },
                  ],
                })(<AJTextField disabled={prohibitEdit} maxLength={100} />)}
              </Form.Item>
            ) : (
              ''
            )}
            {isShow ? (
              <Form.Item {...formLayout3} label="通用格式" className="requireStar">
                <Upload
                  safetyChain
                  data={Immutable(filemp4)}
                  disabled={prohibitEdit}
                  acceptTips="仅支持mp4格式"
                  accept=".mp4"
                  maxLen={1}
                  cbackFun={this.uploadDataFun}
                  clearWaringFun={() => this.setState({ videoTips: false })}
                  typeName="mp4"
                  validate={async (file) => this.refMp4oneocrf.current?.checkIsMp4LinkOcrf(file)}
                  onDel={() => this.setState({fileocrf: []})}
                />
                {videoTips ? <div className="form-error-tips">请添加点播视频！</div> : ''}
              </Form.Item>
            ) : (
              ''
            )}
            {isShow ? (
              <Form.Item {...formLayout3} label="专有格式">
                <Upload
                  data={Immutable(fileocrf)}
                  disabled={prohibitEdit}
                  acceptTips="仅支持ocrf格式，可通过专有播放器进行播放"
                  accept=".crf,.crf2,.ocrf"
                  maxLen={1}
                  cbackFun={this.uploadDataFun}
                  typeName="ocrf"
                  validate={async (file) => this.refMp4oneocrf.current?.checkIsOcrfLinkMp4(file)}
                  hideDelete={this.state.mp4LinkOcrf}
                />
              </Form.Item>
            ) : (
              ''
            )}
            {isShow ? (
              <Form.Item {...formLayout3} label="视频标记">
                <Upload
                  data={Immutable(filetxt)}
                  markups={markups}
                  disabled={prohibitEdit}
                  acceptTips="仅支持txt、json格式"
                  showTxt
                  accept=".txt,.json"
                  maxLen={1}
                  cbackFun={this.uploadDataFun}
                  typeName="txt"
                  isDotFile
                />
              </Form.Item>
            ) : (
              ''
            )}
          </Form>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 15 },
};
const formLayout1: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 15, offset: 2 },
};
const formLayout2: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 },
};
const formLayout3: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 15 },
};
const mapStateToProps = (state: any) => ({
  onDemand: state.Lesson.classHourDetail.onDemand,
  subjectId: state.Lesson.subjectId,
  progressStatus: state.Lesson.progressStatus,
});
export default connect(mapStateToProps)(Form.create<P>()(OnDemand));
