import './index.less';
import React from 'react';
import env_api from '../../config/api';

class Footer extends React.Component<any, any> {
  unmounted: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      // copyright: '',
    };
    this.unmounted = false;
  }
  componentWillUnmount() {
    this.unmounted = true;
  }

  onWatchUserAgreement = () => {
    window.open(env_api.userAgreement);
  };

  onWatchPrivacyPolicy = () => {
    window.open(env_api.privacyPolicy);
  };

  render() {
    return (
      <div className="Footer-component" id="FooterComponent">
        <div className="footer-panel">
          <div>
            <div className="row">
              <p>
                公司地址：四川省成都市高新区世纪城南路599号（天府软件园D区）6栋6楼 |
                咨询电话：028-8531 2130 | 邮箱：kfxx@eastedu.com | 系统版本：V2.18
              </p>
            </div>
            {/* <div className="row">
              <p>电话 028-8531 2130</p>
              <p style={{ textAlign: 'right' }}>系统版本：V2.18</p>
            </div> */}
            <div className="row">
              {/* <p>邮箱 kfxx@eastedu.com</p> */}
              <p>
                <span>
                  <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank">
                    蜀ICP备15016763号-4
                  </a>{' '}
                  川B2-20190557 版权所有
                  <span> 成都东方闻道科技发展有限公司 </span>
                  <span>|</span>
                  <span className="watchProtocol" onClick={this.onWatchUserAgreement}>
                    《用户协议》
                  </span>
                  <span>和</span>
                  <span className="watchProtocol" onClick={this.onWatchPrivacyPolicy}>
                    《隐私政策》
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
