import moment from 'moment';

/**
 *  请求月历之前 判断数据是否是当前的数据，如果当前就是这个月的数据，则不请求接口
 * @param startDate  开始时间 时间戳
 * @param data  当前的月历数据
 */
export const requestDataCompare = (startDate: number, data: { [key: string]: any }) => {
  if (!data) {
    return true;
  }
  if (data[startDate]) {
    return false;
  } else {
    return true;
  }
};

// 根据 月份，获取当月天数数组
export const getMonthDays = (data: string[], monthSchedule: any) => {
  const year = Number(data[0]);
  const month = Number(data[1]);
  const str = data.join('-');
  const daysArr: any = [[], [], [], [], [], []]; // 6*7的日历数组
  const currentWeekday = moment(str, 'YYYY-MM').date(1).weekday(); // 获取当月1日为星期几
  const prevMonthDays = moment(str, 'YYYY-MM').subtract(1, 'month').daysInMonth(); // 获取上月天数
  const currentMonthDays = moment(str, 'YYYY-MM').daysInMonth(); // 获取当月天数
  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 7; j++) {
      // 每月前几天，填充上月天数
      if (i === 0 && j <= currentWeekday) {
        const num = prevMonthDays - currentWeekday + j;
        let m = month - 1;
        let y = year;
        if (m < 1) {
          m = 12;
          y = year - 1;
        }
        daysArr[i][j] = {
          date: moment(`${y}-${m}-${num}`, 'YYYY-MM-DD').valueOf(),
          type: 'gay',
          val: num,
        };
      } else {
        let m = month + 1;
        let y = year;
        if (m > 12) {
          y = year + 1;
          m = 1;
        }
        const num = 7 * i + j - currentWeekday;
        // 处理每月固定天数之后， 填充下月天数
        if (num > currentMonthDays) {
          daysArr[i][j] = {
            date: moment(`${y}-${m}-${num - currentMonthDays}`, 'YYYY-MM-DD').valueOf(),
            type: 'gay',
            val: num - currentMonthDays,
          };
        } else {
          // 正常天数
          const time = moment(`${year}-${month}-${num}`, 'YYYY-MM-DD').valueOf();
          const item = { date: time, type: '', val: num };
          if (monthSchedule && monthSchedule[item.date]) {
            if (monthSchedule[item.date].liveNum > 0) {
              item.type = 'one';
            } else if (
              monthSchedule[item.date].materialNum > 0 ||
              monthSchedule[item.date].vodNum > 0
            ) {
              item.type = 'two';
            }
          }
          daysArr[i][j] = item;
        }
      }
    }
  }
  return daysArr;
};
