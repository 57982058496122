import { message } from 'antd';
import {
  Authenticate,
  appendSearchParams,
  getParameter,
  removeSearchParams,
  tokenManager,
} from 'eastedu-login-sdk';
import React from 'react';
import { encodeURL, decode } from 'js-base64';

const isLocalhost = (url: string) => {
  return /^(https?:)?\/\/localhost/.test(url);
};

function OAuthLogin(props: any) {
  // 如果是本地环境，则从url中获取token。
  if (isLocalhost(location.href)) {
    const token = getParameter('token');
    if (token) {
      try {
        tokenManager.setToken(JSON.parse(decode(token)));
        location.href = removeSearchParams(location.href, ['token']);
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <Authenticate
      onAuthenticated={async (token, returnUri) => {
        // if (returnUri) {
        //   const isSameSite = location.hostname === new URL(returnUri).hostname;
        //   if (isSameSite) {
        //     location.href = returnUri;
        //   } else if (isLocalhost(returnUri)) {
        //     // 由于认证中心的开发环境与本地环境域不一致，登录成功后无法正确跳转到本地环境，所以需要将token信息通过url参数传递给本地环境。
        //     location.href = appendSearchParams(returnUri, {
        //       token: encodeURL(JSON.stringify(token)),
        //     });
        //   }
        // }
        if (returnUri && isLocalhost(returnUri)) {
          window.location.href = `http://localhost:8000/blank?token=${token.access_token}&userId=${token.uid}`;
        } else {
          window.location.href = `/blank?token=${token.access_token}&userId=${token.uid}`;
        }
      }}
      onError={(err, logout) => {
        message.error(err.message ?? '获取用户信息失败，请重新登录', 1500, () => {
          logout();
        });
      }}
    />
  );
}

export default OAuthLogin;
