import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import types from './type';
import { Select, Cascader } from 'antd';

const { Option } = Select;
const InitialState = {
  type: '',
  width: 0,
  isShow: false,
  distance: 0,
  defaultActiveKey: 0,
};
interface obj {
  [key: string]: any;
}
interface P {
  data: obj[];
  type: string;
  disabled?: boolean;
  activeKey?: number;
  notAddAll?: boolean;
  chapterId?: string[];
  materialId?: string;
  tabChange?: (o: obj, key?: number | string) => void;
  isOperation: boolean;
}
const fieldNames = {
  label: 'name',
  value: 'guid',
  children: 'children',
};
class Tablist extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = { ...InitialState, ...this.componentInitState(props) };
  }
  componentInitState(props: P) {
    const { defaultActiveKey } = InitialState;
    const { data, type, activeKey, notAddAll } = props;
    if (!notAddAll && data.every((item) => item.name !== '全部')) {
      if (type === 'tree' || type === 'textbook') {
        data.unshift({ name: '全部', guid: '', children: [] });
      } else {
        data.unshift({ name: '全部', code: '' });
      }
    }
    const baseState = { data, type };
    if (activeKey || activeKey === 0) {
      return { activeKey, ...baseState };
    } else {
      return { activeKey: defaultActiveKey, ...baseState };
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: P) {
    this.setState({ ...InitialState, ...this.componentInitState(nextProps) });
  }
  handleClick(item: obj | string | string[], key?: number) {
    if (!this.props.isOperation) return null;
    const o: obj = {};
    const { tabChange, activeKey } = this.props;
    const { type } = this.state;
    o[type] = item;
    if (!(activeKey === 0 || activeKey)) this.setState({ activeKey: key });
    if (tabChange) tabChange(o, key);
  }
  renderTemplate() {
    const { type, data, activeKey } = this.state;
    const { disabled, materialId, chapterId } = this.props;
    switch (type) {
      case 'textbook':
        return (
          <Select
            allowClear
            style={{ width: 400, height: 32 }}
            value={materialId}
            onChange={(value: string) => this.handleClick(value || '')}
          >
            {data &&
              data.map((item: obj, index: number) => (
                <Option key={`${type}-${index}`} value={item.guid}>
                  {item.name}
                </Option>
              ))}
          </Select>
        );
      case 'tree':
        return (
          <Cascader
            changeOnSelect
            value={chapterId}
            fieldNames={fieldNames}
            options={data}
            disabled={disabled}
            onChange={(value) => this.handleClick(value)}
            placeholder="全部"
          />
        );
      default:
        return (
          data &&
          data.map((item: obj, index: number) => {
            return (
              <div
                key={`${index}`}
                className={activeKey === index ? `item active ${type}` : `item ${type}`}
                onClick={() => this.handleClick(item, index)}
              >
                {
                  // type === 'liveType' && item.name.slice(5, 6) > 0 && activeKey !== index
                  //     ?
                  //     <span>
                  //         {item.name.slice(0, 4)}
                  //         <span style={{ color: 'red' }} > {item.name.slice(4)} </span>
                  //     </span>
                  //     :
                  item.name
                }
              </div>
            );
          })
        );
    }
  }
  render() {
    const { type } = this.state;
    return (
      <div className="tab-list">
        <div className={`transition ${type}`}>
          <pre className={`itemName ${type}`}>{types[type]}:</pre>
          <div className="clear">{this.renderTemplate()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isOperation: state.GloabalCreateSync.isOperation,
});

export default connect(mapStateToProps)(Tablist);
