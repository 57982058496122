/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
// 站点资源添加
import React from 'react';
import { connect } from 'react-redux';
import { AJTextField } from '../../../components/AJInput';
import {
  Form,
  Modal,
  Row,
  Col,
  Select,
  Button,
  Pagination,
  Empty,
  Cascader,
  Checkbox,
  message,
} from 'antd';
import {
  getSiteResources,
  getPreviewResources,
  getResourcesDetail,
} from '../../../redux/action/lesson.action';
import { getChaptersTree } from '../../../redux/action/base.action';
import { FormComponentProps } from 'antd/lib/form';
import { getStudySectionId } from '../../../util';

import PreviewMaterial from './previewMaterial';

import './style.less';

interface P extends FormComponentProps {
  visible?: boolean;
  cancel: () => void;
  title: string; // 标题
  siteResources: any; // 站点资源数据
  cbackFun?: (data: any) => void;
  resourcesType: string; // 资源类型 material -- 资料 video --- 视频
  subjectId: []; // 学科id
  textBooks: any; // 教材
  chaptersList: any; // 章节列表
  category: number[]; // 资料类型
}
interface S {
  pageSize: number;
  pageNumber: number;
  searchData: any; // 搜索数据
  current: number; // 当前展开的资源
  isChaptersShow: boolean;
  fileList: any;
  resourcesDetail: any; // 资源详情
  previewUrl: string; // 预览路径
  previewUrlFileId: string; // 预览文件id
  previewPlayId: string | number; // 预览视频id -- 腾讯云
  previewVisible: boolean; // 预览显示控制
  previewTitle: string; // 预览标题
  extension: string; // 资源后缀
}
// 可预览的类型
const previewType = 'ppt,pptx,jpg,png,mp3,wma,wav,mp4,swf,xls,xlsx,doc,docx,pdf';

class AddNoDemand extends React.Component<P, S> {
  saveData: any = [];
  constructor(props: P) {
    super(props);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      fileList: [],
      searchData: {},
      current: -1,
      isChaptersShow: true,
      resourcesDetail: '',
      previewUrl: '',
      previewUrlFileId: '',
      previewPlayId: '',
      previewVisible: false,
      previewTitle: '',
      extension: '',
    };
  }
  componentDidMount() {
    this.initData();
  }
  initData = () => {
    const { subjectId, category } = this.props;
    const { pageSize, pageNumber, searchData } = this.state;
    if (!searchData.categories || searchData.categories.length === 0) {
      searchData.categories = category.join(',');
    }
    searchData.schoolSectionId = getStudySectionId();
    getSiteResources({ subjectId, size: pageSize, current: pageNumber, ...searchData });
  };
  // 提交数据
  submit = () => {
    this.props.form.validateFields((error: string) => {
      if (error) {
      }
    });
  };
  // 分页
  pageChange = (page: number, pageSize?: number) => {
    this.setState(
      {
        pageNumber: page,
        current: -1,
      },
      this.initData,
    );
  };
  // 分页数量
  pageSizeChange = (current: number, size: number) => {
    this.setState(
      {
        pageNumber: 1,
        pageSize: size,
        current: -1,
        resourcesDetail: '',
      },
      this.initData,
    );
  };
  // 查询
  search = () => {
    const val = this.props.form.getFieldsValue();
    if (val.chapterId) {
      val.chapterId = val.chapterId[val.chapterId.length - 1];
    }
    const obj: any = {};
    for (const i in val) {
      if (val[i]) {
        obj[i] = val[i];
      }
    }
    this.saveData = [];
    this.setState(
      {
        searchData: obj,
        pageNumber: 1,
        fileList: [],
        current: -1,
        resourcesDetail: '',
      },
      this.initData,
    );
  };
  // 重置
  rest = () => {
    this.saveData = [];
    this.setState(
      {
        searchData: {},
        isChaptersShow: true,
        fileList: [],
        current: -1,
        resourcesDetail: '',
      },
      this.initData,
    );
    this.props.form.resetFields();
  };
  //  资料查看详情
  checkDetail = async (item: any, index: number) => {
    const { current } = this.state;
    if (current !== index) {
      this.setState({
        current: index,
        resourcesDetail: {
          year: item.year,
          materialChapters: item.materialChapters,
          markData: item.markData,
          files: item.files,
        },
      });
    } else {
      this.setState({
        current: -1,
        resourcesDetail: '',
      });
    }
  };
  // 点播查看详情
  checkVideoDetail = async (item: any, index: number) => {
    const { current } = this.state;
    if (current !== index) {
      this.setState({
        current: index,
        resourcesDetail: {
          year: item.year,
          materialChapters: item.materialChapters,
          markData: item.markData,
          files: item.files,
        },
      });
    } else {
      this.setState({
        current: -1,
        resourcesDetail: '',
      });
    }
  };
  // 教材选择
  materialChange = (value: string) => {
    if (value) {
      getChaptersTree({ textBookGuid: value });
    } else {
      this.props.form.setFieldsValue({ chapterId: [] });
    }
    this.setState({
      isChaptersShow: !value,
    });
  };
  // 添加资源
  add = async (item: any) => {
    const { resourcesType } = this.props;
    if (resourcesType === 'video') {
      const data = await getResourcesDetail(item.resourceGuid);
      if (data) {
        // markData
        item.markData = data.markData;
        const obj = addDataFormat(item);
        if (this.props.cbackFun) {
          this.props.cbackFun([obj]);
        }
      }
    } else {
      const data = addDataFormat(item);
      if (this.props.cbackFun) {
        this.props.cbackFun([data]);
      }
    }
    this.props.cancel();
  };
  // 多选框 添加资源
  checkbox = (e: any, item: any) => {
    const { fileList } = this.state;
    const data = this.saveData;
    const $dom = e.target;
    if ($dom.checked) {
      // 添加
      fileList.push(item.resourceGuid);
      this.saveData.push(addDataFormat(item));
      this.setState({
        fileList,
      });
    } else {
      // 移除
      const list = [];
      for (const i in fileList) {
        if (fileList[i] !== item.resourceGuid) {
          list.push(fileList[i]);
        }
      }
      const newData = [];
      for (const i in data) {
        if (data[i].resourceGuid !== item.resourceGuid) {
          newData.push(data[i]);
        }
      }
      this.setState({
        fileList: list,
      });
      this.saveData = newData;
    }
  };
  // 确定批量添加
  addBatch = () => {
    if (this.props.cbackFun) {
      this.props.cbackFun(this.saveData);
    }
    this.props.cancel();
  };
  // 资料预览
  previewMaterial = async (item: any, type: number, fileName: string) => {
    if (previewType.indexOf(item.extension.toLowerCase()) < 0) {
      message.warning('该文件暂不支持预览！');
      return;
    }
    if (!item.fileId) {
      message.warning('该资源不存在！');
      return;
    }
    const data = await getPreviewResources(item.fileId, type, encodeURIComponent(fileName));
    if (data) {
      // let url = '';
      let playId = '';
      if (data.playId) {
        playId = data.playId;
      }
      // if (data.cdnUrl) {
      //   url = data.cdnUrl;
      // }

      this.setState({
        previewVisible: true,
        previewUrl: data.cdnUrl || data.url,
        previewUrlFileId: data.fileId || '',
        previewPlayId: playId,
        previewTitle: item.fileName,
        extension: item.extension.toLowerCase(),
      });
    }
  };
  // 关闭弹出
  cancel = () => {
    this.setState({
      previewVisible: false,
      previewUrl: '',
    });
  };
  render() {
    const {
      current,
      isChaptersShow,
      fileList,
      resourcesDetail,
      previewVisible,
      previewUrl,
      previewUrlFileId,
      previewPlayId,
      previewTitle,
      extension,
    } = this.state;
    let {
      title,
      visible,
      cancel,
      resourcesType,
      siteResources: { data: resData, page, total },
      textBooks,
      chaptersList,
    } = this.props;
    textBooks = addAll(textBooks);
    chaptersList = addAll(chaptersList);
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {previewVisible ? (
          <PreviewMaterial
            resource={{
              extension,
              fileId: previewUrlFileId,
              cdnUrl: previewUrl,
            }}
            extension={extension}
            title={previewTitle}
            visible={previewVisible}
            // previewPlayId={previewPlayId}
            previewUrl={previewUrl}
            cancel={this.cancel}
          />
        ) : (
          ''
        )}
        <Modal
          title={title}
          width={800}
          visible={visible}
          onCancel={cancel}
          onOk={this.submit}
          footer={null}
          maskClosable={false}
          className="AddResources"
          getContainer={() => document.getElementById('ContentComponent')!}
        >
          <div className="AddResources-layout">
            <div className="top">
              <Form>
                <Row>
                  <Col span={8}>
                    <Form.Item {...formLayout} label="资料包名称">
                      {getFieldDecorator('name')(<AJTextField placeholder="请输入资料包名称" />)}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item {...formLayout} label="上传者">
                      {getFieldDecorator('createUserName')(
                        <AJTextField placeholder="请输入上传者名称" />,
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                                    <Form.Item {...formLayout} label="来源">
                                        {
                                            getFieldDecorator('ly')(
                                                <Select placeholder="请选择"></Select>
                                            )
                                        }
                                    </Form.Item>
                                </Col> */}
                </Row>
                <Row>
                  {resourcesType === 'material' ? (
                    <Col span={8}>
                      <Form.Item {...formLayout} label="资料类型">
                        {getFieldDecorator('categories')(
                          <Select allowClear placeholder="请选择">
                            {/* <Select.Option value="1">课堂实录</Select.Option> */}
                            <Select.Option value="2">课件</Select.Option>
                            <Select.Option value="3">素材</Select.Option>
                            <Select.Option value="4">教案</Select.Option>
                            <Select.Option value="5">普通作业</Select.Option>
                            <Select.Option value="6">普通试卷</Select.Option>
                          </Select>,
                        )}
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}
                  <Col span={8}>
                    <Form.Item {...formLayout} label="教材">
                      {getFieldDecorator('materialId')(
                        <Select
                          dropdownClassName="addResourcesSelect"
                          allowClear
                          placeholder="全部"
                          onChange={this.materialChange}
                        >
                          {textBooks.map((item: any) => (
                            <Select.Option value={item.guid} key={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item {...formLayout} label="章节">
                      {getFieldDecorator('chapterId')(
                        <Cascader
                          allowClear
                          options={chaptersList}
                          fieldNames={{ label: 'name', value: 'guid', children: 'children' }}
                          placeholder="全部"
                          disabled={isChaptersShow}
                          changeOnSelect
                          className="addResources_Cascader"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  {resourcesType === 'video' ? (
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.search}>
                        查询
                      </Button>
                      <Button className="default" onClick={this.rest}>
                        重置
                      </Button>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
                {resourcesType === 'material' ? (
                  <Row>
                    <Col span={23} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.search}>
                        查询
                      </Button>
                      <Button className="default" onClick={this.rest}>
                        重置
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Form>
            </div>
            <div className="bottom">
              {/* <Checkbox.Group onChange={this.checkbox} value={fileList}> */}
              {resData.map((item: any, index: number) => (
                <div className="list" key={index}>
                  <div className="list-top">
                    {resourcesType === 'material' ? (
                      <Checkbox
                        onChange={(e) => this.checkbox(e, item)}
                        style={{ marginRight: '5px' }}
                        checked={fileList.indexOf(item.resourceGuid) >= 0}
                      />
                    ) : (
                      ''
                    )}
                    <div>
                      <h4 className="title">
                        <span onClick={() => this.checkDetail(item, index)}>{item.name}</span>
                      </h4>
                      <p
                        className={
                          resourcesType === 'material' ? 'basicInfo materral' : 'basicInfo onDemand'
                        }
                      >
                        <span>上传者：{item.createUserName}</span>
                        {resourcesType === 'material' ? (
                          <span>资料类型：{getCategory(item.category)}</span>
                        ) : (
                          ''
                        )}
                        <span>来源：站点内资源</span>
                        <span>学年：{item.schoolYear}</span>
                      </p>
                    </div>
                    <ul>
                      {resourcesType === 'material' ? (
                        <li>
                          <span onClick={() => this.checkDetail(item, index)}>
                            {index === current ? '收起' : '查看'}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}
                      {resourcesType === 'video' ? (
                        <li>
                          <span onClick={() => this.checkDetail(item, index)}>
                            {index === current ? '收起查看' : '展开查看'}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}

                      <li>
                        <span className="line" onClick={() => this.add(item)}>
                          添加此资料包
                        </span>
                      </li>
                    </ul>
                  </div>
                  {
                    // 点播视频
                    resourcesType === 'video' && index === current ? (
                      <div className="list-content">
                        <div className="list-content-top">
                          <span className="one year">
                            年份：{resourcesDetail && resourcesDetail.year}
                          </span>
                          <span className="two">
                            MP4：
                            {resourcesDetail &&
                              getVal(resourcesDetail.files, 'mp4', this.previewMaterial)}
                          </span>
                          <span className="two">
                            ocrf：
                            {resourcesDetail && getVal(resourcesDetail.files, 'ocrf,crf,crf2')}{' '}
                          </span>
                          {/* <span className="one">
                            实录标记：
                            {resourcesDetail &&
                            resourcesDetail.markData &&
                            resourcesDetail.markData.length > 0
                              ? '有'
                              : '无'}
                          </span> */}
                        </div>
                        {resourcesDetail.materialChapters &&
                          resourcesDetail.materialChapters?.length > 0 && (
                            <div className="list-content-content">
                              <span>教材章节:</span>
                              <div className="material-list">
                                {resourcesDetail &&
                                  resourcesDetail.materialChapters &&
                                  resourcesDetail.materialChapters.map(
                                    (item: any, index: number) => (
                                      <div key={index}>{item.chapterName}</div>
                                    ),
                                  )}
                                {/* <div>只展示一行，教材名称，数学北师大版七年级上册 / 章节名称，认识一元一次方程1</div>
                                      <div>只展示一行，教材名称，数学北师大版七年级上册 / 章节名称，认识一元一次方程1</div> */}
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      ''
                    )
                  }
                  {
                    // 资料
                    resourcesType === 'material' && index === current ? (
                      <div className="material-content">
                        {resourcesDetail &&
                          resourcesDetail.files &&
                          resourcesDetail.files.map((item: any, index: number) => (
                            <div key={index} className="material-item">
                              <span
                                className="material-txt"
                                onClick={() => this.previewMaterial(item, 0, item.fileName)}
                              >
                                {item.fileName}
                              </span>{' '}
                              <span
                                className="material-btn"
                                onClick={() => this.previewMaterial(item, 0, item.fileName)}
                              >
                                查看
                              </span>
                            </div>
                          ))}
                      </div>
                    ) : (
                      ''
                    )
                  }
                </div>
              ))}
              {resData.length === 0 ? (
                <Empty style={{ height: '200px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                ''
              )}
              {/* </Chec kbox.Group> */}
            </div>
          </div>
          {resData.length > 0 ? (
            <div className="addResourcesPage">
              {resourcesType === 'material' ? (
                <Button type="primary" onClick={this.addBatch} disabled={!(fileList.length > 0)}>
                  批量添加
                </Button>
              ) : (
                ''
              )}
              <Pagination size="small" total={total} current={page} onChange={this.pageChange} />
            </div>
          ) : (
            ''
          )}
        </Modal>
      </div>
    );
  }
}
// 遍历 获取 点播 数据
function getVal(
  data: any = [],
  name: string,
  cback?: (item: any, type: number, fileName: string) => void,
) {
  for (const i in data) {
    if (name.indexOf(data[i].extension.toLowerCase()) >= 0) {
      const obj = data[i].fileName.length > 10 ? { title: data[i].fileName } : '';
      if (name === 'mp4') {
        return (
          <span
            {...obj}
            className="txt_span blue"
            onClick={() => cback && cback(data[i], 0, data[i].fileName)}
          >
            {data[i].fileName}
          </span>
        );
      } else {
        return (
          <span {...obj} className="txt_span">
            {data[i].fileName}
          </span>
        );
      }
    }
  }
  return '无';
}
// 获取资源类型文案
function getCategory(str: string | number) {
  switch (Number(str)) {
    case 2:
      return '课件';
    case 3:
      return '素材';
    case 4:
      return '教案';
    case 5:
      return '普通作业';
    case 6:
      return '普通试卷';
    default:
  }
}
// 教材 和 章节 添加 全部的 选择项
function addAll(data: any) {
  if (data.length > 0 && data[0].guid) {
    data.unshift({ guid: '', id: Date.now(), name: '全部' });
  }
  return data;
}
// 处理添加数据的 格式
const addDataFormat = (item: any) => {
  if (item.files && item.files.length > 0) {
    item.files = item.files.map((item: any, index: number) => {
      item.id = Date.now() + index;
      return item;
    });
  }
  const data = {
    name: item.name,
    category: item.category,
    resourceGuid: item.resourceGuid,
    createUserId: item.createUserId,
    id: String(Date.now()),
    markData: item.markData,
    subjects: item.subjects.map((item: any) => item.subjectId),
    files: item.files,
  };
  return data;
};
const formLayout: any = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};
const mapStateToProps = (state: any) => ({
  siteResources: state.Lesson.siteResources,
  textBooks: [...state.Base.textBooks],
  chaptersList: state.Base.chaptersTree,
});
export default connect(mapStateToProps)(Form.create<P>()(AddNoDemand));
