/*
 * @Author: gouyang
 * @Description: 创建文章资料
 * @Date: 2019-07-16 19:37:08
 */

import React from 'react';
import { connect } from 'react-redux';

import FileList from './fileList';
import ServiceUpload from './serviceUpload';

interface P {
  flieList: any[]; // 资料列表
  getData: (data: any[], status: string) => void; // 获取上传资料
  fileDelCback: (data: any[]) => void; // 删除数据回调
}

interface S {
  isDelUpload: any;
}

class Material extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isDelUpload: false,
    };
  }

  wrapFileDelCback = (data: any[], isDelUpload?: any) => {
    const { fileDelCback } = this.props;
    if (isDelUpload) {
      this.setState({
        isDelUpload,
      });
    }

    fileDelCback(data);
  };

  render() {
    const { flieList, getData } = this.props;
    const { isDelUpload } = this.state;

    return (
      <div className="model" id="means">
        <h4>添加资料</h4>
        <div className="materral">
          <div className="add-materral">
            <span className="add-materral-desc">添加资料</span>
            <ServiceUpload isDelUpload={isDelUpload} updateData={getData} acceptTips />
          </div>
          <div className="add-list">
            <FileList isScorll={false} fileList={flieList} fileDelCback={this.wrapFileDelCback} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  flieList: state.CreateArticle.flieList,
});
const mapDispatchToProps = (dispatch: any) => ({
  // 获取上传资料
  getData: (data: any[], status: string) => {
    dispatch({ type: 'UPLOAD_DATA', payload: { data, status } });
  },
  // 列表 删除时的回调
  fileDelCback: (data: any[]) => {
    dispatch({ type: 'UPLOAD_DATA', payload: { data, status: 'minus' } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Material);
