import React from 'react';
import moment from 'moment';
import { Button, Icon, Tooltip } from 'antd';
import { NormalObj, MyIcon, getMaterialBg } from '../../../../util';
import { ArtScience } from '../../../../util/enum';
import { allowClicking } from '../../../../util/prohibitShow';

interface LessonItemProps {
  data: NormalObj;
  onClick: () => void;
}

function LessonItem(props: LessonItemProps) {
  const {
    grades,
    lessonName,
    highLightLessonName,
    lessonType,
    subjects,
    releaseTime,
    teachers,
    liveState,
    liveTime,
    artScience,
    expireTime,
    live,
  } = props.data;
  const getIcon = (type: number) => {
    switch (type) {
      case 1:
        return 'iconic_live';
      case 2:
        return 'iconic_demand';
      case 3:
        return 'iconic_data';
      default:
        return '';
    }
  };

  const formatText = (list: any) =>
    list.map((item: NormalObj) => item.teacherName || item.subjectName || item.gradeName).join('/');

  const formatHighLightText = (list: any, label: string): string => {
    const highLight = list
      .map((item: NormalObj) => item.highLightSubjectName || item.highLightGradeName)
      .join('/');
    return `${label}：${highLight}`;
  };

  const isClicking = allowClicking(live, lessonType, liveState);
  const warpClick = () => {
    if (isClicking) {
      props.onClick();
    }
  };

  return (
    <div className="lesson-item commom-item" onClick={warpClick}>
      <span className="lesson-item-icon" style={getMaterialBg(lessonType)}>
        <MyIcon className="iconfont" type={getIcon(lessonType)} />
      </span>
      <span className="lesson-item-text">
        <div
          className="lesson-item-name"
          title={lessonName}
          dangerouslySetInnerHTML={{ __html: highLightLessonName }}
        />
        <div className="lesson-item-info">
          <span className="lesson-item-info-teacher overflow" title={formatText(teachers)}>
            授课教师：{formatText(teachers)}
          </span>
          <span
            className="lesson-item-info-grade overflow"
            title={formatText(grades)}
            dangerouslySetInnerHTML={{ __html: formatHighLightText(grades, '年级') }}
          />
          <span
            className="lesson-item-info-subject overflow"
            title={formatText(subjects)}
            dangerouslySetInnerHTML={{ __html: formatHighLightText(subjects, '学科') }}
          />
          {typeof artScience === 'number' && <span>文理科：{ArtScience[artScience]}</span>}
          <span className="lesson-item-info-release">
            发布时间：{moment(releaseTime).format('YYYY/MM/DD HH:mm')}
          </span>
        </div>
        <div className="lesson-item-bottom">
          {typeof expireTime === 'number' && (
            <Tooltip
              overlayClassName="expire-time-user-class"
              title="有效截止时间内查看将会纳入数据统计"
            >
              <span>
                有效截止时间
                <Icon type="info-circle" />：{moment(expireTime).format('YYYY/MM/DD HH:mm')}
              </span>
            </Tooltip>
          )}
        </div>
      </span>
      {
        // 直播状态
        lessonType === 1 && (
          <span className="liveStatus">
            {liveState === 0 ? (
              <span>{moment(liveTime).format('YYYY/MM/DD HH:mm:ss')} 开始直播</span>
            ) : (
              ''
            )}
            {liveState === 1 ? <span>直播中</span> : ''}
            {liveState === 2 ? <span className="liveEnd">已结束</span> : ''}
          </span>
        )
      }

      <Button
        disabled={!isClicking}
        className={`lesson-item-btn ${isClicking && 'green'}`}
        type="primary"
      >
        观看课时
      </Button>
    </div>
  );
}

export default LessonItem;
