import React, { memo } from 'react';
import './index.less';

interface P {
  visible: boolean;
  content: string;
}

function DescriptionDialog(props: P) {
  const { visible, content } = props;

  return (
    <div className={`description-dialog ${visible && 'description-dialog-style'}`}>
      <div className="description-content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

export default memo(DescriptionDialog);
