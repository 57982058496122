import React, { Component } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Icon, Input, Button, Modal, message } from 'antd';
import './login.less';
import * as action from '../../redux/action/login.action';
import { connect } from 'react-redux';
import { mergeProps } from '../../util';
import getWechatLink from '../../config/wechatLink';
import { tokenManager } from 'eastedu-login-sdk';

interface item {
  leagueId: number;
  name: string;
}
interface IProps extends FormComponentProps {
  visible: boolean;
  unionList: item[];
  setLogin: () => void;
  onRef: (param: {}) => void;
  isSwitch?: boolean; // 是否是切换账号
  path?: string;
  buryingPoint?: () => void;
}
class Login extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      alertVisible: false,
    };
    this.validate = this.validate.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { isSwitch } = this.props;
    if (!(isSwitch === true)) {
      this.wetChatLogin();
    }
    // 禁止浏览器后退
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', this.forbidBack);
  }
  componentWillUnmount() {
    window.removeEventListener('popstate', this.forbidBack);
  }
  // 防止后退
  forbidBack = () => {
    const { alertVisible } = this.state;
    if (alertVisible) {
      this.setState({
        alertVisible: false,
      });
    }
    window.history.pushState(null, '', document.URL);
  };
  // 微信登录
  wetChatLogin() {
    const userInfo = window.localStorage.getItem('userInfo') || '';
    const token = userInfo && JSON.parse(userInfo).access_token;
    if (token) {
      action.getUnionList((data: item[]) => {
        if (data.length === 1) {
          this.handleRedirect(data[0]);
        } else {
          this.setState({ alertVisible: true });
        }
      });
    }
  }

  successResponse = (data: item[]) => {
    // const { isSwitch } = this.props;
    // if (isSwitch === true) {
    //     window.localStorage.removeItem('unionId');
    // }
    this.props.setLogin();
    if (data.length === 1) {
      this.handleRedirect(data[0]);
    } else {
      this.setState({ alertVisible: true });
    }
  };

  switchUser = (data: item[]) => {
    if (data.length === 1) {
      this.handleRedirect(data[0]);
    } else {
      this.setState({ alertVisible: true });
    }
  };

  cancelConfirm = (e: React.MouseEvent) => {
    e.stopPropagation();
    const unionId = window.localStorage.getItem('unionId') || '';
    if (!unionId) {
      (window as any).localStorage.removeItem('userInfo');
    }
    switch (this.props.path) {
      case 'PageIndex':
        window.location.href = getWechatLink(); // 非原流程首页
        break;
      case 'OriginPageIndex':
        this.props.setLogin();
        break;
      default:
        this.alertOk();
        break;
    }
  };

  alertOk = () => {
    this.setState({ alertVisible: false });
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        action.login(
          values,
          (res: any) => {
            window.localStorage.setItem('userInfo', JSON.stringify(res.data));
            // action.getUser();
            action.getUnionList(this.successResponse);
          },
          (err: any) => {
            err && message.error(err.data.message ? err.data.message : err.data);
          },
        );
      }
    });
  };
  handleKeyDown(e: any) {
    return e.target.value.replace(/\D/g, '').substring(0, 11);
  }
  handlePassWordPaste(e: React.ClipboardEvent) {
    e.preventDefault();
  }
  handleRedirect = (item: item) => {
    const { buryingPoint } = this.props;
    this.setState({
      alertVisible: false,
    });
    window.localStorage.setItem('unionId', String(item.leagueId));
    window.localStorage.removeItem('ROOTTYPE');
    // 登陆埋点
    buryingPoint && buryingPoint();
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  };
  validate(rule: any, value: any, callback: any) {
    // let _reg_ = ^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$;
    const _reg_ = /^[0-9]{11}$/;
    if (!value || !value.replace(/\D/g, '')) {
      callback(['请输入手机号！']);
    } else if (!_reg_.test(value)) {
      callback(['手机号格式不正确！']);
    }
    callback();
  }
  render() {
    const { alertVisible } = this.state;
    const { form, unionList, visible } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        {visible && (
          <Modal
            title="登录"
            width={400}
            footer={false}
            keyboard={false}
            visible={visible}
            maskClosable={false}
            wrapClassName="login-wrap"
          >
            <div className="Login-component">
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator('username', {
                    initialValue: '',
                    rules: [{ validator: this.validate }],
                    getValueFromEvent: (e) => this.handleKeyDown(e),
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      className="login-form-item"
                      placeholder="手机号"
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('password', {
                    initialValue: '',
                    rules: [{ required: true, message: '请输入您的密码！' }],
                  })(
                    <div>
                      <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        className="login-form-item"
                        placeholder="密码"
                        onPaste={this.handlePassWordPaste}
                      />
                    </div>,
                  )}
                </Form.Item>
                <div className="ant-row">
                  <Button type="primary" htmlType="submit" className="login-button">
                    登 录
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        )}
        {alertVisible && (
          <div className="ant-modal-mask wrapper-modal">
            <div className="content">
              <div className={`list ${unionList.length < 4 ? 'center' : ''}`}>
                {unionList &&
                  unionList.map((item, index) => (
                    <div
                      className={(index + 1) % 4 === 0 ? 'item mr0' : 'item'}
                      key={index}
                      onClick={() => this.handleRedirect(item)}
                    >
                      <div className="name">{item.name}</div>
                    </div>
                  ))}
              </div>
            </div>
            <span className="hide-Modal" onClick={(e) => this.cancelConfirm(e)}>
              x
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { ...state.Login };
};

export default connect(mapStateToProps, mergeProps)(Form.create<IProps>()(Login));
