/* eslint-disable no-redeclare */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getCheckExist } from '../../redux/action/userCenter.action';
import enterLiveRoom from '../../util/enterLiveRoom';
import TrackRequest from '../../lib/trackRequest';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import './living.less';

interface LessonType {
  courseId: string;
  lessonType: number;
  liveState: number;
  lessonName: string;
  lessonId: string;
  startTime: number;
  endTime: number;
}

interface LesssonItem {
  item: LessonType;
  isActive?: boolean;
  dataSource: any[];
}

interface LivingProps {
  dataSource: LessonType[];
}

const getstatusText = (startTime: number) => {
  return startTime < new Date().getTime() ? '直播中' : '即将开始';
};

// 拼接课时名字，解决名字过短不滚动
const splitLessonName = (name: string) => {
  if (name.length >= 60) {
    return name;
  }
  const _name: any[] = [];
  for (let i = 0; i < 12; i++) {
    _name.push(
      <span className="nameItem" key={i}>
        {name}
      </span>,
    );
  }
  return <>{_name}</>;
};

// 改变全部slide的样式
const changeStyle = (lessonid: string, status: string) => {
  // 状态只会从即将开始变成已开始
  if (status === '即将开始') {
    return;
  }
  // 获取所有的slide, 遍历修改
  const allSlides = document.querySelectorAll(`.swiper-wrapper .swiper-slide`);
  if (allSlides.length > 0) {
    allSlides.forEach((ele: Element) => {
      const eleId = ele.getAttribute('data-lessonid');
      if (eleId && eleId === lessonid) {
        const item = ele.getElementsByClassName('lessonItem')[0];
        if (!item.classList.contains('doing')) {
          item.classList.remove('will');
          item.classList.add('doing');
          item.getElementsByClassName('status')[0].innerHTML = '直播中&nbsp; :';
        }
      }
    });
  }
};

const LesssonItem: React.FC<LesssonItem> = (props) => {
  const { isActive = false, dataSource } = props;
  const { lessonName, lessonId, startTime } = props.item;
  const [statusText, setStatuTest] = useState<string>(getstatusText(startTime));

  const interval = useRef<NodeJS.Timer | undefined>(undefined);
  const interval2 = useRef<NodeJS.Timer | undefined>(undefined);
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const timeout2 = useRef<NodeJS.Timeout | undefined>(undefined);
  const curEle = useRef<HTMLElement | null>(null);

  // 文字滚动动画
  const animationFn = useCallback(() => {
    const width: number = (curEle.current as HTMLElement).offsetWidth;
    let _offset = 0;
    interval.current = setInterval(() => {
      if (
        (statusText === '即将开始' && width - _offset <= 230) ||
        (statusText === '直播中' && width - _offset <= 245)
      ) {
        _offset = 0;
        (curEle.current as HTMLElement).style.transform = `translateX(${_offset}px)`;
        window.clearInterval(Number(interval.current));
        timeout.current = setTimeout(() => {
          animationFn();
        }, 500);
        return;
      }
      _offset += 1;
      (curEle.current as HTMLElement).style.transform = `translateX(-${_offset}px)`;
    }, 16);
  }, [statusText]);

  useEffect(() => {
    // 改变状态
    if (!isActive) return;
    changeStyle(lessonId, getstatusText(startTime));

    // 如果显示的是当前item，判断文字是否需要滚动
    const _element = document.querySelectorAll(`.swiper-slide-active #text_${lessonId}`);
    curEle.current = _element.length > 0 ? (_element[0] as HTMLElement) : null;
    if (curEle.current && curEle.current.offsetWidth > 275) {
      timeout2.current = setTimeout(() => {
        animationFn();
      }, 700);
    }
    return () => {
      if (curEle.current) {
        curEle.current.style.transform = `translateX(0px)`;
      }
      window.clearInterval(Number(interval.current));
      window.clearTimeout(Number(timeout.current));
      window.clearTimeout(Number(timeout2.current));
    };
  }, [isActive, lessonId, animationFn, startTime]);

  useEffect(() => {
    if (dataSource.length === 1) {
      if (getstatusText(startTime) === '直播中') return;
      // 如果只有一个课时单独处理
      interval2.current = setInterval(() => {
        if (getstatusText(startTime) === '直播中') {
          window.clearInterval(Number(interval2.current));
        }
        setStatuTest(getstatusText(startTime));
      }, 10);
    }

    return () => {
      window.clearInterval(Number(interval2.current));
    };
  }, [dataSource, startTime]);

  return (
    <div className={`lessonItem ${statusText === '直播中' ? 'doing' : 'will'}`}>
      <div className="status">{statusText}&nbsp; :</div>
      <div className="name">
        <span id={`text_${lessonId}`} className="text">
          {splitLessonName(lessonName)}
        </span>
      </div>
    </div>
  );
};

const Living: React.FC<LivingProps> = (props) => {
  const { dataSource } = props;
  const swiper = useRef<any>(null);
  const [activeLessonId, setActiveLessonId] = useState<string | null>('');

  // 查看详情
  const checkDetail = async (lesson: any) => {
    const flg = await getCheckExist(lesson.lessonId);
    if (flg) {
      // 观看课时埋点
      TrackRequest({
        name: 'watch_period',
        data: {
          course_id: lesson.courseId,
          period_id: lesson.lessonId,
          period_type: lesson.lessonType,
          watch_period_entrance: 4, // 从header的提示进入
        },
      });
      enterLiveRoom(lesson.lessonId, '2');
    }
  };

  useEffect(() => {
    if (!dataSource || dataSource.length === 0) return;
    setActiveLessonId(dataSource[0].lessonId);
    if (swiper.current) {
      // swiper.current.update();
      swiper.current.destroy(true, true);
    }
    if (document.getElementById('swiper-container')) {
      swiper.current = new Swiper('#swiper-container', {
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        direction: 'vertical',
        loop: dataSource.length > 1,
        on: {
          click() {
            if (this) {
              const item = JSON.parse((this as any).clickedSlide.getAttribute('data-item'));
              checkDetail(item);
            }
          },
          transitionStart() {
            const activeSlideEles = document.querySelectorAll('.swiper-slide.swiper-slide-active');
            setActiveLessonId(
              activeSlideEles.length > 0 ? activeSlideEles[0].getAttribute('data-lessonid') : '',
            );
          },
          transitionEnd() {},
        },
      });
    }

    return () => {
      swiper.current.destroy(true, true);
    };
  }, [dataSource]);

  return (
    <div className="livingContainer">
      <div id="swiper-container" className="swiper-container">
        <div className="swiper-wrapper">
          {dataSource.map((item) => (
            <div
              className="swiper-slide"
              key={item.lessonId}
              data-item={JSON.stringify({
                courseId: item.courseId,
                lessonId: item.lessonId,
                lessonType: item.lessonType,
              })}
              data-lessonid={item.lessonId}
              // data-swiper-autoplay={`${item.lessonName.length > 60 ? '12000' : '10000'}`}
            >
              <LesssonItem
                item={item}
                isActive={activeLessonId === item.lessonId}
                dataSource={dataSource}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Living;
