import COS from 'cos-js-sdk-v5';
import fileAxios from '../lib/fileRequest';

const getSignaturesByMd5 = (md5: string) => {
  return fileAxios(`/v3/cloud/signatures?md5=${md5}&method=get&provider=tencent`, {
    method: 'get',
    headers: {
      Authorization: 'basic 123',
    },
  });
};

export const downloadCosFile = (fileName: string, md5: string, downloadUrl: string) => {
  const browserDownload = (downloadURL: string) => {
    // if (target === TARGET.blank) {
    //   window.open(downloadURL);
    //   onFinished && onFinished();
    // } else {
    //   const iframe = document.createElement('iframe');
    //   iframe.src = downloadURL;
    //   iframe.style.display = 'none';
    //   document.body.appendChild(iframe);
    //   onFinished && onFinished();
    //   setTimeout(() => {
    //     document.body.removeChild(iframe);
    //   }, 5000);
    // }
    const iframe = document.createElement('iframe');
    iframe.src = downloadURL;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  };

  const [, bucket] = /https:\/\/(\S*).cos.ap-chengdu.myqcloud.com/.exec(downloadUrl);
  const [, key] = downloadUrl.split(/https:\/\/\S*.myqcloud.com\//);
  const [, region] = /https:\/\/\S*.\S*\.(\S*)\.myqcloud.com/.exec(downloadUrl);
  const cos = new COS({
    getAuthorization: async (options: any, callback: Function) => {
      try {
        const {
          data: { signature },
        }: any = await getSignaturesByMd5(md5);
        callback(signature);
      } catch (e) {
        console.error('FileDownloader Error: ', e);
      }
    },
  });
  cos.getObjectUrl(
    {
      Bucket: bucket,
      Key: key,
      Region: region,
      Sign: true,
    },
    (err: any, { Url }: any) => {
      console.log('Url: ', Url);
      if (err) {
        console.error(`FileDownloader Error: ${err}`);
        return;
      }
      const downloadURL = `${
        Url + (Url.indexOf('?') > -1 ? '&' : '?')
      }&response-content-type=application%2Foctet-stream&response-content-disposition=attachment%3B%20filename%3D${encodeURIComponent(
        encodeURIComponent(fileName),
      )}`;
      browserDownload(downloadURL);
    },
  );
};
