/*
 * @Descripttion: 直播回看
 * @Author: gouyang
 * @Date: 2021-01-21 14:54:13
 * @LastEditors: gouyang
 * @LastEditTime: 2021-03-26 17:23:53
 */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { RecordPlayingTime, Env, InitUserParams } from 'record-playing-time';
import livingGif from '../../../../assets/living.gif';
import { PlaybackInfo, N, ListsInfo, SelectedInfo } from '../../interface';
import { getSearchParams, getUsername, getUserId, getToken, getLeagueId } from '../../../../util';
import AxiosWrap from '../../../../lib/request';
import ResourceViewCom from '../../../../components/media/resourceView/resourceView';
import apiConfig from '../../../../config/api';
import './index.less';

function getEnv(): Env {
  const env = (window as any).__UNION_CENTER_ENV__;
  switch (env) {
    case 'DEV':
      return 'dev';
    case 'TEST':
      return 'test';
    case 'STAGE':
      return 'pro';
    case 'PROD':
      return 'pro';
    default:
      return 'dev';
  }
}

const recordPlayingTime: RecordPlayingTime = new RecordPlayingTime(getEnv());
interface P {
  playbackInfo: PlaybackInfo;
}

function PlayBack(props: P) {
  const { playbackInfo } = props;
  const playingTimeParams = useRef<InitUserParams>({
    appToken: getToken(),
    sessionId: 0, // 服务器时间
    orgId: getLeagueId(),
    resourceId: getSearchParams().id,
    clientId: 0, // 客户端ID 0-WEB, 1-小程序
    userId: getUserId(), // 用户ID
    source: 1, // 数据来源 0-课堂实录，1-直播回看
    fileId: '',
    speed: 1,
  });
  const isPaused = useRef<-1 | 0 | 1>(-1);
  const searchParams = useRef<N>(getSearchParams());
  const providerRef = useRef<number>(-1);
  const [listsInfo, setPlaybackLists] = useState<ListsInfo>({
    playBack: false,
    playBackLists: [],
    provider: -1,
  });
  const [selectedInfo, setSelectedInfo] = useState<SelectedInfo>({
    i: 0,
    url: '',
  });
  const [fileType, setFileType] = useState<string>('');

  const playBackModal = useCallback(() => {
    const { ignoreClose } = searchParams.current;
    Modal.info({
      title: '回看视频暂未生成！',
      onOk() {
        if (!ignoreClose) {
          (window as any).history.back();
        }
      },
    });
  }, []);

  const initRecordingInfo = useCallback((fileId: string) => {
    recordPlayingTime.clearStatus();
    playingTimeParams.current = { ...playingTimeParams.current, fileId };
    recordPlayingTime.initUpdateParams(playingTimeParams.current);
  }, []);

  const ceateBjyPlayBackUrl = useCallback((url: string): string => {
    const userName = getUsername();
    const userId = getUserId();
    return `${url}&user_name=${userName}&user_number=${userId}`;
  }, []);

  const ceate263PlayBackUrl = useCallback((url: string, token: string): string => {
    const userName = encodeURIComponent(getUsername());
    const userId = 1000000000 + getUserId();
    return `${url}?nickname=${userName}&token=${token}&uid=${userId}`;
  }, []);

  const createLocalPlayBackUrl = useCallback(
    async (fileId: string, fileName: string) => {
      initRecordingInfo(fileId);
      const { data, status } = await AxiosWrap({
        url: `/files/${fileId}/preview?type=${0}&fileName=${encodeURIComponent(fileName)}`,
      });
      if (status === 200 && data) {
        setFileType(data.extension);
        return data.cdnUrl;
      }
      return '';
    },
    [initRecordingInfo],
  );

  const ceatePlayBackUrl = useCallback(
    (info: any): Promise<string> => {
      const { playUrl, url, token, name, fileId } = info;

      if (providerRef.current === 0) {
        return Promise.resolve(ceate263PlayBackUrl(url, token));
      }
      if (providerRef.current === 1) {
        return Promise.resolve(ceateBjyPlayBackUrl(playUrl));
      }
      if (providerRef.current === 2) {
        return Promise.resolve(createLocalPlayBackUrl(fileId, name));
      }
      return ((): never => {
        throw new Error('providerRef.current is not right type');
      })();
    },
    [ceateBjyPlayBackUrl, ceate263PlayBackUrl, createLocalPlayBackUrl],
  );

  const enterPlayBack = useCallback(
    async (info: any[], provider: number) => {
      if (!info || (info && info.length < 1)) {
        playBackModal();
        return;
      }
      setPlaybackLists({
        playBack: true,
        playBackLists: info,
        provider,
      });
      setSelectedInfo({
        i: 0,
        url: await ceatePlayBackUrl(info[0]),
      });
    },
    [playBackModal, ceatePlayBackUrl],
  );

  // 创建回看
  const cratePlayBack = useCallback(
    (playBack: PlaybackInfo) => {
      const { provider, baijiayunPlaybacks, genseePlaybacks, easteduPlaybacks } = playBack;
      providerRef.current = provider;
      if (provider === 0) {
        // 展示互动
        enterPlayBack(genseePlaybacks, provider);
      } else if (provider === 1) {
        // 百家云
        enterPlayBack(baijiayunPlaybacks, provider);
      } else if (provider === 2) {
        // 本地直播回看
        enterPlayBack(easteduPlaybacks, provider);
      }
    },
    [enterPlayBack],
  );

  const onPlayBackChange = useCallback(
    async (review: any, i: number) => {
      if (i !== selectedInfo.i) {
        setSelectedInfo({
          i,
          url: await ceatePlayBackUrl(review),
        });
      }
    },
    [selectedInfo, ceatePlayBackUrl],
  );

  const handleMedia = useCallback((media: any) => {
    media.on('play', () => {
      recordPlayingTime.startPlayTime();
    });
    media.on('pause', () => {
      isPaused.current = 1;
      recordPlayingTime.stopPlayTime();
    });
    media.on('timeupdate', () => {
      recordPlayingTime.getStartAndEnd(media.currentTime(), media.duration());
    });

    // 播放速率更改
    media.on('ratechange', () => {
      recordPlayingTime.updateSpeed(media.playbackRate());
    });
  }, []);

  useEffect(() => {
    const { provider } = playbackInfo;
    if (provider !== -1) {
      cratePlayBack(playbackInfo);
    }
  }, [playbackInfo, cratePlayBack]);

  useEffect(() => {
    const f = async () => {
      const {
        data: { unixtime },
      } = await AxiosWrap({ baseURL: apiConfig.ip });
      Object.assign(playingTimeParams.current, { sessionId: unixtime * 1000 });
    };

    f();
  }, []);

  useEffect(() => {
    return () => {
      recordPlayingTime.clearStatus();
    };
  }, []);

  return (
    <>
      {listsInfo.playBack && (
        <div className="play-back">
          {listsInfo.provider !== 2 ? (
            <iframe title="iframe" frameBorder="0" src={selectedInfo.url} />
          ) : (
            <div className="media-container">
              {selectedInfo.url && (
                <ResourceViewCom
                  autoPlay={false}
                  mediaType={fileType}
                  mediaAddress={selectedInfo.url}
                  playerCallBack={handleMedia}
                />
              )}
            </div>
          )}
          {listsInfo.playBackLists.length > 1 && (
            <div className="review-bar">
              {listsInfo.playBackLists.map((review: any, i: number) => (
                <div
                  className={`review-item ${selectedInfo.i === i && 'item-selected'}`}
                  onClick={() => onPlayBackChange(review, i)}
                  key={i}
                >
                  {selectedInfo.i === i && <img src={livingGif} alt="" />}
                  {review.name || review.subject}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default memo(PlayBack);
