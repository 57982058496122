/* eslint-disable @typescript-eslint/no-this-alias */
// 创建课程/ 编辑课程基本信息
import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Select, Popover, Icon, Input, message, Progress, Tabs } from 'antd';
import { AJTextField } from '../../components/AJInput';
import { FormComponentProps } from 'antd/lib/form';
import {
  addCourse,
  getCourse,
  editCourse,
  getSystemCoverPicture,
  getPersonalCoverPicture,
} from '../../redux/action/course.action';
import { getSearchParams, stringifySearchParams, MyIcon } from '../../util';
import fileServiceUpload from '../../util/fileServiceUpload';
import Base64ToFile from '../../util/base64ToFile';
import { systemCoverPicture } from '../../lib/api';
import TagSelect from '../../components/TagSelect';
import Description from './components/Description';
import Introduction from './components/Introduction';
import getBrief from '../../lib/getBrief';

import './style.less';

const { TabPane } = Tabs;

interface ColumnListData {
  data: any[];
  page: number;
  size: number;
  hasData: boolean;
}
interface P extends FormComponentProps {
  history?: any;
  courseId?: string; // 课程id
  courseInfo: any; // 课程信息
  isRequested: boolean;
  isRequestError: boolean;
  upadateErrorInfo: () => void;
  settingPaginationParams: (page: number, listsType: string) => void;
  columnListInfo: { [key: string]: ColumnListData };
  clearPictrueListsData: () => void;
  finishRquest: () => void;
  completeRequest: boolean;
}
interface S {
  visible: boolean;
  coverUrl: string;
  columnList: any; // 所属栏目
  uploadMask: boolean; // 上传图片遮罩层
  uploadPercent: number; // 上传图片进度
  uploadImgFlag: boolean; // file input开关
  uploadInputDisabled: boolean; // 是否禁用本地上传按钮
  currentUploadImgUrl: string; // 当前本地上传图片地址
  popoverTitleActiveKey: string; // tab当前激活的key
}
class CreateCourse extends React.PureComponent<P, S> {
  private uploadImage: any;
  private fileServiceUploadCallback: () => void; // 文件上传回调函数
  private triggerNode: Element | null; // Popover中滚动元素
  private isLocalUpload = false; // 是否是本地上传
  private editorObj: any = null;
  private pureWordCount = 0;
  constructor(props: P) {
    super(props);
    this.state = {
      visible: true,
      coverUrl: '',
      columnList: [],
      uploadMask: false,
      uploadPercent: 0,
      uploadImgFlag: false,
      uploadInputDisabled: false,
      currentUploadImgUrl: '',
      popoverTitleActiveKey: 'system',
    };
    this.uploadImage = React.createRef();
    this.fileServiceUploadCallback = () => {};
    this.triggerNode = null;
  }
  componentDidMount() {
    const { courseId } = this.props;
    this.init();
    if (courseId) {
      // 编辑
      // 根据id 获取 课程信息
      getCourse(courseId);
    } else {
      this.getSystemFirstPictrue();
    }
  }
  // 获取系统图片的第一张图
  async getSystemFirstPictrue() {
    const { data } = await systemCoverPicture({ page: 0, size: 1 });
    if (data._embedded && data._embedded.courseCovers.length > 0) {
      this.setState({
        coverUrl: data._embedded.courseCovers[0].coverUrl,
      });
    }
  }
  // 初始化操作
  init = () => {
    // 获取栏目数据
    this.getCourseColumn();
  };
  UNSAFE_componentWillReceiveProps(nexrProps: any) {
    const { courseId } = nexrProps;
    // 判断是否取到课程内容
    if (courseId && nexrProps.courseInfo && nexrProps.courseInfo !== this.props.courseInfo) {
      const { courseInfo } = nexrProps;

      const data = {
        brief: getBrief(courseInfo.brief),
        courseName: courseInfo.courseName,
        labels: courseInfo.labels
          ? [...courseInfo.labels].map((lable: any, i: number) => ({
              name: lable.labelName,
              id: lable.labelId,
              key: new Date().getTime() + i,
            }))
          : [],
        description: courseInfo.description,
      };
      if (this.editorObj) {
        this.editorObj.txt.html(courseInfo.description);
      }
      this.setState({
        coverUrl: courseInfo.coverUrl,
      });
      this.props.form.setFieldsValue(data);
    }
  }

  getEditorObj = (obj: any) => {
    this.editorObj = obj;
  };

  getPureWordCount = (l: number) => {
    this.pureWordCount = l;
  };

  // 获取课程所属栏目
  getCourseColumn = () => {
    const str = decodeURI(window.location.search);
    if (str.length === 0) {
      return;
    }
    const obj: any = getSearchParams(str);
    this.setState({
      columnList: [obj],
    });
    this.props.form.setFieldsValue({ menuId: obj.id });
  };

  // 打开封面图弹窗
  openImageModal = () => {
    const { popoverTitleActiveKey } = this.state;
    const { clearPictrueListsData, finishRquest } = this.props;

    finishRquest();
    clearPictrueListsData();
    this.setState({
      visible: true,
    });
    window.setTimeout(() => this.initializePictureLists(popoverTitleActiveKey), 0);
  };

  // 本地上传文件
  uploadImageData() {
    this.setState(
      {
        uploadImgFlag: true,
        uploadPercent: 0,
      },
      () => this.uploadImage.current.input.click(),
    );
  }

  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err || (values.brief && values.brief.pureText.length > 500)) {
        return;
      }
      const { courseId } = this.props;
      const { coverUrl } = this.state;
      values.coverUrl = coverUrl;
      values.courseName = values.courseName.trim();
      values.saveCover = this.isLocalUpload;
      values.labels = values.labels
        ? values.labels.map((label: any) => ({ labelId: label.id, labelName: label.name }))
        : [];
      values.brief = values.brief ? JSON.stringify(values.brief) : '';
      if (this.pureWordCount === 0) values.description = '';
      // 判断课程说明字数
      if (this.pureWordCount > 500) return;
      if (courseId) {
        // 编辑
        editCourse(values, courseId);
      } else {
        addCourse(values, this.successBack);
      }
      this.isLocalUpload = false;
    });
  };

  //  添加或者编辑成功后跳转列表页面
  successBack = () => {
    this.props.history.push(`/navigation${stringifySearchParams(getSearchParams())}`);
  };

  // 监听上传图片变化
  uploadImageChange(e: any) {
    this.setState({ uploadInputDisabled: true });
    const file = e.target.files[0];
    // 上传格式验证
    if (file && file.name) {
      const fileName = file.name.split('.');
      const l = fileName.length;
      const lowerName = fileName[l - 1].toLowerCase();
      if (['png', 'jpg', 'jpeg'].indexOf(lowerName) === -1) {
        this.setState({ uploadInputDisabled: false, uploadImgFlag: false });
        return message.error('上传文件格式错误！');
      }
    }
    if (file.size > 1024 * 1024 * 20) {
      this.setState({ uploadInputDisabled: false, uploadImgFlag: false });
      return message.error('文件大小不能超过20M！');
    }
    if (file.size <= 0) {
      this.setState({ uploadInputDisabled: false, uploadImgFlag: false });
      return message.error('文件大小不能小于0！');
    }
    this.compressPictures(file, file.name, file.type);
  }

  // 压缩图片
  compressPictures(image: any, imageName: string, imageType: string) {
    const self = this;
    const reader = new FileReader();
    // 将图片转换为url路径
    reader.readAsDataURL(image);
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // 图片加载完成后绘制到画布
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        (ctx as any).drawImage(img, 0, 0, img.width, img.height);
        const dataUrl = canvas.toDataURL(imageType, 0.5);
        self.setState({ currentUploadImgUrl: dataUrl, uploadMask: true }); // 更新第二层封面图
        const file = Base64ToFile(dataUrl, imageName);
        self.fileServiceUploadCallback = fileServiceUpload(file, self.fileUpload);
      };
    };
  }

  // 文件上传方法
  fileUpload = (data: any) => {
    let {
      uploadMask,
      uploadPercent,
      uploadImgFlag,
      coverUrl,
      uploadInputDisabled,
      currentUploadImgUrl,
    } = this.state;
    if (data.status === 'start') {
      // 开始上传
      // uploadMask = true;
    } else if (data.status === 'progress') {
      // 上传中
      uploadPercent = data.percent * 100;
    } else if (data.status === 'done') {
      // 上传完成
      uploadMask = false;
      uploadImgFlag = false;
      uploadInputDisabled = false;
      coverUrl = data.data.path;
      message.success(data.message);
      this.isLocalUpload = true;
    } else if (data.status === 'error') {
      // 上传失败
      uploadMask = false;
      uploadImgFlag = false;
      uploadInputDisabled = false;
      currentUploadImgUrl = '';
      message.error(data.message);
    } else if (data.status === 'cance') {
      // 取消上传
      uploadMask = false;
      uploadImgFlag = false;
      uploadInputDisabled = false;
      currentUploadImgUrl = '';
      message.error(data.message);
    }
    this.setState({
      coverUrl,
      uploadMask,
      uploadImgFlag,
      uploadPercent: Math.round(uploadPercent),
      uploadInputDisabled,
      currentUploadImgUrl,
    });
  };

  // 取消图片上传
  cancelImageUpload() {
    this.fileServiceUploadCallback();
  }

  // 选择图片
  selectImg = (item: any) => {
    this.setState(
      {
        currentUploadImgUrl: '',
        coverUrl: item,
        visible: false,
      },
      () => {
        this.isLocalUpload = false;
        this.setState({
          visible: true,
        });
      },
    );
  };

  // 点击重试
  clickRetry() {
    const { popoverTitleActiveKey } = this.state;
    const { upadateErrorInfo } = this.props;

    upadateErrorInfo();
    this.getPictureLists(popoverTitleActiveKey);
  }

  // 滚动事件
  onScroll(currentKey: string, triggerNode: Element) {
    (triggerNode as any).onscroll = (e: any) => {
      const { page, hasData } = this.getCurrentListsInfo(currentKey);

      if (e.target.scrollTop >= 140 * (page + 1) && hasData) {
        const { settingPaginationParams } = this.props;
        settingPaginationParams(page + 1, currentKey);
        this.getPictureLists(currentKey);
      }
    };
  }

  // 获取图片列表dom元素
  getPictrueListsNode() {
    const parentNode = document.querySelector('.coverPicturePopover');
    this.triggerNode = parentNode ? parentNode.querySelector('.ant-popover-inner-content') : null;
  }

  // 重置图片列表滚动距离
  resettingScrollDistance(triggerNode: Element) {
    triggerNode.scrollTo(0, 0);
  }

  // 获取当前列表信息
  getCurrentListsInfo(currentKey: string): ColumnListData {
    const { columnListInfo } = this.props;
    const result = columnListInfo[currentKey];

    return result;
  }

  // 获取图片列表数据
  getPictureLists(currentKey: string) {
    const { page, size } = this.getCurrentListsInfo(currentKey);

    if (currentKey === 'system') {
      getSystemCoverPicture({ page, size, currentKey });
    } else if (currentKey === 'personal') {
      getPersonalCoverPicture({ page, size, currentKey });
    }
  }

  // 添加dom元素滚动事件
  addDomeListener(currentKey: string): void {
    if (this.triggerNode) {
      this.resettingScrollDistance(this.triggerNode);
      this.onScroll(currentKey, this.triggerNode);
    }
  }

  // 初始化图片列表
  initializePictureLists(currentKey: string): void {
    const { settingPaginationParams, upadateErrorInfo } = this.props;

    upadateErrorInfo();
    this.getPictrueListsNode();
    settingPaginationParams(0, currentKey);
    this.getPictureLists(currentKey);
    this.addDomeListener(currentKey);
  }

  // 切换tab初始化列表
  switchTablInitializePictureLists(currentKey: string): void {
    const { settingPaginationParams, upadateErrorInfo, clearPictrueListsData } = this.props;

    upadateErrorInfo();
    clearPictrueListsData();
    settingPaginationParams(0, currentKey);
    this.getPictureLists(currentKey);
    this.addDomeListener(currentKey);
    if (this.triggerNode) {
      this.resettingScrollDistance(this.triggerNode);
    }
  }

  // 获取当前tab激活key
  getTabsActiveKey(currentKey: string): void {
    const { completeRequest } = this.props;

    if (!completeRequest) return;

    this.switchTablInitializePictureLists(currentKey);
    this.setState({
      popoverTitleActiveKey: currentKey,
    });
  }

  // 渲染图片列表dom元素
  renderPictrueDomElement(
    data: any[],
    hasData: boolean,
    isRequested: boolean,
    isRequestError: boolean,
  ): JSX.Element {
    if (data.length === 0 && isRequestError) {
      // 列表为空时请求发生错误
      return (
        <li
          className="loading-li loading-error loading-li-start"
          style={{
            display: isRequestError ? 'block' : 'none',
          }}
        >
          加载失败，请点击
          <i onClick={() => this.clickRetry()}>重试</i>
        </li>
      );
    }
    if (hasData && data.length === 0) {
      // 存在数据，请求中
      return (
        <li className="loading-li-start loading-li">
          <Icon type="loading" className="cover-picture-loading" />
          <span className="cover-text-loading">正在加载...</span>
        </li>
      );
    }
    if (hasData && data.length > 0) {
      return (
        <React.Fragment>
          {data.map((item: any, index: number) => (
            <li onClick={() => this.selectImg(item.coverUrl)} key={index}>
              <img alt={item.coverUrl} src={item.coverUrl} />
            </li>
          ))}
          <li
            className="loading-li"
            style={{
              display: !isRequestError ? 'block' : 'none',
            }}
          >
            <Icon type="loading" className="cover-picture-loading" />
            <span className="cover-text-loading">正在加载...</span>
          </li>
          <li
            className="loading-li loading-error"
            style={{
              display: isRequestError ? 'block' : 'none',
            }}
          >
            加载失败，请点击
            <i onClick={() => this.clickRetry()}>重试</i>
          </li>
        </React.Fragment>
      );
    }
    if (!hasData && data.length === 0) {
      // 没有数据
      return <li className="loading-li-start loading-li">暂无数据</li>;
    }
    return (
      <React.Fragment>
        {data.map((item: any, index: number) => (
          <li onClick={() => this.selectImg(item.coverUrl)} key={index}>
            <img alt={item.coverUrl} src={item.coverUrl} />
          </li>
        ))}
      </React.Fragment>
    );
  }

  render() {
    const {
      visible,
      coverUrl,
      columnList,
      uploadMask,
      uploadPercent,
      uploadImgFlag,
      uploadInputDisabled,
      currentUploadImgUrl,
      popoverTitleActiveKey,
    } = this.state;
    const formData: any = this.props.form;
    const { getFieldDecorator } = formData;
    const { isRequested, isRequestError } = this.props;
    const { data, hasData } = this.getCurrentListsInfo(popoverTitleActiveKey);
    const pictrueDomElement = this.renderPictrueDomElement(
      data,
      hasData,
      isRequested,
      isRequestError,
    );

    return (
      <div className="coursePage">
        {/* 封面图选择组件 */}

        <div className="content" style={{ paddingBottom: '1px' }}>
          <Form className="courseForm">
            <Form.Item {...formLayout} label="课程名称">
              {getFieldDecorator('courseName', {
                rules: [
                  { required: true, message: '请填写课程名称！' },
                  { max: 100, message: '超出最大字数限制！' },
                ],
              })(<AJTextField maxLength={100} />)}
            </Form.Item>
            <Form.Item {...formLayout} label="所属栏目">
              {getFieldDecorator('menuId', {
                rules: [
                  {
                    required: true,
                    message: '请选择所属栏目！',
                  },
                ],
              })(
                <Select placeholder="请选择" disabled>
                  {columnList.map((item: any) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...formLayout} label="课程封面">
              {
                <div className="coverItem">
                  <div className="left">
                    {uploadMask && (
                      <div className="mask">
                        <div className="mask-top" onClick={() => this.cancelImageUpload()}>
                          <MyIcon className="icon-style" type="iconic_del" />
                        </div>
                        <span>文件上传中</span>
                        <div className="progress">
                          <Progress size="small" percent={uploadPercent} showInfo={false} />
                        </div>
                      </div>
                    )}
                    <img alt="封面图" className="cover-img-1 coverImg" src={coverUrl} />
                    {currentUploadImgUrl && (
                      <img
                        alt="封面图"
                        className="cover-img-2 coverImg"
                        src={currentUploadImgUrl}
                      />
                    )}
                  </div>
                  <div className="right">
                    <Popover
                      trigger="click"
                      {...(!visible ? { visible } : '')}
                      overlayClassName="selectImgModal coverPicturePopover"
                      placement="bottomLeft"
                      title={
                        <Tabs
                          activeKey={popoverTitleActiveKey}
                          tabBarStyle={{
                            margin: 0,
                            textAlign: 'center',
                            color: '#262626',
                          }}
                          onChange={this.getTabsActiveKey.bind(this)}
                        >
                          <TabPane tab="系统封面库" key="system" />
                          <TabPane tab="个人封面库" key="personal" />
                        </Tabs>
                      }
                      content={<ul>{pictrueDomElement}</ul>}
                    >
                      {uploadInputDisabled && (
                        <Button disabled className="default mask-btn">
                          选择封面图
                        </Button>
                      )}
                      <Button className="default" onClick={this.openImageModal}>
                        选择封面图
                      </Button>
                    </Popover>
                    {uploadInputDisabled && (
                      <button className="upload-btn upload-btn-mask prohibit" disabled>
                        <Icon type="upload" />
                        本地上传
                      </button>
                    )}
                    <button className="upload-btn" onClick={() => this.uploadImageData()}>
                      <Icon type="upload" />
                      本地上传
                    </button>
                    {uploadImgFlag && (
                      <Input
                        onChange={(e: any) => this.uploadImageChange(e)}
                        type="file"
                        name="file"
                        ref={this.uploadImage}
                        accept={'image/png, image/jpg, image/jpeg'}
                        style={{ display: 'none' }}
                      />
                    )}
                    <p className="upload-desc">
                      支持png、jpg、jpeg文件，且不超过20M。建议图片像素为640*480。
                    </p>
                  </div>
                </div>
              }
            </Form.Item>
            <Form.Item
              {...formLayout}
              label={
                <span>
                  标<i style={{ margin: '0 12px' }} />签
                </span>
              }
            >
              {getFieldDecorator('labels')(<TagSelect groupCode="kechenggonggao" />)}
            </Form.Item>
            <Form.Item {...formLayout} label="课程简介">
              {getFieldDecorator('brief')(<Introduction />)}
            </Form.Item>
            <Form.Item {...formLayout} label="课程说明">
              {getFieldDecorator('description')(
                <Description
                  getPureWordCount={this.getPureWordCount}
                  getEditorObj={this.getEditorObj}
                />,
              )}
            </Form.Item>
            <Form.Item {...formLayout1}>
              <div className="btns">
                <Button type="primary" onClick={this.handleSubmit}>
                  保存
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const formLayout1: any = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20, offset: 4 },
};

const mapStateToProps = (state: any) => ({
  courseInfo: state.Course.courseInfo,
  isRequested: state.Course.isRequested,
  isRequestError: state.Course.isRequestError,
  columnListInfo: state.Course.columnListInfo,
  completeRequest: state.Course.completeRequest,
});

const mapDispatchToProps = (dispatch: any) => ({
  upadateErrorInfo: (): void => {
    dispatch({ type: 'IS_REQUESTED_ERROR', payload: false });
  },
  settingPaginationParams: (page: number, listsType: string): void => {
    dispatch({ type: 'SETTING_PAGINATION_PARAMS', payload: { page, listsType } });
  },
  clearPictrueListsData: (): void => {
    dispatch({ type: 'CLEAR_LISTS_DATA' });
  },
  finishRquest: (): void => {
    dispatch({ type: 'START_REQUEST' });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<P>()(CreateCourse));
