/*
 * @Descripttion: 直播与回看
 * @Author: gouyang
 * @Date: 2021-01-18 14:06:02
 * @LastEditors: gouyang
 * @LastEditTime: 2021-03-10 09:00:51
 */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import { liveAction, playBackAction } from '../../redux/action/live.action';
import { mergeProps, getSearchParams } from '../../util';
import PlayBack from './components/PlayBack';
import LiveBroadcast from './components/LiveBroadcast';
import { PlaybackInfo, LiveBroadcastInfo } from './interface';
import './index.less';

interface IProp extends RouteComponentProps {
  lesson: any;
  live: LiveBroadcastInfo;
  reviews: PlaybackInfo;
}
interface IState {
  url: string;
  selectedIndex: number;
  playBack: boolean;
  playBackLists: any[];
  playbackInfo: PlaybackInfo;
  liveBroadcastInfo: LiveBroadcastInfo;
  liveType: 'live-broadcase' | 'playback' | null;
}

class Live extends React.Component<IProp, IState> {
  private searchParams: { [s: string]: any } = getSearchParams();
  constructor(props: IProp) {
    super(props);
    this.state = {
      url: '',
      selectedIndex: 0,
      playBack: false,
      playBackLists: [],
      playbackInfo: {
        provider: -1,
        baijiayunPlaybacks: null,
        genseePlaybacks: null,
        easteduPlaybacks: null,
      },
      liveBroadcastInfo: {
        provider: -1,
        live: null,
        baijiayunRoom: null,
        genseeRoom: null,
      },
      liveType: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.liveDetails();
  }

  componentDidUpdate(prevProps: IProp) {
    const { live: prevLive, reviews: preReviews } = prevProps;
    const { live, reviews } = this.props;
    if (prevLive !== live) {
      live.provider === 1 &&
        this.setState({
          liveBroadcastInfo: live,
          liveType: 'live-broadcase',
        });
    }
    if (preReviews !== reviews) {
      // 回看
      this.setState({
        playbackInfo: reviews,
        liveType: 'playback',
      });
    }
  }

  liveDetails = () => {
    const { id, state, liveId } = this.searchParams;
    if (state === '2') {
      playBackAction.playBackInfo(id, liveId);
    } else {
      liveAction.liveInfo(id, liveId);
    }
  };

  render() {
    const { playbackInfo, liveBroadcastInfo, liveType } = this.state;

    return (
      <div className="live-view">
        <div className="live-content">
          {liveType === 'playback' && <PlayBack playbackInfo={playbackInfo} />}
          {liveType === 'live-broadcase' && <LiveBroadcast liveBroadcastInfo={liveBroadcastInfo} />}
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  live: state.Live.live,
  lesson: state.Live.lesson,
  reviews: state.Live.reviews,
});
export default withRouter(connect(mapStateToProps, mergeProps)(Live));
