export default {
  file: 'https://gateway.test.eastedu.ltd/file-service',
  api: 'https://openschool-api.test.eastedu.ltd/teaching_api',
  trackApi:
    'https://eastedu-test.cn-shenzhen.log.aliyuncs.com/logstores/open_school/track?APIVersion=0.6.0',
  userCenter: 'https://passport.eastedu.com/wechat/qrcode',
  // resourceCenter: 'http://192.168.101.59:58006',
  resourceCenterDownload: 'https://orgmember.test.eastedu.ltd',
  examinationAndAnalysis: 'http://192.168.101.59:55002',
  ebi: 'https://ebi.test.eastedu.ltd',
  ip: 'https://tool.eastedu.com/ip', // ip与服务器时间
  pingguoApi: 'https://gateway.test.eastedu.ltd/infrastructure-cache-service',
  userCenterApi: 'https://gateway.test.eastedu.ltd/user-center-api',
  markupService: 'https://gateway.test.eastedu.ltd/infrastructure-markup-service',
  SENTRY_DSN: 'https://df5e13a029044c6e80eba1f063b10207@sentry.test.eastedu.ltd/21',
  gateway: 'https://gateway.test.eastedu.ltd',
  passport: 'https://passports.test.eastedu.ltd',
  passport_sdk_token:
    '18nBQf2wbSLgCVLHsQIyc0FNMlcyUkZDS3kyQzJuSUE3WGl2dUZmUm8zZlJBa2hXYXBTcTk4dkRpRXVLemtyeDhmekFLdmRFQmZwbGhLMEt6aEE3K2VmaUlkWGVPUjNxb3duL0xJNTl0OFVueVRCbTgvZjllVVBDTC9EbktjWmhNeHZhdDZRTlBZbTArNWV3S0tVVFZxRTdxeTVKK2tqSmQxdDZPbkM3VzZ4RVpnNjB3dGxMcUNVWmxlcDUxZnZzYkg5aUZ1UUVVMXV4eHpOdHl3OUpUYXNTdHRYY3BnQTcyOVhoKzU2N1lRNGQzbXF4U2hvdjdyVE9kOG9Xd0VJWGhrZHk0N1pQbzlDNVE4WEYydS9BVFdxQXFDb0JaWkZ4TmhTMjFnQXZFZithQUZTVE93WGVKKzN1WVJDc2J5NXdWYVA3elNQUmZVSllLRFR4ZmpSaCtsdk10ZEptR2F2QUVjazNLQ2phbGliYmpHNG5KeWpnWHFONUJMTHg1eVkxZDg5SU5teWdPN29Kcjc4WFdrSStEWkU2WjQ0WXh1OEN1T3ZiUHhCWHlTdnRXSHRyNE8yN0VZbVZ3MHJxS3ozUGhtYWJDVXE4cisrN0NZSldwcXpoc0Z5WmpJb0tJbGN4ZXpjOGo0ODduKzkyNlZhZi9udVVlU1YrSzEzZmdzWXdTTjJFS3dvRnQ2NURRY0M=',
  userAgreement: 'https://u-learning.test.eastedu.ltd/privacy-policy/useragreement.html',
  privacyPolicy: 'https://u-learning.test.eastedu.ltd/privacy-policy/openschoolprivacypolicy.html',
};
