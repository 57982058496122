/* eslint-disable no-case-declarations */
import * as types from '../types/dynamicControl.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  noticeControls: [], // 公告控件列表
  courseControls: [], // 课程控件列表
  functionConfig: {}, // 功能配置
};

const DynamicControl = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_NOTICE_CONTROLS:
      return { ...state, noticeControls: payload };
    case types.GET_COURSE_CONTROLS:
      const { controlSchemas } = payload.controlExtension || { controlSchemas: [] };
      return { ...state, courseControls: controlSchemas };
    case types.FUNCTION_CONFIG:
      return { ...state, functionConfig: { ...payload } };
    default:
      return state;
  }
};
export default DynamicControl;
