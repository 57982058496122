/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-this-alias */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import * as article from '../../redux/action/article.action';
import { getSearchParams, stringifySearchParams } from '../../util';
import { ReleaseType } from '../../util/enum';
import Enclosure from './upload/index';
import mapFileType from '../CreateArticle/components/fileType';
import TrackRequest from '../../lib/trackRequest';
import { TagList } from '../../components/TagSelect/interface';
import './index.less';

interface P extends RouteComponentProps {
  history: any;
  match: any;
  title: string;
  html: string;
  content: string;
  files: any;
  createTime: string;
  updateTime: string;
  expireTime: number;
  callback: (flag: boolean) => void;
  refresh: Function;
  controls: any;
  tags: TagList[];
  grades: string[];
  releaseType: ReleaseType;
  studySectionCode: string;
  studySectionName: string;
  schoolYear: string;
  subjects: string[];
}
interface S {
  is_detail?: boolean;
  nav: any;
  noticeId: any;
}
class ArticlePreview extends React.PureComponent<P, S> {
  searchParams: any; // 地址栏 searh 数据
  constructor(props: P) {
    super(props);
    this.state = {
      is_detail: false,
      nav: ['文章管理', '我的创建', '预览文章'],
      noticeId: '',
    };
    this.searchParams = getSearchParams(props.location.search);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // 刷新页面返回上一个页面
    if (!this.props.title || this.props.title.length === 0) {
      this.props.history.goBack();
    }
    const { leftMenuType, noticeId, fromSearch } = this.searchParams;
    const self = this;
    if (fromSearch) {
      this.setState({ is_detail: true });
      article.articleDetail(noticeId);
      return;
    }
    // 编辑预览--->添加标记状态，返回上一页时，判断是否请求详情接口
    if (leftMenuType === '0' && noticeId) {
      (window as any)._editor = false;
      this.setState({ noticeId });
      window.onload = function () {
        self.props.history.goBack();
      };
    }
    if (!noticeId) {
      window.onload = function () {
        self.props.history.goBack();
      };
    }
    // 同步预览
    if (noticeId && leftMenuType === '2') {
      this.setState({ is_detail: true });
      article.articleDetail(noticeId);
    } else {
      // 创建、编辑预览
      this.setState({ is_detail: false });
    }
    const box: any = document.getElementById('article');
    if (box) {
      const img: any = box.getElementsByTagName('img')[0];

      if (img) {
        img.style.maxwidth = '1000px';
      }
    }
  }
  // 保存
  save = () => {
    const {
      tags,
      title,
      content,
      html,
      files,
      grades,
      releaseType,
      studySectionCode,
      studySectionName,
      schoolYear,
      subjects,
    } = this.props;

    let params = {
      name: title,
      content,
      contentHtml: html,
      files,
      extensionData: {},
      labelIds: tags ? tags.map((tag: TagList) => tag.id) : [],
      releaseType, // 发布方式：1-全部发布，2-部分发布
    } as any;

    if (releaseType === ReleaseType.PART) {
      params = {
        ...params,
        studySectionCode,
        studySectionName,
        subjects: subjects || [],
        grades: grades || [],
        schoolYear,
      };
    }

    const extensionData = { controlData: [] };
    const { controls } = this.props;
    extensionData.controlData = controls.map((control: any) => ({
      controlId: control.controlId,
      value: control.value,
    }));
    params.extensionData = extensionData;
    const { noticeId } = this.state;
    if (noticeId) {
      TrackRequest({ name: 'save_edit_notice', data: { notice_id: noticeId } });
      delete this.searchParams.noticeId;
      article.ediArticle(params, noticeId, (status: boolean) => {
        if (status) {
          this.props.history.push(`/navigation${stringifySearchParams(this.searchParams)}`);
        }
      });
    } else {
      article.createArticle(params, this.searchParams.id, (status: any) => {
        if (status) {
          this.props.history.push(`/navigation${stringifySearchParams(this.searchParams)}`);
        }
      });
    }
  };

  // 上一步
  prev = () => {
    this.props.history.goBack();
    (window as any)._editor = true;
  };
  render() {
    const { is_detail } = this.state;
    const { files, tags, expireTime } = this.props;

    return (
      <div className="article-preview">
        <div className="content">
          <div className="title">
            <div className="line1">{this.props.title}</div>
            {tags && tags.length > 0 && (
              <div className="tags">
                {tags.map((tag: TagList) => (
                  <pre key={tag.id}>{tag.name}</pre>
                ))}
              </div>
            )}
            {is_detail && (
              <div className="line2">
                <span>更新时间：{this.props.updateTime}</span>
                {typeof expireTime === 'number' && (
                  <Tooltip
                    overlayClassName="expire-time-user-class"
                    title="有效截止时间内查看将会纳入数据统计"
                  >
                    <span>
                      有效截止时间
                      <Icon type="info-circle" />：{moment(expireTime).format('YYYY-MM-DD HH:mm')}
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          <div
            className="article"
            dangerouslySetInnerHTML={{ __html: this.props.html }}
            id="article"
          />
        </div>

        {this.props.files.length > 0 && (
          <div className="file-list">
            <div className="title">
              附件资料
              <span />
            </div>
            {files &&
              files.map((item: any, index: number) => <Enclosure data={item} key={index} />)}
          </div>
        )}

        {!is_detail && (
          <div className="footer">
            <Button className="default" onClick={this.prev}>
              返回
            </Button>
            <Button type="primary" onClick={this.save}>
              保存
            </Button>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  const controls = state.DynamicControl.noticeControls || [];
  for (const i in controls) {
    controls[i].value = state.CreateArticle[controls[i].controlId];
  }
  const fileList = state.CreateArticle.flieList || [];

  let files: any = [];
  if (fileList && fileList.length) {
    fileList.forEach((element: any) => {
      const json = {
        fileId: element.data.fileId,
        name: element.data.fileName,
        extension: element.data.extension,
        icon: element.icon,
      };
      files.push(json);
    });
  } else {
    files = state.Article.info.files;
    files.forEach((fileName: any) => {
      const index1 = fileName.name.lastIndexOf('.') + 1;
      const index2 = fileName.length;
      const suffix = fileName.name.substring(index1, index2); // 后缀名
      fileName.icon = mapFileType(suffix);
    });
  }
  return {
    controls: controls.filter((item: any) => item.value !== null),
    title: state.CreateArticle.name || state.Article.info.name,
    html: state.CreateArticle.editorHtml || state.Article.info.contentHtml,
    content: state.CreateArticle.pureEditorText,
    files,
    createTime: moment(state.Article.info.createTime).format('YYYY-MM-DD HH:mm'),
    updateTime: moment(state.Article.info.updateTime).format('YYYY-MM-DD HH:mm'),
    expireTime: state.Article.info.expireTime,
    tags: state.CreateArticle.tags,
    grades: state.CreateArticle.grades, // 年级
    releaseType: state.CreateArticle.releaseType, // 发布方式：1-全部发布，2-部分发布
    studySectionCode: state.CreateArticle.studySectionCode, // 学段
    studySectionName: state.CreateArticle.studySectionName, // 学段
    schoolYear: state.CreateArticle.schoolYear, // 学年
    subjects: state.CreateArticle.subjects, // 学科
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  callback: (flag: boolean) => dispatch({ type: 'CALL_BACK', payload: flag }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePreview);
