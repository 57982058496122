/*
 * @Author: gouyang
 * @Description: 跳转域名配置
 * @Date: 2019-08-26 15:30:20
 */

const env = (window as any).__UNION_CENTER_ENV__;
let linkConfig = 'http://localhost:3000';
window.location.origin === 'https://openschool-teaching.devp.eastedu.ltd' &&
  (linkConfig = window.location.origin);

switch (env) {
  case 'DEV':
    linkConfig = 'https://openschool-teaching.devp.eastedu.ltd';
    break;
  case 'TEST':
    linkConfig = 'https://openschool-teaching.test.eastedu.ltd';
    break;
  case 'STAGE':
    linkConfig = 'https://teaching.open-school.com.cn';
    break;
  case 'PROD':
    linkConfig = 'https://teaching.open-school.com.cn';
    break;
  default:
}
export default linkConfig;
