/*
 * @Author: gouyang
 * @Description: 电话qq号码显示
 * @Date: 2019-07-24 14:03:24
 */
import React from 'react';
import { connect } from 'react-redux';
import { MyIcon } from '../../util';
import './index.less';
import { Icon } from 'antd';

interface P {
  [s: string]: any;
}
class Tips extends React.PureComponent<P> {
  onToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  render() {
    const { leagueDetails } = this.props;

    return (
      <div className="global-tips">
        <div id="btnTop" className="to-top" title="返回顶部" onClick={this.onToTop}>
          <Icon type="to-top" />
        </div>
        {leagueDetails.phone && (
          <div className="content top">
            <div className="icon-box">
              <MyIcon className="self-style" type="iconic_toolbar_tel" />
            </div>
            <p>
              <span>电话：{leagueDetails.phone}</span>
              {leagueDetails.workingHours && (
                <span className="work">({leagueDetails.workingHours})</span>
              )}
            </p>
          </div>
        )}
        {leagueDetails.qq && (
          <div className="content bottom">
            <div className="icon-box">
              <MyIcon className="self-style" type="iconic_toolbar_qq" />
            </div>
            <p>
              <span>QQ：{leagueDetails.qq}</span>
              {leagueDetails.workingHours && (
                <span className="work">({leagueDetails.workingHours})</span>
              )}
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state.Menu });
export default connect(mapStateToProps)(Tips);
