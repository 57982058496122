/*
 * @Author: gouyang
 * @Description: 获取浏览器信息
 * @Date: 2019-09-24 08:55:01
 */

function getExplorerInfo() {
  let explorer: any = window.navigator.userAgent;
  explorer = explorer.toLowerCase();
  // ie
  if (explorer.indexOf('msie') >= 0) {
    const ver = explorer.match(/msie ([\d.]+)/)[1] || '';
    return { name: 'IE', version: ver };
  }
  // edge
  if (explorer.indexOf('edge') >= 0) {
    const ver = explorer.match(/edge\/([\d.]+)/)[1] || '';
    return { name: 'edge', version: ver };
  }
  // firefox
  else if (explorer.indexOf('firefox') >= 0) {
    const ver = explorer.match(/firefox\/([\d.]+)/)[1] || '';
    return { name: 'Firefox', version: ver };
  }
  // 搜狗浏览器
  if (explorer.indexOf('se 2.x') >= 0) {
    return { name: '搜狗浏览器', version: '' };
  }
  // 遨游浏览器
  if (explorer.indexOf('maxthon') >= 0) {
    const ver = explorer.match(/maxthon\/([\d.]+)/)[1] || '';
    return { name: '傲游浏览器', version: ver };
  }
  // UC浏览器
  if (explorer.indexOf('ubrowser') >= 0) {
    const ver = explorer.match(/ubrowser\/([\d.]+)/)[1] || '';
    return { name: 'ubrowser', version: ver };
  }
  // 2345浏览器
  if (explorer.indexOf('2345explorer') >= 0) {
    const ver = explorer.match(/2345explorer\/([\d.]+)/)[1] || '';
    return { name: '2345explorer', version: ver };
  }
  // Chrome
  else if (explorer.indexOf('chrome') >= 0) {
    const ver = explorer.match(/chrome\/([\d.]+)/)[1] || '';
    return { name: 'Chrome', version: ver };
  }
  // Opera
  else if (explorer.indexOf('opera') >= 0) {
    const ver = explorer.match(/opera.([\d.]+)/)[1] || '';
    return { name: 'Opera', version: ver };
  }
  // Safari
  if (explorer.indexOf('safari') >= 0) {
    const ver = explorer.match(/safari\/([\d.]+)/)[1] || '';
    return { name: 'Safari', version: ver };
  }
  // QQ浏览器
  if (explorer.indexOf('qqbrowser') >= 0) {
    const ver = explorer.match(/qqbrowser\/([\d.]+)/)[1] || '';
    return { name: 'QQ浏览器', version: ver };
  }
  return { name: '', version: '' };
}

export default getExplorerInfo;
