/* eslint-disable @typescript-eslint/no-shadow */
import './index.less';
import React from 'react';
import moment from 'moment';
import { Button, Empty, Icon, Tooltip } from 'antd';
import { NormalObj, MyIcon, getMaterialBg, hideSubjectTag, isFzxxUnion } from '../../util';
import DownloadItem from './DownloadItem';
import { ArtScience } from '../../util/enum';
import { allowClicking } from '../../util/prohibitShow';

interface LessonItemProps {
  data: NormalObj;
  leftMenuType: string;
  goToPage?: (resId: string, lessonType: number, category: number) => void;
  toLesson: (type: number, id: string) => void;
  allLoaded?: boolean;
  onLoaded?: () => void;
}

interface Istate {
  openDownloadList: boolean;
  list: any[];
  records: any[];
  loaded: boolean;
}

class LessonItem extends React.Component<LessonItemProps, Istate> {
  constructor(props: LessonItemProps) {
    super(props);
    this.state = {
      openDownloadList: false,
      list: [],
      records: [],
      loaded: false,
    };
  }

  toggleOpen = (e?: any) => {
    e && e.stopPropagation();
    const { openDownloadList, loaded } = this.state;
    this.setState({ openDownloadList: !openDownloadList, loaded: true }, async () => {
      const { openDownloadList } = this.state;
      if (!openDownloadList || loaded) {
        return;
      }
      // let data = await lessonAction.getLessonResList(this.props.data.id);
      const data = {
        classRecord: this.props.data.classRecord || [],
        centerResources: this.props.data.centerResources || [],
      };
      let arr: any[] = [];
      let records: any[] = [];

      if (data && data.classRecord && data.classRecord.files && data.classRecord.files.length > 0) {
        records = data.classRecord.files.filter(
          (item: any) =>
            item.extension.toLowerCase() !== 'mp4' && item.extension.toLowerCase() !== 'txt',
        );
        // records.forEach(i => ((i.category = 1), (i.resourceGuid = data.classRecord.resourceGuid)));
        records.forEach((i) => {
          i.category = 1;
          i.resourceGuid = data.classRecord.resourceGuid;
        });
        this.setState({ records });
      }

      if (data && data.centerResources && data.centerResources.length > 0) {
        data.centerResources.map((item: any) => {
          const cat = item.category;
          const { files } = item;
          // files.forEach(
          //   (i: any) => ((i.category = cat), (i.expiryState = item.expiryState), (i.resourceGuid = item.resourceGuid))
          // );
          files.forEach((i: any) => {
            i.category = cat;
            i.expiryState = item.expiryState;
            i.resourceGuid = item.resourceGuid;
          });
          return (arr = arr.concat(files));
        });
        this.setState({ list: arr });
      }
    });
  };

  componentDidMount() {
    const { allLoaded } = this.props;
    const { lessonType } = this.props.data;
    if (!allLoaded) {
      if (lessonType !== 1) {
        this.props.onLoaded && this.props.onLoaded();
      }
    }
  }

  renderTags(tags: any[]) {
    return (
      <div className="lesson-item-info-tags">
        {tags.map((tag: any, i: number) => {
          if (i < 4) return <span key={tag.labelId}>{tag.labelName}</span>;
          if (i === 4)
            return (
              <span key="6" title={tags.map((t: any) => t.labelName).join('/')}>
                ...
              </span>
            );
          return null;
        })}
      </div>
    );
  }

  render() {
    const {
      id,
      grades,
      lessonName,
      lessonType,
      subjects,
      releaseTime,
      releaseState,
      teachers,
      liveState,
      startTime,
      watchState,
      centerResources,
      classRecord,
      labels,
      artScience,
      expireTime,
      live,
    } = this.props.data;
    const { leftMenuType, goToPage } = this.props;
    const { openDownloadList, list, records } = this.state;

    // 下载统计
    let all: any[] = [];
    let downs: any[] = [];
    if (leftMenuType === '2') {
      if (centerResources && centerResources.length && centerResources.length > 0) {
        for (let i = 0; i < centerResources.length; i++) {
          all = [...all, ...centerResources[i].files];
        }
      }

      if (classRecord && classRecord.files && classRecord.files.length > 0) {
        const index = classRecord.files.find(
          (i: any) =>
            i.extension.toLowerCase() === 'crf' ||
            i.extension.toLowerCase() === 'crf2' ||
            i.extension.toLowerCase() === 'ocrf',
        );
        index && all.push(index);
      }
      downs = all.filter((i: any) => i.downloadState === true);
    }

    const getIcon = (type: number) => {
      switch (type) {
        case 1:
          return 'iconic_live';
        case 2:
          return 'iconic_demand';
        case 3:
          return 'iconic_data';
        default:
          return '';
      }
    };
    const _teachers = teachers && teachers.map((item: NormalObj) => item.teacherName).join('/');
    const _grades = grades && grades.map((item: NormalObj) => item.gradeName).join('；');
    const _subjects = subjects && subjects.map((item: NormalObj) => item.subjectName).join('；');
    const open = (e: any) => {
      if (!allowClicking(live, lessonType, liveState)) return;
      e.stopPropagation();
      this.props.toLesson && this.props.toLesson(lessonType, id);
    };

    const renderItem = (): any => {
      if (list.length > 0 || records.length > 0) {
        return [...records, ...list].map((item: any, index) => {
          return (
            <DownloadItem
              downloadEntrance={1}
              key={item.fileId + index}
              data={item}
              lessonId={this.props.data.id}
              goToPage={
                goToPage &&
                goToPage.bind(this, `${item.fileId}*${index}`, lessonType, item.category)
              }
            />
          );
        });
      } else {
        return (
          <Empty
            className="ant-empty-normal custom-empty"
            description="暂无内容"
            image={<MyIcon className="empty-icon" type="iconcontent_null" />}
          />
        );
      }
    };

    return (
      <div>
        <div className="lesson-item" onClick={open}>
          <span className="lesson-item-icon" style={getMaterialBg(lessonType)}>
            <MyIcon className="iconfont" type={getIcon(lessonType)} />
          </span>
          <div className="lesson-item-text">
            <div className="lesson-item-name" title={lessonName}>
              {!isFzxxUnion() && leftMenuType === '2' && watchState === 2 && (
                <span className="state-icon update-icon">有更新</span>
              )}
              {!isFzxxUnion() && watchState === 1 && (
                <span className="state-icon pre-icon">已查看</span>
              )}
              {lessonName}
            </div>
            <div className="lesson-item-info">
              <span className="lesson-item-info-teacher overflow" title={_teachers}>
                授课教师：{_teachers}
              </span>
              <span className="lesson-item-info-grade overflow" title={_grades}>
                年级：{_grades}
              </span>
              <span className="lesson-item-info-subject overflow" title={_subjects}>
                学科：{_subjects}
              </span>
              {typeof artScience === 'number' && (
                <span className="lesson-item-info-subject" title={_subjects}>
                  文理科：{ArtScience[artScience]}
                </span>
              )}
              {releaseState ? (
                <span className="lesson-item-info-release">
                  发布时间：{moment(releaseTime).format('YYYY/MM/DD HH:mm')}
                </span>
              ) : (
                ''
              )}
              {leftMenuType === '2' && lessonType !== 1 && (
                <span className="lesson-item-info-subject overflow">
                  资料下载：{downs.length}/{all.length}
                </span>
              )}
            </div>
            <div className="lesson-item-bottom">
              {typeof expireTime === 'number' && (
                <Tooltip
                  overlayClassName="expire-time-user-class"
                  title="有效截止时间内查看将会纳入数据统计"
                >
                  <span>
                    有效截止时间
                    <Icon type="info-circle" />：{moment(expireTime).format('YYYY/MM/DD HH:mm')}
                  </span>
                </Tooltip>
              )}
              {labels && this.renderTags(hideSubjectTag(grades, labels))}
            </div>
          </div>
          {
            // 直播状态
            lessonType === 1 ? (
              <span className="liveStatus">
                {liveState === 0 ? (
                  <span>{moment(startTime).format('YYYY/MM/DD HH:mm:ss')} 开始直播</span>
                ) : (
                  ''
                )}
                {liveState === 1 ? <span>直播中</span> : ''}
                {liveState === 2 ? <span className="liveEnd">已结束</span> : ''}
              </span>
            ) : (
              ''
            )
          }
          {leftMenuType === '2' && lessonType !== 1 && goToPage && (
            <Button className="lesson-item-btn" onClick={this.toggleOpen}>
              {!openDownloadList ? '查看' : '收起'}课时资料{' '}
              {!openDownloadList ? <Icon type="down" /> : <Icon type="up" />}
            </Button>
          )}
          <Button
            disabled={!allowClicking(live, lessonType, liveState)}
            className="lesson-item-btn"
            type="primary"
            onClick={open}
          >
            观看课时
          </Button>
        </div>
        <div className="lesson-download-cont">
          {leftMenuType === '2' && openDownloadList && renderItem()}
        </div>
      </div>
    );
  }
}

export default LessonItem;
