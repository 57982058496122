import React, { memo } from 'react';
import './index.less';

interface Tags {
  labelName: string;
}

interface P {
  tags: Tags[];
}

function ListTags(props: P) {
  const { tags } = props;

  let tagDom = <></>;
  if (tags.length > 0) {
    tagDom = (
      <div className="list-tags">
        <pre className="tags-item">{tags[0].labelName}</pre>
        {/* {tags[1] && <pre className="tags-item">{tags[1].labelName}</pre>} */}
        {tags.length > 1 && (
          <span className="tags-item" title={tags.map((tag: any) => tag.labelName).join('/')}>
            ...
          </span>
        )}
      </div>
    );
  }
  return tagDom;
}

export default memo(ListTags);
