/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import moment from 'moment';
import { NormalObj, hideSubjectTag } from '../../util';
import ListTags from '../../components/ListTags';
import getBrief from '../../lib/getBrief';
import './index.less';

export interface courseData {
  courseName: string;
  highLightCourseName: string;
  releaseState: number;
  coverUrl?: string;
  brief: string;
  createTime: number;
  updateTime: number;
  myCreateUpdateTime: number;
  myFallowUpdateTime: number;
  id: number;
  lessonNum: [];
  watchState?: number | null;
  labels: any[];
  gradesMyFallow?: any[];
  source?: string;
}
interface P {
  data: courseData;
  type?: string;
  toCourseDetail: (id: number) => void;
  handleDelete: (id: number) => void;
  handleRelease: (id: number) => void;
  previewCourse: (id: number) => void;
  isSync: boolean;
}
const CoursePanel = ({
  data,
  toCourseDetail,
  handleDelete,
  handleRelease,
  previewCourse,
  type,
  isSync,
}: P) => {
  const [brief, setBrief] = useState({
    html: '',
    pureText: '',
  });
  const { watchState } = data;
  function renderPanelClick(type = '') {
    let result: any = '';
    let firstButton: any = '';
    if (type === '0') {
      firstButton = (
        <Popconfirm
          placement="bottom"
          title={
            data.lessonNum.length > 0 ? (
              <div>
                {data.releaseState === 0
                  ? '确认删除该课程吗？'
                  : data.releaseState === 1
                  ? '确定取消发布该课程吗？'
                  : ''}
                <br />
                <span className="warning-message">
                  {data.releaseState === 0
                    ? '删除后该课程下的课时均被删除'
                    : data.releaseState === 1
                    ? '该课程包含已发布的课时'
                    : ''}
                </span>
              </div>
            ) : data.releaseState === 0 ? (
              '确认删除该课程吗？'
            ) : data.releaseState === 1 ? (
              '确认下架该课程吗？'
            ) : (
              ''
            )
          }
          okText="确定"
          cancelText="取消"
          onConfirm={() =>
            data.releaseState === 0
              ? handleDelete(data.id)
              : data.releaseState === 1
              ? handleRelease(data.id)
              : ''
          }
        >
          <Button className="default">
            {data.releaseState === 0 ? '删除' : data.releaseState === 1 ? '取消发布' : ''}{' '}
          </Button>
        </Popconfirm>
      );
      result = (
        <div className="panel-click">
          {firstButton}
          <Button className="green" onClick={(e: any) => handleChildPreviewCourse(e, data.id)}>
            预览课程
          </Button>
          <Button className="primary" type="primary" onClick={() => toCourseDetail(data.id)}>
            课程管理
          </Button>
        </div>
      );
    } else if (type === '2') {
      result = (
        <div className="panel-click">
          <Button className="green" onClick={(e: any) => handleChildPreviewCourse(e, data.id)}>
            观看课程
          </Button>
        </div>
      );
    }
    return result;
  }
  // 点击父元素跳转课程预览
  function handleParentPreviewCourse(id: number) {
    if (type === '2') {
      previewCourse(id);
    }
  }
  // 点击子元素跳转课程预览
  function handleChildPreviewCourse(e: any, id: number) {
    e.stopPropagation();
    previewCourse(id);
  }

  useEffect(() => {
    if (data) setBrief(getBrief(data.brief));
  }, [data]);

  return (
    <div
      className={`Panel-Component ${type === '2' ? 'cursor' : ''} ${
        type === '0' && data?.source === 'construction_center' ? 'constructionCenter-course' : ''
      }`}
      onClick={() => handleParentPreviewCourse(data.id)}
    >
      <div className="panel-image">
        {data.releaseState !== 1 ? <div className="panel-status">—— 未发布 ——</div> : ''}
        <img src={data.coverUrl} alt="课程封面图" />
        {isSync && watchState === 2 && <span className="update-tag">有更新</span>}
      </div>
      <div className="panel-right">
        <div>
          <div
            className="panel-title mb8 overflow"
            title={data.courseName}
            dangerouslySetInnerHTML={{ __html: data.highLightCourseName }}
          />
          {brief.pureText && (
            <div
              className="panel-intro mb8 overflow"
              dangerouslySetInnerHTML={{ __html: brief.html }}
              title={brief.pureText}
            />
          )}
          <div className="panel-time">
            {type === '0' && (
              <span className="time">
                创建时间：{moment(data.createTime).format('YYYY-MM-DD HH:mm')}
              </span>
            )}
            <span className="time">
              更新时间：
              {type === '0' ? moment(data.myCreateUpdateTime).format('YYYY-MM-DD HH:mm') : ''}
              {type === '2' ? moment(data.myFallowUpdateTime).format('YYYY-MM-DD HH:mm') : ''}
            </span>
          </div>
        </div>
        <div className="panel-btn">
          <div className="panel-btn-box">
            {data.labels && (
              <ListTags tags={hideSubjectTag(data.gradesMyFallow || [], data.labels)} />
            )}
          </div>
          <div className="panel-btn-box">
            <div className="panel-number">
              共
              <span className="panel-number-mid ">
                {data.lessonNum && data.lessonNum.length > 0
                  ? data.lessonNum.reduce((initial: number, item: NormalObj) => {
                      return (initial += item.num);
                    }, 0)
                  : 0}
              </span>
              课时
            </div>
            {renderPanelClick(type)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePanel;
