/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
// 资料课时
import React from 'react';
import { Button, Input, message, Progress, Icon, Modal } from 'antd';

import fileServiceUpload from '../../../util/fileServiceUpload';
import FileLists from '../upload/fileList';
import { MyIcon } from '../../../util';
import { uploadStatus } from '../../../redux/action/lesson.action';

import './style.less';
import { toLower } from 'lodash';

interface P {
  typeName?: string; // 数据类型名，
  accept?: string; // 上传文件类型 默认全部可以上传
  acceptTips?: string; // accept提示
  cbackFun?: (data: any, typeName: string, markData: any, clearUploadStatus?: () => void) => void; // 回调 拿数据
  disabled?: boolean; // 控制上传按钮禁用
  maxLen?: number; // 上传个数
  data?: any; // 回显数据
  markups?: any[];
  name?: string; // 上传按钮名字
  icon?: string; // 图标
  category?: number;
  showTxt?: boolean; // 是否显示文本格式内容
  safetyChain?: boolean; // 是否启用防盗链
  clearWaringFun?: () => void; // 清空警告提示
  isDotFile?: boolean;
  validate?: (file: File) => Promise<string | null>;
  hideDelete?: boolean;
  onDel?: () => any;
}
interface S {
  file: any;
  fileList: any;
  accept: string; // 上传文件类型
  inputFlg: boolean;
  buttonName: string; // 上传按钮名字
  acceptTips: string;
  isShowList: boolean; // 是否展示列表
  txtItem: any; // txt 文本对象
  acheUploadFile: {
    name: string;
    percent: number;
    isExistence: boolean;
    statusText: string;
    status: string;
  }; // 缓存上传文件信息
}
class ServiceUpload extends React.Component<P, S> {
  private fileItem: any = null;
  private uploadCancelFunction = (cancelType?: string): void => {};
  private uploadStatusInfo = '';
  constructor(props: P) {
    super(props);
    this.state = {
      file: null,
      accept: '',
      fileList: [],
      inputFlg: true,
      buttonName: '本地上传',
      acceptTips: '',
      isShowList: true,
      txtItem: [],
      acheUploadFile: {
        name: '',
        percent: 0,
        isExistence: false,
        statusText: '',
        status: '',
      },
      // txtData: ''
    };
  }
  componentDidMount() {
    const { accept, disabled, name, acceptTips, icon } = this.props;
    //  正常的 封装操作
    const data: any = {};
    // 文件类型
    if (accept) {
      data.accept = accept;
    }
    if (typeof disabled === 'boolean') {
      data.disabled = disabled;
    }
    if (name) {
      data.buttonName = name;
    }
    if (typeof acceptTips === 'string' && acceptTips) {
      data.acceptTips = acceptTips;
    }
    if (icon) {
      data.icon = icon;
    }
    this.setState({
      ...data,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { accept, data, typeName } = this.props;
    const _typeName = toLower(typeName);
    if (accept !== nextProps.accept) {
      this.setState({
        accept: nextProps.accept,
      });
    }
    // 数据 回显 ，转化数据格式，--- 页面能够正常展示的格式
    if (data !== nextProps.data) {
      if (nextProps.data.length === 0) {
        if (_typeName === 'mp4' || _typeName === 'ocrf' || _typeName === 'txt') {
          this.setState({
            fileList: [],
          });
        }
        // if (typeName === 'txt') {
        //     this.setState({
        //         txtItem: null
        //     })
        // }
        return;
      }
      const data = nextProps.data.asMutable()[0].asMutable();
      if (_typeName === 'mp4' || _typeName === 'ocrf' || _typeName === 'txt') {
        const obj = {
          data,
          name: data.fileName,
          // name: isDotFile ? '已上传打点文件' : data.fileName,
          icon: '',
          id: Date.now(),
          status: 'done',
        };
        this.setState({
          fileList: [obj],
        });
      }
      // if (typeName === 'txt') {
      //     this.setState({
      //         txtItem: data
      //     })
      // }
    }
  }
  // 重置上传缓存文件
  resetAcheFile() {
    this.setState({
      acheUploadFile: {
        name: '',
        percent: 0,
        isExistence: false,
        statusText: '',
        status: '',
      },
    });
  }
  // 文件上传变化函数
  fileChange = async (e: any) => {
    const { accept, showTxt, clearWaringFun, validate } = this.props;

    const file = e.target.files[0];
    if (showTxt) {
      if (file && file.size > 1024 * 1024 * 5) {
        message.error('文件大小不能超过5M！');
        return;
      }
    } else if (file && file.size > 1024 * 1024 * 1024 * 2) {
      message.error('文件大小不能超过2G！');
      return;
    }
    const fileName = file.name;
    const index1 = fileName.lastIndexOf('.');
    let suffix = fileName.substring(index1, fileName.length); // 后缀名
    suffix = suffix.toLowerCase();
    if (fileName.substring(0, index1).length > 100) {
      message.error('文件名超过100个字符！');
      return;
    }
    // 上传格式验证
    if (accept && file && file.name) {
      const arr = accept.split(',');
      let flg = true;
      if (!arr[arr.length - 1]) {
        arr.pop();
      }
      for (const i in arr) {
        if (arr[i] === suffix) {
          flg = false;
        }
      }
      if (flg) {
        message.error(`上传文件格式错误！`);
        return;
      }
    }
    if (file.size <= 0) {
      message.error('文件大小不能小于0！');
      return;
    }

    if (validate) {
      const errorMsg = await validate(file);
      if (errorMsg) {
        message.error(errorMsg);
        return false;
      }
    }


    if (file) {
      const { icon } = this.props;
      this.fileItem = {
        id: String(Date.now()),
        icon,
        name: file.name,
        status: 'start',
        message: '',
        path: '',
        file,
      };
      clearWaringFun && clearWaringFun();
      this.setState(
        {
          acheUploadFile: { ...this.state.acheUploadFile, percent: 0, isExistence: false },
          fileList: [],
        },
        () => {
          this.setState({
            acheUploadFile: { ...this.state.acheUploadFile, isExistence: true },
          });
          this.clearPreUpload();
          if (showTxt) {
            // 读取本地 txt 并为真正上传，只是本地读取 模拟上传
            readFileTxt(file, this.fileUpload);
            this.uploadCancelFunction = () => {
              this.fileUpload({
                status: 'cance',
                message: '取消上传！',
                percent: 0,
              });
            };
          } else {
            const _safetyChain_ = !!this.props.safetyChain;
            this.uploadCancelFunction = fileServiceUpload(
              file,
              this.fileUpload,
              false,
              false,
              _safetyChain_,
            );
          }
        },
      );
    }
  };
  // 清空上一次上传状态
  clearPreUpload() {
    this.uploadStatusInfo = '';
    this.getData();
    this.uploadCancelFunction('cover');
  }
  // 文件上传方法
  fileUpload = (data: any) => {
    if (data.cancelType === 'cover') {
      return null;
    }

    const { maxLen, showTxt, typeName } = this.props;
    let { fileList } = this.state;
    let txtItem = [];
    let uploadText = '';
    const isExistence = true;
    this.fileItem.status = data.status;
    this.fileItem.message = data.message;
    fileList = [];
    if (data.status === 'start') {
      uploadStatus(typeName, false);
      // 开始上传
    } else if (data.status === 'progress') {
      // 上传中
      this.fileItem.percent = data.percent * 100;
    } else if (data.status === 'done') {
      // 上传完成
      this.fileItem.path = data.path;
      this.fileItem.data = data.data;
      fileList = [this.fileItem];
      message.success(data.message);
      uploadText = '上传完成';
      uploadStatus(typeName, true);
      if (data.txtResult && showTxt) {
        //  当上传为txt 打点文件
        txtItem = data.txtResult;
      }
    } else if (data.status === 'error') {
      // 上传失败
      uploadText = '上传失败！';
      message.error(data.message);
      this.uploadStatusInfo = 'error';
      uploadStatus(typeName, true);
    } else if (data.status === 'cance') {
      // 取消上传
      uploadText = '取消上传！';
      uploadStatus(typeName, true);
      if (this.uploadStatusInfo !== 'error') {
        message.error(data.message);
      }
    }
    if (maxLen) {
      fileList = fileList.slice(0, maxLen);
    }

    this.setState(
      {
        acheUploadFile: {
          ...this.state.acheUploadFile,
          percent: Math.round(this.fileItem.percent),
          status: data.status,
          statusText: uploadText,
          isExistence,
          name: this.fileItem.name,
        },
        fileList,
        txtItem,
      },
      () => {
        if (data.status === 'done') {
          this.getData();
        }
        if (data.status === 'done' || data.status === 'cance') {
          this.resetAcheFile();
        }
      },
    );
  };

  doUpload = () => {
    this.setState(
      {
        inputFlg: false,
      },
      () => {
        this.setState(
          {
            inputFlg: true,
          },
          () => {
            const { inputText } = this.refs as any;
            inputText.input.click();
          },
        );
      },
    );
  };

  // 打开 文件上传页
  clickUpload = () => {
    const { fileList } = this.state;
    const hasUploadFile = !!fileList?.[0]?.data?.file;
    if (this.props.isDotFile && (hasUploadFile || (this.props.markups || []).length > 0)) {
      Modal.confirm({
        title: '该视频已有普通实录的打点，是否覆盖',
        onOk: () => {
          this.doUpload();
        },
      });
    } else {
      this.doUpload();
    }
  };
  // 删除
  del = (index: number) => {
    let { txtItem } = this.state;
    const { fileList } = this.state;
    const item = fileList[0];
    if (toLower(item.data.extension) === 'txt') {
      txtItem = [];
    }
    this.setState(
      {
        fileList: [],
        txtItem,
      },
      this.getData,
    );
    setTimeout(() => {
      this.props.onDel && this.props.onDel();
    }, 0);
  };
  // 父元素回调
  parentCallback() {
    this.clearPreUpload();
    this.resetAcheFile();
  }
  // 获取数据
  getData = async () => {
    let { typeName } = this.props;
    const { cbackFun } = this.props;
    typeName = typeName || '';
    const { fileList, txtItem } = this.state;
    const len = fileList.length;
    if (len === 0) {
      cbackFun && cbackFun([], typeName, [], this.parentCallback.bind(this));
      return;
    }
    const data: any = [];
    for (let i = 0; i < len; i++) {
      if (fileList[i].status === 'done') {
        data.push(fileList[i]);
      }
    }
    if (cbackFun) {
      const item = data[0];
      const obj = {
        extension: item.data.extension,
        fileId: item.data.fileId,
        fileName: item.data.fileName,
        md5: item.data.md5,
        size: item.data.size,
        file: item.file,
      };
      let duration = 0;
      if (typeName === 'mp4' && item.data.path) {
        try {
          duration = await this.getVideoDuration(item.data.path);
        } catch (error) {
          console.log(error);
        }
        Object.assign(obj, { duration });
      }
      cbackFun([obj], typeName, txtItem);
    }
  };
  getVideoDuration(url: string): Promise<number> {
    const video = document.createElement('video');
    video.src = url;
    return new Promise((resolve: any, reject: any) => {
      video.onloadedmetadata = () => {
        const { duration } = video;
        video.removeAttribute('src');
        return resolve(duration);
      };
      video.onerror = (error) => {
        return reject(error);
      }
    });
  }
  // 关闭
  cancel = () => {
    this.uploadCancelFunction(this.uploadStatusInfo);
  };

  dotFileDom = () => {
    const { fileList } = this.state;
    const { disabled, isDotFile, markups, hideDelete } = this.props;
    const hasUploadFile = !!fileList?.[0]?.data?.file;

    // 没有上传文件且没有打点
    if (!hasUploadFile && markups?.length === 0) {
      return null;
    }

    // 没有上传文件但有打点
    const hadMarkups = (markups || []).length > 0;
    if (!hasUploadFile && hadMarkups && isDotFile) {
      return <div style={{ marginTop: 4, fontSize: 12 }}>已上传打点文件</div>;
    }

    return (
      <FileLists
        disabled={disabled}
        clearDisabled={!!isDotFile || !!hideDelete}
        data={fileList}
        fileDelCback={this.del}
      />
    );
  };

  render() {
    const { accept, acceptTips, inputFlg, buttonName, acheUploadFile } = this.state;
    const { disabled } = this.props;

    return (
      <div className="upload">
        {inputFlg ? (
          <Input
            type="file"
            name="file"
            ref="inputText"
            accept={accept}
            onChange={this.fileChange}
            id="uploadInput"
            style={{ display: 'none' }}
          />
        ) : (
          ''
        )}
        <div>
          <Button
            disabled={!!disabled}
            className="default"
            icon="upload"
            onClick={this.clickUpload}
          >
            {buttonName}
          </Button>{' '}
          <span className="acceptTips">{acceptTips}</span>
        </div>
        {this.dotFileDom()}
        {
          // showTxt && txtItem ?
          //     <pre className="txt-content"> 文件已上传 </pre>
          //     :
          //     ''
        }
        {acheUploadFile.isExistence && (
          <div className="upload-status">
            <p>
              <Icon style={{ fontSize: '12px', color: '#77849E' }} type="paper-clip" />
              <span className="upload-file-name">{acheUploadFile.name}</span>
            </p>
            <Progress
              showInfo={false}
              percent={acheUploadFile.percent}
              strokeWidth={2}
              status={acheUploadFile.status === 'error' ? 'exception' : undefined}
            />
            <div className="upload-operate">
              <div>
                {acheUploadFile.status !== 'progress' && (
                  <span className={acheUploadFile.status}>
                    {acheUploadFile.statusText}
                    <MyIcon
                      type="iconic_success"
                      className={`icon-display icon-${acheUploadFile.status}`}
                    />
                  </span>
                )}
                {acheUploadFile.status === 'progress' && <span>{acheUploadFile.percent}%</span>}
              </div>
              <MyIcon
                onClick={() => this.cancel()}
                className="close-style"
                type="iconic_del"
                title="关闭"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
//  读取txt 文件
interface fileChangeParameter {
  status: string; // 状态 start - 开始， progress- 上传中， done - 上传完成，error - 上传失败  cance -- 取消上传
  message: string; // 返回信息
  path?: string; // 上传成功之后返回的 路径
  percent?: number; // 上传中 'progress' 返回的 上传进度
  data?: any; // 文件服务返回的一整条数据
  txtResult?: any; // txt 内容
}
const readFileTxt = function (file: any, fileChange: (data: fileChangeParameter) => void) {
  let percent = 0;
  fileChange({ status: 'start', message: '上传开始！', percent });
  const reader = new FileReader();
  reader.onload = function () {
    if (reader.result) {
      // const str: any = reader.result;
      // let data: {
      //   bookmarks: Array<{ [ky: string]: string }>;
      // };
      // 不进行校验
      // try {
      //   data = JSON.parse(str);
      //   if (!(data instanceof Object) || !data.bookmarks) {
      //     fileChange({ status: 'error', message: '上传文件内容不符合规范！', percent });
      //     return;
      //   }
      // } catch (error) {
      //   fileChange({ status: 'error', message: '上传文件内容不符合规范！', percent });
      //   return;
      // }
      // 显示文件内容
      fileChange({
        status: 'done',
        message: '上传成功！',
        data: {
          extension: 'txt',
          fileId: '365426351903608832',
          md5: 'f1c9645dbc14efddc7d8a322685f26eb',
          fileName: file.name,
          size: file.size,
        },
        // txtResult: data.bookmarks,
      });
    } else {
      fileChange({ status: 'error', message: '未读取到文件内容！', percent });
    }
  };
  reader.readAsText(file, 'gb2312'); // gb2312 格式 解决乱码
  reader.onprogress = function (this: FileReader, ev: ProgressEvent) {
    const { loaded, total } = ev;
    percent = loaded / total;
    fileChange({ status: 'progress', message: '上传中...', percent });
  };
  reader.onerror = function () {
    fileChange({ status: 'error', message: '上传失败！', percent });
  };
};
export default ServiceUpload;
