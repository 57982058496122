/*
 * @Author: gouyang
 * @Description: 栏目操作方法
 * @Date: 2019-11-06 16:52:06
 */
// 获取第一个子元素信息（除链接类型）
export function recurdionGetFirstChild(data: any): any {
  if (!data) {
    return { id: null, child: null, name: null, type: -1 };
  }
  if (!data.children || data.children.length === 0) {
    return { id: data.columnId, child: data, name: data.name, type: data.type };
  } else if (data.children.length > 0) {
    let result = { id: null, child: null, name: null, type: -1 };
    for (let i = 0, l = data.children.length; i < l; i++) {
      result = recurdionGetFirstChild(data.children[i]);
      if (!!result.id && !!result.child && result.type !== 2) {
        return result;
      }
    }
    return result;
  } else {
    return { id: null, child: null, name: null, type: -1 };
  }
}
