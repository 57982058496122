import React from 'react';
import moment from 'moment';
import { getMonthSchedule } from '../../../redux/action/userCenter.action';

import { requestDataCompare } from '../formatData';

import Calendar from './calendar';

import './index.less';

interface P {
  dateChange: (value: number) => void;
  monthChange: (value: number[]) => void;
  composeMonthDays: (value: string[]) => any;
  value: number; // 时间戳
  source: string;
  data: any; // 月历当月的课时数据
}

const time = Date.now();
let sourceType = ''; // 来源类型
class CalendarIndex extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {
      flg: false, // 控制 按钮的 hover 效果
    };
  }
  componentDidMount() {
    sourceType = this.props.source;
    // 监听 事件
    document.addEventListener('click', this.controlClick, false);
    (document.getElementById('root') as any).addEventListener('click', this.calendarHide, false);
  }
  componentWillUnmount() {
    // 注销事件
    document.removeEventListener('click', this.controlClick, false);
    (document.getElementById('root') as any).removeEventListener('click', this.calendarHide, false);
  }
  // 日历显示
  calendarShow = (e: any) => {
    this.getMonth((data: any) => {
      const { value, composeMonthDays } = this.props;
      const obj = {
        dateChange: this.dateChange,
        monthChange: this.monthChange,
        composeMonthDays,
        monthSchedule: data,
        value,
        event: e,
      };
      Calendar.open(obj, 'Calendar');
    });
    this.setState({
      flg: true,
    });
  };
  // 获取月历 ---点击显示月历按钮 请求接口
  getMonth = async (cback: (data: any) => void) => {
    const { data, source, value } = this.props;
    const lastStr = moment(value).endOf('month').format('YYYY-MM-DD');
    const startDate = moment(value).startOf('month').valueOf();
    const endDate = moment(`${lastStr} 23:59:59`).valueOf();
    //  判断数据是否存在
    const flg = requestDataCompare(startDate, data);
    if (flg || sourceType !== source) {
      const data = await getMonthSchedule({ startDate, endDate, scheduleType: source });
      sourceType = source;
      cback(data);
    } else {
      cback(data);
    }
  };
  // 日历隐藏
  calendarHide = () => {
    this.setState({
      flg: false,
    });
    Calendar.remove('Calendar');
  };
  /**
   * 月历日期切换
   * @param value: 时间戳
   */
  dateChange = (value: number) => {
    this.props.dateChange(value);
  };
  /**
   * 月历月份切换
   * @param value: 时间戳
   */
  monthChange = (value: number[]) => {
    this.props.monthChange(value);
  };
  // 点击事件 / 展示 /关闭月历
  controlClick = (e: any) => {
    e.stopPropagation();
    // 显示月历
    if (Number(e.target.id) === time && e.target.tagName === 'SPAN') {
      this.calendarShow(e);
      return;
    }
    // 点击日期关闭月历
    if (e.target.getAttribute('data-name') === 'date' && e.target.tagName === 'SPAN') {
      this.calendarHide();
    }
  };
  render() {
    const { flg } = this.state;
    const { value } = this.props;
    return (
      <div className="oper-calendar">
        <span id={String(time)} className={flg ? 'title hover' : 'title'}>
          {moment(value).format('YYYY年MM月')}
        </span>
      </div>
    );
  }
}
export default CalendarIndex;
