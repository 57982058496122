/*
 * @Author: gouyang
 * @Description: 数据统计
 * @Date: 2019-09-03 15:11:11
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Cascader, Icon, Tooltip } from 'antd';
import Circle from './charts/circle';
import Histogram from './charts/histogram';
import FormStatistics from './charts/form';
import Head from './charts/head';
import StatisticsEmpty from './charts/empty';
import AxiosWrap from './../../lib/request';
import { getRoot } from '../../redux/action/menu.action';
import { specialColumns } from '../../util';

import './index.less';

interface P {
  menus: any[];
}
interface S {
  currentTime: string;
  currentSession: string;
  currentTypes: string;
  schoolPeriodLists: any[];
  statisticsDate: string;
  treeData: string[];
  firstCircleData: { [s: string]: any };
  secondCircleData: { [s: string]: any };
  firstHistogram: { [s: string]: any };
  secondHistogram: { [s: string]: any };
  liveStatistics: { [s: string]: any };
  reviewLiveStatistics: { [s: string]: any };
  emptyConfig: {
    title: string;
    isEmpty: boolean;
  };
}
interface query {
  businessDateType?: string; // 时间
  columnId?: string; // 栏目id
  schoolPeriod?: string; // 学届
}
function styles(): React.CSSProperties {
  return {
    color: '#D9D9D9',
    fontSize: '14px',
    marginRight: '5px',
  };
}

class Statistics extends PureComponent<P, S> {
  private time: any[];
  private submitData: any; // 筛选数据
  private tooltipObj: any; // 指标说明
  private timeTooltip: any; // 课时及资料情况说明
  private currentRoot: string; // 当前用户权限
  private firstHistogramTitle: string[]; // 第一个柱状图title
  private acheFirstHistogramData: { [s: string]: any }; // 缓存第一个柱状图统计数据
  private secondHistogramTitle: string[]; // 第二个柱状图title
  private acheSecondHistogramData: { [s: string]: any }; // 缓存第二个柱状图统计数据
  constructor(props: P) {
    super(props);
    this.state = {
      currentTime: 'CURRENT_WEEK', // 当前时间
      currentSession: 'all', // 当前学届
      currentTypes: 'create', // 当前类型
      schoolPeriodLists: [], // 学届列表
      statisticsDate: '', // 统计时间
      treeData: ['all$$'], // 当前选择的栏目
      firstCircleData: {
        title: [],
        info: [],
        total: '0',
        isData: false, // 是否存在数据
        top: '28%', // 图例距离顶部高度
      }, // 第一个饼图数据
      secondCircleData: {
        title: [],
        info: [],
        total: '0',
        isData: false, // 是否存在数据
        top: '15%', // 图例距离顶部高度
      }, // 第二个饼图数据
      firstHistogram: {
        title: [],
        data: [],
      }, // 第一个柱状图数据
      secondHistogram: {
        title: [],
        data: [],
      }, // 第二个柱状图数据
      liveStatistics: {
        lessonCount: 0, // 参与课时个数
        actuallyTotalDuration: 0, // 课时实际总时长
        lessonDuration: 0, // 参与总时长
        averageLessonDuration: 0, // 平均时长
      }, // 直播课时长统计
      reviewLiveStatistics: {
        lessonCount: 0,
        actuallyTotalDuration: 0,
        lessonDuration: 0,
        averageLessonDuration: 0,
      }, // 回看课时长统计
      emptyConfig: {
        isEmpty: true, // 是否显示“暂无数据”
        title: '加载中...',
      },
    };
    this.time = [
      {
        name: '本周',
        id: 'CURRENT_WEEK',
      },
      {
        name: '本月',
        id: 'CURRENT_MONTH',
      },
      {
        name: '本学期',
        id: 'CURRENT_TERM',
      },
      {
        name: '本年',
        id: 'CURRENT_YEAR',
      },
    ];
    this.submitData = {
      businessDateType: 'CURRENT_WEEK', // 时间
      columnId: '', // 栏目id
      schoolPeriod: '', // 学届
    };
    this.timeTooltip = (
      <div>
        <span style={{ display: 'block' }}>数据统计截止时间为前一天23:59:59。</span>
        <span style={{ display: 'block' }}>其他说明：</span>
        <span style={{ display: 'block' }}>
          1.当更新时间为“周一”，则看到的“本周”数据为上周的整体数据。
        </span>
        <span style={{ display: 'block' }}>
          2.当更新时间为“每月1号”，则看到的“本月”数据为上月的整体数据。
        </span>
        <span style={{ display: 'block' }}>
          3.当更新时间为“8月11号或者2月1号”，则看到的“本学期”数据为上学期的整体数据。
        </span>
        <span style={{ display: 'block' }}>
          4.当更新时间为“1月1号”，则看到的数据“本学年”数据为去年的整体数据。
        </span>
      </div>
    );
    this.tooltipObj = {
      // 指标说明
      dataViewing: (
        <div>
          <span style={{ display: 'block' }}>直播课时在线观看个数=用户已参与直播课时个数</span>
          <span style={{ display: 'block' }}>
            点播课时在线观看个数=用户在教学平台在线观看的点播课时个数
          </span>
          <span style={{ display: 'block' }}>
            资料课时在线观看个数=用户在教学平台在线观看的资料课时个数
          </span>
        </div>
      ),
      live: (
        <div>
          <span style={{ display: 'block' }}>已参与课时个数=用户已参与直播课时个数</span>
          <span style={{ display: 'block' }}>
            已参与课时总时长=用户已参与的直播课时对应的课时总时长
          </span>
          <span style={{ display: 'block' }}>已参与总时长=用户已参与的总时长</span>
          <span style={{ display: 'block' }}>已参与平均时长=用户已参与的平均时长</span>
        </div>
      ),
      reviewLive: (
        <div>
          <span style={{ display: 'block' }}>已回看课时个数=用户已回看直播课时个数</span>
          <span style={{ display: 'block' }}>
            已回看课时总时长=用户已回看的直播课时对应的课时总时长
          </span>
          <span style={{ display: 'block' }}>已回看总时长=用户已回看的总时长</span>
          <span style={{ display: 'block' }}>已回看平均时长=用户已回看的平均时长</span>
        </div>
      ),
    };
    this.currentRoot = 'sync'; // 默认同步权限
    this.firstHistogramTitle = ['直播课时', '点播课时', '资料课时'];
    this.acheFirstHistogramData = {};
    this.secondHistogramTitle = ['课件', '素材', '教案', '普通作业', '普通试卷', '课堂实录'];
    this.acheSecondHistogramData = {};
  }
  componentDidMount() {
    this.getUserRoot();
  }
  // 获取用户权限
  async getUserRoot() {
    const rootData = await getRoot(1); // 获取权限

    const creat = this.getIsExistRoot('course:*', rootData);
    const sync = this.getIsExistRoot('resource:sync', rootData);
    if (creat) {
      // 创建
      if (!sync) {
        // 是创建
      }
    }
    if (sync) {
      // 同步
      this.setState({
        emptyConfig: {
          isEmpty: false,
          title: '',
        },
      });
      this.currentRoot = 'sync';
      this.followSchoolPeriods();
      this.followStatistics(this.submitData);
    } else {
      this.currentRoot = '';
      this.setState({
        emptyConfig: {
          isEmpty: true,
          title: '暂无数据，敬请期待',
        },
      });
    }
  }
  // 判断是否有权限
  getIsExistRoot(name: string, data: any[]) {
    if (data) {
      let flg = false;
      for (const i in data) {
        if (data[i].code === name) {
          flg = true;
        }
      }
      return flg;
    } else {
      // console.error('未获取到权限！');
    }
  }
  // 我的同步-统计数据
  async followStatistics(params: query) {
    const { businessDateType, columnId, schoolPeriod } = params;
    const _params_ = [];
    businessDateType && _params_.push(`businessDateType=${businessDateType}`);
    columnId && _params_.push(`columnId=${columnId}`);
    schoolPeriod && _params_.push(`schoolPeriod=${schoolPeriod}届`);
    const url =
      _params_.length > 0
        ? `/bigdata/sync_statistics?${_params_.join('&')}`
        : '/bigdata/sync_statistics';
    const { data } = await AxiosWrap({ url });

    this.updatePageData(data);
  }
  // 更新页面数据
  updatePageData(data: any) {
    const firstEcharts = this.circleFormatTransfor(
      data.syncLessonWithTypes,
      'lessonTypeName',
      'syncLessonCount',
      'first',
    );
    const secondEcharts = this.circleFormatTransfor(
      data.syncResourceWithTypes,
      'resourceCategoryName',
      'syncResourceCount',
      'second',
    );
    const thirdEcharts = this.histogramFormatTransfor(data.useLessonWithTypes, 'first');
    const fourEcharts = this.histogramFormatTransfor(data.useResourceWithTypes, 'second');
    const fiveEcharts = this.statisticsTimeFormatTransfor(data.personLiveIndicesPartake, 'live');
    const sixEcharts = this.statisticsTimeFormatTransfor(
      data.personLiveIndicesPartake,
      'reviewLive',
    );
    const updateDate = Object.assign({}, this.state, {
      statisticsDate: data.statisticDate,
      firstCircleData: { ...firstEcharts, top: '28%' },
      secondCircleData: { ...secondEcharts, top: '15%' },
      firstHistogram: thirdEcharts,
      secondHistogram: fourEcharts,
      liveStatistics: fiveEcharts,
      reviewLiveStatistics: sixEcharts,
    });
    this.setState(updateDate);
  }
  // 饼图数据格式转换
  circleFormatTransfor(circleData: any[], nameKey: string, countKey: string, type: string) {
    const acheCircleData: { [s: string]: any } = {};
    let count = 0;
    const _circleData_: { info: any[]; title: string[]; total: string; isData: boolean } = {
      info: [],
      title: [],
      total: '0',
      isData: false,
    };
    circleData.forEach((data: any) => {
      count += parseFloat(data[countKey]) * 1000;
      acheCircleData[data[nameKey]] = data[countKey];
    });
    if (type === 'first') {
      this.firstHistogramTitle.forEach((dataKey: string) => {
        _circleData_.info.push({ name: dataKey, value: acheCircleData[dataKey] });
        _circleData_.title.push(dataKey);
      });
    } else if (type === 'second') {
      this.secondHistogramTitle.forEach((dataKey: string) => {
        _circleData_.info.push({ name: dataKey, value: acheCircleData[dataKey] });
        _circleData_.title.push(dataKey);
      });
    }
    count /= 1000;
    _circleData_.isData = count !== 0;
    _circleData_.total = this.numberFormatTransfor(count);

    return _circleData_;
  }
  // 柱状图数据格式转换
  histogramFormatTransfor(histogramData: any[], type: string) {
    const _histogramData_: { title: string[]; data: number[] } = { title: [], data: [] };
    histogramData.forEach((data: any) => {
      if (type === 'first') {
        this.acheFirstHistogramData[data.lessonTypeName] = data.useLessonCount;
      } else if (type === 'second') {
        this.acheSecondHistogramData[data.resourceCategoryName] = data.useResourceCount;
      }
    });
    // 柱状图需要排序
    if (type === 'first') {
      this.firstHistogramTitle.forEach((title: string) => {
        _histogramData_.data.push(this.acheFirstHistogramData[title]);
      });
      _histogramData_.title = this.firstHistogramTitle;
    } else if (type === 'second') {
      this.secondHistogramTitle.forEach((title: string) => {
        _histogramData_.data.push(this.acheSecondHistogramData[title]);
      });
      _histogramData_.title = this.secondHistogramTitle;
    }

    return _histogramData_;
  }
  // 数值格式转换
  numberFormatTransfor(value: number): string {
    let result = '';
    result =
      value >= 10000 ? `${Math.round(value / 100) / 100}万` : `${Math.round(value * 100) / 100}`;
    return result;
  }
  // 统计时长数据格式转换
  statisticsTimeFormatTransfor(timeData: any, type: string) {
    let result = {
      lessonCount: 0, // 参与课时个数
      actuallyTotalDuration: 0, // 课时实际总时长
      lessonDuration: 0, // 参与总时长
      averageLessonDuration: 0, // 平均时长
    };
    if (type === 'live') {
      const {
        joinLiveLessonCount,
        liveActuallyTotalDuration,
        joinLiveLessonDuration,
        avgJoinLiveLessonDuration,
      } = timeData;
      result = Object.assign(result, {
        lessonCount: joinLiveLessonCount, // 参与课时个数
        actuallyTotalDuration: this.numberFormatTransfor(liveActuallyTotalDuration / 60000), // 课时实际总时长
        lessonDuration: this.numberFormatTransfor(joinLiveLessonDuration / 60000), // 参与总时长
        averageLessonDuration: this.numberFormatTransfor(avgJoinLiveLessonDuration / 60000), // 平均时长
      });

      return result;
    } else if (type === 'reviewLive') {
      const {
        reviewLiveLessonCount,
        reviewLiveTotalDuration,
        reviewLiveLessonDuration,
        avgReviewLiveLessonDuration,
      } = timeData;
      result = Object.assign(result, {
        lessonCount: reviewLiveLessonCount,
        actuallyTotalDuration: this.numberFormatTransfor(reviewLiveTotalDuration / 60000),
        lessonDuration: this.numberFormatTransfor(reviewLiveLessonDuration / 60000),
        averageLessonDuration: this.numberFormatTransfor(avgReviewLiveLessonDuration / 60000),
      });

      return result;
    }
  }
  // 我的同步-学届
  async followSchoolPeriods() {
    const { data } = await AxiosWrap({ url: '/follow_query_conditions/school_periods' });
    data.unshift({ id: 'all', name: '全部' });
    this.setState({
      schoolPeriodLists: data,
    });
  }
  // 监听当前时间变化
  timeChange(id: string) {
    if (this.state.currentTime === id) return null;
    this.setState({
      currentTime: id,
    });
    this.submitData.businessDateType = id;
    this.followStatistics(this.submitData);
  }
  // 监听学届变化
  schoolPeriodChange(id: string) {
    if (this.state.currentSession === id) return null;
    this.setState({
      currentSession: id,
    });
    this.submitData.schoolPeriod = id === 'all' ? '' : id;
    this.followStatistics(this.submitData);
  }
  // 栏目数据结构转换
  transforTreeData() {
    const { menus } = this.props;
    const treeData = this.recursionTreeData(menus);
    treeData.unshift({ value: 'all$$', label: '全部', type: 0, state: 1 });
    return treeData;
  }
  // 递归栏目树
  recursionTreeData(data: any[]) {
    if (data.length === 0) return [];

    const result: any[] = [];
    data.forEach((child: any) => {
      if (child.name !== '课程表' && child.type !== 2 && !specialColumns.includes(child.name)) {
        const _children_: any[] = this.recursionTreeData(child.children);
        let _value_;
        if (child.children.length === 0) {
          _value_ = `${child.columnId}$$`;
        } else {
          _value_ = child.columnId;
        }
        if (child.state === 1) {
          const obj = Object.assign({}, child, {
            value: _value_,
            label: child.name,
            children: _children_,
          });
          if (obj.children.length === 0) delete obj.children;
          result.push(obj);
        }
      }
    });

    return result;
  }
  // 监听栏目变化
  treeSelectChange(value: string[], selectedOptions: any) {
    const newValue = value[value.length - 1];
    if (newValue.indexOf('$$') === -1) return null;
    const _value_ = newValue.split('$$')[0];
    if ((_value_ === 'all' && !this.submitData.columnId) || this.submitData.columnId === _value_)
      return null;
    this.setState({
      treeData: value,
    });
    this.submitData.columnId = _value_ === 'all' ? '' : _value_;

    const choosedOptions = selectedOptions[selectedOptions.length - 1];
    if (choosedOptions.type === 0 && choosedOptions.state === 1 && this.currentRoot === 'sync') {
      // 课程同步
      this.setState({
        emptyConfig: {
          isEmpty: false,
          title: '',
        },
      });
      this.followStatistics(this.submitData);
    } else {
      this.setState({
        emptyConfig: {
          isEmpty: true,
          title: '暂无数据，敬请期待',
        },
      });
    }
  }
  render() {
    const {
      currentTime,
      schoolPeriodLists,
      currentSession,
      statisticsDate,
      firstCircleData,
      secondCircleData,
      firstHistogram,
      secondHistogram,
      liveStatistics,
      reviewLiveStatistics,
      treeData,
      emptyConfig,
    } = this.state;
    const { dataViewing, live, reviewLive } = this.tooltipObj;

    return (
      <div className="statistics">
        <header>
          <span className="tree">栏目</span>
          <Cascader
            style={{ width: '300px' }}
            allowClear={false}
            value={treeData}
            options={this.transforTreeData()}
            onChange={(value: string[], selectedOptions: any) =>
              this.treeSelectChange(value, selectedOptions)
            }
          />
        </header>
        {emptyConfig.isEmpty ? (
          <div className="is-empty">
            <StatisticsEmpty title={emptyConfig.title} />
          </div>
        ) : (
          <section className="middle">
            <h2>
              <span>课时及资料情况</span>
              <span>
                <Tooltip overlayStyle={{ minWidth: '535px' }} title={this.timeTooltip}>
                  <Icon style={styles()} type="info-circle" />
                </Tooltip>
                更新时间：{statisticsDate}
              </span>
            </h2>
            <div className="content">
              <div className="search">
                <p>
                  <i>时间：</i>
                  {this.time.map((a: any) => {
                    return (
                      <span
                        onClick={() => this.timeChange(a.id)}
                        className={`${currentTime === a.id ? 'active' : ''}`}
                        key={a.id}
                      >
                        {a.name}
                      </span>
                    );
                  })}
                </p>
                <p>
                  <i>学届：</i>
                  {schoolPeriodLists.map((list: any) => {
                    return (
                      <span
                        onClick={() => this.schoolPeriodChange(list.id)}
                        className={`${currentSession === list.id ? 'active' : ''}`}
                        key={list.id}
                      >
                        {list.name}
                      </span>
                    );
                  })}
                </p>
              </div>
              <div className="top-left charts-content">
                <Head title="各类型课时新增个数" background="#2683F5" />
                <Circle id="first-main" countName="新增课时个数" data={firstCircleData} />
              </div>
              <div className="top-right charts-content">
                <Head title="各类型资料新增个数" background="#2683F5" />
                <Circle id="second-main" countName="新增资料个数" data={secondCircleData} />
              </div>
              <div className="top-left charts-content">
                <Head tooltip={dataViewing} title="各类型课时已观看个数" background="#0CB86E" />
                <Histogram id="third-main" data={firstHistogram} />
              </div>
              <div className="top-right charts-content">
                <Head title="各类型资料已下载个数" background="#0CB86E" />
                <Histogram id="four-main" data={secondHistogram} />
              </div>
              <div className="top-left charts-content">
                <Head tooltip={live} title="直播课时参与情况" background="#77849E" />
                <FormStatistics data={liveStatistics} />
              </div>
              <div className="top-right charts-content">
                <Head tooltip={reviewLive} title="直播课时回看情况" background="#77849E" />
                <FormStatistics data={reviewLiveStatistics} />
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  menus: state.Menu.menus,
});

export default connect(mapStateToProps)(Statistics);
