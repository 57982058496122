import React, { memo, useCallback, useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useSelector } from 'react-redux';
import {
  getLatestMessage,
  readLatestMessage,
  hasReadMessage,
} from '../../../../redux/action/message.action';
import './index.less';

function MessageModal() {
  const [visible, setVisible] = useState<boolean>(false);
  const { latestMessage } = useSelector((store: any) => ({
    latestMessage: store.Message.latestMessage,
  }));

  const onEnter = useCallback(() => {
    if (latestMessage) {
      readLatestMessage(latestMessage.id, () => {
        setVisible(false);
        hasReadMessage();
        window.localStorage.setItem('ISREAD', 'true');
      });
    }
  }, [latestMessage]);

  useEffect(() => {
    const ISLOGIN = window.localStorage.getItem('ISREAD');
    ISLOGIN === 'false' && getLatestMessage();
  }, []);

  useEffect(() => {
    if (latestMessage) {
      setVisible(true);
    }
  }, [latestMessage]);

  return (
    <Modal closable={false} width={1000} style={{ top: '45px' }} visible={visible} footer={null}>
      {latestMessage && (
        <div className="latest-message-modal">
          <div className="message-content">
            <div className="message-title">{latestMessage.name}</div>
            <div dangerouslySetInnerHTML={{ __html: latestMessage.content }} />
          </div>
          <div className="read-message">
            <Button type="primary" onClick={onEnter}>
              我知道了
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default memo(MessageModal);
