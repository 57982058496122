/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
// 课时创建
import React from 'react';
import { Radio, Button, Row, Col, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import _, { toLower } from 'lodash';
import * as action from '../../redux/action/vod.action';
import Alert from '../../components/Alert/Alert';
import {
  getCreatClassHour,
  getUpdateClassHour,
  getClassHourDetail,
  clearData,
} from '../../redux/action/lesson.action';
import { getRoot } from './upload/uploadFormat';
import { getSearchParams, NormalObj, stringifySearchParams } from '../../util';
// 子组件
import Information from './information/information'; // 基础信息
import LiveBroadcast from './livebroadcast/liveBroadcast'; // 直播课时
import OnDemand from './noDemand/onDemand'; // 点播课时
import Material from './material/material'; // 点播课时
import SetTime from './setTime/setTime'; // 设置时间
import DynamicSetting from './dynamic/dynamicSetting'; // 动态配置

import './style.less';
import './reset.less';

interface P extends RouteComponentProps {
  test: any;
  lessonType: string | number;
  lessonId: string | number;
  controls: string;
}
interface S {
  courseId: string; // 课程id
  lessonId: string; // 课时id
  current: string; // 导航激活
  nav: any; // 导航
  lessonType: string; // 课时类型
  isEdit: boolean; // 是否是编辑状态
  lessonTips: boolean;
  showPreview: boolean;
}
let InformationForm: any = null;
let SetTimes: any = null;
let LiveBroadcastForm: any = null;
let MaterialForm: any = null;
let OnDemandForm: any = null;
class NewClassHour extends React.PureComponent<P, S> {
  searchParams: NormalObj;
  private dynamicSetting: any = null;
  private _teacherAuthority: (t: 0 | 1 | 2, show: boolean) => void;
  constructor(props: P) {
    super(props);
    this.state = {
      isEdit: false,
      courseId: '',
      lessonId: '',
      current: '基本信息',
      nav: [
        { name: '基本信息', id: 'information' },
        { name: '时间设置', id: 'setTime' },
        { name: '课时类型', id: 'classHourType' },
      ],
      lessonType: '',
      lessonTips: false,
      showPreview: false,
    };
    this.searchParams = getSearchParams(props.location.search);
    this._teacherAuthority = this.teacherAuthority();
  }
  componentWillMount() {
    const { courseId, lessonId } = this.searchParams;
    this.setState({
      courseId,
      lessonId,
    });
    if (lessonId) {
      // 编辑
      this.setState({
        isEdit: true,
      });
      // const res = await action.getNewMarkups();
    }
  }
  componentDidMount() {
    const { lessonId } = this.searchParams;
    if (lessonId) {
      // 编辑
      getClassHourDetail(lessonId);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (String(nextProps.lessonType) !== String(this.props.lessonType)) {
      this.typeChangeFun(String(nextProps.lessonType));
    }
    return true;
  }
  componentWillUnmount() {
    clearData('classHourDetail');
    Alert.remove('classHour-detail');
  }

  /**
   * 0：授课教师
   * 1：主讲老师
   * 2：助教老师
   */
  teacherAuthority = () => {
    let text: string[] = [];
    let amountFlag = 0;

    return (t: 0 | 1 | 2, show: boolean) => {
      switch (t) {
        case 0:
          show && text.push('授课老师');
          amountFlag++;
          break;
        case 1:
          show && text.push('主讲老师');
          amountFlag++;
          break;
        case 2:
          show && text.push('助教老师');
          amountFlag++;
          break;
        default:
      }
      if (amountFlag === 3 && text.length !== 0) {
        const _text = text.join('，');
        Modal.info({
          title: '提示',
          content: (
            <div>
              <p>
                {_text}学科属性已变化，请重新选择{_text}！
              </p>
            </div>
          ),
        });
        amountFlag = 0;
        text = [];
      }
    };
  };

  // 课时类型
  classHourTypeChange = (e: any) => {
    const { value } = e.target;
    this.typeChangeFun(value);
  };
  // 类型变化的方法
  typeChangeFun = (value: any) => {
    let { nav: data } = this.state;
    data = data.slice(0, 3);
    switch (value) {
      case '1': // 直播课时
        data.push({ name: '直播设置', id: 'liveBroadcast' });
        break;
      case '2': // 点播课时
        data.push({ name: '点播设置', id: 'onDemand' });
        data.push({ name: '添加资料', id: 'onDemandMeans' });
        break;
      case '3': // 资料课时
        data.push({ name: '添加资料', id: 'means' });
        break;
      default:
    }
    this.setState({
      nav: data,
      lessonType: value,
      lessonTips: false,
      showPreview: value !== '1',
    });
  };

  splitTeacher = (params: any): [number, string] | null => {
    if (typeof params === 'string' && params) {
      const r = params.split('&&');
      const _id = parseInt(r[0], 10);
      if (!isNaN(_id) && r.length > 1) {
        return [_id, r.slice(1).join('&&')];
      }
      return null;
    }
    return null;
  };

  produceLiveParams = (originParams: any) => {
    const newParams = { ...originParams };
    const _lecturer = this.splitTeacher(newParams.lecturer);
    const _assistantTeacher = this.splitTeacher(newParams.assistantTeacher);
    if (_lecturer) {
      newParams.teacherId = _lecturer[0];
      newParams.teacherName = _lecturer[1];
    }
    if (_assistantTeacher) {
      newParams.assistantId = _assistantTeacher[0];
      newParams.assistantName = _assistantTeacher[1];
    }
    delete newParams.lecturer;
    delete newParams.assistantTeacher;
    return newParams;
  };

  // 提交数据
  submit = async () => {
    const { lessonType, courseId, isEdit, showPreview } = this.state;
    const { lessonId, history } = this.props;
    const LINKTO = window.localStorage.getItem('LINKTO');
    let cb: any = () => {
      window.localStorage.removeItem('LINKTO');
      return LINKTO ? history.push(JSON.parse(LINKTO)) : null;
    };
    if (showPreview) {
      cb = (lessonId: string) => {
        const search = {
          courseName: this.searchParams.courseName,
          courseId: this.searchParams.courseId,
          lessonId,
          lessonType,
          type: '0',
        };
        // const currentSearch = getSearchParams(decodeURI(window.location.search));
        const currentSearch = getSearchParams(window.location.search);
        currentSearch.lessonId = lessonId;
        const currentPath = `${window.location.pathname}${stringifySearchParams(currentSearch)}`;
        window.localStorage.setItem('LINKTOPREVIEW', JSON.stringify(currentPath));
        return history.push(`/lesson-preview${stringifySearchParams(search)}`);
      };
    }

    try {
      let data: any = {};
      let list: any = [];
      // 基础信息
      const infos = await InformationForm.getItemsValue();
      data = { ...data, ...infos };
      const { startTime, endTime } = await SetTimes.getItemsValue();
      data.startTime = startTime;
      data.endTime = endTime;
      data.labels = data.labels
        ? data.labels.map((label: any) => ({
            labelId: label.id,
            labelName: label.name,
          }))
        : [];

      // 课时类型
      if (!lessonType) {
        this.setState({
          lessonTips: true,
        });
        return;
      }
      this.getDynamicInfo(data);
      // 直播课时
      if (lessonType === '1') {
        const liveData = await LiveBroadcastForm.getItemsValue();
        if (new Date().getTime() >= data.startTime) {
          const text =
            isEdit && lessonId
              ? '当前时间已超过开课时间，不支持编辑！'
              : '开课时间不能小于当前时间';
          message.error(text);
          return;
        }
        data.live = this.produceLiveParams(liveData);
      }
      // 点播课时
      if (lessonType === '2') {
        const onDemandData = await OnDemandForm.getItemsValue();

        data.classRecord = onDemandData;
        const materialData = await MaterialForm.getItemsValue();

        list = [...list, ...materialData];

        // 获取需上传的打点文件
        const uploadDotFile = data.classRecord.files.filter(
          (t: any) => toLower(t.extension) === 'txt' && t.file,
        )?.[0];

        if (uploadDotFile) {
          const md5 = data.classRecord.files.filter((t: any) => toLower(t.extension) === 'mp4')?.[0]
            ?.md5;
          const suc = await action.uploadMarkupFile({
            file: uploadDotFile.file,
            md5,
          });
          if (!suc) {
            message.error('打点文件上传失败!');
          }
        }
      }
      // 资料课时
      if (lessonType === '3') {
        const materialData = await MaterialForm.getItemsValue();
        list = [...list, ...materialData];
      }
      data.centerResources = list;

      if (isEdit && lessonId) {
        // 编辑
        data.lessonType = lessonType; // 课时类型
        getUpdateClassHour({ data, lessonId, courseId }, cb);
      } else {
        // 新增
        data.lessonType = lessonType; // 课时类型
        data.courseId = courseId; // 课程id
        getCreatClassHour(data, cb);

        // 清空分页信息
        window.sessionStorage.removeItem('DEFAULTPAGE');
        window.sessionStorage.removeItem('DEFAULTSIZE');
      }
    } catch (error) {
      console.log('error: ', error);
      // 异常处理
    }
  };
  // 获取动态配置信息
  async getDynamicInfo(updateData: any) {
    const wrappedFormatValue: any[] = [];
    updateData.extensionData = {
      controlData: null,
    };
    const { formatValue } = await this.dynamicSetting.getItemsValue();
    Object.keys(formatValue).forEach((key: string) => {
      wrappedFormatValue.push({ controlId: key, value: formatValue[key] });
    });
    if (wrappedFormatValue.length > 0) {
      updateData.extensionData.controlData = wrappedFormatValue;
    }
  }
  // 转换 资源数据格式
  formatData = (data: any) => {
    const list: any = [];
    for (const i in data) {
      if (data[i].isResource) {
        list.push({
          resourceGuid: data[i].resourceGuid,
          category: data[i].category,
        });
      } else {
        list.push({
          category: data[i].category,
          resourceGuid: data[i].resourceGuid,
          description: '',
          name: data[i].name,
          files: [
            {
              extension: data[i].extension,
              fileId: data[i].fileId,
              fileName: data[i].fileName,
              md5: data[i].md5,
              size: data[i].size,
            },
          ],
        });
      }
    }
    return list;
  };
  render() {
    const lessonRoot: any = getRoot('lesson:*');
    const { nav, lessonType, isEdit, lessonTips, showPreview } = this.state;
    const { controls } = this.props;
    const fianlNav = JSON.parse(JSON.stringify(nav));
    if (JSON.parse(controls).length > 0) {
      fianlNav.splice(2, 0, {
        name: '其它信息',
        id: 'others',
      });
    }

    return (
      <div className="coursePage" style={{ backgroundColor: 'transparent' }}>
        <div className="content create-lesson" id="newClassHour">
          <div className="create-lesson-top" id="navId">
            {fianlNav.map((item: any) => (
              <span key={item.id}>{item.name}</span>
            ))}
          </div>
          {
            /* 基本信息 */
            <Information
              teacherAuthority={this._teacherAuthority}
              isEdit={isEdit}
              wrappedComponentRef={(from: any) => (InformationForm = from)}
            />
          }
          {
            // 设置时间
            <SetTime isEdit={isEdit} wrappedComponentRef={(from: any) => (SetTimes = from)} />
          }
          {
            <DynamicSetting
              controls={JSON.parse(controls)}
              wrappedComponentRef={(from: any) => (this.dynamicSetting = from)}
            />
          }
          <div className="model" id="classHourType">
            <h3>课时类型</h3>
            <Row className="classHourType">
              <Col span={2}>课时类型：</Col>
              <Col span={10}>
                <div>
                  <Radio.Group
                    disabled={!!isEdit}
                    onChange={this.classHourTypeChange}
                    value={lessonType}
                  >
                    {lessonRoot && lessonRoot.indexOf(1) >= 0 ? (
                      <Radio value="1">直播课时</Radio>
                    ) : (
                      ''
                    )}
                    {lessonRoot && lessonRoot.indexOf(2) >= 0 ? (
                      <Radio value="2">点播课时</Radio>
                    ) : (
                      ''
                    )}
                    {lessonRoot && lessonRoot.indexOf(3) >= 0 ? (
                      <Radio value="3">资料课时</Radio>
                    ) : (
                      ''
                    )}
                  </Radio.Group>
                  {lessonTips ? (
                    <div style={{ color: 'red', marginTop: '5px' }}>请选择课时类型！</div>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {nav[3] && nav[3].name === '直播设置' ? (
            <LiveBroadcast
              teacherAuthority={this._teacherAuthority}
              isEdit={isEdit}
              wrappedComponentRef={(from: any) => (LiveBroadcastForm = from)}
            />
          ) : (
            ''
          )}
          {nav[3] && nav[3].name === '点播设置' ? (
            <OnDemand isEdit={isEdit} wrappedComponentRef={(from: any) => (OnDemandForm = from)} />
          ) : (
            ''
          )}
          {nav[3] && nav[3].name === '点播设置' ? (
            <Material
              isEdit={isEdit}
              wrappedComponentRef={(from: any) => (MaterialForm = from)}
              lessonType={lessonType}
            />
          ) : (
            ''
          )}
          {nav[3] && nav[3].name === '添加资料' ? (
            <Material
              isEdit={isEdit}
              wrappedComponentRef={(from: any) => (MaterialForm = from)}
              lessonType={lessonType}
            />
          ) : (
            ''
          )}
          <div className="newClassHourbtns">
            {/* 点播课时是保存并预览，直播课时是保存 */}
            <Button type="primary" onClick={this.submit}>
              {showPreview ? '保存并预览' : '保存'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  const { extensionData } = state.Lesson.classHourDetail;
  const controlDatas = extensionData ? extensionData.controlData : null;
  const controls = _.cloneDeep(state.DynamicControl.courseControls);

  if (controlDatas) {
    controls.forEach((control: any) => {
      const i = controlDatas.findIndex(
        (controlData: any) => controlData.controlId === control.controlId,
      );
      control.defaultValue = i !== -1 ? controlDatas[i].value : control.defaultValue;
    });
  }
  return {
    lessonType: state.Lesson.classHourDetail.lessonType,
    lessonId: state.Lesson.classHourDetail.lessonId,
    controls: JSON.stringify(controls), // 转成字符串是因为PureComponent进行的是浅比较，避免重复渲染
  };
};
export default connect(mapStateToProps)(NewClassHour);
