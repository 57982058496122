// 基础信息
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Immutable from 'seamless-immutable';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Select, Input, Row, Col, Button, Tag, message } from 'antd';
import { AJTextField } from '../../../components/AJInput';
import {
  getStudySectionId,
  getSubjectId,
  isPingguoUnion,
  getSchoolYear,
  getPingguoSchoolYear,
  getSubjectList,
  computeGrades,
} from '../../../util';
import { getChangeSubject, createLessonFilteredTeacher } from '../../../redux/action/lesson.action';
import {
  getTeacher,
  getStudySections,
  getGrades,
  getTextbooks,
} from '../../../redux/action/base.action';
import AddTeaching from './addTeaching';
import TagSelect from '../../../components/TagSelect';
import './style.less';

interface P extends FormComponentProps {
  isEdit: boolean;
  teacherList: any; // 授课老师列表
  gradesList: any; // 年级列表
  studySectionsList: any; // 学段列表
  Information: any; // 基本信息
  materialChapters: any; // 教材章节数据
  totalPages: number; // 分页总数
  reload: any;
  classHourList: any; // 课时列表
  filteredTeacherList?: any[];
  updateSubjectIds: (ids: string[]) => void;
  updateTeacherDisabled: (status: boolean) => void;
  teacherAuthority: (t: 0 | 1 | 2, show: boolean) => void;
}
interface S {
  booksDisabled: boolean; // 教材章节按钮控制禁用
  subjectDisabled: boolean; // 学科禁用控制
  teacherDisabled: boolean; // 授课老师禁用控制
  isFillFLg: boolean; // 判断是否填充过数据了
  visible: boolean; // 弹出框控制显示
  teachingList: any; // 教材章节 列表
  subjects: string[]; // 学科id
  grades: string[]; // 年级id
  subjectsList: any; // 学科列表
  page: number; // 分页page
}
// 学年列表
const { schoolYears: _schoolYears, curSchoolYear } = getSchoolYear(4, 1);
const schoolYears = isPingguoUnion() ? getPingguoSchoolYear() : _schoolYears;
class Information extends React.PureComponent<P, S> {
  private teacherSelector: any = null;
  private gradeSelector: any = null;
  private subjectSelector: any = null;
  private _onSearch: any;
  private artScienceLists: Array<{ [s: string]: string | number }> = [];
  constructor(props: P) {
    super(props);
    this.state = {
      booksDisabled: true,
      subjectDisabled: true,
      teacherDisabled: true,
      isFillFLg: false,
      visible: false,
      subjects: [],
      grades: [],
      teachingList: [],
      subjectsList: [],
      page: 0,
    };
    this._onSearch = _.debounce(this.onSearch, 800);
    this.artScienceLists = [
      { id: 1, label: '文科' },
      { id: 2, label: '理科' },
      { id: 0, label: '文理科' },
    ];
  }
  componentDidMount() {
    const studySectionCode = getStudySectionId();
    getGrades(studySectionCode); // 获取年级列表
    getStudySections(); // 学段
    this.initData();
  }
  // 初始化数据填充
  initData = () => {
    const { isEdit, updateSubjectIds, updateTeacherDisabled } = this.props;
    // 获取 学科id 列表， 和学科列表
    const subjectIds = getSubjectId();
    const subjectsList = getSubjectList();
    // 学科判断 如果是多科 则 需要手动选择，不是多科则不让选择 自动填充
    if (subjectIds.length > 1) {
      const data: any = { subjectDisabled: false };
      if (isEdit) {
        data.teacherDisabled = false;
        updateTeacherDisabled(false);
      }
      this.setState({ ...data, subjectsList });
    } else {
      // 单学科
      this.setState(
        {
          teacherDisabled: false,
          subjects: subjectIds,
          subjectsList,
        },
        this.getBooks,
      );
      updateTeacherDisabled(false);
      updateSubjectIds([...subjectIds]);
      // 通知站点资源模块 已选择学科
      getChangeSubject(subjectIds);
      // if (!isEdit) getTeacher({ subjectIdString: subjectIds.join('|') });
      // if (!isEdit) getTeacher();
    }
    if (!isEdit) {
      const studySectionCode = getStudySectionId();
      // 平果联盟默认学年选最后一项
      const year = isPingguoUnion() ? schoolYears[schoolYears.length - 1] : curSchoolYear;
      this.props.form.setFieldsValue({ schoolYear: year, schoolSection: studySectionCode });

      // 如果不是第一个课时,则基础信息字段默认选择上一个课时的信息
      if (this.props.classHourList && this.props.classHourList.length > 0) {
        const lastLesson = this.props.classHourList[0];
        const defaultValues: Record<string, any> = {};

        defaultValues.schoolYear = lastLesson.schoolYear;
        defaultValues.grades = (lastLesson.grades || []).map((item: any) => item.gradeId);
        defaultValues.artScience = lastLesson.artScience;
        defaultValues.subjects = (lastLesson.subjects || [])
          .map((item: any) => item.subjectId)
          .filter((item: any) => subjectIds.includes(item));
        defaultValues.teachers = (lastLesson.teachers || []).map((item: any) => item.teacherId);
        this.props.form.setFieldsValue({
          ...defaultValues,
          ...(defaultValues.subjects.length > 0 ? {} : { teachers: [] }),
        });
        getChangeSubject(defaultValues.subjects);
        this.setState(
          {
            grades: defaultValues.grades,
            subjects: defaultValues.subjects,
            teacherDisabled: false,
            teachingList: lastLesson.materialChapters,
          },
          async () => {
            updateTeacherDisabled(false);
            updateSubjectIds([...defaultValues.subjects]);
            this.getBooks();
            this.setTeachingTest();
            await createLessonFilteredTeacher(lastLesson);
          },
        );
        return;
      }
      // 如果是该课程下的第一个课时，手动计算年级
      this.schoolYearChange(year);
    }
  };
  componentWillUnmount() {
    const { updateTeacherDisabled } = this.props;
    getChangeSubject([]);
    this.props.reload();
    updateTeacherDisabled(true);
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { isFillFLg } = this.state;
    // 填充数据
    const { Information, isEdit, teacherList, materialChapters, filteredTeacherList } = nextProps;
    const { updateSubjectIds, teacherAuthority, classHourList } = this.props;
    const { subjectsList } = this.state;
    // --------------编辑数据 填充 ----------------
    // 填充 教材章节
    if (isEdit && materialChapters !== this.props.materialChapters) {
      this.setState(
        {
          teachingList: materialChapters,
        },
        this.setTeachingTest,
      );
    }
    // 填充基础信息
    if (isEdit && Information !== this.props.Information) {
      const data = Information.asMutable();
      const subjectsObj = data.subjectsObj
        .asMutable()
        .map((item: any) => ({ id: null, code: item.subjectId, name: item.subjectName }));
      this.props.form.setFieldsValue({
        lessonName: data.lessonName,
        schoolSection: data.schoolSection,
        grades: data.grades.asMutable(),
        teachers: data.teachers.asMutable(),
        schoolYear: data.schoolYear,
        labels: data.labels.asMutable(),
      });
      if (typeof data.artScience === 'number') {
        this.props.form.setFieldsValue({
          artScience: data.artScience,
        });
      }
      this.setState(
        {
          subjects: data.subjects.asMutable(),
          grades: data.grades.asMutable(),
          subjectsList: _.unionBy(subjectsList, subjectsObj, 'code'),
        },
        this.getBooks,
      );
      updateSubjectIds([...data.subjects.asMutable()]);
      // 通知站点资源模块 已选择学科
      getChangeSubject(data.subjects);
    }
    // eslint-disable-next-line
    if (!isFillFLg && isEdit && Information !== this.props.Information) {
      // ----- 判断授课老师是否失效 -----
      // 当前课时的授课老师
      if (!Immutable.isImmutable(Information.teachers)) {
        teacherAuthority(0, false);
        return;
      }

      const { teachers } = Information;

      // 失效老师 -- 置空 失效老师 给提示
      if (teacherList.length > 0 && teachers.length !== filteredTeacherList.length) {
        // 失效老师过滤后的老师数组 的id
        const newTeacherIds = filteredTeacherList.map((i: { userId: number }) => i.userId);
        teacherAuthority(0, true);
        this.props.form.setFieldsValue({ teachers: newTeacherIds });
      } else {
        teacherAuthority(0, false);
      }
      this.setState({
        isFillFLg: true,
      });
    }
    // 创建的情况下需要判断 继承的授课老师是否过期
    if (
      !isFillFLg &&
      !isEdit &&
      classHourList &&
      classHourList.length > 0 &&
      teacherList.length > 0
    ) {
      const { teachers } = classHourList[0];
      if (teacherList.length > 0 && teachers.length !== filteredTeacherList.length) {
        const newTeacherIds = filteredTeacherList.map((i: { userId: number }) => i.userId);
        this.props.form.setFieldsValue({ teachers: newTeacherIds });
      }
      this.setState({
        isFillFLg: true,
      });
    }
  }
  // 学科选择变化
  changeSubject = (value: any) => {
    const { updateSubjectIds } = this.props;
    this.subjectSelector.blur();
    updateSubjectIds([...value]);
    this.setState(
      {
        page: 0,
        subjects: value,
        teachingList: [],
      },
      () => {
        this.getBooks();
        this.setTeachingTest();
        // 选择学科的时候 清空老师选项---保留存在的学科
        // getTeacher({ subjectIdString: value.join('|') }, 1);
        // getTeacher(undefined, 1);
        // let teachersIds = this.props.form.getFieldValue('teachers');
        // console.log(teachersIds);
        // if (teachersIds && teachersIds.length > 0 && teachObj && teachObj.status === 200) {
        //   let data = teachObj.data._embedded.teachers;
        //   let teachers = teachersIds.filter((item: any) => {
        //     for (let i in data) {
        //       if (item === data[i].userId) {
        //         return true;
        //       }
        //     }
        //     return false;
        //   });
        //   console.log(teachers);
        // this.props.form.setFieldsValue({ teachers: [] });
        // }
      },
    );
    // action
    getChangeSubject(value);
  };
  // 学年发生变化
  schoolYearChange = (value: any) => {
    const curYear = parseInt(curSchoolYear.split('-')[1], 10);
    const selYear = parseInt(value.split('-')[1], 10);
    // 如果选择的学年是当前学年以前的学年，直接清空年级；
    if (selYear < curYear) {
      this.props.form.setFieldsValue({
        grades: [],
        artScience: undefined,
      });
      this.setState({ grades: [] });
      return;
    }
    const studySectionCode = getStudySectionId();
    const grades = computeGrades(studySectionCode, value);
    this.props.form.setFieldsValue({ grades });
    if (studySectionCode === 'GZ' && grades.includes('G1')) {
      this.props.form.setFieldsValue({ artScience: 0 });
    } else {
      this.props.form.setFieldsValue({ artScience: undefined });
    }
    this.setState({ grades });
  };
  // 年级选择变化
  gradeChange = (value: any) => {
    this.gradeSelector.blur();
    const studySectionCode = getStudySectionId();
    if (studySectionCode === 'GZ' && value.includes('G1')) {
      this.props.form.setFieldsValue({ artScience: 0 });
    } else {
      this.props.form.setFieldsValue({ artScience: undefined });
    }
    this.setState({
      grades: value,
    });
  };
  // 根据学科、学段 获取教材章节
  getBooks = () => {
    const { updateTeacherDisabled } = this.props;
    const { subjects } = this.state;
    // 获取学段id
    const studySectionCode = getStudySectionId();
    let booksDisabled = true;
    if (subjects.length > 0) {
      booksDisabled = false;
      getTextbooks({ studySectionCode, subjectCodeString: subjects.join('|') });
      this.props.form.setFieldsValue({ subjects });
    }
    this.setState({
      booksDisabled,
      teacherDisabled: booksDisabled,
    });
    updateTeacherDisabled(booksDisabled);
  };
  // 获取数据
  getItemsValue = () => {
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      this.props.form.validateFieldsAndScroll((error: any, value: any) => {
        if (!error) {
          const { teachingList, subjectsList } = this.state;
          const { teacherList, gradesList } = this.props;
          const data: any = {};
          for (const i in value) {
            if (i !== 'schoolSection') {
              data[i] = value[i];
            }
          }
          // 年级
          const { grades } = data;
          const grade_list = [];
          for (const i in gradesList) {
            if (Object.prototype.hasOwnProperty.call(gradesList, i)) {
              for (const j in grades) {
                if (gradesList[i].code === grades[j]) {
                  grade_list.push({
                    gradeId: gradesList[i].code,
                    gradeName: gradesList[i].name,
                  });
                }
              }
            }
          }
          data.grades = grade_list;

          // 学科
          const { subjects } = data;
          const subject_list = [];
          for (const i in subjectsList) {
            if (Object.prototype.hasOwnProperty.call(subjectsList, i)) {
              for (const j in subjects) {
                if (subjects[j] === subjectsList[i].code) {
                  subject_list.push({
                    subjectId: subjectsList[i].code,
                    subjectName: subjectsList[i].name,
                  });
                }
              }
            }
          }
          data.subjects = subject_list;
          // 老师
          const { teachers } = data;
          const teacher_list = [];
          for (const i in teacherList) {
            if (Object.prototype.hasOwnProperty.call(teacherList, i)) {
              for (const j in teachers) {
                if (teacherList[i].userId === teachers[j]) {
                  teacher_list.push({
                    teacherId: teacherList[i].userId,
                    teacherName: teacherList[i].name,
                  });
                }
              }
            }
          }
          data.teachers = teacher_list;
          data.materialChapters = teachingList; // 教材章节
          resolve(data);
        } else {
          reject('请填写基本信息！');
        }
      });
    });
  };
  // 返回数据方法
  cbackData = (data: any) => {
    this.setState(
      {
        teachingList: data,
      },
      this.setTeachingTest,
    );
    this.showFlg(false);
  };
  // 显示弹出
  showFlg = (flg: boolean) => {
    this.setState({
      visible: flg,
    });
  };
  // 删除教材
  del = (item: any) => {
    const { teachingList } = this.state;
    const len = teachingList.length;
    const list = [];
    for (let i = 0; i < len; i++) {
      if (teachingList[i].chapterId !== item.chapterId) {
        list.push(teachingList[i]);
      }
    }
    this.setState(
      {
        teachingList: list,
      },
      this.setTeachingTest,
    );
  };
  // 设置 教材章节的 验证值
  setTeachingTest = () => {
    const { teachingList } = this.state;
    if (teachingList.length > 0) {
      this.props.form.setFieldsValue({ materialChapters: 1 });
    } else {
      this.props.form.setFieldsValue({ materialChapters: '' });
    }
  };
  // 限制授课老师个数
  changeTeacher = (value: any) => {
    this.teacherSelector.blur();
    if (value.length > 5) {
      message.warning('授课老师最多可选5个！');
      const data = value.slice(0, 5);
      // 延迟时间 取消多余的 选项
      setTimeout(() => {
        this.props.form.setFieldsValue({ teachers: data });
      }, 100);
    }
  };

  onSearch = async (keyWord: any) => {
    // const { subjects } = this.state;
    // const subjectIdString = subjects.join('|');
    // await getTeacher({ subjectIdString, page: 0, keyWord });
    await getTeacher({ page: 0, keyWord });
    this.setState({ page: 0 });
  };

  onSelect = async () => {
    // const { subjects } = this.state;
    // const subjectIdString = subjects.join('|');
    // await getTeacher({ subjectIdString, page: 0 });
    await getTeacher({ page: 0 });
    this.setState({ page: 0 });
  };

  // 授课老师滚动事件
  onPopupScroll = async (e: any) => {
    e.persist();
    const { target } = e;
    const preHeight = target.scrollHeight;
    if ((target.scrollTop as number) + (target.offsetHeight as number) === target.scrollHeight) {
      // const { page, subjects } = this.state;
      // const subjectIdString = subjects.join('|');
      const { page } = this.state;
      const { totalPages } = this.props;
      if (page < totalPages - 1) {
        // const { status }: any = await getTeacher({ subjectIdString, page: page + 1 });
        const { status }: any = await getTeacher({ page: page + 1 });
        if (status === 200)
          this.setState({ page: page + 1 }, () => {
            target.scrollTop = preHeight - 150;
          });
      }
    }
  };

  render() {
    const { subjectDisabled, booksDisabled, visible, teachingList, subjectsList, subjects } =
      this.state;
    const { teacherList, gradesList, studySectionsList } = this.props;
    const { getFieldDecorator } = this.props.form;
    const studySectionCode = getStudySectionId();

    return (
      <div className="model" id="information">
        {visible ? (
          <AddTeaching
            data={teachingList}
            cancel={this.showFlg}
            cbackData={this.cbackData}
            visible={visible}
          />
        ) : (
          ''
        )}
        <h3>基本信息</h3>
        <div className="information">
          <Form>
            <Form.Item {...formLayout} label="课时名称">
              {getFieldDecorator('lessonName', {
                rules: [
                  {
                    required: true,
                    message: '请输入课时名称！',
                  },
                  {
                    max: 100,
                    message: '字数超出最大限制！',
                  },
                ],
              })(<AJTextField maxLength={100} />)}
            </Form.Item>
            <Row>
              <Col span={6}>
                <Form.Item {...formLayout1} label="学段">
                  {getFieldDecorator('schoolSection', {
                    rules: [
                      {
                        required: true,
                        message: '请选择学段！',
                      },
                    ],
                  })(
                    <Select placeholder="请选择" disabled>
                      {studySectionsList.map((item: any) => (
                        <Select.Option value={item.code} key={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item {...formLayout1} label="学年">
                  {getFieldDecorator('schoolYear', {
                    rules: [
                      {
                        required: true,
                        message: '请选择学年！',
                      },
                    ],
                  })(
                    <Select placeholder="请选择" onChange={this.schoolYearChange}>
                      {schoolYears.map((item: string, index: number) => (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item {...formLayout} label="年级">
              {getFieldDecorator('grades', {
                rules: [
                  {
                    required: true,
                    message: '请选择年级！',
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择"
                  onChange={this.gradeChange}
                  ref={(ref: any) => (this.gradeSelector = ref)}
                >
                  {gradesList.map((item: any) => (
                    <Select.Option value={item.code} key={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            {studySectionCode === 'GZ' && (
              <Form.Item {...formLayout} label="文理科">
                {getFieldDecorator('artScience', {
                  rules: [
                    {
                      required: true,
                      message: '请选择文理科！',
                    },
                  ],
                })(
                  <Select placeholder="请选择">
                    {this.artScienceLists.map((item: any) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            )}
            <Form.Item {...formLayout} label="学科">
              {getFieldDecorator('subjects', {
                rules: [
                  {
                    required: true,
                    message: '请选择学科！',
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  placeholder="请选择"
                  onChange={this.changeSubject}
                  disabled={subjectDisabled}
                  ref={(ref: any) => (this.subjectSelector = ref)}
                >
                  {subjectsList.map((item: any) => (
                    <Select.Option value={item.code} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...formLayout1} label="授课教师">
              {getFieldDecorator('teachers', {
                rules: [
                  {
                    required: true,
                    message: '请选择授课老师！',
                  },
                ],
              })(
                <Select
                  style={{ width: '480px' }}
                  // {...(teacherDisabled ? { open: false } : '')}
                  mode="multiple"
                  placeholder="请选择"
                  optionFilterProp="children"
                  onChange={this.changeTeacher}
                  // disabled={teacherDisabled}
                  onSearch={this._onSearch}
                  onSelect={this.onSelect}
                  onPopupScroll={this.onPopupScroll}
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      this.onSearch('');
                    }
                  }}
                  ref={(ref: any) => (this.teacherSelector = ref)}
                  getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
                >
                  {teacherList.map((item: any) => (
                    <Select.Option value={item.userId} key={item.userId}>
                      {item.name}（{item.phone}）
                    </Select.Option>
                  ))}
                </Select>,
              )}
              <span className="gray_font" style={{ marginLeft: '5px' }}>
                最多可添加5位教师
              </span>
            </Form.Item>
            <Row className="teachingMaterial">
              <Col span={24}>
                <Form.Item {...formLayout2} label="教材章节">
                  <Button
                    className="default"
                    onClick={() => this.showFlg(true)}
                    disabled={booksDisabled}
                  >
                    请选择教材章节
                  </Button>
                  {getFieldDecorator('materialChapters', {
                    rules: [
                      {
                        required: !(subjects || []).includes('QT'),
                        message: '请选择教材章节！',
                      },
                    ],
                  })(<Input type="hidden" />)}
                  <span className="gray_font" style={{ marginLeft: '5px' }}>
                    最多可添加10个章节
                  </span>
                  <div>
                    {teachingList &&
                      teachingList.map((item: any) => (
                        <Tag onClose={() => this.del(item)} closable key={item.chapterId}>
                          {item.chapterName}
                        </Tag>
                      ))}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item {...formLayout2} label="标签">
              {getFieldDecorator('labels')(<TagSelect groupCode="kechenggonggao" />)}
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 },
};
const formLayout1: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 16 },
};
const formLayout2: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const mapStateToProps = (state: any) => ({
  filteredTeacherList: state.Base.filteredTeacherList,
  totalPages: state.Base.totalPages,
  teacherList: state.Base.teacherList,
  studySectionsList: state.Base.studySectionsList,
  gradesList: state.Base.gradesList,
  Information: state.Lesson.classHourDetail.Information,
  materialChapters: state.Lesson.classHourDetail.materialChapters,
  classHourList: state.Lesson.classHourList, // 课时列表
});

const mapDispatchToProps = (dispatch: any) => ({
  reload: () => dispatch({ type: 'TEACHER_LIST_REQUEST_RELOAD', payload: [] }),
  updateSubjectIds: (subjectIds: string[]) => {
    dispatch({ type: 'UPDATE_SUBJECTIDS', payload: subjectIds });
  },
  updateTeacherDisabled: (status: boolean) => {
    dispatch({ type: 'UPDATE_TEACHER_DISABLED', payload: status });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<P>()(Information));
