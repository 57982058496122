/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';

interface P {}
class Content extends React.PureComponent<P, any> {
  render() {
    const minHeight = window.innerHeight - 278 - 149;
    return (
      <div className="Content-Component" id="ContentComponent" style={{ minHeight }}>
        {this.props.children}
      </div>
    );
  }
}
export default Content;
