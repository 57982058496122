import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSearchParams } from '../../../util';
import { getMessageDetails, hasReadMessage } from '../../../redux/action/message.action';
import './index.less';

function MessageDetails() {
  const { details } = useSelector((store: any) => ({
    details: store.Message.messageDetails,
  }));

  useEffect(() => {
    const { id } = getSearchParams();
    if (id) {
      getMessageDetails(id);
    }
    return () => {
      hasReadMessage();
    };
  }, []);

  return (
    <div className="message-details">
      {details && (
        <div>
          <div className="message-title">{details.name}</div>
          <div className="details" dangerouslySetInnerHTML={{ __html: details.content }} />
        </div>
      )}
    </div>
  );
}

export default memo(MessageDetails);
