import { logout } from "eastedu-login-sdk";
import getWechatLink from "../config/wechatLink";

export const logoutAccount = () => {
  const loginLink = getWechatLink();
  window.localStorage.removeItem('userInfo');
  window.localStorage.removeItem('unionId');
  window.localStorage.removeItem('ROOTTYPE');
  // window.location.href = getWechatLink();
  // oauth2Logout();
  logout(loginLink);
};
