import React, { memo, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { SearchParams } from '../lib/searchInterface';
import FilterResources from '../../ResourceLib/components/FilterResources';
import { globalSearchParams } from '../lib/configParams';
import { INITIAL_FILTER_STATUS } from '../../../redux/types/globalSearch.type';

interface P {
  category: string;
  keywords: string;
  onFilterChange: (params: SearchParams) => void;
}

function SearchFilter(props: P) {
  const { category, keywords, onFilterChange } = props;
  const searchParams = useRef({ ...globalSearchParams }); // 查询参数
  const dispatch = useDispatch();
  const {
    resourceCategory,
    gradeId,
    subjectId,
    schoolPeriod,
    materialId,
    chapterId,
    page,
    size,
    schoolSection,
  } = searchParams.current;
  const [selfSearchParams, setSelfSearchParams] = useState({
    category: resourceCategory,
    gradeId,
    subjectId,
    schoolPeriod,
    materialId,
    chapterId,
    page,
    size,
    schoolSection,
  });
  const [hideCategory, setHideCategory] = useState(false);

  useEffect(() => {
    if (category !== '2') {
      setHideCategory(true);
    } else {
      setHideCategory(false);
    }
  }, [category]);

  useEffect(() => {
    dispatch({
      type: INITIAL_FILTER_STATUS,
      payload: () => {
        searchParams.current = { ...globalSearchParams };
        const {
          resourceCategory,
          gradeId,
          subjectId,
          schoolPeriod,
          materialId,
          chapterId,
          page,
          size,
          schoolSection,
        } = searchParams.current;
        setSelfSearchParams({
          category: resourceCategory,
          gradeId,
          subjectId,
          schoolPeriod,
          materialId,
          chapterId,
          page,
          size,
          schoolSection,
        });
      },
    });
    return () => {
      dispatch({ type: INITIAL_FILTER_STATUS, payload: () => {} });
    };
  }, [dispatch]);

  // 筛选的数据
  const getChildFilterParams = (params: any) => {
    const tempCategory = params.category;
    setSelfSearchParams({ ...params });
    delete params.category;
    const tempParams = { ...params, resourceCategory: tempCategory };
    searchParams.current = { ...searchParams.current, ...tempParams, category, text: keywords };
    onFilterChange(searchParams.current);
  };

  return (
    <FilterResources
      category={category}
      hideCategory={hideCategory}
      initFilterParams={selfSearchParams}
      dispatchFilterParams={getChildFilterParams}
      isGlobalSearch
    />
  );
}

export default memo(SearchFilter);
