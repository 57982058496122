/*
 * @Author: gouyang
 * @Description: 统计播放时长
 * @Date: 2019-08-22 17:21:13
 */
import { submitStatisticData } from '../lib/api';

interface pushData {
  fileId: string;
  resourceId: string;
  lessonId: string;
  courseId: string;
  source: number;
}

class _PlayTime_ {
  speed = 1; // 播放速率
  private submitData: any;
  private totalTimer: any;
  private playTime: boolean;
  private groupId: number;
  private timeInterval: number[];
  private videoDuration: number;
  private isStarted: boolean;
  private ids: pushData;
  private isDraged: boolean;

  constructor() {
    this.submitData = null;
    this.totalTimer = null;
    this.playTime = false;
    this.groupId = new Date().getTime();
    this.timeInterval = [];
    this.videoDuration = 0;
    this.isStarted = false;
    this.isDraged = false;
    this.ids = {
      fileId: '',
      resourceId: '',
      lessonId: '',
      courseId: '',
      source: 0,
    };
  }

  // 开始统计播放时长
  startPlayTime(ids: pushData) {
    this.ids = ids;
    this.playTime = true;

    // 通过拖拽触发的播放
    if (this.isDraged) {
      this.isDraged = false;
      this.didDragged();
    } else {
      this.groupId = new Date().getTime();
      this.isStarted = true;
      this.didPlayed();
    }
  }

  // 停止统计播放时长
  stopPlayTime() {
    if (!this.isDraged) {
      this.playTime = false;
      this.videoEnd();
    }
  }

  // 清空状态
  clearStatus() {
    const result = this.getPlayTimeInterval();
    if (result) {
      this.submitPlayTimeData(result, this.groupId);
    }

    this.submitData = null;
    this.timeInterval = [];
    this.groupId = new Date().getTime();
    this.videoDuration = 0;
    this.isStarted = false;
    this.playTime = false;
    this.isDraged = false;
    this.clearTimer();
  }

  // 提交时长
  async submitPlayTimeData(duration: any, groupId: number) {
    const serverTime = window.sessionStorage.getItem('SERVERTIME') || ''; // 服务器时间

    this.submitData = {
      timeTick: Number(serverTime),
      ...duration,
      ...this.ids,
      groupId: `${groupId}`,
      videoDuration: this.videoDuration,
      speed: this.speed || 1,
    };
    await submitStatisticData(this.submitData);
  }

  // 获取播放时间间隔
  getPlayTimeInterval() {
    const l = this.timeInterval.length;
    if (l > 0) {
      const timeInterval = {
        start: Math.floor(this.timeInterval[0]),
        end: Math.floor(this.timeInterval[l - 1]),
      };
      this.timeInterval.length = 0;
      return timeInterval;
    } else {
      return null;
    }
  }

  // 清空定时器
  clearTimer() {
    clearInterval(this.totalTimer);
    this.totalTimer = null;
  }

  // 拖动时提交
  didDragged() {
    const result = this.getPlayTimeInterval();
    if (result) {
      const { end } = result;

      this.submitPlayTimeData(result, this.groupId);
      this.groupId = new Date().getTime();
      this.submitPlayTimeData(
        {
          start: end,
          end,
        },
        this.groupId,
      );
      this.didPlayed();
    }
  }

  // 改变视频播放速率时提交
  changePlayRate(speed: number) {
    this.clearTimer();
    const result = this.getPlayTimeInterval();
    if (result) {
      const { end } = result;
      this.submitPlayTimeData(result, this.groupId);

      this.speed = speed;
      this.groupId = new Date().getTime();
      this.submitPlayTimeData(
        {
          start: end,
          end,
        },
        this.groupId,
      );
      this.didPlayed();
    }
  }

  // 视频播放中时长提交
  didPlayed() {
    if (this.totalTimer) return null;

    this.totalTimer = setInterval(() => {
      const result = this.getPlayTimeInterval();

      if (this.playTime) {
        if (result) {
          this.submitPlayTimeData(result, this.groupId);
        }
      }
    }, 5000);
  }

  // 视频播放完毕时长提交
  videoEnd() {
    this.clearTimer();
    const result = this.getPlayTimeInterval();
    result && this.submitPlayTimeData(result, this.groupId);
  }

  // 获取开始和结束点
  getStartAndEnd(currentTime: number, totalDuration: number, isTurnTo: boolean) {
    if (!this.playTime) return;

    const end = this.timeInterval[this.timeInterval.length - 1];
    const duration = this.computeDuration(end, currentTime);
    this.videoDuration = Math.floor(totalDuration);
    this.timeInterval.push(currentTime);
    if (this.isStarted) {
      this.isStarted = false;
      this.submitPlayTimeData(
        {
          start: Math.floor(currentTime),
          end: Math.floor(currentTime),
        },
        this.groupId,
      );
    }
    if (isTurnTo) {
      this.clearTimer();
      this.didDragged();
    } else if (duration > 1.5) {
      this.isDraged = true;
      this.clearTimer();
    }
  }

  computeDuration(x: number, y: number) {
    return Math.abs(y * 1000000 - x * 1000000) / 1000000;
  }
}

export const PlayTime = () => new _PlayTime_();
