/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author: gouyang
 * @Description: 全局创建同步reducer
 * @Date: 2019-10-10 14:38:05
 */
import { AnyAction } from 'redux';
import {
  GLOBALCREATESYNCTYPE,
  ROOTOPTION,
  GETDATABYROOT,
  INITIALTIMETABLEROOTINFO,
  UPDATEADDRESSBARID,
  UPDATEOPERATIONSTATUS,
} from '../types/globalCreateSync.type';

const initialState = (() => ({
  currentRootType: null, // 当前权限类型
  rootOption: [], // 权限选项
  noticeRootOption: [], // 公告权限
  resourceRootOption: [], // 资源权限
  getDataByRoot: (type: string) => {}, // 根据权限获取数据
  getTimetableData: (type: string) => {}, // 根据权限获取课程表数据
  addressBarColumnId: '', // 当前地址栏中所选栏目id
  isOperation: true, // 是否可以进行操作
}))();

const GloabalCreateSync = (state = { ...initialState }, { type, payload }: AnyAction) => {
  const rootType = window.localStorage.getItem('ROOTTYPE');
  switch (type) {
    case ROOTOPTION: // 栏目权限
      const { rootOption, noticeRootOption, resourceRootOption } = payload;
      let _currentRootType_ = (rootOption[0] && rootOption[0].type) || '';
      const rootResult = [...rootOption].findIndex((item: any) => item.type === rootType);
      if (rootResult !== -1) {
        _currentRootType_ = rootType;
      } else {
        window.localStorage.setItem('ROOTTYPE', _currentRootType_);
      }

      return Object.assign({}, state, {
        rootOption: [...rootOption],
        noticeRootOption,
        resourceRootOption,
        currentRootType: _currentRootType_,
      });
    case GLOBALCREATESYNCTYPE: // 更新当前权限类型
      const currentPrivilegeType = payload || state.currentRootType;

      return Object.assign({}, state, { currentRootType: currentPrivilegeType });
    case GETDATABYROOT: // 根据权限类型获取数据
      return Object.assign({}, state, { getDataByRoot: payload });
    case INITIALTIMETABLEROOTINFO: // 初始化课程表权限信息
      const { privilegeType, fn } = payload;
      return Object.assign({}, state, { currentRootType: privilegeType, getTimetableData: fn });
    case UPDATEADDRESSBARID: // 更新地址栏中所选栏目id
      return Object.assign({}, state, { addressBarColumnId: payload });
    case UPDATEOPERATIONSTATUS: // 更新操作状态
      return Object.assign({}, state, { isOperation: payload });
    default:
      return { ...state };
  }
};

export default GloabalCreateSync;
