// 查询基础信息
import AxiosWrap from '../../lib/request';
import * as types from '../types/account.type';
import { dispatch } from '../../createStore';
import { message } from 'antd';

interface GetOrganizationsRequestParams {
  dashboardCode: string;
  organizationName: string;
}

// 获取 学段列表
export const getOrganizations = async (params: GetOrganizationsRequestParams) => {
  try {
    const { dashboardCode, ...rest } = params;
    const { status, data } = await AxiosWrap({
      url: `/dashboards/${dashboardCode}/organizations`,
      params: rest,
    });
    if (status === 200) {
      dispatch({ type: types.ORGANIGATIONS, payload: data });
      return data;
    }
    return [];
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
    return [];
  }
};

interface GetUSersRequestParams {
  page: number;
  size: number;
  leagueId: string;
  orgId: string;
  labelId?: string;
  keyWord?: string;
}

// 获取用户列表
export const getUsers = async (params: GetUSersRequestParams) => {
  return AxiosWrap({
    url: `/users`,
    params,
  });
};

interface GetLabelsRequestParams {
  page: number;
  size: number;
  code: string;
}

// 获取标签列表
export const getLabels = async (params: GetLabelsRequestParams) => {
  return AxiosWrap({
    url: `/labels`,
    params,
  });
};

// 获取学段列表
export const getStudySections = async (organizationId: string) => {
  return AxiosWrap({
    url: `/ebi/organizations/${organizationId}/study_sections`,
  });
};

// 获取学段下对应学科列表
export const getObjectsBySection = async (studySectionCode: string) => {
  return AxiosWrap({
    url: `/base_query_conditions/subjects`,
    params: {
      studySectionCode,
    },
  });
};
