/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty */
import AxiosWrap from '../../lib/request';
import Ajax from '../../lib/Ajax';
import * as types from '../types/article.type';
import { UPDATEOPERATIONSTATUS } from '../types/globalCreateSync.type';
import { dispatch } from '../../createStore';
import { message } from 'antd';
import TrackRequest from '../../lib/trackRequest';
import Alert from '../../components/Alert/Alert';
import isNotRootLink from '../../util/isNotRootLink';

export const createArticle = async (
  params: any,
  columnId: string,
  callback: (success: boolean) => void,
) => {
  try {
    const data = await AxiosWrap({
      url: `/manager/columns/${columnId}/notices`,
      method: 'POST',
      data: params,
    });
    if (data.status === 200) {
      // 保存埋点
      TrackRequest({ name: 'save_created_notice', data: { notice_id: data.data.noticeId } });
      message.success('保存成功！');
      callback(true);
    }
  } catch (error) {
    if (error && (error as any).data) {
      Alert.open((error as any).data.message, () => {
        isNotRootLink();
      });
    }
  }
};
// 编辑公告
export const ediArticle = async (
  params: any,
  noticeId: string,
  callback: (success: boolean) => void,
) => {
  try {
    const { status } = await AxiosWrap({
      url: `/manager/notices/${noticeId}`,
      method: 'PUT',
      data: params,
    });
    if (status === 200) {
      message.success('保存成功！');
      callback(true);
    }
  } catch (error) {
    if (error && (error as any).data) {
      Alert.open((error as any).data.message, () => {
        isNotRootLink();
      });
    }
  }
};
// 文章列表
export const getArticleList = async (params: any, columnId: string, leftMenuType: string) => {
  let url = '';
  if (leftMenuType === '0') {
    // 我的创建
    url = `/manager/columns/${columnId}/notices`;
  } else if (leftMenuType === '2') {
    // 我的同步
    url = `/columns/${columnId}/notices`;
  }
  try {
    dispatch({ type: UPDATEOPERATIONSTATUS, payload: false });
    const { status, data } = await AxiosWrap({ url, params });
    if (status === 200) {
      const article = {
        total: data.page.totalElements,
        pageNumber: data.page.number + 1,
        data: [],
      };
      if (data._embedded && data._embedded.notices) {
        article.data = data._embedded.notices;
        dispatch({ type: types.ARTICLE_LIST_REQUEST, payload: article });
      } else if (params.page > 0) {
        params.page -= 1;
        getArticleList(params, columnId, leftMenuType);
      } else {
        dispatch({ type: types.ARTICLE_LIST_REQUEST, payload: article });
      }
    }
    dispatch({ type: UPDATEOPERATIONSTATUS, payload: true });
  } catch (error) {
    dispatch({ type: UPDATEOPERATIONSTATUS, payload: true });
    if (error && (error as any).status === 403) {
      Alert.open('暂无权限！', () => {
        isNotRootLink();
      });
      return;
    }
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 文章删除
export const getArticleDel = async (noticeId: string, cback: () => void) => {
  try {
    const { status } = await AxiosWrap({ url: `/manager/notices/${noticeId}`, method: 'DELETE' });
    if (status === 200) {
      message.success('操作成功！');
      cback();
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 文章详情
export const articleDetail = async (noticeId: string) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/notices/${noticeId}` });
    if (status === 200) {
      const tags = data.labels
        ? data.labels.map((label: any, i: number) => ({
            id: label.labelId,
            name: label.labelName,
            key: new Date().getTime() + i,
          }))
        : [];
      dispatch({ type: types.ARTICLE_DETAIL, payload: data });
      dispatch({ type: types.UPDATETAGS, payload: tags });
    }
  } catch (error) {}
};
// 文章发布 / 取消发布
export const getArticlePush = async (params: any, cback: () => void) => {
  try {
    const { status } = await AxiosWrap({
      url: `/manager/notices/${params.id}/state`,
      method: 'PUT',
      data: params.data,
    });
    if (status === 200) {
      message.success('操作成功！');
      cback();
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 文章置顶 / 取消置顶
export const getArticleTop = async (params: any, cback: () => void) => {
  try {
    const { status } = await AxiosWrap({
      url: `/manager/notices/${params.id}/top`,
      method: 'PUT',
      data: params.data,
    });
    if (status === 200) {
      message.success('操作成功！');
      cback();
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

//  检查文章是否存在
export const getCheckExist = async (id: string) => {
  try {
    const { status }: any = await Ajax({ url: `/notices/${id}` });
    if (status === 200) {
      return Promise.resolve(true);
    }
  } catch (error) {
    if (error && (error as any).status === 404) {
      message.error('公告不存在！');
      return;
    }
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

//  检查文章是否存在 我的创建
export const getCheckExistInCreate = async (id: string) => {
  try {
    const { status }: any = await Ajax({ url: `/manager/notices/${id}` });
    if (status === 200) {
      return Promise.resolve(true);
    }
  } catch (error) {
    if (error && (error as any).status === 404) {
      message.error('公告不存在！');
      return;
    }
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
