/*
 * @Author: gouyang
 * @Description: 富文本编辑器
 * @Date: 2019-07-16 11:25:53
 */

import React from 'react';
import { message } from 'antd';
import { menus, filterPasteText } from './TextEditorConfig';
import fileServiceUpload from '../../util/fileServiceUpload';

interface P {
  getData: (html: string, text: string) => void;
  editorHtml: string;
  getEditorObj?: (target: any) => void;
  getWordCount?: (l: number) => void;
}

class TextEditor extends React.PureComponent<P> {
  private E: any; // 编辑器对象
  private dom: any; // dom节点
  private instanceE: any; // 编辑器实例对象
  private insert: any; // 插入图片函数
  private wordCount = 0;
  constructor(props: P) {
    super(props);
    this.dom = React.createRef();
    this.E = (window as any).E;
  }

  componentDidMount() {
    const { getEditorObj, editorHtml } = this.props;
    this.inilializeEditor();
    this.instanceE.txt.html(editorHtml);
    getEditorObj && getEditorObj(this.instanceE);
  }

  componentDidUpdate(prevProps: P) {
    if (prevProps.editorHtml !== this.props.editorHtml) {
      const { getWordCount } = this.props;
      if (this.wordCount === 0) {
        // 当onChange被触发后，editorHtml变化会导致此处被执行
        // onChange内部已经执行过getPureText方法
        // 增加判断避免重复计算
        this.getPureText();
      }
      getWordCount && getWordCount(this.wordCount);
      this.wordCount = 0;
    }
  }

  // 初始化编辑器
  inilializeEditor() {
    this.instanceE = new this.E(this.dom.current);
    this.instanceE.customConfig.menus = menus;
    this.instanceE.customConfig.onchangeTimeout = 16;
    this.instanceE.customConfig.fontNames = ['宋体', 'Arial', 'Tahoma', 'Verdana'];
    this.uploadImgConfig();
    this.getEditorContent();
    this.addEventListenerPaste();
    this.instanceE.create();
  }
  // 监听编辑器内容变化
  getEditorContent() {
    const { getData } = this.props;
    this.instanceE.customConfig.onchange = (html: string) => {
      getData(html, this.getPureText());
    };
  }

  getPureText(): string {
    const text = this.instanceE.txt.text();
    let pureText = text.replace(/&nbsp;/g, ' ');
    pureText = pureText.replace(/<[^>]+>/g, '');
    this.wordCount = pureText ? pureText.length : 0;
    return pureText;
  }

  // 监听粘贴变化
  addEventListenerPaste() {
    this.instanceE.customConfig.pasteTextHandle = function (content: string) {
      const txt = content ? filterPasteText(content) : '';
      return txt;
    };
  }
  // 上传图片配置
  uploadImgConfig() {
    // 配置服务器端地址 apiConfig.file
    // this.instanceE.customConfig.uploadImgServer = '/';
    // this.instanceE.customConfig.uploadImgShowBase64 = true;
    this.instanceE.customConfig.uploadImgMaxLength = 1;
    // 将图片大小限制为 2G
    this.instanceE.customConfig.uploadImgMaxSize = 1024 * 1024 * 1024 * 2;
    this.instanceE.customConfig.showLinkImg = false;
    // 定义提示语
    this.instanceE.customConfig.customAlert = (info: string) => {
      if (info === '一次最多上传1张图片') {
        message.error(info);
        return;
      }
      message.error('图片大小不能超过2G！');
    };
    this.instanceE.customConfig.customUploadImg = (files: any[], insert: any) => {
      this.insert = insert;
      fileServiceUpload(files[0], this.uploadImg.bind(this));
    };
  }
  // 文件上传方法
  uploadImg(result: any) {
    if (result.status === 'done') {
      const { data } = result;
      this.insert(data.path);
    }
  }

  render() {
    return <div ref={this.dom} />;
  }
}

export default TextEditor;
