/*
 * @Author: gouyang
 * @Description: 公告types
 * @Date: 2019-07-17 10:05:16
 */
export const ARTICLE_NAME = 'ARTICLE_NAME'; // 公告名称
export const EDITOR_DATA = 'EDITOR_DATA'; // 编辑器内容
export const PUBLISH_RANGE = 'PUBLISH_RANGE'; // 发布范围
export const UPLOAD_DATA = 'UPLOAD_DATA'; // 上传资料
export const ARTICLE_LIST_REQUEST = 'ARTICLE_LIST_REQUEST'; // 公告列表
export const ARTICLE_DATA = 'ARTICLE_DATA'; // 文章内容
export const ARTICLE_DETAIL = 'ARTICLE_DETAIL'; // 文章详情
export const EDITOR_TARGET = 'EDITOR_TARGET'; // 文本编辑器对象
export const RESET_ARTICLE_LISTS = 'RESET_ARTICLE_LISTS'; // 重置列表
export const CLEAN_FILE_LISTS = 'CLEAN_FILE_LISTS'; // 清洗文件数组
export const DEADLINE = 'DEADLINE'; // 截止时间
export const UPDATETAGS = 'UPDATETAGS'; // 更新标签
