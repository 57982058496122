/**
 *  @desc    媒体资源播放（图片类：jpg等图片类资源）
 *  @author  huangyu
 *  @params
 */

import React, { Component } from 'react';
import './image.less';

export default class ImageComponent extends Component {
  static defaultProps = {
    pause: true,
    playerCallBack: () => {},
  };
  constructor() {
    super();
    this.state = {
      address:
        'http://125.69.76.128:10000/devstoreaccount1/data/FCRClient-E-Windows/20170930/108532-03247c12fa4a46ce8973be4ce60664cf.jpg',
    };
  }
  render() {
    const { mediaAddress } = this.props;
    return (
      <div className="image-comtent">
        <img crossOrigin="anonymous" className="image-resource" src={mediaAddress} alt="" />
      </div>
    );
  }
}
