/*
 * @Descripttion: 直播教师选择
 * @Author: gouyang
 * @Date: 2021-01-08 09:55:49
 * @LastEditors: jiyong_gou
 * @LastEditTime: 2022-12-29 15:45:41
 */

import React, { useCallback, useEffect, useRef } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getTeacher } from '../../../redux/action/base.action';

interface P {
  value?: any;
  onChange?: (...argst: any) => void;
}

const SelectTeacher = React.forwardRef<any, P>((props) => {
  const { value, onChange } = props;
  const page = useRef<number>(0);
  const _onSearch = useRef<(...argst: any) => void>(() => {});
  const { totalPages, teacherList, subjectIds, teacherDisabled } = useSelector((store: any) => ({
    totalPages: store.Base.totalPages,
    teacherList: store.Base.teacherList,
    subjectIds: store.Live.subjectIds,
    teacherDisabled: store.Live.teacherDisabled,
  }));

  const onSearch = useCallback(
    async (keyword?: any) => {
      const subjectIdString = subjectIds.join('|');
      const params: { [s: string]: string | number } = { subjectIdString, page: 0 };
      if (keyword) params.keyWord = keyword;
      await getTeacher(params);
      page.current = 0;
    },
    [subjectIds],
  );

  const onSelect = useCallback(() => {
    onSearch('');
  }, [onSearch]);

  const onPopupScroll = useCallback(
    async (e: any) => {
      e.persist();
      const { target } = e;
      const preHeight = target.scrollHeight;
      if ((target.scrollTop as number) + (target.offsetHeight as number) === target.scrollHeight) {
        const subjectIdString = subjectIds.join('|');
        if (page.current < totalPages - 1) {
          /**
           * 不必初始化教师列表
           * 在基本信息里面已经初始过
           */
          const { status }: any = await getTeacher({ subjectIdString, page: page.current + 1 });
          if (status === 200) {
            page.current += 1;
            target.scrollTop = preHeight - 150;
          }
        }
      }
    },
    [subjectIds, totalPages],
  );

  useEffect(() => {
    _onSearch.current = _.debounce(onSearch, 800);
  }, [onSearch]);

  return (
    <Select
      {...(teacherDisabled ? { open: false } : '')}
      allowClear
      showSearch
      value={value}
      onChange={onChange}
      placeholder="请选择"
      optionFilterProp="children"
      disabled={teacherDisabled}
      onSearch={_onSearch.current}
      onSelect={onSelect}
      onPopupScroll={onPopupScroll}
      getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
    >
      {teacherList.map((item: any) => (
        <Select.Option value={`${item.userId}&&${item.name}`} key={item.userId}>
          {item.name}（{item.phone}）
        </Select.Option>
      ))}
    </Select>
  );
});

export default SelectTeacher;
