/*
 * @Descripttion:
 * @Author: gouyang
 * @Date: 2021-03-12 16:27:49
 * @LastEditors: gouyang
 * @LastEditTime: 2021-03-26 13:16:59
 */
import { message, Modal } from 'antd';
import AxiosWrap from '../../lib/request';
import * as types from '../types/live.type';
import * as vodTypes from '../types/vod.type';
import Alert from '../../components/Alert/Alert';
import isNotRootLink from '../../util/isNotRootLink';

export const liveAction = {
  lessonInfo: (
    lessonId: string,
    leftMenuType: string,
    success: (liveId: string, state: number) => void,
  ) => {
    let url = `/lesson/${lessonId}`;
    if (leftMenuType === '2') {
      url = `/lesson/my-fallow/${lessonId}`;
    }
    AxiosWrap({
      url,
    })
      .then((res) => {
        const { liveId, state, startTime } = res.data.live;
        if (startTime > new Date().getTime() + 30 * 60 * 1000) {
          message.warning('直播未开始！');
        } else {
          (window as any)._dispatch({ type: types.LESSON_INFO, payload: res.data });
          success(liveId, state);
        }
      })
      .catch((res) => {
        if (res && res.status === 403) {
          Alert.open('暂无权限！', () => isNotRootLink());
          return;
        }
        res && res.data && message.error(res.data.message);
      });
  },
  liveInfo: (lessonId: string, liveId: string) => {
    AxiosWrap({
      url: `/lesson/${lessonId}/live/${liveId}`,
    })
      .then((res) => {
        (window as any)._dispatch({ type: types.LIVE_INFO, payload: res.data });
      })
      .catch((error: any) => {
        if (error && error.data) {
          const { message } = error.data;
          Modal.info({
            title: message,
            onOk() {
              (window as any).history.back();
            },
          });
        }
      });
  },
  getLiveLesson: async (lessonId: string) => {
    try {
      const { status } = await AxiosWrap({
        url: `/lesson/${lessonId}/check-exist`,
        ignoreLoading: true,
      });
      (window as any)._dispatch({ type: vodTypes.LESSON_EXIST, payload: true });
      return Promise.resolve({ status });
    } catch (err) {
      (window as any)._dispatch({ type: vodTypes.LESSON_EXIST, payload: false });
      if (err && err.status === 404) {
        message.error('课时不存在！');
      }
      if (err && err.status === 403) message.error('暂无权限！');
      return Promise.resolve({ status: 400 });
    }
  },
  getOrg: () => {
    AxiosWrap({
      url: `/auth/organization`,
    }).then((res) => {
      if (res && res.data && res.data.name) {
        localStorage.setItem('orgName', res.data.name);
      }
    });
  },
};

export const playBackAction = {
  playBackInfo: (lessonId: string, liveId: string) => {
    AxiosWrap({
      url: `lesson/${lessonId}/live/${liveId}/playback`,
    })
      .then((res) => (window as any)._dispatch({ type: types.REVIEW_INFO, payload: res.data }))
      .catch((e) => {
        if (e && e.status === 400 && e.data && e.data.message) {
          Modal.info({
            title: e.data.message,
            onOk() {
              (window as any).history.back();
            },
          });
        }
      });
  },
};
