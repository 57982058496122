/* eslint-disable react/no-deprecated */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Pagination, Popconfirm, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import { getSearchParams, stringifySearchParams } from '../../util';
import Empty from '../Empty';
import TrackRequest from '../../lib/trackRequest';
import Tablist from '../../components/Tablist';
import EmptyList from '../../components/Empty';
import ColumnList from '../../components/ColumnList';
import CreateSearch from '../../components/CreateSearch';
import ListTags from '../../components/ListTags';
import { updateButtonStatus } from '../../redux/action/menu.action';
import { updateSearchValue } from '../../redux/action/createSearch.action';
import {
  getArticleList,
  getArticleDel,
  getArticlePush,
  getArticleTop,
  getCheckExist,
  getCheckExistInCreate,
} from '../../redux/action/article.action';
import './index.less';

interface obj {
  [key: string]: any;
}
interface P extends RouteComponentProps {
  Article: obj; // 公告列表
  history: any;
  registerGetDataFn: any;
  noticeRootOption: any[];
  addressBarColumnId: string;
  updateCurrentRootType: any;
  resetArticleLists: () => {};
  rootOption: any[];
}
interface S {
  leftNav: Array<{ [key: string]: any }>; // 左侧菜单
  pushState: string | number; // 发布状态
  pageNumber: number; // 页码
  pageSize: number; // 每页个数
  activeReleaseStateKey: any;
  isRoot: boolean; // 是否具有权限
}
class ArticleList extends React.Component<P, S> {
  searchParams: obj; // 地址栏？后的参数
  actionType: string | null; // 左侧菜单激活类型
  private keyword: string;
  constructor(props: P) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 20,
      pushState: '',
      leftNav: [...this.props.noticeRootOption],
      activeReleaseStateKey: 0,
      isRoot: true,
    };
    this.actionType = '';
    this.searchParams = getSearchParams(props.location.search);
    this.keyword = '';
  }
  componentDidMount() {
    this.props.resetArticleLists();
    this.props.registerGetDataFn(this.handleMenuClick);
    this.initData();
  }
  componentWillReceiveProps(nextProps: P) {
    this.searchParams = getSearchParams(this.props.location.search);
    if (nextProps.addressBarColumnId !== this.props.addressBarColumnId) {
      this.props.resetArticleLists();
      this.initData();
    }
  }
  // 初始化数据
  initData = async () => {
    const { rootOption, noticeRootOption } = this.props;

    const nav = [...noticeRootOption];
    this.actionType = rootOption[0] ? rootOption[0].type : '';
    if (window.localStorage.getItem('ROOTTYPE')) {
      this.actionType = window.localStorage.getItem('ROOTTYPE');
    } else {
      this.props.updateCurrentRootType(this.actionType);
    }
    this.setState(
      {
        leftNav: nav,
      },
      () => this.handleMenuClick(this.actionType),
    );
    // if (judgeSourceType(this.searchParams.name)) {
    //   this.setState({
    //     isRoot: false,
    //   });
    // }
  };
  // 获取数据
  getList = () => {
    const { id } = this.searchParams;
    const { pageNumber, pageSize, pushState } = this.state;
    const data: obj = {
      page: pageNumber - 1,
      size: pageSize,
      state: pushState,
    };
    if (this.keyword) {
      data.name = this.keyword;
    }
    const leftMenuType = this.actionType === 'sync' ? '2' : '0';
    getArticleList(data, id, leftMenuType);
  };

  /**
   * 发布/取消发布公告
   * @param id 公告id
   */
  handleRelease(id: string, state: number) {
    switch (state) {
      case 0: // 取消发布埋点
        TrackRequest({ name: 'confirm_unpub_notice', data: { notice_id: id } });
        break;
      case 1: // 发布埋点
        TrackRequest({ name: 'confirm_pub_notice', data: { notice_id: id } });
        break;
      default:
    }
    getArticlePush({ id, data: { state } }, this.getList);
  }
  /**
   * 删除公告
   * @param id 公告id
   */
  handleDelete = (id: string) => {
    TrackRequest({ name: 'confirm_del_notice', data: { notice_id: id } });
    getArticleDel(id, this.getList);
  };
  /**
   * 置顶/ 取消置顶
   * @param id 公告id
   */
  handleisTop = (id: string, top: boolean) => {
    const { pageNumber } = this.state;
    this.setState(
      {
        pageNumber: top ? 1 : pageNumber,
      },
      () => {
        getArticleTop({ id, data: { top } }, this.getList);
      },
    );
  };
  /**
   * 公告管理
   * @param id 公告id
   */
  toArticleDetail = async (id: string) => {
    // 公告管理
    TrackRequest({ name: 'notice_management', data: { notice_id: id } });
    if (this.actionType === 'create') {
      const flg = await getCheckExistInCreate(id);
      if (flg) {
        this.searchParams.leftMenuType = 0;
        this.searchParams.noticeId = id;
        window.open(`/articleManagement${stringifySearchParams(this.searchParams)}`);
      }
    }
  };
  /**
   * 状态筛选
   * @param name
   */
  handleReleaseChange = (item: any, key?: any) => {
    if (item.releaseState) {
      updateSearchValue(this.keyword);
      this.setState(
        {
          activeReleaseStateKey: key,
          pushState: item.releaseState.value,
          pageNumber: 1,
        },
        this.getList,
      );
    }
  };
  /**
   * 左侧菜单点击
   * @param type 菜单类型 create:我的创建 sync:我的同步
   */
  handleMenuClick = (type: string | null) => {
    // if (judgeSourceType(this.searchParams.name)) return null;
    const { leftNav } = this.state;
    // 判断是否有该权限
    const rootResult = leftNav.findIndex((item: any) => item.type === type);
    if (rootResult === -1) {
      this.setState({
        isRoot: false,
      });
      return null;
    }
    this.actionType = type;
    this.keyword = '';
    updateSearchValue(this.keyword);
    updateButtonStatus({ status: type === 'create', type: 'create_notice', name: '创建公告' });
    this.setState(
      {
        leftNav,
        pageNumber: 1,
        pushState: '',
        activeReleaseStateKey: 0,
        isRoot: true,
      },
      this.getList,
    );
  };
  /**
   * 分页
   * @param page
   * @param pageSize
   */
  changePage = (page: number) => {
    window.scrollTo(0, 0);
    this.setState(
      {
        pageNumber: page,
      },
      this.getList,
    );
  };
  /**
   * 同步查看
   * @param id 公告id
   */
  listItemClick = async (id: string) => {
    if (this.actionType === 'sync') {
      // 观看公告埋点
      TrackRequest({ name: 'watch_notice', data: { notice_id: id, watch_entrance: 0 } });
      const flg = await getCheckExist(id);
      if (flg) {
        this.searchParams.leftMenuType = 2;
        this.searchParams.noticeId = id;
        window.open(`/articleSyncPreview${stringifySearchParams(this.searchParams)}`);
      }
    }
  };

  enterSearch(v: string) {
    this.keyword = v;
    this.setState(
      {
        pageNumber: 1,
      },
      this.getList,
    );
  }

  render() {
    const { activeReleaseStateKey, pageSize, isRoot } = this.state;
    const { total, pageNumber, data } = this.props.Article;
    const releaseProps = {
      type: 'releaseState',
      data: [
        {
          name: '已发布',
          value: 1,
        },
        {
          name: '未发布',
          value: 0,
        },
      ],
      activeKey: activeReleaseStateKey,
      tabChange: this.handleReleaseChange,
    };
    // 分页配置
    const pageConfig = {
      current: pageNumber,
      pageSize,
      total,
      onChange: this.changePage,
      size: 'small',
    };

    return (
      <div className="CourseList-Component ArticleList">
        {
          <div className="page">
            <ColumnList />
            {isRoot ? (
              <div className="right">
                {this.actionType === 'create' ? (
                  <div className="search-filter">
                    <div className="filter">
                      <Tablist key={releaseProps.type} {...releaseProps} />
                    </div>
                    <div className="create-search">
                      <CreateSearch
                        enterSearch={this.enterSearch.bind(this)}
                        placeholder={'请输入公告名称'}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* 我的同步并且数据为空 --->需要设置最小高度 和 控制margin , 
                                    我的同步 数据不为空时 控制 margin
                                    数据为空，不是同步，不需要设置margin
                                */}
                <div
                  className={
                    this.actionType === 'sync' && data.length === 0
                      ? 'content minHeight noMargin'
                      : this.actionType === 'sync'
                      ? 'content noMargin'
                      : data.length === 0
                      ? 'content minHeight'
                      : 'content'
                  }
                >
                  {/* 我的同步 需要设置 标题 hover 效果 */}
                  <div className={this.actionType === 'create' ? 'list' : 'list hover'}>
                    {data.map((item: any) => (
                      <div
                        className={`list-item ${
                          this.actionType === 'create' && item?.source === 'construction_center'
                            ? 'constructionCenter-course'
                            : ''
                        }`}
                        key={item.noticeId}
                        onClick={() => this.listItemClick(item.noticeId)}
                      >
                        <div className="list-left">
                          <span className="month">{moment(item.updateTime).format('MM')}月</span>
                          <span className="day">{moment(item.updateTime).format('DD')}</span>
                        </div>
                        <div className="list-right">
                          <h3>
                            {item.top ? <span className="istop">置顶</span> : ''}
                            {this.actionType === 'create' && item.state === 0 && (
                              <span className="publish">未发布</span>
                            )}
                            {this.actionType === 'sync' && (
                              <>
                                {item.updateState && <span className="istop">有更新</span>}
                                {item.watchState && <span className="publish">已查看</span>}
                              </>
                            )}
                            {item.name}
                          </h3>
                          <div className="time-box">
                            <div className="time-content">
                              {this.actionType === 'create' && (
                                <span>
                                  创建时间：{moment(item.createTime).format('YYYY-MM-DD HH:mm')}
                                </span>
                              )}
                              <span>
                                更新时间：{moment(item.updateTime).format('YYYY-MM-DD HH:mm')}
                              </span>
                              {typeof item.expireTime === 'number' && (
                                <Tooltip
                                  overlayClassName="expire-time-user-class"
                                  title="有效截止时间内查看将会纳入数据统计"
                                >
                                  <span>
                                    有效截止时间
                                    <Icon type="info-circle" />：
                                    {moment(item.expireTime).format('YYYY-MM-DD HH:mm')}
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                            {item.labels && this.actionType !== 'create' && (
                              <ListTags tags={item.labels} />
                            )}
                          </div>
                          {this.actionType === 'create' && (
                            <div className="grade-subject">
                              <span>
                                年级：
                                {(item.grades || []).length === 0
                                  ? '全部'
                                  : item.grades.map((t: any) => t.name).join('/')}
                              </span>
                              <span>
                                学科：
                                <span
                                  title={
                                    (item.subjects || []).length === 0
                                      ? '全部'
                                      : item.subjects.map((t: any) => t.name).join('/')
                                  }
                                >
                                  {(item.subjects || []).length === 0
                                    ? '全部'
                                    : item.subjects.map((t: any) => t.name).join('/')}
                                </span>
                              </span>
                            </div>
                          )}
                          <div className="btns-box">
                            {this.actionType === 'create' && (
                              <>
                                {item.labels && (
                                  <div>
                                    <ListTags tags={item.labels} />
                                  </div>
                                )}
                                <div className="btns">
                                  {
                                    // 发布没有 删除按钮
                                    item.state !== 1 ? (
                                      <Popconfirm
                                        placement="bottom"
                                        title="确定删除该公告吗？"
                                        onConfirm={() => this.handleDelete(item.noticeId)}
                                        cancelText="取消"
                                        okText="确定"
                                      >
                                        <Button className="default">删除</Button>
                                      </Popconfirm>
                                    ) : (
                                      ''
                                    )
                                  }

                                  {item.state === 0 ? (
                                    <Popconfirm
                                      placement="bottom"
                                      title="确定发布该公告吗？"
                                      onConfirm={() => this.handleRelease(item.noticeId, 1)}
                                      cancelText="取消"
                                      okText="确定"
                                    >
                                      <Button className="default">发布</Button>
                                    </Popconfirm>
                                  ) : (
                                    ''
                                  )}
                                  {item.state === 1 ? (
                                    <Popconfirm
                                      placement="bottom"
                                      title="确定取消发布该公告吗？"
                                      onConfirm={() => this.handleRelease(item.noticeId, 0)}
                                      cancelText="取消"
                                      okText="确定"
                                    >
                                      <Button className="default">取消发布</Button>
                                    </Popconfirm>
                                  ) : (
                                    ''
                                  )}
                                  {item.top ? (
                                    <Button
                                      className="default"
                                      onClick={() => this.handleisTop(item.noticeId, false)}
                                    >
                                      取消置顶
                                    </Button>
                                  ) : (
                                    <Button
                                      className="default"
                                      onClick={() => this.handleisTop(item.noticeId, true)}
                                    >
                                      置顶
                                    </Button>
                                  )}
                                  <Button
                                    type="primary"
                                    onClick={() => this.toArticleDetail(item.noticeId)}
                                  >
                                    公告管理
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {data.length === 0 ? <EmptyList state="noMore" /> : ''}
                  </div>
                  {data.length > 0 ? (
                    <div className="course-list-pagination">
                      <Pagination {...pageConfig} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <div className="right">
                <Empty />
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    Article: state.Article.article,
    noticeRootOption: state.GloabalCreateSync.noticeRootOption,
    addressBarColumnId: state.GloabalCreateSync.addressBarColumnId,
    rootOption: state.GloabalCreateSync.rootOption,
  };
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  // 重置公告列表
  resetArticleLists: () => dispatch({ type: 'RESET_ARTICLE_LISTS' }),
  // 注册根据权限获取数据函数
  registerGetDataFn: (fn: any) => dispatch({ type: 'GETDATABYROOT', payload: fn }),
  updateCurrentRootType: (type: string) =>
    dispatch({ type: 'GLOBALCREATESYNCTYPE', payload: type }),
  ...ownProps,
});
export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
