import './index.less';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isNil, invert } from 'lodash';

import { getCourseTableList, getMonthSchedule } from '../../redux/action/userCenter.action';
import { NormalObj } from '../../util';
import { getMonthDays, requestDataCompare } from './formatData';
import { privilegeTypeMapScheduleId, scheduleIdMapPrivilegeType } from '../../util/privilege';
import CourseTable from './CourseTable';
import Calendar from './Calendar';
import SwitchWeek from './switchWeek';

import { isNotEmpty } from '../../util/arrayUtils';

interface P {
  monthSchedule: NormalObj; // 月历数据列表
  courseTable: NormalObj; // 课程表列表
  inlitialRootInfo: any;
  clearListData: any;
  resourceRootOption: any[]; // 资源权限
  addressBarColumnId: string;
  rootOption: any[];
}

class UserCenter extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {
      timeStamp: moment(moment().format('YYYY-MM-DD')).valueOf(), // 时间戳
      source: null,
    };
  }

  componentDidMount() {
    this.getSourceList();
  }

  UNSAFE_componentWillReceiveProps(nextProps: P) {
    if (nextProps.addressBarColumnId !== this.props.addressBarColumnId) {
      this.getSourceList();
    }
  }

  // 根据权限 获取来源列表
  getSourceList = () => {
    const scheduleType = this.getScheduleTypeBy();
    this.props.inlitialRootInfo(scheduleIdMapPrivilegeType[scheduleType], this.onPrivilegeChanged);
    this.setScheduleTypeToStateAndLoadData(scheduleType);
  };
  setScheduleTypeToStateAndLoadData(scheduleType: number) {
    this.setState(
      {
        source: scheduleType,
      },
      () => {
        if (this.hasPrivilege()) {
          this.getData(true);
        } else {
          // 不存在该权限
          this.props.clearListData();
        }
      },
    );
  }

  getPrivilegeType() {
    const privilegeType = window.localStorage.getItem('ROOTTYPE');
    if (isNil(privilegeType)) {
      const { rootOption } = this.props;
      if (isNotEmpty(rootOption)) {
        const defaultGlobalPrivilege = rootOption[0];
        return defaultGlobalPrivilege.type;
      }
    }
    return privilegeType;
  }

  getScheduleTypeBy() {
    const privilegeType = this.getPrivilegeType();
    if (isNil(privilegeType)) {
      return -1;
    }
    return privilegeTypeMapScheduleId[privilegeType];
  }

  hasPrivilege(): boolean {
    const privilegeType = this.getPrivilegeType();
    const resourceRootOption = [...this.props.resourceRootOption];
    return resourceRootOption.some((item: any) => item.type === privilegeType);
  }

  // 获取课程表数据
  getData = (sourceFlg?: boolean) => {
    const { source, timeStamp } = this.state;
    const { courseTable } = this.props;
    // source不存在时
    if (!source) return null;
    const lastStr = moment(timeStamp).endOf('week').format('YYYY-MM-DD');
    const startDate = moment(timeStamp).startOf('week').valueOf();
    const endDate = moment(`${lastStr} 23:59:59`).valueOf();
    //  判断数据是否存在
    const flg = requestDataCompare(startDate, courseTable);
    if (flg || sourceFlg) {
      getCourseTableList({ startDate, endDate, scheduleType: source });
    }
  };
  /**
   * 月历日期 切换
   * @param value: 时间戳
   */
  dateChange = (value: number) => {
    this.setState(
      {
        timeStamp: value,
      },
      () => {
        this.getData();
      },
    );
  };
  /**
   * 月历月份 切换
   */
  monthChange = () => {};
  /**
   * 周切换
   * @param value: 时间戳-- 切换周的周一时间戳
   */
  changeWeek = (value: number) => {
    this.setState(
      {
        timeStamp: value,
      },
      () => {
        this.getData();
      },
    );
  };
  // 来源筛选条件
  onPrivilegeChanged = (scheduleType: number) => {
    this.savePrivilegeToLocalStorage(scheduleType);
    this.setScheduleTypeToStateAndLoadData(scheduleType);
  };

  savePrivilegeToLocalStorage(scheduleType: number) {
    const privilegeType = invert(privilegeTypeMapScheduleId)[scheduleType] || 'sync';
    window.localStorage.setItem('ROOTTYPE', privilegeType);
  }

  // 根据后台数据，标记月历
  composeMonthDays = async (value: string[]) => {
    const { monthSchedule } = this.props;
    const { source } = this.state;
    const lastStr = moment(value.join('-'), 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    const startDate = moment(value.join('-'), 'YYYY-MM').startOf('month').valueOf();
    const endDate = moment(`${lastStr} 23:59:59`).valueOf();
    //  判断数据是否存在
    const flg = requestDataCompare(startDate, monthSchedule);
    if (flg) {
      const data = await getMonthSchedule({ startDate, endDate, scheduleType: source });
      return getMonthDays(value, data);
    } else {
      return getMonthDays(value, monthSchedule);
    }
  };

  render() {
    const { source, timeStamp } = this.state;
    const { monthSchedule } = this.props;
    return (
      <div className="user-center">
        <div className="user-center-top">
          <div className="month">
            <Calendar
              composeMonthDays={this.composeMonthDays}
              dateChange={this.dateChange}
              source={source}
              monthChange={this.monthChange}
              value={timeStamp}
              data={monthSchedule}
            />
          </div>
          <div className="week">
            <SwitchWeek onChage={this.changeWeek} value={timeStamp} />
          </div>
          <div className="search" />
        </div>
        <div className="user-center-bottom">
          <CourseTable value={timeStamp} source={source} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  monthSchedule: state.userCenter.monthSchedule,
  courseTable: state.userCenter.courseTable,
  resourceRootOption: state.GloabalCreateSync.resourceRootOption,
  addressBarColumnId: state.GloabalCreateSync.addressBarColumnId,
  rootOption: state.GloabalCreateSync.rootOption,
});
const mapDispatchToProps = (dispatch: any) => ({
  // 初始化课程表权限信息
  inlitialRootInfo: (privilegeType: string, fn: any) => {
    dispatch({
      type: 'INITIALTIMETABLEROOTINFO',
      payload: {
        privilegeType,
        fn,
      },
    });
  },
  // 清空课程列表
  clearListData: () => {
    dispatch({ type: 'COURSE_TABLE_LIST_REQUEST', payload: null });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCenter);
