import React from 'react';
import { MyIcon } from '../../util';
import './index.less';
// 自定义图标 添加 鼠标 移入/移出 切换图标的的效果
interface P {
  [key: string]: any;
  type?: string; // 图标朝向默认 left, left , right
  name: string; // 图标名字
  activeName: string; // 图标激活状态的名字
}
class CustomIcon extends React.Component<P, any> {
  rightStyle = { transform: 'rotateZ(180deg)' };
  constructor(props: P) {
    super(props);
    this.state = {
      flg: false,
    };
  }
  // 鼠标移入
  mouseenter = (e: any) => {
    e.stopPropagation();
    const { flg } = this.state;
    if (!flg) {
      this.setState({
        flg: !flg,
      });
    }
  };
  // 鼠标移出
  mouseleave = (e: any) => {
    e.stopPropagation();
    const { flg } = this.state;
    if (flg) {
      this.setState({
        flg: !flg,
      });
    }
  };
  render() {
    const { flg } = this.state;
    let { type } = this.props;
    const { name, activeName } = this.props;
    type = type || 'left';
    return (
      <span
        onClick={this.props.onClick}
        onMouseEnter={this.mouseenter}
        onMouseLeave={this.mouseleave}
      >
        <MyIcon
          className="iconfont"
          type={flg ? activeName : name}
          style={type === 'right' ? this.rightStyle : {}}
        />
      </span>
    );
  }
}
export { CustomIcon };
