/*
 * @Descripttion: 根据联盟屏蔽下载按钮
 * @Author: gouyang
 * @Date: 2021-01-26 16:48:46
 * @LastEditors: gouyang
 * @LastEditTime: 2021-01-26 17:11:31
 */
import { getLeagueId } from './index';

// 东方闻道远程教学业务培训平台
export function permitDownload(): boolean {
  const unionId = getLeagueId();
  return unionId === '531406738848792576';
}
