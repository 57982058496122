/*
 * @Descripttion: 检测当前操作系统
 * @Author: gouyang
 * @Date: 2021-01-13 15:08:18
 * @LastEditors: gouyang
 * @LastEditTime: 2021-01-13 15:08:44
 */
function detectionSystem(): 'windows' | 'mac' | null {
  const agent = navigator.userAgent.toLowerCase();
  if (/macintosh|mac os x/i.test(agent)) {
    return 'mac';
  }
  if (
    agent.indexOf('win32') >= 0 ||
    agent.indexOf('wow32') >= 0 ||
    agent.indexOf('win64') >= 0 ||
    agent.indexOf('wow64') >= 0
  ) {
    return 'windows';
  }
  return null;
}

export default detectionSystem;
