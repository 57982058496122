/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-string-refs */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
import './index.less';

import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  mergeProps,
  MyIcon,
  NormalObj,
  getSearchParams,
  getToken,
  getLeagueId,
  getUserId,
  isFzxxUnion,
} from '../../util';
import Alert from '../Alert/Alert';
import AxiosWrap from '../../lib/request';
// import coursewareTypes from './coursewaretType';
import { Progress, Icon } from 'antd';
import api from '../../config/api/index';
import TrackRequest from '../../lib/trackRequest';
import downloadSuccess from '../../components/downloadSuccess';
import { isProhibit } from '../../util/prohibitShow';
import { permitDownload } from '../../util/permitDownload';
/**
 * 初始化状态-initialState
 *
 * @result visible 下载框显示
 * @result percent 下载框百分比
 * @result title 下载框课件名字
 * @result isError 下载框报错
 * @result childCourseWare 作业资料附属课件
 *
 */
const initialState = {
  visible: false,
  percent: 0,
  title: null,
  isError: false,
  childCourseWare: null,
};
/**
 * 点播播放页-AttachmentItem课件组件
 *
 * @props data 课件数据
 * @props type  课件格式 1-word, 2-ppt, 3-image, 4-audio, 5-vedio, 6-gif, 7-excel, 8-pdf, 9-zip, 10-ocrf, 其他(非空)-other
 * @props name 课件名称
 * @props active 激活样式标志
 * @props preview 课件预览
 * @props extension 课件后缀名
 * @props resourceId 资源中心id
 * @props activeIndex 选中序号
 * @props attachmentId 主键ID
 *
 */
interface P {
  data: NormalObj;
  index: number;
  active?: any;
  type?: any;
  name?: any;
  preview?: any;
  extension?: any;
  resourceId?: any;
  activeIndex?: any;
  attachmentId?: any;
  is_create?: any;
  onClick: () => void;
  isVideo?: boolean;
  functionConfig: NormalObj;
}
class AttachmentItem extends Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {
      data: this.props.data,
      type: this.props.type,
      name: this.props.name,
      active: this.props.active,
      preview: this.props.preview,
      extension: this.props.extension,
      resourceId: this.props.resourceId,
      activeIndex: this.props.activeIndex,
      attachmentId: this.props.attachmentId,
      ...initialState,
    };
    // this.type = this.props.type;
  }
  componentWillReceiveProps(nextProps: P) {
    this.setState({ ...nextProps });
  }

  /**
   * 关闭下载框
   */
  handleCancel = () => {
    const { percent, isError } = this.state;
    if (percent < 100 && !isError) return;
    this.setState({ percent: 0, visible: false });
  };
  /**
   * 课件对应ICON
   */
  getType() {
    const o = {
      name: '',
      type: '',
    };
    const { data } = this.props;
    if (data.category === 1) {
      // 点播课时
      switch (data.extension.toLowerCase()) {
        case 'mp4':
          o.type = 'iconFile_mp';
          break;
        case 'crf':
        case 'crf2':
        case 'ocrf':
          o.type = 'iconocrf';
          break;
        case 'txt':
          o.type = 'iconFile_txt';
          break;
        default:
          o.type = 'iconunknown';
          break;
      }
    } else {
      switch (data.category) {
        case 2:
          o.name = '课件';
          o.type = 'iconcourseware';
          break;
        case 3:
          o.name = '素材';
          o.type = 'iconmaterial';
          break;
        case 4:
          o.name = '教案';
          o.type = 'iconteaching_plan';
          break;
        case 5:
          o.name = '普通作业';
          o.type = 'iconoperation';
          break;
        case 6:
          o.name = '普通试卷';
          o.type = 'icontest_paper';
          break;

        default:
          o.name = '未知';
          o.type = 'iconunknown';
          break;
      }
    }
    return o;
  }

  /**
   * 转化name和extension
   *
   * @params data 课件
   * @params type 作业资料附属课件
   *
   */
  getNameExtension(data: any, type: any) {
    let name = '';
    let extension = '';
    if (!data) return { name: '', extension: '' };
    if (type === 'childList') {
      name = data.fileName;
      extension = data.fileExtension;
    } else {
      name = data.fileName;
      extension = data.extension;
    }
    return { name, extension };
  }
  /**
   * 转化课件名
   *
   * @params name 课件名字
   * @params extension 课件后缀名
   *
   */
  getFileName(name: any, extension: any) {
    if (extension) {
      if (name.includes(`.${extension}`)) {
        return name;
      } else {
        return `${name}.${extension.toLocaleLowerCase()}`;
      }
    }
    return name;
  }
  /**
   * 下载预处理
   *
   * @params data 课件
   * @params category normal:不包含子课件, childList:包含子课件
   *
   */
  download(data: any, category: any) {
    const searchParam = getSearchParams(window.location.search);
    const that = this;
    //    let preAttributes = getPreAttributes();
    //    if (preAttributes) preAttributes.current_time = new Date().getTime();
    const obj = this.getNameExtension(data, category);
    const title = this.getFileName(obj.name, obj.extension);
    this.setState({ isError: false });
    if (data.category === 6 && data.extension.toLowerCase() === 'pdf') {
      let mainUserInfo = window.localStorage.getItem('mainUserInfo');
      mainUserInfo = mainUserInfo ? JSON.parse(mainUserInfo) : { userId: '' };
      const { userId } = mainUserInfo as any;
      const childUserId = getUserId();
      const currentUserId = childUserId === userId ? 0 : childUserId; // 判断是否使用主账号
      // 试卷加密
      const link = `${
        api.resourceCenterDownload
      }/FileService/DownloadPdf?usertoken=${getToken()}&fileid=${
        data.fileId
      }&leagueId=${getLeagueId()}&relationUserId=${currentUserId}`;
      this.callBack(title, '', data, link, true, 20);
      // 加密试卷下载状态
      const _name_ = encodeURIComponent(data.fileName);
      const url = `${api.api}/resources/${data.resourceGuid}/files/${data.fileId}/download?courseId=${searchParam.courseId}&lessonId=${searchParam.lessonId}&fileName=${_name_}`;
      if (url) {
        AxiosWrap({
          url,
          method: 'get',
        });
      }
    } else if (category === 'normal') {
      const _name_ = encodeURIComponent(data.fileName);
      const url = `${api.api}/resources/${data.resourceGuid}/files/${data.fileId}/download?courseId=${searchParam.courseId}&lessonId=${searchParam.lessonId}&fileName=${_name_}`;
      if (url) {
        AxiosWrap({
          url,
          method: 'get',
        }).then((res: any) => {
          that.webDownloadFn(res, title, data);
        });
      }
    } else if (category === 'childList') {
      if (data.fileUrl) {
        this.setState({ type: data.fileExtensionType });
        this.callBack(title, '', this.props.data, data.fileUrl);
      } else {
        Alert.open('该文件暂时无法下载，请联系客服');
      }
    }
  }
  // 浏览器下载
  webDownloadFn(res: any, title: any, data: any) {
    let url = res.data.cdnUrl || res.data.url;
    if (url) {
      url = `https${url.substring(url.indexOf(':'))}`;
      this.callBack(title, '', data, url);
    } else {
      Alert.open('该文件暂时无法下载，请联系客服');
    }
  }
  /**
   * 下载资源
   *
   * @params title 下载框标题
   * @params trackData 大数据埋点
   * @params data 课件
   * @params url 资源地址
   * @params noCache 选填 不禁用缓存
   * @params basePercent 选填 基本百分比
   *
   */
  callBack(
    title: any,
    trackData: any,
    data: any,
    url: any,
    noCache?: boolean,
    basePercent?: number,
  ) {
    const that = this;
    this.setState({ visible: true, title });
    if (basePercent) {
      let n = 0;
      const timer = window.setInterval(() => {
        if (n >= 3) {
          window.clearInterval(timer);
        }
        n++;
        this.setState({ percent: Math.floor(basePercent / 4) * n });
      }, 20);
    }
    const { data: lessonInfo } = this.props;
    let cache = {};
    !noCache && (cache = { 'Cache-Control': 'no-cache' });
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: cache,
      onDownloadProgress(progressEvent) {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (basePercent) {
          // 加密过程模拟进度条
          const _basePercent_ = Math.floor(basePercent / 4) * 4;
          percent >= _basePercent_ && that.setState({ percent });
        } else {
          that.setState({ percent });
        }
        if (percent === 100 || percent === Infinity) {
          setTimeout(() => {
            that.setState({ visible: false, percent: 0 });
          }, 2000);
        }
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', title);
        document.body.appendChild(link);
        link.click();
        // 文件下载提示语
        downloadSuccess();
        // that.handleTrackData(trackData, data);
        // 下载资料 埋点
        const searchParam = getSearchParams(window.location.search);
        TrackRequest({
          name: 'download_material',
          data: {
            course_id: searchParam.courseId,
            period_id: searchParam.lessonId,
            material_id: lessonInfo.resourceGuid,
            material_type: lessonInfo.category,
            download_entrance: 0,
          },
        });
      })
      .catch((err) => {
        if (err) {
          that.setState({ isError: true, visible: false });
        }
      });
  }
  /**
   * 大数据埋点处理
   *
   * @params trackData 预置属性
   * @params data 课件
   * @params event_properties 事件参数
   *
   */
  // handleTrackData(trackData:any, data:any, event_properties = {}) {
  //     if (trackData) {
  //         event_properties.resource_id = data.resourceId;
  //         event_properties.attachment_id = data.attachmentId;
  //         event_properties.resource_stage = getStage();
  //         event_properties.grade_subject_code = data.gradeSubjects;
  //         event_properties.resource_category = data.category;

  //         commonAction.pushData('download_resource', {
  //             event_properties,
  //             preset_properties: trackData
  //         });
  //     }
  // }

  renderDownload(is_create: string, visible: boolean, data: any) {
    const s = data.category === 1 && data.extension.toLowerCase() === 'mp4';
    if (is_create === '2' && !visible && !s && isProhibit(data)) {
      return (
        <div
          className="attachment-item-inner-download"
          onClick={(e) => {
            e.stopPropagation();
            this.download(data, 'normal');
          }}
        >
          <MyIcon className="download-icon" type="iconDownload_normal" />
        </div>
      );
    }
  }

  render() {
    const {
      active,
      data,
      index,
      is_create,
      isVideo,
      functionConfig: { downloadFile = false },
    } = this.props;
    const { visible, percent, isError } = this.state;
    return (
      <div
        ref="component"
        onClick={this.props.onClick}
        className={`attachment-item ${active === data.fileId + index ? 'active' : ''} ${
          data.expiryState ? 'expire-item' : ''
        }`}
      >
        <MyIcon className="iconfont expire-icon" type="iconziyuan" />
        <div className="attachment-item-inner">
          <div className="attachment-item-inner-logo">
            <MyIcon className="iconfont" type={this.getType().type} />
          </div>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: !data.downloadState ? 'row' : 'column',
            }}
            title={isVideo ? data.fileName.slice(0, -4) : data.fileName}
          >
            <div className="attachment-item-inner-name">
              {isVideo ? data.fileName.slice(0, -4) : data.fileName}
            </div>
            <div>
              {/* {data.previewState && <span className="state-icon pre-icon">已查看</span>} */}
              {!isFzxxUnion() && data.downloadState && (
                <span className="state-icon down-icon">已下载</span>
              )}
            </div>
          </div>
          {!permitDownload() && downloadFile && this.renderDownload(is_create, visible, data)}
        </div>
        {isError && (
          <div
            style={{
              color: '#F5222D',
              paddingLeft: '36px',
              paddingTop: '10px',
              position: 'relative',
            }}
          >
            下载失败!
            <span
              style={{ position: 'absolute', color: '#fff', right: '16px' }}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ visible: false, isError: false });
              }}
            >
              <Icon type="close" />
            </span>
          </div>
        )}

        {visible && !isError && (
          <Progress
            percent={this.state.percent}
            status={percent === 100 ? 'success' : !isError ? 'active' : 'exception'}
          />
        )}
        {/* {visible && <ProgressBar {...progressProps} />} */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  functionConfig: { ...state.DynamicControl.functionConfig },
});

export default connect(mapStateToProps, mergeProps)(AttachmentItem);
