// 查询基础信息
import AxiosWrap from '../../lib/request';
import * as types from '../types/base.type';
import { dispatch } from '../../createStore';
import { message } from 'antd';
import _ from 'lodash';

// 有权限的授课老师列表
export const getFilteredTeacher = async (params: any, teacherIds: number[]) => {
  try {
    const { status, data } = await AxiosWrap({
      url: '/create_query_conditions/available_teachers',
      params,
    });
    if (status === 200) {
      const teachers = data.filter((d: any) => teacherIds.some((id: number) => id === d.userId));
      dispatch({
        type: types.FILTERED_TEACHER_LIST_REQUEST,
        payload: { teachers, allTeachers: data },
      });
      dispatch({
        type: types.UNSHIFT_TEACHER_LIST_REQUEST,
        payload: data,
      });
      return Promise.resolve({ status, data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 授课老师 列表 - 我的创建
export const getTeacher = async (params?: any, reload?: number) => {
  try {
    const { status, data } = await AxiosWrap({
      url: '/create_query_conditions/teacher_pages',
      params,
    });
    if (status === 200) {
      const payload = data._embedded.teachers || [];
      if (reload === 1) {
        dispatch({ type: types.TEACHER_LIST_REQUEST_RELOAD, payload });
      } else {
        dispatch({ type: types.TEACHER_LIST_REQUEST, payload });
      }
      dispatch({ type: types.TEACHER_LIST_TOTALPAGES, payload: data.page.totalPages });
      return Promise.resolve({ status, data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取 学段列表
export const getStudySections = async () => {
  try {
    const { status, data } = await AxiosWrap({ url: '/base_query_conditions/study_sections' });
    if (status === 200) {
      const sections = (data || []).reverse();
      const cloneData = _.cloneDeep(sections);
      dispatch({ type: types.STUDY_LIST_REQUEST, payload: sections });
      return cloneData;
    }
    return [];
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
    return [];
  }
};

// 获取 学科列表
export const getSubjects = async (studySectionCode?: string) => {
  try {
    const { status, data } = await AxiosWrap({
      url: '/base_query_conditions/subjects',
      params: {
        studySectionCode,
      },
    });
    if (status === 200) {
      dispatch({ type: types.SUBJECTS_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 获取 学科列表 -- 我的同步
export const getFollowSubjects = async () => {
  try {
    const { status, data } = await AxiosWrap({ url: '/follow_query_conditions/subjects' });
    if (status === 200) {
      dispatch({ type: types.SUBJECTS_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 获取 学科列表 -- 我的创建
export const getCreateSubjects = async () => {
  try {
    const { status, data } = await AxiosWrap({ url: '/create_query_conditions/subjects' });
    if (status === 200) {
      dispatch({ type: types.SUBJECTS_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取 年级列表
export const getGrades = async (studySectionCode?: any) => {
  try {
    const { status, data } = await AxiosWrap({
      url: '/base_query_conditions/grades',
      params: {
        studySectionCode,
      },
    });
    if (status === 200) {
      dispatch({ type: types.GRADES_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取 年级列表 -- 我的同步
export const getFollowGrades = async (params: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: '/follow_query_conditions/grades', params });
    if (status === 200) {
      dispatch({ type: types.GRADES_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    // 回调
  }
};

// 获取 学年列表
export const getSchoolYear = async () => {
  try {
    const { status, data } = await AxiosWrap({ url: '/base_query_conditions/school_years' });
    if (status === 200) {
      dispatch({ type: types.GET_SCHOOL_YEARS, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取学届列表
export const getSchoolPeriod = async () => {
  try {
    const { status, data } = await AxiosWrap({ url: '/base_query_conditions/school_periods' });
    if (status === 200) {
      dispatch({ type: types.GET_SCHOOL_PERIODS, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取学届列表 -- 我的同步
export const getFollowSchoolPeriod = async () => {
  try {
    const { status, data } = await AxiosWrap({ url: '/follow_query_conditions/school_periods' });
    if (status === 200) {
      dispatch({
        type: types.GET_SCHOOL_PERIODS,
        payload: data.sort((a: any, b: any) => b.id - a.id),
      });
    }
  } catch (error) {
    // 回调
  }
};

// 获取教材列表
export const getTextbooks = async (params: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: '/base_query_conditions/materials', params });
    if (status === 200) {
      dispatch({ type: types.GET_TEXT_BOOKS, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取我的同步教材列表
export const getFollowTextbooks = async (params: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: '/follow_query_conditions/materials', params });
    if (status === 200) {
      dispatch({ type: types.GET_TEXT_BOOKS, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 获取章节树
export const getChaptersTree = async (params: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: '/base_query_conditions/chapters', params });
    if (status === 200) {
      dispatch({ type: types.GET_CHAPTERS_TREE_TREE, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
