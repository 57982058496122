import React from 'react';
import './index.less';
import axios from 'axios';
import api from '../../config/api';
import { RouteComponentProps } from 'react-router';
import cover from '../../assets/img_ziliao.jpg';
import poster from '../../assets/img_dianbo.jpg';
import { getSearchParams, getToken } from '../../util';
import * as action from '../../redux/action/vod.action';
import AxiosWrap from '../../lib/request';
import Toast from './components/Toast/Toast';
import { Attachment } from './components/Attachment';
import { Marks } from './components/Marks';
import { Tab } from './components/Tab';

import { PlayTime } from '../../util/playTime';

const legalTypes = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];

type P = RouteComponentProps;

interface Istate {
  marks: any;
  lessonName: string;
  lessonId: string;
  lessonType: string;
  tabValue: number;
  attachmentList: any;
  videoSrc: string;
  mediaProps: object;
}

export default class MiniProgram extends React.Component<P, Istate> {
  tcPlayer: any;
  appId: any;
  videoContainer: any;
  duration: number;
  private PlayTimeFn: any;
  private ids: {
    fileId: string;
    resourceId: string;
    lessonId: string;
    courseId: string;
    source: number;
  }; // 播放时长提交数据
  constructor(props: P) {
    super(props);
    this.duration = 0;
    this.tcPlayer = null;
    this.videoContainer = 'player-container';
    this.state = {
      marks: [],
      lessonName: '',
      lessonId: '',
      lessonType: '',
      tabValue: 0,
      videoSrc: '',
      attachmentList: [],
      mediaProps: {},
    };
    this.PlayTimeFn = PlayTime();
    this.ids = {
      fileId: '',
      resourceId: '',
      lessonId: '',
      courseId: '',
      source: 1, // 来源小程序
    };
  }
  async componentDidMount() {
    const { lessonName, lessonId, lessonType, courseId } = getSearchParams(
      this.props.location.search,
    );
    Object.assign(this.ids, {
      lessonId,
      courseId,
    });
    document.title = lessonName;
    this.setState({
      lessonName,
      lessonId,
      lessonType,
      tabValue: lessonType === '2' ? 0 : 1,
    });
    // 覆盖默认全局样式
    const root = document.getElementById('root');
    if (root) {
      root.className = 'atMini';
    }
    try {
      const lessonInfo = await action.fetchLesson(lessonId, '2');
      const attachments = lessonInfo.centerResources;
      const attachmentList: any[] = [];
      attachments.forEach((attachment: any) => {
        attachment.files.forEach((file: any) => {
          attachmentList.push({
            category: attachment.category,
            ...file,
          });
        });
      });
      this.setState({
        attachmentList,
      });
      if (lessonType === '2') {
        const vods = lessonInfo.classRecord && lessonInfo.classRecord.files;
        const mainVideo = vods.find((item: any) => item.extension.toLowerCase() === 'mp4');
        Object.assign(this.ids, {
          resourceId: lessonInfo.classRecord ? lessonInfo.classRecord.resourceGuid : '',
          fileId: mainVideo.fileId,
        });
        this.getVideoInfoByFileId(mainVideo.fileId);
      }
    } catch (error) {
      Toast.open({ text: error });
    }
  }
  componentWillUnmount() {
    this.PlayTimeFn.stopSubmitStatisticalTime();
  }
  coverDefaultEvent = () => {
    const rateRadio = document.querySelector('.vjs-playback-rate.vjs-control');
    const menu = document.querySelector('.vjs-playback-rate div.vjs-menu');
    (rateRadio as any).ontouchend = () => {
      if ((menu as any).style.display === 'none' || (menu as any).style.display === '') {
        (menu as any).style.display = 'block';
      } else {
        (menu as any).style.display = 'none';
      }
    };
  };

  getVideoInfoByFileId = async (id: string) => {
    const resData = await action.getMark(id);
    const { marks, url, playId } = resData;
    this.setState({
      marks,
      videoSrc: url,
    });
    if (playId) {
      this.getTencentAppId(playId, this.videoContainer);
    } else {
      this.tcPlayer = (window as any).TCPlayer(this.videoContainer, { autoplay: true });

      this.createPlayerCallBack(this.tcPlayer);
    }
  };

  getTencentAppId(playId: string, container: string) {
    if (playId) {
      axios({
        method: 'GET',
        url: `${api.file}/v3/cloud/bucket`,
        headers: {
          Authorization: `Bearer ${getToken() || ''}`,
          'x-module': 'open_school',
        },
        params: {
          provider: 'tencent',
          playId,
        },
      }).then(
        (res) => {
          this.appId = res.data.subAppId;
          this.creactCloudPlayer(playId, container);
        },
        () => (this.appId = ''),
      );
    }
  }

  creactCloudPlayer(fileID: string, container: string, time?: string) {
    const { appId } = this;
    this.tcPlayer = (window as any).TCPlayer(container, {
      fileID: '',
      appID: appId,
      autoplay: true,
    });
    if (appId) {
      this.createPlayerCallBack(this.tcPlayer, time);
    } else {
      window.setTimeout(() => {
        this.creactCloudPlayer(fileID, container);
      }, 500);
    }
  }

  createPlayerCallBack(player: any, time?: string) {
    player.on('ended', () => {
      player.isFullscreen() && player.exitFullscreen();
    });
    player.on('ready', () => {
      if (time) {
        player.currentTime(time);
      }
    });
    player.on('pause', () => {
      this.PlayTimeFn.stopSubmitStatisticalTime();
    });
    player.on('play', () => {
      this.PlayTimeFn.submitStatisticalTime(this.ids);
    });
    player.on('loadedmetadata', () => {
      this.duration = player.duration();
      this.coverDefaultEvent();
    });
  }

  onTimeChanged = (timeTick: any) => {
    if (this.tcPlayer) {
      this.tcPlayer.play();
      // 部分初次不能跳转
      if (this.duration === 0) return;
      if (this.duration > timeTick && timeTick > 0) {
        this.tcPlayer.currentTime(timeTick);
        this.tcPlayer.play();
      } else {
        Toast.open({ text: '对不起，该时间点已超过视频总时长！' });
      }
    }
  };

  onTabChanged = (val: any) => {
    this.setState({ tabValue: val });
  };

  onDownloadClicked = async (item: any) => {
    if (
      !legalTypes.includes(item.extension.toLowerCase()) ||
      (item.category === 6 && item.extension.toLowerCase() === 'pdf')
    ) {
      Toast.open({ text: '该文件暂不支持预览，请前往Web端进行操作' });
      return null;
    }
    this.tcPlayer && this.tcPlayer.pause();
    const { data } = await AxiosWrap({ url: `/files/${item.fileId}` });
    // console.log('before nav');
    // console.log((window as any)._wx_.miniProgram);
    (window as any)._wx_.miniProgram.navigateTo({
      url: `/pages/download/index?extension=${data.extension}&name=${data.fileName}&url=${
        data.cdnUrl || data.url
      }`,
      success() {
        // console.log('success');
      },
      fail() {
        // console.log('fail');
      },
      complete() {
        // console.log('complete');
      },
    });
    // console.log('after nav');
  };

  render() {
    const { tabValue, attachmentList, marks, lessonType, videoSrc } = this.state;

    return (
      <div className="mini-program">
        <div className="cover-cont">
          {lessonType === '2' ? (
            <video
              poster={poster}
              id={this.videoContainer}
              width="100%"
              preload="auto"
              playsInline
              x5-playinline="test"
              webkit-playinline="test"
              src={videoSrc}
            />
          ) : (
            <img src={cover} alt="" />
          )}
        </div>
        <Tab lessonType={lessonType} tabValue={tabValue} onTabChanged={this.onTabChanged} />
        <div className="scroll-cont">
          {tabValue === 0 && lessonType === '2' ? (
            <div className="markup-row">
              <Marks marks={marks} onTimeChanged={this.onTimeChanged} />
            </div>
          ) : (
            <div className="res-row">
              <Attachment
                attachmentList={attachmentList}
                onDownloadClicked={this.onDownloadClicked}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
