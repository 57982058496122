import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { searchTotalNumberAjax } from '../../../redux/action/globalSearch.action';
import { getQueryString } from '../../../util';
import { tabList } from '../lib/configParams';

import './Tab.less';

interface List {
  name: string;
  id: string;
}

interface TabProps {
  onTabChange: (id: string) => void;
}

function Tab(props: TabProps) {
  const category = getQueryString('category');
  const text = getQueryString('text');
  const distance = 72;
  const [currentKey, setCurrentKey] = useState(0);
  const { searchTotalNumber, searchTimestamp } = useSelector((store: any) => ({
    searchTotalNumber: store.GlobalSearch.searchTotalNumber,
    searchTimestamp: store.GlobalSearch.searchTimestamp,
  }));

  const onTabChange = (list: List, index: number) => {
    setCurrentKey(index);
    props.onTabChange(list.id);
  };

  // 初始化tab
  useEffect(() => {
    const index = tabList.findIndex((list: List) => list.id === category);
    if (index !== -1) setCurrentKey(index);
  }, [category]);

  useEffect(() => {
    const text = getQueryString('text');
    searchTotalNumberAjax(text);
  }, [searchTimestamp]);

  return (
    <header className="global-search-tab">
      <div className="search-total-number">
        共找到
        <span>{searchTotalNumber}</span>
        个与
        <span>“{text}”</span>
        相关的结果
      </div>
      <ul>
        {tabList.map((list: List, index: number) => {
          return (
            <li
              onClick={() => onTabChange(list, index)}
              className={`${currentKey === index ? 'active-color' : 'normal-color'}`}
              key={list.id}
            >
              {list.name}
            </li>
          );
        })}
        <li className="bar-box">
          <span className="bar" style={{ transform: `translateX(${distance * currentKey}px)` }} />
        </li>
      </ul>
    </header>
  );
}

export default memo(Tab);
