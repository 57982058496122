import * as types from '../types/menu.type';
import * as dynamicControlTypes from '../types/dynamicControl.type';
import { dispatch } from '../../createStore';
import { menuLists, leagueInfo, resourceRoots } from '../../lib/api';

// 判断是否有公告
function judgeNotice(data: any[], result: any[]) {
  for (let i = 0, l = data.length; i < l; i++) {
    if (data[i].type === 1) {
      result.push(data[i]);
    }
    judgeNotice(data[i].children, result);
  }
}

// 获取联盟信息
export async function getLeagueInfos(cb?: (data: any) => void) {
  try {
    const { status, data } = await leagueInfo();
    if (status === 200) {
      dispatch({
        type: dynamicControlTypes.FUNCTION_CONFIG,
        payload: data.functionConfig,
      });
      cb && cb(data.functionConfig);
    }
  } catch (e) {
    // console.log(e);
  }
}

// 获取当前栏目及联盟信息
export async function getMenusAndLeagueInfo() {
  try {
    const menus = await menuLists();
    const leagueInfos = await leagueInfo();

    const { status, data } = leagueInfos;
    if (status === 200) {
      dispatch({ type: dynamicControlTypes.FUNCTION_CONFIG, payload: data.functionConfig });
      dispatch({ type: types.LEAGUEID_DETAILS, payload: data });
      if (
        data.noticeConfig &&
        data.noticeConfig.controlExtension &&
        data.noticeConfig.controlExtension.controlSchemas
      ) {
        dispatch({
          type: dynamicControlTypes.GET_NOTICE_CONTROLS,
          payload: data.noticeConfig.controlExtension.controlSchemas,
        });
      }
    }

    const fixedMenus: any[] = [];
    if (leagueInfos.data.functionConfig.showClassSchedule) {
      fixedMenus.push({
        name: '课程表',
        isfix: true,
        columnId: 'kechengbiao',
        type: 'kechengbiao',
        children: [],
      });
    }

    if (menus.status === 200 && leagueInfos.status === 200) {
      const { lessonConfig } = leagueInfos.data;
      if (leagueInfos.data.functionConfig.showResourceArchive) {
        menus.data.push({
          name: '资料库',
          isfix: true,
          columnId: 'resLib',
          type: 'resLib',
          children: [],
        });
      }

      dispatch({ type: types.GET_MENUS, payload: [...fixedMenus, ...menus.data] });
      dispatch({ type: types.LEAGUEID_DETAILS, payload: leagueInfos.data });
      dispatch({ type: dynamicControlTypes.GET_COURSE_CONTROLS, payload: lessonConfig });
    }
  } catch {
    dispatch({ type: types.GET_MENUS, payload: [] });
  }
}
/**
 * @name: 获取栏目列表，判断是否具有公告
 * @return: Promise
 */
export async function isNoticeByMenus() {
  try {
    const { data, status } = await menuLists();
    if (status === 200) {
      const result: any[] = [];
      judgeNotice(data, result);
      const isNoticeRoot = result.length > 0;
      return Promise.resolve(isNoticeRoot);
    }
  } catch (error) {
    return Promise.resolve(false);
  }
}
/**
 * 获取资源权限
 * @param permissionType 1:资源权限，2:数据权限，3:栏目权限，4:后台权限
 */
export async function getRoot(permissionType: number) {
  try {
    const { status, data } = await resourceRoots(permissionType);
    if (status === 200) {
      window.localStorage.setItem('permissions', JSON.stringify(data));
      return Promise.resolve(data);
    }
  } catch (error) {
    return Promise.resolve([]);
  }
}

// 更新菜单按钮状态
export function updateButtonStatus(config: { status: boolean; type: string; name: string }) {
  dispatch({ type: types.BUTTON_CONFIG, payload: { ...config } });
}
