const textbook = '教材';
const schoolSection = '学    段';
const grade = '年    级';
const subject = '学    科';
const docType = '格式 :';
const category = '类型 :';
const resourceCategory = '类型 :';
const courseWare = '方式 :';
const tree = '章节';
const schoolYear = '学   年';
const extensionType = '格式 :';
const releaseState = '状    态';
const schoolPeriod = '学    届';
const resourceTypes = '类    型';

const types: {
  [key: string]: any;
} = {
  textbook,
  schoolSection,
  grade,
  subject,
  docType,
  category,
  courseWare,
  tree,
  schoolYear,
  extensionType,
  releaseState,
  resourceCategory,
  schoolPeriod,
  resourceTypes,
};
export default types;
