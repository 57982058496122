/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux';

import {
  SEARCH_PARAMS,
  SEARCH_TIMESTAMP,
  SEARCH_RESULT,
  SEARCH_TOTAL_NUMBER,
  REGISTER_CLEAR_FILTER,
  INITIAL_FILTER_STATUS,
  LOADING,
} from '../types/globalSearch.type';

const initialState = (() => ({
  result: {},
  analyzesResult: [],
  searchParams: {
    keyword: '',
    tabId: '',
  },
  searchTimestamp: 0, // 搜索时间戳
  searchTotalNumber: 0, // 搜索总数
  clearFilterStatus: () => {}, // 清空筛选状态
  initialFilterStatus: () => {}, // 初始化全局搜索筛选状态
  isKeywords: true, // 是否是关键字搜索
  loading: false,
}))();

const GlobalSearch = (state = { ...initialState }, { type, payload }: AnyAction) => {
  switch (type) {
    case SEARCH_RESULT:
      const { searchData, analyzes, isKeywords } = payload;
      return { ...state, result: searchData, analyzesResult: analyzes, isKeywords };
    case SEARCH_PARAMS:
      const { keyword, tabId } = payload;
      return { ...state, searchParams: { keyword, tabId } };
    case SEARCH_TIMESTAMP:
      return { ...state, searchTimestamp: payload };
    case SEARCH_TOTAL_NUMBER:
      return { ...state, searchTotalNumber: payload };
    case REGISTER_CLEAR_FILTER:
      return { ...state, clearFilterStatus: payload };
    case INITIAL_FILTER_STATUS:
      return { ...state, initialFilterStatus: payload };
    case LOADING:
      return { ...state, loading: payload };
    default:
      return {
        ...state,
      };
  }
};
export default GlobalSearch;
