import React, { Component } from 'react';
import MediaComponent from './../media/media';
// import Attachment from './../attachment/attachment';
// import AudioComponent from './../audio/audio';
import FlashComponent from './../flash/flash';
import FileComponent from './../file/file';
import ImageComponent from './../image/image';
import './resourceView.less';

export default class ResourceViewCom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // mediaAddress:
      mediaAddress: '',
      pause: false,
      player: {},
      playFlag: true, // 播放状态标识
      mediaType: 'mp4', // 资源类型,
      audioStatus: true,
    };
  }
  playerCallBack(players) {
    this.props.playerCallBack && this.props.playerCallBack(players);
    this.setState({
      player: players,
    });
  }
  attachmentFun = (state) => {
    const { player, playFlag } = this.state;
    // 展开之前
    if (state) {
      // 初始状态为播放
      if (!player.paused()) {
        this.setState({
          playFlag: true,
        });
      } else {
        this.setState({
          playFlag: false,
        });
      }
      this.setState({
        pause: true,
      });
    }
    // 展开之后
    else if (playFlag) {
      this.setState({
        pause: false,
      });
    }
  };
  getSourceFun = () => {
    // console.log('附件资源接口请求');
  };
  audioCallBack = (status) => {
    this.setState({
      audioStatus: status.pause,
    });
  };
  shouldComponentUpdate(nextprops) {
    if (
      nextprops.mediaType !== this.props.mediaType ||
      nextprops.mediaAddress !== this.props.mediaAddress
    ) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    let mediaComponent = '';
    const { mediaType, mediaAddress, pause } = this.props;
    switch (mediaType) {
      case 'mp4':
        mediaComponent = (
          <div>
            <MediaComponent
              type={mediaType}
              pause={pause}
              mediaAddress={mediaAddress}
              wrapperClassName={this.props.wrapperClassName}
              playerCallBack={(player) => this.playerCallBack(player)}
              autoPlay={this.props.autoPlay}
            />
          </div>
        );
        break;
      case 'mp3':
        mediaComponent = (
          <MediaComponent
            type="mp3"
            pause={pause}
            mediaAddress={mediaAddress}
            playerCallBack={(player) => this.playerCallBack(player)}
            autoPlay={this.props.autoPlay}
          />
        );
        break;
      case 'flash':
        mediaComponent = <FlashComponent mediaAddress={mediaAddress} />;
        break;
      case 'file':
        mediaComponent = <FileComponent mediaAddress={mediaAddress} />;
        break;
      case 'image':
        mediaComponent = <ImageComponent mediaAddress={mediaAddress} />;
        break;
      default:
        mediaComponent = (
          <div>
            <MediaComponent
              mediaAddress={mediaAddress}
              pause={pause}
              playerCallBack={(player) => this.playerCallBack(player)}
            />
            {/* <Attachment
                            player={this}
                            attachmentFun={this.attachmentFun}
                            getSourceFun={this.getSourceFun}
                        /> */}
          </div>
        );
        break;
    }
    return <div className="player">{mediaComponent}</div>;
  }
}
