/* eslint-disable no-case-declarations */
import * as types from '../types/course.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  columnList: [], // 新增课程 栏目选择列表
  courseInfo: null, // 获取课程信息
  coverList: [], // 课程封面图
  isRequested: true, // 是否需要请求
  isRequestError: false, // 是否请求发送错误
  columnListInfo: {
    // 列表数据信息
    system: {
      data: [],
      page: 0,
      size: 12,
      hasData: true,
    },
    personal: {
      data: [],
      page: 0,
      size: 12,
      hasData: true,
    },
  },
  completeRequest: true,
};

const course = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.COLUMN_LIST_REQUEST: // 栏目下拉列表
      return { ...state, columnList: payload };
    case types.COVER_LIST_REQUEST: // 课程封面图
      const { pageInfo, result, currentKey } = payload;
      const { number, totalPages } = pageInfo;
      let { data, hasData } = state.columnListInfo[currentKey];

      if (data.length > 0) {
        data = [...data, ...result];
      } else {
        data = [...result];
      }
      hasData = !!(number < totalPages - 1 && totalPages !== 0);

      state.columnListInfo[currentKey] = {
        data,
        hasData,
        size: 12,
        page: number,
      };

      return { ...state, columnListInfo: { ...state.columnListInfo } };
    case types.GET_COURSE_REQUEST: // 根据id 获取课程信息
      return { ...state, courseInfo: payload };
    case types.IS_REQUESTED_ERROR: // 是否发生请求错误
      return { ...state, isRequestError: payload };
    case types.SETTING_PAGINATION_PARAMS: // 设置分页参数
      const { page, listsType } = payload;
      const temporary = { ...state.columnListInfo[listsType] };
      temporary.page = page;
      state.columnListInfo[listsType] = temporary;
      return { ...state, columnListInfo: { ...state.columnListInfo } };
    case types.CLEAR_LISTS_DATA: // 清空列表数据
      return {
        ...state,
        columnListInfo: {
          system: {
            data: [],
            page: 0,
            size: 12,
            hasData: true,
          },
          personal: {
            data: [],
            page: 0,
            size: 12,
            hasData: true,
          },
        },
      };
    case types.START_REQUEST: // 开始请求
      return { ...state, completeRequest: false };
    case types.FINISH_REQUEST: // 完成请求
      return { ...state, completeRequest: true };
    default:
      return state;
  }
};
export default course;
