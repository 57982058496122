import './index.less';
import React from 'react';
import moment from 'moment';
import { CustomIcon } from '../../components/SmallComponents';
// 课时详情
interface P {
  onChage: (value: any) => void;
  value: number; // 时间戳
}
class SwitchWeek extends React.PureComponent<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {
      str: '',
      timeStamp: moment(this.props.value).valueOf(),
    };
  }
  componentDidMount() {
    this.initData();
  }
  initData = () => {
    const { timeStamp } = this.state;
    const $moment = moment(timeStamp);
    this.setState({
      str: `${$moment.startOf('week').format('MM月DD日')} - ${$moment
        .endOf('week')
        .format('MM月DD日')}`,
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps: P) {
    const { value } = nextProps;
    if (value !== this.props.value) {
      this.setState(
        {
          timeStamp: moment(value).valueOf(),
        },
        this.initData,
      );
    }
  }
  // 计算日期
  countDate = () => {
    const { timeStamp } = this.state;
    this.initData();
    this.props.onChage(timeStamp);
  };
  // 上一周
  prevWeek = () => {
    const { timeStamp } = this.state;
    this.setState(
      {
        timeStamp: moment(timeStamp).add(-1, 'weeks').valueOf(),
      },
      this.countDate,
    );
  };
  // 下一周
  nextWeek = () => {
    const { timeStamp } = this.state;
    this.setState(
      {
        timeStamp: moment(timeStamp).add(1, 'weeks').valueOf(),
      },
      this.countDate,
    );
  };
  render() {
    const { str } = this.state;
    return (
      <div>
        <CustomIcon
          onClick={this.prevWeek}
          name="iconPagin_left"
          activeName="iconPagin_leftbeifen"
        />
        <span>{str}</span>
        <CustomIcon
          onClick={this.nextWeek}
          name="iconPagin_left"
          activeName="iconPagin_leftbeifen"
          type="right"
        />
      </div>
    );
  }
}

export default SwitchWeek;
