// 菜单栏
export const menus = [
  'head', // 标题
  'bold', // 粗体
  'fontSize', // 字号
  'fontName', // 字体
  'italic', // 斜体
  'underline', // 下划线
  'strikeThrough', // 删除线
  'foreColor', // 文字颜色
  'backColor', // 背景颜色
  // 'link',  // 插入链接
  'list', // 列表
  'justify', // 对齐方式
  // 'quote',  // 引用
  // 'emoticon',  // 表情
  'image', // 插入图片
  // 'table',  // 表格
  // 'video',  // 插入视频
  // 'code',  // 插入代码
  'undo', // 撤销
  'redo', // 重复
];

// 粘贴文本过滤
export const filterPasteText = (text: string) => {
  // 过滤<htmL>
  let str = text.replace(/<html>|<html[^>]+>/gim, '');
  // 过滤<head></head>
  str = str.replace(/<head|[^>]+>[\s\S]*<\/head>/gim, '');
  // 过滤<style></style>
  str = str.replace(/<style|[^>]+>[\s\S]*<\/style>/gim, '');
  // 去掉<body></body>
  str = str.replace(/<body>|<body[^>]+>|<\/body>/g, '');
  // 去掉头部多余的>字符
  str = str.replace(/^>?/, '');
  // 为Img标签添加属性
  str = str.replace(/<img+/gim, '<img style="max-width: 100%"');

  return str ? str.trim() : '';
};
