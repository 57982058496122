import React, { Component } from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';

import DynamicControl from '../../../components/DynamicControl';

interface P extends FormComponentProps {
  controls: any[];
}

class DynamicSetting extends Component<P> {
  // 父组件获取数据
  getItemsValue = () => {
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      this.props.form.validateFields((error: any, value: any) => {
        if (!error) {
          const formatValue: { [k: string]: number } = {};
          Object.keys(value).forEach((key: string) => {
            if (value[key]) {
              if (typeof value[key] === 'object') {
                formatValue[key] = moment(value[key]).valueOf();
              } else {
                formatValue[key] = value[key];
              }
            }
          });
          resolve({ formatValue });
        } else {
          reject('请添加开始结束时间！');
        }
      });
    });
  };

  render() {
    const { controls } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        {controls.length > 0 && (
          <div className="model dc-cont" style={{ marginTop: 30 }}>
            <h3>其它信息</h3>
            <Form>
              {controls.map((control: any) => {
                return (
                  <DynamicControl
                    {...control}
                    key={control.controlId}
                    getFieldDecorator={getFieldDecorator}
                  />
                );
              })}
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default Form.create<P>()(DynamicSetting);
