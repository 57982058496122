/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-require-imports */
/*
 * @Author: gouyang
 * @Description:
 * @Date: 2019-09-26 13:13:52
 */
import SparkMD5 from 'spark-md5';
import fileAxios from '../lib/fileRequest';

const COS = require('cos-js-sdk-v5'); // 空对象
const TcVod = require('vod-js-sdk-v6'); // 带对象

/**
 *
 * @param file 文件对象
 * @param fileChange 上传状态变化方法
 */
// fileChange 回调方法的参数类型
interface fileChangeParameter {
  status: string; // 状态 start - 开始， progress- 上传中， done - 上传完成，error - 上传失败  cance -- 取消上传
  message: string; // 返回信息
  path?: string; // 上传成功之后返回的 路径
  percent?: number; // 上传中 'progress' 返回的 上传进度
  data?: any; // 文件服务返回的一整条数据
  cancelType?: string; // 取消类型
}
class Uploads {
  private file: File;
  private fileChange: (data: fileChangeParameter) => void;
  private client = 'open_school';
  private provider = 'tencent';
  private preview = 1; // 0 视频资源 ，1 其他资源
  private fileMD5 = '';
  private fileKey = '';
  private fileId = '';
  private fileExt = ''; // 后缀名
  private filename = ''; // 文件名
  private fileSize = 0; // 文件大小
  private cos: any;
  private fileUrl = '';
  private bucket = null;
  private region = null;
  private taskId = ''; // 上传任务id
  private close = false;
  private percent = 0; // 上传进度
  private flgNum = 0;
  private isVod: boolean; // 是否用点播上传 / 默认 对象上传
  private isTranscode: boolean; // 是否转码
  private uploader: any; // 点播上传对象
  private playId = ''; // 点播上传 返回的id,用于转码
  private safetyChain: boolean; // 是否启用防盗链
  /**
   * 构造函数
   * @param file 必填 文件对象
   * @param fileChange 必填 文件上传回调
   * @param isTranscode 选填 是否转码
   * @param safetyChain 选填 是否启用防盗链
   */
  constructor(
    file: File,
    fileChange: (data: fileChangeParameter) => void,
    isVod = false,
    isTranscode = false,
    safetyChain = false,
  ) {
    this.file = file;
    this.fileChange = fileChange;
    // 文件名
    this.filename = file.name;
    // 文件大小
    this.fileSize = file.size;
    if (file.name.trim().length > 0) {
      const index1 = file.name.lastIndexOf('.');
      const index2 = file.name.length;
      const suffix = file.name.substring(index1 + 1, index2); // 后缀名
      this.fileExt = suffix; // 文件后缀名
    }
    this.flgNum = 0;
    this.isVod = isVod;
    this.isTranscode = isTranscode;
    this.safetyChain = safetyChain;
  }
  // 取消上传 --- 除了上传操作，其他操作进行中不予以取消，只阻止其进入下一步操作
  cance = (cancelType?: string) => {
    // 对象存储取消上传
    if (this.cos && this.taskId) {
      this.cos.cancelTask(this.taskId);
    }
    // 点播取消上传
    if (this.uploader) {
      this.uploader.cancel();
    }
    this.fileChange({
      status: 'cance',
      message: '取消上传！',
      percent: this.percent,
      cancelType,
    });
    this.close = true;
  };
  // 主方法
  main = async () => {
    // 上传资源类型 --- 0 视频资源 ，1 其他资源
    if (this.file.type && this.file.type.indexOf('video') >= 0 && this.isVod === true) {
      this.preview = 0;
    }
    this.fileChange({ status: 'start', message: '上传开始！', percent: this.percent });
    // md5 转换
    const md5Flg = await this.transFormMd5();
    if (!md5Flg || this.close) {
      return;
    }
    // 检验md5 判断文件是否已经上传过
    const checkMd5Flg = await this.checkMd5File();
    if (!checkMd5Flg || this.close) {
      return;
    }
    // 创建上传实例
    this.creatExample();
    // 获取上传区域
    const bucketFlg = await this.getUploadBucket();
    if (!bucketFlg || this.close) {
      return;
    }
    // 开始上传
    let putCosFlg: any = false;
    if (this.isVod === true) {
      putCosFlg = await this.tcVodUpload();
    } else {
      putCosFlg = await this.putCosObject();
    }
    if (!putCosFlg || this.close) {
      return;
    }
    // 告知文件服务 上传成功
    this.addMd5File();
  };
  // 创建上传实例
  private creatExample = () => {
    if (this.isVod === true) {
      // 点播上传
      this.cos = new TcVod.default({
        getSignature: this.getSignature,
      });
    } else {
      // 对象存储上传
      this.cos = new COS({
        getAuthorization: (options: any, callback: any) => {
          this.getAuthorization(options, callback);
        },
      });
    }
  };
  // 获取对象存储上传签名
  private getAuthorization = async (options: any, callback: any) => {
    const method = (options.Method || 'get').toLowerCase();
    const key = options.Key || '/';
    const param = {
      md5: this.fileMD5,
      path: key,
      genre: this.preview,
      method,
      provider: this.provider,
    };
    try {
      const { status, data } = await fileAxios({
        url: '/v3/cloud/signatures',
        params: param,
        headers: { safetyChain: this.safetyChain },
      });
      if (status === 200) {
        callback(data.signature);
      }
    } catch (error) {
      this.cbackError(error, '获取签名失败');
    }
  };
  // 获取点播上传签名
  private getSignature = async () => {
    const param = {
      md5: this.fileMD5,
      genre: this.preview,
      method: 'put',
      provider: this.provider,
    };
    return fileAxios({
      url: '/v3/cloud/signatures',
      params: param,
      headers: { safetyChain: this.safetyChain },
    }).then(
      (response: any) => {
        return response.data.signature;
      },
      (error: any) => {
        this.cbackError(error, '获取签名失败');
      },
    );
  };
  // md5 转换
  private transFormMd5 = () => {
    return new Promise((resolve) => {
      // md5 转换
      this.md5FromFile({
        file: this.file,
        // md5 计算结果
        md5: (md5) => {
          // 检验md5 ---- 在文件服务上查询 该文件是否存在
          this.fileMD5 = md5;
          resolve(true);
        },
        // md5的 计算进度
        percent: (percent) => {
          if (this.close) {
            return;
          }
          this.percent = Math.floor(percent / 5) / 100;
          this.fileChange({ status: 'progress', message: '上传中...', percent: this.percent });
        },
        error: () => {
          this.cbackError(true, 'md5计算失败');
        },
      });
    });
  };
  // md5 检验
  private checkMd5File = async () => {
    const param = {
      client: this.client,
      extension: this.fileExt,
      fileName: this.filename,
      md5: this.fileMD5,
      genre: this.preview,
      provider: this.provider,
      size: this.fileSize,
      method: 'put',
    };
    try {
      const { status, data } = await fileAxios({
        url: '/v3/cloud/signatures',
        data: param,
        method: 'post',
        headers: { safetyChain: this.safetyChain },
      });
      if (status === 200) {
        // 已经存在该文件
        if (data.upload === true && !this.close) {
          // 模拟上传中
          this.simulationProgress(data);
        } else {
          // 文件不存在-- 继续上传流程
          this.fileId = data.fileId;
          this.fileKey = data.path;
          return Promise.resolve(true);
        }
      }
    } catch (error) {
      this.cbackError(error, '检查md5失败');
    }
  };
  // 获取上传区域
  private getUploadBucket = async () => {
    try {
      const { status, data } = await fileAxios({
        url: '/v3/cloud/bucket',
        params: { provider: this.provider },
        headers: { safetyChain: this.safetyChain },
      });
      if (status === 200) {
        this.bucket = data.bucket;
        this.region = data.region;
        return Promise.resolve(true);
      }
    } catch (error) {
      this.cbackError(error, '获取上传区域失败');
    }
  };
  // 上传文件
  private putCosObject = () => {
    return new Promise((resolve) => {
      this.cos.sliceUploadFile(
        {
          Bucket: this.bucket,
          Region: this.region,
          Key: this.fileKey,
          Body: this.file,
          onTaskReady: (taskId: string) => {
            this.taskId = taskId;
          },
          onProgress: (progressData: any) => {
            if (progressData.percent === this.flgNum) {
              return;
            }
            this.percent = 0.2 + progressData.percent / 1.25;
            this.fileChange({ status: 'progress', message: '上传中...', percent: this.percent });
            this.flgNum = progressData.percent;
          },
        },
        (err: any, data: any) => {
          if (err) {
            // console.error(err);
            this.cbackError(true, '上传文件出错');
          } else {
            // if (data.ETag !== `"${this.fileMD5}"`) {
            //     this.cbackError(true, '上传过程中文件损坏')
            // } else {
            this.fileUrl = data.Location;
            resolve(true);
            // }
          }
        },
      );
    });
  };
  // 点播上传
  private tcVodUpload = () => {
    return new Promise((resolve) => {
      this.uploader = this.cos.upload({
        mediaFile: this.file, // 媒体文件（视频或音频或图片），类型为 File
      });
      // 上传进度
      this.uploader.on('media_progress', (info: any) => {
        if (info.percent === this.flgNum) {
          return;
        }
        this.percent = 0.2 + info.percent / 1.25;
        this.fileChange({ status: 'progress', message: '上传中...', percent: this.percent });
        this.flgNum = info.percent;
      });
      // 上传完成
      this.uploader
        .done()
        .then((data: any) => {
          if (data) {
            this.fileUrl = data.video && data.video.url;
            this.playId = data.fileId;
            resolve(true);
          }
        })
        .catch(() => {
          this.cbackError(true, '上传文件出错');
        });
    });
  };
  // 告知文件服务 上传成功的图片
  private addMd5File = async () => {
    const reg = /(http|ftp|https):\/\/[\w\-_]+(.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/;
    const urls = reg.test(this.fileUrl) ? this.fileUrl : `https://${this.fileUrl}`;
    let params: any = {
      path: urls,
    };
    // 视频转码
    if (this.isTranscode === true && this.preview === 0) {
      params.playId = this.playId;
      params.transcode = true;
    }
    if (!this.isVod) {
      params = {};
    }
    try {
      const { status, data } = await fileAxios({
        url: `/v3/files/${this.fileId}`,
        data: params,
        method: 'PUT',
        headers: { safetyChain: this.safetyChain },
      });
      if (status === 200) {
        data.fileName = this.file.name;
        const obj = {
          ...data,
          extension: this.fileExt,
          size: this.fileSize,
          fileId: this.fileId,
        };
        // 转码
        this.fileChange({ status: 'done', message: '上传成功！', data: obj, path: data.path });
      }
    } catch (error) {
      this.cbackError(error, '添加Md5文件记录失败');
    }
  };
  // md5 转换
  private md5FromFile = (param: {
    file: any;
    md5: (md5: string) => void;
    percent?: (percent: number) => void;
    error?: (error: boolean) => void;
  }) => {
    const { file } = param;
    const blobSlice = File.prototype.slice;
    const chunkSize = 2 * 1024 * 1024;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const frOnload = (e: any) => {
      spark.append(e.target.result);
      currentChunk++;
      param.percent && param.percent(Math.round((currentChunk * 10000) / chunks) / 100);
      // 如果 在md5 转换过程中关闭，则不在进行转换
      if (currentChunk < chunks && !this.close) {
        loadNext();
      } else if (!this.close) {
        const md5 = spark.end();
        param.md5(md5);
      }
    };
    const frOnerror = () => {
      param.error && param.error(true);
    };
    let loadNext = () => {
      const fileReader = new FileReader();
      fileReader.onload = frOnload;
      fileReader.onerror = frOnerror;
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    };
    loadNext();
  };
  // 模拟上传进度
  private simulationProgress = (data: any) => {
    const timer = setInterval(() => {
      this.percent += 0.2;
      this.fileChange({ status: 'progress', message: '上传中...', percent: this.percent });
      if (this.percent > 1) {
        clearInterval(timer);
        data.fileName = this.file.name;
        const obj = {
          ...data,
          extension: this.fileExt,
          size: this.fileSize,
        };
        this.fileChange({ status: 'done', message: '上传成功！', data: obj, path: data.path });
      }
    }, 100);
  };
  // 错误信息返回
  cbackError = (error: any, msg: string) => {
    // eslint-disable-next-line no-console
    msg && console.error(msg); // 打印错误信息
    if (error && error.message && !error.status) {
      this.fileChange({ status: 'error', message: error.message, percent: this.percent });
      return;
    }
    error && this.fileChange({ status: 'error', message: '上传失败！', percent: this.percent });
  };
}
/**
 * 上传实例
 * @param file 必填 文件对象
 * @param fileChange 必填 文件上传回调
 * @param isVod 选填 是否点播上传 / 默认对象上传
 * @param isTranscode 选填 是否转码
 * @param safetyChain 选填 是否启用防盗链（默认不启用）
 */
const Upload = (
  file: File,
  fileChange: (data: fileChangeParameter) => void,
  isVod?: boolean,
  isTranscode?: boolean,
  safetyChain?: boolean,
) => {
  const data = new Uploads(file, fileChange, isVod, isTranscode, safetyChain);
  data.main();
  return data.cance;
};
export default Upload;
