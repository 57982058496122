import { message } from 'antd';
import AxiosWrap from '../../lib/request';
import Ajax from '../../lib/Ajax';
import * as types from '../types/userCenter.type';
import { UPDATEOPERATIONSTATUS } from '../types/globalCreateSync.type';
import { dispatch } from '../../createStore';
// 课程表 日程
export const getCourseTableList = async (params: any) => {
  try {
    dispatch({ type: UPDATEOPERATIONSTATUS, payload: false });
    const { status, data } = await AxiosWrap({ url: `/class-schedule`, params });
    if (status === 200) {
      dispatch({ type: types.COURSE_TABLE_LIST_REQUEST, payload: data });
    }
    dispatch({ type: UPDATEOPERATIONSTATUS, payload: true });
  } catch (error) {
    dispatch({ type: UPDATEOPERATIONSTATUS, payload: true });
    dispatch({ type: types.COURSE_TABLE_LIST_REQUEST, payload: null });
    if (error && error.status === 403) {
      return;
    }
    error && error.data && message.error(error.data.message);
  }
};
// 课程表 月历
export const getMonthSchedule = async (params: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/month-schedule`, params });
    if (status === 200) {
      dispatch({ type: types.MONTH_SCHEDULE_LIST_REQUEST, payload: data });
      return Promise.resolve(data);
    }
  } catch (error) {
    if (error && error.status === 403) {
      return;
    }
    error && error.data && message.error(error.data.message);
  }
};

// 查询 课时 是否存在 --- 同步请求，做跳转
export const getCheckExist = async (lessonId: string) => {
  try {
    const { status }: any = await Ajax({ url: `/lesson/${lessonId}/check-exist` });
    if (status === 200) {
      return Promise.resolve(true);
    }
  } catch (error) {
    if (error && error.status === 404) {
      message.error('课时不存在！');
      return;
    }
    if (error && error.status === 403) {
      message.error('暂无权限！');
      return;
    }
    if (error && error.data) {
      message.error(error.data.message);
    }
  }
};
