import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { filterPasteText } from '../../../../components/TextEditors/TextEditorConfig';
import './index.less';

interface Value {
  html: string;
  pureText: string;
}

interface P {
  value?: Value;
  onChange?: (value: Value) => void;
}

const Introduction = forwardRef((props: P) => {
  const { value, onChange } = props;
  const isNeedInitialization = useRef<boolean>(true);
  const isUnmount = useRef<boolean>(false);
  const instanceE = useRef<any>(null);
  const [count, setCount] = useState<number>(0);

  const getPureText = useCallback((): string => {
    const text = instanceE.current.txt.text();
    let pureText = text.replace(/&nbsp;/g, ' ');
    pureText = pureText.replace(/<[^>]+>/g, '');
    return pureText;
  }, []);

  const inilializeEditor = useCallback(
    (dom: any) => {
      const { E } = window as any;
      if (E && !isUnmount.current) {
        instanceE.current = new E(dom);
        instanceE.current.customConfig.menus = ['bold', 'foreColor'];
        instanceE.current.customConfig.onchangeTimeout = 16;
        instanceE.current.customConfig.onchange = (html: string) => {
          isNeedInitialization.current = false;
          const pureText = getPureText();
          setCount(pureText.length);
          onChange && onChange({ html, pureText });
        };
        instanceE.current.customConfig.pasteTextHandle = function (content: string) {
          const txt = content ? filterPasteText(content) : '';
          return txt;
        };
        instanceE.current.create();
      }
    },
    [onChange, getPureText],
  );

  useEffect(() => {
    if (value && isNeedInitialization.current) {
      isNeedInitialization.current = false;
      instanceE.current.txt.html(value.html);
      const pureText = getPureText();
      setCount(pureText.length);
      onChange && onChange({ html: value.html, pureText });
    }
  }, [value, getPureText, onChange]);

  useEffect(() => {
    return () => {
      isUnmount.current = true;
    };
  }, []);

  return (
    <div className="introduction">
      <div className={`${count > 500 && 'introduction-error'} introduction-border`}>
        <div ref={inilializeEditor} />
      </div>
      <p className="word-count">
        {count > 500 && <span className="text-error">文本内容超过限制！</span>}
        <span>{count}/500</span>
      </p>
    </div>
  );
});

export default Introduction;
