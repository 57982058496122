/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-bitwise */
/* eslint-disable react/no-access-state-in-setstate */
import './header.less';
import { Popover, Menu, Select, Input } from 'antd';
import React, { Component, ChangeEvent } from 'react';
import * as action from '../../redux/action/login.action';
import * as types from '../../redux/types/login.type';
import { getUsername, getSearchParams, getQueryString } from '../../util';
import Login from '../conceptIndex/Login';
import Triangle from './../../assets/Triangle_fold.png';
import Avatar from './../../assets/ic_avatar.png';
import mapPageData from './../../util/environmentalMapData';
import { connect } from 'react-redux';
import { liveAction } from '../../redux/action/live.action';
import { updateButtonStatus } from '../../redux/action/menu.action';
import { privilegeTypeMapScheduleId, scheduleIdMapPrivilegeType } from '../../util/privilege';
import { globalSearchKeyword } from '../../redux/action/globalSearch.action';
import { hasReadMessage } from '../../redux/action/message.action';
import isNotRootLink from '../../util/isNotRootLink';
import TrackRequest from '../../lib/trackRequest';
import { showUserInfo } from '../../util/prohibitShow';
import Living from './Living';
import { dispatch } from '../../createStore';

const { SubMenu } = Menu;
const { Search } = Input;
const { Option } = Select;
interface P {
  logo?: string | undefined;
  subUsers: [];
  rootOption: [];
  currentRootType: any;
  updateCurrentRootType: any;
  getDataByRoot: any;
  getTimetableData: any;
  history: any;
  functionConfig: any;
  hasRead: number;
  livingLessons: any[];
}
// logo
class Header extends Component<P, any> {
  login: any;
  child: any;
  private timeout: NodeJS.Timeout | null;
  constructor(props: P) {
    super(props);
    this.state = {
      visible: false,
      searchValue: getQueryString('text'),
    };
    this.login = null;
    this.timeout = null;
    this.getCurrentRoot = this.getCurrentRoot.bind(this);
    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
  }
  componentDidMount() {
    action.getUser();
    action.getSubUsers();
    liveAction.getOrg();
    hasReadMessage();

    const currentType =
      this.getPrivilegeSelectValue(this.props.currentRootType) ||
      window.localStorage.getItem('ROOTTYPE');
    this.getLivingLesson(currentType !== 'create');
  }

  UNSAFE_componentWillReceiveProps() {
    const currentSearchValue = getQueryString('text');
    if (this.state.searchValue !== currentSearchValue) {
      this.setState({
        searchValue: currentSearchValue,
      });
    }
  }

  componentWillUnmount() {
    window.clearTimeout(Number(this.timeout));
  }

  // 获取进行中和即将开始的直播(轮询)
  getLivingLesson = (flag = true) => {
    if (!flag) {
      dispatch({
        type: types.GET_LIVING_LESSONS,
        payload: [],
      });
      window.clearTimeout(Number(this.timeout));
      return;
    }
    const getLivingCallback = (data: any[]) => {
      // 大于10条，5分钟轮询，小于10条3分钟轮询
      const delay = data && data.length > 10 ? 5 * 60 * 1000 : 3 * 60 * 1000;
      this.timeout = setTimeout(() => {
        action.getLiving(getLivingCallback.bind(this));
      }, delay);
    };

    window.clearTimeout(Number(this.timeout));
    action.getLiving(getLivingCallback.bind(this));
  };

  // 消息
  onEnterMessage = () => {
    TrackRequest({ name: 'enter_mess_center', data: {} });
    updateButtonStatus({ status: false, type: '', name: '' });
    (window as any)._history.push('/message-lists');
  };

  onChangeSearchValue(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      searchValue: e.target.value,
    });
  }

  setLogin = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  switchSubUser = (item: { userId: any }) => {
    action.getList(item.userId, this.child.switchUser, item);
  };
  onRef = (ref: any) => {
    this.child = ref;
  };

  goToUserInfo = () => {
    TrackRequest({ name: 'watch_per_inform', data: {} });
    window.open('/userInfo');
  };

  // 数据统计
  dataStatistics() {
    TrackRequest({ name: 'watch_per_dashboard', data: {} });
    window.open('/statistics');
  }
  // 获取当前资源
  getCurrentRoot(value: string) {
    // console.log('value', value);
    this.getLivingLesson(!!(value === 'sync' || value === '2'));

    const { type } = getSearchParams(window.location.search);
    if (type === 'kechengbiao') {
      this.loadClassSchedule(value);
    } else {
      this.loadResource(value);
    }
  }
  // 课程表
  loadClassSchedule(value: string) {
    const privilegeType = scheduleIdMapPrivilegeType[~~value];
    this.props.updateCurrentRootType(privilegeType);
    this.props.getTimetableData(value);
    window.localStorage.setItem('ROOTTYPE', privilegeType);
  }
  // 课程公告
  loadResource(value: string) {
    this.props.updateCurrentRootType(value);
    this.props.getDataByRoot(value);
    window.localStorage.setItem('ROOTTYPE', value);
  }
  // 获取权限下拉框value
  getPrivilegeSelectValue(realValue: string): string {
    const { type } = getSearchParams(window.location.search);
    if (type === 'kechengbiao' && realValue) {
      return `${privilegeTypeMapScheduleId[realValue]}`;
    }

    return realValue || '';
  }

  // 获取当前搜索框状态
  getGlobalSearchStatus() {
    const { pathname } = window.location;
    // 缓存计算结果
    if ((this.getGlobalSearchStatus as any).prevPathname === pathname) {
      return (this.getGlobalSearchStatus as any).hasSearch;
    }
    const specialRoute = ['/navigation', '/global-search', '/message-lists', '/message-detatils'];
    (this.getGlobalSearchStatus as any).prevPathname = pathname;
    (this.getGlobalSearchStatus as any).hasSearch = specialRoute.some(
      (r: string) => r === pathname,
    );

    return (this.getGlobalSearchStatus as any).hasSearch;
  }

  // 生成消息样式
  createMessageStyle(count: number) {
    if (count > 99) {
      return {
        right: '-16px',
      };
    }
    if (count > 9 && count <= 99) {
      return {
        right: '-10px',
      };
    }
    if (count <= 9) {
      return {
        width: '16px',
        right: '-8px',
        borderRadius: '50%',
      };
    }
  }

  // 生成消息数量
  createMessageCount(count: number) {
    if (count > 99) {
      return '99+';
    }
    return count;
  }

  render() {
    const { logo, subUsers, rootOption, currentRootType, functionConfig, hasRead, livingLessons } =
      this.props;
    const { visible, searchValue } = this.state;
    const currentGlobalSearchRoot = currentRootType || window.localStorage.getItem('ROOTTYPE');
    const { pathname } = window.location;
    const content = (
      <Menu className="popover-content" selectedKeys={[]}>
        <div className="user-photo">
          {' '}
          <img src={Avatar} alt="头像" />
        </div>
        <p>{getUsername()}</p>
        {functionConfig.showUserInfo && (
          <Menu.Item className="popover-content-item" onClick={this.goToUserInfo}>
            个人信息
          </Menu.Item>
        )}
        {functionConfig.allowUserChangeAccount && subUsers.length > 0 && (
          <SubMenu title="切换账号">
            {subUsers.map((item: any) => {
              return (
                <Menu.Item key={item.userId} onClick={() => this.switchSubUser(item)}>
                  {item.name}
                </Menu.Item>
              );
            })}
          </SubMenu>
        )}
        {/* {functionConfig.showDataStatistics && (
          <Menu.Item className="popover-content-item" onClick={() => this.dataStatistics()}>
            数据统计
          </Menu.Item>
        )} */}
        {functionConfig.allowUserLogout && (
          <Menu.Item className="popover-content-item" onClick={() => action.loginOut()}>
            退出登录
          </Menu.Item>
        )}
      </Menu>
    );
    return (
      <div className="Header-Component">
        <Login
          visible={visible}
          setLogin={this.setLogin}
          isSwitch
          onRef={(ref: any) => (this.child = ref)}
        />
        <div className="inner-header">
          <div
            className="logo"
            onClick={isNotRootLink}
            style={{
              background: `url(${
                logo || (mapPageData() && mapPageData().logo)
              }) no-repeat center left / contain`,
            }}
          />
          {currentGlobalSearchRoot === 'sync' && this.getGlobalSearchStatus() && (
            <>
              <div id="globla-search" className="search">
                <Search
                  value={searchValue}
                  className="search-input"
                  placeholder="请输入关键字"
                  onChange={this.onChangeSearchValue}
                  onSearch={(v: string) => {
                    if (v.trim() !== '') {
                      globalSearchKeyword({ text: v.trim() });
                    }
                  }}
                  style={{ width: 250 }}
                  enterButton
                />
              </div>
              {livingLessons.length > 0 && (
                <div className="livingBox">
                  <Living dataSource={livingLessons} />
                </div>
              )}
            </>
          )}
          <div className="inner-header-right">
            <div className="message" onClick={this.onEnterMessage}>
              {hasRead !== 0 && (
                <span className="has-read" style={this.createMessageStyle(hasRead)}>
                  {this.createMessageCount(hasRead)}
                </span>
              )}
            </div>
            {rootOption.length >= 2 &&
              (pathname === '/navigation' ||
                pathname === '/message-lists' ||
                pathname === '/message-detatils') && (
                <Select
                  value={this.getPrivilegeSelectValue(currentRootType)}
                  onChange={this.getCurrentRoot}
                  style={{ width: 85, marginRight: 23 }}
                >
                  {rootOption.map((item: any) => {
                    return (
                      <Option
                        key={this.getPrivilegeSelectValue(item.type)}
                        value={this.getPrivilegeSelectValue(item.type)}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            {showUserInfo() && (
              <Popover content={content} getPopupContainer={() => this.login}>
                <div className="loginState" ref={(ref) => (this.login = ref)}>
                  <img className="userpick" src={Avatar} alt="用户头像" />
                  <span className="username">
                    <span className="username-text">{getUsername()}</span>
                    <img className="username-icon" src={Triangle} alt="" />
                  </span>
                </div>
              </Popover>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapPropsToState(state: {
  Login: { allowAccountAndPasswordLogin: boolean; subUsers: []; livingLessons: any };
  GloabalCreateSync: {
    rootOption: [];
    currentRootType: null;
    getDataByRoot: (type: string) => {};
    getTimetableData: (type: string) => {};
    timetableRootOption: [];
  };
  DynamicControl: {
    functionConfig: {};
  };
  Message: {
    hasRead: number;
  };
}) {
  return {
    allowAccountAndPasswordLogin: state.Login.allowAccountAndPasswordLogin || false,
    subUsers: state.Login.subUsers || [],
    rootOption: state.GloabalCreateSync.rootOption,
    currentRootType: state.GloabalCreateSync.currentRootType,
    getDataByRoot: state.GloabalCreateSync.getDataByRoot,
    getTimetableData: state.GloabalCreateSync.getTimetableData,
    functionConfig: state.DynamicControl.functionConfig,
    hasRead: state.Message.hasRead,
    livingLessons: state.Login.livingLessons,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    updateCurrentRootType: (type: string): void => {
      dispatch({ type: 'GLOBALCREATESYNCTYPE', payload: type });
    },
  };
}

export default connect(mapPropsToState, mapDispatchToProps, undefined, { pure: false })(Header);
