// 上传文件类型
export const uploadFileTypes = [
  'ppt',
  'pptx',
  'jfif',
  'pjpeg',
  'jpeg',
  'pjp',
  'jpg',
  'png',
  'gif',
  'swl',
  'swf',
  'mp3',
  'wma',
  'wav',
  'mp4',
  'm4v',
  'avi',
  'mkv',
  'flv',
  'rm',
  '3gp',
  'asf',
  'rmvb',
  'xls',
  'xlsx',
  'text',
  'txt',
  'doc',
  'docx',
  'pdf',
  'zip',
  'rar',
  '7z',
];
