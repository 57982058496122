/*
 * @Author: gouyang
 * @Description: 学校配置
 * @Date: 2019-08-13 17:33:04
 */
// 高中
import nb1 from '../static/highSchoolImg/banner1.jpg';
import nb2 from '../static/highSchoolImg/banner2.jpg';
import nb3 from '../static/highSchoolImg/banner3.jpg';
import ic1 from '../static/highSchoolImg/icon-1.png';
import ic2 from '../static/highSchoolImg/icon-2.png';
import ic3 from '../static/highSchoolImg/icon-3.png';
import bzsz from '../static/highSchoolImg/bzsz.png';
import cyy from '../static/highSchoolImg/cyy.png';
import kd from '../static/highSchoolImg/kd.png';
import zy from '../static/highSchoolImg/zy.png';
import zkw from '../static/highSchoolImg/zkw.png';
import lhx from '../static/highSchoolImg/lhx.png';
import bk_nor from '../static/highSchoolImg/bk_nor.png';
import bk_pre from '../static/highSchoolImg/bk_pre.png';
import bk_swiper from '../static/highSchoolImg/bk_swiper.jpg';
import sk_nor from '../static/highSchoolImg/sk_nor.png';
import sk_pre from '../static/highSchoolImg/sk_pre.png';
import sk_swiper from '../static/highSchoolImg/sk_swiper.jpg';
import zy_nor from '../static/highSchoolImg/zy_nor.png';
import zy_pre from '../static/highSchoolImg/zy_pre.png';
import zy_swiper from '../static/highSchoolImg/zy_swiper.jpg';
import sj_nor from '../static/highSchoolImg/sj_nor.png';
import sj_pre from '../static/highSchoolImg/sj_pre.png';
import sj_swiper from '../static/highSchoolImg/sj_swiper.jpg';
import p1 from '../static/highSchoolImg/1.jpg';
import p2 from '../static/highSchoolImg/2.jpg';
import p3 from '../static/highSchoolImg/3.jpg';
import p4 from '../static/highSchoolImg/4.jpg';
import p5 from '../static/highSchoolImg/5.jpg';
import p6 from '../static/highSchoolImg/6.jpg';
import p7 from '../static/highSchoolImg/7.jpg';
import p8 from '../static/highSchoolImg/8.jpg';
import p9 from '../static/highSchoolImg/9.jpg';
import p10 from '../static/highSchoolImg/10.jpg';
// 初中
import cb1 from '../static/juniorMiddleSchoolImg/banner1.jpg';
import cb2 from '../static/juniorMiddleSchoolImg/banner2.jpg';
import cb3 from '../static/juniorMiddleSchoolImg/banner3.jpg';
import cb4 from '../static/juniorMiddleSchoolImg/banner4.jpg';
import cqzx from '../static/juniorMiddleSchoolImg/cqzx.jpg';
import syez from '../static/juniorMiddleSchoolImg/syez.jpg';
import bk_swiper_c from '../static/juniorMiddleSchoolImg/bk_swiper_c-10.png';
import ks_swiper_c from '../static/juniorMiddleSchoolImg/ks_swiper_c.jpg';
import sk_swiper_c from '../static/juniorMiddleSchoolImg/sk_swiper_c-10.png';
import zy_swiper_c from '../static/juniorMiddleSchoolImg/zy_swiper_c.jpg';
import cp1 from '../static/juniorMiddleSchoolImg/1.jpg';
import cp2 from '../static/juniorMiddleSchoolImg/2.jpg';
import cp3 from '../static/juniorMiddleSchoolImg/3-1.jpg';
import cp4 from '../static/juniorMiddleSchoolImg/4.jpg';
import cp5 from '../static/juniorMiddleSchoolImg/5.jpg';
import cp6 from '../static/juniorMiddleSchoolImg/6.jpg';
import cp7 from '../static/juniorMiddleSchoolImg/7.jpg';
import cp8 from '../static/juniorMiddleSchoolImg/8.jpg';
import cp9 from '../static/juniorMiddleSchoolImg/9.jpg';
import cp10 from '../static/juniorMiddleSchoolImg/10.jpg';
// 小学
import xb1 from '../static/primarySchoolImg/banner1.jpg';
import xb2 from '../static/primarySchoolImg/banner2.jpg';
import xb3 from '../static/primarySchoolImg/banner3.jpg';
import xb4 from '../static/primarySchoolImg/banner4.jpg';
import cdsf from '../static/primarySchoolImg/cdsf.jpg';
import spxc from '../static/primarySchoolImg/spxc.jpg';
import bk_swiper_x from '../static/primarySchoolImg/bk_swiper_x.jpg';
import ks_swiper_x from '../static/primarySchoolImg/ks_swiper_x.jpg';
import sk_swiper_x from '../static/primarySchoolImg/sk_swiper_x.jpg';
import zy_swiper_x from '../static/primarySchoolImg/zy_swiper_x.jpg';
import xp1 from '../static/primarySchoolImg/1.jpg';
import xp2 from '../static/primarySchoolImg/2.jpg';
import xp3 from '../static/primarySchoolImg/3.jpg';
import xp4 from '../static/primarySchoolImg/4.jpg';
import xp5 from '../static/primarySchoolImg/5.jpg';
import xp6 from '../static/primarySchoolImg/6.jpg';
import xp7 from '../static/primarySchoolImg/7.jpg';
import xp8 from '../static/primarySchoolImg/8.jpg';
import xp9 from '../static/primarySchoolImg/9.jpg';
import xp10 from '../static/primarySchoolImg/10.jpg';
// 幼儿园
import yb1 from '../static/kidergardenImg/banner1.jpg';
import yb2 from '../static/kidergardenImg/banner2.jpg';
import yb3 from '../static/kidergardenImg/banner3.jpg';
import yb4 from '../static/kidergardenImg/banner4.jpg';
import bz_swiper_y from '../static/kidergardenImg/bz_swiper_y.jpg';
import cg_swiper_y from '../static/kidergardenImg/cg_swiper_y.jpg';
import lz_swiper_y from '../static/kidergardenImg/lz_swiper_y.jpg';
import yd_swiper_y from '../static/kidergardenImg/yd_swiper_y.jpg';
import lz_nor from '../static/kidergardenImg/lz_nor.png';
import lz_pre from '../static/kidergardenImg/lz_pre.png';
import yp1 from '../static/kidergardenImg/1.jpg';
import yp2 from '../static/kidergardenImg/2.jpg';
import yp3 from '../static/kidergardenImg/3.jpg';
import yp4 from '../static/kidergardenImg/4.jpg';
import yp5 from '../static/kidergardenImg/5.jpg';
import yp6 from '../static/kidergardenImg/6.jpg';
import yp7 from '../static/kidergardenImg/7.jpg';
import yp8 from '../static/kidergardenImg/8.jpg';
import yp9 from '../static/kidergardenImg/9.jpg';
import yp10 from '../static/kidergardenImg/10.jpg';
// 未来课堂
import fb1 from '../static/futureSchoolImg/banner1.jpg';
import fb2 from '../static/futureSchoolImg/banner2.jpg';
import fb3 from '../static/futureSchoolImg/banner3.jpg';
import fb4 from '../static/futureSchoolImg/banner4.jpg';
import mxyl_swiper from '../static/futureSchoolImg/mxyl_swiper.jpg';
import ai_swiper from '../static/futureSchoolImg/ai_swiper.jpg';
import jsfk_swiper from '../static/futureSchoolImg/jsfk_swiper.jpg';
import qmsj_swiper from '../static/futureSchoolImg/qmsj_swiper.jpg';
import fp1 from '../static/futureSchoolImg/1.jpg';
import fp2 from '../static/futureSchoolImg/2.jpg';
import fp3 from '../static/futureSchoolImg/3.jpg';
import fp4 from '../static/futureSchoolImg/4.jpg';
import fp5 from '../static/futureSchoolImg/5.jpg';
import fp6 from '../static/futureSchoolImg/6.jpg';
import fp7 from '../static/futureSchoolImg/7.jpg';
import fp8 from '../static/futureSchoolImg/8.jpg';
import fp9 from '../static/futureSchoolImg/9.jpg';
import fp10 from '../static/futureSchoolImg/10.jpg';
import mx_nor from '../static/futureSchoolImg/mx_nor.png';
import mx_pre from '../static/futureSchoolImg/mx_pre.png';
import AI_nor from '../static/futureSchoolImg/AI_nor.png';
import AI_pre from '../static/futureSchoolImg/AI_pre.png';
import js_nor from '../static/futureSchoolImg/js_nor.png';
import js_pre from '../static/futureSchoolImg/js_pre.png';
import dsj_nor from '../static/futureSchoolImg/dsj_nor.png';
import dsj_pre from '../static/futureSchoolImg/dsj_pre.png';

interface ShoolConfig {
  [s: string]: any;
}
const shoolConfig: ShoolConfig = {
  // 高中
  highSchool: {
    indexHorselight: [nb1, nb2, nb3], // 头部轮播图
    floatingLayer: {
      // 浮层内容
      cover_1: [
        { type: '个省级行政区', value: 13 },
        // { type: '省', value: 9 },
        // { type: '区', value: 3 },
        // { type: '市', value: 1 },
      ],
      statistics: [
        {
          total: 300,
          desc: '合作学校',
        },
        {
          total: 1600,
          desc: '受益班级',
        },
        {
          total: 10000,
          desc: '受益教师',
        },
        {
          total: 93000,
          desc: '受益学生',
        },
      ],
    },
    infoDesc: {
      // 信息描述内容
      title: [
        {
          icon: ic2,
          h: '学生学业成绩优秀',
          p: '从2005年至2019年，七中网校已成为向全国重点本科院校不断输送人才的生源基地。先后有114名同学考取清华北大，一大批学生就读国家985、211计划的高校。',
        },
        {
          icon: ic1,
          h: '教师跟岗研修成长迅速',
          p: '经过长期的协同教学，一大批远端教师快速成长，成为市区优秀教师、学科带头人、骨干教师，并多次在各级赛课中夺得大奖，造就了一批省内外名师。',
        },
        {
          icon: ic3,
          h: '远端学校持续性发展',
          p: '牵引远端学校适应远程教学的高效管理要求，带动了远端学校整体管理效率的提升和教学管理机制的建立，形成了以远程教学为抓手，推动学校教学管理持续发展的态势。',
        },
      ],
      swiper: [
        {
          icon: kd,
          t: '四川省甘孜州康定中学',
          p: '借助全日制远程直播教学实现了“康巴教育明珠，藏区人才摇篮”的理想，带动了整个甘孜州的网课教学，进而大大地提高了甘孜州的教育质量。',
        },
        {
          icon: bzsz,
          t: '四川省巴中市第三中学',
          p: '从一个低层次的薄弱中学成长为一个质朴而有内涵的高完中，从网班岗位上成长了2名特级教师，1名正高级教师，12名省级优秀骨干教师、省优秀教师和省优秀班主任，60多名市级优秀教师和学科带头人。',
        },
        {
          icon: zy,
          t: '张瑜',
          p: '四川省泸定中学英语教师。先后荣获县、州英语赛课一等奖。2018年在四川省举办的全省高中英语课堂教学展评活动中获一等奖，成为甘孜州首例参加全国英语课例遴选教师。',
        },
        {
          icon: cyy,
          t: '程远友',
          p: '四川省甘孜州康定中学英语教师。经过15年的教学与管理实践，现已成长为国家“万人计划”教学名师、全国先进工作者、国务院津贴专家、四川省学术和技术带头人。',
        },
        {
          icon: zkw,
          t: '曾楷徽',
          p: '毕业于广西平果高级中学，就读于清华大学#“七中老师们上课时总能对知识进行形象、透彻的讲解，七中的同学们总能回答出老师的问题，他们巧妙的思路和新奇的方法常常让我有醍醐灌顶之感。”',
        },
        {
          icon: lhx,
          t: '李海霞',
          p: '毕业于四川省宣汉南坝中学，就读于清华大学#“从前的自己从来没有想过今天能坐在清华图书馆里学习。这个世界，真是有无数的可能，只要足够努力，它就会给你腾飞的空间—而七中网校就是助我起飞的风。”',
        },
      ],
    },
    region1: {
      // 区域1的内容
      title: {
        t: '四位一体$四个同时',
        p: '前端教师、远端教师、把关教师、技术教师"四位一体"分工协同',
      },
      horselight: [
        { src: bk_swiper, id: 'bk_swiper_1' },
        { src: sk_swiper, id: 'sk_swiper_2' },
        { src: zy_swiper, id: 'zy_swiper_3' },
        { src: sj_swiper, id: 'sj_swiper_4' },
      ],
      buttonBox: [
        {
          icon_1: bk_nor,
          icon_2: bk_pre,
          p: '同时备课',
        },
        {
          icon_1: sk_nor,
          icon_2: sk_pre,
          p: '同时上课',
        },
        {
          icon_1: zy_nor,
          icon_2: zy_pre,
          p: '同时作业',
        },
        {
          icon_1: sj_nor,
          icon_2: sj_pre,
          p: '同时考试',
        },
      ],
    },
    region2: {
      // 区域2的内容
      content: [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10],
    },
    friendShipLinks: [
      {
        id: 'cz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（初中）',
      },
      {
        id: 'xx',
        name_1: '成都市实验小学',
        name_2: '全日制远程植入式教学',
      },
      {
        id: 'yey',
        name_1: '成都市金牛区机关三幼',
        name_2: '远程观摩式教学',
      },
    ],
  },
  // 初中
  middleSchool: {
    indexHorselight: [cb1, cb2, cb3, cb4], // 头部轮播图
    floatingLayer: {
      // 浮层内容
      cover_1: [
        { type: '个省级行政区', value: 9 },
        // { type: '省', value: 9 },
        // { type: '区', value: 1 },
        // { type: '市', value: 1 },
      ],
      statistics: [
        {
          total: 200,
          desc: '合作学校',
        },
        {
          total: 2000,
          desc: '受益班级',
        },
        {
          total: 10000,
          desc: '受益教师',
        },
        {
          total: 100000,
          desc: '受益学生',
        },
      ],
    },
    infoDesc: {
      // 信息描述内容
      title: [
        {
          icon: ic2,
          h: '学生学业成绩优秀',
          p: '远端学校中考成绩在区域内成效显著，不少学校成长为当地最具影响力的初中学校。',
        },
        {
          icon: ic1,
          h: '教师跟岗研修成长迅速',
          p: '经过长期的协同教学，一大批远端教师快速成长，成为市区优秀教师、学科带头人、骨干教师，并多次在各级赛课中夺得大奖，造就了一批省内外名师。',
        },
        {
          icon: ic3,
          h: '远端学校持续性发展',
          p: '牵引远端学校适应远程教学的高效管理要求，带动了远端学校整体管理效率的提升和教学管理机制的建立，形成了以远程教学为抓手，推动学校教学管理持续发展的态势。',
        },
      ],
      swiperArrowHide: true,
      swiper: [
        {
          icon: syez,
          t: '江西省赣江市上犹县第二中学',
          p: '通过几年“全日制远程录播教学”的实践与探索，教师的教学理念得到了更新，学生的自信心、自豪感油然而生，学习兴趣空前高涨，教学效率明显提高。',
        },
        {
          icon: cqzx,
          t: '四川省崇州市崇庆中学初中校区',
          p: '自进行录播教学以来，我校任课教师经历了由怀疑、观望到接受的过程。目前，网班成为了我校教学的一张金字招牌，是我校校本研修的重要载体，成为学生、家长追逐的理想之地。',
        },
      ],
    },
    region1: {
      // 区域1的内容
      title: {
        t: '四位一体$四个同步',
        p: '前端教师、远端教师、把关教师、技术教师"四位一体"分工协同',
      },
      horselight: [
        { src: bk_swiper_c, id: 'bk_swiper_c_5' },
        { src: sk_swiper_c, id: 'sk_swiper_c_6' },
        { src: zy_swiper_c, id: 'zy_swiper_c_7' },
        { src: ks_swiper_c, id: 'zy_swiper_c_8' },
      ],
      buttonBox: [
        {
          icon_1: bk_nor,
          icon_2: bk_pre,
          p: '同时备课',
        },
        {
          icon_1: sk_nor,
          icon_2: sk_pre,
          p: '同步上课',
        },
        {
          icon_1: zy_nor,
          icon_2: zy_pre,
          p: '同步作业',
        },
        {
          icon_1: sj_nor,
          icon_2: sj_pre,
          p: '同步考试',
        },
      ],
    },
    region2: {
      // 区域2的内容
      content: [cp1, cp2, cp3, cp4, cp5, cp6, cp7, cp8, cp9, cp10],
    },
    friendShipLinks: [
      {
        id: 'gz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（高中）',
      },
      {
        id: 'xx',
        name_1: '成都市实验小学',
        name_2: '全日制远程植入式教学',
      },
      {
        id: 'yey',
        name_1: '成都市金牛区机关三幼',
        name_2: '远程观摩式教学',
      },
    ],
  },
  // 小学
  primarySchool: {
    indexHorselight: [xb1, xb2, xb3, xb4], // 头部轮播图
    floatingLayer: {
      // 浮层内容
      cover_1: [
        { type: '个省级行政区', value: 5 },
        // { type: '省', value: 6 },
        // { type: '区', value: 1 },
        // { type: '市', value: 1 },
      ],
      statistics: [
        {
          total: 130,
          desc: '合作学校',
        },
        {
          total: 1700,
          desc: '受益班级',
        },
        {
          total: 5000,
          desc: '受益教师',
        },
        {
          total: 90000,
          desc: '受益学生',
        },
      ],
    },
    infoDesc: {
      // 信息描述内容
      title: [
        {
          icon: ic2,
          h: '学生学业成绩优秀',
          p: '全日制远程植入式教学的开展，使远端学校的孩子和实小的孩子实现了在同一片蓝天下学习，享受一样的教学资源，使远端孩子们的视野开阔了，信息灵通了，变得“耳聪目明”了。',
        },
        {
          icon: ic1,
          h: '教师跟岗研修成长迅速',
          p: '经过长期的协同教学，一大批远端教师快速成长，成为市区优秀教师、学科带头人、骨干教师，并多次在各级赛课中夺得大奖，造就了一批省内外名师。',
        },
        {
          icon: ic3,
          h: '远端学校持续性发展',
          p: '牵引远端学校适应远程教学的高效管理要求，带动了远端学校整体管理效率的提升和教学管理机制的建立，形成了以远程教学为抓手，推动学校教学管理持续发展的态势。',
        },
      ],
      swiperArrowHide: true,
      swiper: [
        {
          icon: cdsf,
          t: '成都师范附属实验学校',
          p: '在实小雅教育的引领下，远端教师通过读课、备课、还课、议课的环节，让远端课堂逐步具有“活雅课堂”的特色。老师们忍不住惊叹：实小网校真是教师专业成长的兵工厂！',
        },
        {
          icon: spxc,
          t: '四川省阿坝州松潘县城关小学',
          p: '自启动植入式教学以来，网班学生从一年级就开始学习英语，打破了以往三年级才开始接触英语的历史。每学期学校都会举办手抄报大赛、朗诵比赛等课外活动，网班学生在自主实践、探索和合作交流等方面均有提升。',
        },
      ],
    },
    region1: {
      // 区域1的内容
      title: {
        t: '四位一体$四个同步',
        p: '前端教师、远端教师、把关教师、技术教师"四位一体"分工协同',
      },
      horselight: [
        { src: bk_swiper_x, id: 'bk_swiper_x_9' },
        { src: sk_swiper_x, id: 'sk_swiper_x_10' },
        { src: zy_swiper_x, id: 'zy_swiper_x_11' },
        { src: ks_swiper_x, id: 'ks_swiper_x_12' },
      ],
      buttonBox: [
        {
          icon_1: bk_nor,
          icon_2: bk_pre,
          p: '同时备课',
        },
        {
          icon_1: sk_nor,
          icon_2: sk_pre,
          p: '同步上课',
        },
        {
          icon_1: zy_nor,
          icon_2: zy_pre,
          p: '同步作业',
        },
        {
          icon_1: sj_nor,
          icon_2: sj_pre,
          p: '同步考试',
        },
      ],
    },
    region2: {
      // 区域2的内容
      content: [xp1, xp2, xp3, xp4, xp5, xp6, xp7, xp8, xp9, xp10],
    },
    friendShipLinks: [
      {
        id: 'gz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（高中）',
      },
      {
        id: 'cz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（初中）',
      },
      {
        id: 'yey',
        name_1: '成都市金牛区机关三幼',
        name_2: '远程观摩式教学',
      },
    ],
  },
  // 幼儿园
  kidergarden: {
    garden: true,
    indexHorselight: [yb1, yb2, yb3, yb4], // 头部轮播图
    floatingLayer: {
      // 浮层内容
      statistics: [
        {
          total: 30,
          desc: '合作学校',
        },
        {
          total: 300,
          desc: '受益班级',
        },
        {
          total: 800,
          desc: '受益教师',
        },
        {
          total: 16000,
          desc: '受益学生',
        },
      ],
    },
    region1: {
      // 区域1的内容
      title: {
        t: '四位一体$四步进阶',
        p: '前端教师、远端教师、把关教师、技术教师"四位一体"分工协同',
      },
      horselight: [
        { src: yd_swiper_y, id: 'yd_swiper_y_13' },
        { src: cg_swiper_y, id: 'cg_swiper_y_14' },
        { src: bz_swiper_y, id: 'bz_swiper_y_15' },
        { src: lz_swiper_y, id: 'lz_swiper_y_16' },
      ],
      buttonBox: [
        {
          icon_1: bk_nor,
          icon_2: bk_pre,
          p: '研读',
        },
        {
          icon_1: sk_nor,
          icon_2: sk_pre,
          p: '重构',
        },
        {
          icon_1: zy_nor,
          icon_2: zy_pre,
          p: '比照',
        },
        {
          icon_1: lz_nor,
          icon_2: lz_pre,
          p: '垒筑',
        },
      ],
    },
    region2: {
      // 区域2的内容
      content: [yp1, yp2, yp3, yp4, yp5, yp6, yp7, yp8, yp9, yp10],
    },
    friendShipLinks: [
      {
        id: 'gz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（高中）',
      },
      {
        id: 'cz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（初中）',
      },
      {
        id: 'xx',
        name_1: '成都市实验小学',
        name_2: '全日制远程植入式教学',
      },
    ],
  },
  // 未来课堂
  futureSchool: {
    indexHorselight: [fb1, fb2, fb3, fb4], // 头部轮播图
    floatingLayer: {
      // 浮层内容
      cover_2: '四川、重庆、贵州、云南、广西、甘肃',
      statistics: [
        {
          total: 70,
          desc: '合作学校',
        },
        {
          total: 500,
          desc: '受益班级',
        },
        {
          total: 2000,
          desc: '受益教师',
        },
        {
          total: 25000,
          desc: '受益学生',
        },
      ],
    },
    infoDesc: {
      // 信息描述内容
      title: [
        {
          icon: ic2,
          h: '学习更加便捷、有趣',
          p: '学生可以利用多媒体教学资源随时进行学习，打破传统教学时间与空间的限制。教学资源的云端储存、错题集、收藏集、学习数据分析等环节，支撑学生通过精准发现学习“短板”，随时进行自主学习，培养良好学习习惯。丰富的名校资源，能激发学生学习的潜力，不断促进学生学习的主动性与自主性。',
        },
        {
          icon: ic1,
          h: '因材施教、精准教学',
          p: '未来课堂构建了一种适时强反馈的课堂教学模式，信息化课堂内多维度的全面采集数据，通过数据帮助辨别个体差异。平台内向师生开放学生学情数据，便于教师精准掌握学生学情，实现个性化教学。',
        },
        {
          icon: ic3,
          h: '高效互动、创新课堂',
          p: '未来课堂模式下使课前、课中、课后的教学模式发生了改变。信息化的赋能，极大限度减轻教师教学负担，以“低耗高效、轻负高质”为目标，促进师生全向互动为抓手，为每位学生带来最大获得感的课堂。',
        },
      ],
    },
    region1: {
      // 区域1的内容
      title: {
        t: '全信息化$实证教学',
      },
      horselight: [
        { src: mxyl_swiper, id: 'mxyl_swiper' },
        { src: ai_swiper, id: 'ai_swiper' },
        { src: jsfk_swiper, id: 'jsfk_swiper' },
        { src: qmsj_swiper, id: 'qmsj_swiper' },
      ],
      buttonBox: [
        {
          icon_1: mx_nor,
          icon_2: mx_pre,
          p: '名校引领$多校协作',
        },
        {
          icon_1: AI_nor,
          icon_2: AI_pre,
          p: '原生书写$AI识别',
        },
        {
          icon_1: js_nor,
          icon_2: js_pre,
          p: '即时反馈$因材施教',
        },
        {
          icon_1: dsj_nor,
          icon_2: dsj_pre,
          p: '全面数据$精准管理',
        },
      ],
    },
    region2: {
      // 区域2的内容
      content: [fp1, fp2, fp3, fp4, fp5, fp6, fp7, fp8, fp9, fp10],
    },
    friendShipLinks: [
      {
        id: 'gz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（高中）',
      },
      {
        id: 'cz',
        name_1: '四川成都七中',
        name_2: '东方闻道网校（初中）',
      },
      {
        id: 'future',
        name_1: '未来课堂',
        name_2: 'APP下载',
      },
    ],
  },
};

export default shoolConfig;
