import React from 'react';
import { Spin, Icon } from 'antd';
import './loading.less';

const antIcon = (
  <Icon
    type="loading"
    style={{ fontSize: '36px', width: '36px', height: '36px', margin: '-18px' }}
    spin
  />
);

const Loading: React.FC = () => {
  return (
    <div className="loading-component">
      <Spin indicator={antIcon}>{/* <div className='loading-text'>努力加载中...</div> */}</Spin>
    </div>
  );
};

export default Loading;
