/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Input } from 'antd';
import './index.less';

const { TextArea } = Input;

interface IProps {
  [propname: string]: any;
}
interface IState {
  value: string;
  length?: number;
}
export class AJTextArea extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    this.setState({
      value: nextProps.value,
    });
  }
  render() {
    const { maxLength } = this.props;
    const { value } = this.state;
    return (
      <span className="aJTextArea">
        <TextArea
          rows={4}
          {...this.props}
          value={value}
          onChange={(e) => {
            const { value } = e.target;
            this.setState({
              length: value.length,
              value,
            });
            this.props.onChange && this.props.onChange(value);
          }}
          onBlur={(e) => {
            const value = e.target.value.trim();
            this.setState({
              length: value.length,
              value,
            });
            this.props.onChange && this.props.onChange(value);
          }}
        />
        {maxLength !== undefined && (
          <span className="tip">{`${value ? value.length : 0}/${maxLength}`}</span>
        )}
      </span>
    );
  }
}
//  不去除空格 的文本域
export class AJTextAreaTwo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    this.setState({
      value: nextProps.value,
    });
  }
  render() {
    const { maxLength } = this.props;
    const { value } = this.state;
    return (
      <span className="aJTextArea">
        <TextArea
          rows={4}
          {...this.props}
          value={value}
          onChange={(e) => {
            const { value } = e.target;
            this.setState({
              length: value.length,
              value,
            });
            this.props.onChange && this.props.onChange(value);
          }}
          onBlur={(e) => {
            const { value } = e.target;
            this.setState({
              length: value.length,
              value,
            });
            this.props.onChange && this.props.onChange(value);
          }}
        />
        {maxLength !== undefined && (
          <span className="tip">{`${value ? value.length : 0}/${maxLength}`}</span>
        )}
      </span>
    );
  }
}

export class AJTextField extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { value } = props;
    this.state = {
      value,
      length: value ? value.length : 0,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { value } = nextProps;
    this.setState({
      value,
      length: value ? value.length : 0,
    });
  }
  render() {
    const { maxLength } = this.props;
    const { length, value } = this.state;
    return (
      <Input
        {...this.props}
        autoComplete="off"
        value={value}
        addonAfter={maxLength && <div>{`${length}/${maxLength}`}</div>}
        onChange={(e) => {
          const { value } = e.target;
          this.setState({
            length: value.length,
            value,
          });
          this.props.onChange && this.props.onChange(value);
        }}
        onBlur={(e) => {
          const value = e.target.value.trim();
          this.setState({
            length: value.length,
            value,
          });
          this.props.onChange && this.props.onChange(value);
        }}
      />
    );
  }
}
