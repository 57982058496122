/*
 * @Descripttion: 消息action
 * @Author: gouyang
 * @Date: 2020-12-10 10:52:03
 * @LastEditors: gouyang
 * @LastEditTime: 2020-12-17 14:50:57
 */
import { message } from 'antd';
import AxiosWrap from '../../lib/request';
import { dispatch } from '../../createStore';
import * as messageType from '../types/message.type';
import { getUserId } from '../../util';

export const getMessageLists = async (page: number, size: number, type: '0' | '1') => {
  try {
    const { data, status } = await AxiosWrap({
      url: '/user_messages',
      method: 'get',
      params: {
        page,
        size,
        type,
      },
    });
    if (status === 200) {
      const {
        _embedded,
        page: { number, size, totalElements, totalPages },
      } = data;
      if (!_embedded && totalElements !== 0) {
        // 当前页没有数据重新获取
        getMessageLists(totalPages - 1, size, type);
      } else {
        const result = {
          data: _embedded ? _embedded.userMessages : [],
          page: number + 1,
          size,
          total: totalElements,
          key: type,
        };
        if (type === '0') {
          dispatch({ type: messageType.UPDATE_SYSTEM_MESSAGE, payload: result });
        } else if (type === '1') {
          dispatch({ type: messageType.UPDATE_IMPORTANT_MESSAGE, payload: result });
        }
      }
    }
  } catch (e) {
    // 回调
  }
};

export const hasReadMessage = async () => {
  try {
    const { data, status } = await AxiosWrap({
      url: '/un_read_message_amount',
      method: 'get',
    });
    if (status === 200) {
      const { total } = data;
      let hasRead = total;
      if (typeof total !== 'number') hasRead = 0;
      dispatch({ type: messageType.UPDATE_HAS_READ, payload: hasRead });
    }
  } catch (e) {
    // 回调
  }
};

export const getMessageDetails = async (messageId: string) => {
  try {
    const { data, status } = await AxiosWrap({
      url: `/user_messages/${messageId}`,
      method: 'get',
      params: {
        clientId: 0,
      },
    });
    if (status === 200) {
      dispatch({ type: messageType.UPDATE_MESSAGE_DETAILS, payload: data });
    }
  } catch (e) {
    const { data } = e as any;
    if (data && data.message) {
      message.error(data.message);
    }
  }
};

export const getLatestMessage = async () => {
  try {
    const { data, status } = await AxiosWrap({
      url: '/user_latest_message',
      method: 'get',
    });
    if (status === 200 && data) {
      dispatch({
        type: messageType.UPDATE_LATESTS_MESSAGE,
        payload: data,
      });
    } else {
      window.localStorage.setItem('ISREAD', 'true');
    }
  } catch (e) {
    window.localStorage.setItem('ISREAD', 'true');
  }
};

export const readLatestMessage = async (messageId: number, cb: () => void) => {
  try {
    const { status } = await AxiosWrap({
      url: '/message_read_histories',
      method: 'post',
      data: {
        messageId,
        clientId: 0,
        userId: getUserId(),
      },
    });
    if (status === 200) {
      cb();
    }
  } catch (e) {
    // 回调
  }
};
