import * as types from '../types/article.type';

interface Action {
  type: string;
  payload: {};
}
const initialState: any = {
  article: {
    // 文章列表
    data: [],
    total: 0,
    pageNumber: 0,
  },
  info: {
    files: [],
    extensionData: {
      controlData: [],
    },
  },
};

const Article = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.ARTICLE_LIST_REQUEST: // 文章列表
      return { ...state, article: payload };
    case types.ARTICLE_DETAIL: // 文章列表
      return { ...state, info: payload };
    case 'CALL_BACK':
      return { ...state, flag: payload };
    case 'RESET_ARTICLE_LISTS': // 重置列表
      return {
        ...state,
        article: {
          data: [],
          total: 0,
          pageNumber: 0,
        },
      };
    default:
      return state;
  }
};
export default Article;
