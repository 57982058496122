/*
 * @Author: gouyang
 * @Description: 暂无数据
 * @Date: 2019-09-05 15:07:05
 */
import React from 'react';
import emptyImage from './../../../static/content_null.png';

import './empty.less';

interface P {
  title?: string;
}

function StatisticsEmpty({ title }: P) {
  return (
    <div className="empty">
      <img src={emptyImage} alt="" />
      {title ? <span>{title}</span> : <span>暂无数据</span>}
    </div>
  );
}

export default StatisticsEmpty;
