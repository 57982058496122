import './index.less';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Store from './createStore';
import './sentry';
import { setup } from 'eastedu-login-sdk';
import apiConfig from './config/api';

const prefixUrl = `${apiConfig.gateway}/openschool-teaching-api`;

setup({
  endpoint: prefixUrl,
  sdkToken: apiConfig.passport_sdk_token,
});

ReactDOM.render(
  <Provider store={Store}>
    <LocaleProvider locale={zhCN}>
      <App />
    </LocaleProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
