import './OutHeader.less';
import React, { Component } from 'react';
import mapPageData from './../../util/environmentalMapData';
import getWechatLink from './../../config/wechatLink';
import loginLink from './../../config/loginLink';
import { withRouter } from 'react-router-dom';

const env = (window as any).__UNION_CENTER_ENV__;

class OutHeader extends Component {
  
  login() {
    window.location.href = `${loginLink}/blank`;
  }
  // 微信登录
  wetChatLogin() {
    window.location.href = getWechatLink();
    //  this.props.history.push('/oauth2/callback');
  }
  render() {
    return (
      <div className="OutHeader-Component">
        <div className="inner-content">
          <div className="left">
            <img alt="logo" src={mapPageData().logo} />
          </div>
          <div className="right">
            <ul className="clear">
              <li className="item" onClick={() => this.wetChatLogin()}>
                <span className="btn">登录</span>
              </li>
              {/* {(env === 'TEST' || env === 'DEV') && (
                <li className="item" onClick={() => this.login()}>
                  <span className="btn">原流程登录</span>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(OutHeader);
