/*
 * @Author: gouyang
 * @Description: 表格类型统计
 * @Date: 2019-09-04 14:30:51
 */
import React from 'react';

import './style.less';

interface P {
  data: { [s: string]: any };
}
function FormStatistics({ data }: P) {
  const { lessonCount, actuallyTotalDuration, lessonDuration, averageLessonDuration } = data;
  return (
    <div className="from-statistics">
      <p>
        <span>已参与课时个数/课时总时长</span>
        <span>
          {lessonCount}
          <i>个</i> / {actuallyTotalDuration}
          <i>分钟</i>
        </span>
      </p>
      <p>
        <span>已参与总时长</span>
        <span>
          {lessonDuration}
          <i>分钟</i>
        </span>
      </p>
      <p>
        <span>已参与平均时长</span>
        <span>
          {averageLessonDuration}
          <i>分钟</i>
        </span>
      </p>
    </div>
  );
}

export default FormStatistics;
