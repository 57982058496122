import './index.less';
import React from 'react';

function NotFound() {
  return (
    <div className="not-found">
      <div className="cell">
        <div className="bg" />
        <div>对不起，您要找的页面丢失了!</div>
      </div>
    </div>
  );
}

export default NotFound;
