// 教材章节 新增
import React from 'react';
import { Modal, Row, Col, Tree, message } from 'antd';
import { connect } from 'react-redux';
import { getChaptersTree } from '../../../redux/action/base.action';
import './style.less';

const { TreeNode } = Tree;
interface P {
  cancel: (flg: boolean) => void; // 关闭弹出
  visible: boolean; // 弹出显示控制
  textBooks: any; // 教材列表
  chaptersList: any; // 章节列表
  cbackData: (data: any) => void; // 返回数据的回调方法
  data: any; // 数据回显
}
interface S {
  data: any; // 选中的列表数据
  bookId: string | number; // 当前选中数据
  bookName: string;
  checkedKeys: any; // 选中的key 值
  subjectCode: string; // 学科
}
class SelectTeaching extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      data: {},
      bookId: '',
      bookName: '',
      checkedKeys: {},
      subjectCode: '',
    };
  }
  componentDidMount() {
    // 选中数据回显
    this.dataEcho();
  }
  // 数据回显
  dataEcho = () => {
    const { data } = this.props;
    // 复选框选中的key值
    const keys: any = new Set();
    for (const i in data) {
      if (Object.prototype.hasOwnProperty.call(data, i)) {
        keys.add(data[i].chapterId);
      }
    }
    // 选择的数据处理
    let bookIds: any = new Set();
    for (const i in data) {
      if (Object.prototype.hasOwnProperty.call(data, i)) {
        bookIds.add(data[i].materialId);
      }
    }
    bookIds = [...bookIds];
    // 遍历对比 数据的 教材id materialId ,进行数据归类
    const newData: any = {};
    for (const i in bookIds) {
      if (Object.prototype.hasOwnProperty.call(bookIds, i)) {
        newData[bookIds[i]] = [];
        for (const j in data) {
          if (bookIds[i] === data[j].materialId) {
            newData[bookIds[i]].push(data[j]);
          }
        }
      }
    }
    this.setState({
      data: { ...newData },
      checkedKeys: {
        checked: [...keys],
        halfChecked: [],
      },
    });
  };
  // 选择 教材
  selectTextBook = (item: any) => {
    this.setState({
      bookId: item.guid,
      bookName: item.name,
      subjectCode: item.subjectCode,
    });
    getChaptersTree({ textBookId: item.id, textBookGuid: item.guid });
  };
  // 点击复选框
  clickCheckbox = (checkedKeys: any, e: any) => {
    if (checkedKeys.checked.length > 10) {
      message.warning('教材章节最多可选10个！');
      return;
    }
    const { bookId, bookName, subjectCode, data } = this.state;
    // 根据 选中的当前 教材 拿到该教材下的数据
    // let bookIdData = this.dataFilter();

    const newData = e.checkedNodes;
    const len = newData.length;
    const arr = [];
    for (let i = 0; i < len; i++) {
      const item = {
        chapterId: newData[i].props.dataRef.guid,
        chapterName: newData[i].props.dataRef.name,
        materialId: bookId,
        materialName: bookName,
        subjectCode,
      };
      arr.push(item);
    }
    const obj: any = {};
    obj[bookId] = arr;
    this.setState({
      data: { ...data, ...obj },
      checkedKeys,
    });
  };
  // 确定
  submit = () => {
    const { data } = this.state;
    let list: any = [];
    for (const i in data) {
      if (Object.prototype.hasOwnProperty.call(data, i)) {
        list = [...list, ...data[i]];
      }
    }
    this.props.cbackData(list);
  };
  //  TreeNodes 内容 章节
  renderTreeNodes = (data: any) => {
    const { bookId } = this.state;
    if (!bookId) {
      return;
    }
    return data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode title={item.name} key={item.guid} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.name} key={item.guid} dataRef={item} />;
    });
  };
  cancel = () => {
    if (this.props.cancel) {
      this.props.cancel(false);
    }
  };
  render() {
    const { bookId, checkedKeys } = this.state;
    const { visible, textBooks, chaptersList } = this.props;
    return (
      <Modal
        title="选择教材章节"
        width={800}
        visible={visible}
        onCancel={this.cancel}
        onOk={this.submit}
      >
        <Row className="SelectTeaching">
          <Col span={10} className="left">
            <span className="title">教材信息</span>
            <div className="div">
              {textBooks.map((item: any) => (
                <p
                  className={bookId === item.id ? 'active' : ''}
                  key={item.id}
                  onClick={() => this.selectTextBook(item)}
                >
                  {item.name}
                </p>
              ))}
            </div>
          </Col>
          <Col span={13} offset={1} className="right">
            <span className="title">章节信息</span>
            <div className="div">
              <Tree checkable checkStrictly checkedKeys={checkedKeys} onCheck={this.clickCheckbox}>
                {this.renderTreeNodes(chaptersList)}
              </Tree>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}
const mapStateToProps = (state: any) => ({
  textBooks: state.Base.textBooks,
  chaptersList: state.Base.chaptersTree,
});
export default connect(mapStateToProps)(SelectTeaching);
