import { AnyAction } from 'redux';
import * as types from '../types/vod.type';

const initialState = (() => ({
  // 课时详情
  lessonInfo: {
    centerResources: [],
    materialChapters: [],
  },
  // 预览文件是否存在
  file_no_exist: false,
  // 还课列表
  lesson_list: [],
  // 预览课时详情
  preview_lesson: {
    url: '',
    marks: [],
    fileId: '',
    playId: null,
  },
  // 课时是否存在
  lesson_exist: false,
  // 还课视频详情
  rlesson: {
    url: '',
    orgName: '',
    createTime: '',
  },
  // 评论列表
  commentResult: {
    page: {
      size: 10,
      number: 0,
      totalElements: 0,
    },
    list: [],
  },
  // 还课评分详情
  scoreDetail: {
    count: 0,
    score: null,
  },
}))();
const Vod = (state = { ...initialState }, { type, payload }: AnyAction) => {
  switch (type) {
    case types.GET_LESSON:
      return {
        ...state,
        lessonInfo: payload,
      };
    case types.GET_PREVIEW_LESSON:
      return {
        ...state,
        preview_lesson: payload,
      };
    case types.FILE_NO_EXIST:
      return {
        ...state,
        file_no_exist: payload,
      };
    case types.LESSON_EXIST:
      return {
        ...state,
        lesson_exist: payload,
      };
    case types.GET_UPLOAD_LESSON:
      return {
        ...state,
        lesson_list: payload,
      };
    case types.GET_REWORD_LESSON:
      return {
        ...state,
        rlesson: payload,
      };
    case types.COMMENT_LIST:
      return {
        ...state,
        commentResult: {
          ...payload,
        },
      };
    case types.GET_SCORE:
      return {
        ...state,
        scoreDetail: {
          ...payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
export default Vod;
