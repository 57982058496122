import * as types from '../types/userCenter.type';

interface Action {
  type: string;
  payload: {};
}
const initialState: any = {
  courseTable: null, // 课程表列表
  monthSchedule: null, // 课程表月历
};

const Article = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.COURSE_TABLE_LIST_REQUEST: // 课程表列表
      return { ...state, courseTable: payload };
    case types.MONTH_SCHEDULE_LIST_REQUEST: // 课程表月历
      return { ...state, monthSchedule: payload };
    default:
      return state;
  }
};
export default Article;
