/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useEffect, useState } from 'react';
import { Button, Input, Table, Icon } from 'antd';
import { connect } from 'react-redux';
import { getOrganizations } from '../../redux/action/account.action';
import { mergeProps, NormalObj, stringifySearchParams, getSearchParams } from '../../util';

import './index.less';

interface P {
  history?: any;
  organizations: any[];
}
interface TableData {
  no: number | string;
  key: number | string;
  leagueId: string | number;
  leagueName: string;
  organizationId: string | number;
  organizationName: string;
}

interface TableColumns {
  title: string;
  dataIndex: string;
  key: string;
  render?: (...args: any) => {};
}

function AccountManage(props: P) {
  const { organizations } = props;
  const dataSource = organizations.map(
    (o: any, index: number) =>
      ({
        no: index + 1,
        key: o.organizationId,
        ...o,
      } as TableData),
  );
  const columns: TableColumns[] = [
    {
      title: '序号',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '联盟名称',
      dataIndex: 'leagueName',
      key: 'leagueName',
    },
    {
      title: '学校名称',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render(value, record) {
        const { history } = props;
        const { parentId = '' } = getSearchParams(history.location.search);
        const search = {
          parentId,
          leagueId: record.leagueId,
          orgId: record.organizationId,
          orgName: record.organizationName,
        };
        const navToDetail = () => {
          history.push(`/accountManageDetail${stringifySearchParams(search)}`);
        };
        return (
          <span className="href" onClick={navToDetail}>
            查看详情
          </span>
        );
      },
    },
  ];
  const [organizationName, setOrganizationName] = useState<string>('');

  function onInputChange(e: any) {
    setOrganizationName(e.target.value.trim());
  }

  function handleSearch() {
    getOrganizations({ dashboardCode: 'user-create-dashboard', organizationName });
  }

  function handleCancel() {
    setOrganizationName('');
    getOrganizations({ dashboardCode: 'user-create-dashboard', organizationName: '' });
  }

  useEffect(() => {
    getOrganizations({ dashboardCode: 'user-create-dashboard', organizationName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="account-manage">
      <div className="account-manage-header">
        <div className="left-section">
          <div>学校数量：{dataSource.length}</div>
        </div>
        <div className="right-section">
          <Input
            value={organizationName}
            maxLength={50}
            onChange={onInputChange}
            className="search-input"
            placeholder="学校名称"
          />
          <Button className="search-button" type="primary" onClick={() => handleSearch()}>
            <Icon type="search" />
            搜索
          </Button>
          <Button className="search-button" type="ghost" onClick={() => handleCancel()}>
            清空
          </Button>
        </div>
      </div>
      <div className="account-manage-content">
        <Table
          bordered
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName={() => 'account-manage-tablerow'}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: NormalObj) => {
  return { ...state.Account };
};

export default connect(mapStateToProps, mergeProps)(AccountManage);
