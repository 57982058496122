import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import { Button, Select, Tag } from 'antd';
import _ from 'lodash';
import AxiosWrap from '../../lib/request';
import { Option, TagList } from './interface';
import './index.less';

interface P {
  groupCode: string;
  onChange?: (value: TagList[]) => void;
  value?: TagList[];
}

const TagSelect = React.forwardRef<P, any>((props) => {
  const { groupCode, onChange, value = [] } = props;
  const acheScrollTop = useRef<number>(0);
  const loading = useRef<boolean>(false);
  const hasData = useRef<boolean>(false);
  const page = useRef<number>(0);
  const onSelectSearchDebounce = useRef<(v: string) => any>(() => {});
  const acheSearchValue = useRef<string>('');
  const selectRef = useRef<any>(null);
  const openStatus = useRef<boolean>(false);
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [tagSelectList, setTagSelectList] = useState<any[]>([]);

  // 获取标签列表
  const getTagLists = useCallback(
    async (
      page: number,
      name: string,
      isClear: boolean,
      resetScrollPos?: Function,
    ): Promise<void> => {
      loading.current = true;
      const { data } = await AxiosWrap({
        url: '/labels',
        params: {
          page,
          size: 10,
          name,
          code: groupCode,
        },
      });
      if (data) {
        const {
          page: { totalElements },
          _embedded,
        } = data;
        const labels = _embedded
          ? _embedded.labels.map((label: any) => {
              label.value = label.id;
              return label;
            })
          : [];
        hasData.current = labels.length < totalElements;
        if (isClear) setTagSelectList(labels);
        else {
          setTagSelectList((prevState: any[]): any[] => {
            const mergeLabels: any[] = [...prevState, ...labels];
            hasData.current = mergeLabels.length < totalElements;
            return mergeLabels;
          });
          resetScrollPos && resetScrollPos();
        }
      } else {
        hasData.current = false;
        setTagSelectList([]);
      }
      loading.current = false;
    },
    [groupCode],
  );

  const loadmore = useCallback(
    (cb: Function): void => {
      page.current += 1;
      getTagLists(page.current, acheSearchValue.current, false, cb);
    },
    [getTagLists],
  );

  const onOpenSelect = useCallback((): void => {
    setShowSelect(true);
  }, []);

  // 删除标签
  const onDeleteTag = useCallback(
    (e: any, tagKey: number): void => {
      e.persist();
      e.preventDefault();
      onChange && onChange(_.remove(value, (list: TagList) => list.key !== tagKey));
    },
    [onChange, value],
  );

  const onSelectChange = useCallback(
    (v: string, option: any): void => {
      const {
        props: { title },
      } = option;
      const mergeValue = [...value, { id: v, name: title, key: new Date().getTime() }];
      onChange && onChange(_.unionBy(mergeValue, 'id'));
    },
    [onChange, value],
  );

  const onSelectSearch = useCallback(
    (v: string): void => {
      if (openStatus.current) {
        acheSearchValue.current = v.trim();
        page.current = 0;
        getTagLists(0, v.trim(), true);
      }
    },
    [getTagLists],
  );

  // 筛选框展开
  const onDropdownVisibleChange = useCallback(
    (open: boolean): void => {
      openStatus.current = open;
      if (open) {
        page.current = 0;
        acheSearchValue.current = '';
        getTagLists(0, '', true);
      } else window.requestAnimationFrame(() => setShowSelect(false));
    },
    [getTagLists],
  );

  // 监听滚动
  const onPopupScroll = useCallback(
    (e: any) => {
      const { target } = e;
      const { scrollTop, scrollHeight, offsetHeight } = e.target;
      if (scrollHeight - scrollTop - offsetHeight < 1 && !loading.current && hasData.current) {
        acheScrollTop.current = scrollTop;
        loadmore(() => (target.scrollTop = scrollHeight - 150));
      }
    },
    [loadmore],
  );

  useEffect(() => {
    onSelectSearchDebounce.current = _.debounce(onSelectSearch, 500);
  }, [onSelectSearch]);

  return (
    <div className="select-tag" ref={selectRef}>
      {value.map((tagList: TagList) => (
        <Tag closable onClose={(e: any) => onDeleteTag(e, tagList.key)} key={tagList.key}>
          <pre>{tagList.name}</pre>
        </Tag>
      ))}
      {!showSelect && (
        <Button type="dashed" icon="plus" onClick={onOpenSelect}>
          添加标签
        </Button>
      )}
      {showSelect && (
        <Select
          defaultActiveFirstOption={false}
          onChange={onSelectChange}
          style={{ width: '110px' }}
          onPopupScroll={onPopupScroll}
          onSearch={onSelectSearchDebounce.current}
          onDropdownVisibleChange={onDropdownVisibleChange}
          showSearch
          autoFocus
          showAction={['focus']}
          filterOption={false}
          showArrow={false}
          getPopupContainer={() => selectRef.current}
        >
          {tagSelectList.map((o: Option) => (
            <Select.Option title={o.name} key={o.value} value={o.value}>
              <pre>{o.name}</pre>
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
});

export default memo(TagSelect);
