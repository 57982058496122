import './index.less';

import React from 'react';
import { Button, Progress, message } from 'antd';
import { connect } from 'react-redux';
import {
  NormalObj,
  MyIcon,
  getSearchParams,
  getToken,
  getLeagueId,
  getUserId,
  getType,
  mergeProps,
  isFzxxUnion,
} from '../../util';
import * as action from '../../redux/action/vod.action';
import api from '../../config/api/index';
import axios from 'axios';
import AxiosWrap from '../../lib/request';
import Alert from '../Alert/Alert';
import TrackRequest from '../../lib/trackRequest';
import downloadSuccess from '../../components/downloadSuccess';
import { isProhibit } from '../../util/prohibitShow';
import { permitDownload } from '../../util/permitDownload';

const { CancelToken } = axios;

interface Iprops {
  data: NormalObj;
  goToPage?: () => void;
  lessonId: string;
  goToPreview?: (data: any) => void;
  downloadAll?: boolean;
  onCallback?: () => void;
  downloadEntrance: number;
  watchEntrance?: number; // 预览入口
  functionConfig: NormalObj;
}
interface Istate {
  loading: boolean;
  title: string;
  isError: boolean;
  percent: number;
}

function isSupportType(type: string, isSupport?: Boolean | undefined) {
  const supportType = [
    'ppt',
    'pptx',
    'jpg',
    'png',
    'mp3',
    'wma',
    'wav',
    'mp4',
    'swf',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'pdf',
  ];
  if (!isSupport) {
    return supportType.indexOf(type.toLowerCase()) > -1;
  } else {
    return supportType.indexOf(type.toLowerCase()) === -1;
  }
}

class DownloadItem extends React.PureComponent<Iprops, Istate> {
  cancel: any;
  constructor(props: Iprops) {
    super(props);
    this.state = {
      loading: false,
      title: '',
      percent: 0,
      isError: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { downloadAll } = nextProps;
    const { loading } = this.state;
    if (downloadAll && !loading) this.onDownload({});
  }

  onDownload = (e: any) => {
    this.setState({
      percent: 0,
    });
    e.stopPropagation && e.stopPropagation();
    const { data, lessonId } = this.props;
    if (!isProhibit(data)) return null;
    const searchParam = getSearchParams(window.location.search);
    const title = data.fileName;
    this.setState({ isError: false });
    if (data.category === 6 && data.extension.toLowerCase() === 'pdf') {
      let mainUserInfo = window.localStorage.getItem('mainUserInfo');
      mainUserInfo = mainUserInfo ? JSON.parse(mainUserInfo) : { userId: '' };
      const { userId } = mainUserInfo as any;
      const childUserId = getUserId();
      const currentUserId = childUserId === userId ? 0 : childUserId; // 判断是否使用主账号
      // 试卷加密
      const link = `${
        api.resourceCenterDownload
      }/FileService/DownloadPdf?usertoken=${getToken()}&fileid=${
        data.fileId
      }&leagueId=${getLeagueId()}&relationUserId=${currentUserId}`;
      this.callBack(title, '', data, link, true, 20);
      // 加密试卷下载状态
      const _name_ = encodeURIComponent(data.fileName);
      const url = `${api.api}/resources/${data.resourceGuid}/files/${
        data.fileId
      }/download?courseId=${searchParam.courseId || data.courseId}&lessonId=${
        lessonId || data.lessonId
      }&fileName=${_name_}`;
      if (url) {
        AxiosWrap({
          url,
          method: 'get',
        });
      }
    } else {
      const _name_ = encodeURIComponent(data.fileName);
      const url = `${api.api}/resources/${data.resourceGuid}/files/${
        data.fileId
      }/download?courseId=${searchParam.courseId || data.courseId}&lessonId=${
        lessonId || data.lessonId
      }&fileName=${_name_}`;
      if (url) {
        AxiosWrap({
          url,
          method: 'get',
        })
          .then((res: any) => {
            this.webDownloadFn(res, title, data);
          })
          .catch((err) => {
            if (err && err.data) {
              message.error(err.data.message);
            }
          });
      }
    }
  };

  // 浏览器下载
  webDownloadFn(res: any, title: any, data: any) {
    let url = res.data.cdnUrl || res.data.url;
    if (url) {
      url = `https${url.substring(url.indexOf(':'))}`;
      this.callBack(title, '', data, url);
    } else {
      Alert.open('该文件暂时无法下载，请联系客服');
    }
  }

  callBack(
    title: any,
    trackData: any,
    data: any,
    url: any,
    noCache?: boolean,
    basePercent?: number,
  ) {
    this.setState({ loading: true, title });
    if (basePercent) {
      let n = 0;
      const timer = window.setInterval(() => {
        if (n >= 3) {
          window.clearInterval(timer);
        }
        n++;
        this.setState({ percent: Math.floor(basePercent / 4) * n });
      }, 20);
    }
    const { data: lessonInfo, downloadEntrance } = this.props;
    let cache = {};
    !noCache && (cache = { 'Cache-Control': 'no-cache' });
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: cache,
      onDownloadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (basePercent) {
          // 加密过程模拟进度条
          const _basePercent_ = Math.floor(basePercent / 4) * 4;
          percent >= _basePercent_ && this.setState({ percent });
        } else {
          this.setState({ percent });
        }
        if (percent === 100 || percent === Infinity) {
          setTimeout(() => {
            this.setState({ loading: false, percent: 0 });
            this.props.onCallback && this.props.onCallback();
          }, 2000);
        }
      },
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', title);
        document.body.appendChild(link);
        link.click();
        downloadSuccess();
        // 下载资料 埋点
        const searchParam = getSearchParams(window.location.search);
        const { lessonId, data } = this.props;
        TrackRequest({
          name: 'download_material',
          data: {
            course_id: searchParam.courseId || data.courseId,
            period_id: lessonId || data.lessonId,
            material_id: lessonInfo.resourceGuid,
            material_type: lessonInfo.category,
            download_entrance: downloadEntrance,
          },
        });
      })
      .catch((err) => {
        this.props.onCallback && this.props.onCallback();
        if (axios.isCancel(err)) {
          this.setState({ isError: false, loading: false });
        } else {
          this.setState({ isError: true, loading: false });
        }
      });
  }

  stopDownload = (e: any) => {
    const { percent } = this.state;
    e.stopPropagation();
    if (percent < 100) {
      this.cancel();
      this.setState({ percent: 0 });
    }
  };

  previewClick = (e: any) => {
    e.stopPropagation && e.stopPropagation();
    const { data, watchEntrance } = this.props;
    const { fileId: id, extension, category, fileName, courseId, lessonId, resourceGuid } = data;
    if (isSupportType(extension, true) || this.isPaper(category, extension)) {
      message.error('暂不支持预览！', 1);
      return;
    }

    // 预览埋点
    if (typeof watchEntrance === 'number') {
      const trackData = {
        course_id: courseId,
        period_id: lessonId,
        material_id: resourceGuid,
        material_type: category,
        watch_entrance: watchEntrance,
      };
      TrackRequest({ name: 'watch_material', data: trackData });
    }

    this.goToPreView(id, encodeURIComponent(fileName));
  };
  // 判断是否是试卷
  isPaper(category: number, extension: string): boolean {
    if (category === 6 && extension.toLowerCase() === 'pdf') {
      return true;
    }
    return false;
  }

  goToPreView = async (id: string, fileName: string) => {
    const data = await action.filePreview(id, 1, fileName);
    if (data && data.fileId) {
      if (this.props.goToPreview) {
        this.props.goToPreview && this.props.goToPreview({ ...data, fileName });
      } else {
        this.props.goToPage && this.props.goToPage();
      }
    }
  };

  render() {
    const {
      data,
      functionConfig: { downloadFile = false },
    } = this.props;
    const { loading, isError, percent } = this.state;
    return (
      <div
        className={`download-item  ${data.expiryState ? 'expire-item' : ''}`}
        onClick={this.previewClick}
      >
        <MyIcon className="iconfont expire-icon" type="iconziyuan" />
        <div className="item-left">
          <div className="item-info">
            <MyIcon className="iconfont" type={getType(data).type} />
            <div className="attachment-item-inner-name" title={data.fileName}>
              {/* {data.previewState && <span className="state-icon pre-icon">已查看</span>} */}
              {!isFzxxUnion() && data.downloadState && (
                <span className="state-icon down-icon">已下载</span>
              )}
              {data.fileName}
            </div>
          </div>

          {loading && !isError && (
            <div className="download-area">
              <Progress
                percent={this.state.percent}
                status={percent === 100 ? 'success' : !isError ? 'active' : 'exception'}
              />
              <MyIcon
                className="stop-btn"
                type="iconic_del"
                title="取消下载"
                onClick={this.stopDownload}
              />
            </div>
          )}
          {isError && (
            <div
              style={{
                color: '#F5222D',
                padding: '12px 0 23px 60px',
                position: 'relative',
                lineHeight: 0,
              }}
            >
              下载失败!
              <span
                style={{
                  position: 'absolute',
                  color: 'rgba(0, 0, 0, 0.65)',
                  right: '0',
                  fontSize: '8px',
                  top: 8,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ loading: false, isError: false });
                }}
              >
                <MyIcon type="iconic_del" title="关闭" />
              </span>
            </div>
          )}
        </div>
        <div className="item-right">
          {!loading && downloadFile && isProhibit(data) && !permitDownload() && (
            <Button type="primary" onClick={this.onDownload}>
              下载
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  functionConfig: { ...state.DynamicControl.functionConfig },
});

export default connect(mapStateToProps, mergeProps)(DownloadItem);
