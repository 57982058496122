export default {
  api: 'https://openschool-api.devp.eastedu.ltd/teaching_api',
  file: 'https://gateway.devp.eastedu.ltd/file-service',
  trackApi:
    'https://eastedu-develop.cn-shenzhen.log.aliyuncs.com/logstores/open_school/track?APIVersion=0.6.0',
  userCenter: 'https://passport.eastedu.com/wechat/qrcode',
  // resourceCenter: 'http://192.168.101.59:8006', // 资源补发
  resourceCenterDownload: 'https://orgmember.devp.eastedu.ltd', // 文件加密
  examinationAndAnalysis: 'http://192.168.101.59:5002', // 同考同析
  ebi: 'https://ebi.develop.eastedu.ltd',
  ip: 'https://tool.eastedu.com/ip', // ip与服务器时间
  pingguoApi: 'https://gateway.devp.eastedu.ltd/infrastructure-cache-service',
  userCenterApi: 'https://gateway.devp.eastedu.ltd/user-center-api',
  markupService: 'https://gateway.devp.eastedu.ltd/infrastructure-markup-service',
  gateway: 'https://gateway.devp.eastedu.ltd',
  passport: 'https://passports.devp.eastedu.ltd',
  // 认证中心sdk_token
  passport_sdk_token:
    '11eSZNnCghs2rTXAxdGwyaXZYYmZ4ampWei8zSGpTMVd6L0czWDZyenhzcmZqZGdWK2d1dE4xZzVINkkxYll1NkZGQ0h0WHBBYzFKQm9Nay9CUDZpVTlTU1gwR0w5cG9yeTZpSWdxZEJxOWtsRVJRRDAzcExqR2pEaVBvNGdEZm12QXh5dWtlQ040MjdCcEt1OGRMbEJka2FVS3hZMWV2cDBBL1VNQlVSM0J0QVV4aTF1Wm9kUlZFZ1Q4YmlYYVB5RjBxaTYyQ055Y09acDlGVXlOVWZMdlNZYWV2NVFIV1BUaXptQldDVFVwZjM0c25ZOHhMR0h1d0FMN2RMM2s2cDFIdkpUUFlJc0xxbzhUM2xTaDREMks2MitIRTRrUTl3cFQ2ekJpUi8wQkxGckNBRUpUcUQzeHdLaWVPVitoNTNGQnNmTjhZRUZvUFBTK1VQZVBWUzR2N3FSa01yVWFxQ2phbGliYmpHNG5KeWpnWHFONUJMTHg1eVkxZDg5SU5teWdPN29Kcjc4WFdrSStEWkU2WjQ0WXh1OEN1T3ZiUHhCWHlTdnRXSHRyNE8yN0VZbVZ3MHJxS3ozUGhtYWJDVXE4cisrN0NZSldwcXpoc0Z5WmpJb0tJbGN4ZXpjOGo0ODduKzkyNlZhZi9udVVlU1YrSzEzZmdzWXdTTjJFS3dvRnQ2NURRY0M=',

  userAgreement: 'https://u-learning.devp.eastedu.ltd/privacy-policy/useragreement.html',
  privacyPolicy: 'https://u-learning.devp.eastedu.ltd/privacy-policy/openschoolprivacypolicy.html',
};
