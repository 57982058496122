/*
 * @Author: gouyang
 * @Description: 柱状图
 * @Date: 2019-09-04 13:54:20
 */
import React from 'react';
import echarts from 'echarts';

interface P {
  id: string;
  data: { [s: string]: any };
}
function histogram({ id, data }: P) {
  if (document.getElementById(id) && data.title.length > 0) {
    const myCharts = echarts.init(document.getElementById(id) as any);
    const option: { [s: string]: any } = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      xAxis: {
        type: 'category',
        data: data.title,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: data.data,
          type: 'bar',
          barWidth: 50,
          itemStyle: {
            normal: {
              color: '#3597DC',
            },
          },
        },
      ],
    };
    myCharts.setOption(option);
  }

  return (
    <div className="charts">
      <div id={id} className="main" />
    </div>
  );
}

export default histogram;
