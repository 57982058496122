/* eslint-disable no-nested-ternary */
import './index.less';
import React from 'react';
import moment from 'moment';
import { Button, Icon, Tooltip } from 'antd';
import { stringifySearchParams, hideSubjectTag } from '../../../util';
import enterLiveRoom from '../../../util/enterLiveRoom';
import { getCheckExist } from '../../../redux/action/userCenter.action';
import PopuprHigherFun, { removePopupr } from '../../../util/PopuprHigherFun';
import TrackRequest from '../../../lib/trackRequest';
import { ArtScience } from '../../../util/enum';
import { allowClicking } from '../../../util/prohibitShow';

// 课程表 --课时详情
interface P {
  [key: string]: any;
}

enum HeadType {
  'one' = 1,
  'two',
  'three',
}

class Lessondetail extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setPosition();
  }
  // 设置组件显示的位置
  setPosition = () => {
    const { event } = this.props;
    const $dom = event.currentTarget;
    const top = $dom.offsetTop;
    let left = $dom.offsetLeft;

    // 控制 如果 底部 高度，或者 右边 宽度不够 则相对位移
    const $root: any = document.getElementById('root');
    const $lessondetail: any = document.querySelector('.lessondetail');
    // let badH = top + $lessondetail.offsetHeight - $root.offsetHeight;
    const badW = left + $lessondetail.offsetWidth - $root.offsetWidth;
    // if (badH > 0) {
    //   top = top - badH;
    // }
    if (badW > 0) {
      left -= badW;
    }
    this.setState({
      top,
      left,
    });
  };
  // 查看详情
  checkDetail = async () => {
    const { data } = this.props;
    const flg = await getCheckExist(data.lessonId);
    if (flg) {
      const type = data.source === 1 ? 0 : data.source;
      const obj = {
        lessonId: data.lessonId,
        lessonType: data.lessonType,
        type,
        courseId: data.courseId,
        courseName: data.courseName,
      };
      if (data.source === 2) {
        // 同步
        // 观看课时埋点
        TrackRequest({
          name: 'watch_period',
          data: {
            course_id: data.courseId,
            period_id: data.lessonId,
            period_type: data.lessonType,
            watch_period_entrance: 1,
          },
        });
      } else if (data.source === 1) {
        // 预览
        // 预览课时埋点
        TrackRequest({
          name: 'preview_period',
          data: { course_id: data.courseId, period_id: data.lessonId, pre_entrance: 1 },
        });
      }
      if (Number(data.lessonType) === 1) {
        // 直播
        enterLiveRoom(data.lessonId, type);
      } else if (Number(data.lessonType) === 2) {
        // 点播
        window.open(`/vod${stringifySearchParams(obj)}`);
      } else if (Number(data.lessonType) === 3) {
        // 资料
        window.open(`/vod${stringifySearchParams(obj)}`);
      }
    }
  };
  // 详情点击
  btnClick = (e: any) => {
    // 如果点击了按钮则 移除组件
    if (e.target.tagName === 'BUTTON') {
      removePopupr('courseTable');
    }
  };
  render() {
    const { top, left } = this.state;
    const {
      mouseenter,
      mouseleave,
      data: {
        lessonType,
        lessonName,
        orgName,
        grades,
        teachers,
        subjects,
        materialChapters,
        labels,
        artScience,
        releaseTime,
        expireTime,
        liveState,
        centerResources,
        currentRootType,
        live,
      },
    } = this.props;
    const type = HeadType[lessonType];
    const isClicking = allowClicking(live, lessonType, liveState);

    return (
      <div
        className="lessondetail"
        style={{ top, left }}
        onClick={this.btnClick}
        onMouseEnter={mouseenter}
        onMouseLeave={mouseleave}
      >
        <div className={`head ${type}`}>
          <h4>课时信息</h4>
        </div>
        <div className="content">
          <div className="item ">
            <span className="name">课时名称</span>
            <div className="value">{lessonName}</div>
          </div>
          <div className="item ">
            <span className="name">组织</span>
            <div className="value">{orgName}</div>
          </div>
          <div className="item">
            <span className="name">年级</span>
            <div className="value list">
              {grades &&
                grades.map((item: any, index: number) => <span key={index}>{item.gradeName}</span>)}
            </div>
          </div>
          <div className="item">
            <span className="name">授课教师</span>
            <div className="value list">
              {teachers &&
                teachers.map((item: any, index: number) => (
                  <span key={index}>{item.teacherName}</span>
                ))}
            </div>
          </div>
          <div className="item">
            <span className="name">学科</span>
            <div className="value list">
              {subjects &&
                subjects.map((item: any, index: number) => (
                  <span key={index}>{item.subjectName}</span>
                ))}
            </div>
          </div>
          {materialChapters?.length > 0 && (
            <div className="item">
              <span className="name">教材章节</span>
              <div className="value list">
                {materialChapters.map((item: any, index: number) => (
                  <span key={index}>{item.chapterName}</span>
                ))}
              </div>
            </div>
          )}
          {hideSubjectTag(grades || [], labels) && hideSubjectTag(grades || [], labels).length > 0 && (
            <div className="item">
              <span className="name">标签</span>
              <div className="value list">
                {hideSubjectTag(grades || [], labels).map((label: any) => (
                  <span key={label.labelId}>{label.labelName}</span>
                ))}
              </div>
            </div>
          )}
          {typeof artScience === 'number' && (
            <div className="item">
              <span className="name">文理科</span>
              <div className="value">{ArtScience[artScience]}</div>
            </div>
          )}
          <div className="item ">
            <span className="name">发布时间</span>
            <div className="value">{moment(releaseTime).format('YYYY/MM/DD HH:mm')}</div>
          </div>
          {typeof expireTime === 'number' && (
            <Tooltip
              overlayClassName="expire-time-user-class"
              title="有效截止时间内查看将会纳入数据统计"
            >
              <div className="item ">
                <span className="name expireTime-name">
                  有效截止时间
                  <Icon type="info-circle" />
                </span>
                <div className="value">{moment(expireTime).format('YYYY/MM/DD HH:mm')}</div>
              </div>
            </Tooltip>
          )}
          <div className="item ">
            {type === 'one' ? (
              <>
                <span className="name">直播状态</span>
                <div className="value">
                  {liveState === 0 ? '未开始' : ''}
                  {liveState === 1 ? '直播中' : ''}
                  {liveState === 2 ? '已结束' : ''}
                </div>
              </>
            ) : (type === 'two' && centerResources.length > 0) || type === 'three' ? (
              <>
                <span className="name">附件类型</span>
                <div className="value">{getCategory(centerResources)}</div>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="item btn">
            {lessonType === 2 && currentRootType === 'sync' ? (
              <>
                <Button onClick={this.checkDetail}>我要读备</Button>
                <Button onClick={this.checkDetail}>我要还课</Button>
              </>
            ) : (
              ''
            )}
            <Button disabled={!isClicking} type="primary" onClick={this.checkDetail}>
              查看详情
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
function getCategory(data: any) {
  if (!data || data.length === 0) {
    return '';
  }
  const list: any = new Set();
  for (const i in data) {
    if (Object.prototype.hasOwnProperty.call(data, i)) {
      // if (data[i].category === 1) {
      //     list.add('课堂实录');
      // }
      if (data[i].category === 2) {
        list.add('课件');
      }
      if (data[i].category === 3) {
        list.add('素材');
      }
      if (data[i].category === 4) {
        list.add('教案');
      }
      if (data[i].category === 5) {
        list.add('普通作业');
      }
      if (data[i].category === 6) {
        list.add('普通试卷');
      }
    }
  }
  return [...list].join(';');
}
export default PopuprHigherFun(Lessondetail);
