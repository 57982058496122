import React from 'react';
import moment from 'moment';
import { MyIcon } from '../../../util/index';
import Lessondetail from '../lessondetail/lessondetail';

import './index.less';
import { connect } from 'react-redux';

interface P {
  // type: string; //类型
  data: any; // 数据
  source: number;
  currentRootType: any;
}
class CourseTable extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  // 显示
  show = (e: any) => {
    Lessondetail.remove('courseTable');
    const { data: item, source, currentRootType } = this.props;
    item.source = parseInt(`${source}`, 10);
    item.currentRootType = currentRootType;
    const data = {
      mouseleave: this.tableMouseLeave,
      data: item,
      event: e,
    };
    Lessondetail.open(data, 'courseTable');
  };
  // 按钮鼠标移入
  mouseenter = (e: any) => {
    e.stopPropagation();
    this.show(e);
  };
  // 课程表详情鼠标移出
  tableMouseLeave = (e: any) => {
    e.stopPropagation();
    Lessondetail.remove('courseTable');
  };
  render() {
    const { data } = this.props;
    const { lessonType } = data;
    const type =
      lessonType === 1 ? 'one' : lessonType === 2 ? 'two' : lessonType === 3 ? 'three' : '';
    const icon = getIcon(lessonType);

    return (
      <div onMouseEnter={this.mouseenter} className={`lesson-list ${type}`}>
        <div className="lesson-top">
          <p>{data.lessonName}</p>
        </div>
        <div className="lesson-bottom">
          <div>
            <MyIcon className="iconfont" type={icon} />
            {type === 'one' && data.liveState === 1 ? <span className="status">直播中</span> : ''}
            {type === 'one' && data.liveState === 2 ? (
              <span className="status end">已结束</span>
            ) : (
              ''
            )}
            {type === 'one' && data.liveState === 0 ? (
              <span className="status end">未开始</span>
            ) : (
              ''
            )}
          </div>
          <span>{`${moment(data.startTime).format('HH:mm')}-${moment(data.endTime).format(
            'HH:mm',
          )}`}</span>
        </div>
      </div>
    );
  }
}
function getIcon(type: number) {
  switch (type) {
    case 1:
      return 'iconzhibo1';
    case 2:
      return 'icondianbo1';
    case 3:
      return 'iconziliao1';
    default:
  }
}

const mapStateToProps = (state: any) => ({
  currentRootType: state.GloabalCreateSync.currentRootType,
});

export default connect(mapStateToProps)(CourseTable);
