import './index.less';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from './../../assets/ic_avatar.png';
import AxiosWrap from '../../lib/request';

interface P {
  logo?: string | undefined;
  subUsers: [];
}
class UserInfo extends Component<P, any> {
  login: any;
  child: any;
  constructor(props: P) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    AxiosWrap({ url: `/auth/user`, method: 'GET' }).then((res) => {
      this.setState({ data: res.data });
    });
  }

  render() {
    const { data } = this.state;
    const schoolPeriods = data.schoolPeriods || [];
    const subjects = data.subjects || [];
    return (
      <div className="userInfo-cont">
        <div className="userInfo-block">
          <h3>基本信息</h3>
          <div className="userInfo-row">
            <div className="userInfo-label" style={{ lineHeight: '48px' }}>
              头像：
            </div>
            <div className="userInfo-value">
              <img src={Avatar} alt="" />
            </div>
          </div>
          <div className="userInfo-row">
            <div className="userInfo-label">姓名：</div>
            <div className="userInfo-value">{data.name}</div>
          </div>
          <div className="userInfo-row">
            <div className="userInfo-label">账号信息：</div>
            <div className="userInfo-value">{data.phone}</div>
          </div>
        </div>
        <div className="userInfo-block">
          <h3>属性信息</h3>
          <div className="userInfo-row">
            <div className="userInfo-label">学段：</div>
            <div className="userInfo-value">{data.studySectionName}</div>
          </div>
          <div className="userInfo-row">
            <div className="userInfo-label">学科：</div>
            <div className="userInfo-value">{subjects.map((i: any) => i.name).join(' / ')}</div>
          </div>
          <div className="userInfo-row">
            <div className="userInfo-label">学届：</div>
            <div className="userInfo-value">{schoolPeriods.join(' / ')}</div>
          </div>
          {/* <div className="userInfo-row" >
                    <div className="userInfo-label">拥有角色：</div>
                    <div className="userInfo-value">{roles.length > 0 ?roles.map((i:any)=>i.name).join(' / '): '暂无'}</div>
                </div> */}
          <div className="userInfo-row">
            <div className="userInfo-label">所属组织：</div>
            <div className="userInfo-value">{data.orgName}</div>
          </div>
          <div className="userInfo-row">
            <div className="userInfo-label">所属联盟：</div>
            <div className="userInfo-value">{data.leagueName}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapPropsToState() {
  return {};
}
export default connect(mapPropsToState)(UserInfo);
