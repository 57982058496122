/*
 * @Author: gouyang
 * @Description: 饼图
 * @Date: 2019-09-04 10:16:06
 */
import React from 'react';
import echarts from 'echarts';
import StatisticsEmpty from './empty';

import './style.less';

interface P {
  id: string;
  data: { [s: string]: any };
  countName: string;
}
function Circle({ id, data, countName }: P) {
  if (document.getElementById(id) && data.isData) {
    const myCharts = echarts.init(document.getElementById(id) as any);
    const option: { [key: string]: any } = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        top: data.top,
        orient: 'vertical',
        x: '70%',
        icon: 'circle',
        align: 'left',
        itemGap: 20,
        selectedMode: false, // 禁止图例点击
        formatter(name: string) {
          const i = data.info.findIndex((a: any) => a.name === name);
          const target =
            data.info[i].value >= 10000
              ? `${Math.round(data.info[i].value / 100) / 100}万`
              : `${Math.round(data.info[i].value * 100) / 100}`;
          const arr = [`{a|${name}}`, `{b| | ${target}}`];
          return arr.join('');
        },
        textStyle: {
          rich: {
            a: {
              color: '#262626',
              fontSize: '14',
            },
            b: {
              color: '#77849E',
              fontSize: '14',
            },
          },
        },
        data: data.title,
      },
      series: [
        {
          name: '新增个数',
          type: 'pie',
          center: ['35%', '50%'],
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: 'center',
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: data.info,
        },
      ],
    };
    myCharts.setOption(option);
  }
  return (
    <div className="charts">
      <div id={id} className="main" />
      {data.isData ? (
        <div className="total">
          <span>{countName}</span>
          <span>{data.total}</span>
        </div>
      ) : (
        <div className="charts-empty">
          <StatisticsEmpty />
        </div>
      )}
    </div>
  );
}

export default Circle;
