import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import Vod from '../Vod';
import Live from '../Live';
import Layout from './Layout';
import NotFound from '../NotFound';
import HighSchoolConcept from '../conceptIndex/highSchoolConceptIndex';
import { getToken, getLeagueId } from '../../util';
import SplitScreenVideo from '../Vod/splitScreenVideo';
import Tips from './../../components/TipsInfo';
import Blank from './../Blank';
import LoginOauth from '../LoginOauth';
import mapPageData from '../../util/environmentalMapData';
import getWechatLink from '../../config/wechatLink';
import compatibleTips from '../../components/compatibleTips';
import MiniProgram from '../MiniProgram';
import LessonPreview from '../LessonPreview';
import MessageModal from '../Message/Components/MessageModal';
import './index.less';

type P = RouteComponentProps;
const env = (window as any).__UNION_CENTER_ENV__;
const indexRouter = mapPageData() ? mapPageData().router : '';
window.localStorage.setItem('pathSuffix', indexRouter);
const isNotPageRouter = mapPageData.prototype.linkLists().indexOf(window.location.origin);

class Home extends React.Component<P, any> {
  private noneTipsList: string[];
  private pageRoute: string[]; // 页面路由
  constructor(props: P) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      menus: [],
    };
    this.noneTipsList = [
      '/blank',
      '/cdqz',
      '/split-screen-video',
      '/',
      '/404',
      '/cz',
      '/xx',
      '/yey',
      '/MiniProgram',
      '/global-search',
      '/oauth2/callback',
    ];
    this.pageRoute = ['/blank', '/404', '/vod', '/lesson-preview', '/live', '/split-screen-video'];
  }

  handlingExceptions = () => {
    const { match, history, location } = this.props;
    const layoutProps = { match, history, location };
    const { pathname } = location;

    if (this.pageRoute.indexOf(pathname) === -1 && !getToken()) {
      return <Redirect to="/404" />;
    } else if (pathname === '/' && !getToken()) {
      return <Redirect to="/404" />;
    } else {
      return <Layout {...layoutProps} />;
    }
  };
  // 生产和测试环境
  handlingExceptionsProd = () => {
    const { match, history, location } = this.props;
    const layoutProps = { match, history, location };
    const { pathname } = location;
    if (isNotPageRouter !== -1) {
      // 非门户路由
      return <Redirect to={indexRouter} />;
    } else if (pathname === '/' && !getToken()) {
      return (window.location.href = getWechatLink());
    } else {
      return <Layout {...layoutProps} />;
    }
  };

  showMessage = () => {
    const {
      location: { pathname },
    } = this.props;
    return isNotPageRouter === -1 && getToken() && getLeagueId() && pathname !== '/blank';
  };

  render() {
    const { history, location } = this.props;
    (window as any)._history = history;

    return (
      <div className="Home-Component">
        {env === 'STAGE' ? <p className="stage-flag">灰度环境</p> : null}
        {this.showMessage() && <MessageModal />}
        {compatibleTips()}
        {env === 'DEV' && (
          <Switch>
            <Route exact path="/oauth2/callback" component={LoginOauth} />
            <Route exact path="/blank" component={Blank} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/vod" component={Vod} />
            <Route exact path="/lesson-preview" component={LessonPreview} />
            <Route exact path="/MiniProgram" component={MiniProgram} />
            <Route exact path="/live" component={Live} />
            <Route exact path="/cdqz" component={HighSchoolConcept} />
            <Route exact path="/cz" component={HighSchoolConcept} />
            <Route exact path="/xx" component={HighSchoolConcept} />
            <Route exact path="/yey" component={HighSchoolConcept} />
            <Route exact path="/future" component={HighSchoolConcept} />
            <Route exact path="/split-screen-video" component={SplitScreenVideo} />
            <Route path="/" render={this.handlingExceptions} />
          </Switch>
        )}
        {(env === 'PROD' || env === 'STAGE' || env === 'TEST') && isNotPageRouter !== -1 && (
          <Switch>
            <Route exact path="/oauth2/callback" component={LoginOauth} />
            <Route exact path={indexRouter} component={HighSchoolConcept} />
            <Route path="/" render={this.handlingExceptionsProd} />
          </Switch>
        )}
        {(env === 'PROD' || env === 'STAGE' || env === 'TEST') && isNotPageRouter === -1 && (
          <Switch>
            <Route exact path="/oauth2/callback" component={LoginOauth} />
            <Route exact path="/MiniProgram" component={MiniProgram} />
            <Route exact path="/blank" component={Blank} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/vod" component={Vod} />
            <Route exact path="/lesson-preview" component={LessonPreview} />
            <Route exact path="/live" component={Live} />
            <Route exact path="/split-screen-video" component={SplitScreenVideo} />
            <Route path="/" render={this.handlingExceptionsProd} />
          </Switch>
        )}
        {this.noneTipsList.indexOf(location.pathname) === -1 && getToken() && <Tips />}
      </div>
    );
  }
}

export default withRouter(Home);
