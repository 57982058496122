/*
 * @Author: gouyang
 * @Description:没有权限时跳转
 * @Date: 2019-10-31 10:21:53
 */

function isNotRootLink(): void {
  (window as any).location.href = '/';
}

export default isNotRootLink;
