/* eslint-disable no-void */
import './index.less';
import React from 'react';
import { message, Pagination } from 'antd';
import { connect } from 'react-redux';
import {
  getSearchParams,
  NormalObj,
  getSubjectList,
  stringifySearchParams,
  // judgeSourceType,
  isPingguoUnion,
  getStudySectionId,
  getSubjectId,
} from '../../util';
import { RouteComponentProps } from 'react-router';
import * as action from '../../redux/action/course.list.action';
import * as menuAction from '../../redux/action/menu.action';
import * as baseAction from '../../redux/action/base.action';
import { updateSearchValue } from '../../redux/action/createSearch.action';
import Tablist from '../../components/Tablist';
import EmptyList from '../../components/Empty';
import CoursePanel, { courseData } from '../../components/CoursePanel';
import TrackRequest from '../../lib/trackRequest';
import { highLightFn, highLightFnForSync } from '../GlobalSearch/lib/highLight';
import Empty from '../Empty';
import ColumnList from '../../components/ColumnList';
import CreateSearch from '../../components/CreateSearch';
import {
  SPECIAL_WEI_SHI_PRI_SUBJECTS_MAP,
  SPECIAL_WEI_SHI_PRI_COLUMN_ID,
} from '../../constant/courseList';
import QueryString from 'qs';

interface obj {
  [key: string]: any;
}

interface P extends RouteComponentProps {
  tree: [];
  studySections: [];
  subjects: [];
  grades: [];
  textBooks: [];
  courseList: obj;
  schoolPeriods: [];
  history: any;
  registerGetDataFn: any;
  resourceRootOption: any[];
  rootOption: any[];
  addressBarColumnId: string;
  updateCurrentRootType: any;
  resetLists: () => {};
  menusConfig: { [key: string]: any };
}
const initialParams = {
  name: '',
  keyword: '',
  size: 20,
  current: 1,
  releaseState: '',
  materialId: '',
  chapterId: '',
  subjectId: '',
  schoolPeriod: '',
  gradeId: '',
  schoolSection: '',
};
class CourseList extends React.Component<P, any> {
  params: obj;
  filterData: obj;
  createCourse: boolean;
  createArticle: boolean;
  textbookParams: obj;
  leftNavType: string | null;
  constructor(props: P) {
    super(props);
    this.state = {
      root: [...this.props.resourceRootOption],
      disabled: true,
      chapterId: [],
      minHeight: window.innerHeight - 312,
      materialId: '',
      activeGradeKey: 0,
      activeSchoolSectionKey: 0,
      activeSchoolPeriodKey: 0,
      activeReleaseStateKey: 0,
      activeSubjectKey: 0,
      isRoot: true, // 是否具有该权限
      addressBarColumnId: '',
      filterConfig: {
        showSubject: false,
        showGrade: false,
        showSchoolPeriod: false,
        showMaterial: false,
      },
    };
    this.leftNavType = 'create';
    this.createCourse = false;
    this.createArticle = false;
    this.filterData = { ...initialParams };
    this.textbookParams = {
      gradeCodeString: this.filterData.gradeId,
      subjectCodeString: getSubjectId().join('|'),
      studySectionCodeString: getStudySectionId(),
    };
    this.params = getSearchParams(props.location.search);
    this.filterData.menuId = this.params.id || '';
    this.resize = this.resize.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePingguoChange = this.handlePingguoChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRelease = this.handleRelease.bind(this);
    this.previewCourse = this.previewCourse.bind(this);
    this.toCourseDetail = this.toCourseDetail.bind(this);
    this.courseListHighLight = this.courseListHighLight.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps: P) {
    this.params = getSearchParams(nextProps.location.search);
    this.filterData.menuId = this.params.id || '';
    if (nextProps.addressBarColumnId !== this.props.addressBarColumnId) {
      this.props.resetLists();
      this.initRootData();
    }
  }
  componentDidMount() {
    this.props.resetLists();
    // 注册获取数据方法
    this.props.registerGetDataFn(this.handleMenuClick);
    this.initRootData();
  }
  initRootData = async () => {
    const { rootOption, resourceRootOption } = this.props;
    const result = [...resourceRootOption];
    this.leftNavType = rootOption[0] ? rootOption[0].type : '';
    if (window.localStorage.getItem('ROOTTYPE')) {
      this.leftNavType = window.localStorage.getItem('ROOTTYPE');
    } else {
      this.props.updateCurrentRootType(this.leftNavType);
    }

    if (result.length > 0 && this.params.type === '0') {
      this.setState({ root: result }, () => this.handleMenuClick(this.leftNavType));
    } else {
      this.setState({
        isRoot: false,
      });
    }
    // if (judgeSourceType(this.params.name)) {
    //   this.setState({
    //     isRoot: false,
    //   });
    // }
  };
  // 获取基础数据
  getBasicsData = () => {
    if (this.leftNavType === 'create') {
      this.setState({
        filterConfig: {
          showSubject: true,
          showGrade: true,
          showSchoolPeriod: true,
          showMaterial: true,
        },
      });
      baseAction.getSchoolPeriod(); // 获取学届
      baseAction.getGrades(this.textbookParams.studySectionCodeString);
      baseAction.getTextbooks(this.textbookParams);
    }

    // 我的同步
    if (this.leftNavType === 'sync') {
      const { menusConfig } = this.props;
      if (menusConfig[this.filterData.menuId]) {
        const { showGrade, showSchoolPeriod, showMaterial } = menusConfig[this.filterData.menuId];
        this.setState({
          filterConfig: menusConfig[this.filterData.menuId],
        });
        showSchoolPeriod && baseAction.getFollowSchoolPeriod(); // 获取学届
        showGrade &&
          baseAction.getFollowGrades({
            studySectionCode: this.textbookParams.studySectionCodeString,
          });
        showMaterial && baseAction.getFollowTextbooks(this.textbookParams);
      }
    }
  };
  getPingguoData = async () => {
    const studySections = await baseAction.getStudySections();
    if (studySections.length > 0) {
      // this.setState({
      //   activeSchoolSectionKey: 1
      // });
      await baseAction.getGrades(studySections[0].code);
      await baseAction.getSubjects(studySections[0].code);
      this.filterData.schoolSection = studySections[0].code;
    } else {
      await baseAction.getGrades();
      await baseAction.getSubjects();
    }
  };
  resize() {
    this.setState({ minHeight: window.innerHeight - 302 });
  }
  screenChange() {
    window.addEventListener('resize', this.resize);
  }
  /**
   * 收集筛选条件
   * @param o 筛选条件 { type: { value } } || value
   * @param key 索引 index
   */
  handleChange(o: { [key: string]: any }, key?: number | string) {
    this.filterData.current = 1;
    const obj: any = {};
    if (o.releaseState) {
      this.filterData.releaseState = o.releaseState.value;
      obj.activeReleaseStateKey = key;
      // this.setState({ activeReleaseStateKey: key });
    } else if (o.textbook !== void 0) {
      this.filterData.materialId = o.textbook;
      this.filterData.chapterId = '';
      obj.materialId = o.textbook;
      obj.chapterId = [];
      obj.disabled = !o.textbook;
      if (o.textbook) {
        // 教材选择为全部，或者教材的值为空时，不请求章节接口
        baseAction.getChaptersTree({ textBookGuid: o.textbook });
      }
    } else if (o.tree) {
      this.filterData.chapterId = o.tree[o.tree.length - 1];
      obj.chapterId = o.tree;
    } else if (o.schoolPeriod) {
      this.filterData.schoolPeriod = o.schoolPeriod.code;
      obj.activeSchoolPeriodKey = key;
    } else if (o.grade) {
      this.filterData.gradeId = o.grade.code;
      // this.textbookParams.gradeCodes = o.grade.code;
      obj.activeGradeKey = key;
      // 在选择年级之后 要重置 教材章节的筛选条件
      // obj.chapterId = [];
      // obj.disabled = true;
      // obj.materialId = '';
      // this.filterData.chapterId = '';
      // this.filterData.materialId = '';
      // baseAction.getTextbooks(this.textbookParams)
    } else if (o.subject) {
      const { showMaterial } = this.state.filterConfig;
      this.filterData.subjectId = o.subject.code;
      obj.activeSubjectKey = key;
      // 在选择学科之后 要重置 教材章节的筛选条件
      obj.chapterId = [];
      obj.disabled = true;
      obj.materialId = '';
      this.filterData.chapterId = '';
      this.filterData.materialId = '';
      this.textbookParams.subjectCodeString = o.subject.code
        ? o.subject.code
        : getSubjectId().join('|');
      if (this.leftNavType === 'create') {
        baseAction.getTextbooks(this.textbookParams);
      } else if (showMaterial) {
        baseAction.getFollowTextbooks(this.textbookParams);
      }
    }
    updateSearchValue(this.filterData.name);
    this.setState(
      {
        ...obj,
      },
      this.searchData,
    );
  }
  handlePingguoChange(o: { [key: string]: any }, key?: number | string) {
    this.filterData.current = 1;
    const obj: any = {};
    if (o.releaseState) {
      this.filterData.releaseState = o.releaseState.value;
      obj.activeReleaseStateKey = key;
      // this.setState({ activeReleaseStateKey: key });
    } else if (o.schoolSection) {
      this.filterData.schoolSection = o.schoolSection.code;
      obj.activeSchoolSectionKey = key;
      obj.activeGradeKey = 0;
      obj.activeSubjectKey = 0;
      this.filterData.gradeId = '';
      this.filterData.subjectId = '';
      baseAction.getGrades(o.schoolSection.code);
      baseAction.getSubjects(o.schoolSection.code);
    } else if (o.grade) {
      this.filterData.gradeId = o.grade.code;
      // this.textbookParams.gradeCodes = o.grade.code;
      obj.activeGradeKey = key;
      // 在选择年级之后 要重置 教材章节的筛选条件
      // obj.chapterId = [];
      // obj.disabled = true;
      // obj.materialId = '';
      // this.filterData.chapterId = '';
      // this.filterData.materialId = '';
      // baseAction.getTextbooks(this.textbookParams)
    } else if (o.subject) {
      this.filterData.subjectId = o.subject.code;
      obj.activeSubjectKey = key;
      // 在选择学科之后 要重置 教材章节的筛选条件
      obj.chapterId = [];
      obj.disabled = true;
      obj.materialId = '';
      this.filterData.chapterId = '';
      this.filterData.materialId = '';
    }
    updateSearchValue(this.filterData.name);
    this.setState(
      {
        ...obj,
      },
      this.searchData,
    );
  }
  searchData(page?: number) {
    window.scrollTo(0, 0);
    const { root } = this.state;
    const findItem = root.find((item: NormalObj) => item.active);
    if (page) this.filterData.current = page;
    action.getCourseList(this.filterData, findItem && findItem.number, this.courseListHighLight);
  }

  callback = () => {
    const { root } = this.state;
    const findItem = root.find((item: NormalObj) => item.active);
    action.getCourseList(this.filterData, findItem && findItem.number, this.courseListHighLight);
  };

  /**
   * 课程搜索高亮
   * @param courseList 课程列表
   * @param type 课程列表类型 0：创建列表  2：同步列表
   */
  courseListHighLight(courseList: any[], type: string, analyzesData: string[]) {
    const { name } = this.filterData;
    if (type === '0') {
      return courseList.map((item) => {
        highLightFnForSync(item, name);
        return item;
      });
    } else if (type === '2') {
      return courseList.map((item) => {
        if (analyzesData.length > 0) {
          highLightFn(item, analyzesData, 0);
        } else {
          item.highLightCourseName = item.courseName;
        }
        return item;
      });
    }
  }

  /**
   * 课程管理
   * @param id 课程id
   */
  toCourseDetail(id: number) {
    // 清空分页信息
    window.sessionStorage.removeItem('DEFAULTPAGE');
    window.sessionStorage.removeItem('DEFAULTSIZE');

    this.existCourse(id, 'toCourseDetail');
  }
  /**
   * 取消发布课程
   * @param id 课程id
   */
  handleRelease(id: number) {
    const success = () => {
      message.success('下架成功', 1, this.callback);
      // 课程取消发布埋点
      TrackRequest({ name: 'confirm_unpub_course', data: { course_id: id } });
    };
    action.unReleaseCourse(id, success);
  }
  /**
   * 删除课程
   * @param id 课程id
   */
  handleDelete(id: number) {
    if (this.props.courseList.list.length === 1 && this.filterData.current >= 2) {
      this.filterData.current -= 1;
    }
    const success = () => {
      message.success('操作成功！', 1, this.callback);
      // 删除课程埋点
      TrackRequest({ name: 'confirm_del_course', data: { course_id: id } });
    };
    action.deleteCourse(id, success);
  }
  /**
   * 判断课程存在否
   * @param id
   */
  async existCourse(id: number | '', type: string) {
    const { root } = this.state;
    if (id) {
      const flag = await action.existCourse(id);
      if (flag) {
        if (type === 'preview') {
          const type = root.find((item: NormalObj) => item.active).number;
          this.params.courseId = id;
          this.params.leftMenuType = type;
          window.open(`/courseDetail${stringifySearchParams(this.params)}`);
          if (type === '0') {
            // 我的创建
            // 课程预览埋点
            TrackRequest({ name: 'preview_course', data: { course_id: id } });
          } else if (type === '2') {
            // 我的同步
            // 课程观看埋点
            TrackRequest({ name: 'watch_course', data: { course_id: id, watch_entrance: 0 } });
          }
        } else if (type === 'toCourseDetail') {
          this.params.courseId = id;
          window.open(`/courseManagement${stringifySearchParams(this.params)}`);
          // 课程管理埋点
          TrackRequest({ name: 'course_management', data: { course_id: id } });
        }
      }
    }
  }
  /**
   * 预览课程
   * @param id 课程id
   */
  previewCourse(id: number) {
    this.existCourse(id, 'preview');
  }
  /**
   * 左侧菜单点击
   * @param type 菜单类型 create:我的创建 sync:我的同步
   */
  handleMenuClick = (type: string | null) => {
    // if (judgeSourceType(this.params.name)) return null;
    const { root } = this.state;
    // 判断是否具有该权限
    const rootResult = root.findIndex((item: any) => item.type === type);
    if (rootResult === -1) {
      menuAction.updateButtonStatus({
        status: false,
        type: 'create_course',
        name: '创建课程',
      });
      this.setState({ isRoot: false });
      return null;
    }
    this.leftNavType = type;
    Object.assign(this.filterData, initialParams);
    updateSearchValue(this.filterData.name);
    this.textbookParams = {
      gradeCodeString: '',
      subjectCodeString: getSubjectId().join('|'),
      studySectionCodeString: getStudySectionId(),
    };
    menuAction.updateButtonStatus({
      status: type === 'create',
      type: 'create_course',
      name: '创建课程',
    });
    const _active_ = [...root];
    _active_[rootResult].active = true;
    this.setState(
      {
        root: this.transformData(_active_, type),
        disabled: true,
        chapterId: [],
        materialId: '',
        activeGradeKey: 0,
        activeSchoolPeriodKey: 0,
        activeReleaseStateKey: 0,
        activeSchoolSectionKey: 0,
        activeSubjectKey: 0,
        isRoot: true,
      },
      async () => {
        if (this.params.type === '0') {
          this.isShowPingguoUnion() ? await this.getPingguoData() : await this.getBasicsData();
          this.searchData(1);
        }
      },
    );
  };

  transformData(data: NormalObj[], type?: string | null) {
    for (let i = 0, len = data.length; i < len; i++) {
      if (data[i].type === type) {
        data[i].active = true;
      } else {
        data[i].active = false;
      }
    }
    return data;
  }

  hasPage(data: []) {
    if (!data.length) return false;
    if (this.params.type !== '0' && this.params.type !== '1') return false;
    if (data.length === 1) {
      if (this.params.type === '0' && this.createArticle && !this.createCourse) return false;
      if (this.params.type === '1' && this.createCourse && !this.createArticle) return false;
    }
    return true;
  }

  enterSearch = (v: string) => {
    this.filterData.name = v;
    this.searchData(1);
  };

  // 平果联盟定制逻辑
  renderPingguoFilter() {
    const { activeGradeKey, activeSubjectKey, activeSchoolSectionKey } = this.state;
    const {
      studySections,
      grades,
      subjects,
      history: {
        location: { search },
      },
    } = this.props;
    const columnId = QueryString.parse(decodeURIComponent(search).split('?')[1]).id;

    const studySectionProps = {
      data: studySections,
      type: 'schoolSection',
      activeKey: activeSchoolSectionKey,
      tabChange: this.handlePingguoChange,
    };
    const gradeProps = {
      data: grades,
      type: 'grade',
      activeKey: activeGradeKey,
      tabChange: this.handlePingguoChange,
    };
    const subjectProps = {
      data:
        columnId === SPECIAL_WEI_SHI_PRI_COLUMN_ID
          ? this.filterData.schoolSection
            ? SPECIAL_WEI_SHI_PRI_SUBJECTS_MAP[this.filterData.schoolSection]
            : []
          : subjects,
      type: 'subject',
      tabChange: this.handlePingguoChange,
      activeKey: activeSubjectKey,
    };
    const result = [];
    result.push(<Tablist notAddAll key={studySectionProps.type} {...studySectionProps} />);
    result.push(<Tablist key={gradeProps.type} {...gradeProps} />);
    result.push(<Tablist key={subjectProps.type} {...subjectProps} />);

    return result;
  }

  renderNoramlFilter() {
    const {
      disabled,
      chapterId,
      materialId,
      activeGradeKey,
      activeSubjectKey,
      activeSchoolPeriodKey,
    } = this.state;
    const { showSubject, showGrade, showSchoolPeriod, showMaterial } = this.state.filterConfig;
    const { grades, textBooks, tree, schoolPeriods } = this.props;

    const gradeProps = {
      data: grades,
      type: 'grade',
      activeKey: activeGradeKey,
      tabChange: this.handleChange,
    };
    // 学届
    const schoolPeriod = {
      data: schoolPeriods,
      type: 'schoolPeriod',
      tabChange: this.handleChange,
      activeKey: activeSchoolPeriodKey,
    };
    const subject = {
      data: getSubjectList(),
      type: 'subject',
      tabChange: this.handleChange,
      activeKey: activeSubjectKey,
    };

    const textBookProps = {
      materialId,
      data: textBooks,
      type: 'textbook',
      tabChange: this.handleChange,
    };
    const treeProps = {
      disabled,
      chapterId,
      data: tree,
      type: 'tree',
      tabChange: this.handleChange,
    };

    const result = [];
    if (showGrade) {
      result.push(<Tablist key={gradeProps.type} {...gradeProps} />);
    }
    if (showSubject) {
      result.push(<Tablist key={subject.type} {...subject} />);
    }
    if (showSchoolPeriod) {
      result.push(<Tablist key={schoolPeriod.type} {...schoolPeriod} />);
    }
    if (showMaterial) {
      result.push(
        <div className="combine-box" key={4}>
          <div className="combine-box-title">教材章节:</div>
          <div className="combine-box-left">
            <Tablist key={textBookProps.type} {...textBookProps} />
          </div>
          <div className="combine-box-right">
            <Tablist key={treeProps.type} {...treeProps} />
          </div>
        </div>,
      );
    }

    return result;
  }

  isShowPingguoUnion() {
    return isPingguoUnion() && this.leftNavType === 'sync';
  }

  renderFilter() {
    return this.isShowPingguoUnion() ? this.renderPingguoFilter() : this.renderNoramlFilter();
  }

  render() {
    const { root, activeReleaseStateKey, isRoot } = this.state;
    const { courseList } = this.props;

    const releaseProps = {
      type: 'releaseState',
      data: [
        {
          name: '已发布',
          value: 1,
        },
        {
          name: '未发布',
          value: 0,
        },
      ],
      activeKey: activeReleaseStateKey,
      tabChange: this.handleChange,
    };
    const state = Object.keys(this.filterData).findIndex(
      (key) => key !== 'size' && key !== 'current' && this.filterData[key] !== '',
    );
    const _root = root.find((item: NormalObj) => item.active);

    return (
      <div className="CourseList-Component">
        <div className="page">
          <ColumnList />
          {isRoot ? (
            <div className="right">
              <div className="search-filter">
                {this.leftNavType === 'create' && (
                  <div className="create-search">
                    <CreateSearch enterSearch={this.enterSearch} placeholder={'请输入课程名称'} />
                  </div>
                )}
                {this.leftNavType === 'sync' && (
                  <div className="create-search">
                    <CreateSearch
                      enterSearch={this.enterSearch}
                      placeholder={'请输入课程名称/简介'}
                    />
                  </div>
                )}
                <div className="filter">
                  {
                    // 发布状态--在同步里面没有
                    this.leftNavType === 'create' ? (
                      <Tablist key={releaseProps.type} {...releaseProps} />
                    ) : (
                      ''
                    )
                  }
                  {this.renderFilter()}
                </div>
              </div>

              <div
                className={`content ${
                  courseList.list && courseList.list.length === 0 ? 'min-height' : ''
                }`}
              >
                <div className="list">
                  {courseList.list && courseList.list.length > 0 ? (
                    courseList.list.map((item: courseData) => (
                      <CoursePanel
                        key={item.id}
                        data={item}
                        type={_root ? _root.number : ''}
                        toCourseDetail={this.toCourseDetail}
                        handleDelete={this.handleDelete}
                        handleRelease={this.handleRelease}
                        previewCourse={this.previewCourse}
                        isSync={this.leftNavType !== 'create'}
                      />
                    ))
                  ) : (
                    <EmptyList state={state === -1 ? 'noMore' : 'noResult'} />
                  )}
                </div>
                {courseList.page.totalElements > 0 ? (
                  <div className="course-list-pagination">
                    <Pagination
                      current={this.filterData.current}
                      pageSize={this.filterData.size}
                      total={courseList.page.totalElements}
                      size="small"
                      onChange={(page) => this.searchData(page)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div className="right">
              <Empty />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    ...state.CourseList,
    is_exist: state.CourseDetail.is_exist,
    studySections: state.Base.studySectionsList,
    subjects: state.Base.subjectsList,
    grades: state.Base.gradesList,
    schoolPeriods: state.Base.schoolPeriodList,
    textBooks: state.Base.textBooks,
    tree: state.Base.chaptersTree,
    resourceRootOption: state.GloabalCreateSync.resourceRootOption,
    addressBarColumnId: state.GloabalCreateSync.addressBarColumnId,
    rootOption: state.GloabalCreateSync.rootOption,
    menusConfig: state.Menu.menusConfig,
  };
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  // 注册根据权限获取数据函数
  registerGetDataFn: (fn: any) => dispatch({ type: 'GETDATABYROOT', payload: fn }),
  updateCurrentRootType: (type: string) =>
    dispatch({ type: 'GLOBALCREATESYNCTYPE', payload: type }),
  // 重置列表数据
  resetLists: () => dispatch({ type: 'RESET_LISTS' }),
  ...ownProps,
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
