/*
 * @Descripttion: 自定义消息列表
 * @Author: gouyang
 * @Date: 2020-12-08 16:38:51
 * @LastEditors: gouyang
 * @LastEditTime: 2020-12-15 14:10:09
 */
import React, { memo, useCallback } from 'react';
import { Pagination } from 'antd';
import moment from 'moment';
import emptyImage from '../../../../static/content_null.png';
import './index.less';

interface P {
  dataSource: {
    data: any[];
    page: number;
    size: number;
    total: number;
  };
  onChange: (page: number, pageSize?: number) => void;
  activeKey: string;
}

function UserTable(props: P) {
  const {
    dataSource: { data, page, size, total },
    onChange,
    activeKey,
  } = props;

  const onDetatils = useCallback(
    (id: number) => {
      window.sessionStorage.setItem(
        'PAGEINFO',
        JSON.stringify({
          type: activeKey,
          page,
          size,
        }),
      );
      (window as any)._history.push(`/message-detatils?id=${id}`);
    },
    [activeKey, page, size],
  );

  return (
    <div className="user-message-table">
      {data.length !== 0 ? (
        <>
          <ul className="table-ul">
            {data.map((d: any) => (
              <li key={d.id} onClick={() => onDetatils(d.id)}>
                <span className={`message-title ${d.readState === 0 ? 'no-reading' : ''}`}>
                  {d.name}
                </span>
                <span className="time">{moment(d.releaseTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              </li>
            ))}
          </ul>
          <div className="pagination">
            <Pagination
              size="small"
              current={page}
              pageSize={size}
              total={total}
              showSizeChanger
              onShowSizeChange={onChange}
              onChange={onChange}
            />
          </div>
        </>
      ) : (
        <div className="message-list-empty">
          <img alt="" src={emptyImage} />
          <span>暂无数据</span>
        </div>
      )}
    </div>
  );
}

export default memo(UserTable);
