/* eslint-disable @typescript-eslint/no-require-imports */
import Ajax from '../../lib/Ajax';
import AxiosWrap from '../../lib/request';
import * as types from '../types/course.list.type';
import { UPDATEOPERATIONSTATUS } from '../types/globalCreateSync.type';
import { message } from 'antd';
import Alert from '../../components/Alert/Alert';
import isNotRootLink from '../../util/isNotRootLink';
import _ from 'lodash';

const qs = require('qs');
/**
 * 获取课程列表
 * @param  params 筛选条件
 */
export async function getCourseList(params: any, type: string, cb: Function) {
  const _params = _.cloneDeep(params);
  let analyzesData: string[] = [];
  let url = '';
  if (type === '0') {
    url = '/course/created-by-me';
  } else if (type === '2') {
    analyzesData = await getSearchAnalyzes(_params.name);
    url = '/course/my-fallow';
    _params.keyword = _params.name;
    delete _params.name;
  }
  if (url) {
    (window as any)._dispatch({ type: UPDATEOPERATIONSTATUS, payload: false });
    AxiosWrap({
      url,
      params: _params,
    })
      .then((res: any) => {
        const result = {
          page: {},
          list: [],
        };
        result.page = res.data.page;
        if (res.data._embedded) {
          result.list = cb
            ? cb(res.data._embedded.courseResponses, type, analyzesData)
            : res.data._embedded.courseResponses;
        } else {
          result.list = [];
        }
        (window as any)._dispatch({ type: types.GET_COURSE_LIST, payload: result });
        (window as any)._dispatch({ type: UPDATEOPERATIONSTATUS, payload: true });
      })
      .catch((err: any) => {
        (window as any)._dispatch({ type: UPDATEOPERATIONSTATUS, payload: true });
        (window as any)._dispatch({
          type: types.GET_COURSE_LIST,
          payload: {
            page: {
              size: params.size,
              number: params.current,
              totalPages: 0,
              totalElements: 0,
            },
            list: [],
          },
        });
        if (err && err.data) {
          if (err.status === 403) {
            (window as any).sessionStorage.setItem('fetchMenu', true);
            Alert.open(err.data.message, () => {
              isNotRootLink();
            });
          }
        }
      });
  }
}
/**
 * 获取关键词分词结果
 */
export function getSearchAnalyzes(text: string) {
  return AxiosWrap({
    url: `/global-search-analyzes?${qs.stringify({ text })}`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      err && err.data && message.error(err.data.message);
      return [];
    });
}
export function deleteCourse(id: number, success: () => void) {
  AxiosWrap({
    url: `/course/${id}`,
    method: 'DELETE',
  })
    .then((res: any) => {
      if (res.status === 200) success();
    })
    .catch((err: any) => err && err.data && message.error(err.data.message));
}
/**
 * 下架课程
 */
export function unReleaseCourse(params: number, success: () => void) {
  AxiosWrap({
    url: `/course/${params}/unRelease`,
    method: 'put',
  })
    .then((res: any) => {
      if (res.status === 200) success();
    })
    .catch((err: any) => err && err.data && message.error(err.data.message));
}
/**
 * 课程是否存在
 * @param id 课程id
 */
export async function existCourse(id: number) {
  try {
    const { status }: any = await Ajax({ url: `/course/${id}/check-exist` });
    if (status === 200) return Promise.resolve(true);
  } catch (error) {
    if (error) {
      if ((error as any).status === 404) {
        message.error('课程不存在！');
      } else if ((error as any).status === 403) {
        message.error('暂无权限！');
      }
    }
  }
}
