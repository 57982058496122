const env = (window as any).__UNION_CENTER_ENV__;

export const SPECIAL_WEI_SHI_PRI_SUBJECTS_MAP: { [K in string]: any[] } = {
  XQ: [],
  DX: [],
  GZ: [
    { code: 'YW', name: '语文' },
    { code: 'SX', name: '数学' },
    { code: 'YY', name: '英语' },
    { code: 'WL', name: '物理' },
    { code: 'HX', name: '化学' },
    { code: 'SW', name: '生物' },
    { code: 'ZZ', name: '政治' },
    { code: 'LS', name: '历史' },
    { code: 'DL', name: '地理' },
  ],
  CZ: [
    { code: 'YW', name: '语文' },
    { code: 'SX', name: '数学' },
    { code: 'YY', name: '英语' },
    { code: 'WL', name: '物理' },
    { code: 'HX', name: '化学' },
  ],
  XX: [
    { code: 'YW', name: '语文' },
    { code: 'SX', name: '数学' },
    { code: 'YY', name: '英语' },
    { code: 'MS', name: '美术' },
    { code: 'kX', name: '科学' },
  ],
};

// 该栏目id下面学科筛选条件前端写死
// 平果联盟-闻道微师培（线上环境）：616646572466552832
// 平果联盟-闻道微师培（测试环境）：622096548781522944
// 平果联盟（使用闻道远程教学新市场用户体验平台）-微师培（开发环境）627832802944925696
const WEI_SHI_PEI_ID_MAP: { [K: string]: string } = {
  DEV: '627832802944925696',
  TEST: '622096548781522944',
  STAGE: '616646572466552832',
  PROD: '616646572466552832',
};
export const SPECIAL_WEI_SHI_PRI_COLUMN_ID: string = WEI_SHI_PEI_ID_MAP[env];
