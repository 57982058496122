/*
 * @Author: gouyang
 * @Description: 全局创建同步按钮
 * @Date: 2019-10-10 14:39:11
 */
export const GLOBALCREATESYNCTYPE = 'GLOBALCREATESYNCTYPE'; // 当前权限类型
export const ROOTOPTION = 'ROOTOPTION'; // 权限选项
export const GETDATABYROOT = 'GETDATABYROOT'; // 通过权限类型获取数据
export const INITIALTIMETABLEROOTINFO = 'INITIALTIMETABLEROOTINFO'; // 初始化课程表权限信息
export const UPDATEADDRESSBARID = 'UPDATEADDRESSBARID'; // 更新地址栏中所选栏目id
export const UPDATEOPERATIONSTATUS = 'UPDATEOPERATIONSTATUS'; // 更新操作状态
