/*
 * @Descripttion:
 * @Author: gouyang
 * @Date: 2021-03-12 08:49:17
 * @LastEditors: gouyang
 * @LastEditTime: 2021-03-16 13:40:10
 */
import { getLeagueId, getUserId } from './index';

function getFileType(data: any): string | null {
  const { name, extension } = data;
  if (extension) return extension.toLowerCase();
  if (name) {
    const names = name.split('.');
    return names[names.length - 1].toLowerCase();
  }
  return null;
}

export const hasUnion = (): boolean => {
  const unionId = getLeagueId();
  const unionIds = ['430317873770971136', '433237859917283328', '430317817097535488'];
  return unionIds.indexOf(unionId) !== -1;
};

export const isYC = (): boolean => {
  const unionId = getLeagueId();
  const unionIds = ['428915350195585024'];
  return unionIds.indexOf(unionId) !== -1;
};

export const isProhibit = (data: any): boolean => {
  const type = getFileType(data);
  if (!type) return true;
  if (hasUnion()) {
    if (type.toLowerCase() !== 'mp4') return true;
    return false;
  }
  return true;
};

export const showUserInfo = (): boolean => {
  const env = (window as any).__UNION_CENTER_ENV__;
  let userIds: number[] = [316313, 316314, 316331, 317931];
  if (env === 'PROD' || env === 'STAGE') userIds = [460919, 460920, 788246, 1335025];
  if (userIds.indexOf(+getUserId()) !== -1) return false;
  return true;
};

export const showDeclare = (): boolean => {
  const env = (window as any).__UNION_CENTER_ENV__;
  const unionId = getLeagueId();
  let unionIds: string[] = ['341469785811062784', '428912071315210240', '340868357230530560'];
  if (env === 'PROD' || env === 'STAGE') unionIds = ['362543918355361792', '362544042724864000'];
  return unionIds.indexOf(unionId) !== -1;
};

const allowWatchingPlayback = (): boolean => {
  const unionId = getLeagueId();
  return unionId !== '362543918355361792';
};

export const allowClicking = (live: any, lessonType: number, liveState: number): boolean => {
  if (
    lessonType === 1 &&
    liveState === 2 &&
    live &&
    live.liveLessonType === 0 &&
    !allowWatchingPlayback()
  ) {
    return false;
  }
  return true;
};
