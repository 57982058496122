/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Input, Table, Icon, Select, Tooltip, Form } from 'antd';
import { getSearchParams, fixedStudyYear } from '../../util';
import {
  getLabels,
  getUsers,
  getStudySections,
  getObjectsBySection,
} from '../../redux/action/account.action';
import './index.less';

const { Option } = Select;

interface TableData {
  no: number | string;
  key: number | string;
  name: string;
  grade: string;
  subject: string;
  label: string;
  phone: string;
}

interface TableColumns {
  title: string;
  dataIndex: string;
  key: string;
  onCell?: (...args: any) => {};
  render?: (...args: any) => {};
}

interface SearchParams {
  page?: number;
  keyword?: string;
  labelId?: string;
}

const schoolPeriodArr = fixedStudyYear();

function AccountManageDetail({ form }: any) {
  const { getFieldDecorator, setFieldsValue, getFieldsValue } = form;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [labels, setLabels] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [odjectDisable, setOdjectDisable] = useState<boolean>(true);
  const [objects, setObjects] = useState<any[]>([]);

  const { orgName, orgId } = getSearchParams(window.location.search);

  const columns: TableColumns[] = [
    {
      title: '序号',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '学届',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: '执教学科',
      dataIndex: 'subject',
      key: 'subject',
      onCell: () => {
        return {
          style: {
            maxWidth: 180,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          },
        };
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '标签',
      dataIndex: 'label',
      key: 'label',
      onCell: () => {
        return {
          style: {
            maxWidth: 180,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          },
        };
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    // {
    //   title: '联系电话',
    //   dataIndex: 'phone',
    //   key: 'phone',
    // },
  ];
  useEffect(() => {
    Promise.all([getLabels({ page: 0, size: 1000, code: 'chengyuan' }), getStudySections(orgId)])
      .then((res) => {
        const [labelsData, sectionsData] = res;
        const {
          _embedded: { labels = [] },
        } = labelsData.data;
        setLabels(labels);

        const { data } = sectionsData;
        if (data && data.length === 1) {
          setFieldsValue({ studySectionId: data[0].code });
          handleSectionChange(data[0].code);
        }
        setSections((data || []).reverse());
        return true;
      })
      .then(() => {
        getUserMethod();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSearch() {
    setCurrentPage(0);
    getUserMethod();
  }

  function handleCancel() {
    const sectionVal = sections.length === 1 ? sections[0].code : 'all';
    setCurrentPage(0);
    setFieldsValue({
      schoolPeriod: 'all',
      studySectionId: sectionVal,
      subjectId: 'all',
      labelId: undefined,
      name: '',
    });
    handleSectionChange(sectionVal);
    getUserMethod(0, true);
  }

  function handlePageChange(val: number) {
    setCurrentPage(val - 1);
    getUserMethod(val - 1);
  }

  const handleSectionChange = useCallback(
    (val: string) => {
      setFieldsValue({ subjectId: 'all' });
      if (val && val === 'all') {
        setOdjectDisable(true);
      } else {
        getObjectsBySection(val).then(({ data }) => {
          setObjects(data || []);
        });
        setOdjectDisable(false);
      }
    },
    [setFieldsValue],
  );

  function getUserMethod(page?: number, isReset?: boolean) {
    const { leagueId, orgId } = getSearchParams(window.location.search);
    const values = getFieldsValue();
    for (const key in values) {
      if (!values[key] || values[key] === '' || values[key] === 'all') {
        delete values[key];
      }
    }
    getUsers({
      page: page || 0,
      size: 10,
      leagueId,
      orgId,
      ...values,
    }).then(({ data }) => {
      const { _embedded, page } = data;
      setTotal(page && page.totalElements ? page.totalElements : 0);
      setDataSource(
        _embedded && _embedded.users
          ? _embedded.users.map((user: any, index: any) => ({
              no: index + 1,
              key: user.userId,
              name: user.name,
              grade: user.schoolPeriods.join(','),
              subject: user.subjects.map((s: any) => s.subjectName).join(','),
              label: (user.labels || [])
                .map((l: any) => {
                  return l && l.labelName ? l.labelName : '';
                })
                .join(','),
              phone: user.phone,
            }))
          : [],
      );
    });
  }

  return (
    <div className="account-manage-detail">
      <div className="manage-detail-title">{orgName}-账号开通情况</div>
      <div className="manage-detail-search">
        <Form layout="inline">
          <Form.Item label="学届">
            {getFieldDecorator('schoolPeriod', {
              initialValue: 'all',
            })(
              <Select
                className="search-select"
                placeholder="请选择学界"
                dropdownClassName="schoolPeriodProp"
              >
                {schoolPeriodArr.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="学段">
            {getFieldDecorator('studySectionId', {
              initialValue: 'all',
            })(
              <Select
                className="search-select"
                placeholder="请选择学段"
                onChange={handleSectionChange}
              >
                <Option key="all" value="all">
                  全部
                </Option>
                {sections.map((item) => (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="执教学科">
            {getFieldDecorator('subjectId', {
              initialValue: 'all',
            })(
              <Select
                className="search-select"
                placeholder="请选择执教学科"
                disabled={odjectDisable}
              >
                <Option key="all" value="all">
                  全部
                </Option>
                {objects.map((item) => (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="标签">
            {getFieldDecorator('labelId')(
              <Select className="search-select" allowClear placeholder="请选择标签">
                {labels.map((l: any) => (
                  <Option key={l.id} value={l.id}>
                    {l.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="姓名">
            {getFieldDecorator('name', {
              initialValue: '',
            })(<Input className="search-input" allowClear placeholder="教师名称" />)}
          </Form.Item>
        </Form>
      </div>
      <div className="manage-detail-header">
        <div className="left-section">
          {/* <div className="bolder-title">学校账号开通情况</div> */}
          <div>
            教师数量：<span style={{ color: '#02A7F0' }}>{total}</span>
          </div>
        </div>
        <div className="right-section">
          {/* <Select
            className="search-select"
            value={label ? label : undefined}
            onChange={(label: string) => setLabel(label)}
            placeholder="请选择标签"
          >
            {labels.map((l: any) => (
              <Option key={l.id} value={l.id}>
                {l.name}
              </Option>
            ))}
          </Select>
          <Input
            value={keyword}
            onChange={(e: any) => setKeyword(e.target.value.trim())}
            className="search-input"
            placeholder="教师名称或电话"
          /> */}
          <Button className="search-button" type="primary" onClick={() => handleSearch()}>
            <Icon type="search" />
            搜索
          </Button>
          <Button className="search-button" type="ghost" onClick={() => handleCancel()}>
            清空
          </Button>
        </div>
      </div>
      <div className="manage-detail-content">
        <Table
          bordered
          columns={columns}
          dataSource={dataSource}
          pagination={{
            current: currentPage + 1,
            total,
            pageSize: 10,
            onChange: handlePageChange,
          }}
          rowClassName={() => 'table-row'}
        />
      </div>
    </div>
  );
}

export default Form.create()(AccountManageDetail);
