import React from 'react';
import moment from 'moment';
import { CustomIcon } from '../../../components/SmallComponents';
import PopuprHigherFun from '../../../util/PopuprHigherFun';

import './index.less';

interface P {
  value: number; // 时间戳格式
  monthSchedule: any; // 月历数据
  event: any; // 事件对象
  dateChange: (value: number) => void; // 日期切换
  monthChange: (value: number[]) => void; // 月份切换
  composeMonthDays: (value: string[]) => any; // 根据后台数据 ，来组装月历数据
}
class Calendar extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {
      left: 0,
      top: 0,
      text: null,
      data: [], // [YYYY,MM]格式
      daysArr: [],
      current: moment(moment().format('YYYY-MM-DD')).valueOf(),
    };
  }
  componentDidMount() {
    this.setPosition();
    this.setData();
  }
  // 设置数据
  setData = () => {
    const { value } = this.props;
    let val: any = value ? moment(value).format('YYYY-MM') : moment().format('YYYY-MM');
    const text = value ? moment(value).format('YYYY年MM月') : moment().format('YYYY年MM月');
    val = val.split('-');
    this.setState(
      {
        data: val,
        text,
      },
      this.getMonthDay,
    );
  };
  // 设置组件显示的位置
  setPosition = () => {
    const { event } = this.props;
    const $dom = event.target;
    const top = $dom.offsetTop + $dom.clientHeight + 5;
    const left = $dom.offsetLeft;
    this.setState({
      top,
      left,
    });
  };
  //  上一月
  prevMonth = () => {
    const { data } = this.state;
    let year = Number(data[0]);
    let month = Number(data[1]) - 1;
    if (month < 1) {
      year--;
      month = 12;
    }
    const str = moment(`${year}-${month}`, 'YYYY-MM').format('YYYY年MM月');
    this.setState(
      {
        data: [year, month],
        text: str,
      },
      this.getMonthDay,
    );
    this.props.monthChange([year, month]);
  };
  // 下一月
  nextMonth = () => {
    const { data } = this.state;
    let year = Number(data[0]);
    let month = Number(data[1]) + 1;
    if (month > 12) {
      year++;
      month = 1;
    }
    const str = moment(`${year}-${month}`, 'YYYY-MM').format('YYYY年MM月');
    this.setState(
      {
        data: [year, month],
        text: str,
      },
      this.getMonthDay,
    );
    this.props.monthChange([year, month]);
  };
  /**
   * 点击日期切换
   * @param item: 点击的数据对象
   */
  dateChange = (item: any) => {
    this.setState({
      current: item.date,
    });
    this.props.dateChange(item.date);
  };
  // 获取月历日期数据
  getMonthDay = async () => {
    const { composeMonthDays } = this.props;
    const { data } = this.state;
    const list = await composeMonthDays(data);
    this.setState({
      daysArr: list,
    });
  };
  render() {
    const { top, left, text, daysArr, current } = this.state;
    return (
      <div className="calendar" style={{ top: `${top}px`, left: `${left}px` }}>
        <div className="head">
          <div className="operation">
            <CustomIcon
              name="iconPagin_left"
              onClick={this.prevMonth}
              activeName="iconPagin_leftbeifen"
            />
            <span>{text}</span>
            <CustomIcon
              name="iconPagin_left"
              onClick={this.nextMonth}
              activeName="iconPagin_leftbeifen"
              type="right"
            />
          </div>
          <div className="weekstr">
            <span>周日</span>
            <span>周一</span>
            <span>周二</span>
            <span>周三</span>
            <span>周四</span>
            <span>周五</span>
            <span>周六</span>
          </div>
        </div>
        <div className="bottom">
          {daysArr.map((item: any, x: number) => (
            <div className="item" key={x}>
              {item &&
                item.map((items: any, y: number) => (
                  <div
                    className={items.date === current ? 'three' : items.type}
                    key={y}
                    onClick={() => this.dateChange(items)}
                  >
                    <span data-name="date">{items.val}</span>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default PopuprHigherFun(Calendar);
