/*
 * @Author: gouyang
 * @Description: 权限配置
 * @Date: 2019-11-07 09:46:55
 */
// 将权限类型映射为课程id
export const privilegeTypeMapScheduleId: { [key: string]: number } = {
  sync: 2,
  create: 1,
};
// 将课程id映射为权限类型
export const scheduleIdMapPrivilegeType: { [key: number]: string } = {
  2: 'sync',
  1: 'create',
};
