/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-case-declarations */
/*
 * @Author: gouyang
 * @Description: 分屏页面
 * @Date: 2019-07-23 13:33:43
 */
import React, { Component } from 'react';
// import axios from 'axios';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import { Row, Col, Timeline, Icon } from 'antd';
import * as action from '../../redux/action/vod.action';
import Alert from '../../components/Alert/Alert';
import ResourceViewCom from '../../components/media/resourceView/resourceView';
import {
  NormalObj,
  mergeProps,
  getSearchParams,
  formateMinutes,
  MyIcon,
  // getToken,
  formatUrl,
  uuid,
} from '../../util';
import api from '../../config/api';
import jsMethod from './C';
import AxiosWrap from '../../lib/request';
import splitScreenRequest from '../../lib/splitScreenTrakRequest';
import { PlayTime } from '../../util/playTime';
import './index.less';
import './splitScreenVideo.less';

const PlayTimeFn = PlayTime();

// 定义全局变量标识当前出于四分屏中
// let topPlayer: any;
const { Item } = Timeline;
interface P extends RouteComponentProps, FormComponentProps {
  // eslint-disable-next-line react/no-unused-prop-types
  lessonList: NormalObj[];
}
class SplitScreenVideo extends Component<P, any> {
  appId: string;
  pause: boolean;
  topRef: any;
  media: any;
  searchParams: NormalObj;
  private randomMd5: string;
  private achePPTPage: number; // 缓存ppt 页码
  private isPTTTurn: boolean; // 判断是否是ppt触发的跳转
  private ids: {
    fileId: string;
    resourceId: string;
    lessonId: string;
    courseId: string;
    source: number;
    userId: number;
  }; // 播放时长提交数据
  private relationMappingData: any[]; // 最后一个关联关系
  private isTurnTo: boolean;
  constructor(props: P) {
    super(props);
    this.isTurnTo = false;
    this.appId = '';
    this.media = null;
    this.pause = false;
    this.resize = this.resize.bind(this);
    this.topRef = null;
    this.searchParams = getSearchParams(props.location.search);
    this.randomMd5 = uuid();
    this.state = {
      showPreview: false,
      showMarkList: false,
      // vedioHeight: window.innerHeight - 180,
      preViewProps: {
        url: '',
        type: '',
      },
      activeTimeIndex: '',
      activeLessonIndex: '',
      // activeRewordIndex: '',
      preview_lesson: {
        // 上面预览详情
        url: '',
        marks: [],
        fileId: '',
        playId: null,
      },
      // isShowMarks: false, // 是否需要显示打点标记
      isTxPlay: false, // 是否为腾讯播放器
      relationData: [], // 视频与ppt关系
    };
    this.relationMappingData = [];
    this.isPTTTurn = false;
    this.achePPTPage = -1;
    this.pausePlayer = this.pausePlayer.bind(this);
    this.ids = {
      fileId: '',
      resourceId: '',
      lessonId: '',
      courseId: '',
      source: 2,
      userId: this.searchParams.userId,
    };
  }
  componentDidMount() {
    // 接口状态
    window.sessionStorage.setItem('SPLITSCREENSTATUS', JSON.stringify({ status: -1 }));
    // 重置样式
    const root = document.getElementById('root');
    (root as any).style.minWidth = 0;
    this.screenChange();
    this.ids = Object.assign(this.ids, {
      lessonId: this.searchParams.lessonId,
      courseId: this.searchParams.courseId,
    });
    this.initData();
    // 获取视频与ppt关系
    this.relation();
    // 挂载c++方法
    (window as any).handleNativeNotify = this.handleNativeNotify.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    // topPlayer && topPlayer.dispose();
    // topPlayer = null;
    Alert.remove('lesson');
    Alert.remove('lessonList');
  }
  // 初始化数据获取
  initData = async () => {
    const { location } = this.props;
    const { access_token, unionId, id, userId } = this.searchParams;
    (window as any).localStorage.setItem('unionId', unionId);
    (window as any).localStorage.setItem('userInfo', JSON.stringify({ access_token, userId }));
    this.searchParams = getSearchParams(location.search);
    this.playLesson(id);
  };
  // 获取ppt与视频关系
  async relation() {
    const { id } = this.searchParams;
    const videoID = [id];
    const data = await action.getRelation(videoID);
    this.setState({
      relationData: data,
    });
    if (data[data.length - 1]) {
      this.relationMappingData = [...data[data.length - 1].mapping];
      this.relationMappingData.sort((a: any, b: any) => a.timeTick - b.timeTick);
    }
  }
  /**
   * 预览视频
   * @param id 课时Id
   */
  playLesson = async (id: string) => {
    let data: NormalObj = {};
    data = await action.getMark(id);
    // this.setState({ isShowMarks: this.searchParams.videoType === 1 });
    // this.disposeCloudPlayer('player-container-id');
    this.ids = Object.assign(this.ids, { fileId: id, resourceId: this.searchParams.resourceGuid });
    // if (data.playId) {
    //   this.getTencentAppId(data.playId, 'player-container-id');
    // } else {
    this.setState({
      showPreview: true,
      preViewProps: {
        url: formatUrl(data, this.randomMd5),
        type: 'mp4',
      },
    });
    // }
    this.setState({
      preview_lesson: data,
      activeLessonIndex: `${id}0`,
    });
  };
  screenChange() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    // this.setState({ vedioHeight: window.innerHeight - 180 });
  }
  /**
   * 获取腾讯云appId
   * @param playId 腾讯云id
   * @param url 预览视频地址
   */
  // getTencentAppId(playId: string, className: string) {
  //   if (playId) {
  //     axios({
  //       method: 'GET',
  //       url: `${api.file}/v3/cloud/bucket`,
  //       headers: {
  //         Authorization: `Bearer ${getToken() || ''}`,
  //         'x-module': 'open_school',
  //       },
  //       params: {
  //         provider: 'tencent',
  //         playId,
  //       },
  //     }).then(
  //       (res) => {
  //         this.appId = res.data.subAppId;
  //         if (this.state.showPreview && className === 'player-container-id')
  //           this.setState({ showPreview: false });
  //         this.creactCloudPlayer(playId, className);
  //       },
  //       () => (this.appId = ''),
  //     );
  //   }
  // }
  /**
   * 腾讯云超级播放器回调函数
   * @param player 播放器实例
   * @param className 类名
   * @param time 时间戳
   */
  // createPlayerCallBack(player: any, className: string) {
  //   player.on('ended', () => {
  //     player.isFullscreen() && player.exitFullscreen();
  //   });
  //   player.on('pause', () => {
  //     if (className === 'player-container-id') {
  //       PlayTimeFn.stopPlayTime();
  //       this.pause = true;
  //     }
  //   });
  //   player.on('play', () => {
  //     PlayTimeFn.startPlayTime({ ...this.ids });
  //   });
  //   player.on('timeupdate', () => {
  //     PlayTimeFn.getStartAndEnd(player.currentTime(), player.duration(), this.isTurnTo);
  //     this.isTurnTo = false;
  //     this.turnToPPT();
  //   });

  //   player.on('ratechange', () => {
  //     PlayTimeFn.speed = player.playbackRate();
  //   });
  // }
  /**
   * 创建腾讯云超级播放器实例
   * @param appId
   * @param fileID
   * @param url
   * @param className
   * sdk文档: https://cloud.tencent.com/document/product/266/14603
   */
  // createRealPlayer(appId: string, fileID: string, className: string) {
  //   return (window as any).TCPlayer(className, {
  //     fileID,
  //     appID: appId,
  //     autoplay: true,
  //   });
  // }
  /**
   * 初始化腾讯云超级播放器
   * @param fileID 文件服务视频所对应id
   * @param url 视频转码前地址
   * @param className 播放器类名
   * @param time 打点时间
   */
  // creactCloudPlayer(fileID: string, className: string) {
  //   // 请传入点播账号的appID 必须
  //   const { appId } = this;
  //   let tempPlayer = null;
  //   tempPlayer = this.createRealPlayer(appId, fileID, className);
  //   if (appId) {
  //     if (className === 'player-container-id') {
  //       topPlayer = tempPlayer;
  //       this.setState({
  //         isTxPlay: true,
  //       });
  //     }
  //     this.createPlayerCallBack(tempPlayer, className);
  //   } else {
  //     window.setTimeout(() => {
  //       this.creactCloudPlayer(fileID, className);
  //     }, 500);
  //   }
  // }
  /**
   * 重创腾讯云超级播放器
   * @param className 播放器类名
   */
  // disposeCloudPlayer = (className: string) => {
  //   const innerHTML = `<video
  //       id="${className}"
  //       width='100%'
  //       height="100%"
  //       preload="auto"
  //       playsInline='true'
  //       webkit-playinline='true'
  //       x5-playinline='true'
  //       />`;
  //   if (className === 'player-container-id' && topPlayer) {
  //     topPlayer.dispose();
  //     topPlayer = null;
  //     this.topRef.innerHTML = innerHTML;
  //   }
  // };

  /**
   * 实录标记跳转
   * @param timeMark 打点对象
   */
  turnTo(timeMark: NormalObj) {
    const { isEnd } = this.state;
    const time =
      // topPlayer ? topPlayer.duration() :
      this.media.duration();
    if (timeMark.timeTick >= 0 && timeMark.timeTick <= time) {
      if (isEnd) {
        this.setState({ isEnd: false });
      }
      this.isTurnTo = true;
      // if (topPlayer) {
      //   topPlayer.currentTime(timeMark.timeTick);
      // } else {
      //   this.media.currentTime(timeMark.timeTick);
      // }
      this.media.currentTime(timeMark.timeTick);
    } else if (timeMark.timeTick < 0) {
      Alert.open('对不起，该时间点为异常时间点！');
    } else if (timeMark.timeTick > time) {
      Alert.open('对不起，该时间点已超过视频总时长！');
    }
  }
  /**
   * 暂停上/下播放器
   */
  pausePlayer() {
    // if (topPlayer) {
    //   topPlayer.pause();
    // }
    if (this.media) {
      this.media.pause();
    }
  }
  /**
   * 设置顶部资源中心播放器组件
   * @param media 播放器实例
   */
  handleMedia(media: any) {
    this.media = media;
    this.media.on('ended', () => {
      this.media.exitFullscreen();
    });
    this.media.on('play', () => {
      PlayTimeFn.startPlayTime({ ...this.ids });
    });
    this.media.on('pause', () => {
      PlayTimeFn.stopPlayTime();
    });
    this.media.on('timeupdate', () => {
      PlayTimeFn.getStartAndEnd(this.media.currentTime(), this.media.duration(), this.isTurnTo);
      this.isTurnTo = false;
      this.turnToPPT();
    });
    this.media.on('ratechange', () => {
      PlayTimeFn.speed = this.media.playbackRate();
    });
    this.state.isTxPlay && this.setState({ isTxPlay: false });
    // this.media.currentTime(time);
  }
  // 获取课时资料
  async getClassHourData() {
    // 获取课时详情
    const lessonInfo = await action.fetchLesson(this.searchParams.lessonId, this.searchParams.type);
    const data = this.createFileDownloadAddress(lessonInfo.centerResources);
    jsMethod.get_C_method(data);
  }
  // 构造文件下载请求地址
  createFileDownloadAddress(data: any[]) {
    const files: any[] = [];
    data.forEach((d: any) => {
      d.files.forEach((c: any) => {
        c.url = `${api.api}/resources/${d.resourceGuid}/files/${c.fileId}/download?courseId=${this.searchParams.courseId}&lessonId=${this.searchParams.lessonId}`;
        c.createTime = '';
        c.category = d.category;
        files.push(c);
      });
    });
    const result = this.produceStructureAPI('documents', files);
    return result;
  }
  // 生成c++ 数据格式
  produceStructureAPI(name: string, data: any): { protocol: string; details: any } {
    return {
      protocol: name,
      details: data,
    };
  }
  // 获取接口状态
  getApiStatus() {
    let store = window.sessionStorage.getItem('SPLITSCREENSTATUS');
    store = store ? JSON.parse(store) : { status: '' };
    const { status } = store as any;
    switch (status) {
      case -1:
        window.requestAnimationFrame(this.getApiStatus.bind(this));
        break;
      case 1:
        jsMethod.get_C_method({ protocol: 'pageStatus', details: null });
        break;
      case 0:
        delete (store as any).status;
        jsMethod.get_C_method(store);
        break;
      default:
    }
  }
  // 构建文件下载地址
  fileDownloadAddress(fileUrl: string, fileId: string) {
    AxiosWrap({
      url: fileUrl,
      method: 'get',
    }).then((res: any) => {
      let url = res.data.cdnUrl || res.data.url;
      if (url) {
        url = `https${url.substring(url.indexOf(':'))}`;
        const result = this.produceStructureAPI('fileDownloadUrl', { url, fileId });
        jsMethod.get_C_method(result);
      } else {
        const result = this.produceStructureAPI('fileDownloadUrl', { url: null, fileId });
        jsMethod.get_C_method(result);
      }
    });
  }
  // 获取版本地址
  getVersion() {
    const { splitScreenUrl } = this.searchParams;
    const result = this.produceStructureAPI('prepareLessonsStudioVersion', { url: splitScreenUrl });
    jsMethod.get_C_method(result);
  }
  // ppt切换视频跳转
  spliteTurnTo(videoId: string, time: string) {
    const { id } = this.searchParams;
    if (videoId === id) {
      this.achePPTPage = this.palayTimeMapPage(parseFloat(time)); // 防止多次发送数据
      this.isPTTTurn = true;
      this.turnTo({ timeTick: time });
    }
  }
  // 视频跳转ppt切换
  turnToPPT() {
    if (this.isPTTTurn) {
      this.isPTTTurn = false;
      return null;
    }
    const { relationData } = this.state;
    const curentRelationData = relationData[relationData.length - 1];
    if (!curentRelationData) return null;
    const PPTID = curentRelationData.pptId; // ppt id
    const currentTime =
      // topPlayer ? topPlayer.currentTime() :
      this.media.currentTime();
    const PPTPage = this.palayTimeMapPage(currentTime); // ppt 页码
    if (this.achePPTPage !== PPTPage) {
      this.achePPTPage = PPTPage;
      const result = this.produceStructureAPI('documentCurrentShowPosition', {
        fileId: PPTID,
        currentShowPosition: `${PPTPage}`,
      });
      jsMethod.get_C_method(result);
    }
  }
  // 判断播放时间点对应的ppt页码
  palayTimeMapPage(playTime: number) {
    let page = -1;
    for (let i = 0, l = this.relationMappingData.length; i < l; i++) {
      const currentData = parseFloat(this.relationMappingData[i].timeTick);
      const nextData = i + 1 < l ? parseFloat(this.relationMappingData[i + 1].timeTick) : null;
      const firstData = parseFloat(this.relationMappingData[0].timeTick);
      if (playTime <= firstData) {
        page = 1;
        break;
      } else if (nextData !== null && playTime >= currentData && playTime <= nextData) {
        page = this.relationMappingData[i].index;
        break;
      } else if (nextData === null && playTime >= currentData) {
        page = this.relationMappingData[i].index;
      }
    }

    return page;
  }
  // 获取当前播放视频Id
  getCurrentVideoId() {
    const { id } = this.searchParams;
    const result = this.produceStructureAPI('currentRecordFileId', { fileId: id });
    jsMethod.get_C_method(result);
  }
  // 获取埋点信息
  trackInfo() {
    const { userId, unionId, lessonId, userStage } = this.searchParams;
    const result = this.produceStructureAPI('userProfile', {
      userId,
      userStage,
      leagueId: unionId,
      lessonId,
    });
    jsMethod.get_C_method(result);
  }
  // 埋点信息提交
  splitScreenTrakRequest(data: any) {
    splitScreenRequest(data);
  }
  // c++ 调用
  handleNativeNotify(params: string) {
    const _params = JSON.parse(params);
    const { details } = _params;
    switch (_params.protocol) {
      case 'getDocuments': // 获取资料课时
        this.getClassHourData();
        break;
      case 'getFileDownloadUrl': // 获取文件下载地址
        this.fileDownloadAddress(details.url, details.fileId);
        break;
      case 'isPageReady': // 获取接口状态
        this.getApiStatus();
        break;
      case 'prepareLessonsStudioVersion': // 获取版本号
        this.getVersion();
        break;
      case 'getRecordDocumentMapping': // 获取视频与ppt关联关系
        jsMethod.get_C_method(
          this.produceStructureAPI('recordDocumentMapping', this.state.relationData),
        );
        break;
      case 'recordCurrentPosition': // ppt切换视频跳转
        this.spliteTurnTo(details.reordId, details.recordCurrentPosition);
        break;
      case 'getCurrentRecordFileId': // 获取当前视频id
        this.getCurrentVideoId();
        break;
      case 'getUserProfile': // 获取埋点信息
        this.trackInfo();
        break;
      case 'behaviorData': // 埋点信息提交
        this.splitScreenTrakRequest(details.content);
        break;
      default:
        break;
    }
  }
  render() {
    const {
      showPreview,
      preViewProps,
      showMarkList,
      activeTimeIndex,
      activeLessonIndex,
      preview_lesson,
      isTxPlay,
    } = this.state;
    const { marks } = preview_lesson;
    const { videoType } = this.searchParams;

    return (
      <div className="vod-page" onContextMenu={(e: any) => e.preventDefault()}>
        <Row>
          <Col
            span={window.innerWidth <= 1680 ? 18 : 20}
            style={{ height: '100vh', width: '100%' }}
            className="vod-page-content-left"
          >
            <div
              ref={(topRef) => (this.topRef = topRef)}
              className={`wrapper-player ${isTxPlay && 'txPlayClass'}`}
            >
              {/* <video
                id="player-container-id"
                width="100%"
                height="100%"
                preload="auto"
                playsInline
                x5-playinline="test"
                webkit-playinline="test"
              /> */}
            </div>
            {videoType === '1' && marks && marks.length > 0 ? (
              <div
                className={`wrapper-mark ${showMarkList ? 'show' : ''}`}
                onClick={() => this.setState({ showMarkList: !showMarkList })}
              >
                {showMarkList ? <Icon type="double-left" /> : <Icon type="double-right" />}
              </div>
            ) : (
              ''
            )}
            {videoType === '1' && marks && marks.length > 0 ? (
              <div className={`mark-list ${showMarkList ? 'show' : ''}`}>
                <div className="mark-list-header">
                  <span>实录标记</span>
                  <span className="btn" onClick={() => this.setState({ showMarkList: false })}>
                    <MyIcon type="iconic_del" />
                  </span>
                </div>
                <div className="mark-list-content">
                  <Timeline>
                    {marks.map((item: NormalObj, index: number) => (
                      <Item key={index} className={activeTimeIndex === index ? 'active' : ''}>
                        <div onClick={() => this.turnTo(item)} className="mark-list-item">
                          <span className="time">{formateMinutes(item.timeTick)}</span>
                          <span className="intro">{item.description}</span>
                        </div>
                      </Item>
                    ))}
                  </Timeline>
                </div>
              </div>
            ) : (
              ''
            )}
            {showPreview ? (
              <div className="preview-wrap split-screen-wrap">
                {preViewProps.url && !preViewProps.playId && (
                  <ResourceViewCom
                    key={activeLessonIndex}
                    mediaType={preViewProps.type}
                    mediaAddress={preViewProps.url}
                    playerCallBack={(media: any) => this.handleMedia(media)}
                  />
                  // 上面播放器
                )}
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateTopProps = (state: any) => ({
  ...state.Vod,
  ...state.Course,
  lessonList: state.CourseDetail.list,
});
export default connect(mapStateTopProps, mergeProps)(SplitScreenVideo);
