import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import CourseTableChild from './CourseTableChild';
import { MyIcon } from '../../../util';

import './index.less';

interface P {
  value: number; // 时间戳
  source: number;
  courseTable: { [key: string]: any }; // 列表数据u
}
let emptyFlg = 0; // 空状态 标记
class CourseTable extends React.Component<P, any> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { courseTable, source, value } = this.props;
    const newData = dataFormatWeeks(value);
    const newList = dataFormat(courseTable);

    return (
      <div className="course-table">
        <div className="course-table-header">
          {newData.map((item: any, index: number) => (
            <div className="head-list" key={index}>
              <div className={item.className}>
                <span className="week">{item.name}</span>
                <span className="monthDay">{item.value}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="course-table-content">
          {emptyFlg > 0 &&
            newList.map((item: any, index: number) => (
              <div className="content-item" key={index}>
                <div>
                  {item.map((item: any) => (
                    <CourseTableChild key={item.lessonId} data={item} source={source} />
                  ))}
                </div>
              </div>
            ))}
          {emptyFlg === 0 || newList.length === 0 ? (
            <div className="empty">
              <MyIcon className="empty-icon" type="iconcontent_null" />
              <span>暂无内容</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
function dataFormatWeeks(value: number) {
  if (!value) {
    return [];
  }

  const currentDay = new Date().getDate(); // 当前天
  const currentMonth = new Date().getMonth() + 1; // 当前月
  const weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  const data = [];
  for (let i = 0; i < 7; i++) {
    if (i === 0) {
      const date = moment(value).endOf('week').format('MM-DD').split('-');
      const day = Number(date[1]);
      const month = Number(date[0]);
      data.push({
        className: currentDay === day && currentMonth === month ? 'active' : '',
        name: weeks[i],
        value: moment(value).endOf('week').format('MM.DD'),
      });
    } else {
      const date = moment(value).isoWeekday(i).format('MM-DD').split('-');
      const day = Number(date[1]);
      const month = Number(date[0]);
      data.push({
        className: currentDay === day && currentMonth === month ? 'active' : '',
        name: weeks[i],
        value: moment(value).isoWeekday(i).format('MM.DD'),
      });
    }
  }
  return data;
}
// 处理数据
function dataFormat(obj: any) {
  emptyFlg = 0;
  if (!obj) {
    return [];
  }
  let keys: any = [];
  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      keys.push(Number(i));
    }
  }
  keys = keys.sort();
  const len = keys.length;
  const data = [];
  for (let i = 0; i < len; i++) {
    if (i === len - 1) {
      data.unshift(obj[keys[i]]);
    } else {
      data.push(obj[keys[i]]);
    }
    if (obj[keys[i]].length > 0) {
      emptyFlg++;
    }
  }
  return data;
}
const mapStateToProps = (state: any) => ({
  courseTable: state.userCenter.courseTable,
});
export default connect(mapStateToProps)(CourseTable);
