import AxiosWrap from '../../lib/request';
import * as types from '../types/resource.type';
import { dispatch } from '../../createStore';
import { message } from 'antd';

// 资源列表
export const getResource = async (params?: any) => {
  try {
    const { status, data } = await AxiosWrap({
      url: '/follow_resource_archives',
      params,
      ignoreLoading: params.page !== 0,
    });
    if (status === 200) {
      if (data.page.number >= data.page.totalPages - 1) {
        dispatch({
          type: types.HAS_MORE,
          payload: false,
        });
      }
      dispatch({
        type: types.GET_RESOURCE_LIST,
        payload: data._embedded.resources,
      });
      dispatch({
        type: types.CURRENT_PAGE,
        payload: data.page.number + 1,
      });
    }
    return Promise.resolve({ status });
  } catch (error) {
    if (error && error.data && error.data.code !== 80000) {
      message.error(error.data.message);
    }
    return Promise.resolve({ status: 400 });
  }
};
