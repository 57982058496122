import React from 'react';
import { Empty } from 'antd';
import { getUserId } from '../../../util';

const userId = getUserId();

const List = function (props: { [key: string]: any }) {
  const { data, openResources, activeId, delResourcesList, delFiles, upload, openExpireModal } =
    props;
  const { files } = data;
  const isShow = data.createUserId === userId; // 是否显示操作按钮
  const str = switchs(Number(data.category));
  return (
    <div className="list">
      <div className="list-top">
        <span
          className={activeId === data.id ? 'row-expanded' : 'row-collapsed'}
          onClick={() => openResources(data.id)}
        />
        <p>
          <span>{str}</span>
          {data.name}
        </p>
        <div className="list-btn">
          <span onClick={() => openExpireModal(data)}>截止时间设置</span>
          {isShow ? <span onClick={() => upload(data)}>继续添加</span> : ''}
          <span onClick={() => delResourcesList(data.id)}>删除</span>
        </div>
      </div>
      {activeId === data.id ? (
        <div className="list-content">
          {files.map &&
            [...files].reverse().map((item: any, index: number) => (
              <div className="list-cont-list" key={index}>
                <p>{item.fileName}</p>
                <div className="list-btn">
                  {isShow ? <span onClick={() => delFiles(data.id, item.id)}>删除</span> : ''}
                </div>
              </div>
            ))}
          {files.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : ''}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
function switchs(num: number) {
  let str = '';
  switch (num) {
    case 2: // 课件
      str = '[课件]';
      break;
    case 3: // 素材
      str = '[素材]';
      break;
    case 4: // 教案
      str = '[教案]';
      break;
    case 5: // 作业
      str = '[普通作业]';
      break;
    case 6: // 试卷
      str = '[普通试卷]';
      break;
    default:
  }
  return str;
}
export default List;
