/*
 * @Author: gouyang
 * @Description: 区域标题
 * @Date: 2019-08-10 09:03:49
 */

import React from 'react';
import './regionTitle.less';

interface P {
  title: string;
  p?: string;
}

function RegionTitle(props: P) {
  const { title, p } = props;

  return (
    <div className="region-title">
      <h2>
        {title.split('$').map((t: any, i: number) => {
          return <span key={i}>{t}</span>;
        })}
      </h2>
      <p className="line">
        <i />
        <i />
        <i />
      </p>
      {p && <p>{p}</p>}
    </div>
  );
}

export default RegionTitle;
