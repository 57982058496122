import React from 'react';
import { MyIcon, getType } from '../../../util';
import { Empty } from 'antd';

export function Attachment(props: any) {
  const { attachmentList, onDownloadClicked } = props;
  if (attachmentList.length > 0) {
    return (
      <>
        {attachmentList.map((item: any, index: any) => (
          <div className="res-item" key={index} onClick={() => onDownloadClicked(item)}>
            <MyIcon className="iconfont" type={getType(item).type} />
            <span className="res-item-name">{item.fileName}</span>
          </div>
        ))}
      </>
    );
  } else {
    return (
      <Empty
        className="ant-empty-normal custom-empty"
        description="暂无内容"
        image={<MyIcon className="empty-icon" type="iconcontent_null" />}
      />
    );
  }
}
