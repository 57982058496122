/**
 *  @desc    媒体资源播放（flash）
 *  @author  huangyu
 *  @params
 */

import React, { Component } from 'react';
import './flash.less';

export default class FlashComponent extends Component {
  static defaultProps = {
    pause: true,
    playerCallBack: () => {},
  };
  constructor() {
    super();
    this.state = {
      address:
        'http://portalvhds8rskfwphhq1p4.blob.core.chinacloudapi.cn/onlinedata/201704/20170406/File/0-0203b70ecfb0437aa8503c878a86603b.res',
    };
  }
  render() {
    const { mediaAddress } = this.props;
    return (
      <div className="flash-comtent">
        <embed
          type="application/x-shockwave-flash"
          id="flash-player"
          src={mediaAddress}
          className="embed"
          controls="controls"
        />
      </div>
    );
  }
}
