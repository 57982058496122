import React, { useCallback, useState, useEffect } from 'react';
import { Icon } from 'antd';
import moment from 'moment';
import { MyIcon, getMaterialBg, hideSubjectTag } from '../../../../util';
import DescriptionDialog from '../DescriptionDialog';
import getBrief from '../../../../lib/getBrief';
import './contentHeader.less';

interface P {
  data: any;
  isUpdated: boolean;
}
function ContentHeader({ data, isUpdated }: P) {
  const [visible, setVisible] = useState<boolean>(false);
  const [briefInfo, setBriefInfo] = useState({
    html: '',
    pureText: '',
  });
  const lesssonType = ['直播课时', '点播课时', '资料课时'];
  const iconType = ['iconic_live', 'iconic_demand', 'iconic_data'];

  const onDescriptionMouseEnter = useCallback(() => {
    setVisible(true);
  }, []);
  const onDescriptionMouseLeave = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    data && setBriefInfo(getBrief(data.brief));
  }, [data]);

  return (
    data && (
      <div className="Content-Header">
        <div className="info-img">
          <img src={data.coverUrl} alt="" width="100%" />
          {data.leftMenuType === '2' && isUpdated && <span className="update-tag">有更新</span>}
        </div>
        <div className="info-text">
          <div>
            <div className="name">{data.courseName}</div>
            {briefInfo.pureText && (
              <pre className="desc" dangerouslySetInnerHTML={{ __html: briefInfo.html }} />
            )}
            <div className="tags">
              {data.labels &&
                hideSubjectTag(data.grades || [], data.labels).map((lable: any) => (
                  <pre key={lable.labelId}>{lable.labelName}</pre>
                ))}
            </div>
            <div className="desc-time">
              <div className="time-box">
                {data.leftMenuType === '0' && (
                  <span>创建时间：{moment(data.createTime).format('YYYY/MM/DD HH:mm')}</span>
                )}
                {
                  // 我的创建
                  data.leftMenuType === '0' && data.myCreateUpdateTime && (
                    <span>
                      更新时间：{moment(data.myCreateUpdateTime).format('YYYY/MM/DD HH:mm')}
                    </span>
                  )
                }
                {data.leftMenuType === '2' && data.myFallowUpdateTime && (
                  <span>
                    更新时间：{moment(data.myFallowUpdateTime).format('YYYY/MM/DD HH:mm')}
                  </span>
                )}
              </div>
              {data.description && (
                <span
                  className="details-description"
                  onMouseLeave={onDescriptionMouseLeave}
                  onMouseEnter={onDescriptionMouseEnter}
                >
                  <span className="text">课程说明</span>
                  <Icon type="info-circle" />
                  <DescriptionDialog content={data.description} visible={visible} />
                </span>
              )}
            </div>
            {/* <div className="panel-number">
              观看进度：<span className="panel-number-mid fs18">3/5</span>
            </div> */}
          </div>
          <div className="citem-count">
            {data.lessonNum.length > 0 &&
              data.lessonNum.map((item: { lessonType: number; num: number }) => {
                return (
                  <div
                    className="live"
                    key={item.lessonType}
                    style={getMaterialBg(item.lessonType)}
                  >
                    <span className="live-left">
                      <MyIcon className="iconfont" type={iconType[item.lessonType - 1]} />
                      <span className="live-name">{lesssonType[item.lessonType - 1]}</span>
                    </span>
                    <span className="live-right">{item.num}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    )
  );
}
export default ContentHeader;
