import { AnyAction } from 'redux';
import _ from 'lodash';
import * as types from '../types/menu.type';

const initialState = (() => ({
  menus: [],
  root: [],
  leagueDetails: {},
  buttonConfig: {
    status: false,
    type: '',
    name: '',
  }, // 菜单按钮
  menusConfig: {},
}))();

const flatMapDeep = (A: any[], B: { [s: string]: any }) => {
  A.forEach((a: any) => {
    if (a.config) {
      // eslint-disable-next-line no-eval
      B[a.columnId] = eval(`(${a.config})`);
    } else {
      B[a.columnId] = null;
    }
    if (a.children && a.children.length > 0) {
      flatMapDeep(a.children, B);
    }
  });
};

const MenuReducer = (state = { ...initialState }, { type, payload }: AnyAction) => {
  const flatten: { [s: string]: any } = {};
  switch (type) {
    case types.GET_MENUS:
      flatMapDeep(_.cloneDeep(payload), flatten);
      return {
        ...state,
        menus: payload,
        menusConfig: flatten,
      };
    case types.GET_ROOT:
      return {
        ...state,
        root: payload,
      };
    case types.LEAGUEID_DETAILS:
      return {
        ...state,
        leagueDetails: payload,
      };
    case types.BUTTON_CONFIG:
      return {
        ...state,
        buttonConfig: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default MenuReducer;
