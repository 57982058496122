import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Menu as HMenu, Button, Icon } from 'antd';
import { getRoot, updateButtonStatus, isNoticeByMenus } from '../../redux/action/menu.action';
import {
  NormalObj,
  getSearchParams,
  stringifySearchParams,
  getLeagueId,
  getUserId,
  getToken,
  linkColumnUrl,
} from '../../util';
import { recurdionGetFirstChild } from '../../util/menusFn';
import TrackRequest from '../../lib/trackRequest';
import API from './../../config/api';
import NavMore from '../../static/images/nav_more.svg';
import Breadcrumb from '../../components/Breadcrumb';

import './menu.less';

class Menu extends React.PureComponent<NormalObj, any> {
  searchParams: NormalObj;
  private Root: any[]; // 权限
  private previousKey: string; // 上一个栏目key值
  private menuDom: any = createRef();
  private navDomTable: { [s: string]: any } = Object.create(null);
  private navDomDistance: { [s: string]: number } = Object.create(null);
  private menuContainterW = 0;
  private menuLists: NodeListOf<Element> | null = null;
  private ele: any = null;
  private resizeTimer: number | null = null;
  private exsitResizeListener = false;
  constructor(props: NormalObj) {
    super(props);
    this.searchParams = getSearchParams(props.location.search);
    this.state = {
      selectedKeys: [this.searchParams.parentId || ''],
      fixed: false, // 是否固定栏目
      hiddenMenus: [], // 隐藏的栏目
      posX: 0,
    };
    this.wrapHandleClick = this.wrapHandleClick.bind(this);
    this.Root = [];
    this.previousKey = this.searchParams.parentId || '';
  }
  componentDidMount() {
    this.getRourseRoot();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  UNSAFE_componentWillReceiveProps(nextProps: NormalObj) {
    this.searchParams = getSearchParams(nextProps.location.search);
    const { selectedKeys } = this.state;
    if (this.searchParams.parentId !== selectedKeys[0]) {
      this.previousKey = this.searchParams.parentId || '';
      this.setState({
        selectedKeys: [this.searchParams.parentId || ''],
      });
    }
  }
  // 获取当前资源权限
  async getRourseRoot() {
    const rootNav: any[] = [];
    const resourceRoot: any[] = []; // 课程权限
    const noticeRoot: any[] = []; // 公告权限

    this.Root = await getRoot(1);
    const isNoticeSyncRoot = await isNoticeByMenus(); // 是否存在公告同步权限
    const isNoticeCreateRoot = this.getIsExistRoot('notice:*'); // 是否存在公告创建权限
    const isResourceSyncRoot = this.getIsExistRoot('resource:sync'); // 是否存在课程同步权限
    const isResourceCreateRoot =
      this.getIsExistRoot('course:*') || this.getIsExistRoot('article:*'); // 是否存在课程创建权限
    const syncObj = {
      type: 'sync',
      name: '我的同步',
      number: '2',
      id: 2,
    }; // 同步权限配置
    const createObj = {
      type: 'create',
      name: '我的创建',
      number: '0',
      id: 1,
    }; // 创建权限配置

    // 同步权限
    if (isNoticeSyncRoot) {
      noticeRoot.push({ ...syncObj });
      rootNav[0] = { ...syncObj };
    }
    if (isResourceSyncRoot) {
      resourceRoot.push({ ...syncObj, active: false });
      rootNav[0] = { ...syncObj };
    }
    // 创建权限
    if (isNoticeCreateRoot) {
      noticeRoot.push({ ...createObj });
    }
    if (isResourceCreateRoot) {
      resourceRoot.push({ ...createObj, active: false });
    }
    if (isNoticeCreateRoot || isResourceCreateRoot) {
      rootNav.push({ ...createObj });
    }

    this.props.updateRootOption([...rootNav], [...noticeRoot], [...resourceRoot]);
    this.props.updateCurrentColumnId(this.searchParams.id); // 初始化当前栏目id
  }

  /**
   * 判断公告权限是否存在
   */
  getIsExistRoot(rootName: string) {
    return this.Root.findIndex((item: NormalObj) => item.code === rootName) > -1;
  }

  handleClick(args: any) {
    if (!this.props.isOperation) return null;

    const { item, key } = args;
    const data = JSON.parse(item.props.className);
    let columns_link = '';
    if (data.type === 2) {
      columns_link = JSON.parse(data.config).url;
    }
    TrackRequest({ name: 'click_columns', data: { columns_id: data.columnId, columns_link } });
    const linkFlag = this.linkToResourceOrOrg(data);
    if (linkFlag) {
      return null;
    }

    if (this.previousKey !== key) {
      updateButtonStatus({ status: false, type: '', name: '' });
      this.previousKey = key;
    }
    this.props.updateCurrentColumnId(key);

    const firstChildInfo = this.getClickColumnFirstChild(data, key);
    this.setGlobalPrivilege();
    this.updatePrivilegeBtnStatus();
    this.linkToClickColumns(firstChildInfo, key);
  }

  wrapHandleClick = (params: any) => {
    const { hiddenMenus } = this.state;
    const clickMenuIsHidden = hiddenMenus.find((h: any) => h.id === params.key);
    if (clickMenuIsHidden) {
      this.onSelectHiddenMenu(clickMenuIsHidden);
    } else {
      this.handleClick(params);
    }
  };

  // 设置全局权限
  setGlobalPrivilege(): void {
    const rootType = this.props.currentRootType;
    // 全局权限
    const ROOTTYPE = window.localStorage.getItem('ROOTTYPE');
    if (ROOTTYPE) {
      const k = this.props.rootOption.findIndex((r: any) => r.type === ROOTTYPE);
      k === -1 && window.localStorage.setItem('ROOTTYPE', rootType);
    } else {
      window.localStorage.setItem('ROOTTYPE', rootType);
    }
  }
  // 更新当前权限按钮状态
  updatePrivilegeBtnStatus() {
    const ROOTTYPE = window.localStorage.getItem('ROOTTYPE');
    const rootType = this.props.currentRootType;

    if (!!ROOTTYPE && ROOTTYPE !== rootType) {
      this.props.updateCurrentRootType(ROOTTYPE);
    }
  }
  // 跳转链接
  linkToResourceOrOrg(clickColumn: any): boolean {
    const leagueId = getLeagueId();
    if (clickColumn.type === 2) {
      const { access_token, userId } = this.getMainUserInfo();
      const config = JSON.parse(clickColumn.config);
      if (access_token) {
        const url = config.url.split('?');
        const mergeParams = url.slice(1).join('?');
        const params = mergeParams ? `&${mergeParams}` : '';
        const link = `${url[0]}?parentId=${
          clickColumn.columnId
        }&mainUserId=${userId}&currentUserId=${
          this.getCurrentUserInfo().userId
        }&token=${access_token}${params}`;
        window.open(linkColumnUrl(link));
      }
      return true;
    }
    // 兼容旧代码
    else if (clickColumn.name === '年级报告') {
      if (leagueId === '362544224887681024') {
        const { access_token, userId } = this.getMainUserInfo();
        if (access_token) {
          const link = `${API.ebi}/gradeLeader?userId=${userId}&token=${access_token}&dashboardId=505398479082749952`;
          window.open(link);
        }
        return true;
      }
      return false;
    } else if (clickColumn.name === '学科报告') {
      if (leagueId === '362544224887681024') {
        const { access_token, userId } = this.getMainUserInfo();
        if (access_token) {
          const link = `${API.ebi}/preparationLeader?userId=${userId}&token=${access_token}&dashboardId=505404481131773952`;
          window.open(link);
        }
        return true;
      }
      return false;
    } else if (clickColumn.name === '月度报告') {
      const { access_token, userId } = this.getCurrentUserInfo();
      if (leagueId === '362544042724864000') {
        if (access_token) {
          const link = `${API.ebi}/MiddleAndPrimarySchool?userId=${userId}&studySectionCode=CZ&token=${access_token}&dashboardId=508593017049841664`;
          window.open(link);
        }
        return true;
      }
      if (leagueId === '362544095002669056') {
        if (access_token) {
          const link = `${API.ebi}/MiddleAndPrimarySchool?userId=${userId}&studySectionCode=XX&token=${access_token}&dashboardId=508593152811073536`;
          window.open(link);
        }
        return true;
      }
      return false;
    }

    return false;
  }
  // 获取主用户信息
  getMainUserInfo(): { access_token: any; userId: any; orgId: any } {
    let userInfo = window.localStorage.getItem('mainUserInfo');
    userInfo = userInfo ? JSON.parse(userInfo) : { access_token: '', userId: '', orgId: '' };
    const { access_token, userId, orgId } = userInfo as any;
    return {
      access_token,
      userId,
      orgId,
    };
  }
  // 获取当前用户信息
  getCurrentUserInfo(): { access_token: any; userId: any } {
    return {
      access_token: getToken(),
      userId: getUserId(),
    };
  }
  // 跳转所选栏目
  linkToClickColumns(clickColumnChildInfo: any, key: string) {
    const { history } = this.props;
    const { id, child } = clickColumnChildInfo;
    const search = {
      id,
      name: child ? (child as any).name : '',
      type: child ? (child as any).type : -1,
      parentId: key,
    };
    history.push(`/navigation${stringifySearchParams(search)}`);
  }
  // 获取点击栏目下第一个子栏目信息
  getClickColumnFirstChild(
    clickColumn: any,
    key: string,
  ): { id: string; child: any; name: string; type: number } {
    const { children } = clickColumn;
    let acheResult = { id: '', child: {}, name: '', type: -1 };
    if (children && children.length > 0) {
      /**
       * 获取子元素下除链接外的第一个栏目
       */
      for (let i = 0, l = children.length; i < l; i++) {
        acheResult = recurdionGetFirstChild(children[i]);
        if (!!acheResult.id && !!acheResult.child && acheResult.type !== 2) {
          break;
        }
      }
    } else {
      acheResult = { id: key, child: clickColumn, name: clickColumn.name, type: clickColumn.type };
    }
    return acheResult;
  }

  updatePosX = (dx: number, params: { key: string; item: any; name: string }, type: string) => {
    const _selectedKeys: { [s: string]: string[] } = {};
    const especialMenu = ['年级报告', '学科报告', '月度报告'];
    const isEspecialMenu = especialMenu.some((m: string) => m === params.name);
    if (!isEspecialMenu && type !== '2') {
      _selectedKeys.selectedKeys = [params.key];
    }
    this.setState(
      {
        posX: dx,
        ..._selectedKeys,
      },
      () => {
        this.countHiddenMenuDom(Math.abs(dx));
        this.handleClick(params);
      },
    );
  };

  moveRight = (posX: number, x: number): number => {
    const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
    const dx = ((12 + posX) * 100000 + Math.abs(x) * 100000 - scrollLeft * 100000) / 100000;
    return dx;
  };

  moveLeft = (absoluteX: number, w: number): number => {
    const dx = ((absoluteX + 24 + w) * 100000 - this.menuContainterW * 100000) / 100000;
    return dx;
  };

  navDomParams = (id: string) => {
    const { x, width } = this.navDomTable[id].getBoundingClientRect();
    const selectDomAbsoluteX = this.navDomDistance[id];
    return { x, width, selectDomAbsoluteX };
  };

  onSelectHiddenMenu = (menuInfo: any) => {
    const { id, type, name } = menuInfo;
    const { posX } = this.state;
    const { x, selectDomAbsoluteX, width } = this.navDomParams(id);
    const { children } = this.menuDom.current.props;
    const selectDom = children.find((c: any) => c.key === id);
    const params = { key: id, item: selectDom, name };
    if (menuInfo.dire === 'left') {
      const dx = this.moveRight(posX, x);
      this.updatePosX(dx, params, type);
    } else {
      const dx = this.moveLeft(selectDomAbsoluteX, width);
      this.updatePosX(-dx, params, type);
    }
  };

  countHiddenMenuDom = (dx: number) => {
    if (this.menuLists) {
      let count = 0;
      const hiddenMenus: any[] = [];
      this.menuLists.forEach((list: any) => {
        const { menu_id: menuId, menu_type: menuType } = list.dataset;
        const listWidth = list.getBoundingClientRect().width;
        count += listWidth + 24;
        const _dx = (count * 100000 - dx * 100000) / 100000;
        if (_dx - (listWidth + 24) < 0) {
          hiddenMenus.push({ id: menuId, name: list.innerText, type: menuType, dire: 'left' });
        } else if (count > this.menuContainterW + dx) {
          hiddenMenus.push({ id: menuId, name: list.innerText, type: menuType, dire: 'right' });
        }
      });
      this.setState({ hiddenMenus });
    }
  };

  resetPosxWhenCurrentMenuIsHidden = (currentSeletedMenu: any) => {
    const { id } = currentSeletedMenu;
    const { width, selectDomAbsoluteX } = this.navDomParams(id);
    const dx = this.moveLeft(selectDomAbsoluteX, width);
    this.setState(
      {
        posX: -dx,
      },
      () => {
        this.countHiddenMenuDom(Math.abs(dx));
      },
    );
  };

  initHiddenMenuDom = () => {
    const { selectedKeys } = this.state;
    this.menuContainterW = (this.ele.offsetWidth * 100000 - 64 * 100000) / 100000;
    this.menuLists = document.querySelectorAll('[data-menu_id]');
    const hiddenMenus: any[] = [];
    let count = 0;
    let preW = 0;
    this.menuLists.forEach((list: any) => {
      const { menu_id: menuId, menu_type: menuType } = list.dataset;
      const currentWidth = list.getBoundingClientRect().width;
      count += currentWidth + 24;
      this.navDomDistance[menuId] = preW;
      preW += 24 + currentWidth;
      if (count > this.menuContainterW) {
        hiddenMenus.push({ id: menuId, name: list.innerText, type: menuType, dire: 'right' });
      }
      this.navDomTable[menuId] = list;
    });
    const currentSeletedMenu = hiddenMenus.find((h: any) => h.id === selectedKeys[0]);
    if (currentSeletedMenu) {
      this.resetPosxWhenCurrentMenuIsHidden(currentSeletedMenu);
    } else {
      this.setState({ hiddenMenus, posX: 0 });
    }
  };

  wrapInitHiddenMenuDom = () => {
    this.clearResizeTimer();
    this.resizeTimer = window.setTimeout(this.initHiddenMenuDom, 200);
  };

  clearResizeTimer = () => {
    if (this.resizeTimer) {
      window.clearTimeout(this.resizeTimer);
      this.resizeTimer = null;
    }
  };

  removeResizeListener = () => {
    if (!this.exsitResizeListener) return;
    this.exsitResizeListener = false;
    this.clearResizeTimer();
    window.removeEventListener('resize', this.wrapInitHiddenMenuDom);
  };

  getMenuDom = (ele: any) => {
    if (ele) {
      this.exsitResizeListener = true;
      this.ele = ele;
      this.initHiddenMenuDom();
      window.addEventListener('resize', this.wrapInitHiddenMenuDom);
    } else {
      this.removeResizeListener();
    }
  };

  renderMenus(data: NormalObj[]) {
    // todo 多次遍历，需优化
    const { selectedKeys, hiddenMenus, posX } = this.state;
    function renderChildMenus(data: NormalObj[]) {
      return (
        data.length > 0 &&
        data.map((item: NormalObj) => {
          return (
            <HMenu.Item
              data-menu_type={item.type}
              data-menu_id={item.columnId}
              key={item.columnId}
              className={JSON.stringify(item)}
            >
              {item.name}
            </HMenu.Item>
          );
        })
      );
    }
    return (
      <div className="menu-container" ref={this.getMenuDom}>
        <div className="menu-wrap">
          <div
            className={`menu-nav ${hiddenMenus.length === 0 && 'menu-center'}`}
            style={{ transform: `translate(${posX}px)` }}
          >
            <HMenu
              mode="horizontal"
              ref={this.menuDom}
              onClick={this.wrapHandleClick}
              selectedKeys={selectedKeys}
              className="head-menu"
            >
              {renderChildMenus(data)}
            </HMenu>
          </div>
        </div>
        {hiddenMenus.length > 0 && (
          <div className="display-btn">
            <img src={NavMore} alt="" />
            <div className="hidden-menu-container">
              <div className="hidden-menu">
                {hiddenMenus.map((m: any) => (
                  <span onClick={() => this.onSelectHiddenMenu(m)} key={m.id}>
                    {m.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  /**
   * 创建课程/文章
   * @param type 0:课程 1:文章
   */
  ceateButton() {
    const { buttonConfig } = this.props;
    if (buttonConfig.type === 'create_course') {
      window.open(`/createCourse${stringifySearchParams(this.searchParams)}`);
      // 创建课程跳转按钮埋点
      TrackRequest({ name: 'create_course', data: {} });
    } else if (buttonConfig.type === 'create_notice') {
      // 创建公告跳转按钮埋点
      TrackRequest({ name: 'create_notice', data: {} });
      this.searchParams.leftMenuType = 0;
      window.open(`/createArticle${stringifySearchParams(this.searchParams)}`);
    }
  }
  // 判断是否显示 面包屑
  isShowBreadcrumb = (breadcrumbLength: number) => {
    const { location } = this.props;
    const { pathname } = location;
    if (
      pathname === '/navigation' ||
      pathname === 'accountManage' ||
      pathname === '/accountManageDetail'
    ) {
      return false;
    }
    return breadcrumbLength > 0;
  };
  // 判断是否显示按钮
  isShowBtnGroup(buttonConfig: { [K in string]: any }) {
    return buttonConfig.status === true && this.searchParams.id !== 'kechengbiao';
  }
  // 获取面包屑数据
  getBreadcrumbData = () => {
    const { location } = this.props;
    const { pathname } = location;
    const { id, name, type, parentId } = this.searchParams;
    const data: NormalObj[] = [];
    // 默认加上第一级路由
    data.push({
      name,
      path: `/navigation${stringifySearchParams({ id, name, type, parentId })}`,
    });
    // 面包屑 一般从二级路由开始
    // 课程模块
    const course = this.getCourse(pathname);
    // 公告模块
    const article = this.getArticle(pathname);
    // 消息模块
    const message = this.getMessage(pathname);
    return [...data, ...course, ...article, ...message];
  };
  // 课程模块
  getCourse = (pathname: string) => {
    const { id, name, type } = this.searchParams;
    const data = [];
    // 创建课程
    if (pathname === '/createCourse' && id !== 'kechengbiao') {
      data.push({ name: '创建课程' });
    }
    // 课程管理
    if (pathname === '/courseManagement') {
      data.push({ name: '课程管理' });
    }
    if (pathname === '/CreateLesson' || pathname === '/lessonManagement') {
      const { courseId, parentId } = this.searchParams;
      data.push({
        name: '课程管理',
        path: `/courseManagement${stringifySearchParams({ id, name, courseId, type, parentId })}`,
      });
    }
    // 创建课时
    if (pathname === '/CreateLesson') {
      data.push({ name: '添加课时' });
    }
    // 课时管理
    if (pathname === '/lessonManagement') {
      data.push({ name: '课时管理' });
    }
    // 课程详情
    if (pathname === '/courseDetail') {
      data.push({ name: '课程详情' });
    }
    return data;
  };
  // 公告模块
  getArticle = (pathname: string) => {
    const data = [];
    // 创建公告
    if (pathname === '/createArticle') {
      data.push({ name: '创建公告' });
    }
    // 公告管理
    if (pathname.indexOf('/articleManagement') >= 0) {
      data.push({ name: '公告管理' });
    }
    // 公告预览
    if (pathname === '/articlePreview') {
      const { noticeId } = this.searchParams;
      if (noticeId) {
        // 编辑
        // path: `/articleManagement/${noticeId}${stringifySearchParams({ id, name, type, leftMenuType })}` }
        data.push({ name: '公告管理' });
      } else {
        // 创建
        // path: `/createArticle${stringifySearchParams({ id, name, type, leftMenuType })}`
        data.push({ name: '创建公告' });
      }
      data.push({ name: '公告预览' });
    }
    // 公告详情
    if (pathname === '/articleSyncPreview') {
      data.push({ name: '公告详情' });
    }
    return data;
  };

  // 消息模块
  getMessage = (pathname: string) => {
    const data: NormalObj[] = [];
    switch (pathname) {
      case '/message-lists':
        data.push({ name: '消息列表' });
        break;
      case '/message-detatils':
        data.push({ name: '消息列表', path: '/message-lists?d=true' }, { name: '消息详情' });
        break;
      default:
    }

    return data;
  };

  render() {
    const { menus, location, buttonConfig } = this.props;
    const { pathname } = location;
    const data = this.getBreadcrumbData();
    let height =
      this.searchParams.id === 'kechengbiao' ||
      (buttonConfig.status === false && pathname.indexOf('/navigation') >= 0)
        ? { height: '20px' }
        : {};
    height = pathname === '/statistics' ? { height: '20px' } : height;
    if (this.searchParams.fromSearch) return <div style={{ padding: 10 }} />;
    return (
      <div className="Menu-Component" id="MenuComponent">
        {this.renderMenus(menus)}
        {(this.isShowBreadcrumb(data.length) || this.isShowBtnGroup(buttonConfig)) && (
          <div className="bread-cumb" style={height}>
            <div>{this.isShowBreadcrumb(data.length) ? <Breadcrumb data={data} /> : ''}</div>
            <div className="create-button">
              {this.isShowBtnGroup(buttonConfig) ? (
                <Button type="primary" onClick={() => this.ceateButton()}>
                  <Icon
                    type="plus-circle"
                    theme="filled"
                    style={{ fontSize: '16px', verticalAlign: 'middle' }}
                  />
                  {this.searchParams.type === '0'
                    ? '创建课程'
                    : this.searchParams.type === '1'
                    ? '创建公告'
                    : ''}
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  rootOption: state.GloabalCreateSync.rootOption,
  currentRootType: state.GloabalCreateSync.currentRootType,
  getDataByRoot: state.GloabalCreateSync.getDataByRoot, // 根据权限获取数据
  isOperation: state.GloabalCreateSync.isOperation,
});
const mapDispatchToProps = (dispatch: any) => ({
  // 更新权限选项
  updateRootOption: (rootOption: any[], noticeRootOption: any[], resourceRootOption: any[]) => {
    dispatch({
      type: 'ROOTOPTION',
      payload: {
        rootOption,
        noticeRootOption,
        resourceRootOption,
      },
    });
  },
  // 更新所选栏目id
  updateCurrentColumnId: (columnId: string) => {
    dispatch({ type: 'UPDATEADDRESSBARID', payload: columnId });
  },
  // 更新权限按钮状态
  updateCurrentRootType: (type: string) =>
    dispatch({ type: 'GLOBALCREATESYNCTYPE', payload: type }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
