import React from 'react';
import { MyIcon, formateMinutes } from '../../../util';
import { Empty } from 'antd';

export function Marks(props: any) {
  const { marks, onTimeChanged } = props;
  if (marks.length > 0) {
    return (
      <ul className="ant-timeline">
        {marks.map((item: any, index: number) => (
          <li
            className="ant-timeline-item"
            key={index}
            onClick={() => onTimeChanged(item.timeTick)}
          >
            <div className="lint-cont">
              <div className="ant-timeline-item-tail" />
              <div className="big-circle " />
              <div className="small-circle" />
            </div>
            <div className="ant-timeline-item-content">
              <span>{formateMinutes(item.timeTick)}</span>
              <span>{item.description}</span>
            </div>
          </li>
        ))}
      </ul>
    );
  } else {
    return (
      <Empty
        className="ant-empty-normal custom-empty"
        description="暂无内容"
        image={<MyIcon className="empty-icon" type="iconcontent_null" />}
      />
    );
  }
}
