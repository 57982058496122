/*
 * @Descripttion:
 * @Author: gouyang
 * @Date: 2020-08-28 16:58:01
 * @LastEditors: gouyang
 * @LastEditTime: 2021-01-22 17:25:49
 */
import { AnyAction } from 'redux';
import * as types from '../types/live.type';

const initialState = (() => ({
  lesson: {},
  live: {},
  reviews: {},
  subjectIds: [], // 学科id
  teacherDisabled: true, // 老师禁用控制
}))();
const Live = (state = { ...initialState }, { type, payload }: AnyAction) => {
  switch (type) {
    case types.LIVE_INFO:
      return {
        ...state,
        live: payload,
      };
    case types.LESSON_INFO:
      return {
        ...state,
        lesson: payload,
      };
    case types.REVIEW_INFO:
      return {
        ...state,
        reviews: payload,
      };
    case types.UPDATE_SUBJECTIDS:
      return {
        ...state,
        subjectIds: payload,
      };
    case types.UPDATE_TEACHER_DISABLED:
      return {
        ...state,
        teacherDisabled: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Live;
