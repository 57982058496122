// 课程管理
import React from 'react';
import { Tabs } from 'antd';
import { RouteComponentProps } from 'react-router';
import { NormalObj, getSearchParams } from '../../util';
// 课程信息
import CreateCourse from './createCourse';
// 课时列表
import LessonList from '../Lesson/lessonList';

import './style.less';

type P = RouteComponentProps;
interface S {
  activeKey: string;
}
class CourseManagement extends React.PureComponent<P, S> {
  searchParams: NormalObj;
  constructor(props: P) {
    super(props);
    this.state = {
      activeKey: 'courseInfo',
    };
    this.searchParams = getSearchParams(props.location.search);
  }
  componentDidMount() {
    try {
      const url = window.location.hash;
      const key = url.split('=')[1];
      if (key) {
        this.setState({
          activeKey: key,
        });
      }
    } catch (error) {
      // 回调
    }
  }

  change = (value: any) => {
    this.setState({
      activeKey: value,
    });
    window.history.replaceState(null, '', `#status=${value}`);
  };
  render() {
    const { activeKey } = this.state;
    const { courseId } = this.searchParams;
    return (
      <div className="coursePage courseManagement">
        <div className="content" style={{ paddingTop: 0, paddingBottom: '1px' }}>
          <Tabs activeKey={activeKey} animated={false} onChange={this.change}>
            <Tabs.TabPane tab="课程基本信息" key="courseInfo">
              <CreateCourse courseId={courseId} history={this.props.history} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="关联课时" key="lessonList">
              <LessonList courseId={courseId} history={this.props.history} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default CourseManagement;
