/* eslint-disable @typescript-eslint/no-invalid-void-type */
import React, { Component } from 'react';
import Alerts from './Alert';
import './Alert.less';

interface P {
  text: string;
  callback?: () => void | null;
  type?: string;
}
interface S {
  visible: boolean;
}
class Alert extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      visible: true,
    };
    this.alertOk = this.alertOk.bind(this);
  }
  componentDidMount() {
    if (this.props.type === 'black' && this.props.callback) {
      setTimeout(this.alertOk, 1000);
    }
  }
  alertOk = () => {
    this.setState({ visible: false }, () => {
      if (this.props.callback) {
        this.props.callback();
      }
      Alerts.remove('');
      // let modalNode = document.getElementById('alertModal');
      // if (modalNode) {
      //     document.body.removeChild(modalNode);
      // }
    });
  };
  render() {
    const { text, type } = this.props;
    const { visible } = this.state;
    return (
      <div>
        {visible ? (
          <div>
            <div className="ant-modal-mask ant-modal-mask-special" />
            <div
              className={`ant-modal-wrap ${type} alert-wrap ant-modal-wrap-special`}
              role="dialog"
            >
              <div role="document" className="ant-modal" style={{ width: 520 }}>
                <div style={{ width: 0, height: 0, overflow: 'hidden' }} />
                <div className="ant-modal-content">
                  <div className="ant-modal-body">
                    <div className="title">{text}</div>
                    <div className="control">
                      <div className="btn" onClick={this.alertOk}>
                        我知道了
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: 0, height: 0, overflow: 'hidden' }} />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Alert;
