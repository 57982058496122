import React, { useState, useEffect } from 'react';
import { Button, Empty } from 'antd';
import { connect } from 'react-redux';
import { MyIcon, mergeProps, NormalObj } from '../../../../util';
import DownloadItem from '../../../../components/LessonItem/DownloadItem';
import { hasUnion } from '../../../../util/prohibitShow';
import { permitDownload } from '../../../../util/permitDownload';

interface P {
  resourceInfo: {
    resourceGuid: string;
    lessonId: string;
    courseId: string;
    name: string;
    category: number;
    expiryDate: string | null;
    expiryState: boolean;
    files: any[];
    highLightName: string;
  };
  functionConfig: NormalObj;
  goToPreview: (data: any) => void;
}

function ResLists(props: P) {
  const [isOpened, setOpen] = useState(false);
  const [resourceStatus, setResourceStatus] = useState({
    hasResource: true,
    hasDownload: false,
  });
  const [downloadAll, setDownloadAll] = useState(false);
  const {
    resourceInfo,
    goToPreview,
    functionConfig: { downloadFile = false },
  } = props;
  const { resourceGuid, lessonId, courseId, name, category, files, expiryState, highLightName } =
    resourceInfo;

  useEffect(() => {
    if (files) {
      let s = true;
      if (hasUnion()) s = !files.every((file: any) => file.extension.toLowerCase() === 'mp4');
      setResourceStatus({
        hasResource: files.length > 0,
        hasDownload: s,
      });
    } else {
      setResourceStatus({
        hasResource: false,
        hasDownload: true,
      });
    }
  }, [files]);

  const onDownload = (e: any) => {
    e.stopPropagation();
    if (!isOpened) setOpen(!isOpened);
    setDownloadAll(true);
  };

  const getType = (category: number) => {
    switch (category) {
      case 1:
        return 'iconmp';
      case 2:
        return 'iconcourseware';
      case 3:
        return 'iconmaterial';
      case 4:
        return 'iconteaching_plan';
      case 5:
        return 'iconoperation';
      case 6:
        return 'icontest_paper';
      default:
        return 'iconunknown';
    }
  };

  const openFileLists = () => {
    if (!expiryState) {
      setOpen(!isOpened);
    }
  };
  const { hasResource, hasDownload } = resourceStatus;
  return (
    <div className="package commom-item" onClick={openFileLists}>
      <div className={`package-info ${expiryState ? 'expired' : 'effective'}`}>
        <span className="package-icon">
          <MyIcon className="iconfont" type={getType(category)} />
        </span>
        <span
          className="package-name"
          title={name}
          dangerouslySetInnerHTML={{ __html: highLightName }}
        />
        {expiryState ? (
          <MyIcon className="expired-icon" type="iconziyuan" />
        ) : (
          hasDownload &&
          downloadFile &&
          !permitDownload() && (
            <Button type="primary" onClick={onDownload}>
              一键下载
            </Button>
          )
        )}
      </div>
      <div className="package-item-list" style={{ display: isOpened ? 'block' : 'none' }}>
        {hasResource ? (
          files.map((item: any, index: number) => (
            <DownloadItem
              downloadEntrance={3}
              watchEntrance={3}
              key={index}
              data={Object.assign(item, { resourceGuid, lessonId, courseId, category })}
              lessonId={lessonId}
              goToPreview={goToPreview}
              downloadAll={downloadAll}
              onCallback={() => setDownloadAll(false)}
            />
          ))
        ) : (
          <Empty
            className="ant-empty-normal custom-empty"
            description="暂无内容"
            image={<MyIcon className="empty-icon" type="iconcontent_null" />}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  functionConfig: { ...state.DynamicControl.functionConfig },
});

export default React.memo(connect(mapStateToProps, mergeProps)(ResLists));
