/* eslint-disable @typescript-eslint/no-unused-vars */
// 基础信息
import React from 'react';
import { connect } from 'react-redux';
// import _ from 'lodash';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Select, Row, Col, Radio } from 'antd';
import {
  getStudySectionId,
  getSubjectId,
  isPingguoUnion,
  getSchoolYear,
  getPingguoSchoolYear,
  getSubjectList,
  computeGrades,
} from '../../../util';
import { getChangeSubject } from '../../../redux/action/lesson.action';
import { getStudySections, getGrades } from '../../../redux/action/base.action';
import { ReleaseType } from '../../../util/enum';
import { isEqual } from 'lodash';
// import './style.less';

interface P extends FormComponentProps {
  isEdit: boolean;
  gradesList: any; // 年级列表
  studySectionsList: any; // 学段列表

  grades: any[];
  releaseType: ReleaseType;
  studySectionCode: string; // 学段
  studySectionName: string;
  schoolYear: string; // 学年
  subjects: any[]; // 学科

  reload: any;
  updateSubjectIds: (ids: string[]) => void;
}
interface S {
  // isFillFLg: boolean; // 判断是否填充过数据了
  visible: boolean; // 弹出框控制显示
  subjects: string[]; // 学科id
  grades: string[]; // 年级id
  subjectsList: any; // 学科列表
}
// 学年列表
const { schoolYears: _schoolYears, curSchoolYear } = getSchoolYear(4, 1);
const schoolYears = isPingguoUnion() ? getPingguoSchoolYear() : _schoolYears;
class PublishInformation extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      // isFillFLg: false,
      visible: false,
      subjects: [],
      grades: [],
      subjectsList: [],
    };
  }
  componentDidMount() {
    const studySectionCode = getStudySectionId();
    getGrades(studySectionCode); // 获取年级列表
    getStudySections(); // 学段
    this.initData();
  }
  // 初始化数据填充
  initData = () => {
    const { updateSubjectIds } = this.props;
    const { grades, releaseType, studySectionCode, schoolYear, subjects } = this.props;
    // 获取 学科id 列表， 和学科列表
    const subjectIds = getSubjectId();
    const subjectsList = getSubjectList();

    // 单学科
    this.setState({
      subjects: subjectIds,
      subjectsList,
    });
    updateSubjectIds([...subjectIds]);
    // 通知站点资源模块 已选择学科
    getChangeSubject(subjectIds);

    // 平果联盟默认学年选最后一项
    const year = isPingguoUnion() ? schoolYears[schoolYears.length - 1] : curSchoolYear;
    this.props.form.setFieldsValue({
      releaseType,
      schoolSection: studySectionCode || getStudySectionId(),
      grades: grades ? grades?.map((t: any) => t.code) : this.schoolYearChange(year),
      subjects: subjects ? subjects?.map((t: any) => t.code) : [],
      schoolYear: schoolYear || year,
    });
  };

  componentWillUnmount() {
    getChangeSubject([]);
    this.props.reload();
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // 填充数据
    const { grades, releaseType, studySectionCode, studySectionName, schoolYear, subjects, time } =
      nextProps;

    // 填充基础信息
    if (
      // isEdit &&
      !isEqual(
        {
          grades,
          releaseType,
          studySectionCode,
          studySectionName,
          schoolYear,
          subjects,
        },
        {
          grades: this.props.grades,
          releaseType: this.props.releaseType,
          studySectionCode: this.props.studySectionCode,
          studySectionName: this.props.studySectionName,
          schoolYear: this.props.schoolYear,
          subjects: this.props.subjects,
        },
      )
    ) {
      const year = isPingguoUnion() ? schoolYears[schoolYears.length - 1] : curSchoolYear;

      this.props.form.setFieldsValue({
        releaseType,
        schoolSection: studySectionCode || getStudySectionId(),
        grades: grades.map((t: any) => t.code) || this.schoolYearChange(year),
        subjects: subjects.map((t: any) => t.code) || [],
        schoolYear: schoolYear || year,
      });
    }
  }

  // 学年发生变化
  schoolYearChange = (value: any) => {
    const curYear = parseInt(curSchoolYear.split('-')[1], 10);
    const selYear = parseInt(value.split('-')[1], 10);
    // 如果选择的学年是当前学年以前的学年，直接清空年级；
    if (selYear < curYear) {
      this.props.form.setFieldsValue({
        grades: [],
      });
      this.setState({ grades: [] });
      return [];
    }
    const studySectionCode = getStudySectionId();
    const grades = computeGrades(studySectionCode, value);
    this.props.form.setFieldsValue({ grades });
    this.setState({ grades });
    return grades;
  };

  // 获取数据
  getItemsValue = () => {
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      this.props.form.validateFieldsAndScroll((error: any, value: any) => {
        if (!error) {
          const { subjectsList } = this.state;
          const { subjects, grades } = value;
          const { gradesList, studySectionsList } = this.props;
          const data: any = { ...value };

          // 年级
          const grade_list = [];
          for (const i in gradesList) {
            if (Object.prototype.hasOwnProperty.call(gradesList, i)) {
              for (const j in grades) {
                if (gradesList[i].code === grades[j]) {
                  grade_list.push({
                    code: gradesList[i].code,
                    name: gradesList[i].name,
                  });
                }
              }
            }
          }
          data.grades = grade_list;

          // 学科
          // const { subjects } = data;
          const subject_list = [];
          for (const i in subjectsList) {
            if (Object.prototype.hasOwnProperty.call(subjectsList, i)) {
              for (const j in subjects) {
                if (subjects[j] === subjectsList[i].code) {
                  subject_list.push({
                    code: subjectsList[i].code,
                    name: subjectsList[i].name,
                  });
                }
              }
            }
          }
          data.subjects = subject_list;
          data.studySectionCode = value.schoolSection;
          data.studySectionName = studySectionsList.find(
            (t: any) => t.code === value.schoolSection,
          )?.name;
          resolve(data);
        } else {
          reject('请选择公告面向用户的属性！');
        }
      });
    });
  };

  partInfo = () => {
    const { subjectsList } = this.state;
    const { gradesList, studySectionsList } = this.props;
    const { getFieldDecorator } = this.props.form;
    const releaseType = this.props.form.getFieldValue('releaseType');

    return (
      <div style={{ display: releaseType === ReleaseType.ALL ? 'none' : 'block' }}>
        <Row>
          <Col span={6}>
            <Form.Item {...formLayout1} label="学段">
              {getFieldDecorator('schoolSection', {
                rules: [
                  {
                    required: releaseType === ReleaseType.PART,
                    message: '请选择学段！',
                  },
                ],
              })(
                <Select placeholder="请选择" disabled>
                  {studySectionsList.map((item: any) => (
                    <Select.Option value={item.code} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item {...formLayout1} label="学年">
              {getFieldDecorator('schoolYear', {
                rules: [
                  {
                    required: releaseType === ReleaseType.PART,
                    message: '请选择学年！',
                  },
                ],
              })(
                <Select placeholder="请选择" onChange={this.schoolYearChange}>
                  {schoolYears.map((item: string, index: number) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...formLayout} label="年级">
              {/* <span style={{ minWidth: 192, display: 'inline-block' }}> */}
              <div className="autoSelectWidth">
                {getFieldDecorator('grades', {
                  rules: [
                    {
                      required: releaseType === ReleaseType.PART,
                      message: '请选择年级！',
                    },
                  ],
                })(
                  <Select mode="multiple" placeholder="请选择" style={{ minWidth: 192 }}>
                    {gradesList.map((item: any) => (
                      <Select.Option value={item.code} key={item.code}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </div>
              {/* </span> */}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item {...formLayout} label="学科">
          {/* <span style={{ minWidth: 192, maxWidth: 780, display: 'inline-block' }}> */}
          <div className="autoSelectWidth">
            {getFieldDecorator('subjects')(
              <Select mode="multiple" placeholder="请选择" style={{ minWidth: 192, maxWidth: 780 }}>
                {subjectsList.map((item: any) => (
                  <Select.Option value={item.code} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </div>
          {/* </span> */}
          <span className="subject-tips">若不选学科则默认为全部学科</span>
        </Form.Item>
      </div>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="model" id="information">
        <h3>发布信息</h3>
        <div className="information">
          <Form>
            <Form.Item {...formLayout} label="发布范围">
              {getFieldDecorator('releaseType', {
                rules: [
                  {
                    required: true,
                    message: '请选择公告面向用户的属性！',
                  },
                ],
              })(
                <Radio.Group>
                  <Radio value={1}>全部</Radio>
                  <Radio value={2}>部分</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            {this.partInfo()}
          </Form>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 20 },
};
const formLayout1: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 16 },
};
const mapStateToProps = (state: any) => ({
  totalPages: state.Base.totalPages,
  studySectionsList: state.Base.studySectionsList,
  gradesList: state.Base.gradesList,
});

const mapDispatchToProps = (dispatch: any) => ({
  reload: () => {},
  updateSubjectIds: (subjectIds: string[]) => {
    dispatch({ type: 'UPDATE_SUBJECTIDS', payload: subjectIds });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<P>()(PublishInformation));
