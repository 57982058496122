/*
 * @Author: gouyang
 * @Description: 创建文章action
 * @Date: 2019-07-16 09:47:15
 */
import { message } from 'antd';
import Alert from '../../components/Alert/Alert';
import AxiosWrap from '../../lib/request';
import { dispatch } from '../../createStore';
import { ARTICLE_DATA, PUBLISH_RANGE } from '../types/article.type';
import { stringifySearchParams } from '../../util';

// 文章详情
export const editorArticle = async (id: string) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/manager/notices/${id}`, method: 'GET' });
    if (status === 200) {
      dispatch({ type: ARTICLE_DATA, payload: data });
      dispatch({ type: PUBLISH_RANGE, payload: data });
    }
  } catch (error) {
    if (error && error.status === 404) {
      Alert.open('公告已不存在！', undefined, 'black', 'classHour-detail');
      return;
    }
    if (error && error.status === 403) {
      Alert.open('暂无权限！', () =>
        (window as any)._history.push(
          `/userCente${stringifySearchParams({
            id: 'kechengbiao',
            name: '课程表',
            parentId: 'kechengbiao',
          })}`,
        ),
      );
      return;
    }
    if (error && error.data) {
      message.error(error.data.message);
    }
  }
};
