/*
 * @Descripttion: 百家云直播
 * @Author: gouyang
 * @Date: 2021-01-22 09:26:18
 * @LastEditors: gouyang
 * @LastEditTime: 2021-01-26 10:17:13
 */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import OpenLiveClient from '../../../../components/OpenLiveClient';
import IconFont from '../../../../components/IconFont';
import { getSearchParams, getUserId } from '../../../../util';
import { N, LiveBroadcastInfo, LiveUrl } from '../../interface';
import './index.less';

interface P {
  liveBroadcastInfo: LiveBroadcastInfo;
}

function LiveBroadcast(props: P) {
  const { liveBroadcastInfo } = props;
  const searchParams = useRef<N>(getSearchParams());
  const [playMode, setPlayMode] = useState<0 | 1 | -1>(-1); // 0 网页端 1 客户端
  const [url, setUrl] = useState<LiveUrl>({
    webUrl: '',
    clientUrl: '',
  });

  const createBjyLive = useCallback((info: LiveBroadcastInfo) => {
    const {
      live: { useType, teacherId },
      baijiayunRoom: { webUrl, clientUrl },
    } = info;
    const _useType = teacherId === getUserId() ? 1 : useType;
    setPlayMode(_useType);
    setUrl({
      webUrl,
      clientUrl,
    });
  }, []);

  const onModeChange = useCallback((mode: 0 | 1) => {
    setPlayMode(mode);
  }, []);

  useEffect(() => {
    if (liveBroadcastInfo.provider === 1) {
      createBjyLive(liveBroadcastInfo);
    }
  }, [liveBroadcastInfo, createBjyLive]);

  const { ignoreClose } = searchParams.current;
  return (
    <div className="live-broadcast">
      <div className="live-broadcast-content">
        {playMode === 1 && (
          <div className="client">
            <OpenLiveClient url={url.clientUrl} ignoreClose={!!ignoreClose} />
          </div>
        )}
        {playMode === 0 && (
          <iframe
            allow="microphone;camera;midi;encrypted-media;"
            title="百家云直播"
            src={url.webUrl}
          />
        )}
      </div>
      <div className="live-operation">
        <div className={`${playMode === 0 && 'show'} operation`} onClick={() => onModeChange(1)}>
          <IconFont className="icon-mode" type="iconzhibo_interactive" />
          <span className="mode-desc">切换至客户端模式</span>
        </div>
        <div className={`${playMode === 1 && 'show'} operation`} onClick={() => onModeChange(0)}>
          <IconFont className="icon-mode" type="iconzhibo_web" />
          <span className="mode-desc">切换至网页模式</span>
        </div>
        <p className="tips">
          若您当前使用的浏览器非chrome浏览器和360极速浏览器，为了更好的用户体验，建议您使用客户端进入直播间
        </p>
      </div>
    </div>
  );
}

export default memo(LiveBroadcast);
