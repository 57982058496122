/*
 * @Author: gouyang
 * @Description: 环境映射
 * @Date: 2019-08-16 13:14:39
 */
import gz from '../static/highSchoolImg/logo.png';
import cz from '../static/juniorMiddleSchoolImg/logo.png';
import xx from '../static/primarySchoolImg/logo.png';
import yey from '../static/kidergardenImg/logo.png';
import future from '../static/futureSchoolImg/logo-1.png';
import schoolInfo from './schoolInfo';

const env = (window as any).__UNION_CENTER_ENV__;
// 映射页面数据
const mapData: { [s: string]: any } = {
  'https://cdqzyc.open-school.com.cn': { router: '/cz', logo: cz, data: schoolInfo.middleSchool }, // 初中
  'https://cdeps.open-school.com.cn': { router: '/xx', logo: xx, data: schoolInfo.primarySchool }, // 小学
  'https://jg3you.open-school.com.cn': { router: '/yey', logo: yey, data: schoolInfo.kidergarden }, // 幼儿园
  'https://cdqz.open-school.com.cn': { router: '/cdqz', logo: gz, data: schoolInfo.highSchool }, // 高中
  'https://wlkt.open-school.com.cn': {
    router: '/future',
    logo: future,
    data: schoolInfo.futureSchool,
  }, // 未来课堂
  // 测试
  'https://cdqz.test.eastedu.ltd': { router: '/cdqz', logo: gz, data: schoolInfo.highSchool }, // 高中
  'https://cdqzyc.test.eastedu.ltd': { router: '/cz', logo: cz, data: schoolInfo.middleSchool }, // 初中
  'https://cdceps.test.eastedu.ltd': { router: '/xx', logo: xx, data: schoolInfo.primarySchool }, // 小学
  'https://jg3you.test.eastedu.ltd': { router: '/yey', logo: yey, data: schoolInfo.kidergarden }, // 幼儿园
  'https://wlkt.test.eastedu.ltd': {
    router: '/future',
    logo: future,
    data: schoolInfo.futureSchool,
  }, // 未来课堂
};
// 开发环境mapData key值映射
const mapDataKey: { [s: string]: string } = {
  '/cz': 'https://cdqzyc.open-school.com.cn', // 初中
  '/xx': 'https://cdeps.open-school.com.cn', // 小学
  '/yey': 'https://jg3you.open-school.com.cn', // 幼儿园
  '/cdqz': 'https://cdqz.open-school.com.cn', // 高中
  '/future': 'https://wlkt.open-school.com.cn', // 未来课堂
};

function mapPageData(): { router: string; logo: string; data: any } {
  let result;
  if (env === 'PROD' || env === 'STAGE' || env === 'TEST') {
    const { origin } = window.location;
    result = mapData[origin];
  } else {
    const { pathname } = window.location;
    const key = mapDataKey[pathname];
    result = mapData[key];
  }

  return result;
}

// 首页学校id映射link
mapPageData.prototype.idMapLink = () => {
  if (env === 'PROD') {
    return {
      gz: 'https://cdqz.open-school.com.cn',
      cz: 'https://cdqzyc.open-school.com.cn',
      xx: 'https://cdeps.open-school.com.cn',
      yey: 'https://jg3you.open-school.com.cn',
      future: 'http://wlkt.eastedu.com/website/download.aspx',
    };
  } else if (env === 'STAGE') {
    // 灰度
    return {
      gz: 'https://cdqz.open-school.com.cn',
      cz: 'https://cdqzyc.open-school.com.cn',
      xx: 'https://cdeps.open-school.com.cn',
      yey: 'https://jg3you.open-school.com.cn',
      future: 'http://wlkt.eastedu.com/website/download.aspx',
    };
  } else if (env === 'TEST') {
    // 测试
    return {
      gz: 'https://cdqz.test.eastedu.ltd',
      cz: 'https://cdqzyc.test.eastedu.ltd',
      xx: 'https://cdceps.test.eastedu.ltd',
      yey: 'https://jg3you.test.eastedu.ltd',
      future: 'http://wlkt.eastedu.com/website/download.aspx',
    };
  } else {
    return {
      gz: `${window.location.origin}/cdqz`,
      cz: `${window.location.origin}/cz`,
      xx: `${window.location.origin}/xx`,
      yey: `${window.location.origin}/yey`,
      future: 'http://wlkt.eastedu.com/website/download.aspx',
    };
  }
};
// 测试和开发域名列表
mapPageData.prototype.linkLists = () => {
  return [
    'https://cdqzyc.open-school.com.cn', // 初中
    'https://cdeps.open-school.com.cn', // 小学
    'https://jg3you.open-school.com.cn', // 幼儿园
    'https://cdqz.open-school.com.cn', // 高中
    'https://wlkt.open-school.com.cn', // 未来课堂
    // 测试
    'https://cdqz.test.eastedu.ltd', // 高中
    'https://cdqzyc.test.eastedu.ltd', // 初中
    'https://cdceps.test.eastedu.ltd', // 小学
    'https://jg3you.test.eastedu.ltd', // 幼儿园
    'https://wlkt.test.eastedu.ltd', // 未来课堂
  ];
};

export default mapPageData;
