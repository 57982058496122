/* eslint-disable no-case-declarations */
import * as types from '../types/course.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  classHourList: null, // 课时列表
  classHourDetail: {
    // 课时详情
    lessonId: null,
    lessonType: null,
    Information: null,
    setTime: null,
    materialChapters: null,
    live: null,
    material: null,
    onDemand: null,
    extensionData: null,
  },
  siteResources: {
    data: [],
    total: 0,
    page: 1,
  },
  subjectId: [],
  progressStatus: {
    mp4: true,
    ocrf: true,
    txt: true,
  },
};

const course = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.CLASSHOUR_LIST_REQUEST: // 获取课时列表
      return { ...state, classHourList: payload };
    case types.CLASSHOUR_DETAIL_REQUEST: // 课时详情
      return { ...state, classHourDetail: payload.classHourDetail };
    case types.RESOURCES_LIST_REQUEST: // 站点资源列表
      return { ...state, siteResources: payload };
    case types.CHANGE_SUBJECT_REQUEST: // 学科变化
      return { ...state, subjectId: payload };
    case types.CLEAR_DATA: // 清理数据
      state[payload] = initialState[payload];
      return { ...state };
    case types.UPDATE_PROGERSS_STATUS: // 更新进度条状态
      const { status, typeName } = payload;
      if (typeName) {
        let progressStatus: { [key: string]: boolean } = {};
        progressStatus[typeName] = status;
        progressStatus = { ...state.progressStatus, ...progressStatus };
        return { ...state, progressStatus };
      } else {
        return { ...state };
      }
    case types.CHANGE_LIST_SORT: // 改变课时顺序
      return { ...state, classHourList: payload };
    default:
      return state;
  }
};
export default course;
