import React from 'react';
import { Modal, Form } from 'antd';
import moment from 'moment';
import { FormComponentProps } from 'antd/es/form';

import TimeLimit from './TimeLimit';

interface P extends FormComponentProps {
  isOpen: boolean;
  data: any;
  type: number;
  closeModal: () => void;
  handleSubmit: () => void;
  wrappedComponentRef: (a: any) => void;
  onChange: (e: any) => void;
}

class SetExpireModal extends React.Component<P> {
  modalForm: any = null;

  disabledDate = (current: any) => {
    return current && current < moment().endOf('day');
  };

  render() {
    const { isOpen, handleSubmit, closeModal, data, onChange, type } = this.props;

    return (
      <Modal
        title="截止时间设置"
        width={600}
        visible={isOpen}
        destroyOnClose
        className="setExpireModal"
        onOk={handleSubmit}
        onCancel={closeModal}
      >
        <h3 className="info">
          截止时间过后，资料将处于失效状态，失效状态下不支持用户查看和下载资料。
        </h3>
        <TimeLimit
          wrappedComponentRef={(timeLimitRef: any) => {
            this.modalForm = timeLimitRef ? timeLimitRef.props.form : null;
          }}
          type={type}
          onChange={onChange}
          data={data}
          contentHeight={59}
        />
      </Modal>
    );
  }
}

export default Form.create<P>()(SetExpireModal);
