import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';
import { getQueryString, MyIcon } from '../../util';
import Tab from './components/Tab';
import SearchFilter from './components/SearchFilter';
import { SearchParams } from './lib/searchInterface';
import SearchResult from './components/SearchResult';
import { searchAjax } from '../../redux/action/globalSearch.action';
import { highLightFn } from './lib/highLight';
import './index.less';

function GlobalSearch(props: any) {
  const isKeywordSearch = useRef(true);
  const tempSearchParams = useRef({});
  const text = getQueryString('text');
  const category = getQueryString('category');
  const [isRenderFilter, setIsRenderFilter] = useState(false); // 是否渲染筛选条件
  const [renderInfo, setRenderInfo] = useState<any>({
    list: [], // 页面信息
    hasResourcesInfo: false, // 是否有页面信息
    total: 0,
  });
  const [current, SetCurrent] = useState(1);
  const {
    result,
    searchTimestamp,
    analyzesResult,
    clearFilterStatus,
    isKeywords,
    initialFilterStatus,
    loading,
  } = useSelector((store: any) => ({
    result: store.GlobalSearch.result,
    analyzesResult: store.GlobalSearch.analyzesResult,
    searchTimestamp: store.GlobalSearch.searchTimestamp,
    clearFilterStatus: store.GlobalSearch.clearFilterStatus,
    isKeywords: store.GlobalSearch.isKeywords,
    initialFilterStatus: store.GlobalSearch.initialFilterStatus,
    loading: store.GlobalSearch.loading,
  }));

  // 监听后退
  useEffect(() => {
    window.addEventListener('popstate', backFetch);
    return () => {
      window.removeEventListener('popstate', backFetch);
    };
  }, []);

  useEffect(() => {
    setData(result, analyzesResult, isKeywords);
  }, [result, analyzesResult, isKeywords]);

  useEffect(() => {
    SetCurrent(1);
    clearFilterStatus();
    initialFilterStatus();
  }, [searchTimestamp, clearFilterStatus, initialFilterStatus]);

  // 初始化当前tab页信息
  useEffect(() => {
    if (localStorage.getItem('ROOTTYPE') !== 'sync') {
      (window as any).location.href = '/';
      return;
    }
    searchAjax({ text, category, page: 0 }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setData = (data: any, analyzesData: string[], isKeywordSearch: boolean) => {
    const currentCategory = getQueryString('category');

    if (data._embedded && data._embedded.results) {
      const list = data._embedded.results.map((item: any) => {
        if (item.category === 1) {
          highLightFn(item.lesson, analyzesData, 1);
          return Object.assign(item, { ...item.lesson }, { cate: 1 });
        } else if (item.category === 3) {
          highLightFn(item.notice, analyzesData, 3);
          return Object.assign(item, { ...item.notice }, { cate: 3 });
        } else if (item.category === 0) {
          highLightFn(item.course, analyzesData, 0);
          return Object.assign(item, { ...item.course }, { cate: 0 });
        } else if (item.category === 2) {
          highLightFn(item.resource, analyzesData, 2);
          return Object.assign(item, { ...item.resource }, { cate: 2 });
        }
        return [];
      });
      setRenderInfo({
        list,
        hasResourcesInfo: true,
        total: data.page.totalElements,
      });
      if (isKeywordSearch) {
        const flag = !!(currentCategory && currentCategory !== '3');
        setIsRenderFilter(flag);
      }
    } else {
      setRenderInfo({
        list: [],
        hasResourcesInfo: false,
      });
      if (isKeywordSearch) {
        setIsRenderFilter(false);
      }
    }
  };

  const onChange = (page: any) => {
    SetCurrent(page);
    const text = getQueryString('text');
    const category = getQueryString('category');
    searchAjax({ ...tempSearchParams.current, page: page - 1, category, text });
    window.scrollTo(0, 0);
  };

  const onTabChange = (changeCategory: string) => {
    tempSearchParams.current = {};
    isKeywordSearch.current = true;
    clearFilterStatus();
    initialFilterStatus();
    const { history } = props;
    SetCurrent(1);
    const text = getQueryString('text');
    history.push(
      `/global-search?text=${encodeURIComponent(text)}&category=${changeCategory || ''}`,
    );
    searchAjax({ text, category: changeCategory, page: 0 }, true);
  };

  const onFilterChange = (params: SearchParams) => {
    isKeywordSearch.current = false;
    SetCurrent(1);
    searchAjax(params);
    tempSearchParams.current = params;
  };

  const backFetch = () => {
    const text = getQueryString('text');
    const category = getQueryString('category');
    SetCurrent(1);
    searchAjax({ text, category, page: 0 });
  };

  const { hasResourcesInfo, list, total } = renderInfo;

  return (
    <div className="global-search">
      <Tab onTabChange={onTabChange} />
      <div className="global-search-content">
        <div style={{ display: isRenderFilter ? 'block' : 'none' }} className="filter-wrap">
          <SearchFilter category={category} keywords={text} onFilterChange={onFilterChange} />
        </div>
        {hasResourcesInfo && (
          <>
            <SearchResult list={list} />
            <Pagination
              size="small"
              total={total}
              pageSize={20}
              current={current}
              onChange={onChange}
            />
          </>
        )}
        <div
          className="global-search-empty"
          style={{
            display: !hasResourcesInfo ? 'flex' : 'none',
            paddingTop: isRenderFilter ? '35px' : '150px',
          }}
        >
          {!loading && (
            <>
              <MyIcon className="empty-icon" type="iconsearch_null" />
              <p className="search-empty-desc">
                <span>抱歉，暂未查找到相关内容！</span>
                <span>可输入其他关键词进行查找</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GlobalSearch;
