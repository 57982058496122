/* eslint-disable no-case-declarations */
import * as types from '../types/base.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  teacherList: [], // 授课老师列表
  studySectionsList: [], // 学段列表
  subjectsList: [], // 学科列表
  gradesList: [], // 年级列表
  schoolYearList: [], // 学年列表
  schoolPeriodList: [], // 学届列表
  textBooks: [], // 教材列表
  chaptersTree: [], // 章节树
  filteredTeacherList: [], // 有权限授课老师列表
  hasPowerTeacher: [], // 有权限老师列表
};

const Base = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.UNSHIFT_TEACHER_LIST_REQUEST: // 将有权限授课老师unshift到老师列表
      return { ...state, teacherList: [...payload, ...state.teacherList] };
    case types.FILTERED_TEACHER_LIST_REQUEST:
      const { teachers, allTeachers } = payload;
      return { ...state, filteredTeacherList: teachers, hasPowerTeacher: allTeachers };
    case types.TEACHER_LIST_REQUEST_RELOAD: // 重载授课老师列表
      return { ...state, teacherList: payload };
    case types.TEACHER_LIST_REQUEST: // 加载授课老师列表
      const preData = state.teacherList || [];
      const res: any = preData;
      const newData = payload;
      for (const i in newData) {
        if (Object.prototype.hasOwnProperty.call(newData, i)) {
          let flag = false;
          for (const j in preData) {
            if (newData[i].userId === preData[j].userId) {
              flag = true;
              break;
            } else {
              flag = false;
            }
          }
          if (flag === false) {
            res.push(newData[i]);
          }
        }
      }
      return { ...state, teacherList: res };
    case types.STUDY_LIST_REQUEST: // 学段列表
      return { ...state, studySectionsList: payload };
    case types.SUBJECTS_LIST_REQUEST: // 学科列表
      return { ...state, subjectsList: payload };
    case types.GRADES_LIST_REQUEST: // 年级列表
      return { ...state, gradesList: payload };
    case types.GET_SCHOOL_YEARS: // 学年列表
      return { ...state, schoolYearList: payload };
    case types.GET_SCHOOL_PERIODS: // 学届列表
      return { ...state, schoolPeriodList: payload };
    case types.GET_TEXT_BOOKS: // 教材列表
      return { ...state, textBooks: payload };
    case types.GET_CHAPTERS_TREE_TREE: // 章节树
      return { ...state, chaptersTree: payload };
    case types.TEACHER_LIST_TOTALPAGES: // 老师分页总数
      return { ...state, totalPages: payload };
    default:
      return state;
  }
};
export default Base;
