import './index.less';
import React from 'react';
import { MyIcon } from '../../util';

interface P {
  state: string;
  className?: string;
}
function EmptyList({ state, className }: P) {
  function getIcon(state: string) {
    switch (state) {
      case 'noData':
        return {
          type: 'iconcontent_null',
          text: '暂无内容',
        };
      case 'noMore':
        return {
          type: 'iconcontent_null',
          text: '暂时没有内容呀~',
        };
      case 'noResult':
        return {
          type: 'iconsearch_null',
          text: '未找到相关内容哦~',
        };
      case 'noLesson':
        return {
          type: 'iconcomment_null',
          text: '暂无评价',
        };
      case 'noReword':
        return {
          type: 'iconfinger_left',
          text: '选择左侧还课视频进行查看',
        };
      default:
        return {
          type: '',
          text: '',
        };
    }
  }
  return (
    <div className={`empty-list ${className}`}>
      <div className="icon">
        <MyIcon className="empty-icon" type={getIcon(state).type} />
      </div>
      <div className="empty-text">{getIcon(state).text}</div>
    </div>
  );
}

export default EmptyList;
