/* eslint-disable @typescript-eslint/no-this-alias */
import React from 'react';
import { Button, Progress, Icon } from 'antd';
import AxiosWrap from '../../../lib/request';
import axios from 'axios';
import api from '../../../config/api/index';
import Alert from '../../../components/Alert/Alert';
import { connect } from 'react-redux';
import { MyIcon, NormalObj, mergeProps, getSearchParams } from '../../../../src/util';
import TrackRequest from '../../../lib/trackRequest';
import downloadSuccess from '../../../components/downloadSuccess';
import { isProhibit } from '../../../util/prohibitShow';
import './index.less';

interface P {
  data: any;
  functionConfig: NormalObj;
}
interface S {
  visible: boolean;
  percent: number;
  title: any;
  isError: boolean;
  childCourseWare: any;
}
const initialState = {
  visible: false,
  percent: 0,
  title: null,
  isError: false,
  childCourseWare: null,
};
class enclosure extends React.PureComponent<P, S> {
  searchParams: any; // 地址栏 searh 数据
  constructor(props: P) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.searchParams = getSearchParams(window.location.search);
  }
  /**
   * 转化name和extension
   *
   * @params data 课件
   * @params type 作业资料附属课件
   *
   */
  getNameExtension(data: any) {
    let name = '';
    let extension = '';
    if (!data) return { name: '', extension: '' };
    name = data.name;
    extension = data.extension;
    return { name, extension };
  }
  /**
   * 转化课件名
   *
   * @params name 课件名字
   * @params extension 课件后缀名
   *
   */
  getFileName(name: any, extension: any) {
    if (extension) {
      if (name.includes(`.${extension}`)) {
        return name;
      } else {
        return `${name}.${extension.toLocaleLowerCase()}`;
      }
    }
    return name;
  }
  /**
   * 下载预处理
   *
   * @params data 课件
   * @params category normal:不包含子课件, childList:包含子课件
   *
   */
  download(data: any) {
    const that = this;
    const obj = this.getNameExtension(data);
    const title = this.getFileName(obj.name, obj.extension);
    this.setState({ isError: false });
    const downloadFileType = this.searchParams.leftMenuType === '2' ? 1 : 0;
    const _name_ = encodeURIComponent(data.name);
    const url = `${api.api}/files/${data.fileId}?type=${downloadFileType}&fileName=${_name_}`;
    // 下载资料埋点
    TrackRequest({
      name: 'downl_noti_material',
      data: {
        notice_id: this.searchParams.noticeId,
        noti_material_id: data.fileId,
        download_entrance: 0,
      },
    });
    if (url) {
      AxiosWrap({
        url,
        method: 'get',
      }).then((res: any) => {
        let url = res.data.cdnUrl || res.data.url;
        if (url) {
          url = `https${url.substring(url.indexOf(':'))}`;
          that.callBack(title, '', data, url);
        } else {
          Alert.open('该文件暂时无法下载，请联系客服');
        }
      });
    }
  }
  /**
   * 下载资源
   *
   * @params title 下载框标题
   * @params trackData 大数据埋点
   * @params data 课件
   * @params url 资源地址
   *
   */
  callBack(title: any, trackData: any, data: any, url: any) {
    const that = this;
    this.setState({ visible: true, title });
    axios({
      url,
      method: 'GET',
      responseType: 'blob', // important,
      headers: {
        'Cache-Control': 'no-cache',
      },
      onDownloadProgress(progressEvent) {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        that.setState({ percent });
        if (percent === 100) {
          setTimeout(() => {
            that.setState({ visible: false, percent: 0 });
          }, 2000);
        }
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', title);
        document.body.appendChild(link);
        link.click();
        downloadSuccess();
        // that.handleTrackData(trackData, data);
      })
      .catch((err) => {
        if (err) {
          that.setState({ isError: true, visible: false });
        }
      });
  }
  render() {
    const {
      data,
      functionConfig: { downloadFile = false },
    } = this.props;
    const { visible, percent, isError } = this.state;

    return (
      <div className="file-item">
        <div>
          <span title={data.name} className="flex-cont">
            {switchIcon(data.icon)}
            <div className="file-label">
              {data.downloadState && <span className="state-icon down-icon">已下载</span>}
              {data.name}
            </div>
          </span>
          {!visible && downloadFile && isProhibit(data) && (
            <Button type="primary" onClick={() => this.download(data)}>
              下载
            </Button>
          )}
        </div>
        <div style={{ paddingLeft: 38, paddingRight: 70 }}>
          {isError && (
            <div style={{ color: '#F5222D', position: 'relative' }}>
              下载失败!
              <span
                style={{ position: 'absolute', color: '#8A8A8A', left: '100px' }}
                onClick={() => {
                  this.setState({ visible: false, isError: false });
                }}
              >
                <Icon type="close" />
              </span>
            </div>
          )}

          {visible && !isError && (
            <Progress
              percent={this.state.percent}
              status={percent === 100 ? 'success' : !isError ? 'active' : 'exception'}
            />
          )}
        </div>
      </div>
    );
  }
}
const switchIcon = (str: string) => {
  if (str) {
    return <MyIcon style={{ fontSize: 32 }} className="iconfont" type={str} />;
  } else {
    return <Icon style={{ fontSize: 32 }} type="paper-clip" />;
  }
};

const mapStateToProps = (state: any) => ({
  functionConfig: { ...state.DynamicControl.functionConfig },
});

export default connect(mapStateToProps, mergeProps)(enclosure);
