import _ from 'lodash';

function highLightReplace(k: string, target1: string, target2: string | undefined | null): string {
  if (target2) {
    return replace(target2, k);
  }
  return replace(target1, k);
}

function replace(target: string, k: string): string {
  const lowerTarget = _.toLower(target);
  const len = k.length;
  const l = target.length;
  const F = findString(target);
  let str = '';
  let start = 0;

  while (start < l) {
    let j = lowerTarget.indexOf(k, start);

    if (j !== -1) {
      str += F(start, j) + '<mark>' + F(j, j + len) + '</mark>';
      start = j + len;
    } else {
      str += F(start, l);
      start = l;
    }
  }

  return str;
}

function findString(str: string): Function {
  return function f(m: number, n: number): string {
    return str.slice(m, n);
  };
}

export function highLightFn(data: any, text: Array<string>, category: number) {
  if (category === 2) {
    text.forEach((k: string) => {
      const { name } = data;
      data['highLightName'] = highLightReplace(k, name, data['highLightName']);
    });
  } else if (category === 1) {
    const { lessonName } = data;
    text.forEach((k: string) => {
      data['highLightLessonName'] = highLightReplace(k, lessonName, data['highLightLessonName']);
      data.grades.forEach((grade: any) => {
        grade['highLightGradeName'] = highLightReplace(
          k,
          grade.gradeName,
          grade['highLightGradeName'],
        );
      });
      data.subjects.forEach((subject: any) => {
        subject['highLightSubjectName'] = highLightReplace(
          k,
          subject.subjectName,
          subject['highLightSubjectName'],
        );
      });
    });
  } else if (category === 0) {
    const { courseName } = data;
    text.forEach((k: string) => {
      data['highLightCourseName'] = highLightReplace(k, courseName, data['highLightCourseName']);
    });
  } else if (category === 3) {
    const { name } = data;
    text.forEach((k: string) => {
      data['highLightName'] = highLightReplace(k, name, data['highLightName']);
    });
  }
}

export const highLightFnForSync = (data: any, text: string) => {
  if (!text || text === '') {
    data['highLightCourseName'] = data['highLightCourseName'] || data.courseName;
    return;
  }
  const reg = new RegExp(text, 'gi');
  let execResult = null;
  let resList = [];
  do {
    execResult = reg.exec(data.courseName);
    execResult && resList.push(execResult[0]);
  } while (execResult);

  resList = _.uniq(resList);

  data['highLightCourseName'] = data.courseName;
  if (resList.length <= 0) {
    return;
  }
  resList.forEach((k) => {
    const _reg = new RegExp(k, 'g');
    data['highLightCourseName'] = data['highLightCourseName'].replace(_reg, `<mark>${k}</mark>`);
  });
};
