import * as types from '../types/course.detail.type';
import { AnyAction } from 'redux';

const initialState = (() => ({
  detail: {},
  list: [],
  is_exist: false,
  courseInfo: null, // 获取课程信息
}))();

const CourseDetail = (state = { ...initialState }, { type, payload }: AnyAction) => {
  switch (type) {
    case types.GET_COURSE_DETAIL:
      return {
        ...state,
        detail: payload,
      };
    case types.GET_LESSON_LIST:
      return {
        ...state,
        list: payload,
      };
    case types.COURSE_EXIST:
      return {
        ...state,
        is_exist: payload,
      };
    case types.GET_COURSE_REQUEST:
      return { ...state, courseInfo: payload };
    default:
      return {
        ...state,
      };
  }
};
export default CourseDetail;
