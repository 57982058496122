/* eslint-disable guard-for-in */
// 添加资料弹出框
import React from 'react';
import { Form, Modal, Select, Input } from 'antd';
import moment from 'moment';

import { AJTextField } from '../../../components/AJInput';
import { FormComponentProps } from 'antd/lib/form';
import uploadFormat from '../upload/uploadFormat';
import { getUserId } from '../../../util';
import ServiceUpload from '../upload/serviceUpload';
import TimeLimit from './TimeLimit';

const userId = getUserId();
interface P extends FormComponentProps {
  visible: boolean; // 弹出显示控制
  cancel: () => void; // 关闭弹窗方法
  cbackData?: (data: any) => void; // 返回父组件数据
  materialItem?: any; // 选中的某一行数据，在这里行数据里面添加文件
  showTimeLimit: boolean; // 是否显示“截止时间设置”
}
interface S {
  fileList: any; // 文件列表
  disabled: boolean; // 上传按钮控制
  category: number; // 资料类型
  accept: string; // 上传类型控制
  icon: string; //
  typeDisabled: boolean; // 上传类型是否禁用
  type: number; // 截止时间类型
}
class AddMaterial extends React.Component<P, S> {
  private modalForm: any = null;

  constructor(props: P) {
    super(props);
    this.state = {
      fileList: [],
      category: -1,
      disabled: true,
      typeDisabled: false,
      accept: '',
      icon: '',
      type: 1,
    };
  }
  componentDidMount() {
    const { materialItem } = this.props;
    if (materialItem) {
      this.props.form.setFieldsValue({
        materialName: materialItem.name,
        category: materialItem.category,
      });
      this.changeType(materialItem.category);
      this.setState({
        disabled: false,
        typeDisabled: true,
      });
    }
  }
  // 提交数据
  submit = () => {
    const { fileList } = this.state;
    const { cbackData, materialItem } = this.props;
    let expiryDate: any = null;
    let timeLimitIsError = false;
    const form = this.modalForm;

    form &&
      form.validateFields((err: any, dateValues: any) => {
        if (err) {
          timeLimitIsError = true;
          return;
        }
        if (dateValues.type === 2) {
          expiryDate = moment(dateValues.date).valueOf();
        }
      });
    this.props.form.validateFields((error: string, value: any) => {
      if (error || timeLimitIsError) {
        return;
      }
      if (materialItem) {
        // 追加
        const files = [];
        for (const i in fileList) {
          const item = fileList[i];
          files.push({
            fileId: item.data.fileId,
            size: item.data.size,
            md5: item.data.md5,
            fileName: item.data.fileName,
            extension: item.data.extension,
            id: Date.now() + i,
          });
        }
        materialItem.files = [...files, ...materialItem.files];
        materialItem.name = value.materialName;
        cbackData && cbackData(materialItem);
      } else {
        // 添加
        const obj = {
          name: value.materialName,
          category: value.category,
          createUserId: userId,
          expiryDate,
          id: Date.now(),
          files: fileList.map((item: any, index: number) => {
            return {
              fileId: item.data.fileId,
              size: item.data.size,
              md5: item.data.md5,
              fileName: item.data.fileName,
              extension: item.data.extension,
              id: Date.now() + index,
            };
          }),
        };
        cbackData && cbackData(obj);
      }
    });
  };
  // 回调 拿数据
  cbackFun = (data: any) => {
    const len = data.length;
    const fileName = len > 0 ? data[len - 1].name : '';
    // 获取最后一个文件名称
    if (!this.props.form.getFieldValue('materialName')) {
      this.props.form.setFieldsValue({
        materialName: fileName,
      });
    }
    this.setState({
      fileList: data,
    });
    if (len > 0) {
      this.props.form.setFieldsValue({ name: len });
    } else {
      this.props.form.setFieldsValue({ name: '' });
    }
  };
  // 类型选择变化
  changeType = (value: number) => {
    const { sourceMaterial, courseware, teachingPlan, task } = uploadFormat;
    const { fileList } = this.state;
    // 重置资料名称
    if (fileList.length > 0) {
      this.props.form.resetFields(['materialName']);
    }
    let str = '';
    let strIcon = '';
    switch (value) {
      // case '1': //课堂实录
      //     str = video;
      //     break;
      case 2: // 课件ppt
        str = courseware;
        strIcon = 'icon-courseware';
        break;
      case 3: // 素材
        str = sourceMaterial;
        strIcon = 'icon-material';
        break;
      case 4: // 教案 world
        str = teachingPlan;
        strIcon = 'icon-teaching_plan';
        break;
      case 5: // 作业
        str = task;
        strIcon = 'icon-operation';
        break;
      case 6: // 试卷 word + audio
        str = task;
        strIcon = 'icon-test_paper';
        break;
      default:
    }
    this.setState({
      category: value,
      disabled: false,
      accept: str,
      icon: strIcon,
      fileList: [],
    });
  };
  // 监听截止时间变化
  onChange = (e: any) => {
    this.setState({ type: e.target.value }, () => {
      const form = this.modalForm;
      form.validateFields(['date'], { force: true });
      form.resetFields();
    });
  };

  render() {
    const { disabled, accept, icon, category, fileList, typeDisabled, type } = this.state;
    const { visible, cancel, materialItem, showTimeLimit } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal title="添加资料" width={800} visible={visible} onCancel={cancel} onOk={this.submit}>
        <Form>
          <Form.Item {...formLayout2} label="资料包名称">
            {getFieldDecorator('materialName', {
              rules: [
                {
                  required: true,
                  message: '请填写资料包名称！',
                },
              ],
            })(<AJTextField maxLength={100} />)}
          </Form.Item>
          <Form.Item {...formLayout} label="资料类型">
            {getFieldDecorator('category', {
              rules: [
                {
                  required: true,
                  message: '请选择资料类型！',
                },
              ],
            })(
              <Select placeholder="请选择" onChange={this.changeType} disabled={typeDisabled}>
                <Select.Option value={2}>课件</Select.Option>
                <Select.Option value={3}>素材</Select.Option>
                <Select.Option value={4}>教案</Select.Option>
                <Select.Option value={5}>普通作业</Select.Option>
                <Select.Option value={6}>普通试卷</Select.Option>
              </Select>,
            )}
          </Form.Item>
        </Form>
        {showTimeLimit && (
          <TimeLimit
            wrappedComponentRef={(timeLimitRef: any) => {
              this.modalForm = timeLimitRef ? timeLimitRef.props.form : null;
            }}
            type={type}
            onChange={this.onChange}
            data={{ expiryDate: null }}
            contentHeight={0}
            label="截止时间设置"
            dateMargin={108}
          />
        )}
        <Form>
          <Form.Item {...formLayout1} label="上传资料">
            <ServiceUpload
              multiple
              fileList={fileList}
              category={category}
              accept={accept}
              cbackFun={this.cbackFun}
              disabled={disabled}
              acceptTips
              icon={icon}
            />
            {getFieldDecorator('name', {
              rules: [
                {
                  required: !materialItem,
                  message: '请上传资料！',
                },
              ],
            })(<Input type="hidden" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 3 },
  wrapperCol: { span: 7 },
};
const formLayout1: any = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
const formLayout2: any = {
  labelCol: { span: 3 },
  wrapperCol: { span: 15 },
};
export default Form.create<P>()(AddMaterial);
