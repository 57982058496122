/*
 * @Author: gouyang
 * @Description:浏览器兼容性提示语
 * @Date: 2019-09-23 17:15:09
 */
import React from 'react';
import { Alert } from 'antd';
import getExplorerInfo from '../../util/browserInfo';
import './index.less';

function compatibleTips() {
  const { name } = getExplorerInfo();

  if (name !== 'Chrome' && window.sessionStorage.getItem('BROWSERINFO') !== 'closed') {
    window.sessionStorage.setItem('BROWSERINFO', 'warning');
  }
  function onClose() {
    window.sessionStorage.setItem('BROWSERINFO', 'closed');
  }
  return (
    window.sessionStorage.getItem('BROWSERINFO') === 'warning' && (
      <div className="browser-info">
        <Alert
          message={
            <p className="tips">
              为了更好的用户体验，建议使用360（极速模式）、QQ（极速模式）、Google浏览器的最新版本。
              Google浏览器下载链接：
              <a href="https://www.google.cn/chrome/" target="_blank" rel="noopener noreferrer">
                https://www.google.cn/chrome/
              </a>
            </p>
          }
          type="info"
          showIcon
          closable
          onClose={onClose}
        />
      </div>
    )
  );
}

export default compatibleTips;
