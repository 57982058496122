import dev from './api.dev.config';
import test from './api.test.config';
import prod from './api.prod.config';

const env = (window as any).__UNION_CENTER_ENV__;
let env_api: {
  api: string;
  file: string;
  trackApi: string;
  userCenter: string;
  // resourceCenter: string;
  resourceCenterDownload: string;
  examinationAndAnalysis: string;
  ebi: string;
  ip: string;
  pingguoApi: string;
  userCenterApi: string;
  markupService: string;
  SENTRY_DSN?: string;
  gateway: string;
  passport_sdk_token: string;
  passport: string;
  userAgreement: string;
  privacyPolicy: string;
};
switch (env) {
  case 'DEV':
    env_api = dev;
    break;
  case 'TEST':
    env_api = test;
    break;
  case 'PROD':
    env_api = prod;
    break;
  case 'STAGE':
    env_api = prod;
    break;
  default:
    env_api = dev;
    break;
}
export default env_api;
