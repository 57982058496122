import { message } from 'antd';
import Immutable from 'seamless-immutable';
import AxiosWrap from '../../lib/request';
import * as types from '../types/course.type';
import { dispatch } from '../../createStore';
import Alert from '../../components/Alert/Alert';
import TrackRequest from '../../lib/trackRequest';
import { stringifySearchParams } from '../../util';
import isNotRootLink from '../../util/isNotRootLink';
import { systemCoverPicture, personalCoverPicture } from '../../lib/api';

// 获取栏目下拉数据列表
export const column_list = async () => {
  const { status, data } = await AxiosWrap({ url: '/menu' });
  if (status === 200) {
    dispatch({ type: types.COLUMN_LIST_REQUEST, payload: data });
  }
};

// 系统封面
export async function getSystemCoverPicture(paginationParams: {
  page: number;
  size: number;
  currentKey: string;
}) {
  const { page, size, currentKey } = paginationParams;

  dispatch({ type: types.START_REQUEST });
  try {
    const { status, data } = await systemCoverPicture({ page, size });
    if (status === 200) {
      dispatch({
        type: types.COVER_LIST_REQUEST,
        payload: {
          result: data._embedded ? Immutable(data._embedded.courseCovers) : [],
          pageInfo: Immutable(data.page),
          currentKey,
        },
      });
    } else {
      dispatch({ type: types.IS_REQUESTED_ERROR, payload: true });
    }
    dispatch({ type: types.FINISH_REQUEST });
  } catch (error) {
    dispatch({ type: types.IS_REQUESTED_ERROR, payload: true });
    dispatch({ type: types.FINISH_REQUEST });
  }
}

// 个人封面
export async function getPersonalCoverPicture(paginationParams: {
  page: number;
  size: number;
  currentKey: string;
}) {
  const { page, size, currentKey } = paginationParams;
  dispatch({ type: types.START_REQUEST });
  try {
    const { data, status } = await personalCoverPicture({ page, size });
    if (status === 200) {
      dispatch({
        type: types.COVER_LIST_REQUEST,
        payload: {
          result: data._embedded ? Immutable(data._embedded.courseCovers) : [],
          pageInfo: Immutable(data.page),
          currentKey,
        },
      });
    } else {
      dispatch({ type: types.IS_REQUESTED_ERROR, payload: true });
    }
    dispatch({ type: types.FINISH_REQUEST });
  } catch (error) {
    dispatch({ type: types.IS_REQUESTED_ERROR, payload: true });
    dispatch({ type: types.FINISH_REQUEST });
  }
}

// 创建课程 提交数据
export const addCourse = async (params: any, cback: () => void) => {
  try {
    const { data, status } = await AxiosWrap({ url: '/course', method: 'POST', data: params });
    if (status === 200) {
      message.success('保存成功！');
      cback();
      // 课程创建完成埋点
      TrackRequest({ name: 'save_created_course', data: { course_id: data.id } });
    }
  } catch (error) {
    if (error && error.data) {
      Alert.open(error.data.message, () => {
        isNotRootLink();
      });
    }
  }
};
// 编辑课程
export const editCourse = async (params: any, courseId: string, back?: () => void) => {
  try {
    const { status } = await AxiosWrap({ url: `/course/${courseId}`, method: 'PUT', data: params });
    if (status === 200) {
      message.success('保存成功！');
      back && back();
      // 课程编辑完成埋点
      TrackRequest({ name: 'save_course_infor', data: { course_id: courseId } });
    }
  } catch (error) {
    if (error && error.data) {
      Alert.open(error.data.message, () => {
        isNotRootLink();
      });
    }
  }
};
// 根据id 获取课程信息
export const getCourse = async (params: string | number) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/course/${params}` });
    if (status === 200) {
      dispatch({ type: types.GET_COURSE_REQUEST, payload: Immutable(data) });
    }
  } catch (error) {
    if (error && error.status === 404) {
      Alert.open('课程已不存在！', undefined, 'black');
      return;
    }
    if (error && error.status === 403) {
      Alert.open('暂无权限！', () =>
        (window as any)._history.push(
          `/userCente${stringifySearchParams({
            id: 'kechengbiao',
            name: '课程表',
            parentId: 'kechengbiao',
          })}`,
        ),
      );
      return;
    }
    error && error.data && message.error(error.data.message);
  }
};
