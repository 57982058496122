/*
 * @Author: gouyang
 * @Description:
 * @Date: 2019-07-12 16:59:27
 */
import { message } from 'antd';
import axios, { AxiosRequestConfig, AxiosPromise, AxiosInstance } from 'axios';

import Alert from '../components/Alert/Alert';
import StaticLoad from '../components/StaticLoad';

import apiConfig from '../config/api';
import { getToken, getLeagueId, getUserId } from '../util';
import { withRequest } from 'eastedu-login-sdk';
import { logoutAccount } from './account';
// import { oauth2Logout } from '../pages/LoginOauth';
let isInWechat = false;

message.config({
  maxCount: 1,
});
interface wrapConfig extends AxiosRequestConfig {
  isCustom?: boolean;
  onlyLogin?: boolean;
  ignoreLoading?: boolean;
  noLeagueId?: boolean;
  noUserId?: boolean;
  baseURL?: string;
  upload?: boolean;
}
interface wrapAxios extends AxiosInstance {
  (config: wrapConfig): AxiosPromise;
}
let wrapConfig: wrapConfig;
// let isCustom: boolean;
let ignoreLoading: boolean;
let AxiosWrap: wrapAxios = axios.create({
  baseURL: apiConfig.api,
});

/* 过滤请求 */
AxiosWrap.interceptors.request.use((config: wrapConfig) => {
  const index = window.location.search.indexOf('?');
  let o: { [k: string]: any } = {};
  let arr = decodeURIComponent(window.location.search.slice(index + 1)).split('&');
  for (let i = 0, len = arr.length; i < len; i++) {
    let splitArr: Array<any> = arr[i].split('=');
    o[splitArr[0]] = splitArr[1];
  }
  wrapConfig = config;
  // if (config.isCustom) {
  //     isCustom = config.isCustom;
  // } else {
  //     isCustom = false;
  // }
  if (config.ignoreLoading) {
    ignoreLoading = config.ignoreLoading;
  } else {
    ignoreLoading = false;
    StaticLoad.show();
  }
  const token = getToken();
  config.headers.Authorization =
    config.baseURL === apiConfig.pingguoApi ||
    config.baseURL === apiConfig.userCenterApi ||
    config.baseURL === apiConfig.markupService ||
    config.baseURL === apiConfig.gateway
      ? `Bearer ${token}`
      : config.headers.Authorization
      ? config.headers.Authorization
      : token;
  if (config.data !== 'noLeagueId') {
    let id = getLeagueId();
    if (id) {
      config.headers.leagueId = getLeagueId();
    }
  }

  config.headers.relationUserId = config.headers.relationUserId
    ? config.headers.relationUserId
    : getUserId();
  if (!config.headers.Authorization) {
    config.headers.Authorization = o.Authorization || '';
    if (o.Authorization) {
      isInWechat = true;
    }
  }
  if (!config.headers.leagueId) {
    config.headers.leagueId = o.leagueId || '';
  }
  if (!config.headers.relationUserId) {
    config.headers.relationUserId = o.relationUserId || '';
  }

  if (config.noUserId) delete config.headers.relationUserId;
  if (config.noLeagueId) delete config.headers.leagueId;

  if (config.baseURL === apiConfig.userCenterApi) {
    config.headers.apptoken = btoa('WKApi');
  }

  if (config.upload) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  delete config.isCustom;
  delete config.ignoreLoading;

  return config;
});
/* 过滤响应 */
AxiosWrap.interceptors.response.use(
  (response) => {
    // 四分屏
    !!(window as any).CefInstance_c34ad448618447edb9e23efb7e5716da &&
      window.sessionStorage.setItem('SPLITSCREENSTATUS', JSON.stringify({ status: 1 }));
    if (!ignoreLoading) StaticLoad.remove();
    return response;
  },
  (err) => {
    StaticLoad.remove();
    if (!(window as any).CefInstance_c34ad448618447edb9e23efb7e5716da) {
      if (!err || !err.response) {
        let errMessage: string = '网络或服务器错误，请稍后重试！';
        if (wrapConfig.onlyLogin) errMessage = '网络或服务器错误，登录失败，请稍后重试！';
        message.error(errMessage);
        return Promise.reject(err);
      } else {
        if (err.response.status === 401) {
          // if (err.response.data.message === '请选择联盟！') {
          //   message.warning(err.response.data.message, 1, () => {
          //     window.localStorage.removeItem('userInfo');
          //     window.localStorage.removeItem('unionId');
          //     window.localStorage.removeItem('ROOTTYPE');
          //     // window.location.href = getWechatLink();
          //     oauth2Logout();
          //   });
          // } else {
          //   Alert.open(
          //     err.response.data.message,
          //     () => {
          //       window.localStorage.removeItem('userInfo');
          //       window.localStorage.removeItem('unionId');
          //       window.localStorage.removeItem('ROOTTYPE');
          //       // window.location.href = getWechatLink();
          //       oauth2Logout();
          //     },
          //     isInWechat === true ? 'inwechat' : '',
          //   );
          // }
          return Promise.reject(err);
        }
        if (err.response.status <= 504 && err.response.status >= 500) {
          message.error(err.response.data.message || '网络或服务器错误，请稍后重试！');
          return Promise.reject(err);
        }
      }
      return Promise.reject(err.response ? err.response : err);
    } else {
      // 四分屏
      let errData = {
        protocol: 'pageStatus',
        details: { code: 0, message: '' },
        status: 0,
      };
      if (err.response.status === 401) {
        errData.details = { code: 401, message: err.response.data.message };
      } else {
        errData.details = { code: 500, message: '网络或服务器错误，请稍后重试！' };
      }
      window.sessionStorage.setItem('SPLITSCREENSTATUS', JSON.stringify(errData));
      return Promise.reject(errData);
    }
  },
);

// export default AxiosWrap;
export default withRequest(AxiosWrap, {
  paramsTransformer(token, url, config) {
    return [
      url,
      {
        ...config,
        headers: {
          Authorization: `${token.token_type} ${token.access_token}`,
          ...config?.headers,
        },
      },
    ] as any;
  },
  isUnauthorized: async (err: any, url, config) => {
    if (err?.response?.status === 401) {
      if (err?.response?.data.message === '请选择联盟！') {
        message.warning('请选择联盟！', 1, () => {
          logoutAccount();
        });
        return false;
      } else if ([10008, 10006, 10004].includes(err?.response?.data?.code)) {
        Alert.open(
          err?.response?.data.message ?? '登录信息已失效，请重新登录。',
          () => {
            logoutAccount();
          },
          isInWechat === true ? 'inwechat' : '',
        );
        return false;
      }
    }

    return err?.response?.status === 401;
  },
  onRefreshSuccess(token) {
    const obj = window.localStorage.getItem('userInfo')
      ? JSON.parse((window as any).localStorage.getItem('userInfo'))
      : {};
    Object.assign(obj, { access_token: token.access_token });
    window.localStorage.setItem('userInfo', JSON.stringify(obj));

    const mainObj = window.localStorage.getItem('mainUserInfo')
      ? JSON.parse((window as any).localStorage.getItem('mainUserInfo'))
      : {};
    Object.assign(mainObj, { access_token: token.access_token });
    window.localStorage.setItem('mainUserInfo', JSON.stringify(mainObj));
  },
  onUnauthorized: (err: any) => {
    if (err?.response?.data?.message === '请选择联盟！') {
      message.warning('请选择联盟！', 1, () => {
        logoutAccount();
      });
    } else {
      Alert.open(
        err?.response?.data?.message ?? '登录信息已失效，请重新登录。',
        () => {
          logoutAccount();
        },
        isInWechat === true ? 'inwechat' : '',
      );
    }
  },
});
