import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Empty, Icon } from 'antd';
import { MyIcon } from '../../util';
import './index.less';
import ResourcePackage from './components/ResourcePackage';
import InfiniteScroll from 'react-infinite-scroller';
import { getResource } from '../../redux/action/resource.action';
import PreviewMaterial from '../Lesson/siteResources/previewMaterial';
import FilterResources from './components/FilterResources';
import { FilterParams } from './resourceInterface';
// import { isPingguoUnion } from '../../util';
// import * as baseAction from '../../redux/action/base.action';

function ResourceLib() {
  const filterParams = useRef({
    page: 0,
    size: 10,
    materialId: '',
    chapterId: '',
    subjectId: '',
    schoolPeriod: '',
    gradeId: '',
    category: '',
    schoolSection: '',
  });
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  // const [schoolSectionIndex, setSchoolSectionIndex] = useState(0);
  const dispatch = useDispatch();
  const [currentPrivilage, setCurrentPrivilage] = useState('');
  const { page, hasMore, packages, currentRootType, resourceRootOption } = useSelector(
    (store: any) => ({
      currentRootType: store.GloabalCreateSync.currentRootType,
      packages: store.Resource.packages,
      page: store.Resource.page,
      hasMore: store.Resource.hasMore,
      resourceRootOption: store.GloabalCreateSync.resourceRootOption,
      rootOption: store.GloabalCreateSync.rootOption,
    }),
    shallowEqual,
  );

  // 注册获取数据方法
  useEffect(() => {
    dispatch({ type: 'GETDATABYROOT', payload: () => {} });
  }, [dispatch]);

  useEffect(() => {
    filterParams.current.page = page;
  }, [page]);

  useEffect(() => {
    const localPrivilage = window.localStorage.getItem('ROOTTYPE');
    if (resourceRootOption.length === 0) return;
    if (!localPrivilage && !currentRootType) return;
    if (localPrivilage === currentRootType) {
      const index = resourceRootOption.findIndex((item: any) => item.type === currentRootType);
      if (index < 0) return;
    }
    if (localPrivilage !== currentRootType) {
      dispatch({ type: 'GLOBALCREATESYNCTYPE', payload: localPrivilage });
    }
    if (currentRootType === 'sync') {
      setCurrentPrivilage('sync');
    } else {
      return;
    }
    // onLoadMore();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    window.onscroll = () => {
      scrollFunction();
    };
    return () => {
      window.onscroll = null;
      dispatch({ type: 'RESET_RESOURCE_STATE' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceRootOption, dispatch, currentRootType]);

  const scrollFunction = () => {
    if (
      document.body.scrollTop > document.body.clientHeight ||
      document.documentElement.scrollTop > document.body.clientHeight
    ) {
      (document as any).getElementById('btnTop').style.display = 'block';
    } else {
      (document as any).getElementById('btnTop').style.display = 'none';
    }
  };

  const onLoadMore = async () => {
    if (loading) return;
    setLoading(true);
    await getResource(filterParams.current);
    setLoading(false);
  };

  // useEffect(() => {
  //   async function func() {
  //     if (isPingguoUnion()) {
  //       const studySections = await baseAction.getStudySections();
  //       if (studySections.length > 0) {
  //         // setCurrentActiveKey(previewValue => {
  //         //   return { ...previewValue, studySection: 1 };
  //         // });
  //         await baseAction.getGrades(studySections[0].code);
  //         await baseAction.getSubjects(studySections[0].code);
  //         filterParams.current.schoolSection = studySections[0].code;
  //         setSchoolSectionIndex(1);
  //         // await dispatch({ type: 'RESET_RESOURCE_STATE' });
  //         // let tempFilterData = { ...props.setInitFilterParams, page: 0, schoolSection: studySections[1].code };
  //         // props.filterParamsDidObtain && props.filterParamsDidObtain(tempFilterData, true);
  //       } else {
  //         baseAction.getGrades();
  //         baseAction.getSubjects();
  //       }
  //       // eslint-disable-next-line
  //       onLoadMore();
  //     }
  //   }
  //   func();
  // }, []);

  const EmptyRender = () => (
    <Empty
      className="ant-empty-normal custom-empty"
      description="暂无内容"
      image={<MyIcon className="empty-icon" type="iconcontent_null" />}
    />
  );

  const goToPreview = (dataInfo: any) => {
    setData(dataInfo);
    setPreviewVisible(true);
  };

  const filterParamsDidObtain = async (params: FilterParams, force?: boolean) => {
    if (!force && loading) return;
    setLoading(true);
    await getResource(params);
    setLoading(false);
  };
  const dispatchFilterParams = (params: FilterParams): void => {
    filterParams.current = params;
  };

  return (
    <div className="res-lib">
      {currentRootType === 'sync' ? (
        <div>
          {currentPrivilage === 'sync' ? (
            <FilterResources
              initFilterParams={filterParams.current}
              dispatchFilterParams={dispatchFilterParams}
              filterParamsDidObtain={filterParamsDidObtain}
            />
          ) : (
            ''
          )}
          {packages.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              threshold={100}
              loadMore={onLoadMore}
              hasMore={hasMore}
              loader={
                loading ? (
                  <div className="loader" key="loading">
                    <Icon type="loading" />
                    正在加载...
                  </div>
                ) : (
                  <div className="loader clickLoad" onClick={onLoadMore} key="load-more">
                    点击加载更多
                  </div>
                )
              }
              initialLoad={false}
              className="infinite-scroll"
            >
              {packages.map((item: any, index: number) => (
                <ResourcePackage
                  key={`${item.resourceId}${index}`}
                  {...item}
                  goToPreview={goToPreview}
                />
              ))}
              {!hasMore && (
                <div className="noMore" key="finish-loaded">
                  已全部加载完成
                </div>
              )}
            </InfiniteScroll>
          ) : (
            EmptyRender()
          )}
        </div>
      ) : (
        EmptyRender()
      )}
      {previewVisible && (
        <PreviewMaterial
          resource={data}
          visible={previewVisible}
          extension={data.extension && data.extension.toLowerCase()}
          title={data.fileName && decodeURIComponent(data.fileName)}
          // previewPlayId={data.playId}
          previewUrl={data.cdnUrl || data.url}
          cancel={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
}

export default React.memo(ResourceLib);
