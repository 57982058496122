import { message } from 'antd';
import axios, { AxiosRequestConfig, AxiosPromise, AxiosInstance } from 'axios';
import { getToken } from '../util';

// import StaticLoad from '../components/StaticLoad';

import apiConfig from '../config/api';
message.config({
  maxCount: 1,
});
interface wrapConfig extends AxiosRequestConfig {
  isCustom?: boolean;
  onlyLogin?: boolean;
  ignoreLoading?: boolean;
  noLeagueId?: boolean;
}
interface wrapAxios extends AxiosInstance {
  (config: wrapConfig): AxiosPromise;
}
let wrapConfig: wrapConfig;
let AxiosWrap: wrapAxios = axios.create({
  baseURL: apiConfig.file,
});

/* 过滤请求 */
AxiosWrap.interceptors.request.use((config: wrapConfig) => {
  wrapConfig = config;

  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;

  config.headers['x-module'] = 'open_school';
  if (!!config.headers.safetyChain) {
    // 防盗链
    config.headers['x-module'] = 'e7fb1579d43e4de2830a4573deca9a5a';
  }

  delete config.headers.safetyChain;
  delete config.isCustom;
  delete config.ignoreLoading;

  return config;
});
/* 过滤响应 */
AxiosWrap.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (!err || !err.response) {
      return Promise.reject({ message: '网络或服务器错误，请稍后重试！' });
    } else {
      if (err.response.status <= 504 && err.response.status >= 500) {
        return Promise.reject({ message: '网络或服务器错误，请稍后重试！' });
      }
    }
    return Promise.reject(err.response ? err.response : err);
  },
);

export default AxiosWrap;
