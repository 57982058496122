/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import NoticeItem from './NoticeItem/index';
import LessonItem from './LessonItem/index';
import { stringifySearchParams } from '../../../util';
import enterLiveRoom from '../../../util/enterLiveRoom';
import TrackRequest from '../../../lib/trackRequest';
import { getCheckExist } from '../../../redux/action/article.action';
import { liveAction } from '../../../redux/action/live.action';
import * as lessonAction from '../../../redux/action/vod.action';
import * as action from '../../../redux/action/course.list.action';
import CourseItem from './CourseItem';
import ResLists from './ResLib/ResLists';
import './ResLib/index.less';
import PreviewMaterial from '../../Lesson/siteResources/previewMaterial';

interface Iprops {
  list: any;
}

function SearchResult(props: Iprops) {
  const { list } = props;
  const [data, setData] = useState<any>({});
  const [previewVisible, setPreviewVisible] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onOpenNotice = async (data: any) => {
    const { noticeId } = data;
    TrackRequest({ name: 'watch_notice', data: { notice_id: noticeId, watch_entrance: 1 } });
    const flg = await getCheckExist(noticeId);
    if (flg) {
      const a = {
        noticeId,
        type: 1,
        leftMenuType: 2,
        fromSearch: true,
      };
      window.open(`/articleSyncPreview${stringifySearchParams(a)}`);
    }
  };

  const onOpenLesson = async (courseInfo: any) => {
    const { lessonType, courseId, id } = courseInfo;
    TrackRequest({
      name: 'watch_period',
      data: {
        course_id: courseId,
        period_id: id,
        period_type: lessonType,
        watch_period_entrance: 3,
      },
    });
    switch (courseInfo.lessonType) {
      case 1: {
        // 直播
        const res1 = await liveAction.getLiveLesson(id);
        if (res1.status !== 200) return;
        enterLiveRoom(id, '2');
        break;
      }
      case 2: // 点播
      case 3: {
        // 资料
        const res2 = await lessonAction.getLessson(id);
        if (res2.status !== 200) return;
        const search = {
          columnId: '000',
          courseName: '123',
          courseId,
          lessonId: id,
          lessonType,
          type: 2,
        };
        window.open(`/vod${stringifySearchParams(search)}`);
        break;
      }
      default:
        break;
    }
  };

  const onOpenCourse = async (dataInfo: any) => {
    const { id } = dataInfo;
    const params = {
      id: '1',
      parentId: '1',
      courseId: id,
      leftMenuType: 2,
      name: '1',
      type: '0',
      fromSearch: true,
    };
    const flag = await action.existCourse(id);
    if (flag) {
      TrackRequest({ name: 'watch_course', data: { course_id: id, watch_entrance: 1 } });
      window.open(`/courseDetail${stringifySearchParams(params)}`);
    }
  };

  const goToPreview = (dataInfo: any) => {
    setData(dataInfo);
    setPreviewVisible(true);
  };

  if (list.length === 0) {
    return <div />;
  }

  return (
    <div className="serach-result-wrap">
      {list.map((item: any, index: number) => {
        const { cate } = item;
        if (cate === 3) {
          return <NoticeItem key={index} data={item} onClick={() => onOpenNotice(item)} />;
        } else if (cate === 1) {
          return <LessonItem key={index} data={item} onClick={() => onOpenLesson(item)} />;
        } else if (cate === 0) {
          return <CourseItem key={index} data={item} onClick={() => onOpenCourse(item)} />;
        } else if (cate === 2) {
          return (
            <ResLists
              key={`${item.resource.resourceGuid}${index}`}
              resourceInfo={item.resource}
              goToPreview={goToPreview}
            />
          );
        }
        return null;
      })}

      {previewVisible && (
        <PreviewMaterial
          resource={data}
          visible={previewVisible}
          extension={data.extension && data.extension.toLowerCase()}
          title={data.fileName && decodeURIComponent(data.fileName)}
          // previewPlayId={data.playId}
          previewUrl={data.cdnUrl || data.url}
          cancel={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
}

export default React.memo(SearchResult);
