import React, { memo } from 'react';
import moment from 'moment';
import { Button, Icon, Tooltip } from 'antd';

function NoticeItem(props: any) {
  const { data, onClick } = props;
  return (
    <div className="notice-item commom-item" onClick={onClick}>
      <div className="notice-left">
        <span className="month">{moment(data.updateTime).format('MM')}月</span>
        <span className="day">{moment(data.updateTime).format('DD')}</span>
      </div>
      <div className="notice-middle">
        <div className="notice-name" dangerouslySetInnerHTML={{ __html: data.highLightName }} />
        <div className="notice-time">
          <span>更新时间：{moment(data.updateTime).format('YYYY-MM-DD HH:mm')}</span>
          {typeof data.expireTime === 'number' && (
            <Tooltip
              overlayClassName="expire-time-user-class"
              title="有效截止时间内查看将会纳入数据统计"
            >
              <span>
                有效截止时间
                <Icon type="info-circle" />：{moment(data.expireTime).format('YYYY-MM-DD HH:mm')}
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="notice-right">
        <Button className="green">观看公告</Button>
      </div>
    </div>
  );
}

export default memo(NoticeItem);
