/* eslint-disable react/no-render-return-value */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';

interface IProps {}

interface IState {
  show: boolean;
  item: any;
  text: string;
}
class ToastTip extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      item: {},
      show: false,
      text: '',
    };
  }
  open = (options: any) => {
    const { text, time } = options;
    if (typeof text === 'string') {
      this.setState({ show: true, text });
      this.close(time || 3000);
    }
  };

  close = (time: number) => {
    setTimeout(() => {
      this.setState({ show: false });
    }, time);
  };

  render() {
    const { show, text } = this.state;
    return (
      show && (
        <div className="toast-wrap">
          <div className="toast">{text}</div>
        </div>
      )
    );
  }
}

const div = document.createElement('div');
document.body.appendChild(div);
const Toast = ReactDOM.render(React.createElement(ToastTip), div);
export default Toast;
