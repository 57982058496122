import './conceptIndex.less';
import 'swiper/dist/css/swiper.min.css';
import React, { Component } from 'react';
import OutHeader from './OutHeader';
import Swiper from 'swiper';
import { MyIcon } from '../../util';
import RegionTitle from './indexComponent/regionTitle';
import Footer from '../../components/Footer';
import mapPageData from '../../util/environmentalMapData';
import landscape from '../../static/landscape.png';

interface P {
  history: any;
  location: any;
}
class HighSchoolConceptIndex extends Component<P, any> {
  private initialSwiperConfig: any; // 初始轮播图配置
  private regionSwiper: any; // 区域轮播图
  constructor(props: P) {
    super(props);
    this.state = {
      currentRegionIndex: 1, // 当前区域1轮播图索引
      pageInfo: null,
    };
    this.initialSwiperConfig = {
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      }, // 可选选项，自动滑动
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    };
  }
  UNSAFE_componentWillMount() {
    this.initPageData();
  }
  componentDidMount() {
    // 轮播图1
    this.productHordelight('#swiper-container-1', this.initialSwiperConfig);
    // 轮播图2
    const bottomSwiperConfig = {
      ...this.initialSwiperConfig,
      autoplay: false,
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 24,
      navigation: {
        nextEl: '.info-bottom-swiper-button-next',
        prevEl: '.info-bottom-swiper-button-prev',
      },
    };
    delete bottomSwiperConfig.scrollbar;
    this.productHordelight('#swiper-container-2', bottomSwiperConfig);
    // 轮播图3
    const regionSwiperConfig = {
      ...this.initialSwiperConfig,
      direction: 'vertical',
      autoHeight: true,
      autoplay: false,
    };
    delete regionSwiperConfig.navigation;
    delete regionSwiperConfig.scrollbar;
    this.regionSwiper = this.productHordelight('#swiper-container-3', regionSwiperConfig);
  }
  // 初始化页面数据
  initPageData() {
    const result = mapPageData();
    // 储存路由
    window.localStorage.setItem('pathSuffix', result.router);
    this.setState({
      pageInfo: result.data,
    });
  }
  // 生成轮播图
  productHordelight(id: string, config: any) {
    return new Swiper(id, { ...config });
  }
  // 切换区域1轮播图
  switchSwiper(index: number) {
    this.regionSwiper.slideTo(index, 500, false);
    this.setState({ currentRegionIndex: index });
  }
  // 友情链接跳转
  friendShipLink(id: string) {
    const link = mapPageData.prototype.idMapLink()[id];
    window.location.href = link;
  }

  render() {
    const { pageInfo, currentRegionIndex } = this.state;
    const { floatingLayer, infoDesc, region1, region2, indexHorselight, friendShipLinks, garden } =
      pageInfo;

    return (
      <div className="conceptIndexPage">
        <OutHeader />
        <div className="header-content">
          {/* ******************************轮播图******************************** */}
          <div className="banner">
            <div id="swiper-container-1" className="swiper-container swiper-no-swiping">
              <div className="swiper-wrapper">
                {indexHorselight.map((img: any, i: number) => {
                  return (
                    <div
                      key={i}
                      style={{
                        background: `url(${img}) no-repeat center`,
                        backgroundSize: 'cover',
                      }}
                      className="swiper-slide"
                    />
                  );
                })}
              </div>
              {/* <div className="swiper-scrollbar"></div> */}
              <div className="swiper-button-prev">
                <MyIcon className="iconfont iconfont-prev" type="iconarrow" />
              </div>
              <div className="swiper-button-next">
                <MyIcon className="iconfont iconfont-next" type="iconarrow" />
              </div>
            </div>
          </div>
          {/* ****************************浮框******************************* */}
          <div className={`floating-layer ${garden ? 'garden-floating-layer' : ''}`}>
            {(floatingLayer.cover_1 || floatingLayer.cover_2) && (
              <div className="floating-layer-title">
                <h2>
                  服务覆盖：
                  {floatingLayer.cover_1 &&
                    floatingLayer.cover_1.map((c: any, i: number) => {
                      return (
                        <p key={i}>
                          <span>{c.value}</span>
                          <span>{c.type}</span>
                        </p>
                      );
                    })}
                  {floatingLayer.cover_2 && (
                    <span className="cover-2">{floatingLayer.cover_2}</span>
                  )}
                </h2>
                {/* <p>文字描述文字描述文字描述文字描述</p> */}
              </div>
            )}
            <div className="floating-layer-statistics">
              {floatingLayer.statistics.map((s: any, i: number) => {
                return (
                  <div key={i} className="statistics">
                    <span>{s.total}+</span>
                    <span>{s.desc}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* ************************信息描述************************** */}
        {infoDesc && (
          <div className={`info-desc ${!infoDesc.swiper ? 'info-desc-height-auto' : ''}`}>
            <div className="info-content">
              {infoDesc.title.map((info: any, i: number) => {
                return (
                  <div className="info" key={i}>
                    <div className="icon">
                      <img src={info.icon} alt="" />
                    </div>
                    <h3>{info.h}</h3>
                    <p>{info.p}</p>
                  </div>
                );
              })}
            </div>
            {/* **********************轮播图**************************** */}
            {infoDesc.swiper && (
              <div className="info-bottom">
                <div id="swiper-container-2" className="swiper-container swiper-no-swiping">
                  <div className="swiper-wrapper">
                    {infoDesc.swiper.map((s: any, j: number) => {
                      return (
                        <div className="swiper-slide" key={j}>
                          <div className="icon">
                            <img src={s.icon} alt="" />
                          </div>
                          <p>
                            <span className="title">{s.t}</span>
                            {s.p.split('#').map((p: string, i: number) => {
                              return <span key={i}>{p}</span>;
                            })}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {!infoDesc.swiperArrowHide && (
                  <div>
                    <div className="info-bottom-swiper-button-prev">
                      <MyIcon className="iconfont iconfont-prev" type="iconarrow" />
                    </div>
                    <div className="info-bottom-swiper-button-next">
                      <MyIcon className="iconfont iconfont-next" type="iconarrow" />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="landscape">
              <img src={landscape} alt="" />
            </div>
          </div>
        )}
        {/* ****************************区域一**************************** */}
        <div className={`region-1 ${garden ? 'garden-margin-top' : ''}`}>
          <RegionTitle title={region1.title.t} p={region1.title.p} />
          <div className="region-1-swiper-box">
            <div className="region-1-swiper-btn">
              {region1.buttonBox.map((b: any, i: number) => {
                return (
                  <div
                    className={`btn ${currentRegionIndex === i + 1 && 'choose-style'}`}
                    key={i}
                    onClick={() => this.switchSwiper(i + 1)}
                  >
                    <img src={currentRegionIndex === i + 1 ? b.icon_2 : b.icon_1} alt="" />
                    <p>
                      {b.p.split('$').map((_p: string, i: number) => {
                        return (
                          <span key={i} className="text">
                            {_p}
                          </span>
                        );
                      })}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="region-1-swiper">
              <div id="swiper-container-3" className="swiper-container swiper-no-swiping">
                <div className="swiper-wrapper">
                  {region1.horselight.map((h: any) => {
                    return (
                      <div className="swiper-slide" key={h.id}>
                        <img src={h.src} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* *************************区域2************************** */}
        <div className="region-2">
          <RegionTitle title="协作掠影" />
          <div className="content-box">
            {region2.content.map((c: any, i: number) => {
              return <img src={c} className="content" key={i} alt="" />;
            })}
          </div>
        </div>
        {/* **************************友情链接***************************** */}
        <div className="region-3">
          <RegionTitle title="友情链接" />
          <div className="region-3-btn-box">
            {friendShipLinks.map((link: any, i: number) => {
              return (
                <div className="btn" key={i} onClick={() => this.friendShipLink(link.id)}>
                  <span>{link.name_1}</span>
                  <span>{link.name_2}</span>
                  <span>{link.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default HighSchoolConceptIndex;
