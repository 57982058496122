export default {
  api: 'https://gateway.open-school.com.cn/teaching_api',
  file: 'https://gateway.eastedu.com/file-service',
  trackApi:
    'https://eastedu-production.cn-shenzhen.log.aliyuncs.com/logstores/open_school/track?APIVersion=0.6.0',
  userCenter: 'https://passport.eastedu.com/wechat/qrcode',
  // resourceCenter: 'http://www.eastedu.org',
  resourceCenterDownload: 'https://member.eastedu.org',
  examinationAndAnalysis: 'http://tktx.eastedu.org:8003',
  ebi: 'https://ebi.eastedu.com',
  ip: 'https://tool.eastedu.com/ip', // ip与服务器时间
  pingguoApi: 'https://gateway.eastedu.com/infrastructure-cache-service',
  userCenterApi: 'https://gateway.eastedu.com/user-center-api',
  markupService: 'https://gateway.eastedu.com/infrastructure-markup-service',
  SENTRY_DSN: 'https://64c08468057744398cc66792ae7abe35@sentry.eastedu.com/8',
  gateway: 'https://gateway.eastedu.com',
  passport: 'https://passports.eastedu.com',
  passport_sdk_token:
    '16mYCLfh5JTd374vVXT2lKWElFQnAwT0lXOW1LNXQwVERIamlEa1hlYVNycXVRVEYvQWRXWGtZdkgwRXlobTNwaFdOTlFvNmQ2Y1FXOWw3eTdKcFFwdnFxbFpYV2d5dTl2VVpQMzFnaStVUnVVTkVWZm4zbGt3QldweFJ1ZVBFclBjZlc3YWFySFB5T2Y5dmhIdGNNdmxYczNCRHVFeitLeThqcHRtWTgzWmw0NTg5d0tXR2x5SGRvRHlBZG5ycnJFa3lpQ2ZKNGFackdnRy9XVXpaVk9KcTVjeDNudDAwWUJOSTZiTzRpZC9RNk5LY0dCSmtsbkYwbnFQMmZSL0VaYmlmNmRXTi93SUpNTitZZENNK3BkM0lQZVl2OHlYaC9xZzEyMDMwTmJmRjlsSGJ1OGRKemkvRUo2dEpRVG51ZDdzRjdqSEI4QlRMdkZqTVV6TzZzYkUxQWpwbVpZL3pZejBnPT0=',
  userAgreement: 'https://u-learning.eastedu.com/privacy-policy/useragreement.html',
  privacyPolicy: 'https://u-learning.eastedu.com/privacy-policy/openschoolprivacypolicy.html',
};
