import './index.less';
import React from 'react';

function Empty() {
  return (
    <div className="empty-page">
      <div>
        <span>请选择栏目！</span>
      </div>
    </div>
  );
}

export default Empty;
