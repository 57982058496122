/* eslint-disable @typescript-eslint/no-empty-interface */
// 直播课时
import React from 'react';
import { connect } from 'react-redux';
import { Form, Radio, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import SelectTeacher from './SelectTeacher';

const formLayout1: any = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const formLayout3: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
interface P extends FormComponentProps {
  isEdit: boolean; // 是否编辑
  live: any; // 编辑数据
  subjectIds: string[];
  teacherAuthority: (t: 0 | 1 | 2, show: boolean) => void;
  hasPowerTeacher: any[];
}
interface S {}

class LiveBroadcast extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.updateLiveInfo();
  }

  componentDidUpdate(prevProps: P) {
    if (prevProps.subjectIds !== this.props.subjectIds) {
      const { resetFields } = this.props.form;
      // 学科变化重置教师
      resetFields(['lecturer', 'assistantTeacher']);
    }
  }

  updateLiveInfo() {
    const {
      form: { setFieldsValue },
      isEdit,
      live,
    } = this.props;
    // 编辑数据回显
    if (isEdit && live) {
      const {
        liveLessonType,
        liveType,
        useType,
        teacherId,
        teacherName,
        assistantId,
        assistantName,
      } = live.asMutable();
      const r = this.checkTeacherAuthority(teacherId, assistantId);
      const liveInfo: { [s: string]: any } = {
        liveLessonType,
        liveType,
        useType,
      };
      if (r[0]) {
        liveInfo.lecturer = `${teacherId}&&${teacherName}`;
      }
      if (assistantId && r[1]) {
        liveInfo.assistantTeacher = `${assistantId}&&${assistantName}`;
      }
      setFieldsValue(liveInfo);
    }
  }

  checkTeacherAuthority(teacherId: number, assistantId: number): boolean[] {
    const { hasPowerTeacher, teacherAuthority } = this.props;
    const hasTeacher = hasPowerTeacher.some((t: any) => t.userId === teacherId);
    const hasAssistantTeacher = assistantId
      ? hasPowerTeacher.some((t: any) => t.userId === assistantId)
      : true;

    teacherAuthority(1, !hasTeacher);
    teacherAuthority(2, !hasAssistantTeacher);
    return [hasTeacher, hasAssistantTeacher];
  }

  // 获取数据
  getItemsValue = () => {
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      this.props.form.validateFields({ firstFields: ['lecturer'] }, (error: any, value: any) => {
        if (!error) {
          const { live, isEdit } = this.props;
          if (isEdit) {
            value.liveId = live.liveId;
          }
          resolve(value);
        } else {
          reject('请填写直播信息！');
        }
      });
    });
  };

  userValidator = (otherTeacherKey: string) => {
    const {
      form: { getFieldValue, getFieldError, setFields },
    } = this.props;

    return (rule: any, value: number, callback: any) => {
      const otherTeacher = getFieldValue(otherTeacherKey);
      const otherTeacherError = getFieldError(otherTeacherKey);
      if (value && value === otherTeacher && !otherTeacherError) {
        callback('主讲老师和助教老师不能为同一个人');
      } else if (otherTeacher && value !== otherTeacher && otherTeacherError) {
        const v: { [k: string]: any } = {};
        v[otherTeacherKey] = {
          value: otherTeacher,
          error: 'success',
        };
        setFields(v);
      }
      callback();
    };
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <div className="model" id="liveBroadcast">
        <h3>直播设置</h3>
        <div className="liveBroadcast">
          <Form>
            <Form.Item {...formLayout3} label="直播场景">
              {getFieldDecorator('liveLessonType', {
                rules: [
                  {
                    required: true,
                    message: '请选择直播场景！',
                  },
                ],
              })(
                <Radio.Group>
                  <Radio value={0}>备课直播</Radio>
                  <Radio value={1}>其他直播</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <Form.Item {...formLayout3} label="直播类型">
              {getFieldDecorator('liveType', {
                rules: [
                  {
                    required: true,
                    message: '请选择直播类型！',
                  },
                ],
                initialValue: 0,
              })(
                <Radio.Group>
                  <Radio value={0}>常规直播</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <Form.Item {...formLayout3} label="使用方式">
              {getFieldDecorator('useType', {
                rules: [
                  {
                    required: true,
                    message: '请选择使用方式！',
                  },
                ],
              })(
                <Radio.Group>
                  <Radio value={0}>网页</Radio>
                  <Radio value={1}>客户端</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <Row>
              <Col span={7}>
                <Form.Item {...formLayout1} className="first-item" label="主讲老师">
                  {getFieldDecorator('lecturer', {
                    rules: [
                      {
                        required: true,
                        message: '请选择主讲老师！',
                      },
                      {
                        validator: this.userValidator('assistantTeacher'),
                      },
                    ],
                  })(<SelectTeacher />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item {...formLayout1} className="first-item" label="助教老师">
                  {getFieldDecorator('assistantTeacher', {
                    rules: [
                      {
                        validator: this.userValidator('lecturer'),
                      },
                    ],
                  })(<SelectTeacher />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  live: state.Lesson.classHourDetail.live,
  subjectIds: state.Live.subjectIds,
  hasPowerTeacher: state.Base.hasPowerTeacher,
});

export default connect(mapStateToProps)(Form.create<P>()(LiveBroadcast));
