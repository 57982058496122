import axios, { AxiosRequestConfig, AxiosPromise, AxiosInstance } from 'axios';
import qs from 'qs';
import apiConfig from '../config/api';
import { getUserId, getStudySectionId, getLeagueId } from '../util';
import getExplorerInfo from '../util/browserInfo';

//埋点
interface wrapAxios extends AxiosInstance {
  (config: AxiosRequestConfig): AxiosPromise;
}
let AxiosWrap: wrapAxios = axios.create();

const request = (params: { name: string; data?: { [key: string]: string | number } }) => {
  // 浏览器
  const explorel = getExplorerInfo();
  // 操作系统
  const osInfo = getOsInfo();
  // ip 地址
  const IP_Obj = (window as any).returnCitySN;
  // 泛在学习用户Id;
  const fzxx_userid = window.sessionStorage.getItem('fzxx_userid') || '';
  const data = {
    __topic__: encodeURIComponent(params.name),
    log: encodeURIComponent(
      JSON.stringify({
        action: params.name,
        user_id: getUserId(),
        user_stage: getStudySectionId(),
        league_id: getLeagueId(), // 联盟ID
        app_code: 'Openlearning', // 应用名缩写
        version_code: '1.1.2', // 应用的当前版本号
        device: '', // 设备型号
        os: osInfo.name,
        os_version: osInfo.version,
        browser: explorel.name,
        browser_version: explorel.version,
        user_ip: IP_Obj ? IP_Obj.cip : '', // 用户IP
        session_id: '', //会话
        school_id: '',
        current_time: Date.now(),
        category: 'web',
        application_id: 'openschool',
        fzxx_userid,
        ...params.data,
      }),
    ),
  };
  const _data = qs.stringify(data, { encode: false });
  AxiosWrap({ url: `${apiConfig.trackApi}&${_data}`, method: 'get' });
};

// 获取操作系统信息
function getOsInfo() {
  var userAgent = navigator.userAgent.toLowerCase();
  var name = 'Unknown';
  var version = 'Unknown';
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows';
    if (userAgent.indexOf('windows nt 5.0') > -1) {
      version = 'Windows 2000';
    } else if (
      userAgent.indexOf('windows nt 5.1') > -1 ||
      userAgent.indexOf('windows nt 5.2') > -1
    ) {
      version = 'Windows XP';
    } else if (userAgent.indexOf('windows nt 6.0') > -1) {
      version = 'Windows Vista';
    } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
      version = 'Windows 7';
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
      version = 'Windows 8';
    } else if (userAgent.indexOf('windows nt 6.3') > -1) {
      version = 'Windows 8.1';
    } else if (
      userAgent.indexOf('windows nt 6.2') > -1 ||
      userAgent.indexOf('windows nt 10.0') > -1
    ) {
      version = 'Windows 10';
    } else {
      version = 'Unknown';
    }
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'Iphone';
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac';
  } else if (
    userAgent.indexOf('x11') > -1 ||
    userAgent.indexOf('unix') > -1 ||
    userAgent.indexOf('sunname') > -1 ||
    userAgent.indexOf('bsd') > -1
  ) {
    name = 'Unix';
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android';
    } else {
      name = 'Linux';
    }
  } else {
    name = 'Unknown';
  }
  return { name, version };
}
export default request;
