import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { updateSearchValue } from '../../redux/action/createSearch.action';
import './index.less';

const { Search } = Input;

interface P {
  placeholder: string;
  enterSearch: (v: string) => void;
}

function CreateSearch(props: P) {
  const { placeholder, enterSearch } = props;
  const { value } = useSelector((store: any) => ({
    value: store.CreateSearch.value,
  }));
  // 用来标识用户是否点击了搜索按钮
  const hasSearched = useRef(false);

  const search = (v: string) => {
    const value = v.trim();
    enterSearch(value);
    hasSearched.current = true;
  };

  const valueChange = (e: any) => {
    const { value } = e.target;
    updateSearchValue(value);
    // 点击清空图标清除
    if (!value && e.type === 'click') {
      // 如果用户没有点击搜索直接清空，不刷新列表，点击了搜索后清空，刷新列表
      if (hasSearched.current) {
        enterSearch(value);
        hasSearched.current = false;
      }
    }
  };

  return (
    <div className="create-search-box">
      <Search
        onChange={valueChange}
        allowClear
        value={value}
        onSearch={search}
        placeholder={placeholder}
        style={{ width: 200, borderRadius: '20px' }}
      />
    </div>
  );
}

export default memo(CreateSearch);
