import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { apiGetMp4ByOcrfmd5, apiGetOcrfByMp4md5 } from '../../../redux/action/vod.action';
import { getFileMd5 } from '../../../util';

interface IFileItem {
  md5: string;
}

interface IProps {
  filemp4: IFileItem[];
  fileocrf: IFileItem[];
  changeFileocrf?: (data: any) => void;
  onChangeMp4LinkOcrf?: (data: boolean) => void;
}

function Mp4OneOcrf(props: IProps, ref: any) {
  const { filemp4, fileocrf, changeFileocrf, onChangeMp4LinkOcrf } = props;
  const [mp4LinkOcrf, setMp4LinkOcrf] = useState<any[]>([]);
  const [ocrfLinkMp4, setOcrfLinkMp4] = useState<any[]>([]);

  const { mp4Md5, ocrfMd5 } = useMemo(() => {
    return {
      mp4Md5: filemp4[0]?.md5,
      ocrfMd5: fileocrf[0]?.md5,
    };
  }, [filemp4, fileocrf]);

  useEffect(() => {
    if (mp4Md5) {
      _getOcrfFilesByMp4(mp4Md5);
    } else if (!mp4Md5) {
      setMp4LinkOcrf([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mp4Md5]);

  useEffect(() => {
    if (ocrfMd5) {
      _getMp4FilesByOcrf(ocrfMd5);
    } else if (!ocrfMd5) {
      setOcrfLinkMp4([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrfMd5]);

  useEffect(() => {
    onChangeMp4LinkOcrf && onChangeMp4LinkOcrf(mp4LinkOcrf.length > 0);
  }, [mp4LinkOcrf]);

  const _getOcrfFilesByMp4 = async (md5: string) => {
    try {
      const { data } = await apiGetOcrfByMp4md5(md5);
      if (Array.isArray(data)) {
        const list = data.map((v) => ({ ...v }));
        setMp4LinkOcrf(list);

        // 当前没有ocrf时就使用关联的赋值
        if (fileocrf.length === 0) {
          changeFileocrf && changeFileocrf(list.slice(0, 1));
        }
        return list;
      }
    } catch (error) {
      console.log(error);
    }

    return undefined;
  };

  const _getMp4FilesByOcrf = async (md5: string) => {
    try {
      const { data } = await apiGetMp4ByOcrfmd5(md5);
      if (Array.isArray(data)) {
        const list = data.map((v) => ({ ...v }));
        setOcrfLinkMp4(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkIsMp4LinkOcrf = async (file: File) => {
    if (fileocrf.length === 0) {
      return;
    }

    try {
      const md5 = await getFileMd5(file);

      // 如果是先上传ocrf的情况，在mp4上传时，需要检测ocrf是否关联了其他的mp4
      if (Array.isArray(ocrfLinkMp4) && ocrfLinkMp4.length > 0) {
        const linkMp4Md5 = ocrfLinkMp4[0].md5;

        if (linkMp4Md5 !== md5) {
          return '当前ocrf文件已经跟其他mp4文件绑定，不允许操作';
        }
      }

      const { data } = await apiGetOcrfByMp4md5(md5);

      // mp4是否关联了其他的ocrf；
      if (Array.isArray(data) && data.length > 0) {
        const ocrfMd5 = data.slice(0, 1)?.[0]?.md5;
        const curOcrfsMd5 = fileocrf.map((v) => v.md5);
        // 当前ocrf关联的就是这个mp4
        if (curOcrfsMd5.includes(ocrfMd5)) {
          return undefined;
        } else {
          return '该mp4文件已经跟其他ocrf文件绑定，不允许操作';
        }
      } else {
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return '网络错误';
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkIsOcrfLinkMp4 = async (file: File) => {
    if (filemp4.length === 0) {
      return;
    }

    try {
      const md5 = await getFileMd5(file);
      const { data } = await apiGetMp4ByOcrfmd5(md5);

      if (Array.isArray(data) && data.length > 0) {
        const mp4Md5 = data.slice(0, 1)?.[0]?.md5;
        const curMp4sMd5 = filemp4.map((v) => v.md5);
        if (curMp4sMd5.includes(mp4Md5)) {
          return;
        } else {
          return '该ocrf文件已经跟其他mp4文件绑定，不允许操作';
        }
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      return '网络错误';
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      checkIsMp4LinkOcrf,
      checkIsOcrfLinkMp4,
    }),
    [checkIsMp4LinkOcrf, checkIsOcrfLinkMp4],
  );

  return <div></div>;
}

export default forwardRef(Mp4OneOcrf);
