/*
 * @Author: gouyang
 * @Description: 微信登录地址
 * @Date: 2019-08-26 16:29:41
 */
// import apiConfig from './api';
// import loginLink from './loginLink';

function getWechatLink(getRedirectUrl = false): string | any {
  // const env = (window as any).__UNION_CENTER_ENV__;
  const redirectUrl = window.localStorage.getItem('REDIRECTURL');
  // let wechatLink = `${apiConfig.userCenter}?appcode=Openlearning&openlogin=true&callback=${loginLink}/blank`;
  let wechatLink = `${window.location.origin}/oauth2/callback`;

  // if (env === 'TEST' || env === 'DEV') {
  //   wechatLink = `${apiConfig.userCenter}?appcode=Openlearning&openlogin=true&callback=${loginLink}/blank?type=isWechat`;
  // }

  // 如果已经记录了重定向地址，就使用重定向地址
  if (redirectUrl) {
    wechatLink = decodeURIComponent(redirectUrl);
    window.localStorage.removeItem('REDIRECTURL');
  }

  if (getRedirectUrl) {
    return {
      wechatLink,
      redirectUrl,
    }
  }
  return wechatLink;
}

export default getWechatLink;
