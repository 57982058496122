import React, { memo, useCallback, useState } from 'react';
import TextEditor from '../../../../components/TextEditors';
import './index.less';

interface P {
  onChange?: (h: string) => void;
  value?: string;
  getEditorObj: (obj: any) => void;
  getPureWordCount: (l: number) => void;
}

const Description = React.forwardRef<P, any>((props) => {
  const { onChange, value, getEditorObj, getPureWordCount } = props;
  const [wordCount, setWordCount] = useState<number>(0);

  const getEditorData = useCallback(
    (h: string) => {
      onChange && onChange(h);
    },
    [onChange],
  );

  const getWordCount = useCallback(
    (l: number) => {
      getPureWordCount(l);
      setWordCount(l);
    },
    [getPureWordCount],
  );

  return (
    <div className="description">
      <div className={`${wordCount > 500 && 'description-error'}`}>
        <TextEditor
          getEditorObj={getEditorObj}
          editorHtml={value}
          getData={(html: string) => getEditorData(html)}
          getWordCount={getWordCount}
        />
      </div>
      <p className="word-count">
        {wordCount > 500 && <span className="text-error">文本内容超过限制！</span>}
        <span>{wordCount}/500</span>
      </p>
    </div>
  );
});

export default memo(Description);
