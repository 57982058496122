import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import apiConfig from './config/api';

if (apiConfig.SENTRY_DSN) {
  Sentry.init({
    dsn: apiConfig.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
