/* eslint-disable @typescript-eslint/no-require-imports */
import {
  SEARCH_RESULT,
  SEARCH_TIMESTAMP,
  SEARCH_TOTAL_NUMBER,
  LOADING,
} from '../types/globalSearch.type';
import { getSearchParams } from '../../util';
import { dispatch } from '../../createStore';
import AxiosWrap from '../../lib/request';
import { globalSearchParams } from '../../pages/GlobalSearch/lib/configParams';

const qs = require('qs');

export const globalSearchKeyword = async (params: any) => {
  const history = (window as any)._history;
  const { text } = params;
  const { search } = window.location;
  const { category } = getSearchParams(search);
  params.category = category;
  history.push(`/global-search?text=${encodeURIComponent(text)}&category=${category || ''}`);
  searchAjax(params, true);
  dispatch({ type: SEARCH_TIMESTAMP, payload: Date.now() });
};

export const searchTotalNumberAjax = async (text: string) => {
  try {
    const { data, status } = await AxiosWrap({
      url: `/global-search-results?${qs.stringify({
        text,
        page: 0,
        size: 0,
      })}`,
      method: 'get',
    });
    if (status === 200) {
      const {
        page: { totalElements },
      } = data;

      dispatch({ type: SEARCH_TOTAL_NUMBER, payload: totalElements });
    } else {
      dispatch({ type: SEARCH_TOTAL_NUMBER, payload: 0 });
    }
  } catch {
    dispatch({ type: SEARCH_TOTAL_NUMBER, payload: 0 });
  }
};

export const searchAjax = async (params: any, isKeywords = false) => {
  const p = { ...globalSearchParams };
  dispatch({
    type: SEARCH_RESULT,
    payload: {
      searchData: [],
      analyzes: [],
      isKeywords: false,
    },
  });
  dispatch({
    type: LOADING,
    payload: true,
  });
  const mergeParams = Object.assign(p, params);
  const text = { text: mergeParams.text };

  try {
    const [searchResults, searchAnalyzes] = await Promise.all([
      AxiosWrap({
        url: `/global-search-results?${qs.stringify(mergeParams)}`,
      }),
      AxiosWrap({
        url: `/global-search-analyzes?${qs.stringify(text)}`,
      }),
    ]);
    if (searchResults.status === 200 && searchAnalyzes.status === 200) {
      dispatch({
        type: SEARCH_RESULT,
        payload: {
          searchData: searchResults.data,
          analyzes: searchAnalyzes.data,
          isKeywords,
        },
      });
    }
    dispatch({
      type: LOADING,
      payload: false,
    });
    return Promise.resolve({
      status: searchResults.status,
      data: {
        searchData: searchResults.data,
        analyzes: searchAnalyzes.data,
        isKeywords,
      },
    });
  } catch (error) {
    dispatch({
      type: LOADING,
      payload: false,
    });
    return Promise.resolve({ status: 400, data: {} });
  }
};
