function formatString(str: string): any {
  let _str = str.replace(/ /gm, '&nbsp;');

  _str = _str
    .split('\n')
    .map((b: string) => {
      return `<p>${b}</p>`;
    })
    .join('');

  return _str;
}

const getBrief = (brief: any) => {
  const newBrief = { html: '', pureText: '' };
  try {
    const parseBrief = JSON.parse(brief);
    if (typeof parseBrief === 'object') {
      const { html, pureText } = parseBrief;
      Object.assign(newBrief, { html, pureText });
    } else if (typeof parseBrief === 'number') {
      Object.assign(newBrief, { html: `${parseBrief}`, pureText: `${parseBrief}` });
    } else {
      Object.assign(newBrief, { html: '', pureText: '' });
    }
  } catch (e) {
    if (typeof brief === 'string') {
      const _brief = formatString(brief);
      Object.assign(newBrief, { html: _brief, pureText: brief });
    } else {
      Object.assign(newBrief, { html: '', pureText: '' });
    }
  }
  return newBrief;
};

export default getBrief;
