/* eslint-disable @typescript-eslint/no-invalid-void-type */
import React from 'react';
import ReactDOM from 'react-dom';
import AlertChild from './AlertChild';

const defaultLoad = 'alertModal';
// 处理特殊处理
const idDispose = (id: string) => {
  return defaultLoad + (id || 'M').charCodeAt(0) + 888;
};
const Alert = {
  open: (text: string, callback?: () => void | null, id?: string, type?: string) => {
    const defaultId = idDispose(id || '') || defaultLoad;
    const loadNode = document.getElementById(defaultId);
    if (!loadNode) {
      const div = document.createElement('div');
      const root = React.createElement(AlertChild, { text, callback, type });
      div.id = idDispose(id || '') || defaultLoad;
      document.body.appendChild(div);
      ReactDOM.render(root, div);
    }
  },
  remove: (id: string) => {
    const defaultId = idDispose(id) || defaultLoad;
    const loadNode = document.getElementById(defaultId);
    loadNode && document.body.removeChild(loadNode);
  },
};
export default Alert;
