/**
 *  @desc    媒体资源播放（视频）
 *  @author  huangyu
 *  @params
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './media.less';
// 引入Flash播放器的SWF文件
// import SWF_PATH from 'video.js/dist/video-js.swf';
// 引入vtt.js
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default class MediaComponent extends Component {
  static defaultProps = {
    pause: true,
    playerCallBack: () => {},
  };
  constructor() {
    super();
    this.state = {
      errorState: false,
      media: null,
    };
  }
  componentDidMount() {
    this.initMedia();
  }

  componentWillUnmount() {
    const { media } = this.state;
    media && media.dispose();
  }
  screenFun = () => {
    const { media } = this.state;
    if (media.isFullscreen_) {
      media.exitFullscreen();
    } else {
      media.requestFullscreen();
    }
  };
  WatchedDomList = () => {
    const videoDom = document.getElementById(
      `${this.props.wrapperClassName || 'video-player'}_html5_api`,
    );
    if (!videoDom) return null;
    return videoDom.addEventListener('timeupdate', () => {
      const watchedList = new Array(videoDom.played.length).fill(null).map((item, index) => ({
        key: index,
        start: videoDom.played.start(index),
        end: videoDom.played.end(index),
      }));
      ReactDOM.render(
        <ul>
          {watchedList.map((watchItem) => (
            <li key={watchItem.key}>
              从{watchItem.start.toFixed(2)}s开始，播放至{watchItem.end.toFixed(2)}s
            </li>
          ))}
        </ul>,
        document.getElementById('watchedListWrapper'),
      );
    });
  };
  initMedia() {
    const media = videojs(
      this.props.wrapperClassName || 'video-player',
      {
        techOrder: ['html5'],
        BigPlayButton: false,
        controlBar: {
          playToggle: true,
          volumeMenuButton: true,
          muteToggle: false,
          fullscreenToggle: true,
          volumePanel: {
            inline: false,
          },
        },
        autoplay: this.props.autoPlay === false ? this.props.autoPlay : true,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
      },
      () => {
        media.src(this.props.mediaAddress);
        media.on('volumechange', () => {
          // 应把音量映射到全局
          media.volume(media.volume());
        });
        media.on('progress', () => {
          // document.getElementsByClassName('vjs-loading-spinner')[0].style.display = 'block';
        });
        // loding
        media.on('canplaythrough', () => {
          document.getElementsByClassName('vjs-loading-spinner')[0].style.display = 'none';
        });
        // this.media.on('pause', this.error);
        // 媒体资源异常处理
        media.on('error', this.error);
        media.on('abort', this.error);
        media.on('pause', () => {
          // console.log('zans');
        });
      },
    );
    this.setState(
      {
        media,
      },
      () => {
        const el = document.getElementsByClassName('vjs-volume-panel')[0];
        document
          .getElementById(`${this.props.wrapperClassName || 'video-player'}_html5_api`)
          .addEventListener('dblclick', this.screenFun);
        document
          .getElementsByClassName('vjs-custom-control-spacer')[0]
          .addEventListener('click', () => {
            if (el.style.display === 'none') {
              el.style.display = 'block';
            } else {
              el.style.display = 'none';
            }
          });
      },
    );
    this.props.playerCallBack(media);
  }
  UNSAFE_componentWillReceiveProps(nexpprops) {
    const { media } = this.state;
    if (nexpprops.mediaAddress && media) {
      media.src(nexpprops.mediaAddress);
      // 重新加载播放器
      media.load();
    }
  }
  error() {
    this.setState({
      errorState: true,
    });
  }
  reload() {
    const { media } = this.state;
    media.load(this.props.mediaAddress);
  }
  render() {
    const { media, errorState } = this.state;
    const { type, wrapperClassName } = this.props;
    if (media) {
      if (this.props.pause) {
        media.pause();
      } else {
        media.play();
      }
    }
    return (
      <div
        className={`media-comtent ${type === 'mp3' ? 'mp3' : ''}`}
        ref={(c) => (this.mediaPlayer = c)}
      >
        <video
          controls
          id={wrapperClassName || 'video-player'}
          preload="auto"
          data-setup="{}"
          className="video-js vjs-default-skin vjs-big-play-centered"
        />
        {/* 调研需求，统计用户播放音频总时长（方案可行） */}
        {/* <div id="watchedListWrapper" style={{ color: '#fff', position: 'absolute', top: 20, left: 20, zIndex: 999 }}>
          {this.WatchedDomList()}
        </div> */}
        <div className={errorState ? 'video-message ' : 'vjs-hidden'}>
          <div className={errorState ? 'video-error' : 'vjs-hidden'}>
            <i className="iconfont icon-vedio_addfail pull-left" />
            <div className="pull-left">
              <span>获取地址失败.....</span>
              <div className="loadPage" onClick={this.reload.bind(this)}>
                刷新页面
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
