import React from 'react';
import { Icon, Tooltip } from 'antd';
import './style.less';

interface P {
  title: string;
  background: string;
  tooltip?: string;
}
function styles(): React.CSSProperties {
  return {
    textIndent: '5px',
  };
}
function Head({ title, background, tooltip }: P) {
  return (
    <div className="title-style" style={{ background }}>
      {title}
      {tooltip && (
        <Tooltip overlayStyle={{ minWidth: '420px' }} title={tooltip}>
          <Icon style={styles()} type="info-circle" />
        </Tooltip>
      )}
    </div>
  );
}

export default Head;
