import React, { forwardRef } from 'react';
import { DatePicker, Form, Input } from 'antd';
import './index.less';
import moment from 'moment';

const { TextArea } = Input;

interface IProps {
  type: string;
  placeholder: string;
  label: string;
  required: boolean;
  defaultValue: any;
  getFieldDecorator: any;
  controlId: string;
}

const DynamicControl = forwardRef((props: IProps) => {
  const { type, placeholder, label, required, defaultValue, controlId } = props;

  const convertValue = (defaultValue: any) => {
    if (type === 'datetime') {
      return defaultValue === null ? defaultValue : moment(defaultValue);
    } else {
      return defaultValue;
    }
  };

  const render = () => {
    if (type === 'datetime') {
      return <DatePicker placeholder={placeholder} showTime />;
    } else if (type === 'textarea') {
      return (
        <TextArea
          placeholder={placeholder}
          style={{ width: 1000 }}
          autosize={{ minRows: 3, maxRows: 5 }}
          maxLength={1000}
        />
      );
    }
  };

  return (
    <Form.Item label={label} className="dc-row">
      {props.getFieldDecorator(controlId, {
        initialValue: convertValue(defaultValue),
        rules: [
          {
            required,
            message: placeholder,
          },
        ],
      })(render())}
    </Form.Item>
  );
});
export default DynamicControl;
