/* eslint-disable react/no-string-refs */
// 资料课时
import React from 'react';
import { Button, Icon, Input, Tooltip, Progress, message } from 'antd';
import fileServiceUpload from '../../../util/fileServiceUpload';

import './upload.less';

interface P {
  accept?: string; // 上传文件类型 默认全部可以上传
  acceptTips?: boolean; // accept提示
  cbackFun?: (data: any) => void; // 回调 拿数据
  disabled?: boolean; // 控制上传按钮禁用
  maxLen?: number; // 上传个数
  data?: any; // 回显数据
  name?: string; // 上传按钮名字
  icon?: string; // 图标
  isShowList?: boolean; // 是否展示列表
  category?: number;
  fileList?: any;
  multiple?: boolean;
}
interface S {
  file: any;
  fileList: any;
  disabled: boolean;
  accept: string; // 上传文件类型
  inputFlg: boolean;
  buttonName: string; // 上传按钮名字
  acceptTips: boolean;
  progressFlg: boolean; // 进度条弹出展示
  percent: number; // 上传进度
  isShowList: boolean; // 是否展示列表
}
let fileItem: any = null;
class ServiceUpload extends React.Component<P, S> {
  private filesLen: number; // 上传文件个数
  private filesIndex: number; // 当前上传文件索引
  private files: any[]; // 上传文件列表
  constructor(props: P) {
    super(props);
    this.state = {
      file: null,
      accept: '',
      disabled: false,
      fileList: [],
      inputFlg: true,
      buttonName: '上传资料',
      acceptTips: false,
      progressFlg: false,
      percent: 0,
      isShowList: true,
    };
    this.filesLen = 0;
    this.filesIndex = -1;
    this.files = [];
  }
  componentDidMount() {
    const { accept, disabled, name, acceptTips, icon, isShowList } = this.props;
    // 编辑时候的数据回显
    // if (fileList && fileList.length > 0) {
    //     this.setState({
    //         fileList: fileList
    //     })
    // }
    //  正常的 封装操作
    const data: any = {};
    // 文件类型
    if (accept) {
      data.accept = accept;
    }
    if (typeof disabled === 'boolean') {
      data.disabled = disabled;
    }
    if (name) {
      data.buttonName = name;
    }
    if (typeof acceptTips === 'boolean') {
      data.acceptTips = acceptTips;
    }
    if (icon) {
      data.icon = icon;
    }
    if (typeof isShowList === 'boolean') {
      data.isShowList = isShowList;
    }
    this.setState({
      ...data,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { disabled, accept, fileList } = this.props;
    if (disabled !== nextProps.disabled) {
      if (typeof nextProps.disabled === 'boolean') {
        this.setState({
          disabled: nextProps.disabled,
        });
      }
    }
    if (accept !== nextProps.accept) {
      this.setState({
        accept: nextProps.accept,
      });
    }
    if (fileList !== nextProps.fileList) {
      this.setState({
        fileList: nextProps.fileList,
      });
    }
  }
  // 上传文件主方法
  uploadMain() {
    const { accept } = this.props;
    this.filesIndex += 1;
    if (this.filesIndex >= this.filesLen) {
      this.getData();
      this.filesIndex = -1;
      this.files = [];
      this.filesLen = 0;
      return null;
    }
    const file = this.files[this.filesIndex];
    if (file && file.size > 1024 * 1024 * 1024 * 2) {
      message.error('文件大小不能超过2G！');
      this.uploadMain();
      return;
    }
    const fileName = file.name;
    const index1 = fileName.lastIndexOf('.');
    let suffix = fileName.substring(index1, fileName.length); // 后缀名
    suffix = suffix.toLowerCase();
    if (fileName.substring(0, index1).length > 100) {
      message.error('文件名超过100个字符！');
      this.uploadMain();
      return;
    }
    // 上传格式验证
    if (accept && file && file.name) {
      const arr = accept.split(',');
      let flg = true;
      if (!arr[arr.length - 1]) {
        arr.pop();
      }
      for (const i in arr) {
        if (arr[i] === suffix) {
          flg = false;
        }
      }
      if (flg) {
        message.error(`上传文件格式错误！`);
        this.uploadMain();
        return;
      }
    }
    if (file.size <= 0) {
      message.error('文件大小不能小于0！');
      this.uploadMain();
      return;
    }

    if (file) {
      const { icon, category } = this.props;
      fileItem = {
        id: String(Date.now()),
        category,
        icon,
        name: file.name,
        status: 'start',
        message: '',
        path: '',
      };
      this.setState(
        {
          disabled: true,
          percent: 0,
        },
        () => {
          fileServiceUpload(file, this.fileUpload);
        },
      );
    }
  }
  // 文件上传变化函数
  fileChange = (e: any) => {
    this.filesLen = e.target.files.length;
    this.files = e.target.files;
    this.uploadMain();
  };
  // 文件上传方法
  fileUpload = (data: any) => {
    const { maxLen } = this.props;
    let { fileList, disabled, progressFlg, percent } = this.state;
    fileItem.status = data.status;
    fileItem.message = data.message;
    if (data.status === 'start') {
      // 开始上传
      disabled = true;
      progressFlg = true;
    } else if (data.status === 'progress') {
      // 上传中
      disabled = true;
      fileItem.percent = data.percent * 100;
      percent = data.percent * 100;
    } else if (data.status === 'done') {
      // 上传完成
      disabled = false;
      progressFlg = false;
      fileItem.path = data.path;
      fileItem.data = data.data;
      fileList.unshift(fileItem);
      message.success(data.message);
    } else if (data.status === 'error') {
      // 上传失败
      disabled = false;
      progressFlg = true;
      message.error(data.message);
    } else if (data.status === 'cance') {
      // 取消上传
      disabled = false;
      progressFlg = false;
      message.error(data.message);
    }
    if (maxLen) {
      fileList = fileList.slice(0, maxLen);
    }
    this.setState(
      {
        fileList,
        disabled,
        progressFlg,
        percent: Math.round(percent),
      },
      () => {
        if (data.status === 'done') {
          this.uploadMain();
        }
      },
    );
  };
  // 打开 文件上传页
  clickUpload = () => {
    this.setState(
      {
        inputFlg: false,
      },
      () => {
        this.setState(
          {
            inputFlg: true,
          },
          () => {
            const { inputText } = this.refs as any;
            inputText.input.click();
          },
        );
      },
    );
  };

  // 删除
  del = (id: string) => {
    const { fileList } = this.state;
    const data: any[] = [];
    fileList.forEach((item: any) => {
      if (item.id !== id) {
        data.push(item);
      }
    });
    this.setState(
      {
        fileList: data,
      },
      this.getData,
    );
  };
  // 获取数据
  getData = () => {
    const { cbackFun } = this.props;
    const { fileList } = this.state;
    const len = fileList.length;
    const data: any = [];
    for (let i = 0; i < len; i++) {
      if (fileList[i].status === 'done') {
        data.push(fileList[i]);
      }
    }
    if (cbackFun) {
      cbackFun([...data]);
    }
  };
  // 关闭
  cancel = () => {
    this.setState({
      progressFlg: false,
    });
  };
  render() {
    const {
      fileList,
      disabled,
      accept,
      acceptTips,
      inputFlg,
      buttonName,
      progressFlg,
      percent,
      isShowList,
    } = this.state;
    const { multiple } = this.props;

    return (
      <div className="upload">
        {inputFlg ? (
          <Input
            multiple={multiple || false}
            type="file"
            name="file"
            ref="inputText"
            accept={accept}
            onChange={this.fileChange}
            id="uploadInput"
            style={{ display: 'none' }}
          />
        ) : (
          ''
        )}
        <Button icon="upload" disabled={disabled} onClick={this.clickUpload}>
          {buttonName}
        </Button>
        {accept && acceptTips ? (
          <span
            className="uploadDescribe"
            title={`支持上传文件类型为：${acceptConversion(accept)}`}
          >
            支持上传文件类型为：{acceptConversion(accept)}
          </span>
        ) : (
          ''
        )}
        {isShowList ? (
          <div className="fileList">
            {[...fileList].reverse().map((item: any) =>
              item.status === 'error' ? (
                <Tooltip placement="top" title={item.message} key={item.id}>
                  <div>
                    <div className="list error">
                      <span className="icon">{switchIcon(item.icon)}</span>
                      <span className="name">{item.name}</span>
                      <span className="close">
                        <Icon type="close" onClick={() => this.del(item.id)} />
                      </span>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <div key={item.id}>
                  <div className="list">
                    <span className="icon">
                      {
                        // eslint-disable-next-line
                        item.status === 'start' || item.status === 'progress' ? (
                          <Icon type="loading" />
                        ) : item.status === 'done' ? (
                          switchIcon(item.icon)
                        ) : (
                          <Icon type="paper-clip" />
                        )
                      }
                    </span>
                    <span className="name">{item.name}</span>
                    <span className="close">
                      <Icon type="close" onClick={() => this.del(item.id)} />
                    </span>
                  </div>
                </div>
              ),
            )}
          </div>
        ) : (
          ''
        )}
        {/* {
                    progressFlg ? <UploadModal percent={percent} fileItem={fileItem} cancel={this.cancel} /> : ''
                } */}
        {progressFlg ? (
          <div>
            <Progress
              percent={percent}
              status={fileItem.status === 'error' ? 'exception' : 'normal'}
            />
            {fileItem.status === 'error' ? <span style={{ color: 'red' }}>上传失败</span> : ''}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
const switchIcon = (str: string) => {
  if (str) {
    return <span className={`iconfont ${str}`} />;
  } else {
    return <Icon type="paper-clip" />;
  }
};
const acceptConversion = (str: string) => {
  const arr = str.split(',');
  const data: any[] = [];
  arr.forEach((_, i) => {
    data.push(arr[i].replace('.', ''));
  });
  return data.join('，');
};
export default ServiceUpload;
