import React from 'react';
import { NormalObj } from '../../../util';
import moment, { Moment } from 'moment';
import List from './List';
import SetExpireModal from './SetExpireModal';

interface P {
  data: NormalObj;
  delResourcesList: (id: string) => void; // 删除资源
  delFiles: (id: string, filesId: number) => void; // 删除文件
  upload: (data: any) => void; // 继续上传
  onSetExpire: (form: any, id: any) => void;
}
interface S {
  activeId: string;
  type: number;
  date: Moment;
  isOpen: boolean;
  curID: any;
  curData: object;
}
class ResourcesList extends React.Component<P, S> {
  formRef: any;
  constructor(props: P) {
    super(props);
    this.state = {
      activeId: '',
      type: 1,
      date: moment(),
      isOpen: false,
      curID: '',
      curData: {},
    };
  }
  // 展开资源
  openResources = (id: string) => {
    let { activeId } = this.state;
    if (activeId === id) {
      activeId = '';
    } else {
      activeId = id;
    }
    this.setState({
      activeId,
    });
  };
  openExpireModal = (data: any) => {
    let type = 1;
    if (data.expiryDate) {
      type = 2;
    }
    this.setState({ isOpen: true, curID: data.resourceGuid, curData: data, type });
  };

  handleSubmit = () => {
    const { curID } = this.state;
    const form = this.formRef.modalForm;
    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      this.props.onSetExpire(values, curID);
      form.resetFields();
      this.setState({ isOpen: false });
    });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
  saveFormRef = (formRef: any) => {
    this.formRef = formRef;
  };

  onChange = (e: any) => {
    this.setState({ type: e.target.value }, () => {
      const form = this.formRef.modalForm;
      form.validateFields(['date'], { force: true });
      form.resetFields();
    });
  };

  render() {
    const { activeId, isOpen, curData, type } = this.state;
    const { data, delResourcesList, delFiles, upload } = this.props;

    return (
      <div className="materialTable">
        {data.map((item: any, index: number) => (
          <List
            key={index}
            data={item}
            openResources={this.openResources}
            activeId={activeId}
            delResourcesList={delResourcesList}
            delFiles={delFiles}
            upload={upload}
            openExpireModal={this.openExpireModal}
          />
        ))}

        <SetExpireModal
          type={type}
          data={curData}
          wrappedComponentRef={this.saveFormRef}
          isOpen={isOpen}
          onChange={this.onChange}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default ResourcesList;
