/*
 * @Author: gouyang
 * @Description: 创建文章资料列表
 * @Date: 2019-07-18 14:40:04
 */
import React from 'react';
import { Icon, Progress } from 'antd';
import { MyIcon } from './../../../util';

import './upload.less';

interface P {
  isScorll?: boolean;
  fileList: any;
  fileDelCback?: (data: any[], isDelUpload?: any) => void; // 删除数据回调
}
class FileList extends React.Component<P> {
  // 删除
  del = (index: number, targetData: any) => {
    if (targetData.cancelFn) {
      targetData.cancelFn();
      return null;
    }
    const { fileDelCback, fileList } = this.props;
    const data = [...fileList];
    data.splice(index, 1);
    if (fileDelCback) {
      targetData.status === 'upload' ? fileDelCback(data, targetData) : fileDelCback(data);
    }
  };

  render() {
    const { isScorll, fileList } = this.props;
    return (
      <div className="upload">
        <div
          className="fileList"
          style={isScorll ? {} : { overflowY: 'visible', maxHeight: 'none' }}
        >
          {fileList.map((item: any, index: number) => (
            <div key={item.id || item.key}>
              <div className="list">
                <span className="icon">{switchIcon(item.icon)}</span>
                <span className="name" title={item.name}>
                  {item.name}
                </span>
                <span className="close">
                  <Icon className="close-icon" type="close" onClick={() => this.del(index, item)} />
                </span>
              </div>
              {item.status !== 'done' && (
                <div className="progress">
                  <Progress
                    showInfo={item.status !== 'error'}
                    strokeWidth={4}
                    percent={item.percent}
                    status={item.status === 'error' ? 'exception' : 'normal'}
                  />
                  {item.status === 'error' && <span className="upload-error">上传失败</span>}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const switchIcon = (str: string) => {
  if (str) {
    return <MyIcon className="iconfont" type={str} />;
  } else {
    return <Icon type="paper-clip" />;
  }
};
export default FileList;
