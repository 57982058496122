import { message } from 'antd';
import AxiosWrap from '../../lib/request';
import * as types from '../types/login.type';
import TrackRequest from '../../lib/trackRequest';
import getWechatLink from './../../config/wechatLink';
import { signOut } from '../../lib/api';
// import { oauth2Logout } from '../../pages/LoginOauth';
import { logout } from 'eastedu-login-sdk';

export function login(params: object, success: (res: any) => void, fail: (err: any) => void) {
  AxiosWrap({
    url: '/auth/login',
    method: 'post',
    data: params,
    onlyLogin: true,
  })
    .then((res: any) => success(res))
    .catch((err: any) => fail(err));
}
export async function loginOut() {
  // 退出登陆埋点
  TrackRequest({ name: 'logout' });
  await signOut();
  // 退出登陆清空数据
  const loginLink = getWechatLink(true);

  const DECLARE = window.localStorage.getItem('DECLARE');
  window.localStorage.clear();
  window.localStorage.setItem('DECLARE', DECLARE || '');
  // message.info('您已退出！', 1, () => (window.location.href = loginLink));
  message.info('您已退出！', 1, () => {
    if (loginLink?.redirectUrl) {
      window.location.href = decodeURIComponent(loginLink.redirectUrl);
      return;
    }
    // oauth2Logout();
    logout('/oauth2/callback');
  });
  // message.info('您已退出！', 1, () => (window as any)._history.replace(getPathSuffix()));
}
export function getUnionList(success: (data: []) => void) {
  // AxiosWrap({
  //     url: '/current_users/all_leagues',
  // }).then((res: any) => {
  //     (window as any)._dispatch({ type: types.GET_UNION_LIST, payload: res.data });
  //     success(res.data);
  // })
  // 因为 登陆的时候 要埋点，获取联盟跟 获取用户信息是2个接口，返回时间不一定，所以放在一起，好做判断
  Promise.all([
    AxiosWrap({ url: '/current_users/all_leagues' }),
    AxiosWrap({ url: '/current_users/user_info' }),
  ]).then((values: any) => {
    const leagues = values[0];
    const userInfo = values[1];
    // 用户信息
    if (userInfo && userInfo.status === 200) {
      const obj = window.localStorage.getItem('userInfo')
        ? JSON.parse((window as any).localStorage.getItem('userInfo'))
        : {};
      Object.assign(obj, userInfo.data);
      window.localStorage.setItem('userInfo', JSON.stringify(obj));
      window.localStorage.setItem('mainUserInfo', JSON.stringify(obj));
    }
    // 联盟
    if (leagues && leagues.status === 200) {
      (window as any)._dispatch({ type: types.GET_UNION_LIST, payload: leagues.data });
      success(leagues.data);
    }
  });
}
export async function getUser(success?: () => void) {
  const { data } = await AxiosWrap({ url: '/current_users/user_info' });
  const obj = window.localStorage.getItem('userInfo')
    ? JSON.parse((window as any).localStorage.getItem('userInfo'))
    : {};
  Object.assign(obj, data);
  (window as any)._dispatch({
    type: types.GET_SWITCH_LOGIN_AUTH,
    payload: obj.allowAccountAndPasswordLogin,
  });
  window.localStorage.setItem('userInfo', JSON.stringify(obj));
  success && success();
}

// 获取主账号信息
export async function getMainUserInfo(success?: () => void) {
  const { data } = await AxiosWrap({ url: '/current_users/user_info', noUserId: true });
  const obj = window.localStorage.getItem('userInfo')
    ? JSON.parse((window as any).localStorage.getItem('userInfo'))
    : {};
  if (!obj.userId) {
    window.localStorage.setItem('userInfo', JSON.stringify({ ...obj, userId: data.userId }));
  }
  Object.assign(obj, data);
  window.localStorage.setItem('mainUserInfo', JSON.stringify(obj));
  success && success();
}

export async function getSubUsers() {
  const { data } = await AxiosWrap({ url: '/auth/accounts' });
  (window as any)._dispatch({ type: types.GET_SUB_USERS, payload: data });
}

export async function getList(id: any, success: (params: any) => void, userInfo: {}) {
  const { data } = await AxiosWrap({
    url: '/auth/leagues',
    headers: { relationUserId: id },
    data: 'noLeagueId',
  });
  if (data && data.length > 0) {
    (window as any)._dispatch({ type: types.GET_UNION_LIST, payload: data });
    const obj = window.localStorage.getItem('userInfo')
      ? JSON.parse((window as any).localStorage.getItem('userInfo'))
      : {};
    Object.assign(obj, userInfo);
    window.localStorage.setItem('userInfo', JSON.stringify(obj));
    window.localStorage.setItem('ISREAD', 'false');
    // window.localStorage.removeItem('unionId');
    success(data);
  }
}

export async function getLiving(success?: (data: any[]) => void) {
  const { data } = await AxiosWrap({
    url: '/living_lessons',
    ignoreLoading: true,
  });
  (window as any)._dispatch({ type: types.GET_LIVING_LESSONS, payload: data });
  success && success(data);
}
