// 文件上传的 格式
const data: any = {};
Object.defineProperties(data, {
  // 点播视频格式
  onDemand: {
    value: '.mp4',
  },
  // 素材
  sourceMaterial: {
    value:
      '.jfif,.pjpeg,.jpeg,.pjp,.jpg,.png,.gif,.swl,.swf,.mp3,.wma,.wav,.mp4,.m4v,.avi,.mkv,.flv,.rm,.3gp,.asf,' +
      '.rmvb,.xls,.xlsx,.text,.txt,.doc,.docx,.pdf,.zip,.rar,.7z',
  },
  // 课件
  courseware: {
    value: '.ppt,.pptx',
  },
  // 教案
  teachingPlan: {
    value: '.doc,.docx',
  },
  // 普通 作业 / 试卷
  task: {
    value: '.doc,.docx,.pdf,.mp3,.wma,.wav',
  },
});

export default data;

// 获取权限
export const getRoot = (name: string) => {
  const str = window.localStorage.getItem('permissions');
  const data = str ? JSON.parse(str) : null;
  if (data) {
    let arr: any = [];
    for (const i in data) {
      if (data[i].code === name) {
        if (data[i].ruleContent && typeof data[i].ruleContent === 'string') {
          const item = JSON.parse(data[i].ruleContent);
          arr = [...arr, ...item.lessonType];
        }
      }
    }
    const list: any = new Set(arr);
    if (list.size === 0) {
      return false;
    } else {
      return [...list];
    }
  } else {
    return false;
  }
};
