/*
 * @Descripttion: 消息列表
 * @Author: gouyang
 * @Date: 2020-12-08 15:11:17
 * @LastEditors: gouyang
 * @LastEditTime: 2020-12-18 16:41:42
 */
import React, { memo, useCallback, useEffect } from 'react';
import { Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import UserTable from '../Components/UserTable';
import { getSearchParams } from '../../../util';
import { getMessageLists } from '../../../redux/action/message.action';
import './index.less';

const { TabPane } = Tabs;

function MessageLists() {
  const dispatch = useDispatch();
  const { systemMessageLists, importantMessageLists, activeKey } = useSelector((state: any) => ({
    systemMessageLists: state.Message.systemMessageLists,
    importantMessageLists: state.Message.importantMessageLists,
    activeKey: state.Message.activeKey,
  }));

  const onKeyChange = useCallback(
    (key: string) => {
      const size = key === '0' ? systemMessageLists.size : importantMessageLists.size;
      getMessageLists(0, size, key as '0' | '1');
    },
    [systemMessageLists, importantMessageLists],
  );

  const onSystemChange = useCallback((page: number, size?: number) => {
    getMessageLists(page - 1, size || 10, '0');
  }, []);

  const onImportantChange = useCallback((page: number, size?: number) => {
    getMessageLists(page - 1, size || 10, '1');
  }, []);

  // 注册获取数据方法
  useEffect(() => {
    dispatch({ type: 'GETDATABYROOT', payload: () => {} });
    return () => {
      dispatch({ type: 'RESET_ACTIVEKEY' });
    };
  }, [dispatch]);

  useEffect(() => {
    const { d } = getSearchParams();
    const PAGEINFO = window.sessionStorage.getItem('PAGEINFO');
    if (d && PAGEINFO) {
      const { page, size, type } = JSON.parse(PAGEINFO);
      getMessageLists(page - 1, size, type);
      window.sessionStorage.removeItem('PAGEINFO');
    } else {
      getMessageLists(0, 10, '0');
    }
  }, []);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, [systemMessageLists, importantMessageLists]);

  return (
    <div className="message-lists">
      <Tabs
        activeKey={activeKey}
        onChange={onKeyChange}
        tabBarGutter={48}
        tabBarStyle={{ marginBottom: 0, paddingLeft: '32px' }}
        animated={false}
      >
        <TabPane tab="站内信" key="0">
          <UserTable
            activeKey={activeKey}
            onChange={onSystemChange}
            dataSource={systemMessageLists}
          />
        </TabPane>
        <TabPane tab="重要通知" key="1">
          <UserTable
            activeKey={activeKey}
            onChange={onImportantChange}
            dataSource={importantMessageLists}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default memo(MessageLists);
