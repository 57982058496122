import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Tablist from '../../../components/Tablist';
import {
  getTextbooks,
  getChaptersTree,
  getStudySections,
  getGrades,
  getSubjects,
} from '../../../redux/action/base.action';
import { REGISTER_CLEAR_FILTER } from '../../../redux/types/globalSearch.type';
import { getStudySectionId, getSubjectId, isPingguoUnion } from '../../../util';
import { useFilterInfo } from './CustomizeHooks';
import { FilterParams } from '../resourceInterface';
import './filterResources.less';

interface P {
  // force是否强制请求
  filterParamsDidObtain?: (params: FilterParams, force?: boolean) => {};
  dispatchFilterParams: (params: FilterParams) => void;
  initFilterParams: FilterParams;
  hideCategory?: boolean;
  category?: string;
  isGlobalSearch?: boolean;
}

function FilterResources(props: P) {
  const { filterParamsDidObtain, dispatchFilterParams, initFilterParams } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resourceTypes, grades, subject, schoolPeriodList] = useFilterInfo();

  const [currentActiveKey, setCurrentActiveKey] = useState({
    resourceTypes: 0,
    grade: 0,
    subject: 0,
    schoolPeriod: 0,
    schoolSection: 0,
  });

  // useEffect(() => {
  //   if (category && category != 3) {
  //     initData();
  //   }
  // }, [category]);

  const [materialParams, setMaterialParams] = useState({
    materialId: '',
    disabled: true,
    chapterId: [],
    subjectCodeString: getSubjectId().join('|'),
    studySectionCodeString: getStudySectionId(),
  });
  const { materialId, disabled, chapterId, subjectCodeString, studySectionCodeString } =
    materialParams;
  const { textBooks, chaptersTree } = useSelector((state: any) => ({
    textBooks: state.Base.textBooks,
    chaptersTree: state.Base.chaptersTree,
  }));

  const { pingguoStudySections, pingguoGrades, pingguoSubjects } = useSelector((state: any) => ({
    pingguoStudySections: state.Base.studySectionsList,
    pingguoGrades: state.Base.gradesList,
    pingguoSubjects: state.Base.subjectsList,
  }));

  const handleChange = (o: { [key: string]: any }, key?: number | string) => {
    const currentKey = parseInt(`${key || 0}`, 10);
    const updateActiveKey: { [key: string]: number } = {};
    let updateFilterData: { [key: string]: any } = {};
    let tempFilterData = { ...initFilterParams, page: 0 };

    // eslint-disable-next-line no-void
    if (o.textbook !== void 0) {
      tempFilterData = { ...tempFilterData, materialId: o.textbook, chapterId: '' };
      updateFilterData = {
        materialId: o.textbook,
        chapterId: [],
        disabled: !o.textbook,
      };
    } else if (o.resourceTypes) {
      updateActiveKey.resourceTypes = currentKey;
      tempFilterData = { ...tempFilterData, category: o.resourceTypes.code };
    } else if (o.grade) {
      updateActiveKey.grade = currentKey;
      tempFilterData = { ...tempFilterData, gradeId: o.grade.code };
    } else if (o.subject) {
      const subjectCodeString = o.subject.code ? o.subject.code : getSubjectId().join('|');
      updateActiveKey.subject = currentKey;
      tempFilterData = {
        ...tempFilterData,
        subjectId: o.subject.code,
        chapterId: '',
        materialId: '',
      };
      updateFilterData = {
        chapterId: [],
        disabled: true,
        materialId: '',
        subjectCodeString,
      };
    } else if (o.schoolPeriod) {
      tempFilterData = { ...tempFilterData, schoolPeriod: o.schoolPeriod.code };
      updateActiveKey.schoolPeriod = currentKey;
    } else if (o.tree) {
      tempFilterData = { ...tempFilterData, chapterId: o.tree[o.tree.length - 1] };
      updateFilterData = {
        chapterId: o.tree,
      };
    }

    dispatch({ type: 'RESET_RESOURCE_STATE' });
    setCurrentActiveKey({ ...currentActiveKey, ...updateActiveKey });
    setMaterialParams({ ...materialParams, ...updateFilterData });
    dispatchFilterParams({ ...tempFilterData });
    filterParamsDidObtain && filterParamsDidObtain({ ...tempFilterData });
  };

  const handlePingguoChange = (o: { [key: string]: any }, key?: number | string) => {
    const currentKey = parseInt(`${key || 0}`, 10);
    const updateActiveKey: { [key: string]: number } = {};
    let updateFilterData: { [key: string]: any } = {};
    let tempFilterData = { ...initFilterParams, page: 0 };

    if (o.schoolSection) {
      updateActiveKey.schoolSection = currentKey;
      getGrades(o.schoolSection.code);
      getSubjects(o.schoolSection.code);
      updateActiveKey.grade = 0;
      updateActiveKey.subject = 0;
      tempFilterData = {
        ...tempFilterData,
        schoolSection: o.schoolSection.code,
        gradeId: '',
        subjectId: '',
      };
    } else if (o.resourceTypes) {
      updateActiveKey.resourceTypes = currentKey;
      tempFilterData = { ...tempFilterData, category: o.resourceTypes.code };
    } else if (o.grade) {
      updateActiveKey.grade = currentKey;
      tempFilterData = { ...tempFilterData, gradeId: o.grade.code };
    } else if (o.subject) {
      const subjectCodeString = o.subject.code ? o.subject.code : getSubjectId().join('|');
      updateActiveKey.subject = currentKey;
      tempFilterData = {
        ...tempFilterData,
        subjectId: o.subject.code,
        chapterId: '',
        materialId: '',
      };
      updateFilterData = {
        chapterId: [],
        disabled: true,
        materialId: '',
        subjectCodeString,
      };
    }

    dispatch({ type: 'RESET_RESOURCE_STATE' });
    setCurrentActiveKey({ ...currentActiveKey, ...updateActiveKey });
    setMaterialParams({ ...materialParams, ...updateFilterData });
    dispatchFilterParams({ ...tempFilterData });
    filterParamsDidObtain && filterParamsDidObtain({ ...tempFilterData });
  };

  // const resourceFilter = {
  //   data: resourceTypes,
  //   type: 'resourceTypes',
  //   activeKey: currentActiveKey['resourceTypes'],
  //   tabChange: handleChange,
  // };

  const studySectionFilter = {
    data: pingguoStudySections || [],
    type: 'schoolSection',
    activeKey: currentActiveKey.schoolSection,
    tabChange: handlePingguoChange,
  };
  const gradeFilter = {
    data: isPingguoUnion() ? pingguoGrades : grades || [],
    type: 'grade',
    activeKey: currentActiveKey.grade,
    tabChange: isPingguoUnion() ? handlePingguoChange : handleChange,
  };

  const subjectFilter = {
    data: isPingguoUnion() ? pingguoSubjects : subject || [],
    type: 'subject',
    activeKey: currentActiveKey.subject,
    tabChange: isPingguoUnion() ? handlePingguoChange : handleChange,
  };
  const schoolPeriodFilter = {
    data: schoolPeriodList || [],
    type: 'schoolPeriod',
    activeKey: currentActiveKey.schoolPeriod,
    tabChange: handleChange,
  };
  const textBookProps = {
    materialId,
    data: textBooks,
    type: 'textbook',
    tabChange: handleChange,
  };
  const treeProps = {
    disabled,
    chapterId,
    data: chaptersTree,
    type: 'tree',
    tabChange: handleChange,
  };

  const initData = useCallback(async () => {
    if (isPingguoUnion()) {
      const studySections = await getStudySections();
      if (!props.isGlobalSearch && studySections.length > 0) {
        // setCurrentActiveKey(previewValue => {
        //   return { ...previewValue, schoolSection: 1 };
        // });
        await getGrades(studySections[0].code);
        await getSubjects(studySections[0].code);

        // await dispatch({ type: 'RESET_RESOURCE_STATE' });
        const tempFilterData = {
          ...props.initFilterParams,
          page: 0,
          schoolSection: studySections[0].code,
        };
        props.dispatchFilterParams(tempFilterData);
        props.filterParamsDidObtain && props.filterParamsDidObtain(tempFilterData, true);
      } else {
        await getGrades();
        await getSubjects();
      }
    } else {
      const tempFilterData = { ...props.initFilterParams, page: 0 };
      props.dispatchFilterParams(tempFilterData);
      props.filterParamsDidObtain && props.filterParamsDidObtain(tempFilterData, true);
    }
  }, [props]);

  // async function initData() {
  //   if (isPingguoUnion()) {
  //     const studySections = await getStudySections();
  //     if (!props.isGlobalSearch && studySections.length > 0) {
  //       setCurrentActiveKey(previewValue => {
  //         return { ...previewValue, schoolSection: 1 };
  //       });
  //       await getGrades(studySections[0].code);
  //       await getSubjects(studySections[0].code);

  //       // await dispatch({ type: 'RESET_RESOURCE_STATE' });
  //       let tempFilterData = { ...props.initFilterParams, page: 0, schoolSection: studySections[0].code };
  //       dispatchFilterParams(tempFilterData);
  //       props.filterParamsDidObtain && props.filterParamsDidObtain(tempFilterData, true);
  //     } else {
  //       await getGrades();
  //       await getSubjects();
  //     }
  //   } else {
  //     let tempFilterData = { ...props.initFilterParams, page: 0 };
  //     dispatchFilterParams(tempFilterData);
  //     props.filterParamsDidObtain && props.filterParamsDidObtain(tempFilterData, true);
  //   }
  // }

  /* eslint-disable */
  useLayoutEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    getTextbooks({ subjectCodeString, studySectionCodeString });
  }, [subjectCodeString, studySectionCodeString]);

  useEffect(() => {
    if (materialId) {
      getChaptersTree({ textBookGuid: materialId });
    }
  }, [materialId]);

  useEffect(() => {
    dispatch({
      type: REGISTER_CLEAR_FILTER,
      payload: () => {
        setCurrentActiveKey({
          resourceTypes: 0,
          grade: 0,
          subject: 0,
          schoolPeriod: 0,
          schoolSection: 0,
        });
        setMaterialParams({
          materialId: '',
          disabled: true,
          chapterId: [],
          subjectCodeString: getSubjectId().join('|'),
          studySectionCodeString: getStudySectionId(),
        });
      },
    });
    return () => {
      dispatch({ type: REGISTER_CLEAR_FILTER, payload: () => {} });
    };
  }, [dispatch]);

  return (
    <div className="filter-resources">
      {/* {!hideCategory && <Tablist key={resourceFilter.type} {...resourceFilter} />} */}
      {isPingguoUnion() && (
        <Tablist
          notAddAll={!props.isGlobalSearch}
          key={studySectionFilter.type}
          {...studySectionFilter}
        />
      )}
      <Tablist key={gradeFilter.type} {...gradeFilter} />
      <Tablist key={subjectFilter.type} {...subjectFilter} />
      {!isPingguoUnion() && <Tablist key={schoolPeriodFilter.type} {...schoolPeriodFilter} />}
      {!isPingguoUnion() && (
        <div className="combine-box">
          <div className="combine-box-title">教材章节 ：</div>
          <div className="combine-box-left">
            <Tablist key={textBookProps.type} {...textBookProps} />
          </div>
          <div className="combine-box-right">
            <Tablist key={treeProps.type} {...treeProps} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterResources;
