/*
 * @Descripttion: 进入直播间
 * @Author: gouyang
 * @Date: 2021-01-13 15:46:23
 * @LastEditors: gouyang
 * @LastEditTime: 2021-01-28 09:39:59
 */
import { stringifySearchParams } from './index';
import { liveAction } from '../redux/action/live.action';

function enterLiveRoom(id: string, leftMenuType: string, type?: 'normal') {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let success = (liveId: string, state: number) => {};
  switch (type) {
    case 'normal':
      success = (liveId: string, state: number) => {
        (window as any)._history.push(
          `/live${stringifySearchParams({
            id,
            leftMenuType,
            liveId,
            state,
          })}`,
        );
      };
      break;
    default:
      success = (liveId: string, state: number) => {
        window.open(
          `/live${stringifySearchParams({
            id,
            leftMenuType,
            ignoreClose: true,
            liveId,
            state,
          })}`,
        );
      };
      break;
  }

  liveAction.lessonInfo(id, `${leftMenuType}`, success);
}

export default enterLiveRoom;
