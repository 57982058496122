import React from 'react';

export function Tab(props: any) {
  const { lessonType, tabValue, onTabChanged } = props;

  return (
    <div className="tabbar">
      {lessonType === '2' && (
        <span
          className={`markup-item tab-item  ${tabValue === 0 ? 'active' : ''} `}
          onClick={() => onTabChanged(0)}
        >
          <span />
          实录标记
        </span>
      )}
      <span
        className={`tab-item  ${tabValue === 1 ? 'active' : ''} `}
        onClick={() => onTabChanged(1)}
      >
        相关资料
      </span>
    </div>
  );
}
