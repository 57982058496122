/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author: gouyang
 * @Description: 创建文章reducer
 * @Date: 2019-07-16 09:50:08
 */
import { AnyAction } from 'redux';
import { ReleaseType } from '../../util/enum';
import {
  ARTICLE_NAME,
  EDITOR_DATA,
  UPLOAD_DATA,
  ARTICLE_DATA,
  EDITOR_TARGET,
  CLEAN_FILE_LISTS,
  DEADLINE,
  UPDATETAGS,
  PUBLISH_RANGE,
} from './../types/article.type';
import mapFileType from './../../pages/CreateArticle/components/fileType';

const initialState = (() => ({
  editorTarget: { txt: { html: (s: string) => {} } }, // 文本编辑器对象
  name: '', // 文章名称
  editorHtml: '', // 编辑器html
  pureEditorText: '', // 编辑器文本
  flieList: [] as any[], // 资料列表
  extensionData: {},
  tags: [],
  grades: '', // 年级
  releaseType: ReleaseType.ALL, // 发布方式：1-全部发布，2-部分发布
  studySectionCode: '', // 学段
  studySectionName: '',
  schoolYear: '', // 学年
  subjects: '', // 学科
}))();

const CreateArticle = (state = { ...initialState }, { type, payload }: AnyAction) => {
  switch (type) {
    case ARTICLE_NAME:
      return Object.assign({}, state, { name: payload });
    case EDITOR_DATA:
      return Object.assign({}, state, {
        editorHtml: payload.html,
        pureEditorText: payload.text,
      });
    case PUBLISH_RANGE:
      return Object.assign({}, state, payload);
    case DEADLINE:
      return Object.assign({}, state, payload);
    case UPLOAD_DATA:
      let fileList = [] as any[];
      if (payload.status === 'plus') {
        fileList = [...payload.data, ...state.flieList];
      } else if (payload.status === 'minus') {
        fileList = payload.data;
      } else if (payload.status === 'change') {
        fileList = [...state.flieList];
        const changeData = payload.data as any[];

        fileList.forEach((file: any, i: number) => {
          if (file.id === changeData[0].id) {
            fileList[i] = changeData[0];
          }
        });
        fileList = fileList.filter((file: any) => file.status !== 'cancel');
      }
      return { ...state, flieList: fileList };
    case ARTICLE_DATA:
      const { name, content, contentHtml, files, extensionData, labels } = payload;
      files.forEach((item: any) => {
        const arr = item.name.split('.');
        const l = arr.length;
        item.data = {
          fileId: item.fileId,
          fileName: item.name,
          extension: arr[l - 1],
        };
        item.icon = mapFileType(arr[l - 1]);
        item.status = 'done';
      });

      const details = {
        name,
        pureEditorText: content,
        editorHtml: contentHtml,
        flieList: files.map((file: any) => {
          file.key = String(parseInt(`${Math.random() * 1000000000000}`, 10));
          return file;
        }),
        extensionData,
        tags: labels
          ? labels.map((label: any, i: number) => ({
              id: label.labelId,
              name: label.labelName,
              key: new Date().getTime() + i,
            }))
          : [],
      };
      state.editorTarget.txt.html(contentHtml);
      return Object.assign({}, state, details);
    case EDITOR_TARGET:
      return Object.assign({}, state, { editorTarget: payload });
    case CLEAN_FILE_LISTS:
      const tempFileList = [] as any[];
      state.flieList.forEach((file: any) => {
        if (file.status && file.status === 'done') {
          tempFileList.push(file);
        } else if (!file.status) {
          tempFileList.push(file);
        }
      });

      return { ...state, flieList: tempFileList };
    case UPDATETAGS:
      return { ...state, tags: payload };
    default:
      return { ...state };
  }
};

export default CreateArticle;
