/*
 * @Author: gouyang
 * @Description: 接口请求
 * @Date: 2019-11-06 10:06:50
 */
import AxiosWrap from './request';
import hasNotStatusRequest from './hasNotStatusRequest';
import { getToken } from '../util';
import { SearchParams } from '../pages/GlobalSearch/lib/searchInterface';

// 栏目列表
export function menuLists() {
  return AxiosWrap({
    url: '/foundations/columns',
  });
}

// 联盟详情
export function leagueInfo() {
  return AxiosWrap({ url: '/league' });
}

/**
 * 获取资源权限
 * @param permissionType 1:资源权限，2:数据权限，3:栏目权限，4:后台权限
 */
export function resourceRoots(permissionType: number) {
  return AxiosWrap({
    url: '/permission/user/permissions',
    params: { permissionType },
  });
}

// 播放时长
export function submitStatisticData(statistic: any) {
  hasNotStatusRequest({
    url: '/video-watch-records',
    method: 'PUT',
    data: statistic,
  });
}

// 退出登录
export function signOut() {
  AxiosWrap({
    url: '/auth/logout',
    method: 'post',
    data: {
      access_token: getToken(),
    },
  });
}

// 系统封面图
export function systemCoverPicture(paginationParams: { page: number; size: number }) {
  return AxiosWrap({
    url: '/system_course_covers',
    method: 'get',
    params: paginationParams,
    ignoreLoading: true,
  });
}

// 个人封面图
export function personalCoverPicture(paginationParams: { page: number; size: number }) {
  return AxiosWrap({
    url: '/user_course_covers',
    method: 'get',
    params: paginationParams,
    ignoreLoading: true,
  });
}

// 全局搜索
export function globalSearchResults(searchParams: SearchParams) {
  return AxiosWrap({
    url: '/global-search-results',
    method: 'get',
    params: searchParams,
    ignoreLoading: true,
  });
}

// 课时排序
export function lessonSort(courseId: string, lessonId: string, offset: number) {
  return AxiosWrap({
    url: `/course/${courseId}/lesson-of-sort/${lessonId}`,
    method: 'PUT',
    data: { offset },
  });
}
