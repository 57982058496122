/* eslint-disable no-case-declarations */
import * as types from '../types/course.list.type';

interface Action {
  type: string;
  payload?: any;
}
const initialState = (() => ({
  courseList: {
    page: {
      size: 10,
      number: 1,
      totalPages: 0,
      totalElements: 0,
    },
    list: [],
  },
}))();
const CourseList = (state = { ...initialState }, { type, payload }: Action) => {
  switch (type) {
    case types.GET_COURSE_LIST:
      return {
        ...state,
        courseList: payload,
      };
    case types.APPEND_LIST:
      const page =
        state.courseList.page.totalPages > payload.page.number
          ? payload.page
          : state.courseList.page;
      return {
        ...state,
        courseList: {
          page,
          list: state.courseList.list.concat(payload.list),
        },
      };
    case types.RESET_LISTS: // 重置列表
      return {
        courseList: {
          page: {
            size: 10,
            number: 1,
            totalPages: 0,
            totalElements: 0,
          },
          list: [],
        },
      };
    default:
      return {
        ...state,
      };
  }
};
export default CourseList;
