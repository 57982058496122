import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getStudySectionId, getSubjectList, isPingguoUnion } from '../../../../util';
import { getFollowGrades, getFollowSchoolPeriod } from '../../../../redux/action/base.action';

// 获取基本筛选基本信息
function useFilterInfo() {
  const studySectionCode = getStudySectionId();
  const subject = useRef();
  const resourceTypes = [
    { id: 1, code: '1', name: '课堂实录' },
    { id: 2, code: '2', name: '课件' },
    { id: 3, code: '3', name: '素材' },
    { id: 4, code: '4', name: '教案' },
    { id: 5, code: '5', name: '普通作业' },
    { id: 6, code: '6', name: '普通试卷' },
  ];
  const { grades, schoolPeriodList } = useSelector((state: any) => ({
    grades: state.Base.gradesList,
    schoolPeriodList: state.Base.schoolPeriodList,
  }));

  // 年级
  useEffect(() => {
    !isPingguoUnion() && getFollowGrades({ studySectionCode });
  }, [studySectionCode]);

  // 学届
  useEffect(() => {
    !isPingguoUnion() && getFollowSchoolPeriod();
  }, []);

  // 学科
  useEffect(() => {
    !isPingguoUnion() && (subject.current = getSubjectList());
  }, []);

  return [
    resourceTypes,
    grades,
    subject.current,
    [...schoolPeriodList].sort((a: any, b: any) => b.id - a.id),
  ];
}

export default useFilterInfo;
