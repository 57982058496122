import { SearchParams } from './searchInterface';

export const globalSearchParams: SearchParams = {
  text: '', // 关键字
  category: '', // 搜索类型
  lessonCategory: '', // 课时类型
  resourceCategory: '', // 资料类型
  gradeId: '', // 年级id
  subjectId: '', // 学科id
  schoolPeriod: '', // 学届id
  materialId: '', // 教材id
  chapterId: '', // 章节id
  schoolSection: '', // 学段
  page: 0,
  size: 20,
};

export const tabList = [
  {
    name: '全部',
    id: '',
  },
  {
    name: '资料',
    id: '2',
  },
  {
    name: '课时',
    id: '1',
  },
  {
    name: '课程',
    id: '0',
  },
  {
    name: '公告',
    id: '3',
  },
];
