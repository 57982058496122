/* eslint-disable guard-for-in */
// 资料课时
import React from 'react';
import { Icon } from 'antd';

import './upload.less';

interface P {
  clearDisabled: boolean;
  data: any;
  fileDelCback?: (data: any) => void; // 删除数据回调
  disabled?: boolean; // 是否禁用删除
}
interface S {
  fileList: any;
}
class FileList extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      fileList: [],
    };
  }
  componentDidMount() {
    const { data } = this.props;
    this.setData(data);
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setData(nextProps.data);
  }
  setData = (data: any) => {
    const { fileList } = this.state;
    if (data !== fileList) {
      this.setState({
        fileList: this.addIcon(data),
      });
    }
  };
  // 添加图标
  addIcon = (data: any) => {
    for (const i in data) {
      let strIcon = '';
      switch (data[i].category) {
        case 2: // 课件ppt
          strIcon = 'icon-courseware';
          break;
        case 3: // 素材
          strIcon = 'icon-material';
          break;
        case 4: // 教案 world
          strIcon = 'icon-teaching_plan';
          break;
        case 5: // 作业
          strIcon = 'icon-operation';
          break;
        case 6: // 试卷 word + audio
          strIcon = 'icon-test_paper';
          break;
        default:
      }
      data[i].icon = strIcon;
    }
    return data;
  };
  // 删除
  del = (index: number) => {
    const { fileDelCback, disabled } = this.props;
    const { fileList } = this.state;

    if (disabled) return null;
    const len = fileList.length;
    const data = [...fileList.slice(0, index), ...fileList.slice(index + 1, len)];
    this.setState({
      fileList: data,
    });
    if (fileDelCback) {
      fileDelCback(data);
    }
  };
  render() {
    const { fileList } = this.state;
    const { clearDisabled } = this.props;

    return (
      <div className="fileList">
        {fileList.map((item: any, index: number) => (
          <div key={item.id}>
            <div className="list">
              <span className="icon">
                {item.status === 'done' ? (
                  switchIcon(item.icon)
                ) : (
                  <Icon style={{ color: '#77849E' }} type="paper-clip" />
                )}
              </span>
              <span className="name">{item.name}</span>
              {clearDisabled === false ? (
                <span className="close">
                  <Icon type="close" onClick={() => this.del(index)} />
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
const switchIcon = (str: string) => {
  if (str) {
    return <span className={`iconfont ${str}`} />;
  } else {
    return <Icon style={{ color: '#77849E' }} type="paper-clip" />;
  }
};
export default FileList;
