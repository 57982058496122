/* eslint-disable guard-for-in */
// 资料课时
import React from 'react';
import { connect } from 'react-redux';
import { Form, Radio, Button, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import AddMaterial from './addMaterial';
import AddResources from '../siteResources/addResources';
import ResourcesList from './resourcesList';

import './style.less';
import moment from 'moment';

interface P extends FormComponentProps {
  isEdit: boolean; // 是否是编辑
  material: any; // 回显数据
  subjectId: [];
  lessonType: string;
}
interface S {
  visible: boolean; // 弹出显示控制
  resourcesVisible: boolean; // 站点资源弹出
  current: number | string; // 激活状态
  data: any; // 文件列表
  subjects: [];
  Tips: boolean;
  materialItem: any; // 资料选中的一行
  showTimeLimit: boolean; // 是否显示“截止时间设置”
}
class Material extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      visible: false,
      resourcesVisible: false,
      current: '',
      data: [],
      subjects: [],
      Tips: false,
      materialItem: null,
      showTimeLimit: true,
    };
  }
  componentDidMount() {
    // 编辑回显示数据
    const { isEdit, material, subjectId } = this.props;
    if (isEdit && material) {
      this.setState({
        data: material,
        subjects: subjectId,
      });
    } else {
      this.setState({
        subjects: subjectId,
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.subjectId !== nextProps.subjectId) {
      this.setState(
        {
          subjects: nextProps.subjectId.asMutable(),
        },
        this.clearData,
      );
    }
  }
  // 资料类型
  meansType = (e: any) => {
    const { value } = e.target;
    this.setState({
      current: value,
    });
  };
  /**
   * 显示弹窗
   * @param name: 显示弹窗的名字，
   * @param item: 资料列表的某一项
   */
  show = (name: string, item?: any) => {
    let { showTimeLimit } = this.state;
    const { subjects } = this.state;

    if (name === 'resourcesVisible' && subjects.length === 0) {
      message.warning('请选择学科！');
      return;
    }
    const obj: any = {};
    obj[name] = true;
    if (!!item && name === 'visible') {
      showTimeLimit = false;
    }
    this.setState({
      ...obj,
      showTimeLimit,
      materialItem: item || null,
    });
  };
  // 关闭弹窗
  cancel = (name: string) => {
    let { showTimeLimit } = this.state;

    if (name === 'visible') {
      showTimeLimit = true;
    }
    const obj: any = {};
    obj[name] = false;
    this.setState({
      ...obj,
      showTimeLimit,
    });
  };
  // 获取上传资料获取值回调
  uploadCbackData = (objData: any) => {
    let { data } = this.state;
    const index = data.findIndex((item: any) => item.id === objData.id);
    if (index > -1) {
      data[index] = objData;
    } else {
      data = [...[objData], ...data];
    }
    this.setState({
      data,
      visible: false,
      Tips: false,
    });
  };
  // 添加资源获取值回调
  addResourcesCbackData = (list: any) => {
    let { data } = this.state;
    data = [...list, ...data];
    this.setState({
      data,
      visible: false,
      Tips: false,
    });
  };
  // 列表 删除时的回调
  fileDelCback = (data: any) => {
    this.setState({
      data,
    });
  };
  // 父组件获取数据
  getItemsValue = () => {
    const { data } = this.state;
    const { lessonType } = this.props;
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      this.props.form.validateFields((error: any) => {
        if (!error) {
          if (data.length === 0 && lessonType === '3') {
            reject('请添加资料！');
            this.setState({
              Tips: true,
            });
            return;
          }
          resolve(data);
        } else {
          reject('请添加资料课时信息！');
        }
      });
    });
  };
  // 当学科变化 清空 站点资源选择的数据
  clearData = () => {
    const { subjects, data } = this.state;
    if (data && data.length > 0) {
      const list = data.filter((item: any) => {
        if (!item.subjects) {
          return true;
        }
        for (const i in subjects) {
          if (item.subjects.indexOf(subjects[i]) >= 0) {
            return true;
          }
        }
        return false;
      });
      this.setState({
        data: list,
      });
    }
  };
  // 去重复
  duplicate = (data: any) => {
    const obj: any = {};
    const list = [];
    for (const i in data) {
      if (data[i].data && !obj[data[i].data.fileId]) {
        list.push(data[i]);
        obj[data[i].data.fileId] = true;
      }
      if (data[i].resourceGuid && !obj[data[i].resourceGuid]) {
        list.push(data[i]);
        obj[data[i].resourceGuid] = true;
      }
    }
    return list;
  };
  // 删除资源的列表
  delResourcesList = (id: string) => {
    const { data } = this.state;
    const newData = data.filter((item: any) => item.id !== id);
    this.setState({
      data: newData,
    });
  };
  // 删除文件
  delFiles = (id: string, fliesId: number) => {
    const { data } = this.state;
    const newData = data.filter((item: any) => {
      if (item.id === id) {
        item.files = item.files.filter((item: any) => item.id !== fliesId);
        if (item.files.length > 0) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    });
    this.setState({
      data: newData,
    });
  };

  onSetExpire = (form: any, id: any) => {
    const { data } = this.state;
    data.forEach((item: any) => {
      if (id === item.resourceGuid) {
        if (form.type === 1) {
          item.expiryDate = null;
        } else {
          item.expiryDate = moment(form.date).valueOf();
        }
      }
    });
  };
  render() {
    const {
      current,
      visible,
      data,
      resourcesVisible,
      subjects,
      Tips,
      materialItem,
      showTimeLimit,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { lessonType } = this.props;
    return (
      <div className="model" id="means">
        <h3>
          添加资料
          {lessonType === '2' ? <span className="gray_font">（非必填）</span> : ''}
        </h3>
        {visible ? (
          <AddMaterial
            visible={visible}
            cancel={() => this.cancel('visible')}
            cbackData={this.uploadCbackData}
            materialItem={materialItem}
            showTimeLimit={showTimeLimit}
          />
        ) : (
          ''
        )}
        {resourcesVisible ? (
          <AddResources
            category={[2, 3, 4, 5, 6]}
            title="选择资料"
            subjectId={subjects}
            resourcesType="material"
            cancel={() => this.cancel('resourcesVisible')}
            cbackFun={this.addResourcesCbackData}
            visible={resourcesVisible}
          />
        ) : (
          ''
        )}
        <div className="lesson-materral">
          <Form>
            <Form.Item
              {...formLayout}
              className={lessonType === '2' ? 'first-form-item' : 'requireStar first-form-item'}
              label="添加方式"
            >
              {getFieldDecorator('means', {
                rules: [
                  {
                    required: false,
                    // message: '请选择添加资料类型'
                  },
                ],
              })(
                <Radio.Group onChange={this.meansType}>
                  <Radio value={1}>本地上传</Radio>
                  <Radio value={2}>站点资源</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            {current ? (
              <Form.Item {...formLayout1}>
                {current === 1 ? (
                  <Button className="default" icon="upload" onClick={() => this.show('visible')}>
                    上传资料
                  </Button>
                ) : (
                  ''
                )}
                {current === 2 ? (
                  <Button
                    className="default"
                    style={{ width: '110px' }}
                    onClick={() => this.show('resourcesVisible')}
                  >
                    选择资源
                  </Button>
                ) : (
                  ''
                )}
                {current === 2 ? (
                  <span className="gray_font" style={{ marginLeft: '5px' }}>
                    请先选择学科
                  </span>
                ) : (
                  ''
                )}
              </Form.Item>
            ) : (
              ''
            )}
            {data.length > 0 ? (
              <Form.Item {...formLayout1} className="form-item-btn">
                <ResourcesList
                  data={data}
                  onSetExpire={this.onSetExpire}
                  delResourcesList={this.delResourcesList}
                  delFiles={this.delFiles}
                  upload={(item: any) => this.show('visible', item)}
                />
              </Form.Item>
            ) : (
              ''
            )}

            {Tips ? <div className="errorTips">请添加资料！</div> : ''}
          </Form>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 15 },
};
const formLayout1: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 20, offset: 2 },
};
const mapStateToProps = (state: any) => ({
  material: state.Lesson.classHourDetail.material,
  subjectId: state.Lesson.subjectId,
});
export default connect(mapStateToProps)(Form.create<P>()(Material));
