/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-case-declarations */
import './courseDetail.less';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { RouteComponentProps, match } from 'react-router';
import Alert from '../../components/Alert/Alert';
import ContentHeader from './components/ContentHeader/ContentHeader';
import LessonItem from '../../components/LessonItem';
import * as action from '../../redux/action/course.detail.action';
import { liveAction } from '../../redux/action/live.action';
import * as lessonAction from '../../redux/action/vod.action';
import { mergeProps, NormalObj, getSearchParams, stringifySearchParams, MyIcon } from '../../util';
import enterLiveRoom from '../../util/enterLiveRoom';
import TrackRequest from '../../lib/trackRequest';

interface matchProps extends match {
  params: {
    id: number;
  };
}
interface P extends RouteComponentProps {
  list: NormalObj[];
  match: matchProps;
  courseInfo: NormalObj;
  lesson_exist: Boolean;
}
class CourseDetail extends Component<P, any> {
  lessonType: number;
  lessonId: any;
  searchParams: NormalObj;
  child: any[] = [];
  constructor(props: P) {
    super(props);
    this.state = {
      allLoaded: false,
    };
    this.lessonType = 0;
    this.lessonId = '';
    this.toLesson = this.toLesson.bind(this);
    this.searchParams = getSearchParams(props.location.search);
  }

  componentDidMount() {
    this.lessonId = '';
    (window as any)._dispatch({ type: 'LESSON_EXIST', payload: false });
    // 根据 左侧菜单类型 来获取 列表数据
    action.getCourseDetail(this.searchParams.courseId, this.searchParams.leftMenuType);
    action.getList(this.searchParams.courseId, this.searchParams.leftMenuType);
  }

  componentDidUpdate() {
    const { leftMenuType } = this.searchParams;
    const { history, courseInfo, lesson_exist } = this.props;
    if (lesson_exist && courseInfo && this.lessonId) {
      switch (this.lessonType) {
        case 1:
          // 直播
          this.track(courseInfo);
          enterLiveRoom(this.lessonId, leftMenuType, 'normal');
          break;
        case 2: // 点播
        case 3: // 资料
          const search = {
            columnId: this.searchParams.id,
            courseName: courseInfo.courseName,
            courseId: courseInfo.id,
            lessonId: this.lessonId,
            lessonType: this.lessonType,
            type: this.searchParams.leftMenuType,
          };
          history.push(`/vod${stringifySearchParams(search)}`);
          this.track(courseInfo);
          break;
        default:
          break;
      }
      this.lessonId = '';
      (window as any)._dispatch({ type: 'LESSON_EXIST', payload: false });
    }
  }

  componentWillUnmount() {
    Alert.remove('course');
  }

  toLesson(type: number, lessonId: string) {
    this.lessonId = lessonId;
    this.lessonType = type;
    switch (type) {
      case 1:
        liveAction.getLiveLesson(lessonId);
        break;
      case 2:
      case 3:
        lessonAction.getLessson(lessonId);
        break;
      default:
        break;
    }
  }
  /** 代码埋点
   * @param courseInfo : 课程信息
   */
  track = (courseInfo: any) => {
    if (this.searchParams.leftMenuType === '2') {
      // 同步
      // 观看课时埋点
      TrackRequest({
        name: 'watch_period',
        data: {
          course_id: courseInfo.id,
          period_id: this.lessonId,
          period_type: this.lessonType,
          watch_period_entrance: 0,
        },
      });
    } else if (this.searchParams.leftMenuType === '0') {
      // 预览
      // 预览课时埋点
      TrackRequest({
        name: 'preview_period',
        data: { course_id: courseInfo.id, period_id: this.lessonId, pre_entrance: 0 },
      });
    }
  };

  goToPage = (lessonId: string, resId: string, lessonType: number, category: number) => {
    this.searchParams = getSearchParams(this.props.location.search);
    const { history, courseInfo } = this.props;
    const search = {
      columnId: this.searchParams.id,
      courseName: courseInfo.courseName,
      courseId: courseInfo.id,
      lessonId,
      lessonType,
      type: this.searchParams.leftMenuType,
      resId,
      category,
    };
    history.push(`/vod${stringifySearchParams(search)}`);
  };

  onLoaded = () => {
    this.setState({ allLoaded: true });
  };

  shouldComponentUpdate(np: any, ns: any) {
    if (ns.allLoaded && !this.state.allLoaded && this.searchParams.leftMenuType === '2') {
      for (let i = 0; i < this.child.length; i++) {
        if (this.child[i].props.data.lessonType !== 1) {
          setTimeout(() => {
            this.child && this.child[i] && this.child[i].toggleOpen();
          }, 250);
          break;
        }
      }
    }
    return true;
  }

  render() {
    const { allLoaded } = this.state;
    const { courseInfo, list } = this.props;
    // 是否有更新
    const isUpdated = list.some((i: any) => i.watchState === 2);
    const { leftMenuType } = this.searchParams;
    if (courseInfo) {
      courseInfo.leftMenuType = leftMenuType;
    }

    return (
      <div className="CourseDetail-Component">
        <ContentHeader data={courseInfo} isUpdated={isUpdated} />
        <div className="detail-list">
          <div className="detail-title">
            <span>课程目录</span>
          </div>
          <div className="lesson-list">
            {list && list.length > 0 ? (
              list.map((item: NormalObj, idx: number) => {
                return (
                  <div className="lesson-list-item" key={idx}>
                    <LessonItem
                      ref={(ref) => (this.child[idx] = ref)}
                      key={item.id}
                      data={item}
                      allLoaded={allLoaded}
                      onLoaded={this.onLoaded}
                      toLesson={this.toLesson}
                      leftMenuType={leftMenuType}
                      goToPage={this.goToPage.bind(this, item.id)}
                    />
                  </div>
                );
              })
            ) : (
              <Empty
                className="ant-empty-normal custom-empty"
                description="暂无内容"
                image={<MyIcon className="empty-icon" type="iconcontent_null" />}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: NormalObj) => ({
  ...state.CourseDetail,
  lesson_exist: state.Vod.lesson_exist,
});
export default connect(mapStateToProps, mergeProps)(CourseDetail);
