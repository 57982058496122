/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from 'antd';
import { toLower } from 'lodash';
import { CSSProperties } from 'react';
import jsMd5File from 'js-md5-file';

export function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
  return { ...ownProps, ...stateProps, ...dispatchProps };
}
function Role() {
  return (window as any).localStorage.getItem('userInfo')
    ? JSON.parse((window as any).localStorage.getItem('userInfo'))
    : {
        access_token: '',
        userId: '',
        name: '',
        username: '',
        schoolId: '',
        orgName: '',
        phone: '',
        email: '',
        studySectionId: '',
        subjects: [],
        schoolPeriods: [],
      };
}
export function getLeagueId() {
  return (window as any).localStorage.getItem('unionId') || '';
}
export function getToken() {
  return Role().access_token;
}
export function getUserId() {
  return Role().userId || '';
}
export function getStudySectionId() {
  return Role().studySectionId;
}
export function getUsername() {
  return Role().name;
}
export function getSchoolPeriods() {
  return Role().schoolPeriods;
}
export function getSchoolId() {
  return Role().schoolId;
}
export function getOrgName() {
  return Role().orgName;
}

export function isPingguoUnion() {
  const leagueId = getLeagueId();
  return leagueId === '586869483291066368' || leagueId === '618749356510937088';
}

export function isFzxxUnion() {
  const leagueId = getLeagueId();
  return leagueId === '430317817097535488';
}

export function getSubjectList() {
  return Role().subjects ? Role().subjects : [];
}
export function getSubjectId() {
  const { subjects } = Role();
  return subjects ? subjects.map((item: any) => item.code) : [];
}
export interface NormalObj {
  [key: string]: any;
}
export function getSearchParams(str?: string) {
  const s = str || window.location.search;
  const index = s.indexOf('?');
  const o: NormalObj = {};
  if (index > -1) {
    const arr = decodeURIComponent(s.slice(index + 1)).split('&');
    for (let i = 0, len = arr.length; i < len; i++) {
      const splitArr = arr[i].split('=');
      o[splitArr[0]] = splitArr[1];
    }
  }
  return o;
}

export function getQueryString(name: string) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return '';
}

export function stringifySearchParams(obj: { [key: string]: any }) {
  const search: string[] = [];
  Object.keys(obj).forEach((key: string, idx: number, arr) => {
    if (obj[key] || obj[key] === 0 || obj[key] === false) {
      search.push(`${key}=${obj[key]}`);
    }
  });
  const str = search.length > 0 ? `?${encodeURIComponent(search.join('&'))}` : '';
  return str;
}
// 判断权限是否存在
export function getRoot(name: string) {
  const str = window.localStorage.getItem('permissions');
  const data = str ? JSON.parse(str) : [];
  for (const i in data) {
    if (data[i].code === name) {
      return true;
    }
  }
  return false;
}
// 获取路径后缀名
export function getPathSuffix() {
  const str = window.localStorage.getItem('pathSuffix');
  return str || '/404';
}

/**
 * 获取学年
 * @param beforeNum 表示获取当前学年往前的几个学年，默认获取往前4个学年
 * @param afterNum 表示获取当前学年往后几个学年， 默认获取往后1个学年
 */
export function getSchoolYear(beforeNum = 4, afterNum = 1) {
  const sourceTime = new Date();
  const targetTime = new Date(`${sourceTime.getFullYear()}/8/10 23:59:59`);
  const year =
    sourceTime.getTime() > targetTime.getTime()
      ? sourceTime.getFullYear()
      : sourceTime.getFullYear() - 1;
  const arr = [];
  for (let i = year - beforeNum; i <= year + afterNum; i++) {
    arr.push(`${i}-${i + 1}`);
  }
  return {
    schoolYears: arr,
    curSchoolYear: `${year}-${year + 1}`,
  };
}

/**
 * 获取平果联盟学年
 * 获取前5年+后2年
 * 比如今年是2021年，那么学年就是2015-2016、2016-2017、2017-2018、2018-2019、2019-2020、2020-2021、2021-2022
 */
export function getPingguoSchoolYear() {
  const sourceTime = new Date();
  const targetTime = new Date(`${sourceTime.getFullYear()}/8/10 23:59:59`);
  const year =
    sourceTime.getTime() > targetTime.getTime()
      ? sourceTime.getFullYear()
      : sourceTime.getFullYear() - 1;
  const arr = [];
  for (let i = -5; i <= 1; i++) {
    arr.push(`${year + i}-${year + 1 + i}`);
  }
  return arr;
}

// 项目Icon
export const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1237562_3avz1op6uu8.js',
});
// 直播-点播-资料课时背景颜色
export const getMaterialBg = (type: number): CSSProperties => {
  switch (type) {
    case 1:
      return {
        background: '#15B79D', // 直播
      };
    case 2:
      return {
        background: '#2683F5', // 点播
      };
    case 3:
      return {
        background: '#2B5F92', // 资料
      };
    default:
      return {};
  }
};
// 判断是否为资源补发和同考同析中的一个
export function judgeSourceType(type: string) {
  return type === '同考同析' || type === '资源补发';
}

export const getType = (data: any) => {
  const o = {
    name: '',
    type: '',
  };
  if (data.category === 1) {
    // 点播课时
    switch (data.extension.toLowerCase()) {
      case 'mp4':
        o.type = 'iconFile_mp';
        break;
      case 'crf':
      case 'crf2':
      case 'ocrf':
        o.type = 'iconocrf';
        break;
      case 'txt':
        o.type = 'iconFile_txt';
        break;
      default:
        o.type = 'iconunknown';
        break;
    }
  } else {
    switch (data.category) {
      case 2:
        o.name = '课件';
        o.type = 'iconcourseware';
        break;
      case 3:
        o.name = '素材';
        o.type = 'iconmaterial';
        break;
      case 4:
        o.name = '教案';
        o.type = 'iconteaching_plan';
        break;
      case 5:
        o.name = '普通作业';
        o.type = 'iconoperation';
        break;
      case 6:
        o.name = '普通试卷';
        o.type = 'icontest_paper';
        break;

      default:
        o.name = '未知';
        o.type = 'iconunknown';
        break;
    }
  }
  return o;
};

export const formateMinutes = (time: number) => {
  const secondes = time % 60;
  const minute = (time - secondes) / 60;
  return `${minute < 10 ? `0${minute}` : minute}:${secondes < 10 ? `0${secondes}` : secondes}`;
};

// 特殊栏目
export const specialColumns = ['年级报告', '学科报告', '月度报告'];

// 获取参数的值
const getValueByParam = (param: string): string => {
  let userInfo = window.localStorage.getItem('mainUserInfo');
  userInfo = userInfo ? JSON.parse(userInfo) : { access_token: '', userId: '', orgId: '' };
  const { access_token, userId, orgId } = userInfo as any;
  const leagueId = getLeagueId();
  const schoolId = getSchoolId();
  const orgName = getOrgName();
  let value = '';
  switch (param) {
    case 'token':
      value = access_token;
      break;
    case 'userid':
    case 'userId':
      value = userId;
      break;
    case 'orgId':
      value = orgId;
      break;
    case 'orgName':
      value = orgName;
      break;
    case 'leagueId':
      value = leagueId;
      break;
    case 'schoolId':
    case 'schoolGuid':
      value = schoolId;
      break;
    default:
      value = '';
  }
  return value;
};

interface paramsListType {
  paramStr: string; // 参数的模板字符串
  param: string; // 参数
}
// 链接栏目的url参数替换
export const linkColumnUrl = (originUrl: string): string => {
  const paramReg = /\$\{(.*?)\}/g;
  let execResult = null;
  const paramsList: paramsListType[] = [];
  do {
    execResult = paramReg.exec(originUrl);
    execResult &&
      paramsList.push({
        paramStr: execResult[0],
        param: execResult[1],
      });
  } while (execResult);

  if (paramsList.length === 0) {
    return originUrl;
  }

  for (let i = 0; i < paramsList.length; i++) {
    originUrl = originUrl.replace(paramsList[i].paramStr, getValueByParam(paramsList[i].param));
  }
  return originUrl;
};

// 特定联盟下里的高一的课程‘文理科’的标签需要隐藏
export const hideSubjectTag = (grades: any[], tags: any[]): any[] => {
  if (getLeagueId() !== '362543918355361792') {
    return tags;
  }
  const seniorOne = (grades || []).find((item) => item.gradeName === '高一');
  if (!seniorOne) {
    return tags;
  } else {
    return tags.filter((item) => item.labelName !== '文理科');
  }
};

// 学校账号开通详情页-学界前端写死
export const fixedStudyYear = () => {
  const curYear = new Date().getFullYear();
  const schoolPeriodArr = [
    { label: '全部', value: 'all' },
    { label: `${curYear}届`, value: `${curYear}届` },
  ];
  for (let i = 1; i <= 6; i++) {
    schoolPeriodArr.push({
      label: `${curYear + i}届`,
      value: `${curYear + i}届`,
    });
  }
  return schoolPeriodArr;
};

enum schoolSectionValMap {
  'XQ' = 3,
  'XX' = 6,
  'CZ' = 3,
  'GZ' = 3,
  'DX' = 4,
}
const gradesMap = {
  XQ: ['XB', 'ZB', 'DB'],
  XX: ['X1', 'X2', 'X3', 'X4', 'X5', 'X6'],
  CZ: ['C1', 'C2', 'C3'],
  GZ: ['G1', 'G2', 'G3'],
  DX: [],
};
// 根据用户所选学段、学年以及创建者本身的学界计算年级
export const computeGrades = (schoolSection: string, schoolYears: string) => {
  const schoolSectionVal = schoolSectionValMap[schoolSection as keyof typeof schoolSectionValMap];
  const schoolYearsVal = ~~schoolYears.split('-')[1];
  const schoolPeriodsVal = (getSchoolPeriods() || []).map((item: string) => parseInt(item, 10));

  const gradesArr = (schoolPeriodsVal || [])
    .map((item: number) => schoolSectionVal + schoolYearsVal - item)
    .sort((a: number, b: number) => a - b)
    .filter((item: number) => item >= 1 && item <= schoolSectionVal);

  const gradesCodeArr = gradesArr.map(
    (grade: number) => gradesMap[schoolSection as keyof typeof gradesMap][grade - 1],
  );
  return gradesCodeArr;
};

export function uuid() {
  const s: any[] = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  const uuid = s.join('');
  return uuid;
}

// 获取主用户信息
const getMainUserInfo = (): { access_token: any; userId: any; orgId: any } => {
  let userInfo = window.localStorage.getItem('mainUserInfo');
  userInfo = userInfo ? JSON.parse(userInfo) : { access_token: '', userId: '', orgId: '' };
  const { access_token, userId, orgId } = userInfo as any;
  return {
    access_token,
    userId,
    orgId,
  };
};

export const formatUrl = (data: any, requestId: string) => {
  const { extension, fileId, cdnUrl } = data;
  if (extension !== 'mp4' && extension !== 'MP4') {
    return cdnUrl;
  }
  // 只有视频才在url后面添加参数
  const { userId } = getMainUserInfo();
  const serverTime = window.sessionStorage.getItem('SERVERTIME') || ''; // 服务器时间;

  const paramsStr = `${cdnUrl.indexOf('?') < 0 ? '?' : '&'}wd_userId=${userId}${
    serverTime ? `&wd_timeTick=${Number(serverTime)}` : ''
  }&wd_fileId=${fileId}&wd_source=web&wd_system=openschool&wd_requestId=${requestId}`;

  return cdnUrl + paramsStr;
};

// B存在于A中
const BExistsInA = (A: string[], B: string) => {
  return A.indexOf(B) > -1;
};

export const getExtensionType = (extension: string) => {
  const audioType = ['mp3', 'wma', 'wav'];
  const imageType = ['jpg', 'png'];
  const vedioType = ['mp4'];
  const flashType = ['swf'];
  const officeType = ['ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx', 'pdf'];
  const _extension = toLower(extension);
  if (BExistsInA(audioType, _extension)) {
    return 'mp3';
  } else if (BExistsInA(vedioType, _extension)) {
    return 'mp4';
  } else if (BExistsInA(officeType, _extension)) {
    return 'file';
  } else if (BExistsInA(imageType, _extension)) {
    return 'image';
  } else if (BExistsInA(flashType, _extension)) {
    return 'flash';
  } else {
    return '';
  }
};

// 获取文件md5
export const getFileMd5 = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    jsMd5File(file, (err: any, fileMd5: any) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(fileMd5);
    });
  });
};
