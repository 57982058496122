/* eslint-disable react/no-access-state-in-setstate */
/*
 * @Author: gouyang
 * @Description: 空白页，处理微信登录token
 * @Date: 2019-08-05 14:09:05
 */
import React, { Component } from 'react';
import * as action from '../../redux/action/login.action';
import TrackRequest from '../../lib/trackRequest';
import Login from '../conceptIndex/Login';
import { tokenManager } from 'eastedu-login-sdk';

// const env = (window as any).__UNION_CENTER_ENV__;
interface item {
  leagueId: number;
  name: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface P {}
interface S {
  choose: boolean;
  visible: boolean;
  showLogin: boolean;
}
class Blank extends Component<P, S> {
  private loginEntrance: number;

  constructor(props: any) {
    super(props);
    this.state = {
      choose: false, // 多联盟
      visible: false,
      showLogin: false, // 开放测试环境登录框显示
    };
    this.setLogin = this.setLogin.bind(this);
    this.loginEntrance = -1;
  }
  componentDidMount() {
    this.init();
  }
  init() {
    // 版权声明
    const DECLARE = window.localStorage.getItem('DECLARE');
    const access_token = tokenManager.getToken();
    window.localStorage.clear();
    access_token && tokenManager.setToken(access_token);
    window.localStorage.setItem('ISREAD', 'false');
    window.localStorage.setItem('DECLARE', DECLARE || '');
    // 微信登录
    const index = window.location.search.indexOf('?');
    if (window.location.search.indexOf('type') === -1) {
      this.setState({
        showLogin: true,
      });
    }
    if (index > -1) {
      const o = this.getUrlSearchParams(index);
      const { token, userId, type, leagueId, redirectUrl, relationUserId } = o;
      if (token) {
        window.localStorage.setItem(
          'userInfo',
          JSON.stringify({ access_token: token, userId: relationUserId }),
        );

        if (!access_token) {
          tokenManager.setToken({
            access_token: token,
          } as any);
        }

        if (userId) {
          // 从开放学习跳转过来会在url上带上 uerId
          window.sessionStorage.setItem('fzxx_userid', userId);
        } else {
          window.sessionStorage.removeItem('fzxx_userid');
        }

        // 平台登陆
        if (type === 'isForum') {
          this.loginEntrance = 3;
          window.sessionStorage.setItem('ENTRANCE', 'isForum');
          action.getMainUserInfo(() => this.linkToIndex(leagueId));
          return null;
        }
        // 账号密码登陆
        window.localStorage.removeItem('REDIRECTURL');
        if (type === 'isAccountLogin') {
          this.loginEntrance = 2;
          window.localStorage.setItem('REDIRECTURL', redirectUrl);
        }

        this.getLeagues();
      }
    }
  }

  // 获取地址搜索参数
  getUrlSearchParams(index: number) {
    const o: { [s: string]: any } = {};
    const arr = window.location.search.slice(index + 1).split('&');
    for (let i = 0, len = arr.length; i < len; i++) {
      const splitArr = arr[i].split('=');
      o[splitArr[0]] = splitArr[1];
    }
    return { ...o };
  }

  // 获取联盟
  getLeagues() {
    action.getUnionList((data: item[]) => {
      if (data.length === 1) {
        // 只有一个联盟直接跳转
        const child = data[0];
        this.linkToIndex(String(child.leagueId));
      } else {
        // 多个联盟
        this.setState({
          choose: true,
          visible: false,
        });
      }
    });
  }

  // 跳转到首页
  linkToIndex(leagueId: string) {
    window.localStorage.setItem('unionId', leagueId);
    this.loginBuryingPoint();
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }

  // 登陆埋点
  loginBuryingPoint() {
    let login_entrance: any;
    // 判断登录方式
    if (window.location.search.includes('sms')) {
      login_entrance = 1;
    } else {
      login_entrance = 0;
    }
    if (this.loginEntrance !== -1) login_entrance = this.loginEntrance;
    setTimeout(() => {
      TrackRequest({
        name: 'login',
        data: {
          login_entrance,
        },
      });
    }, 100);
  }

  setLogin = () => this.setState({ visible: !this.state.visible });
  render() {
    const { choose } = this.state;

    return (
      <div>
        {choose && (
          <div>
            <Login
              path="PageIndex"
              visible={false}
              buryingPoint={this.loginBuryingPoint.bind(this)}
            />
          </div>
        )}
        {/* {
          // 原流程登录
          (env === 'TEST' || env === 'DEV') && showLogin && (
            <Login
              path="OriginPageIndex"
              visible={visible}
              setLogin={this.setLogin}
              buryingPoint={this.loginBuryingPoint.bind(this)}
            />
          )
        } */}
      </div>
    );
  }
}

export default Blank;
