/*
 * @Author: gouyang
 * @Description: c++ 交互方法
 * @Date: 2019-08-02 14:10:14
 */
class _jsMethod_ {
  // 调用c++注册过的方法
  get_C_method(data: any) {
    const C = (window as any).CefInstance_c34ad448618447edb9e23efb7e5716da;
    C && C.call('HandleJsNotify', data, () => {});
  }
}
const jsMethod = new _jsMethod_();

export default jsMethod;
