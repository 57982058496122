/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { message } from 'antd';
import AxiosWrap from '../../lib/request';
import * as types from '../types/vod.type';
import Alert from '../../components/Alert/Alert';
import { NormalObj } from '../../util';
import Immutable from 'seamless-immutable';
import isNotRootLink from '../../util/isNotRootLink';
import apiConfig from '../../config/api';
import { parseMulLayerMarkerData } from 'video-marker-player';

/**
 * 获取课时存在否
 * @param id 课时id
 */
export async function getLessson(id: string) {
  try {
    const { status } = await AxiosWrap({
      url: `/lesson/${id}/check-exist`,
    });
    (window as any)._dispatch({ type: types.LESSON_EXIST, payload: true });
    return Promise.resolve({ status });
  } catch (err) {
    (window as any)._dispatch({ type: types.LESSON_EXIST, payload: false });
    if (err && err.status === 404) {
      message.error('课时不存在！');
    }
    if (err && err.status === 403) message.error('暂无权限！');
    return Promise.resolve({ status: 400 });
  }
}
/**
 * 获取课时详情
 * @param params any
 */
export async function fetchLesson(id: string, type: string) {
  let url = '';
  if (type === '0') {
    // 我的创建
    url = `/lesson/${id}`;
  } else if (type === '2') {
    // 我的同步
    url = `/lesson/my-fallow/${id}`;
  }
  try {
    const { status, data } = await AxiosWrap({ url });
    const {
      data: { unixtime },
    } = await AxiosWrap({ baseURL: apiConfig.ip });
    if (typeof unixtime === 'number') {
      window.sessionStorage.setItem('SERVERTIME', `${unixtime * 1000}`);
    }
    if (status === 200) {
      return Promise.resolve(data);
    }
  } catch (error) {
    if (error && error.status === 404) {
      Alert.open('课时已不存在', undefined, 'black', 'lesson');
    }
    if (error && error.status === 403) {
      Alert.open('暂无权限！', () => isNotRootLink());
    }
    return Promise.resolve({
      centerResources: [],
      materialChapters: [],
    });
  }
}

/**
 * 课件预览
 * @param fileId 文件id
 * @param callback 文件不存在回调
 */
export async function filePreview(fileId: string, type: number, fileName: string) {
  try {
    const { status, data } = await AxiosWrap({
      url: `/files/${fileId}/preview?type=${type}&fileName=${fileName}`,
    });

    if (status === 200 && data) {
      const { status: status2, data: data2 } = (await getNewMarkups(data.md5)) as any;

      if (status2 === 200) {
        const Obj = {
          COURSE_MEMOIR: '课堂实录目录', // 普通实录
          MODEL_COURSE: '课堂实录目录', // 示范课
          PREPARE_LESSON_MEMOIR: '备课实录目录',
          TEACHING_RESEARCH_RECORD: '教研实录目录',
        };
        let markups = (data2 || [])
          .filter(
            (t: any) =>
              [
                'COURSE_MEMOIR',
                'MODEL_COURSE',
                'PREPARE_LESSON_MEMOIR',
                'TEACHING_RESEARCH_RECORD',
              ].includes(t?.resourceType) && t?.markupState === 'PUTAWAY',
          )
          .map((t: any) => {
            return {
              tabName: (Obj as any)[t.resourceType],
              panelData: parseMulLayerMarkerData(t.data),
            };
          });
        if (markups.length === 0) {
          const { status: status3, data: data3 } = (await getOldMarkups(data.md5)) as any;

          if (status3 === 200 && (data3?.system || []).length > 0) {
            markups = [
              {
                tabName: '课堂实录目录',
                panelData: data3.system.map((t: any, index: number) => {
                  return {
                    name: t.description,
                    key: index,
                    time: t.timeTick,
                  };
                }),
              },
            ];
          }
        }

        return Promise.resolve({
          ...data,
          markups,
        });
      }
    }
  } catch (error) {
    (window as any)._dispatch({
      type: types.GET_PREVIEW_LESSON,
      payload: { fileId: '', url: '', marks: [] },
    });
    if (error && error.data) {
      message.error(error.data.message);
      if (error.status === 404) {
        (window as any)._dispatch({ type: types.FILE_NO_EXIST, payload: true });
      }
    }
    return Promise.resolve({ fileId: '', url: '', marks: [] });
  }
}
/**
 * 获取点播视频文件预览信息
 * @param fileId 文件Id
 */
export async function getMark(fileId: string) {
  try {
    const { status, data } = await AxiosWrap({ url: `/vod-files/${fileId}/preview` });
    if (status === 200) {
      return Promise.resolve(data);
    }
  } catch (error) {
    (window as any)._dispatch({
      type: types.GET_PREVIEW_LESSON,
      payload: { fileId: '', url: '', marks: [] },
    });
    return Promise.resolve({ fileId: '', url: '', marks: [] });
  }
}
// 获取主视频与ppt关系
export async function getRelation(videoID: string[]) {
  try {
    const _videoID_: string = videoID.join('&videoIds=');
    const { status, data } = await AxiosWrap({ url: `/video-ppt-mappings?videoIds=${_videoID_}` });
    if (status === 200) {
      return Promise.resolve(data);
    }
  } catch (error) {
    return Promise.resolve(false);
  }
}
/**
 * 上传课时
 */
export async function uploadLesson(params: NormalObj) {
  try {
    const { status, data } = await AxiosWrap({
      url: '/reword-lessons',
      method: 'post',
      data: params,
    });
    if (status === 200 && data) {
      return Promise.resolve(data);
    }
  } catch (err) {
    if (err && err.status === 400) message.error(err.data && err.data.message);
    return Promise.resolve(false);
  }
}
/**
 * 获取还课列表
 */
export function getList(lessonId: string, cb?: any) {
  AxiosWrap({
    url: `/lessons/${lessonId}/reword-lessons`,
  })
    .then((res) => {
      (window as any)._dispatch({ type: types.GET_UPLOAD_LESSON, payload: Immutable(res.data) });
      cb && res.data.length > 0 && cb(res.data[0].rewordLessonId, 0);
    })
    .catch(() => (window as any)._dispatch({ type: types.GET_UPLOAD_LESSON, payload: [] }));
}
/**
 * 创建评论
 */
export function createReword(id: string, data: NormalObj, success: (data: NormalObj) => void) {
  AxiosWrap({
    url: `/reword-lessons/${id}/comments`,
    method: 'post',
    data,
  })
    .then(({ data }) => {
      success(data);
      getReword(id);
      getRewordList(id);
    })
    .catch((err) => err && err.data && message.error(err.data.message));
}
/**
 * 获取评分
 * @param id
 */
export function getReword(id: string) {
  AxiosWrap({
    url: `/reword-lessons/${id}/score`,
  })
    .then((res) => (window as any)._dispatch({ type: types.GET_SCORE, payload: res.data }))
    .catch(() =>
      (window as any)._dispatch({
        type: types.GET_SCORE,
        payload: {
          count: 0,
          score: null,
        },
      }),
    );
}
/**
 * 获取评论列表
 */
export function getRewordList(id: string, params = { page: 0, size: 10 }) {
  AxiosWrap({
    url: `/reword-lessons/${id}/comments`,
    params,
  })
    .then((res) => {
      const result = {
        page: res.data.page,
        list: [],
      };
      if (result.page.totalElements > 0) {
        result.list = res.data._embedded.comments;
      }
      (window as any)._dispatch({ type: types.COMMENT_LIST, payload: result });
    })
    .catch(() =>
      (window as any)._dispatch({
        type: types.COMMENT_LIST,
        payload: {
          page: {
            size: 10,
            number: 0,
            totalElements: 0,
          },
          list: [],
        },
      }),
    );
}
/**
 * 获取还课详情
 */
export async function getRewordLesson(id: string) {
  try {
    const { status, data } = await AxiosWrap({ url: `/reword-lessons/${id}` });
    if (status === 200 && data) {
      getReword(id);
      getRewordList(id);
      return Promise.resolve(data);
    }
  } catch (error) {
    message.error(error.data.message);
    return Promise.resolve({
      url: '',
      playId: '',
      orgName: '',
      createTime: '',
    });
  }
}

// 获取播放器地址
export async function getPlayerUrl(type: number) {
  try {
    const { data } = await AxiosWrap({ url: `/application?type=${type}` });
    return Promise.resolve(data.url);
  } catch (error) {
    console.error(error);
  }
}

/**
 * 获取最新版本的闻道播放器信息
 * @param code 软件唯一编码
 */
export async function getNewestPlayerInfo(code = 'knowspring_player') {
  try {
    const { data } = await AxiosWrap({
      baseURL: apiConfig.userCenterApi,
      url: `api/SoftWareUpgrade/GetMaxEnableToSiteByCode?code=${code}`,
      ignoreLoading: true,
      noUserId: true,
      noLeagueId: true,
    });

    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
  }
}

// 课时资料文件列表
export async function getLessonResList(lessonId: string) {
  try {
    const { data } = await AxiosWrap({ url: `/lesson/${lessonId}/resources-file` });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
  }
}

/**
 * 获取视频的打点文件
 * @param md5 文件md5
 */
export async function getAllMarkups(md5: string) {
  try {
    const { status, data } = (await getNewMarkups(md5)) as any;

    if (status === 200) {
      let markups = [];
      if (data.length) {
        const Obj = {
          COURSE_MEMOIR: '课堂实录目录', // 普通实录
          MODEL_COURSE: '课堂实录目录', // 示范课
          PREPARE_LESSON_MEMOIR: '备课实录目录',
          TEACHING_RESEARCH_RECORD: '教研实录目录',
        };
        markups = data
          .filter((t: any) =>
            [
              'COURSE_MEMOIR',
              'MODEL_COURSE',
              'PREPARE_LESSON_MEMOIR',
              'TEACHING_RESEARCH_RECORD',
            ].includes(t.resourceType),
          )
          .map((t: any) => {
            return {
              tabName: (Obj as any)[t.resourceType],
              panelData: parseMulLayerMarkerData(t.data),
            };
          });
      } else {
        const { status: status3, data: data3 } = (await getOldMarkups(data.md5)) as any;

        if (status3 === 200 && (data3?.system || []).length > 0) {
          markups = [
            {
              tabName: '课堂实录目录',
              panelData: data3.system.map((t: any, index: number) => {
                return {
                  name: t.description,
                  key: index,
                  time: t.timeTick,
                };
              }),
            },
          ];
        }
      }

      return Promise.resolve(markups);
    }
  } catch (error) {
    // (window as any)._dispatch({
    //   type: types.GET_PREVIEW_LESSON,
    //   payload: { fileId: '', url: '', marks: [] },
    // });
    // if (error && error.data) {
    //   message.error(error.data.message);
    //   if (error.status === 404) {
    //     (window as any)._dispatch({ type: types.FILE_NO_EXIST, payload: true });
    //   }
    // }
    return Promise.resolve([]);
  }
}

// 获取打点文件
export async function getOldMarkups(md5: string) {
  try {
    const data = await AxiosWrap({
      url: `/v1/videos/${md5}/markups/0`,
      baseURL: apiConfig.markupService,
      noUserId: true,
      noLeagueId: true,
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
  }
}

// 获取打点文件
export async function getNewMarkups(md5: string) {
  try {
    const data = await AxiosWrap({
      url: `/api/markups?version=2.0`,
      baseURL: apiConfig.markupService,
      params: {
        md5List: md5,
      },
      noUserId: true,
      noLeagueId: true,
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
  }
}

// 上传打点文件
export async function uploadMarkupFile(params: any) {
  try {
    const formData = new FormData();
    formData.append('file', params.file);

    const data = await AxiosWrap({
      url: `/api/markup-file/videos?version=2.0`,
      baseURL: apiConfig.markupService,
      data: formData,
      params: {
        md5: params.md5,
        level: 'SYSTEM_MARKUP',
        target: 'VIDEO',
        markupState: 'PUTAWAY',
      },
      noUserId: true,
      noLeagueId: true,
      upload: true,
      method: 'POST',
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.resolve(false);
  }
}

export const apiGetOcrfByMp4md5 = (videoMd5: string) => {
  return AxiosWrap({
    method: 'GET',
    baseURL: apiConfig.gateway,
    url: `/resource-center-basic-file-service/api/admin/v1/files`,
    params: {
      md5: videoMd5,
    },
    noUserId: true,
    noLeagueId: true,
  });
};

export const apiGetMp4ByOcrfmd5 = (ocrfMd5: string) => {
  return AxiosWrap({
    method: 'GET',
    baseURL: apiConfig.gateway,
    url: `/resource-center-basic-file-service/api/admin/v1/files`,
    params: {
      md5: ocrfMd5,
      extensionType: 'VIDEO',
    },
    noUserId: true,
    noLeagueId: true,
  });
};
