/*
 * @Author: gouyang
 * @Description: 文件类型对应的icon
 * @Date: 2019-07-18 11:18:30
 */
const mapFileType = (type: string) => {
  const _type_ = type.toLowerCase();
  let result = 'iconother';
  const iconConfig: { [s: string]: string[] } = {
    iconimg: ['jfif', 'pjpeg', 'jpeg', 'pjp', 'png', 'gif', 'jpg'],
    icongif: ['swl', 'swf'],
    iconaudio: ['mp3', 'wma', 'wav'],
    iconvedio1: ['mp4', 'm4v', 'avi', 'mkv', 'flv', 'rm', '3gp', 'asf', 'rmvb'],
    iconexcel: ['xls', 'xlsx'],
    icontxt1: ['text', 'txt'],
    iconword: ['doc', 'docx'],
    iconic_courseware_pdf: ['pdf'],
    iconzip: ['zip', 'rar', '7z'],
    iconppt: ['ppt', 'pptx'],
  };
  Object.keys(iconConfig).forEach((key: string) => {
    const arr: string[] = iconConfig[key];
    if (arr.indexOf(_type_) !== -1) {
      result = key;
    }
  });
  return result;
};

export default mapFileType;
