/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import getBrief from '../../../../lib/getBrief';
import ListTags from '../../../../components/ListTags';

interface P {
  data: any;
  onClick: () => void;
}
const CourseItem = ({ data, onClick }: P) => {
  const [brief, setBrief] = useState({
    html: '',
    pureText: '',
  });

  useEffect(() => {
    if (data) setBrief(getBrief(data.brief));
  }, [data]);

  return (
    <div className="Panel-Component commom-item" onClick={onClick}>
      <div className="panel-image">
        <img src={data.coverUrl} alt="课程封面图" />
      </div>
      <div className="panel-right">
        <div
          className="panel-title mb8 overflow"
          title={data.courseName}
          dangerouslySetInnerHTML={{ __html: data.highLightCourseName }}
        />
        {brief.pureText && (
          <div
            className="panel-intro mb8 overflow"
            dangerouslySetInnerHTML={{ __html: brief.html }}
            title={brief.pureText}
          />
        )}
        <div className="panel-time">
          {/* <span className="time">创建时间：{moment(data.createTime).format('YYYY-MM-DD HH:mm')}</span> */}
          <span className="time">
            更新时间：
            {moment(data.updateTime).format('YYYY-MM-DD HH:mm')}
          </span>
        </div>
        {data.labels && (
          <div style={{ marginTop: '10px' }}>
            <ListTags tags={data.labels} />
          </div>
        )}
        <div className="panel-btn">
          <div className="panel-number">
            共
            <span className="panel-number-mid ">
              {data.lessonNum && data.lessonNum.length > 0
                ? data.lessonNum.reduce((initial: number, item: any) => {
                    return (initial += item.num);
                  }, 0)
                : 0}
            </span>
            课时
          </div>
        </div>
      </div>

      <div className="panel-click">
        <Button className="green">观看课程</Button>
      </div>
    </div>
  );
};

export default CourseItem;
