/* eslint-disable guard-for-in */
/*
 * @Author: gouyang
 * @Description: 创建文章
 * @Date: 2019-07-16 08:57:45
 */

import React from 'react';
import { Button, Form, message } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router';
import TextEditor from './../../components/TextEditors';
import { getSearchParams, stringifySearchParams } from '../../util';
import { AJTextField } from './../../components/AJInput';
import { editorArticle } from './../../redux/action/createArticle.action';
import TrackRequest from '../../lib/trackRequest';
import Material from './components/material'; // 资料
import DynamicSetting from '../Lesson/dynamic/dynamicSetting';
import TagSelect from '../../components/TagSelect';
import { TagList } from '../../components/TagSelect/interface';
import PublishInformation from './components/publishInformation';
import './createArticle.less';
import { ReleaseType } from '../../util/enum';

interface P extends RouteComponentProps, FormComponentProps {
  history: any;
  test: any;
  name: string; // 文章名称
  tags: TagList[];
  getInfoName: (name: any) => void;
  getPropsEditorData: (html: string, text: string) => void;
  getEditorObj: (target: any) => void;
  editorHtml: string;
  pureEditorText: string;
  controls: any[];
  CreateArticle: any;
  flieList: any[];
  cleanFileLists: () => void;
  deadline: (time: any) => void;
  updateTags: (tags: TagList[]) => void;
  publishRange: (data: any) => void;

  grades: any[];
  releaseType: ReleaseType;
  studySectionCode: string; // 学段
  studySectionName: string;
  schoolYear: string; // 学年
  subjects: any[]; // 学科
}
interface S {
  current: string; // 导航激活
  nav: any; // 导航
  id: string; // 公告id
  editorIsEmpty: boolean;
}

let publishInformationForm: any = null;

class CreateArticle extends React.PureComponent<P, S> {
  searchParams: any; // 地址栏 searh 数据
  private dynamicSetting: any = null;

  constructor(props: P) {
    super(props);
    this.state = {
      id: '',
      current: '公告信息',
      nav: [
        {
          name: '公告信息',
          id: 'information',
        },
        {
          name: '添加资料',
          id: 'addData',
        },
      ],
      editorIsEmpty: false,
    };
    this.searchParams = getSearchParams(props.location.search);
  }
  componentDidMount() {
    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    const { noticeId } = this.searchParams;
    const flag = (window as any)._editor; // 判断是否由预览返回
    // 公告id
    if (noticeId && !flag) {
      editorArticle(noticeId);
    }
    this.setState({ id: noticeId });
  }
  // 转换 资源数据格式
  formatData = (data: any) => {
    const list: any = [];
    for (const i in data) {
      if (data[i].isResource) {
        list.push({
          resourceGuid: data[i].resourceGuid,
          category: data[i].category,
        });
      } else {
        list.push({
          category: data[i].category,
          resourceGuid: data[i].resourceGuid,
          description: '',
          name: data[i].name,
          files: [
            {
              extension: data[i].extension,
              fileId: data[i].fileId,
              fileName: data[i].fileName,
              md5: data[i].md5,
              size: data[i].size,
            },
          ],
        });
      }
    }
    return list;
  };
  // 预览
  previewClick = async () => {
    const { pureEditorText, editorHtml, flieList, cleanFileLists, deadline, publishRange } =
      this.props;
    const { id } = this.state;
    let flag = false;
    // 预览埋点
    TrackRequest({ name: 'cre_pre_notice', data: {} });

    if (pureEditorText.length > 5000) return null;
    if (pureEditorText.length === 0 && editorHtml.indexOf('img') === -1) {
      this.setState({
        editorIsEmpty: true,
      });
      flag = true;
    }
    const uploading = flieList.find((file: any) => file.status && file.status === 'upload');

    this.props.form.validateFields(async (error: any) => {
      if (error || flag) {
        //
      } else if (uploading) {
        message.warning('请等待资源上传完成后，再进行预览！');
      } else {
        const { formatValue } = await this.dynamicSetting.getItemsValue();
        const infos = await publishInformationForm.getItemsValue();
        console.log('infos: ', infos);
        publishRange(infos);
        deadline(formatValue);

        this.searchParams.noticeId = id;
        cleanFileLists();
        this.props.history.push(`/articlePreview${stringifySearchParams(this.searchParams)}`);
      }
    });
  };

  // 编辑器数据
  getEditorData(html: string, text: string) {
    this.state.editorIsEmpty && this.setState({ editorIsEmpty: false });
    this.props.getPropsEditorData(html, text);
  }

  // 获取公告名称
  getNoticeName(): void {
    const { form, getInfoName } = this.props;
    getInfoName(form.getFieldValue('name'));
  }
  getTrimNoticeName(): void {
    const { form, getInfoName } = this.props;
    getInfoName(form.getFieldValue('name').trim());
  }

  render() {
    const { nav, editorIsEmpty, id } = this.state;
    const {
      getEditorObj,
      editorHtml,
      pureEditorText,
      controls,
      updateTags,
      tags,
      grades,
      releaseType,
      studySectionCode,
      schoolYear,
      subjects,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const fianlNav = JSON.parse(JSON.stringify(nav));
    if (controls.length > 0) {
      fianlNav.splice(1, 0, {
        name: '其它信息',
        id: 'others',
      });
    }

    return (
      <div className="coursePage" style={{ backgroundColor: 'transparent' }}>
        <div className="content newClassHour" id="newClassHour">
          <div className="newClassHourTop" id="navId">
            {fianlNav.map((item: any) => (
              <span key={item.id}>{item.name}</span>
            ))}
          </div>
          <div className="shadow-style">
            <div className="model" id="information">
              <h3 className="notice-info">公告信息</h3>
              <div className="information">
                <Form onChange={() => this.getNoticeName()} onBlur={() => this.getTrimNoticeName()}>
                  <Form.Item {...formLayout} label="公告名称">
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          max: 100,
                          required: true,
                          message: '请输入公告名称！',
                        },
                      ],
                    })(<AJTextField placeholder="请输入公告名称" maxLength={100} />)}
                  </Form.Item>
                </Form>
              </div>
              <div className="tags">
                <span className="label">标签：</span>
                <TagSelect onChange={updateTags} value={tags} groupCode="kechenggonggao" />
              </div>
            </div>
            <div className="model editor-text">
              <div className="editor-title">文本描述</div>
              <div className="editor">
                <div
                  className={`${
                    (editorIsEmpty || pureEditorText.length > 5000) && 'editor-content'
                  }`}
                  style={{ position: 'relative', zIndex: 800 }}
                >
                  <TextEditor
                    getEditorObj={getEditorObj}
                    editorHtml={editorHtml}
                    getData={(html: string, text: string) => this.getEditorData(html, text)}
                  />
                </div>
                <div className="tips">
                  <p>
                    <span className="error">{editorIsEmpty && '文本描述不能为空！'}</span>
                    <span className="error">
                      {pureEditorText.length > 5000 && '文本内容超过限制！'}
                    </span>
                  </p>
                  <span>{pureEditorText.length}/5000</span>
                </div>
              </div>
            </div>
            {
              <DynamicSetting
                controls={controls}
                wrappedComponentRef={(from: any) => (this.dynamicSetting = from)}
              />
            }
          </div>
          <div className="shadow-style">
            <Material />
          </div>
          <div className="shadow-style">
            <PublishInformation
              isEdit={!!id}
              wrappedComponentRef={(from: any) => (publishInformationForm = from)}
              grades={grades}
              releaseType={releaseType}
              studySectionCode={studySectionCode}
              schoolYear={schoolYear}
              subjects={subjects}
              studySectionName=""
            />
          </div>
          <div className="newClassHourbtns">
            <Button type="primary" onClick={this.previewClick}>
              预览
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 },
};
const mapStateToProps = (state: any) => {
  const { controlData } = state.CreateArticle.extensionData || [];
  const controls = state.DynamicControl.noticeControls || [];
  if (controlData) {
    for (const i in controls) {
      for (const j in controlData) {
        if (controls[i].controlId === controlData[j].controlId) {
          if (controls[i].value) {
            controls[i].defaultValue = controls[i].value;
          } else {
            controls[i].defaultValue = controlData[j].value;
          }
        }
      }
    }
  } else {
    for (const i in controls) {
      if (controls[i].value) controls[i].defaultValue = controls[i].value;
    }
  }
  return {
    CreateArticle: state.CreateArticle,
    name: state.CreateArticle.name,
    editorHtml: state.CreateArticle.editorHtml,
    pureEditorText: state.CreateArticle.pureEditorText,
    controls,
    flieList: state.CreateArticle.flieList,
    tags: state.CreateArticle.tags,
    grades: state.CreateArticle.grades,
    releaseType: state.CreateArticle.releaseType,
    studySectionCode: state.CreateArticle.studySectionCode, // 学段
    studySectionName: state.CreateArticle.studySectionName,
    schoolYear: state.CreateArticle.schoolYear, // 学年
    subjects: state.CreateArticle.subjects, // 学科
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  // 获取信息名称
  getInfoName: (name: any) => {
    dispatch({ type: 'ARTICLE_NAME', payload: name });
  },

  // 文本编辑器内容
  getPropsEditorData: (html: string, text: string) => {
    dispatch({ type: 'EDITOR_DATA', payload: { html, text } });
  },

  // 获取文本编辑器对象
  getEditorObj: (target: any) => {
    dispatch({ type: 'EDITOR_TARGET', payload: target });
  },

  // 清洗文件数组
  cleanFileLists: () => {
    dispatch({ type: 'CLEAN_FILE_LISTS' });
  },

  // 截止时间
  deadline: (time: any) => {
    dispatch({ type: 'DEADLINE', payload: time });
  },

  publishRange: (data: any) => {
    dispatch({ type: 'PUBLISH_RANGE', payload: data });
  },

  // 更新标签
  updateTags: (tags: TagList[]): void => {
    dispatch({ type: 'UPDATETAGS', payload: tags });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Form.create<P>({
    mapPropsToFields: (props: any) => {
      return {
        name: Form.createFormField({
          value: props.name,
        }),
      };
    },
  })(CreateArticle),
);
