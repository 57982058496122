import React, { PureComponent } from 'react';
// import axios from 'axios';
import { Modal } from 'antd';
import {
  // getToken,
  formatUrl,
  uuid,
} from '../../../util';
// import env_api from '../../../config/api';
import ResourceViewCom from '../../../components/media/resourceView/resourceView';
import './style.less';

interface S {
  visible: boolean;
  previewUrl: string;
  // previewPlayId: string | number;
  // showPreview: boolean;
  mediaType: string;
}
interface P {
  resource: any;
  visible: boolean;
  previewUrl: string;
  cancel: () => void;
  title: string;
  // previewPlayId: string | number; // 预览视频id 腾讯云
  extension: string;
}
// const URL = env_api.file;
// let cloudPlayer: any;
// const token = getToken();
// const header = {
//   Authorization: `Bearer ${token}`,
//   'x-module': 'open_school',
// };
class VedioPreview extends PureComponent<P, S> {
  appId = '';
  private randomMd5: string;
  constructor(props: any) {
    super(props);
    this.randomMd5 = uuid();
    this.state = {
      visible: false,
      previewUrl: '',
      // previewPlayId: '',
      // showPreview: false,
      mediaType: '',
    };
  }
  componentDidMount() {
    const {
      visible,
      previewUrl,
      // previewPlayId,
      extension,
    } = this.props;
    if (visible) {
      this.setState(
        {
          visible,
          previewUrl,
          // previewPlayId,
          mediaType: this.getExtensionType(extension),
        },
        // () => {
        //   // 腾讯云播放
        //   if (previewPlayId) {
        //     this.getTencentAppId(previewPlayId);
        //   }
        // }
      );
    }
  }
  // 创建播放器
  // creactCloudPlayer = (fileID: string) => {
  //   const { previewUrl } = this.state;
  //   // 	/*
  //   //         fileID: '7447398155662759364', // 请传入需要播放的视频filID 必须
  //   //         player-container-id 为播放器容器ID，必须与html中一致
  //   //         appID: '1400104604', // 请传入点播账号的appID 必须
  //   //     */
  //   cloudPlayer = (window as any).TCPlayer('player-container-id', {
  //     fileID,
  //     appID: this.appId,
  //     autoplay: true,
  //     urls: {
  //       20: previewUrl,
  //       // 20: "http://1400116577.vod2.myqcloud.com/fa85a162vodcq1400116577/f8fa805a5285890781247249398/MxH0JVi8l04A.mp4"//演示地址，请替换实际地址
  //       // 20: activeLesson.coursewareUrl
  //     },
  //     plugins: {
  //       ContinuePlay: {
  //         // auto: true //
  //       },
  //     },
  //     flash: {
  //       swf: `//${this.appId}.vod2.myqcloud.com/vod-player/${this.appId}/${fileID}/tcplayer/player.swf`,
  //     },
  //   });
  //   // cloudPlayer.on('ended', () => {
  //   //     // this.showEnd();
  //   //     // cloudPlayer.exitFullscreen();
  //   // });
  //   cloudPlayer.on('error', (code: any) => {
  //     console.log(code);
  //   });
  //   // cloudPlayer.on('ready', () => {
  //   //     // if (time) {
  //   //     //     cloudPlayer.currentTime(time);
  //   //     // }
  //   // });
  //   // cloudPlayer.on('pause', () => {
  //   //     // this.pause = true;
  //   // });
  //   // cloudPlayer.on('play', () => {
  //   //     // this.pause = false;
  //   // });
  // };
  // 获取appid
  // getTencentAppId = (playId: any) => {
  //   axios({
  //     method: 'GET',
  //     url: `${URL}/v3/cloud/bucket`,
  //     headers: header,
  //     params: {
  //       provider: 'tencent',
  //       playId,
  //     },
  //   }).then(
  //     (res) => {
  //       this.disposeCloudPlayer();
  //       if (res.data && res.data.subAppId) {
  //         this.appId = res.data.subAppId;
  //         this.creactCloudPlayer(playId);
  //       } else {
  //         setTimeout(() => {
  //           this.getTencentAppId(playId);
  //         }, 1000);
  //       }
  //     },
  //     (code) => {}
  //   );
  // };
  // disposeCloudPlayer = () => {
  //   if (cloudPlayer) {
  //     cloudPlayer.dispose();
  //   }
  //   // cloudPlayer = null;
  //   this.setState({
  //     showPreview: true,
  //   });
  // };
  /**
   * 转化文件分类
   * @param  extension 文件分类
   */
  getExtensionType(extension: string) {
    // let supportType = ['ppt', 'pptx', 'jpg', 'png', 'mp3', 'wma', 'wav', 'mp4', 'swf', 'xls', 'xlsx', 'doc', 'docx', 'pdf'];
    const audioType = ['mp3', 'wma', 'wav'];
    const imageType = ['jpg', 'png'];
    const vedioType = ['mp4'];
    const flashType = ['swf'];
    const officeType = ['ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx', 'pdf'];
    if (audioType.indexOf(extension) > -1) {
      return 'mp3';
    } else if (vedioType.indexOf(extension) > -1) {
      return 'mp4';
    } else if (officeType.indexOf(extension) > -1) {
      return 'file';
    } else if (imageType.indexOf(extension) > -1) {
      return 'image';
    } else if (flashType.indexOf(extension) > -1) {
      return 'flash';
    } else {
      return '';
    }
  }
  render() {
    const {
      visible,
      previewUrl,
      // previewPlayId, showPreview,
      mediaType,
    } = this.state;
    const { resource, cancel, title } = this.props;
    return (
      <div>
        <Modal
          key={Date.now()}
          title={title}
          visible={visible}
          onCancel={cancel}
          footer={null}
          width={1000}
          maskClosable={false}
          className="preview-modal"
          getContainer={() => document.getElementById('ContentComponent')!}
        >
          <div className="preview-wrap">
            {/* {previewUrl && !previewPlayId ? ( */}
            {previewUrl ? (
              <ResourceViewCom
                mediaType={mediaType}
                mediaAddress={formatUrl(resource, this.randomMd5)}
              />
            ) : null}
            {/* {previewUrl && previewPlayId ? (
              <div className="preview-player">
                {showPreview ? (
                  <video
                    id="player-container-id"
                    width="100%"
                    height="100%"
                    preload="auto"
                    playsInline
                    x5-playinline="test"
                    webkit-playinline="test"
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )} */}
          </div>
        </Modal>
      </div>
    );
  }
}

export default VedioPreview;
