import * as types from '../types/resource.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  packages: [], // 课程表列表
  page: 0,
  hasMore: true,
};

const Article = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.RESET_RESOURCE_STATE: // 清空资料包列表
      return { ...state, packages: [], page: 0, hasMore: true };
    case types.GET_RESOURCE_LIST: // 资料包列表
      return { ...state, packages: [...state.packages, ...payload] };
    case types.CURRENT_PAGE: // 分页
      return { ...state, page: payload };
    case types.HAS_MORE: // 加载完毕
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};
export default Article;
