export enum ArtScience {
  '文理科',
  '文科',
  '理科',
}

export enum ReleaseType {
  ALL = 1, // 全部发布
  PART = 2, // 部分发布
}
