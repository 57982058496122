/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { Component, useRef } from 'react';
import moment from 'moment';
import Immutable from 'seamless-immutable';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import {
  Row,
  Col,
  Timeline,
  message,
  Form,
  Button,
  Progress,
  Pagination,
  Modal,
  Rate,
  Select,
  Icon,
  Tooltip,
} from 'antd';
import * as action from '../../redux/action/vod.action';
import * as detailAction from '../../redux/action/course.detail.action';
import * as menuAction from '../../redux/action/menu.action';
import Alert from '../../components/Alert/Alert';
import AttachmentItem from '../../components/AttachmentItem';
import ResourceViewCom from '../../components/media/resourceView/resourceView';
import {
  NormalObj,
  mergeProps,
  getSearchParams,
  stringifySearchParams,
  getLeagueId,
  getToken,
  getUserId,
  getStudySectionId,
  // formateMinutes,
  hideSubjectTag,
  formatUrl,
  uuid,
  MyIcon,
  getExtensionType,
} from '../../util';
// import api from '../../config/api';
import { AJTextArea } from '../../components/AJInput';
import Footer from '../../components/Footer';
import EmptyList from '../../components/Empty';
import basicUpload from '../../util/fileServiceUpload';
import TrackRequest from '../../lib/trackRequest';
import { PlayTime } from '../../util/playTime';
import { ArtScience } from '../../util/enum';
import './index.less';
import { VideoMarkerPlayer } from 'video-marker-player';
import 'video-marker-player/dist/index.css';
import { downloadCosFile } from '../../util/cosFileDownload';

// let topPlayer: any;
// let bottomPlayer: any;
const { Item } = Timeline;
const { Option } = Select;
interface P extends RouteComponentProps, FormComponentProps {
  file_no_exist: Boolean;
  courseInfo: NormalObj;
  lessonList: NormalObj[];
  lesson_list: NormalObj[];
  commentResult: NormalObj;
  scoreDetail: NormalObj;
  functionConfig: NormalObj;
}
class Vod extends Component<P, any> {
  appId: string;
  pause: boolean;
  topRef: any;
  bottomRef: any;
  media: any;
  xgPlayer: any;
  bottomMedia: any;
  rewordId: string;
  fileInput: any;
  searchParams: NormalObj;
  nextCourse: NormalObj;
  playerPrevStatus: string;
  private currentFileInfos: any; // 当前文件信息
  private uploadFileObj: any; // 文件上传对象
  private ids: {
    fileId: string;
    resourceId: string;
    lessonId: string;
    courseId: string;
    source: number;
    userId: number;
  }; // 播放时长提交数据
  private mainVideoInfo: any; // 点播视频信息
  private PlayTimeFn: any; // 播放时长统计对象
  private classRcordVideoId: string | null; // 课堂实录id
  private hasBeenDeleted: boolean;
  private isTurnTo: boolean;
  private randomMd5: string;
  /**
   * topTx 顶部腾讯播放器播放
   * topH5 顶部H5播放器播放
   * bottomTx 底部腾讯播放器播放
   * bottomH5 底部H5播放器播放
   */
  private videoStatus: 'topTx' | 'topH5' | 'bottomTx' | 'bottomH5' | null = null;

  constructor(props: P) {
    super(props);
    this.appId = '';
    this.bottomRef = null;
    this.media = null;
    this.bottomMedia = null;
    this.pause = false;
    this.resize = this.resize.bind(this);
    this.rewordId = '';
    this.fileInput = null;
    this.topRef = null;
    this.searchParams = getSearchParams(props.location.search);
    this.nextCourse = {};
    this.playerPrevStatus = '';
    this.uploadFileObj = null;
    this.isTurnTo = false;
    this.randomMd5 = uuid();
    this.currentFileInfos = {
      id: '',
      extension: '',
      category: -1,
      resourceGuid: '',
    };
    this.state = {
      number: 0,
      okDisable: true,
      showPreview: false,
      showMarkList: true,
      lesson_list: [],
      vedioHeight: window.innerHeight - 180,
      preViewProps: {
        url: '',
        type: '',
      },
      // activeVodIdx: '',
      isShowFileInput: true,
      activeTimeIndex: '',
      activeLessonIndex: '',
      activeRewordIndex: '',
      switchBottomModel: true, // 底部模块显示/隐藏标示
      lessonInfo: {
        // 可是详情
        centerResources: [],
        materialChapters: [],
        _video_: [], // 点播课时
        _material_: [], // 资料课时
      },
      preview_lesson: {
        // 上面预览详情
        url: '',
        marks: [],
        fileId: '',
        playId: null,
      },
      rlesson: {
        url: '',
        playId: '',
        orgName: '',
        createTime: '',
      },
      isShowDowload: false, // 是否展示网席下载器链接
      wxUrl: '', // 网席播放器地址
      splitScreenUrl: '', // 读备空间地址
      openTitle: false,
      showResourceList: true,
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.pausePlayer = this.pausePlayer.bind(this);
    this.ids = {
      fileId: '',
      resourceId: '',
      lessonId: '',
      courseId: '',
      source: 0,
      userId: getUserId(),
    };
    this.mainVideoInfo = {
      id: '',
      extension: '',
      category: -1,
      resourceGuid: '',
    };
    this.classRcordVideoId = '';
    this.PlayTimeFn = PlayTime();
    this.hasBeenDeleted = false;
  }
  componentDidMount() {
    // 将页面置顶
    window.scrollTo(0, 0);

    const { type, lessonType, lessonId, courseId } = this.searchParams;

    if (type === '2' && lessonType === '2') {
      this.setState({ isShowDowload: true });
    }
    menuAction.getLeagueInfos((data: any) => {
      if (data.showRewordLesson) action.getList(lessonId, this.openFirstReword);
    });
    this.screenChange();
    this.ids = Object.assign(this.ids, {
      lessonId,
      courseId,
      source: type === '2' ? 0 : 3,
    });
    this.initData();
    // 切换底部模块
    this.switchBottomModel();
    // 获取网席播放器
    this.getPlayerUrl(0);
    // 获取四分屏地址
    this.getPlayerUrl(1);
    window.addEventListener('visibilitychange', this.onStopVideo);
  }

  componentWillReceiveProps(nextProps: P) {
    // 还课列表
    const { lesson_list } = nextProps;
    if (lesson_list !== this.props.lesson_list) {
      this.setState({
        lesson_list,
      });
    }
  }
  componentWillUnmount() {
    this.PlayTimeFn.clearStatus();
    window.removeEventListener('resize', this.resize);
    // topPlayer && topPlayer.dispose();
    // bottomPlayer && bottomPlayer.dispose();
    // topPlayer = null;
    // bottomPlayer = null;
    Alert.remove('lesson');
    Alert.remove('lessonList');
    window.removeEventListener('visibilitychange', this.onStopVideo);
  }

  // 暂停播放
  onStopVideo = (): void => {
    if (document.visibilityState === 'hidden') {
      this.pausePlayer();
    }
  };

  // 获取播放器地址
  async getPlayerUrl(type: number) {
    const url = await action.getPlayerUrl(type);
    switch (type) {
      case 0:
        this.setState({ wxUrl: url });
        break;
      case 1:
        this.setState({ splitScreenUrl: url });
        break;
      default:
    }
  }
  // 切换底部模块
  switchBottomModel = () => {
    const { type, lessonType } = this.searchParams;
    if ((type === '2' && lessonType === '3') || type === '0') {
      // 我的同步点播课时显示还课{type: 2(同步), type: 0(创建)}
      this.setState({
        switchBottomModel: false,
      });
    }
    if (type === '2') {
      detailAction.getList(this.searchParams.courseId, type, 'lessonList');
    }
  };
  // 初始化数据获取
  initData = async () => {
    // 获取课时详情
    const lessonInfo = await action.fetchLesson(this.searchParams.lessonId, this.searchParams.type);
    this.classRcordVideoId = this.getClassRecordVideoId(lessonInfo);
    const { file_no_exist, location } = this.props;
    this.searchParams = getSearchParams(location.search);
    const { idx, mainVedio, centerResources } = this.filterData(lessonInfo, file_no_exist);
    lessonInfo.centerResources = centerResources;
    const id = this.getMainId(mainVedio, idx);

    if (
      centerResources &&
      centerResources.length > 0 &&
      centerResources.every((item: NormalObj) => this.isSupportType(item.extension, true))
    ) {
      this.setState({ noSupport: true });
    }
    const { video, material } = this.filterVideoAndMaterial(centerResources);
    lessonInfo._video_ = video;
    lessonInfo._material_ = material;
    this.setState(
      {
        lessonInfo,
      },
      () => {
        if (this.searchParams && this.searchParams.resId) {
          const [id, idx] = this.searchParams.resId.split('*');
          const { category } = this.searchParams;
          const i = centerResources.findIndex(
            (resource: any) =>
              resource.fileId === id && resource.category === parseInt(category, 10),
          );
          if (i !== -1 && !centerResources[i].expiryState) {
            this.playLesson(this.searchParams.lessonType, id, idx, centerResources[i], true, true);
          }
          return null;
        }
        centerResources[idx] &&
          !centerResources[idx].expiryState &&
          this.playLesson(this.searchParams.lessonType, id, idx, centerResources[idx], true);
      },
    );
  };
  // 获取课堂实录视频id
  getClassRecordVideoId(classRecordInfo: any): string | null {
    const { classRecord } = classRecordInfo;
    let id = null;
    if (classRecord && Array.isArray(classRecord.files)) {
      classRecord.files.forEach((file: any) => {
        if (file.extension.toLowerCase() === 'mp4') {
          id = file.fileId;
        }
      });
    }
    return id;
  }
  // 获取视频
  /**
   * 预览默认视频----默认预览第一个可预览视频
   * @param type 课时类型 2:点播 3:资料
   * @param id 课时Id
   * @param index 课时序号
   * @param updateMainVideoInfo 是否更新点播视频信息
   */
  playLesson = async (
    type: string,
    id: string,
    index: number,
    item: any,
    updateMainVideoInfo: boolean,
    openDirect?: boolean,
  ) => {
    const { lessonId, courseId } = this.searchParams;
    let data: NormalObj = {};
    const { category, resourceGuid, extension } = item;
    if (category === 6 && extension.toLowerCase() === 'pdf') {
      message.error('暂不支持预览！', 1);
      data = { fileId: '', url: '', marks: [] };
    } else if (type === '2' || type === '3') {
      const previewType = this.searchParams.type === '2' ? 1 : 0;
      data = await action.filePreview(id, previewType, encodeURIComponent(item.fileName));
    }
    this.currentFileInfos = {
      id,
      extension: data.extension,
      category: item.category,
      resourceGuid,
    };
    if (updateMainVideoInfo && item.category === 1) {
      this.mainVideoInfo = { ...this.currentFileInfos };
    }
    this.ids = Object.assign(this.ids, { fileId: id, resourceId: resourceGuid });
    // 代码埋点
    if (data && data.url) {
      const trackData = {
        course_id: courseId,
        period_id: lessonId,
        material_id: resourceGuid,
        material_type: category,
        watch_entrance: 0,
      };
      // 我的创建
      if (this.searchParams.type === '0') {
        // 资料预览埋点
        TrackRequest({ name: 'preview_material', data: trackData });
      }
      // 我的同步
      else if (this.searchParams.type === '2') {
        // 资料观看埋点
        if (openDirect) {
          trackData.watch_entrance = 1;
        }
        TrackRequest({ name: 'watch_material', data: trackData });
      }
    }
    // this.disposeCloudPlayer('player-container-id');
    // if (data.playId) {
    //   this.getTencentAppId(data.playId, 'player-container-id');
    // } else {
    this.setState({
      showPreview: true,
      preViewProps: {
        url: formatUrl(data, this.randomMd5),
        type: getExtensionType(data.extension),
      },
    });
    // }
    this.setState({
      preview_lesson: data,
      activeLessonIndex: id + index,
    });
  };
  /**
   * 是否支持预览格式
   * @param type 资源后缀
   * @param isSupport 支持所有/一个都不支持
   */
  isSupportType(type: string, isSupport?: Boolean | undefined) {
    const supportType = [
      'ppt',
      'pptx',
      'jpg',
      'png',
      'mp3',
      'wma',
      'wav',
      'mp4',
      'swf',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'pdf',
    ];
    if (!isSupport) {
      return supportType.indexOf(type.toLowerCase()) > -1;
    } else {
      return supportType.indexOf(type.toLowerCase()) === -1;
    }
  }

  /**
   * 获取主视频ID
   * @param data 课堂实录/支持预览的附件
   */
  getMainId(data: NormalObj | null, index: any) {
    let id = '';
    switch (this.searchParams.lessonType) {
      case '2':
      case '3':
        id = data && data.fileId;
        break;
      default:
        break;
    }
    return id;
  }
  screenChange() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    this.setState({ vedioHeight: window.innerHeight - 180 });
  }

  toCourseDetail(course: NormalObj) {
    const { location, history } = this.props;
    const path = `${location.pathname.slice(
      0,
      location.pathname.indexOf('/courseDetail'),
    )}/courseDetail/${course.contentId}`;
    history.push(path);
  }
  /**
   * 实录标记跳转
   * @param timeMark 打点对象
   */
  turnTo(timeMark: NormalObj) {
    const { lessonId, courseId, type } = this.searchParams;
    if (type === '2')
      TrackRequest({
        name: 'use_record_marks',
        data: { course_id: courseId, period_id: lessonId },
      });

    if (this.media) {
      const time = this.media.duration();
      if (time > timeMark.timeTick) {
        this.isTurnTo = true;
        this.media.currentTime(timeMark.timeTick);
      }
      if (time < timeMark.timeTick) {
        Alert.open('对不起，该时间点已超过视频总时长！');
      }
      if (timeMark.timeTick < 0) {
        Alert.open('对不起，该时间点为异常时间点！');
      }
    }
  }
  /**
   * 预览不存在回调
   */
  previewError() {
    window.location.reload();
  }
  /**
   * 资源预览
   * @param id 附件id
   * @param extension 资源后缀
   * @param index 选中附件序号
   * @param type 1-点播, 2-附件
   */
  previewClick = async (item: any, index: number, type: string) => {
    this.PlayTimeFn.stopPlayTime();
    const { fileId: id, extension, resourceGuid } = item;
    if (this.isSupportType(extension, true)) {
      message.error('暂不支持预览！', 1);
      return;
    }
    if (this.state.activeLessonIndex === id + index) return;
    this.currentFileInfos = {
      id,
      extension,
      category: item.category,
      resourceGuid,
    };
    if (
      ['mp3', 'mp4'].indexOf(getExtensionType(extension)) > -1 &&
      (this.videoStatus === 'bottomH5' || this.videoStatus === 'bottomTx')
    ) {
      this.pausePlayer();
    } else if (this.videoStatus === 'topH5' || this.videoStatus === 'topTx') {
      this.videoStatus = null;
    }
    this.playLesson(type, id, index, item, false);
  };
  // 点播课时和资料课时
  filterVideoAndMaterial(data: any[]) {
    const video: any[] = [];
    const material: any[] = [];
    data.forEach((file: any) => {
      if (file.category === 1) {
        if (file.extension.toLowerCase() === 'mp4') {
          video.push(file);
        } else if (file.extension.toLowerCase() !== 'txt') {
          material.push(file);
        }
      } else {
        material.push(file);
      }
    });
    return { video, material };
  }
  /**
   * 过滤数据
   * @param data 数据源
   * @param file_no_exist 文件不存在 // todo
   */
  filterData(data: NormalObj, file_no_exist?: Boolean) {
    let idx = 0;
    const result: NormalObj[] = [];
    let mainVedio: NormalObj | null = null;
    const { centerResources, classRecord } = data;
    const mergeData: NormalObj[] = classRecord
      ? [classRecord, ...centerResources]
      : centerResources;
    if (mergeData && mergeData.length > 0) {
      for (let i = 0, len = mergeData.length; i < len; i++) {
        const item = mergeData[i];
        item.files.map((citem: NormalObj) => {
          citem.category = item.category;
          citem.resourceGuid = item.resourceGuid;
          citem.expiryState = item.expiryState;
          return citem;
        });
        result.push(...item.files);
      }
      switch (this.searchParams.lessonType) {
        case '2': // 点播预览
        case '3': // 资料预览
          mainVedio = result[0];
          if (file_no_exist) {
            idx = result.findIndex((item: NormalObj) => this.isSupportType(item.extension));
            if (idx > -1) mainVedio = result[idx];
          }
          break;
        default:
          break;
      }
    }
    return { idx, mainVedio, centerResources: result };
  }
  errorHandle = (msr: string) => {
    message.warning(msr);
    this.fileInput = null;
    this.setState({
      error: true,
      disable: false,
    });
  };
  fileErrorHandle = () => {
    this.setState({
      error: true,
      disable: false,
    });
  };
  // 打开 文件上传页
  clickUpload = () => {
    this.setState(
      {
        isShowFileInput: false,
      },
      () => {
        this.setState(
          {
            isShowFileInput: true,
          },
          () => {
            this.fileInput.click();
          },
        );
      },
    );
  };
  // 开始上传
  uploadFile() {
    this.hasBeenDeleted = false;
    const files = this.fileInput.files[0];
    const fileName = files.name.substring(0, files.name.lastIndexOf('.'));
    const suffix = files.name.substring(files.name.lastIndexOf('.') + 1, files.name.length); // 后缀名
    if (fileName.length > 30) {
      this.errorHandle('还课名称不能超过30！');
      return;
    }
    if (suffix.toLowerCase() !== 'mp4') {
      this.errorHandle('上传文件格式错误！');
      return;
    }
    if (files.size > 1024 * 1024 * 1024 * 2) {
      this.errorHandle('视频大小不能超过2G！');
      return;
    }
    this.setState({
      error: false,
      disable: true,
    });
    this.uploadFileObj = basicUpload(this.fileInput.files[0], this.fileUploadCback, true, true);
  }
  //  上传回调
  fileUploadCback = async (params: any) => {
    const { data, status, percent, message } = params;
    const files = this.fileInput.files[0];
    const fileName = files.name.substring(0, files.name.lastIndexOf('.'));
    if (status === 'start') {
      let { lesson_list } = this.state;
      lesson_list = transfromImmutableData(lesson_list);

      lesson_list.unshift({ name: fileName, type: 'local', percent: 0 });
      this.setState({
        lesson_list: Immutable(lesson_list),
      });
    } else if (status === 'progress') {
      // 开始上传-上传中
      let { lesson_list } = this.state;
      lesson_list = transfromImmutableData(lesson_list);
      const item = transfromImmutableData(lesson_list[0]);
      item.percent = Math.floor(percent * 100);
      lesson_list[0] = item;
      this.setState({
        // percent,
        disable: true,
        lesson_list: Immutable(lesson_list),
      });
    } else if (status === 'done') {
      // 上传完成
      const success: any = await action.uploadLesson({
        name: fileName,
        fileId: data.fileId,
        lessonId: this.searchParams.lessonId,
      });
      this.setState({
        disable: false,
        error: false,
      });
      if (success) {
        action.getList(this.searchParams.lessonId);
        // 上传还课埋点
        const { lessonId, courseId } = this.searchParams;
        TrackRequest({
          name: 'return_period',
          data: {
            course_id: courseId,
            period_id: lessonId,
            return_period_id: success.rewordLessonId,
          },
        });
      } else {
        // 视频已经存在
        if (this.hasBeenDeleted) return;
        let { lesson_list } = this.state;
        this.fileErrorHandle();
        lesson_list = lesson_list.asMutable();
        lesson_list.shift();
        this.setState({
          lesson_list: Immutable(lesson_list),
        });
      }
    } else if (status === 'error') {
      // 上传失败
      let { lesson_list } = this.state;
      lesson_list = lesson_list.asMutable();
      lesson_list.shift();
      this.setState({
        lesson_list: Immutable(lesson_list),
      });
      this.errorHandle(message);
    }
  };
  // 取消上传
  cancelUpload = () => {
    if (this.uploadFileObj) {
      this.hasBeenDeleted = true;
      const lesson_list = this.state.lesson_list.asMutable();
      lesson_list.shift();
      this.uploadFileObj();
      this.setState({
        lesson_list: Immutable(lesson_list),
        disable: false,
        error: false,
      });
    }
  };
  // 默认打开第一个还课视频
  openFirstReword = async (id: string, index: number) => {
    if (this.state.activeRewordIndex === id) return;
    this.rewordId = id;
    const data = await action.getRewordLesson(id);
    if (data) {
      // this.disposeCloudPlayer('player-container-bottom');
      // data.playId && this.getTencentAppId(data.playId, 'player-container-bottom');
      this.setState({
        rlesson: data,
        activeRewordIndex: id,
      });
    }
  };
  /**
   * 还课详情
   * @param id 还课视频id
   * @param index 索引
   */
  handleRewordClick = async (id: string, index: number) => {
    if (this.state.activeRewordIndex === id) return;
    this.rewordId = id;
    const data = await action.getRewordLesson(id);
    if (data) {
      // this.disposeCloudPlayer('player-container-bottom');
      // data.playId && this.getTencentAppId(data.playId, 'player-container-bottom');
      this.setState({
        rlesson: data,
        activeRewordIndex: id,
      });
      // 观看还课视频埋点
      const { lessonId, courseId } = this.searchParams;
      TrackRequest({
        name: 'watch_return_period',
        data: { course_id: courseId, period_id: lessonId, return_period_id: id },
      });
    }
  };
  /**
   * 暂停上/下播放器
   */
  pausePlayer() {
    switch (this.videoStatus) {
      // case 'topTx':
      //   topPlayer.pause();
      //   break;
      case 'topH5':
        this.xgPlayer?.pause();
        this.media?.pause();
        break;
      // case 'bottomTx':
      //   bottomPlayer.pause();
      //   break;
      case 'bottomH5':
        this.bottomMedia?.pause();
        break;
      default:
    }
    this.videoStatus = null;
  }
  /**
   * 显示弹框
   */
  commentLesson() {
    this.setState({ visible: true });
    // 我要标准议课 -- 点击我要议课按钮埋点
    const { lessonId, courseId } = this.searchParams;
    TrackRequest({
      name: 'evaluate_normal',
      data: { course_id: courseId, period_id: lessonId, return_period_id: this.rewordId },
    });
  }
  /**
   * 隐藏modal
   */
  handleCancel = () =>
    this.setState({
      number: 0,
      visible: false,
      okDisable: true,
    });
  /**
   * 翻页
   */
  paginationChange = (page: number) =>
    action.getRewordList(this.rewordId, { page: page - 1, size: 10 });
  /**
   * 改变分数
   * @param num 分数
   */
  handleChange(num: number) {
    const isDisable = num > 0 ? false : !this.props.form.getFieldValue('content');
    this.setState({
      number: num * 2,
      okDisable: isDisable,
    });
  }
  /**
   * 改变评论
   * @param value 评论内容
   */
  handleText(value: string) {
    const isDisable = value ? false : !this.state.number;
    this.setState({ okDisable: isDisable });
  }
  /**
   * 创建评论
   */
  handleSumbit() {
    this.props.form.validateFields((err: any, values: NormalObj) => {
      if (err) return;
      action.createReword(
        this.rewordId,
        {
          content: values.content.trim(),
          score: this.state.number === 0 ? null : this.state.number,
        },
        (data: NormalObj) => {
          this.handleCancel();
          //  发表标准议课 埋点
          if (data.rewordLessonCommentId) {
            const { lessonId, courseId } = this.searchParams;
            TrackRequest({
              name: 'eval_normal_pub',
              data: {
                course_id: courseId,
                period_id: lessonId,
                return_period_id: this.rewordId,
                eval_normal_id: data.rewordLessonCommentId,
              },
            });
          }
        },
      );
    });
  }
  /**
   * 切换课时
   * @param value 课时Id
   */
  changeLesson = (value: any, data: any) => {
    const { key } = data;
    const arr = key.split('|');
    this.searchParams.lessonId = arr[0];
    this.searchParams.lessonType = arr[1];
    //  同步 观看课时 切换 埋点
    const { courseId } = this.searchParams;
    TrackRequest({
      name: 'watch_period',
      data: {
        course_id: courseId,
        period_id: arr[0],
        period_type: arr[1],
        watch_period_entrance: 2,
      },
    });
    if (this.searchParams.resId) {
      delete this.searchParams.resId;
    }
    setTimeout(() => {
      window.location.replace(`/vod${stringifySearchParams(this.searchParams)}`);
    }, 500);
  };
  /**
   * 设置顶部资源中心播放器组件
   * @param media 播放器实例  --- 2022/2/16 新增需求：MP3格式的附件也需要统计观看时长
   */
  handleMedia(media: any) {
    this.media = media;
    this.media.on('play', () => {
      this.videoStatus !== 'topH5' && this.pausePlayer();
      if (this.classRcordVideoId === this.ids.fileId || this.currentFileInfos.extension === 'mp3') {
        this.PlayTimeFn.startPlayTime({ ...this.ids });
      }
      this.videoStatus = 'topH5';
    });
    this.media.on('ended', () => {
      if (this.media.isFullscreen_) {
        this.media.exitFullscreen();
      }
    });
    this.media.on('pause', () => {
      if (this.classRcordVideoId === this.ids.fileId || this.currentFileInfos.extension === 'mp3') {
        this.PlayTimeFn.stopPlayTime();
      }
    });
    this.media.on('timeupdate', () => {
      if (this.classRcordVideoId === this.ids.fileId || this.currentFileInfos.extension === 'mp3') {
        this.PlayTimeFn.getStartAndEnd(
          this.media.currentTime(),
          this.media.duration(),
          this.isTurnTo,
        );
        this.isTurnTo = false;
      }
    });

    // 播放速率更改
    this.media.on('ratechange', () => {
      const { lessonId, courseId, type } = this.searchParams;
      if (this.classRcordVideoId === this.ids.fileId || this.currentFileInfos.extension === 'mp3') {
        this.PlayTimeFn.changePlayRate(media.playbackRate());
        // this.PlayTimeFn.speed = this.media.playbackRate();
      }
      if (type === '2') {
        TrackRequest({
          name: 'playback_speed_contr',
          data: { course_id: courseId, period_id: lessonId },
        });
      }
    });
    // 播放报错
    this.media.on('error', () => {
      // console.log('播放报错了', this.media.error_);
      const { error_ } = this.media;
      if (error_ && error_.code === 3) {
        Modal.info({
          title: '观看出错啦，请重新打开浏览器再试！',
          okText: '知道了',
          onOk() {},
        });
      }
    });
  }
  /**
   * 设置底部资源中心播放器组件
   * @param media 播放器实例
   */
  handleBottomMedia(media: any) {
    this.bottomMedia = media;
    this.bottomMedia.on('play', () => {
      this.videoStatus !== 'bottomH5' && this.pausePlayer();
      this.videoStatus = 'bottomH5';
    });
    this.bottomMedia.on('pause', () => {});
  }
  /**
   * 计算议课评分 的宽度
   * @param n 分数
   */
  calcWidth(n: number) {
    let num = 0;
    if (!n) return num;
    if (n % 2 === 0) {
      num = (Math.floor(n / 2) - 1) * 5 + (75 * n) / 10;
    } else {
      num = Math.floor(n / 2) * 5 + (75 * n) / 10;
    }
    return num;
  }
  // 启动读备份空间
  startReadSpace = () => {
    // 启动读备空间埋点
    const { id, extension, category, resourceGuid } = this.mainVideoInfo;
    const { lessonId, courseId, type } = this.searchParams;
    TrackRequest({ name: 'start_prep_period', data: { course_id: courseId, period_id: lessonId } });
    // 打开读备空间
    const { lessonInfo, splitScreenUrl } = this.state;
    const findMaterial = lessonInfo._material_.findIndex((material: any) => {
      const types = material.extension.toLowerCase();
      return types === 'ppt' || types === 'pptx';
    }); // 判断资料课时中是否有ppt文件
    if (extension === 'mp4' && findMaterial !== -1 && category === 1) {
      this.pausePlayer();
      const { origin } = window.location;
      const search: any = {
        id,
        type,
        lessonId,
        courseId,
        userStage: getStudySectionId(),
        access_token: getToken(),
        unionId: getLeagueId(),
        userId: getUserId(),
        videoType: category,
        splitScreenUrl,
        resourceGuid,
      };

      const link = `pls.495.elf:${origin}/split-screen-video${stringifySearchParams(search)}`;
      window.location.href = link;
      // window.open(`/split-screen-video${stringifySearchParams(search)}`);
    } else {
      message.error('当前课时不支持在线播放，暂不支持启动！');
    }
  };

  /**
   * 下载闻道播放器
   */
  downloadPlayer = async () => {
    const res = await action.getNewestPlayerInfo();
    if (res.Success) {
      const { FileName, Path } = res.Data;
      // 截取最后一个斜杠后面的部分，即文件名及其扩展名
      const fileNameWithExtension = Path.substring(Path.lastIndexOf('/') + 1);

      // 如果需要，你可以进一步切分文件名和扩展名
      const md5 = fileNameWithExtension.split('.')[0]; // 文件名
      
      downloadCosFile(FileName, md5, Path);
      // const xhr = new XMLHttpRequest();
      // xhr.open('GET', Path, true);
      // xhr.responseType = 'blob';
      // xhr.onload = function () {
      //   if (xhr.status === 200) {
      //     const url = window.URL.createObjectURL(xhr.response);
      //     const link = document.createElement('a');
      //     link.style.display = 'none';
      //     link.href = url;
      //     link.download = FileName;
      //     link.click();
      //     window.URL.revokeObjectURL(link.href);
      //   }
      // };
      // xhr.send();
    }
  };

  toggleTitle = () => {
    this.setState({ openTitle: !this.state.openTitle });
  };

  // 读备空间下载埋点
  downloadPoint() {
    TrackRequest({ name: 'downl_prep_period', data: {} });
  }

  // 实录标记控制
  controlMark(status?: boolean) {
    const newStatus = status === undefined ? !this.state.showMarkList : status;
    this.setState({ showMarkList: newStatus }, () => {
      const { showMarkList } = this.state;
      const { lessonId, courseId, type } = this.searchParams;
      if (showMarkList && type === '2') {
        TrackRequest({
          name: 'expand_record_marks',
          data: {
            course_id: courseId,
            period_id: lessonId,
          },
        });
      } else if (type === '2') {
        TrackRequest({
          name: 'retract_record_marks',
          data: {
            course_id: courseId,
            period_id: lessonId,
          },
        });
      }
    });
  }

  // onVideoTrack = (data: any) => {
  //   if (data.trackType === 'PLAY') {
  //     this.PlayTimeFn.startPlayTime({ ...this.ids });
  //   } else if (data.trackType === 'PAUSE') {
  //     this.PlayTimeFn.stopPlayTime();
  //   }
  // };

  onSiderTrack = (data: any) => {
    switch (data.trackType) {
      case 'NODE':
        {
          const { lessonId, courseId } = this.searchParams;
          if (this.xgPlayer?.currentTime < this.xgPlayer?.duration) {
            this.isTurnTo = true;
          }
          TrackRequest({
            name: 'use_record_marks',
            data: { course_id: courseId, period_id: lessonId },
          });
        }
        break;
      case 'SIDEROPEN':
        this.controlMark(true);
        break;
      case 'SIDERCLOSE':
        this.controlMark(false);
        break;
      case 'PROGRESS_CLICK':
      case 'DRAG_END':
      case 'MARKER_CLICK':
        if (this.xgPlayer?.currentTime < this.xgPlayer?.duration) {
          this.isTurnTo = true;
        }
        break;
      default:
        break;
    }
  };

  playDom = () => {
    const { preViewProps, activeLessonIndex, preview_lesson } = this.state;
    const { markups } = preview_lesson;

    // 点播课时且是mp4
    return this.currentFileInfos.category === 1 && preViewProps.type === 'mp4' ? (
      <VideoMarkerPlayer
        defaultSilderOpen
        theme="#2683f5"
        videoUrl={preViewProps.url}
        markerData={markups}
        onSiderTrack={(data) => this.onSiderTrack(data)}
        onVideoTrack={(data) => this.onSiderTrack(data)}
        playerOptions={
          {
            events: {
              play: (player: any) => {
                this.xgPlayer = player;
                this.PlayTimeFn.startPlayTime({ ...this.ids });
                this.videoStatus !== 'topH5' && this.pausePlayer();
                this.videoStatus = 'topH5';
              },
              pause: (player: any) => {
                this.PlayTimeFn.stopPlayTime();
              },
              ended: (player: any) => {
                player.exitFullscreen();
              },
              timeupdate: (player: any) => {
                this.PlayTimeFn.getStartAndEnd(player.currentTime, player.duration, this.isTurnTo);
                this.isTurnTo = false;
              },
            },
            xgplayerOptions: {
              autoplayMuted: false,
              autoplay: true,
            },
          } as any
        }
      />
    ) : (
      <ResourceViewCom
        key={activeLessonIndex}
        mediaType={preViewProps.type}
        mediaAddress={preViewProps.url}
        playerCallBack={(media: any) => this.handleMedia(media)}
      />
    );
  };

  onExpand = () => {
    this.setState({
      showResourceList: !this.state.showResourceList,
    });
  };

  render() {
    const {
      error,
      number,
      disable,
      visible,
      noSupport,
      okDisable,
      lesson_list,
      vedioHeight,
      showPreview,
      preViewProps,
      isShowFileInput,
      activeRewordIndex,
      activeLessonIndex,
      lessonInfo,
      rlesson,
      switchBottomModel,
      isShowDowload,
      splitScreenUrl,
      openTitle,
      showResourceList,
    } = this.state;
    const { form, lessonList, scoreDetail, commentResult, functionConfig } = this.props;
    // const { marks } = preview_lesson;
    const { getFieldDecorator } = form;
    const { _video_, _material_ } = lessonInfo;
    let gradesName = '';
    let subjectsName = '';
    if (lessonInfo.grades && lessonInfo.grades.length > 0) {
      gradesName = lessonInfo.grades.map((item: any) => item.gradeName).join('，');
    }
    if (lessonInfo.subjects && lessonInfo.subjects.length > 0) {
      subjectsName = lessonInfo.subjects.map((item: any) => item.subjectName).join('，');
    }

    return (
      <div className="vod-page" onContextMenu={(e: any) => e.preventDefault()}>
        <div className="vod-page-header">
          {this.searchParams.columnId !== '000' && (
            <>
              <span className="page-header-title">
                {this.searchParams.courseName} {'>'}
              </span>{' '}
              {this.searchParams.type === '2' && this.searchParams.lessonType !== '1' ? (
                <Select
                  style={{ minWidth: 10 }}
                  value={this.searchParams.lessonId}
                  onSelect={this.changeLesson}
                  suffixIcon={<MyIcon type="iconTriangle_white1" />}
                >
                  {lessonList &&
                    lessonList.length > 0 &&
                    lessonList.map((item: NormalObj, idx: number) =>
                      item.lessonType !== 1 ? (
                        <Option key={`${item.id}|${item.lessonType}`} value={item.id}>
                          {item.lessonName}
                        </Option>
                      ) : (
                        ''
                      ),
                    )}
                </Select>
              ) : (
                lessonInfo.lessonName
              )}
            </>
          )}
        </div>
        <Row className="flex">
          {/* {this.currentFileInfos.category === 1 && marks && marks.length > 0 && (
            <Col className={`left ${showMarkList ? 'show' : ''}`} style={{ height: vedioHeight }}>
              {this.currentFileInfos.category === 1 && marks && marks.length > 0 && (
                <div
                  className={`wrapper-mark ${showMarkList ? 'show' : ''}`}
                  onClick={() => this.controlMark()}
                  title={showMarkList ? '收起实录标记' : '展开实录标记'}
                >
                  {showMarkList ? <Icon type="double-left" /> : <Icon type="double-right" />}
                </div>
              )}
              {this.currentFileInfos.category === 1 && marks && marks.length > 0 && (
                <div className={`mark-list ${showMarkList ? 'show' : ''}`}>
                  <div className="mark-list-header">
                    <span>实录标记</span>
                    <span className="btn" onClick={() => this.controlMark(false)}>
                      <MyIcon type="iconic_del" />
                    </span>
                  </div>
                  <div className="mark-list-content">
                    <Timeline>
                      {marks.map((item: NormalObj, index: number) => (
                        <Item key={index} className={activeTimeIndex === index ? 'active' : ''}>
                          <div onClick={() => this.turnTo(item)} className="mark-list-item">
                            <span className="time">{formateMinutes(item.timeTick)}</span>
                            <span className="intro">{item.description}</span>
                          </div>
                        </Item>
                      ))}
                    </Timeline>
                  </div>
                </div>
              )}
            </Col>
          )} */}
          <Col
            style={{ height: isShowDowload ? vedioHeight : vedioHeight + 48 }}
            className="vod-page-content-left middle"
          >
            <div ref={(topRef) => (this.topRef = topRef)} className="wrapper-player" />
            {isShowDowload ? (
              <div className="wrapper-download">
                {functionConfig.showDownloadWenDaoPlayer && (
                  <div>
                    下载
                    <span className="name" onClick={this.downloadPlayer}>
                      闻道课件播放器
                    </span>
                    {/* <a rel="noopener noreferrer" href={wxUrl} download>
                      <span className="name">闻道课件播放器</span>
                    </a> */}
                    ，可以本地播放课时内容。
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            {showPreview ? (
              <div className="preview-wrap">
                {noSupport ? (
                  <div className="errorWrap">
                    <MyIcon type="iconpreview" className="errorIcon" />
                    <div className="errorText">暂不支持预览</div>
                  </div>
                ) : (
                  preViewProps.url && !preViewProps.playId && this.playDom()
                  // 上面播放器
                )}
              </div>
            ) : (
              ''
            )}
          </Col>

          <Col
            style={{ height: vedioHeight, width: showResourceList ? 320 : 0 }}
            className="vod-page-content-right right"
          >
            <div className="vod-page-expand-btn" onClick={this.onExpand}>
              {!showResourceList ? <Icon type="double-left" /> : <Icon type="double-right" />}
              资源列表
            </div>
            <div className="vod-page-content-right-info">
              <div className="vod-page-content-right-item">
                <div className="item-title" onClick={this.toggleTitle}>
                  基本信息{' '}
                  <span className="item-title-toggle">
                    {openTitle ? '收起' : '展开'}
                    <Icon type={openTitle ? 'up' : 'down'} />
                  </span>
                </div>
                <div className="text" style={{ display: openTitle ? 'block' : 'none' }}>
                  <div className="item-text">
                    <span>授课老师：</span>
                    <span>
                      {lessonInfo.teachers &&
                        lessonInfo.teachers.map((item: NormalObj) => item.teacherName).join(' / ')}
                    </span>
                  </div>
                  <div className="item-text">
                    <span>年级：</span>
                    <span>
                      {lessonInfo.grades &&
                        lessonInfo.grades.map((item: NormalObj) => item.gradeName).join(' / ')}
                    </span>
                  </div>
                  <div className="item-text">
                    <span>学科：</span>
                    <span>
                      {lessonInfo.subjects &&
                        lessonInfo.subjects.map((item: NormalObj) => item.subjectName).join(' / ')}
                    </span>
                  </div>
                  {lessonInfo.materialChapters && lessonInfo.materialChapters?.length > 0 && (
                    <div className="item-text">
                      <span>教材章节：</span>
                      <span
                        // className="material-chapters"
                        title={
                          lessonInfo.materialChapters &&
                          lessonInfo.materialChapters
                            .map((item: NormalObj) => item.materialName + item.chapterName)
                            .join(' / ')
                        }
                      >
                        {lessonInfo.materialChapters &&
                          lessonInfo.materialChapters
                            .map((item: NormalObj) => item.materialName + item.chapterName)
                            .join(' / ')}
                      </span>
                    </div>
                  )}
                  {hideSubjectTag(lessonInfo.grades || [], lessonInfo.labels) &&
                    hideSubjectTag(lessonInfo.grades || [], lessonInfo.labels).length > 0 && (
                      <div className="item-text">
                        <span>标签：</span>
                        <span className="tag">
                          {hideSubjectTag(lessonInfo.grades || [], lessonInfo.labels)
                            .map((label: NormalObj) => label.labelName)
                            .join(' / ')}
                        </span>
                      </div>
                    )}
                  {typeof lessonInfo.artScience === 'number' && (
                    <div className="item-text">
                      <span>文理科：</span>
                      <span>{ArtScience[lessonInfo.artScience]}</span>
                    </div>
                  )}
                  {typeof lessonInfo.expireTime === 'number' && (
                    <Tooltip
                      getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
                      overlayClassName="expire-time-user-class"
                      title="有效截止时间内查看将会纳入数据统计"
                    >
                      <div className="item-text">
                        <span>
                          有效截止时间
                          <Icon type="info-circle" />：
                        </span>
                        <span>{moment(lessonInfo.expireTime).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              {this.searchParams.lessonType === '2' && _video_.length > 0 ? (
                <div className="vod-page-content-right-item lesson-name">
                  <div className="item-title">课堂实录</div>
                  {_video_.map((item: NormalObj, idx: number) => {
                    return (
                      <div key={`${item.fileId}-${idx}`} className="item-text text">
                        <AttachmentItem
                          data={item}
                          key={`${item.fileId}-${idx}`}
                          index={idx}
                          active={activeLessonIndex}
                          is_create={this.searchParams.type}
                          onClick={this.previewClick.bind(this, item, idx, '2')}
                          isVideo
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
              {_material_.length > 0 && (
                <div className="vod-page-content-right-item courseware">
                  <div className="item-title">课时资料</div>
                  {_material_.map((item: NormalObj, index: number) => {
                    return (
                      <AttachmentItem
                        data={item}
                        key={`${item.fileId}-${index}`}
                        index={index}
                        active={activeLessonIndex}
                        is_create={this.searchParams.type}
                        onClick={this.previewClick.bind(this, item, index, '3')}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            {switchBottomModel && showResourceList
              ? functionConfig.showFourSplitScreen && (
                  <div className="vod-page-content-right-startWorkSpace">
                    <Button className="orange" onClick={this.startReadSpace}>
                      启动我的读备空间
                    </Button>
                    <a download href={splitScreenUrl} onClick={() => this.downloadPoint()}>
                      下载读备空间
                    </a>
                  </div>
                )
              : ''}
          </Col>
        </Row>
        {switchBottomModel
          ? functionConfig.showRewordLesson && (
              <div className="vod-page-bottom">
                <div className="vod-page-bottom-left">
                  <div className="vod-page-bottom-header">还课展播台</div>
                  <div className="vod-page-bottom-left-content">
                    {lesson_list &&
                      lesson_list.map((item: NormalObj, idx: number) => {
                        return item.type ? (
                          <div className="vod-lesson-component" key={item.type}>
                            <div className="logo" />
                            <div className="info">
                              <div className="title">{item.name}</div>
                              <div className="basic">
                                <span className="cancel-upload" onClick={this.cancelUpload}>
                                  <MyIcon type="iconic_del" />
                                </span>
                                <Progress
                                  strokeWidth={2}
                                  percent={item.percent}
                                  status={error ? 'exception' : 'active'}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            key={item.rewordLessonId}
                            className={`vod-lesson-component ${
                              activeRewordIndex === item.rewordLessonId ? 'active' : ''
                            }`}
                            onClick={() => this.handleRewordClick(item.rewordLessonId, idx)}
                          >
                            <div className="logo" />
                            <div className="info">
                              <div className="title">{item.name}</div>
                              <div className="basic">
                                <span className="teacher" title={item.createUserName}>
                                  上传者：{item.createUserName}
                                </span>
                                <span className="grade" title={gradesName}>
                                  年级：{gradesName}
                                </span>
                                <span className="subjects" title={subjectsName}>
                                  学科：{subjectsName}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {(!lesson_list || lesson_list.length === 0) && (
                      <div className="left-empty">
                        <MyIcon className="iconfont" type="iconcontent_null" />
                        暂无内容
                      </div>
                    )}
                  </div>
                  <div className="wrapper-upload">
                    {isShowFileInput ? (
                      <input
                        ref={(input) => (this.fileInput = input)}
                        type="file"
                        accept=".mp4"
                        disabled={disable}
                        onChange={this.uploadFile}
                        style={{ display: 'none' }}
                        className="really-input"
                      />
                    ) : (
                      ''
                    )}
                    <Button
                      type="primary"
                      disabled={disable}
                      className="upload-btn"
                      onClick={this.clickUpload}
                    >
                      上传我的还课
                    </Button>
                  </div>
                </div>
                {lesson_list && lesson_list.length > 0 ? (
                  <div className="vod-page-bottom-right">
                    {activeRewordIndex ? (
                      <div>
                        <div className="bottom-right-tp">
                          <div ref={(dom: any) => (this.bottomRef = dom)} className="play-content">
                            {/* <video
                              id="player-container-bottom"
                              width="100%"
                              height="100%"
                              preload="auto"
                              playsInline
                              x5-playinline="test"
                              webkit-playinline="test"
                            /> */}
                          </div>
                          {
                            // 下面播放器
                            rlesson.url && !rlesson.playId ? (
                              <div className="wrapper-bottom-media">
                                <ResourceViewCom
                                  key={activeRewordIndex}
                                  mediaType="mp4"
                                  mediaAddress={rlesson.url}
                                  playerCallBack={(media: any) => this.handleBottomMedia(media)}
                                  wrapperClassName="bottom-player"
                                  autoPlay={false}
                                />
                              </div>
                            ) : (
                              ''
                            )
                          }
                          <div className="comment-content">
                            <div className="rate">
                              <div className="comment-rate">
                                <div className="source-star">
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                </div>
                                <div
                                  className="source-star active"
                                  style={{ width: this.calcWidth(scoreDetail.score) }}
                                >
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                  <MyIcon type="iconstar" className="start" />
                                </div>
                                <span className="score">
                                  {!scoreDetail.score ? (
                                    '暂无评分'
                                  ) : (
                                    <div className="content">
                                      <span className="value">
                                        <i>{scoreDetail.score}</i>分
                                      </span>
                                      <span className="count">{scoreDetail.count}人评价</span>
                                    </div>
                                  )}
                                </span>
                              </div>
                              <div
                                className="comment-lesson-button"
                                onClick={() => this.commentLesson()}
                              >
                                我要议课
                              </div>
                            </div>
                            <div className="rlesson-info">
                              <div className="base">
                                <span>
                                  <i>所属组织：</i>
                                  {rlesson.orgName || ''}
                                </span>
                                <span>
                                  <i>上传时间：</i>
                                  {rlesson.createTime &&
                                    moment(rlesson.createTime).format('YYYY/MM/DD HH:mm:ss')}
                                </span>
                              </div>
                              {lessonInfo.materialChapters &&
                                lessonInfo.materialChapters?.length > 0 && (
                                  <div className="material">
                                    <i>教材章节：</i>
                                    {lessonInfo.materialChapters &&
                                      lessonInfo.materialChapters
                                        .map(
                                          (item: NormalObj) => item.materialName + item.chapterName,
                                        )
                                        .join(' / ')}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="watch-comment">
                          <div className="vod-page-bottom-header">观课评价</div>
                          <div
                            className={`watch-list ${
                              commentResult.list.length === 0 ? 'no-data' : ''
                            }`}
                          >
                            {commentResult.page.totalElements > 0 ? (
                              commentResult.list.map((item: NormalObj, idx: number) => (
                                <div className="watch-list-item" key={idx}>
                                  <div className="top">
                                    <span className="name">{item.createUserName}</span>
                                    <span className="time">
                                      {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                                    </span>
                                  </div>
                                  <div className="bottom">{item.content}</div>
                                </div>
                              ))
                            ) : (
                              <EmptyList state="noLesson" />
                            )}
                          </div>
                          {commentResult.page.totalElements > 0 ? (
                            <div className="footer">
                              <Pagination
                                size="small"
                                total={commentResult.page.totalElements}
                                current={commentResult.page.number + 1}
                                pageSize={commentResult.page.size}
                                onChange={this.paginationChange}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="no-detail">
                        <EmptyList state="noReword" />
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            )
          : ''}
        <Footer />
        {visible && (
          <Modal
            title="观课评价"
            width={800}
            visible
            wrapClassName="wrap-comment"
            onCancel={() => this.handleCancel()}
            footer={[
              <Button key="back" onClick={() => this.handleCancel()}>
                取消
              </Button>,
              <Button
                key="submit"
                disabled={okDisable}
                type="primary"
                onClick={() => this.handleSumbit()}
              >
                确定
              </Button>,
            ]}
          >
            <Form>
              <Form.Item {...formLayout} label="评分">
                <Rate
                  allowHalf
                  value={number / 2 || 0}
                  onChange={(value) => this.handleChange(value)}
                />
                <span className={`ant-rate-text ${number ? 'active' : ''}`}>{number || 0}分</span>
              </Form.Item>
              <Form.Item {...formLayout} label="评价">
                {getFieldDecorator('content', {
                  initialValue: '',
                })(
                  <AJTextArea
                    onChange={(value: string) => this.handleText(value)}
                    placeholder="说说您的想法"
                    maxLength="200"
                  />,
                )}
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}
// 转换数据格式
const transfromImmutableData = (data: any) => {
  if (Immutable.isImmutable(data)) {
    return data.asMutable();
  } else {
    return data;
  }
};
const formLayout: any = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
const mapStateTopProps = (state: any) => ({
  ...state.Vod,
  ...state.Course,
  lessonList: state.CourseDetail.list,
  functionConfig: { ...state.DynamicControl.functionConfig },
});
export default Form.create<P>()(connect(mapStateTopProps, mergeProps)(Vod));
