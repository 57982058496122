/**
 *  @desc    媒体资源播放（文件类:doc,excle等文件类资源）
 *  @author  huangyu
 *  @params
 */

import React, { Component } from 'react';
import './file.less';

export default class FileComponent extends Component {
  static defaultProps = {
    pause: true,
    playerCallBack: () => {},
  };
  constructor() {
    super();
    this.state = {
      address: '',
    };
    this.iframe = '';
  }
  componentDidMount() {
    if (this.iframe) {
      this.iframe.contentWindow.location.replace(this.props.mediaAddress);
    }
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (this.iframe) {
      this.iframe.contentWindow.location.replace(nextprops.mediaAddress);
    }
  }
  render() {
    return (
      <div className="file-comtent">
        <iframe
          className="file-resource"
          ref={(iframe) => {
            this.iframe = iframe;
          }}
          title="This is a unique title"
        />
      </div>
    );
  }
}
