import { message } from 'antd';
import AxiosWrap from '../../lib/request';
import * as types from '../types/course.detail.type';
import Alert from '../../components/Alert/Alert';
import isNotRootLink from '../../util/isNotRootLink';
/**
 * 获取课程详情 预览
 * @param params any
 */
export const getCourseDetail = async (params: any, type: string) => {
  let url = '';
  if (type === '0') {
    // 我的创建
    url = `/course/${params}`;
  } else if (type === '2') {
    // 我的同步
    url = `/course/my-fallow/${params}`;
  }
  try {
    const { status, data } = await AxiosWrap({ url });
    if (status === 200) {
      (window as any)._dispatch({ type: types.GET_COURSE_REQUEST, payload: data });
    }
  } catch (error) {
    if (error && (error as any).status === 404) {
      Alert.open('课程已不存在！', undefined, 'black', 'course');
      (window as any)._dispatch({ type: types.GET_COURSE_REQUEST, payload: { lessonNum: [] } });
      return;
    }
    if (error && (error as any).status === 403) {
      Alert.open('暂无权限！', () => isNotRootLink());
      return;
    }
    error && (error as any).data && message.error((error as any).data.message);
  }
};
/**
 * 获取课程下课时列表
 */
export function getList(id: number, type: string, actionType?: string) {
  let url = '';
  if (type === '0') {
    // 我的创建
    url = `/course/${id}/lesson`;
  } else if (type === '2') {
    // 我的同步
    url = `/course/${id}/released-lesson`;
  }
  AxiosWrap({
    url,
  })
    .then((res) => (window as any)._dispatch({ type: types.GET_LESSON_LIST, payload: res.data }))
    .catch((err: any) => {
      (window as any)._dispatch({ type: types.GET_LESSON_LIST, payload: [] });
      if (err && err.status === 404 && actionType === 'lessonList') {
        Alert.open('课程不存在！', undefined, 'black', actionType);
      }
    });
}
