/* eslint-disable guard-for-in */
/* eslint-disable react/no-string-refs */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author: gouyang
 * @Description: 创建文章资料上传
 * @Date: 2019-07-18 16:13:16
 */
import React from 'react';
import { Button, Input, message } from 'antd';
import fileServiceUpload from '../../../util/fileServiceUpload';
import { uploadFileTypes } from './../../../util/filetypes';
import mapFileType from './fileType';

import './upload.less';

interface FileList {
  id: number | string; // 文件id
  name: string; // 文件名
  status: string; // 文件上传状态
  message: string; // 上传信息
  path: string; // 文件路径
  data?: any; // 文件服务返回的一整条数据
}
interface P {
  accept?: string; // 上传文件类型 默认全部可以上传
  acceptTips?: boolean; // accept提示
  updateData: (data: any[], status: string) => void; // 回调 拿数据
  maxLen?: number; // 上传个数
  data?: any; // 回显数据
  name?: string; // 上传按钮名字
  isDelUpload: any;
}
interface S {
  file: any;
  accept: string; // 上传文件类型
  inputFlg: boolean;
  buttonName: string; // 上传按钮名字
  acceptTips: boolean;
}

class ServiceUpload extends React.Component<P, S> {
  private fileItem: any[];
  private cancelUpload: any;
  private currentUploadIndex: number;
  constructor(props: P) {
    super(props);
    this.state = {
      file: null,
      accept: '',
      inputFlg: true,
      buttonName: '上传资料',
      acceptTips: false,
    };
    this.fileItem = [];
    this.cancelUpload = null;
    this.currentUploadIndex = -1;
  }
  componentDidMount() {
    const { accept, name, acceptTips } = this.props;
    //  正常的 封装操作
    const data: any = {};
    // 文件类型
    if (accept) {
      data.accept = accept;
    }
    if (name) {
      data.buttonName = name;
    }
    if (typeof acceptTips === 'boolean') {
      data.acceptTips = acceptTips;
    }
    this.setState({
      ...data,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { accept, isDelUpload } = this.props;
    if (accept !== nextProps.accept) {
      this.setState({
        accept: nextProps.accept,
      });
    }

    if (isDelUpload !== nextProps.isDelUpload) {
      this.fileItem = this.fileItem.filter((file: any) => file.id !== nextProps.isDelUpload.id);
    }
  }

  warpFileChange = (e: any) => {
    const files = Array.from(e.target.files).reverse();
    files.forEach((file: any) => {
      this.fileChange(file);
    });
    this.wrapFileServiceUpload();
  };

  // 文件上传变化函数
  fileChange = (file: any) => {
    let lowerName = '';
    // 上传格式验证
    if (file && file.name) {
      const fileName = file.name.split('.');
      const l = fileName.length;
      lowerName = fileName[l - 1].toLowerCase();
      const flag = uploadFileTypes.indexOf(lowerName) === -1;
      if (flag) {
        message.error('不支持该类型文件上传！');
        return;
      }
    }

    if (file.size <= 0) {
      message.error('文件大小不能小于0！');
      return;
    }
    if (file.size > 1073741824 * 2) {
      message.error('文件大小不能超过2G！');
      return;
    }

    if (file.name.length > 1000) {
      message.error('文件名过长！');
      return;
    }
    if (file) {
      const fileItem = {
        id: String(parseInt(`${Math.random() * 1000000000000}`, 10)),
        icon: mapFileType(lowerName),
        name: file.name,
        status: 'upload',
        data: '',
        path: '',
        percent: 0,
        message: '',
        cancelFn: false,
        file,
      };
      this.fileItem.push(fileItem);
      this.props.updateData([fileItem], 'plus');
    }
  };

  wrapFileServiceUpload() {
    const i = this.fileItem.findIndex((file: any) => file.status === 'upload');
    if (i !== -1 && this.currentUploadIndex !== i) {
      this.currentUploadIndex = i;
      const { file } = this.fileItem[i];
      this.cancelUpload = fileServiceUpload(file, (data: any) => this.fileUpload(data, i));
    }
  }

  // 文件上传方法
  fileUpload = (data: any, index: number) => {
    const tempFileItem = this.fileItem[index];
    const { updateData } = this.props;
    if (data.status === 'start') {
      // 开始上传
    } else if (data.status === 'progress') {
      // 上传中
      tempFileItem.cancelFn = this.cancelUpload;
      tempFileItem.percent = Math.round(data.percent * 100);
    } else if (data.status === 'done') {
      // 上传完成
      tempFileItem.path = data.path;
      tempFileItem.data = data.data;
      tempFileItem.status = 'done';
      tempFileItem.cancelFn = false;
      this.wrapFileServiceUpload();
      message.success(data.message);
    } else if (data.status === 'error') {
      // 上传失败
      tempFileItem.status = 'error';
      tempFileItem.cancelFn = false;
      this.wrapFileServiceUpload();
      message.error(data.message);
    } else if (data.status === 'cance') {
      // 取消上传
      tempFileItem.status = 'cancel';
      tempFileItem.cancelFn = false;
      this.wrapFileServiceUpload();
      message.error(data.message);
    }
    this.fileItem[index] = tempFileItem;
    updateData([tempFileItem], 'change');
  };
  // 打开 文件上传页
  clickUpload = () => {
    this.setState(
      {
        inputFlg: false,
      },
      () => {
        this.setState(
          {
            inputFlg: true,
          },
          () => {
            const { inputText } = this.refs as any;
            inputText.input.click();
          },
        );
      },
    );
  };

  render() {
    const { accept, acceptTips, inputFlg, buttonName } = this.state;
    return (
      <div className="upload">
        {inputFlg ? (
          <Input
            multiple
            type="file"
            name="file"
            ref="inputText"
            accept={accept}
            onChange={this.warpFileChange}
            id="uploadInput"
            style={{ display: 'none' }}
          />
        ) : (
          ''
        )}
        <Button icon="upload" className="default" onClick={this.clickUpload}>
          {buttonName}
        </Button>
        {accept && acceptTips ? (
          <span
            className="uploadDescribe"
            title={`支持上传文件类型为：${acceptConversion(accept)}`}
          >
            支持上传文件类型为：{acceptConversion(accept)}
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
}
const acceptConversion = (str: string) => {
  const arr = str.split(',');
  const data = [];
  for (const i in arr) {
    data.push(arr[i].replace('.', ''));
  }
  return data.join('，');
};
export default ServiceUpload;
