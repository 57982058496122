/* eslint-disable @typescript-eslint/member-ordering */
/*
 * @Author: gouyang
 * @Description:子栏目列表
 * @Date: 2019-10-14 13:31:31
 */
import React, { Component } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { getSearchParams } from '../../util';
import fromColumnToPage from '../../util/fromColumnToPage';
import { updateButtonStatus } from '../../redux/action/menu.action';
import componentSafeUnmount from '../../util/componentSafeUnmount';
import TrackRequest from '../../lib/trackRequest';
import './index.less';

const { SubMenu, Item } = Menu;

// 获取子列表
function getChildren(parentId: string, data: any[]): any[] {
  const index = data.findIndex((item: any) => item.columnId === parentId);
  const children = index !== -1 ? data[index].children : [];
  return children;
}
// 根据父元素获取需要展开的栏目
function getOpenKey(
  parentId: string,
  targetId: string,
  data: any[],
): { currentOpenKey: string; rootOpenkeys: string[]; parentName: string } {
  const parentIndex = data.findIndex((item: any) => item.columnId === parentId);
  let currentOpenKey = parentId;
  const rootOpenkeys: string[] = [];
  function getIdFn(ele: any) {
    const { children } = ele;
    if (children.length > 0) {
      rootOpenkeys.push(ele.columnId);
    }
    for (let i = 0, l = children.length; i < l; i++) {
      if (children[i].columnId === targetId) {
        currentOpenKey = ele.columnId;
      } else {
        getIdFn(children[i]);
      }
    }
  }
  if (parentIndex !== -1) {
    getIdFn(data[parentIndex]);
  }
  const name = data[parentIndex] ? data[parentIndex].name : '';
  return { currentOpenKey, rootOpenkeys, parentName: name };
}

// 渲染导航列表
function renderNavMenu(data: any[]) {
  return (
    data.length > 0 &&
    data.map((d: any) => {
      return d.children && d.children.length > 0 ? (
        <SubMenu title={d.name} key={d.columnId} className={JSON.stringify(d)}>
          {renderNavMenu(d.children)}
        </SubMenu>
      ) : (
        <Item key={d.columnId} className={JSON.stringify(d)}>
          {d.name}
        </Item>
      );
    })
  );
}

interface P {
  menus: any[];
  buttonConfig: any;
  updateCurrentColumnId: any;
  addressBarColumnId: string;
  isOperation: boolean;
  currentRootType: string;
  updateCurrentRootType: (type: string) => {};
}
interface S {
  currentOpenKey: string[];
  parentName: string;
  menus: any[];
  children: any[];
  chooseId: string[];
  rootOpenkeys: string[];
  containHeight: number;
  headerHeight: number;
  isBtn: boolean;
  addressBarColumnId: string;
}

@componentSafeUnmount
class ColumnList extends Component<P, S> {
  private isfixed: boolean;
  constructor(props: P) {
    super(props);
    this.state = {
      currentOpenKey: [], // 当前展开的栏目key
      parentName: '', // 一级栏目标题
      menus: [], // 列表数据
      children: [], // 子元素列表
      chooseId: [], // 选中的元素id
      rootOpenkeys: [], // 所有有子栏目的栏目
      containHeight: 0, // 容器高度
      headerHeight: 70, // header高度
      isBtn: false, // 是否存在创建按钮
      addressBarColumnId: '', // 当前地址栏中栏目id
    };
    this.isfixed = false; // 固定栏目
  }
  componentDidMount() {
    this.setState({
      containHeight: this.calculationMenuHeight(),
    });
    // 浏览器窗口变化
    window.onresize = () => {
      this.setState({
        containHeight: this.calculationMenuHeight(),
      });
    };
    // 浏览器滚动
    window.onscroll = (e: any) => {
      const { scrollTop } = e.target.scrollingElement;
      const topHeight = this.calculationNodeHeight('MenuComponent'); // 顶部位置
      if (scrollTop >= 64 && !this.isfixed) {
        const pageLeftNav = document.getElementById('pageLeftNav') as any;
        if (pageLeftNav) {
          pageLeftNav.style.top = `${topHeight}px`;
          pageLeftNav.style.position = 'fixed';
        }
        this.isfixed = true;
      } else if (scrollTop < 64 && this.isfixed) {
        const pageLeftNav = document.getElementById('pageLeftNav') as any;
        if (pageLeftNav) {
          pageLeftNav.style.top = 0;
          pageLeftNav.style.position = 'relative';
        }
        this.isfixed = false;
      }
    };
  }

  // 计算栏目高度
  calculationMenuHeight(): number {
    const rootNodeHeight = document.body.clientHeight;
    const footNodeHeight = this.calculationNodeHeight('FooterComponent');
    const height = rootNodeHeight - footNodeHeight - 60;

    return height;
  }
  // 计算dom元素高度
  calculationNodeHeight(domId: string): number {
    const height = document.getElementById(domId)
      ? (document.getElementById(domId) as any).offsetHeight
      : 1;

    return height;
  }
  static getDerivedStateFromProps(props: P, state: any) {
    if (props.menus.length > 0 && props.addressBarColumnId !== state.addressBarColumnId) {
      const { menus, addressBarColumnId } = props;
      const { id, parentId, name, type } = getSearchParams(window.location.search);
      const children = getChildren(parentId, menus);
      const { currentOpenKey, rootOpenkeys, parentName } = getOpenKey(parentId, id, menus);
      return {
        chooseId: type !== '2' && name !== '同考同析' && name !== '资源补发' ? [id] : [],
        children,
        parentName,
        currentOpenKey: [currentOpenKey],
        menus,
        rootOpenkeys,
        addressBarColumnId,
      };
    }
    if (props.buttonConfig.status !== state.isBtn) {
      let headerHeight = 70;
      if (
        props.buttonConfig.status &&
        (getSearchParams(window.location.search).type === '0' ||
          getSearchParams(window.location.search).type === '1')
      ) {
        headerHeight = 130;
      }

      return {
        isBtn: props.buttonConfig.status,
        headerHeight,
      };
    }
    return null;
  }
  // 栏目展开收起回调
  onOpenChange = (openKeys: string[]) => {
    this.setState({
      currentOpenKey: openKeys,
    });
  };
  // 获取点击栏目的id
  getChooseId = (target: any) => {
    if (!this.props.isOperation) return null;
    const data = JSON.parse(target.item.props.className);
    let columns_link = '';
    if (data.type === 2) {
      columns_link = JSON.parse(data.config).url;
    }
    TrackRequest({ name: 'click_columns', data: { columns_id: data.columnId, columns_link } });
    const { parentId, id, type } = getSearchParams(window.location.search);
    const { key } = target;
    if (id !== key || type === '2') {
      updateButtonStatus({ status: false, type: '', name: '' });
      fromColumnToPage(target, parentId);
      this.updatePrivilegeBtnStatus();
      this.props.updateCurrentColumnId(key);
    }
  };
  // 更新当前权限按钮状态
  updatePrivilegeBtnStatus() {
    const ROOTTYPE = window.localStorage.getItem('ROOTTYPE');
    const rootType = this.props.currentRootType;

    if (!!ROOTTYPE && ROOTTYPE !== rootType) {
      this.props.updateCurrentRootType(ROOTTYPE);
    }
  }

  render() {
    const { children, parentName, currentOpenKey, chooseId, containHeight, headerHeight } =
      this.state;

    return (
      children.length > 0 && (
        <div
          id="LeftTreeMenu"
          className="left-tree-menu column-list"
          style={{ height: `${containHeight - headerHeight}px` }}
        >
          <div
            id="pageLeftNav"
            className="left-tree-box"
            style={{ height: `${containHeight - headerHeight}px` }}
          >
            <div className="left-side">
              <div className="left-side-title">{parentName}</div>
              <Menu
                mode="inline"
                onOpenChange={this.onOpenChange}
                onClick={this.getChooseId}
                openKeys={currentOpenKey}
                selectedKeys={chooseId}
              >
                {renderNavMenu(children)}
              </Menu>
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  menus: state.Menu.menus,
  buttonConfig: state.Menu.buttonConfig,
  addressBarColumnId: state.GloabalCreateSync.addressBarColumnId,
  isOperation: state.GloabalCreateSync.isOperation,
  currentRootType: state.GloabalCreateSync.currentRootType,
});
const mapDispatchToProps = (dispatch: any) => ({
  // 更新所选栏目id
  updateCurrentColumnId: (columnId: string) => {
    dispatch({ type: 'UPDATEADDRESSBARID', payload: columnId });
  },
  // 更新权限按钮状态
  updateCurrentRootType: (type: string) =>
    dispatch({ type: 'GLOBALCREATESYNCTYPE', payload: type }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnList);
