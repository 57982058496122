import * as types from '../types/login.type';

interface Action {
  type: string;
  payload?: any;
}
const initialState = (() => ({
  user: {},
  unionList: [],
  livingLessons: [], // 进行中的直播课时，即将开始和正在直播
}))();
const Login = (state = { ...initialState }, { type, payload }: Action) => {
  switch (type) {
    case types.GET_UNION_LIST:
      return {
        ...state,
        unionList: payload,
      };
    case types.GET_USER:
      return {
        ...state,
        user: payload,
      };
    case types.GET_SWITCH_LOGIN_AUTH:
      return {
        ...state,
        allowAccountAndPasswordLogin: payload,
      };
    case types.GET_SUB_USERS:
      return {
        ...state,
        subUsers: payload,
      };
    case types.GET_LIVING_LESSONS:
      return {
        ...state,
        livingLessons: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Login;
