import React from 'react';
import ReactDOM from 'react-dom';
import Loading from './loading';

const defaultLoad = 'default-loading';
// 特殊处理
const idDispose = (id?: string) => {
  return defaultLoad + (id || 'M').charCodeAt(0) + 888;
};

const StaticLoad = {
  default: (message: string, id?: string) => {
    const defaultId = idDispose(id) || defaultLoad;
    const loadNode = document.getElementById(defaultId);
    if (!loadNode) {
      const div = document.createElement('div');
      const loading = React.createElement(Loading);
      div.id = idDispose(id) || defaultLoad;
      document.body.appendChild(div);

      ReactDOM.render(loading, div);
    }
  },
  show: (message = '', id?: string) => {
    StaticLoad.default(message, id);
  },

  remove: (id?: string) => {
    const defaultId = idDispose(id) || defaultLoad;
    const loadNode = document.getElementById(defaultId);

    loadNode && document.body.removeChild(loadNode);
  },
};

export default StaticLoad;
