/*
 * @Author: gouyang
 * @Description: base64文件转file类型
 * @Date: 2019-09-18 16:33:04
 */
function Base64ToFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = (arr[0] as any).match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default Base64ToFile;
