/*
 * @Descripttion: 消息reducer
 * @Author: gouyang
 * @Date: 2020-12-09 10:58:20
 * @LastEditors: gouyang
 * @LastEditTime: 2020-12-16 13:42:25
 */
import * as types from '../types/message.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  systemMessageLists: {
    data: [],
    page: 1,
    size: 10,
    total: 0,
  },
  importantMessageLists: {
    data: [],
    page: 1,
    size: 10,
    total: 0,
  },
  activeKey: '0',
  hasRead: 0,
  messageDetails: null,
  latestMessage: null,
};

const Message = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.UPDATE_SYSTEM_MESSAGE:
      return { ...state, systemMessageLists: payload, activeKey: payload.key };
    case types.UPDATE_IMPORTANT_MESSAGE:
      return { ...state, importantMessageLists: payload, activeKey: payload.key };
    case types.RESET_ACTIVEKEY:
      return { ...state, activeKey: '0' };
    case types.UPDATE_HAS_READ:
      return { ...state, hasRead: payload };
    case types.UPDATE_MESSAGE_DETAILS:
      return { ...state, messageDetails: payload };
    case types.UPDATE_LATESTS_MESSAGE:
      return { ...state, latestMessage: payload };
    default:
      return state;
  }
};
export default Message;
