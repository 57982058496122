/*
 * @Author: gouyang
 * @Description: 文件下载完后提示语
 * @Date: 2019-09-19 16:17:05
 */
import React from 'react';
import { notification } from 'antd';

import './index.less';

function downloadSuccess() {
  notification.success({
    message: (
      <p className="download-success">
        下载完成后，可按<i>Ctrl+J</i>查看本地下载记录！
      </p>
    ),
    placement: 'topRight',
  });
}

export default downloadSuccess;
