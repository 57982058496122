/* eslint-disable @typescript-eslint/no-empty-interface */
/*
 * @Author: gouyang
 * @Description:当栏目无类型时显示该页面
 * @Date: 2019-10-16 14:40:24
 */
import React, { Component } from 'react';
import Empty from './index';
import ColumnList from '../../components/ColumnList';
import { connect } from 'react-redux';
import * as menuAction from '../../redux/action/menu.action';

interface P {
  registerGetDataFn: any;
}
interface S {}
class EmptySorce extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    menuAction.updateButtonStatus({
      status: false,
      type: 'create_course',
      name: '创建课程',
    });
    this.props.registerGetDataFn();
  }
  render() {
    return (
      <div>
        <div className="page">
          <ColumnList />
          <div style={{ flex: 1, padding: '20px 45px 0 0', background: '#fff' }}>
            <Empty />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  // 注册根据权限获取数据函数
  registerGetDataFn: () => dispatch({ type: 'GETDATABYROOT', payload: () => {} }),
});

export default connect(null, mapDispatchToProps)(EmptySorce);
