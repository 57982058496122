// 全局封装弹出的 高阶函数
import React from 'react';
import ReactDOM from 'react-dom';

const CLASS_NAME = '_overall-Situation';
export default function (Components: any) {
  return {
    open: (props: { [key: string]: any }, id?: string) => {
      const defaultId = id || String(Date.now());
      const loadNode = document.getElementById(defaultId);
      if (!loadNode) {
        // 给页面上添加组件
        const div = document.createElement('div');
        const root = React.createElement(Components, { ...props });
        div.id = defaultId;
        div.className = CLASS_NAME;
        document.body.appendChild(div);
        ReactDOM.render(root, div);
      }
    },
    remove: (id?: string) => {
      if (id) {
        const loadNode = document.getElementById(id);
        loadNode && ReactDOM.unmountComponentAtNode(loadNode);
        loadNode && document.body.removeChild(loadNode);
      } else {
        const list = document.querySelectorAll(`.${CLASS_NAME}`);
        for (const i in list) {
          if (list[i].tagName === 'DIV') {
            document.body.removeChild(list[i]);
          }
        }
      }
    },
  };
}
// 移除组件
export const removePopupr = (id?: string) => {
  if (id) {
    const loadNode = document.getElementById(id);
    loadNode && ReactDOM.unmountComponentAtNode(loadNode);
    loadNode && document.body.removeChild(loadNode);
  } else {
    const list = document.querySelectorAll(`.${CLASS_NAME}`);
    for (const i in list) {
      if (list[i].tagName === 'DIV') {
        ReactDOM.unmountComponentAtNode(list[i]);
        document.body.removeChild(list[i]);
      }
    }
  }
};
