import reducers from './redux/reducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
// const finalCreateStore = compose(applyMiddleware.apply(this, [thunk]))(createStore);
// let Store = finalCreateStore(reducers);

const Store = createStore(reducers, applyMiddleware(thunk));
(window as any)._dispatch = Store.dispatch;
export default Store;

export const dispatch = (func: any) => {
  Store.dispatch(func);
};
