/* eslint-disable @typescript-eslint/no-empty-interface */
// 设置时间
import React from 'react';
import { connect } from 'react-redux';
import { Form, DatePicker, TimePicker, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import _ from 'lodash';

interface P extends FormComponentProps {
  isEdit: boolean; // 是否是编辑
  setTime: any; // 回显数据
}
interface S {}
class Material extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // 编辑回显示数据
    const { setTime, isEdit } = nextProps;
    if (setTime !== this.props.setTime) {
      if (isEdit && setTime) {
        this.props.form.setFieldsValue({
          date: moment(setTime.startTime),
          startTime: moment(setTime.startTime),
          endTime: moment(setTime.endTime),
        });
      }
    }
  }
  // 父组件获取数据
  getItemsValue = () => {
    return new Promise((resolve: (value: any) => void, reject: (str: string) => void) => {
      this.props.form.validateFields((error: any, value: any) => {
        if (!error) {
          const str_date = moment(value.date).format('YYYY-MM-DD');
          const str_start = `${moment(value.startTime).format('HH:mm')}:00`;
          const str_end = `${moment(value.endTime).format('HH:mm')}:00`;
          const startTime = moment(`${str_date} ${str_start}`, 'YYYY-MM-DD HH:mm:ss').valueOf();
          const endTime = moment(`${str_date} ${str_end}`, 'YYYY-MM-DD HH:mm:ss').valueOf();
          if (startTime >= endTime) {
            message.error('结束时间需大于开始时间！');
            return;
          }
          resolve({ startTime, endTime });
        } else {
          reject('请添加开始结束时间！');
        }
      });
    });
  };
  // 开始时间改变
  startTimeChange = (value: any) => {
    if (!value) return;
    this.props.form.setFieldsValue({ endTime: _.cloneDeep(value).add(40, 'm') });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="model" id="setTime">
        <h3>时间设置</h3>
        <div className="setTime">
          <Form>
            <Form.Item {...formLayout} label="课时日期">
              {getFieldDecorator('date', {
                rules: [
                  {
                    required: true,
                    message: '请选择日期！',
                  },
                ],
              })(<DatePicker format="YYYY-MM-DD" placeholder="请选择日期" />)}
            </Form.Item>
            <Form.Item {...formLayout} label="开始时间">
              {getFieldDecorator('startTime', {
                rules: [
                  {
                    required: true,
                    message: '请选择开始时间！',
                  },
                ],
              })(
                <TimePicker
                  format={'HH:mm'}
                  style={{ width: '171px' }}
                  placeholder="开始时间"
                  onChange={this.startTimeChange}
                />,
              )}
            </Form.Item>
            <Form.Item {...formLayout} label="结束时间">
              {getFieldDecorator('endTime', {
                rules: [
                  {
                    required: true,
                    message: '请选择结束时间！',
                  },
                ],
              })(<TimePicker format={'HH:mm'} style={{ width: '171px' }} placeholder="结束时间" />)}
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
const formLayout: any = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 },
};
// function disabledDate(current: any) {
//   let str = current.format('YYYY-MM-DD');
//   str += ' 23:59:59';
//   return current && moment(str) < moment();
// }
const mapStateToProps = (state: any) => ({
  setTime: state.Lesson.classHourDetail.setTime,
});
export default connect(mapStateToProps)(Form.create<P>()(Material));
