/*
 * @Descripttion: 打开直播客户端
 * @Author: gouyang
 * @Date: 2021-01-13 15:05:35
 * @LastEditors: gouyang
 * @LastEditTime: 2021-01-26 10:13:42
 */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'antd';
import detectionSystem from '../../util/detectionSystem';
import browserInfo from '../../util/browserInfo';
import live_logo from '../../static/live_logo.png';
import './index.less';

interface P {
  ignoreClose: boolean;
  url: string;
}

function OpenLiveClient(props: P) {
  const { ignoreClose, url } = props;
  const timer = useRef<number>(0);
  const [downloadUrl, setDownloadUrl] = useState<string>('');

  const onClose = useCallback(() => {
    (window as any).history.back();
  }, []);

  const onOpenClient = useCallback(() => {
    const { name } = browserInfo();
    let client: any = null;
    if (name === 'IE' || name === 'Firefox') {
      client = window.open(url);
    } else {
      const a = document.createElement('a');
      a.setAttribute('href', url);
      document.body.appendChild(a);
      a.click();
    }
    timer.current = window.setTimeout(() => {
      if (client) {
        client.close();
      }
    }, 5000);
  }, [url]);

  useEffect(() => {
    const system = detectionSystem();
    if (system === 'mac') {
      setDownloadUrl(
        'https://www.baijiayun.com/default/home/liveclientDownload?type=mac&partner_id=20000000',
      );
    } else if (system === 'windows') {
      setDownloadUrl(
        'https://www.baijiayun.com/default/home/liveclientDownload?type=windows&partner_id=20000000',
      );
    }
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <div className="live-client-content">
      {!ignoreClose && (
        <Icon onClick={onClose} className="close-live-client-modal" type="close-circle" />
      )}
      <img alt="" src={live_logo} />
      <div className="download-info">
        <div className="btn-box">
          <button onClick={onOpenClient}>启动云端课堂</button>
          <button>
            <a href={downloadUrl}>下载云端课堂</a>
          </button>
        </div>
        <p>若未安装或无法启动客户端，请下载最新客户端。</p>
      </div>
    </div>
  );
}

export default memo(OpenLiveClient);
