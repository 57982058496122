import React from 'react';
import './index.less';
import { Breadcrumb } from 'antd';
import { NormalObj } from '../../util';

interface P {
  data: NormalObj[];
}
const Index = (props: P) => {
  const { data } = props;

  return (
    <Breadcrumb className="breadcrumb" separator=">">
      {data.map((item: any, index: number) => (
        <Breadcrumb.Item key={index} {...(item.path ? { href: item.path } : {})}>
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Index;
