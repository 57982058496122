import { AnyAction } from 'redux';
import { UPDATE_SEARCH_VALUE } from './../types/createSearch.type';

const initialState = (() => ({
  value: '',
}))();

const CreateSearch = (state = { ...initialState }, { type, payload }: AnyAction) => {
  switch (type) {
    case UPDATE_SEARCH_VALUE:
      return { ...state, value: payload };
    default:
      return { ...state };
  }
};

export default CreateSearch;
