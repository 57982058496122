import { combineReducers } from 'redux';

import Base from './base.reducer';
import Vod from './vod.reducer';
import Menu from './menu.reducer';
import Login from './login.reducer';
import Course from './course.reducer';
import CourseList from './course.list.reducer';
import Lesson from './lesson.reducer';
import CourseDetail from './course.detail.reducer';
import Live from './live.reducer';
import CreateArticle from './createArticle.reducer';
import Article from './article.reducer';
import userCenter from './userCenter.reducer';
import GloabalCreateSync from './globalCreateSync.reducer';
import DynamicControl from './dynamicControl.reducer';
import Resource from './resource.reducer';
import GlobalSearch from './globalSearch.reducer';
import CreateSearch from './createSearch.reducer';
import Message from './message.reducer';
import Account from './account.reducer';

export default combineReducers({
  Base,
  Vod,
  Menu,
  Login,
  Course,
  Lesson,
  CourseList,
  CourseDetail,
  Live,
  CreateArticle,
  Article,
  userCenter,
  GloabalCreateSync,
  DynamicControl,
  Resource,
  GlobalSearch,
  CreateSearch,
  Message,
  Account,
});
