import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MyIcon, mergeProps } from '../../../util';
import { Button } from 'antd';
import DownloadItem from '../../../components/LessonItem/DownloadItem';
import { hasUnion } from '../../../util/prohibitShow';
import { permitDownload } from '../../../util/permitDownload';

function ResourcePackage(props: any) {
  const [isOpened, setOpen] = useState(false);
  const [downloadAll, setDownloadAll] = useState(false);
  const [hasDownload, setHasDownload] = useState(false);
  const {
    resourceName,
    resourceCategory,
    files,
    goToPreview,
    resourceId: resourceGuid,
    courseId,
    lessonId,
    functionConfig: { downloadFile = false },
  } = props;
  useEffect(() => {
    if (hasUnion()) {
      const s = !files.every((file: any) => file.extension.toLowerCase() === 'mp4');
      setHasDownload(s);
    } else {
      setHasDownload(true);
    }
  }, [files]);

  const onDownload = (e: any) => {
    e.stopPropagation();
    if (!isOpened) setOpen(!isOpened);
    setDownloadAll(true);
  };

  const getType = (category: number) => {
    switch (category) {
      case 1:
        return 'iconmp';
      case 2:
        return 'iconcourseware';
      case 3:
        return 'iconmaterial';
      case 4:
        return 'iconteaching_plan';
      case 5:
        return 'iconoperation';
      case 6:
        return 'icontest_paper';
      default:
        return 'iconunknown';
    }
  };

  return (
    <div className="package">
      <div className="package-info" onClick={() => setOpen(!isOpened)}>
        <span className="package-icon">
          <MyIcon className="iconfont" type={getType(resourceCategory)} />
        </span>
        <span className="package-name" title={resourceName}>
          {resourceName}
        </span>
        {hasDownload && downloadFile && !permitDownload() && (
          <Button className="package-btn" type="primary" onClick={onDownload}>
            一键下载
          </Button>
        )}
      </div>
      <div className="package-item-list" style={{ display: isOpened ? 'block' : 'none' }}>
        {files.map((item: any, index: number) => (
          <DownloadItem
            downloadEntrance={2}
            watchEntrance={2}
            key={index}
            data={Object.assign(item, {
              resourceGuid,
              lessonId,
              courseId,
              category: resourceCategory,
            })}
            lessonId={props.data}
            goToPreview={goToPreview}
            downloadAll={downloadAll}
            onCallback={() => setDownloadAll(false)}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  functionConfig: { ...state.DynamicControl.functionConfig },
});

export default React.memo(connect(mapStateToProps, mergeProps)(ResourcePackage));
