import apiConfig from '../config/api';
import { message } from 'antd';
import Alert from '../components/Alert/Alert';
import StaticLoad from '../components/StaticLoad';
import { getToken, getLeagueId, getUserId } from '../util';
import qs from 'querystring';
import getWechatLink from '../config/wechatLink';

// 处理浏览器异步请求打不开新窗口问题
const Ajax = (data: { url: string; params?: any }) => {
  return new Promise((resolve, reject) => {
    // StaticLoad.remove('ajax');
    let XHR: any = null;
    let timer: any = null;
    let method = 'get';
    if ((window as any).XMLHttpRequest) {
      // 非IE内核
      XHR = new XMLHttpRequest();
    } else if ((window as any).ActiveXObject) {
      // IE内核,这里早期IE的版本写法不同,具体可以查询下
      XHR = new (window as any).ActiveXObject('Microsoft.XMLHTTP');
    } else {
      XHR = null;
    }
    if (data.params) {
      data.url = `${data.url}?${qs.stringify(data.params)}`;
    }
    if (XHR) {
      XHR.open(method, `${apiConfig.api}${data.url}`, false);
      XHR.setRequestHeader('Authorization', getToken());
      XHR.setRequestHeader('leagueId', getLeagueId());
      XHR.setRequestHeader('relationUserId', getUserId());
      XHR.onreadystatechange = function () {
        if (XHR.readyState && XHR.readyState === 4) {
          if (XHR.status && XHR.status === 200) {
            clearTimeout(timer);
            StaticLoad.remove();
            // 这里可以对返回的内容做处理
            resolve({ status: XHR.status });
            XHR = null;
          } else {
            clearTimeout(timer);
            StaticLoad.remove();
            if (XHR.status === 401) {
              let data: { message: string; code: string };
              try {
                data = JSON.parse(XHR.responseText);
              } catch (error) {
                data = {
                  message: '',
                  code: '',
                };
              }
              Alert.open(data['message'], () => {
                window.localStorage.removeItem('userInfo');
                window.localStorage.removeItem('unionId');
                window.localStorage.removeItem('ROOTTYPE');
                // (window as any)._history.replace(getPathSuffix());
                window.location.href = getWechatLink();
              });
              reject(null);
            }
            if (XHR.status <= 504 && XHR.status >= 500) {
              message.error('网络或服务器错误，请稍后重试！');
              reject(null);
            } else {
              let data = XHR.responseText ? JSON.parse(XHR.responseText) : {};
              reject({ status: XHR.status, data: data });
            }
            XHR = null;
          }
        }
      };
      // 控制请求超时
      timer = setTimeout(() => {
        StaticLoad.remove();
        message.error('网络或服务器错误，请稍后重试！');
        XHR.abort();
        XHR = null;
      }, 5000);
      StaticLoad.show();
      XHR.send();
    }
  });
};

export default Ajax;
