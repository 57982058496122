// ------ 课程 ------
export const COLUMN_LIST_REQUEST = 'COLUMN_LIST_REQUEST';
export const GET_COURSE_REQUEST = 'GET_COURSE_REQUEST';
export const COVER_LIST_REQUEST = 'COVER_LIST_REQUEST';

// ------ 课时 ------
export const CLEAR_DATA = 'CLEAR_DATA';
export const CHANGE_SUBJECT_REQUEST = 'CHANGE_SUBJECT_REQUEST';
export const RESOURCES_LIST_REQUEST = 'RESOURCES_LIST_REQUEST';
export const CLASSHOUR_LIST_REQUEST = 'CLASSHOUR_LIST_REQUEST';
export const CLASSHOUR_DETAIL_REQUEST = 'CLASSHOUR_DETAIL_REQUEST';
export const UPDATE_PROGERSS_STATUS = 'UPDATE_PROGERSS_STATUS';
export const CHANGE_LIST_SORT = 'CHANGE_LIST_SORT';

// 上传封面图
export const IS_REQUESTED_ERROR = 'IS_REQUESTED_ERROR';
export const SETTING_PAGINATION_PARAMS = 'SETTING_PAGINATION_PARAMS';
export const CLEAR_LISTS_DATA = 'CLEAR_LISTS_DATA';
export const START_REQUEST = 'START_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';
