/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import './index.less';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import React, { Component } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Row, Col, Timeline, message, Form, Icon, Button } from 'antd';
import * as action from '../../redux/action/vod.action';
import * as menuAction from '../../redux/action/menu.action';
import Alert from '../../components/Alert/Alert';
import AttachmentItem from '../../components/AttachmentItem';
import ResourceViewCom from '../../components/media/resourceView/resourceView';
import {
  NormalObj,
  mergeProps,
  getSearchParams,
  MyIcon,
  formatUrl,
  uuid,
  getExtensionType,
} from '../../util';
import Footer from '../../components/Footer';
import { ArtScience } from '../../util/enum';
import { VideoMarkerPlayer } from 'video-marker-player';
import 'video-marker-player/dist/index.css';
import { downloadCosFile } from '../../util/cosFileDownload';

// let topPlayer: any;
const { Item } = Timeline;
interface P extends RouteComponentProps, FormComponentProps {
  file_no_exist: Boolean;
  // courseInfo: NormalObj;
  functionConfig: NormalObj;
}
class LessonPreview extends Component<P, any> {
  appId: string;
  pause: boolean;
  topRef: any;
  media: any;
  searchParams: NormalObj;
  private randomMd5: string;
  private currentFileInfos: any; // 当前文件信息

  constructor(props: P) {
    super(props);
    this.appId = '';
    this.media = null;
    this.pause = false;
    this.resize = this.resize.bind(this);
    this.topRef = null;
    this.searchParams = getSearchParams(props.location.search);
    this.randomMd5 = uuid();
    this.currentFileInfos = {
      id: '',
      extension: '',
      category: -1,
      resourceGuid: '',
    };
    this.state = {
      showResourceList: true,
      showPreview: false,
      showMarkList: true,
      vedioHeight: window.innerHeight - 180,
      preViewProps: {
        url: '',
        type: '',
      },
      // activeVodIdx: '',
      activeTimeIndex: '',
      activeLessonIndex: '',
      lessonInfo: {
        // 可是详情
        centerResources: [],
        materialChapters: [],
        _video_: [], // 点播课时
        _material_: [], // 资料课时
      },
      preview_lesson: {
        // 上面预览详情
        url: '',
        marks: [], // 老逻辑打点数据
        markups: [], // 新逻辑打点数据
        fileId: '',
        playId: null,
      },
      isShowDowload: true, // 是否展示网席下载器链接
      wxUrl: '', // 网席播放器地址
      openTitle: false,
    };
    this.pausePlayer = this.pausePlayer.bind(this);
  }
  componentDidMount() {
    // 将页面置顶
    window.scrollTo(0, 0);
    menuAction.getLeagueInfos();
    this.screenChange();
    this.initData();
    // 获取网席播放器
    this.getPlayerUrl(0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    // topPlayer && topPlayer.dispose();
    // topPlayer = null;
    Alert.remove('lesson');
    Alert.remove('lessonList');
  }
  // 获取播放器地址
  async getPlayerUrl(type: number) {
    const url = await action.getPlayerUrl(type);
    switch (type) {
      case 0:
        this.setState({ wxUrl: url });
        break;
      default:
    }
  }
  // 初始化数据获取
  initData = async () => {
    // 获取课时详情
    const lessonInfo = await action.fetchLesson(this.searchParams.lessonId, this.searchParams.type);
    const { file_no_exist, location } = this.props;
    this.searchParams = getSearchParams(location.search);
    const { idx, mainVedio, centerResources } = this.filterData(lessonInfo, file_no_exist);
    lessonInfo.centerResources = centerResources;
    const id = this.getMainId(mainVedio, idx);

    if (
      centerResources &&
      centerResources.length > 0 &&
      centerResources.every((item: NormalObj) => this.isSupportType(item.extension, true))
    ) {
      this.setState({ noSupport: true });
    }
    const { video, material } = this.filterVideoAndMaterial(centerResources);
    lessonInfo._video_ = video;
    lessonInfo._material_ = material;
    this.setState(
      {
        lessonInfo,
      },
      () => {
        if (this.searchParams && this.searchParams.resId) {
          const [id, idx] = this.searchParams.resId.split('*');
          const { category } = this.searchParams;
          const i = centerResources.findIndex(
            (resource: any) =>
              resource.fileId === id && resource.category === parseInt(category, 10),
          );
          if (i !== -1 && !centerResources[i].expiryState) {
            this.playLesson(this.searchParams.lessonType, id, idx, centerResources[i]);
          }
          return null;
        }
        centerResources[idx] &&
          !centerResources[idx].expiryState &&
          this.playLesson(this.searchParams.lessonType, id, idx, centerResources[idx]);
      },
    );
  };

  // 获取视频
  /**
   * 预览默认视频----默认预览第一个可预览视频
   * @param type 课时类型 2:点播 3:资料
   * @param id 课时Id
   * @param index 课时序号
   * @param updateMainVideoInfo 是否更新点播视频信息
   */
  playLesson = async (type: string, id: string, index: number, item: any) => {
    let data: NormalObj = {};
    const { category, resourceGuid, extension } = item;
    if (category === 6 && extension.toLowerCase() === 'pdf') {
      message.error('暂不支持预览！', 1);
      data = { fileId: '', url: '', marks: [] };
    } else if (type === '2' || type === '3') {
      const previewType = this.searchParams.type === '2' ? 1 : 0;
      data = await action.filePreview(id, previewType, encodeURIComponent(item.fileName));
    }
    this.currentFileInfos = {
      id,
      extension: data.extension,
      category: item.category,
      resourceGuid,
    };

    // this.disposeCloudPlayer('player-container-id');
    // if (data.playId) {
    //   this.getTencentAppId(data.playId, 'player-container-id');
    // } else {
    this.setState({
      showPreview: true,
      preViewProps: {
        url: formatUrl(data, this.randomMd5),
        type: getExtensionType(data.extension),
      },
      preview_lesson: data,
      activeLessonIndex: id + index,
      // markups: data.markups,
    });
    // }
    // this.setState({
    //   preview_lesson: data,
    //   activeLessonIndex: id + index,
    // });
  };
  /**
   * 是否支持预览格式
   * @param type 资源后缀
   * @param isSupport 支持所有/一个都不支持
   */
  isSupportType(type: string, isSupport?: Boolean | undefined) {
    const supportType = [
      'ppt',
      'pptx',
      'jpg',
      'png',
      'mp3',
      'wma',
      'wav',
      'mp4',
      'swf',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'pdf',
    ];
    if (!isSupport) {
      return supportType.indexOf(type.toLowerCase()) > -1;
    } else {
      return supportType.indexOf(type.toLowerCase()) === -1;
    }
  }

  /**
   * 获取主视频ID
   * @param data 课堂实录/支持预览的附件
   */
  getMainId(data: NormalObj | null, index: any) {
    let id = '';
    switch (this.searchParams.lessonType) {
      case '2':
      case '3':
        id = data && data.fileId;
        break;
      default:
        break;
    }
    return id;
  }
  screenChange() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    this.setState({ vedioHeight: window.innerHeight - 180 });
  }

  /**
   * 实录标记跳转
   * @param timeMark 打点对象
   */
  turnTo(timeMark: NormalObj) {
    const { topPlayerError, isEnd } = this.state;
    if (this.media) {
      const time = this.media.duration();
      if (time > timeMark.timeTick) {
        this.media.currentTime(timeMark.timeTick);
      }
      if (time < timeMark.timeTick) {
        Alert.open('对不起，该时间点已超过视频总时长！');
      }
      if (timeMark.timeTick < 0) {
        Alert.open('对不起，该时间点为异常时间点！');
      }
    }
    // if (topPlayer) {
    //   if (!topPlayerError) {
    //     const time = topPlayer.duration();
    //     if (timeMark.timeTick < 0) {
    //       Alert.open('对不起，该时间点为异常时间点！');
    //     } else if (timeMark.timeTick > time) {
    //       Alert.open('对不起，该时间点已超过视频总时长！');
    //     } else {
    //       isEnd && this.setState({ isEnd: false });
    //       topPlayer.currentTime(timeMark.timeTick);
    //       this.pause && topPlayer.play();
    //     }
    //   } else {
    //     Alert.open('视频播放出错，暂无法使用打点跳转功能！');
    //   }
    // }
  }

  /**
   * 资源预览
   * @param id 附件id
   * @param extension 资源后缀
   * @param index 选中附件序号
   * @param type 1-点播, 2-附件
   */
  previewClick = async (item: any, index: number, type: string) => {
    const { fileId: id, extension, resourceGuid } = item;
    if (this.isSupportType(extension, true)) {
      message.error('暂不支持预览！', 1);
      return;
    }
    if (this.state.activeLessonIndex === id + index) return;
    this.currentFileInfos = {
      id,
      extension,
      category: item.category,
      resourceGuid,
    };
    this.playLesson(type, id, index, item);
  };

  // 点播课时和资料课时
  filterVideoAndMaterial(data: any[]) {
    const video: any[] = [];
    const material: any[] = [];
    data.forEach((file: any) => {
      if (file.category === 1) {
        if (file.extension.toLowerCase() === 'mp4') {
          video.push(file);
        } else if (file.extension.toLowerCase() !== 'txt') {
          material.push(file);
        }
      } else {
        material.push(file);
      }
    });
    return { video, material };
  }
  /**
   * 过滤数据
   * @param data 数据源
   * @param file_no_exist 文件不存在 // todo
   */
  filterData(data: NormalObj, file_no_exist?: Boolean) {
    let idx = 0;
    const result: NormalObj[] = [];
    let mainVedio: NormalObj | null = null;
    const { centerResources, classRecord } = data;
    const mergeData: NormalObj[] = classRecord
      ? [classRecord, ...centerResources]
      : centerResources;
    if (mergeData && mergeData.length > 0) {
      for (let i = 0, len = mergeData.length; i < len; i++) {
        const item = mergeData[i];
        item.files.map((citem: NormalObj) => {
          citem.category = item.category;
          citem.resourceGuid = item.resourceGuid;
          citem.expiryState = item.expiryState;
          return citem;
        });
        result.push(...item.files);
      }
      switch (this.searchParams.lessonType) {
        case '2': // 点播预览
        case '3': // 资料预览
          mainVedio = result[0];
          if (file_no_exist) {
            idx = result.findIndex((item: NormalObj) => this.isSupportType(item.extension));
            if (idx > -1) mainVedio = result[idx];
          }
          break;
        default:
          break;
      }
    }
    return { idx, mainVedio, centerResources: result };
  }

  /**
   * 暂停上/下播放器
   * @type up: 上  down: 下
   */
  pausePlayer() {
    // if (topPlayer) {
    //   topPlayer.pause();
    // }
    if (this.media) {
      this.media.pause();
    }
  }

  /**
   * 设置顶部资源中心播放器组件
   * @param media 播放器实例
   */
  handleMedia(media: any) {
    this.media = media;
    this.media.on('play', () => {});
    this.media.on('ended', () => {
      if (this.media.isFullscreen_) {
        this.media.exitFullscreen();
      }
    });
    this.media.on('pause', () => {});
  }

  // 下载闻道播放器
  downloadPalyer = async () => {
    const res = await action.getNewestPlayerInfo();
    if (res.Success) {
      const { FileName, Path } = res.Data;
      // 截取最后一个斜杠后面的部分，即文件名及其扩展名
      const fileNameWithExtension = Path.substring(Path.lastIndexOf('/') + 1);

      // 如果需要，你可以进一步切分文件名和扩展名
      const md5 = fileNameWithExtension.split('.')[0]; // 文件名

      downloadCosFile(FileName, md5, Path);
      // const xhr = new XMLHttpRequest();
      // xhr.open('GET', Path, true);
      // xhr.responseType = 'blob';
      // xhr.onload = function () {
      //   if (xhr.status === 200) {
      //     const url = window.URL.createObjectURL(xhr.response);
      //     const link = document.createElement('a');
      //     link.style.display = 'none';
      //     link.href = url;
      //     link.download = FileName;
      //     link.click();
      //     window.URL.revokeObjectURL(link.href);
      //   }
      // };
      // xhr.send();
    }
  };

  toggleTitle = () => {
    this.setState({ openTitle: !this.state.openTitle });
  };

  // 实录标记控制
  controlMark(status?: boolean) {
    const newStatus = status === undefined ? !this.state.showMarkList : status;
    this.setState({ showMarkList: newStatus });
  }

  onLinkTo = (key: string): void => {
    const { history } = this.props;
    const LINKTO = window.localStorage.getItem(key);
    window.localStorage.removeItem(key);
    LINKTO && history.push(JSON.parse(LINKTO));
  };

  onGoEdit = (): void => {
    this.onLinkTo('LINKTOPREVIEW');
  };

  onClose = (): void => {
    this.onLinkTo('LINKTO');
  };

  playDom = () => {
    const { preViewProps, activeLessonIndex, preview_lesson } = this.state;
    const { markups } = preview_lesson;

    // 点播课时且是mp4
    return this.currentFileInfos.category === 1 && preViewProps.type === 'mp4' ? (
      <VideoMarkerPlayer
        defaultSilderOpen
        theme="#2683f5"
        videoUrl={preViewProps.url}
        markerData={markups}
        playerOptions={
          {
            events: {
              ended: (player: any) => {
                player.exitFullscreen();
              },
            },
            xgplayerOptions: {
              autoplayMuted: false,
              autoplay: true,
            },
          } as any
        }
      />
    ) : (
      <ResourceViewCom
        key={activeLessonIndex}
        mediaType={preViewProps.type}
        mediaAddress={preViewProps.url}
        playerCallBack={(media: any) => this.handleMedia(media)}
      />
    );
  };

  onExpand = () => {
    this.setState({
      showResourceList: !this.state.showResourceList,
    });
  };

  render() {
    const {
      noSupport,
      vedioHeight,
      showPreview,
      preViewProps,
      activeLessonIndex,
      lessonInfo,
      preview_lesson,
      isShowDowload,
      openTitle,
      showResourceList,
    } = this.state;
    const { functionConfig } = this.props;
    const { _video_, _material_ } = lessonInfo;

    return (
      <div className="lesson-preview-page" onContextMenu={(e: any) => e.preventDefault()}>
        <div className="vod-page-header">
          <span className="page-header-title">
            {this.searchParams.courseName} {'>'}
          </span>{' '}
          {lessonInfo.lessonName}
        </div>
        <Row className="flex">
          {/* 左侧打点模块 */}
          {/* {this.currentFileInfos.category === 1 && marks && marks.length > 0 && (
            <Col className={`left ${showMarkList ? 'show' : ''}`} style={{ height: vedioHeight }}>
              {this.currentFileInfos.category === 1 && marks && marks.length > 0 && (
                <div
                  className={`wrapper-mark ${showMarkList ? 'show' : ''}`}
                  onClick={() => this.controlMark()}
                  title={showMarkList ? '收起实录标记' : '展开实录标记'}
                >
                  {showMarkList ? <Icon type="double-left" /> : <Icon type="double-right" />}
                </div>
              )}
              {this.currentFileInfos.category === 1 && marks && marks.length > 0 && (
                <div className={`mark-list ${showMarkList ? 'show' : ''}`}>
                  <div className="mark-list-header">
                    <span>实录标记</span>
                    <span className="btn" onClick={() => this.controlMark(false)}>
                      <MyIcon type="iconic_del" />
                    </span>
                  </div>
                  <div className="mark-list-content">
                    <Timeline>
                      {marks.map((item: NormalObj, index: number) => (
                        <Item key={index} className={activeTimeIndex === index ? 'active' : ''}>
                          <div onClick={() => this.turnTo(item)} className="mark-list-item">
                            <span className="time">{formateMinutes(item.timeTick)}</span>
                            <span className="intro">{item.description}</span>
                          </div>
                        </Item>
                      ))}
                    </Timeline>
                  </div>
                </div>
              )}
            </Col>
          )} */}

          <Col
            style={{ height: isShowDowload ? vedioHeight : vedioHeight + 48 }}
            className="vod-page-content-left middle"
          >
            <div ref={(topRef) => (this.topRef = topRef)} className="wrapper-player" />
            {isShowDowload ? (
              <div className="wrapper-download">
                {functionConfig.showDownloadWenDaoPlayer && (
                  <div>
                    下载
                    <span className="name" onClick={this.downloadPalyer}>
                      闻道课件播放器
                    </span>
                    {/* <a rel="noopener noreferrer" href={wxUrl} download>
                      <span className="name">闻道课件播放器</span>
                    </a> */}
                    ，可以本地播放课时内容。
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            {showPreview ? (
              <div className="preview-wrap">
                {noSupport ? (
                  <div className="errorWrap">
                    <MyIcon type="iconpreview" className="errorIcon" />
                    <div className="errorText">暂不支持预览</div>
                  </div>
                ) : (
                  preViewProps.url && !preViewProps.playId && this.playDom()
                )}
              </div>
            ) : (
              ''
            )}
          </Col>

          {/* 右侧信息 */}
          <Col
            className="vod-page-content-right right"
            style={{ height: vedioHeight, width: showResourceList ? 320 : 0 }}
          >
            <div className="vod-page-expand-btn" onClick={this.onExpand}>
              {!showResourceList ? <Icon type="double-left" /> : <Icon type="double-right" />}
              资源列表
            </div>
            <div className="vod-page-content-right-info">
              <div className="vod-page-content-right-item">
                <div className="item-title" onClick={this.toggleTitle}>
                  基本信息{' '}
                  <span className="item-title-toggle">
                    {openTitle ? '收起' : '展开'}
                    <Icon type={openTitle ? 'up' : 'down'} />
                  </span>
                </div>
                <div className="text" style={{ display: openTitle ? 'block' : 'none' }}>
                  <div className="item-text">
                    <span>授课老师：</span>
                    <span>
                      {lessonInfo.teachers &&
                        lessonInfo.teachers.map((item: NormalObj) => item.teacherName).join(' / ')}
                    </span>
                  </div>
                  <div className="item-text">
                    <span>年级：</span>
                    <span>
                      {lessonInfo.grades &&
                        lessonInfo.grades.map((item: NormalObj) => item.gradeName).join(' / ')}
                    </span>
                  </div>
                  <div className="item-text">
                    <span>学科：</span>
                    <span>
                      {lessonInfo.subjects &&
                        lessonInfo.subjects.map((item: NormalObj) => item.subjectName).join(' / ')}
                    </span>
                  </div>
                  {lessonInfo.materialChapters && lessonInfo.materialChapters?.length > 0 && (
                    <div className="item-text">
                      <span>教材章节：</span>
                      <span
                        title={
                          lessonInfo.materialChapters &&
                          lessonInfo.materialChapters
                            .map((item: NormalObj) => item.materialName + item.chapterName)
                            .join(' / ')
                        }
                      >
                        {lessonInfo.materialChapters &&
                          lessonInfo.materialChapters
                            .map((item: NormalObj) => item.materialName + item.chapterName)
                            .join(' / ')}
                      </span>
                    </div>
                  )}
                  {lessonInfo.labels && lessonInfo.labels.length > 0 && (
                    <div className="item-text">
                      <span>标签：</span>
                      <span className="tag">
                        {lessonInfo.labels.map((label: NormalObj) => label.labelName).join(' / ')}
                      </span>
                    </div>
                  )}
                  {typeof lessonInfo.artScience === 'number' && (
                    <div className="item-text">
                      <span>文理科：</span>
                      <span>{ArtScience[lessonInfo.artScience]}</span>
                    </div>
                  )}
                </div>
              </div>
              {this.searchParams.lessonType === '2' && _video_.length > 0 ? (
                <div className="vod-page-content-right-item lesson-name">
                  <div className="item-title">课堂实录</div>
                  {_video_.map((item: NormalObj, idx: number) => {
                    return (
                      <div key={`${item.fileId}-${idx}`} className="item-text text">
                        <AttachmentItem
                          data={item}
                          key={`${item.fileId}-${idx}`}
                          index={idx}
                          active={activeLessonIndex}
                          is_create={this.searchParams.type}
                          onClick={this.previewClick.bind(this, item, idx, '2')}
                          isVideo
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
              {_material_.length > 0 && (
                <div className="vod-page-content-right-item courseware">
                  <div className="item-title">课时资料</div>
                  {_material_.map((item: NormalObj, index: number) => {
                    return (
                      <AttachmentItem
                        data={item}
                        key={`${item.fileId}-${index}`}
                        index={index}
                        active={activeLessonIndex}
                        is_create={this.searchParams.type}
                        onClick={this.previewClick.bind(this, item, index, '3')}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="operate-btn">
          <Button onClick={this.onGoEdit}>返回编辑</Button>
          <Button type="primary" onClick={this.onClose}>
            关闭
          </Button>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state: any) => ({
  ...state.Vod,
  ...state.Course,
  functionConfig: { ...state.DynamicControl.functionConfig },
});
export default Form.create<P>()(connect(mapStateTopProps, mergeProps)(LessonPreview));
