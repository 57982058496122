/*
 * @Descripttion: 消息redux type
 * @Author: gouyang
 * @Date: 2020-12-09 11:00:47
 * @LastEditors: gouyang
 * @LastEditTime: 2020-12-16 13:41:54
 */

export const UPDATE_SYSTEM_MESSAGE = 'UPDATE_SYSTEM_MESSAGE';
export const UPDATE_IMPORTANT_MESSAGE = 'UPDATE_IMPORTANT_MESSAGE';
export const RESET_ACTIVEKEY = 'RESET_ACTIVEKEY';
export const UPDATE_HAS_READ = 'UPDATE_HAS_READ';
export const UPDATE_MESSAGE_DETAILS = 'UPDATE_MESSAGE_DETAILS';
export const UPDATE_LATESTS_MESSAGE = 'UPDATE_LATESTS_MESSAGE';
