import React, { PureComponent } from 'react';
import { Radio, DatePicker, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment';

import './style.less';

interface P extends FormComponentProps {
  type: number;
  onChange: (e: any) => void;
  data: any;
  contentHeight: number;
  label?: string;
  dateMargin?: number;
}

class TimeLimit extends PureComponent<P> {
  disabledDate = (current: any) => {
    return current && current < moment().endOf('day');
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { type, onChange, data, contentHeight, label, dateMargin } = this.props;
    const { expiryDate } = data;

    return (
      <div className="time-limit">
        <Form>
          <Form.Item label={label || ''}>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: '请选择截止时间！',
                },
              ],
              initialValue: type,
            })(
              <Radio.Group onChange={onChange}>
                <Radio value={1}>不限</Radio>
                <Radio value={2}>截止时间</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
          {type === 2 ? (
            <Form.Item style={{ height: 59, marginLeft: dateMargin }}>
              {getFieldDecorator('date', {
                rules: [
                  {
                    required: type === 2,
                    message: '请设置截止时间！',
                  },
                ],
                initialValue:
                  expiryDate === null || expiryDate === undefined ? null : moment(expiryDate),
              })(
                <DatePicker
                  format="YYYY-MM-DD"
                  showToday={false}
                  disabledDate={this.disabledDate}
                  style={{ width: 192 }}
                />,
              )}
            </Form.Item>
          ) : (
            <div style={{ height: contentHeight }} />
          )}
        </Form>
      </div>
    );
  }
}

export default Form.create<P>()(TimeLimit);
