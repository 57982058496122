/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import { message } from 'antd';
import Alert from '../../components/Alert/Alert';
import AxiosWrap from '../../lib/request';
import * as types from '../types/course.type';
import { dispatch } from '../../createStore';
import Immutable from 'seamless-immutable';
import TrackRequest from '../../lib/trackRequest';
import { getTeacher, getFilteredTeacher } from './base.action';
import { getNewMarkups, getOldMarkups } from './vod.action';
import { stringifySearchParams } from '../../util';
import qs from 'qs';
import isNotRootLink from '../../util/isNotRootLink';
import { lessonSort } from '../../lib/api';
import { parseMulLayerMarkerData } from 'video-marker-player';

// ------------- 课时模块 -------------
// 查询 课时 是否存在
export const getCheckExist = async (lessonId: string) => {
  try {
    const { status } = await AxiosWrap({ url: `/lesson/${lessonId}/check-exist` });
    if (status === 200) {
      return Promise.resolve(true);
    }
  } catch (error) {
    if (error && (error as any).status === 404) {
      message.error('课时不存在！');
      return;
    }
    if (error && (error as any).status === 403) {
      message.error('暂无权限！');
      return;
    }
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 学科变化
export const getChangeSubject = (params: []) => {
  dispatch({ type: types.CHANGE_SUBJECT_REQUEST, payload: Immutable(params) });
};
// 获取站点资源
export const getSiteResources = async (params: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/center-resource?${qs.stringify(params)}` });
    if (status === 200) {
      try {
        const payload = {
          data: [],
          total: data.page.totalElements,
          page: data.page.number,
        };
        if (data._embedded && data._embedded.centerResources) {
          payload.data = data._embedded.centerResources;
        }
        dispatch({ type: types.RESOURCES_LIST_REQUEST, payload });
      } catch (error) {
        message.error('返回参数有误！');
      }
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 查看站点资源详情
export const getResourcesDetail = async (resourceId: any) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/center-resource/${resourceId}` });
    if (status === 200) {
      return Promise.resolve(data);
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 获取资源预览
export const getPreviewResources = async (fileId: any, type: number, fileName: string) => {
  try {
    const { status, data } = await AxiosWrap({
      url: `/files/${fileId}/preview?type=${type}&fileName=${fileName}`,
    });
    if (status === 200) {
      return Promise.resolve(data);
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 创建课时
export const getCreatClassHour = async (params: any, cback: Function) => {
  try {
    const { status, data } = await AxiosWrap({ url: '/lesson', method: 'POST', data: params });
    if (data && status === 200) {
      message.success('保存成功！');
      cback(data.id);
      // 创建课时完成埋点
      TrackRequest({
        name: 'save_add_period',
        data: { course_id: params.courseId, period_type: params.lessonType, period_id: data.id },
      });
    }
  } catch (error) {
    if (error && (error as any).data) {
      if ((error as any).status === 403) {
        Alert.open((error as any).data.message, () => {
          isNotRootLink();
        });
      } else {
        message.error((error as any).data.message);
      }
    }
  }
};
// 获取课时列表
export const getClassHour = async (params: string | number) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/course/${params}/lesson` });
    if (status === 200) {
      dispatch({ type: types.CLASSHOUR_LIST_REQUEST, payload: data });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 课时详情
export const getClassHourDetail = async (params: string) => {
  try {
    const { status, data } = await AxiosWrap({ url: `/lesson/${params}` });
    const subjects = data.subjects.map((item: any) => item.subjectId);
    const teacherIds = data.teachers.map((i: any) => i.teacherId);
    let mergeTeacher: number[] = [];
    if (data.live) {
      const { assistantId, teacherId } = data.live;
      mergeTeacher = [assistantId, teacherId];
    }

    const teacherIdString: string = [...teacherIds, ...mergeTeacher]
      .filter((id: any) => typeof id === 'number')
      .join('|');

    await getFilteredTeacher(
      {
        subjectIdString: subjects.join('|'),
        teacherIdString,
        studySectionId: data.schoolSection,
      },
      teacherIds,
    );
    const { status: status1 }: any = await getTeacher({ subjectIdString: subjects.join('|') });
    if (status === 200 && status1 === 200) {
      // 处理 老师数组对象 转换成 id 列表
      const teacherIds = [];
      if (data.teachers.length > 0) {
        for (const i in data.teachers) {
          if (Object.prototype.hasOwnProperty.call(data.teachers, i)) {
            teacherIds.push(Number(data.teachers[i].teacherId));
          }
        }
      }

      const obj: any = {
        lessonType: data.lessonType,
        lessonId: data.id,
        setTime: {
          startTime: data.startTime,
          endTime: data.endTime,
        },
        Information: Immutable({
          artScience: data.artScience,
          lessonName: data.lessonName,
          teachers: teacherIds,
          schoolSection: data.schoolSection,
          grades: data.grades.map((item: any) => item.gradeId),
          subjects: data.subjects.map((item: any) => item.subjectId),
          subjectsObj: data.subjects,
          schoolYear: data.schoolYear,
          labels: data.labels
            ? data.labels.map((label: any, i: number) => ({
                id: label.labelId,
                name: label.labelName,
                key: new Date().getTime() + i,
              }))
            : [],
        }),
        materialChapters: data.materialChapters,
        live: Immutable(data.live),
        material: null,
        onDemand: null,
        extensionData: data.extensionData,
      };
      // 点播
      if (data.lessonType === 2) {
        const { data: data2 } = (await getNewMarkups(data?.classRecord.files?.[0].md5)) as any;
        const _data = (data2 || []).filter((t: any) => t.resourceType === 'COURSE_MEMOIR');
        let markups = [];
        if (_data.length) {
          markups = _data.map((t: any) => parseMulLayerMarkerData(t.data));
        } else {
          const { data: data3 } = (await getOldMarkups(data?.classRecord.files?.[0].md5)) as any;
          if ((data3.system || []).length > 0) {
            markups = [
              {
                ...data3.system.map((t: any, index: number) => {
                  return {
                    name: t.description,
                    key: index,
                    time: t.timeTick,
                  };
                }),
              },
            ];
          }
        }
        obj.onDemand = {
          ...data.classRecord,
          markups,
        };

        // 资料
        obj.material = dataFormat(data.centerResources);
      }
      // 资料课时
      if (data.lessonType === 3) {
        obj.material = dataFormat(data.centerResources);
      }
      dispatch({ type: types.CLASSHOUR_DETAIL_REQUEST, payload: { classHourDetail: obj } });
    }
  } catch (error) {
    if (error && (error as any).status === 404) {
      Alert.open('课时已不存在！', undefined, 'black', 'classHour-detail');
      return;
    }
    if (error && (error as any).status === 403) {
      Alert.open('暂无权限！', () =>
        (window as any)._history.push(
          `/userCente${stringifySearchParams({
            id: 'kechengbiao',
            name: '课程表',
            parentId: 'kechengbiao',
          })}`,
        ),
      );
      return;
    }
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
  // 数据格式处理
  function dataFormat(list: any) {
    const newList: any = [];
    if (list.length === 0) {
      return [];
    }
    for (const i in list) {
      if (Object.prototype.hasOwnProperty.call(list, i)) {
        const item = list[i];
        const obj = {
          resourceGuid: item.resourceGuid,
          category: item.category,
          name: item.name,
          createUserId: item.createUserId,
          expiryDate: item.expiryDate,
          expiryState: item.expiryState,
          status: 'done',
          id: Date.now() + Math.random(),
          subjects: item.subjects.map((item: any) => item.subjectId),
          files: item.files.map((item: any) => {
            item.id = Date.now() + Math.random();
            return item;
          }),
        };
        // if ((category === 2 || category === 3 || category === 4) && files && files.length > 0) {
        //     obj.name = files[0].fileName;
        // }
        newList.push(obj);
      }
    }
    return newList;
  }
};
// 创建课时，过滤掉失效老师
export const createLessonFilteredTeacher = async (detail: any) => {
  try {
    const subjects = detail.subjects.map((item: any) => item.subjectId);
    const teacherIds = detail.teachers.map((i: any) => i.teacherId);
    let mergeTeacher: number[] = [];
    if (detail.live) {
      const { assistantId, teacherId } = detail.live;
      mergeTeacher = [assistantId, teacherId];
    }

    const teacherIdString: string = [...teacherIds, ...mergeTeacher]
      .filter((id: any) => typeof id === 'number')
      .join('|');

    await getFilteredTeacher(
      {
        subjectIdString: subjects.join('|'),
        teacherIdString,
        studySectionId: detail.schoolSection,
      },
      teacherIds,
    );
    await getTeacher({ subjectIdString: subjects.join('|') });
  } catch (error) {
    // 回调
  }
};

// 发布/取消发布 课时
export const getClassHourPush = async (
  params: { lessonId: string; courseId: string; lessonType?: number },
  statu: string,
  cback: () => void,
) => {
  try {
    let datas: any = {};
    if (statu === '1') {
      // 发布
      datas = await AxiosWrap({ url: `/lesson/${params.lessonId}/release`, method: 'PUT' });
    } else if (statu === '2') {
      // 取消发布
      datas = await AxiosWrap({ url: `/lesson/${params.lessonId}/un-release`, method: 'PUT' });
    }
    const { status } = datas;
    if (status === 200) {
      message.success('操作成功！');
      cback();
      const obj: any = {
        course_id: params.courseId,
        period_id: params.lessonId,
      };
      // 课时发布埋点
      if (statu === '1') {
        obj.pub_entrance = 0; // 发布入口 0 - 创建  1 - 审核 int
        obj.period_type = params.lessonType;
        TrackRequest({ name: 'confirm_pub_period', data: obj });
      }
      // 课时取消发布埋点
      if (statu === '2') {
        TrackRequest({ name: 'confirm_unpub_period', data: obj });
      }
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};
// 删除课时
export const getDelClassHour = async (
  params: { lessonId: string; courseId: string },
  cback: () => void,
) => {
  try {
    const { status } = await AxiosWrap({ url: `/lesson/${params.lessonId}`, method: 'DELETE' });
    if (status === 200) {
      message.success('操作成功！');
      cback();
      // 删除课时埋点
      TrackRequest({
        name: 'confirm_del_period',
        data: { course_id: params.courseId, period_id: params.lessonId },
      });
    }
  } catch (error) {
    if (error && (error as any).data) {
      message.error((error as any).data.message);
    }
  }
};

// 更新课时
export const getUpdateClassHour = async (params: any, cback: Function) => {
  try {
    const { status, data } = await AxiosWrap({
      url: `/lesson/${params.lessonId}`,
      method: 'PUT',
      data: params.data,
    });
    if (data && status === 200) {
      message.success('保存成功！');
      cback(data.id);
      // 编辑课时完成埋点
      TrackRequest({
        name: 'save_edit_period',
        data: {
          course_id: params.courseId,
          period_id: params.lessonId,
          period_type: params.data.lessonType,
        },
      });
    }
  } catch (error) {
    if (error && (error as any).data) {
      if ((error as any).status === 403) {
        Alert.open((error as any).data.message, () => {
          isNotRootLink();
        });
      } else {
        message.error((error as any).data.message);
      }
    }
  }
};

// 清理数据
export const clearData = (params: any) => {
  dispatch({ type: types.CLEAR_DATA, payload: params });
};

// 更新上传状态
export const uploadStatus = (typeName: string | undefined, status: boolean) => {
  dispatch({ type: types.UPDATE_PROGERSS_STATUS, payload: { typeName, status } });
};

export const lessonSortAction = async (
  courseId: string,
  lessonId: string,
  offset: number,
  sortData: any[],
) => {
  try {
    const { status } = await lessonSort(courseId, lessonId, offset);
    if (status === 200) {
      dispatch({ type: types.CHANGE_LIST_SORT, payload: sortData });
    } else {
      message.error('网络错误，请稍后再试！');
    }
  } catch {
    message.error('网络错误，请稍后再试！');
  }
};
