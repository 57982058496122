import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Menu from './Menu';
import Header from './Header';
import Footer from '../../components/Footer';
import Content from './Content';
import CourseList from '../CourseList/CourseList';
import CourseDetail from '../CourseDetail';
import CreateCourse from '../Course/createCourse';
import CourseManagement from '../Course/courseManagement';
import CreateLesson from '../Lesson/createLesson';
import UserCenter from '../UserCenter';
import ArticlePreview from '../ArticlePreview';
import CreateArticle from '../CreateArticle/CreateArticle';
import ArticleList from '../ArticleList';
import Statistics from '../Statistics';
import EmptySorce from '../Empty/emptySource';
import GlobalSearch from '../GlobalSearch';
import AccountManage from '../AccountManage';
import AccountManageDetail from '../AccountManageDetail';

import * as action from '../../redux/action/menu.action';
import { NormalObj, mergeProps, getSearchParams, stringifySearchParams } from '../../util';
import UserInfo from '../UserInfo';
import { recurdionGetFirstChild } from '../../util/menusFn';
import Alert from '../../components/Alert/Alert';
import ResourceLib from '../ResourceLib';
import MessageLists from '../Message/MessageLists';
import MessageDetatils from '../Message/MessageDetatils';
import TrackRequest from '../../lib/trackRequest';

type P = NormalObj;
class Layout extends React.PureComponent<P, any> {
  private hasNotCurrentColumnId = true; // 没有当前Id

  componentDidMount() {
    action.getMenusAndLeagueInfo();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: P) {
    if (nextProps.menus !== this.props.menus) {
      const searchParams = getSearchParams(this.props.location.search);
      const columnId = searchParams.id;
      const firstColum = nextProps.menus[0];
      const defaultRouterInfo = recurdionGetFirstChild(firstColum);
      const { id, child } = defaultRouterInfo;
      const search = {
        id,
        name: child ? (child as any).name : '',
        type: child ? (child as any).type : -1,
        parentId: firstColum ? firstColum.columnId : '',
      };
      if (this.continueToDo()) {
        this.onTrack(nextProps.menus, searchParams, columnId, firstColum);
        this.redirectPage(nextProps.menus, columnId, search);
      }
    }
  }

  continueToDo(): boolean {
    const { pathname } = this.props.location;
    const excludeTarget = [
      '/accountManage',
      '/accountManageDetail',
      '/statistics',
      '/userInfo',
      '/global-search',
      '/message-lists',
      '/message-detatils',
    ];
    if (excludeTarget.indexOf(pathname) === -1) return true;
    return false;
  }

  // 埋点
  onTrack(menus: any[], searchParams: any, columnId: string, firstColum: any) {
    let trackData = null;
    const currentMenus = menus.find((m: any) => m.columnId === searchParams.parentId);
    let currentMenu: any = currentMenus;
    if (searchParams.parentId !== columnId && currentMenus) {
      currentMenu = currentMenus.children.find((m: any) => m.columnId === columnId);
    }

    if (currentMenu) {
      trackData = {
        columns_id: columnId,
        columns_link: currentMenu.type === 2 ? JSON.parse(currentMenu.config).url : '',
      };
    } else if (firstColum) {
      trackData = {
        columns_id: firstColum.columnId,
        columns_link: firstColum.type === 2 ? JSON.parse(firstColum.config).url : '',
      };
    }

    if (trackData) {
      TrackRequest({
        name: 'click_columns',
        data: trackData,
      });
    }
  }

  // 重定向路由
  redirectPage(menus: any, targetId: string, search: any) {
    if (!targetId) {
      this.hasNotColumnIdRedirectPage(search);
    } else {
      this.hasNotColumnReloadPage(menus, targetId, search);
    }
  }
  // 地址栏中不存在栏目Id时重定向页面
  hasNotColumnIdRedirectPage(search: any): void {
    const searchParams = getSearchParams(this.props.location.search);
    if (searchParams.fromSearch) return;
    this.props.history.push(`/navigation${stringifySearchParams(search)}`);
  }
  // 不具有栏目权限时刷新页面
  hasNotColumnReloadPage(menus: any, targetId: string, search: any): void {
    const searchParams = getSearchParams(this.props.location.search);
    if (searchParams.fromSearch) return;
    if (this.hasNotColumnPrivilege(menus, targetId)) {
      Alert.open('暂无权限！');
      this.props.history.push(`/navigation${stringifySearchParams(search)}`);
    }
  }
  // 判断栏目是否不存在
  hasNotColumnPrivilege(menus: any, targetId: string): boolean {
    this.columnListsHasCoulumnId(menus, targetId);
    if (this.hasNotCurrentColumnId) {
      return true;
    }
    return false;
  }
  // 判断当前id是否在栏目列表中
  columnListsHasCoulumnId(menus: any, columnId: string): void {
    for (let i = 0, l = menus.length; i < l; i++) {
      if (menus[i].columnId === columnId) {
        this.hasNotCurrentColumnId = false;
      } else {
        this.columnListsHasCoulumnId(menus[i].children, columnId);
      }
    }
  }
  // 将路由映射到不同组件
  routerMapComponent(param: string) {
    let component;
    switch (param) {
      case '0':
        component = CourseList;
        break;
      case '1':
        component = ArticleList;
        break;
      case 'kechengbiao':
        component = UserCenter;
        break;
      case 'resLib':
        component = ResourceLib;
        break;
      default:
        component = EmptySorce;
        break;
    }
    return component;
  }
  renderMenu(menuPropData: any): JSX.Element {
    const { buttonConfig, menus } = this.props;
    const pageName = window.location.pathname;
    if (pageName === '/global-search') {
      return <span />;
    }
    if (menus.length > 0) {
      return <Menu {...menuPropData} buttonConfig={buttonConfig} />;
    }
    return <div style={{ marginTop: '20px' }} />;
  }
  render() {
    const { match, history, location, menus, leagueDetails } = this.props;
    const searchParams = getSearchParams(location.search);
    const menuProps = {
      menus,
      match,
      history,
      location,
    };
    return (
      <div className="Layout">
        <Header logo={leagueDetails.logo} history={history} />
        {this.renderMenu(menuProps)}
        <Content>
          <Switch>
            <Route
              exact
              path="/navigation"
              component={this.routerMapComponent(searchParams.type)}
            />
            <Route exact path="/resLib" component={ResourceLib} />
            <Route exact path="/accountManage" component={AccountManage} />
            <Route exact path="/accountManageDetail" component={AccountManageDetail} />
            <Route exact path="/courseDetail" component={CourseDetail} />
            <Route exact path="/createCourse" component={CreateCourse} />
            <Route exact path="/courseManagement" component={CourseManagement} />
            <Route exact path="/CreateLesson" component={CreateLesson} />
            <Route exact path="/lessonManagement" component={CreateLesson} />
            <Route exact path="/articlePreview" component={ArticlePreview} />
            <Route exact path="/articleSyncPreview" component={ArticlePreview} />
            <Route exact path="/createArticle" component={CreateArticle} />
            <Route exact path="/articleManagement" component={CreateArticle} />
            <Route exact path="/userInfo" component={UserInfo} />
            <Route exact path="/statistics" component={Statistics} />
            <Route exact path="/global-search" component={GlobalSearch} />
            <Route exact path="/message-lists" component={MessageLists} />
            <Route exact path="/message-detatils" component={MessageDetatils} />
            <Redirect to="/navigation" />
          </Switch>
        </Content>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({ ...state.Menu });
export default connect(mapStateToProps, mergeProps)(Layout);
