/* eslint-disable prefer-rest-params */
// 安全卸载组件
function componentSafeUnmount(WrappedComponent: any) {
  const next = WrappedComponent.prototype.componentWillUnmount;
  WrappedComponent.prototype.componentWillUnmount = function () {
    if (next) {
      next.call(this, ...Array.from(arguments));
    }
    this.unmout = true;
  };
  const { setState } = WrappedComponent.prototype;
  WrappedComponent.prototype.setState = function () {
    if (this.unmout) return null;
    setState.call(this, ...Array.from(arguments));
  };
}

export default componentSafeUnmount;
