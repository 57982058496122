/*
 * @Author: gouyang
 * @Description: 四分屏埋点提交
 * @Date: 2019-09-09 09:12:32
 */

import axios, { AxiosRequestConfig, AxiosPromise, AxiosInstance } from 'axios';
import { getToken } from '../util';
import apiConfig from '../config/api';

//埋点
interface wrapAxios extends AxiosInstance {
  (config: AxiosRequestConfig): AxiosPromise;
}
let AxiosWrap: wrapAxios = axios.create({
  baseURL: apiConfig.trackApi,
});
AxiosWrap.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers['x-module'] = 'open_school';
  return config;
});

const splitScreenRequest = (data: any) => {
  AxiosWrap({ data: data, method: 'post' });
};
/* 过滤响应 */
AxiosWrap.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {},
);

export default splitScreenRequest;
