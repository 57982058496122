import * as types from '../types/account.type';

interface Action {
  type: string;
  payload: any;
}
const initialState: any = {
  organizations: [],
};

const Account = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.ORGANIGATIONS: // 组织列表
      return { ...state, organizations: payload };
    default:
      return state;
  }
};
export default Account;
