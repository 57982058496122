// 课时
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Table, Modal, Popconfirm, Icon, message } from 'antd';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import TrackRequest from '../../lib/trackRequest';
import { getSubjectId, getSearchParams, stringifySearchParams } from '../../util';
import { getRoot } from './upload/uploadFormat';
import {
  getClassHour,
  getDelClassHour,
  getClassHourPush,
  getCheckExist,
  lessonSortAction,
} from '../../redux/action/lesson.action';
import DragableBodyRow from './DragableBodyRow';
import { ArtScience } from '../../util/enum';

import './style.less';

interface P {
  history: any;
  classHourList: any; // 课时列表
  courseId: string; // 课程id
  courseName?: string; // 课程名称
  courseInfo: any;
}
interface S {
  loading: boolean;
}
const SubjectIds = getSubjectId();
class ClassHour extends React.PureComponent<P, S> {
  private currentPage: number;
  private pageSize: number;
  constructor(props: P) {
    super(props);
    this.state = {
      loading: false,
    };
    this.currentPage = 1;
    this.pageSize = 10;
  }
  componentDidMount() {
    window.setTimeout(() => window.scrollTo(0, 0), 0);
    this.init();
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { classHourList } = nextProps;
    if (classHourList !== this.props.classHourList) {
      this.setState({
        loading: false,
      });
    }
  }
  init = () => {
    const { courseId } = this.props;
    this.setState({
      loading: true,
    });
    // 获取课时列表
    getClassHour(courseId);
  };
  // 切换内容组件
  switch = (e: Object, name: string) => {
    const data: any = {};
    if (name === 'course') {
      // 课程
      data.courseFlg = true;
      data.classHourFlg = false;
    } else if (name === 'classHour') {
      // 课时
      data.courseFlg = false;
      data.classHourFlg = true;
    }
    this.setState({
      ...data,
    });
  };
  // 课时发布/取消发布
  push = async (item: any, str: string) => {
    const { courseId } = this.props;
    if (str === '1') {
      // 发布
      getClassHourPush(
        { lessonId: item.id, courseId, lessonType: item.lessonType },
        str,
        this.init,
      );
    } else if (str === '2') {
      // 取消发布
      getClassHourPush({ lessonId: item.id, courseId }, str, this.init);
    }
  };

  // 课时删除
  del = (item: any) => {
    const { courseId } = this.props;
    getDelClassHour({ lessonId: item.id, courseId }, this.init);
  };

  // 查看审核原因
  reasons = () => {
    Modal.info({
      title: '审核未通过原因',
      content: (
        <div>
          <p>内容不合格...</p>
          <p>内容不合格...</p>
        </div>
      ),
      onOk() {},
    });
  };

  // 分页
  pageChange = (page: number) => {
    this.currentPage = page;
    window.sessionStorage.setItem('DEFAULTPAGE', `${page}`);
    window.scrollTo(0, 0);
  };

  // 分页数目变化
  onShowSizeChange = (current: number, size: number) => {
    this.currentPage = current;
    this.pageSize = size;
    window.sessionStorage.setItem('DEFAULTPAGE', `${current}`);
    window.sessionStorage.setItem('DEFAULTSIZE', `${size}`);
    window.scrollTo(0, 0);
  };

  // 添加课时
  addClassHour = () => {
    const { courseId, courseInfo } = this.props;
    const search = getSearchParams(decodeURI(window.location.search));
    search.courseId = courseId;
    search.courseName = courseInfo.courseName;
    // 创建课时跳转页面按钮埋点
    TrackRequest({ name: 'add_period', data: { course_id: courseId } });
    const currentPath = `${window.location.pathname + window.location.search}#status=lessonList`;
    this.props.history.push(`/CreateLesson${stringifySearchParams(search)}`);
    window.localStorage.setItem('LINKTO', JSON.stringify(currentPath));
  };

  // 课时管理
  classHourManagement = async (record: any) => {
    const { startTime, id, lessonType } = record;
    if (lessonType === 1 && new Date().getTime() >= startTime) {
      message.error('当前时间已超过开课时间，不支持编辑！');
      return;
    }
    const flg = await getCheckExist(id);
    if (flg) {
      const { courseId, courseInfo } = this.props;
      // 课时管理跳转按钮埋点
      TrackRequest({ name: 'period_management', data: { course_id: courseId, period_id: id } });
      const search = getSearchParams(decodeURI(window.location.search));
      search.courseId = courseId;
      search.courseName = courseInfo.courseName;
      search.lessonId = id;

      const currentPath = `${window.location.pathname + window.location.search}#status=lessonList`;
      this.props.history.push(`/lessonManagement${stringifySearchParams(search)}`);
      window.localStorage.setItem('LINKTO', JSON.stringify(currentPath));
    }
  };

  // 拖动行
  moveRow = (dragIndex: number, hoverIndex: number) => {
    const l = (this.currentPage - 1) * this.pageSize;
    const actualDragIndex = l + dragIndex;
    const actualHoverIndex = l + hoverIndex;
    const { classHourList, courseId } = this.props;
    const dragRow = classHourList[actualDragIndex];
    const data = update(classHourList, {
      $splice: [
        [actualDragIndex, 1],
        [actualHoverIndex, 0, dragRow],
      ],
    });
    lessonSortAction(courseId, dragRow.id, hoverIndex - dragIndex, data);
  };

  // 获取默认分页信息
  getDefaultInfo(): number[] {
    const page = window.sessionStorage.getItem('DEFAULTPAGE');
    const size = window.sessionStorage.getItem('DEFAULTSIZE');
    const p = page ? +page : 1;
    const s = size ? +size : 10;
    this.currentPage = p;
    this.pageSize = s;
    return [p, s];
  }

  render() {
    const { loading } = this.state;
    let { classHourList } = this.props;
    const lessonRoot: any = getRoot('lesson:*');
    classHourList = classHourList || [];
    let showHeader = true;
    if (classHourList.length === 0) {
      showHeader = false;
    }
    const columns: any = [
      {
        title: '课时名称',
        dataIndex: 'lessonName',
        width: 130,
        render: (text: string) => {
          return (
            <span title={text} className="table_span lessonName">
              {text}
            </span>
          );
        },
      },
      {
        title: '授课教师',
        dataIndex: 'teachers',
        width: 90,
        render: (text: any) => {
          const str = text.map((item: any) => item.teacherName).join('，');
          const obj = str.length > 5 ? { title: str } : '';
          return (
            <span {...obj} className="table_span teacherName">
              {str}
            </span>
          );
        },
      },
      {
        title: '课时类型',
        dataIndex: 'lessonType',
        width: 90,
        render: (text: number) => {
          const str = String(text);
          switch (str) {
            case '1':
              return '直播';
            case '2':
              return '点播';
            case '3':
              return '资料';
            default:
          }
        },
      },
      {
        title: '年级',
        dataIndex: 'grades',
        width: 110,
        render: (text: { [key: string]: any }) => {
          const str = text.map((item: any) => item.gradeName).join('，');
          const obj = str.length > 5 ? { title: str } : '';
          return (
            <span {...obj} className="table_span teacherName">
              {str}
            </span>
          );
        },
      },
      {
        title: '学科',
        dataIndex: 'subjects',
        width: 110,
        render: (text: { [key: string]: any }) => {
          const str = text.map((item: any) => item.subjectName).join('，');
          const obj = str.length > 5 ? { title: str } : '';
          return (
            <span {...obj} className="table_span teacherName">
              {str}
            </span>
          );
        },
      },
      {
        title: '文理科',
        dataIndex: 'artScience',
        render: (artScience: number) => {
          if (typeof artScience === 'number') {
            return ArtScience[artScience];
          }
          return '——';
        },
      },
      {
        title: '状态',
        dataIndex: 'status',
        width: 100,
        render: (text: string, record: any) => {
          // 禁用状态  forbiddenState 0 ：正常，1: 已禁用
          if (record.forbiddenState === 1) {
            return '已禁用';
          } else if (record.releaseState === 0) {
            return <span style={{ color: '#77849E' }}>待发布</span>;
          } else if (record.releaseState === 1) {
            return '已发布';
          }
        },
      },
      {
        title: '添加时间',
        dataIndex: 'createTime',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        render: (text: string, record: any) => {
          const { hoursManagement, push, cancelPush, reason, del } = select(this, record);
          // 如果登录用户不为全科 并且跟该课时 学科不一致，不能进行操作
          // eslint-disable-next-line
          let str = record.subjects.map((item: any) => item.subjectId);
          if (!SubjectIds) {
            return;
          }
          const flg = str.every((item: any) => SubjectIds.indexOf(item) >= 0);
          if (!flg) {
            return;
          }
          return (
            <div className="table">
              {
                // 课时管理
                record.forbiddenState === 0 &&
                (record.releaseState === 0 || record.reviewState === 2)
                  ? hoursManagement
                  : ''
              }
              {
                // 发布
                record.forbiddenState === 0 && record.releaseState === 0 ? push : ''
              }
              {
                // 取消发布
                // record.releaseState === 1 && record.reviewState === 3 ? cancelPush : ''
                record.forbiddenState === 0 && record.releaseState === 1 ? cancelPush : ''
              }
              {
                // 审核未通过原因
                record.releaseState === 1 && record.reviewState === 2 ? reason : ''
              }
              {
                //  删除 --- 已禁用 也要展示删除按钮 还没有禁用状态
                record.forbiddenState === 1 || record.releaseState === 0 || record.reviewState === 2
                  ? del
                  : ''
              }
            </div>
          );
        },
      },
    ];
    // 分页设置
    const total = classHourList.length;
    const [defaultCurrent, defaultPageSize] = this.getDefaultInfo();
    const pagination: any = {
      size: 'small',
      total,
      onChange: this.pageChange,
      defaultCurrent,
      showSizeChanger: true,
      defaultPageSize,
      pageSizeOptions: ['10', '20', '50', '100'],
      onShowSizeChange: this.onShowSizeChange,
    };

    return (
      <div className="coursePage">
        <div
          className="content lessonTable"
          style={showHeader ? { paddingTop: '8px' } : style.content}
        >
          <div style={{ padding: '24px 24px 0 24px' }}>
            {
              // 权限
              lessonRoot ? (
                <div className="addBtn">
                  <Button type="primary" onClick={this.addClassHour}>
                    <Icon type="plus-circle" theme="filled" />
                    添加课时
                  </Button>
                </div>
              ) : (
                ''
              )
            }
            <DndProvider backend={HTML5Backend}>
              <Table
                loading={loading}
                rowKey="id"
                showHeader={showHeader}
                pagination={pagination}
                dataSource={classHourList}
                columns={columns}
                components={DragableBodyRow}
                onRow={(record, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
              />
            </DndProvider>
          </div>
        </div>
      </div>
    );
  }
}
const select = ($this: any, record: any) => {
  const hoursManagement = (
    <span
      className="span_a"
      onClick={() => {
        $this.classHourManagement(record);
      }}
    >
      课时管理
    </span>
  );
  const push = (
    <Popconfirm
      placement="bottom"
      title="确认发布该课时吗？"
      onConfirm={() => $this.push(record, '1')}
    >
      <span className="span_a">发布</span>
    </Popconfirm>
  );
  const cancelPush = (
    <Popconfirm
      placement="bottom"
      title="确认取消发布该课时吗？"
      onConfirm={() => $this.push(record, '2')}
    >
      <span className="span_a">取消发布</span>
    </Popconfirm>
  );
  const reason = (
    <span className="span_a" onClick={() => $this.reasons(record)}>
      查看审核原因
    </span>
  );
  const del = (
    <Popconfirm placement="bottom" title="确认删除该课时吗？" onConfirm={() => $this.del(record)}>
      <span className="span_a">删除</span>
    </Popconfirm>
  );
  return {
    hoursManagement,
    push,
    cancelPush,
    reason,
    del,
  };
};
const style: any = {
  content: {
    paddingTop: '8px',
    paddingBottom: '80px',
  },
};
const mapStateToProps = (state: any) => ({
  classHourList: state.Lesson.classHourList,
  courseInfo: state.Course.courseInfo,
});

export default connect(mapStateToProps)(ClassHour);
